@use "../theme/colors" as *;
@use "../theme/breakpoints" as *;
@use "../theme/mixins" as *;

.ui-kit-tooltip-mobile {
    position: fixed;
    bottom: 0;
    z-index: 2000;
    width: 100vw;
    border-radius: 15px 15px 0 0;
    background-color: rgba(0 0 0 / 85%);
    backdrop-filter: blur(10px);
    max-height: 0;
    visibility: hidden;
    transition: max-height 0.5s;

    &.show {
        visibility: visible;
        max-height: 500px;

        & + .tooltip-mobile-backdrop {
            background-color: rgba(0 0 0 / 20%);
            display: block;
        }

        @include breakpoint($md) {
            display: none;
        }
    }

    & + .tooltip-mobile-backdrop {
        display: none;
        z-index: 1500;
    }
}

.tooltip-mobile-button {
    background-color: $transparent;
    border: none;
    border-top: 1px solid $white;
    text-decoration: underline;
}

.ui-kit-tooltip {
    width: 100%;
    background-color: rgba(0 0 0 / 75%);
    color: $gray-225;
    backdrop-filter: blur(20px);
    border-radius: 25px;
    z-index: 50;
    left: 50%;
    padding: 20px 20px 20px 30px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 40%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;

    @include breakpoint($lg) {
        padding: 35px 35px 25px;
    }

    .never-transform {
        transform: rotate(0) !important;

        &:hover {
            transform: rotate(0) !important;
        }
    }

    & + span svg {
        border-radius: 50%;
        position: relative;
        width: 34px;
        height: 34px;
        transition: transform 0.4s;
        background-color: $blue-450;

        &.icon-svg {
            background-color: transparent;

            &:hover {
                box-shadow: none;
                background-color: transparent;

                .fill-white {
                    fill: white !important;
                }
            }
        }

        @include dark {
            background-color: $navy-blue-300;
        }

        &:hover {
            background-color: $blue-600;
            box-shadow: 0 4px 4px rgba(0 0 0 / 25%);

            .info-path {
                fill: $blue-600;
            }

            .fill-blue-50 {
                fill: $blue-50;
            }
        }

        path {
            transition: fill 0.4s;
        }

        &.purple {
            background-color: $light-purple-100;

            &:hover {
                background-color: $light-purple-500;
                box-shadow: none;

                path {
                    fill: $white;
                }
            }
        }
    }

    &.show {
        visibility: visible;
        opacity: 1;

        & + span svg.purple {
            background-color: $light-purple-500;
            box-shadow: none;
            transform: rotate(0);

            path {
                fill: $white;
            }
        }

        & + span::before {
            visibility: visible;
            opacity: 1;
        }

        & + span .x-close.x-close-mirror {
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        & + span .x-close {
            transform: translate(-50%, -50%) rotate(45deg);
            opacity: 1;
            z-index: 5;
        }

        & + span .icon-effect {
            box-shadow: none;
        }

        & + span svg {
            &.never-transform {
                transform: none !important;

                path,
                &:hover .info-path {
                    &.fill-white {
                        fill: white;
                    }
                }
            }

            transform: rotate(45deg);
            background-color: $gray-350;
            box-shadow: none;

            path,
            &:hover .info-path {
                fill: $gray-350;
            }
        }
    }
}

.x-close-mirror {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.x-close {
    position: absolute;
    width: 3px;
    height: 70%;
    background-color: $white;
    top: 50%;
    left: 50%;
    transition: transform 0.4s, opacity 0.4s;
    transform: translate(-50%, -50%);
    opacity: 0;
    border-radius: 100px;
}

.tooltip-w-overflow-sm {
    @include breakpoint($md) {
        width: calc(100% + 20px);
    }
}

.tooltip-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.tooltip-arrow::before {
    content: "";
    z-index: 25;
    position: absolute;
    transition: opacity 0.4s;
    visibility: hidden;
    opacity: 0;
    left: 50%;
    bottom: -18px;
    background-image: linear-gradient(
        135deg,
        rgba(0 0 0 / 75%) 0,
        rgba(0 0 0 / 75%) 50%,
        $transparent 50%,
        $transparent 100%
    );
    width: 14px;
    height: 14px;
    transform: translateX(-50%) rotate(45deg);
    box-shadow: -2px -2px 2px 0 rgb(0 0 0 / 10%);
    display: none;

    @include breakpoint($md) {
        display: block;
    }

    @include dark {
        background-color: $dark-blue-300;
    }
}

.tooltip-arrow.no-arrow {
    &::before {
        display: none;
    }
}

.mobile-tooltip-content {
    max-height: 250px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #efefef80;
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #c5c5ff;
        border-radius: 5px;
        width: 10px;
        outline: 1px solid #c5c5ff;
    }
}
