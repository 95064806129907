$primary: #00725c;
$secondary: #005252;
$tertiary: #01a998;
$warning-400: #e9d502;
$warning-500: #af8d00;
$success-400: #4bb543;
$transparent: transparent;
$white: #fff;
$white-250: #fdfdfd;
$white-900: #e6e6e8;
$white-800: #dde8e9;
$white-alpha-10: rgba(255 255 255 / 10%);
$white-alpha-20: rgba(255 255 255 / 20%);
$black: #000;
$black-900: #010314;
$red-200: #ee8681;
$red-400: #f00;
$pale-blue-400: #f7f8fc;
$pale-blue-500: #f0f5ff;
$light-blue-400: #2ca8b1;
$blue-50: #c6c5ff;
$blue-200: #8d8dff;
$blue-225: #6366f1;
$blue-250: #3b3beb;
$blue-300: #3838f8;
$blue-350: #3a3af2;
$blue-400: #3a3aea;
$blue-450: #443ae1;
$blue-500: #0303d1;
$blue-600: #0000ad;
$blue-800: #000081;
$navy-blue-100: #80b5f5;
$navy-blue-300: #2b77bd;
$navy-blue-400: #005298;
$navy-blue-500: #2b517c;
$navy-blue-600: #2a537e;
$navy-blue-700: #1d3b5e;
$navy-blue-800: #152b4b;
$dark-blue-300: #012c3c;
$dark-blue-350: #002432;
$dark-blue-400: #001923;
$dark-blue-500: #000517;
$dark-blue-550: #0c1933;
$dark-blue-600: #010314;
$light-gray-50: #f5f5f5cb;
$light-gray-100: #f5f5f5;
$light-gray-125: #f7f7f7;
$light-gray-175: #dcdcdc;
$light-gray-150: #e3e3e3;
$light-gray-200: #e5e5e5;
$light-gray-300: #ebebeb;
$light-gray-350: #dedede;
$light-gray-400: #d6d6d6;
$light-gray-450: #fcfcfc;
$purple-200: #362547;
$purple-400: #7120ce;
$purple-500: #6e37bd;
$light-purple-100: #e6e6ff;
$light-purple-150: #f6f1fd;
$light-purple-200: #f2e9ff;
$light-purple-400: #a7a7ff;
$light-purple-500: #a7a7f9;
$gray-200: #e1e1e1;
$gray-225: #d9d9d9;
$gray-250: #d3d3d3;
$gray-300: #c7c7c7;
$gray-350: #bababa;
$gray-360: #bdbdbd;
$gray-375: #8e95a2;
$gray-400: #949494;
$medium-gray: #b7b7b7;
$dark-gray-100: #efefef;
$dark-gray-150: #7e7e7e;
$dark-gray-200: #707070;
$dark-gray-300: #ffffff4d;
$dark-gray-350: #696969;
$dark-gray-400: #5a5a5a;
$dark-gray-450: #615e5e;
$dark-gray-500: #4b4b4b;
$dark-gray-700: #222;
$dark-gray-800: #121212;
$teal-400: #01a998;
$teal-600: #006464;
$teal-650: #025858;
$teal-675: #035577;
$teal-700: #043f3f;
$teal-800: #003d3d;
$dark-teal-400: #02192d;
$dark-teal-500: #011923;
$green-200: #90bf6f;
$green-400: #6ccc82;
$light-green-400: #c5eef1;
$persian-green-200: #00e6ba;
$persian-green-300: #00c7a2;
$persian-green: #009e80;
$pink-400: #ee4d99;
$pink-500: #cc2e78;
$blueish-gray-100: #d4d7e5;
$blueish-gray-700: #565973;
$magenta-600: #b70edb;
$colors-map: (
    "primary": $primary,
    "secondary": $secondary,
    "tertiary": $tertiary,
    "warning-400": $warning-400,
    "warning-500": $warning-500,
    "success-400": $success-400,
    "transparent": $transparent,
    "white": $white,
    "white-250": $white-250,
    "white-900": $white-900,
    "white-alpha-10": $white-alpha-10,
    "white-alpha-20": $white-alpha-20,
    "black": $black,
    "black-900": $black-900,
    "red-200": $red-200,
    "red-400": $red-400,
    "pale-blue-400": $pale-blue-400,
    "pale-blue-500": $pale-blue-500,
    "light-blue-400": $light-blue-400,
    "blue-50": $blue-50,
    "blue-200": $blue-200,
    "blue-225": $blue-225,
    "blue-250": $blue-250,
    "blue-300": $blue-300,
    "blue-350": $blue-350,
    "blue-400": $blue-400,
    "blue-450": $blue-450,
    "blue-500": $blue-500,
    "blue-600": $blue-600,
    "blue-800": $blue-800,
    "navy-blue-100": $navy-blue-100,
    "navy-blue-400": $navy-blue-400,
    "navy-blue-300": $navy-blue-300,
    "navy-blue-500": $navy-blue-500,
    "navy-blue-600": $navy-blue-600,
    "navy-blue-700": $navy-blue-700,
    "navy-blue-800": $navy-blue-800,
    "dark-blue-300": $dark-blue-300,
    "dark-blue-350": $dark-blue-350,
    "dark-blue-400": $dark-blue-400,
    "dark-blue-500": $dark-blue-500,
    "light-gray-50": $light-gray-50,
    "light-gray-100": $light-gray-100,
    "light-gray-125": $light-gray-125,
    "light-gray-150": $light-gray-150,
    "light-gray-175": $light-gray-175,
    "light-gray-200": $light-gray-200,
    "light-gray-300": $light-gray-300,
    "light-gray-350": $light-gray-350,
    "light-gray-400": $light-gray-400,
    "light-gray-450": $light-gray-450,
    "light-purple-100": $light-purple-100,
    "light-purple-200": $light-purple-200,
    "light-purple-500": $light-purple-500,
    "purple-200": $purple-200,
    "purple-400": $purple-400,
    "purple-500": $purple-500,
    "gray-200": $gray-200,
    "gray-225": $gray-225,
    "gray-250": $gray-250,
    "gray-300": $gray-300,
    "gray-350": $gray-350,
    "gray-360": $gray-360,
    "gray-375": $gray-375,
    "gray-400": $gray-400,
    "medium-gray": $medium-gray,
    "dark-gray-100": $dark-gray-100,
    "dark-gray-150": $dark-gray-150,
    "dark-gray-200": $dark-gray-200,
    "dark-gray-300": $dark-gray-300,
    "dark-gray-350": $dark-gray-350,
    "dark-gray-400": $dark-gray-400,
    "dark-gray-450": $dark-gray-450,
    "dark-gray-500": $dark-gray-500,
    "dark-gray-700": $dark-gray-700,
    "dark-gray-800": $dark-gray-800,
    "teal-400": $teal-400,
    "teal-600": $teal-600,
    "teal-650": $teal-650,
    "teal-675": $teal-675,
    "teal-700": $teal-700,
    "teal-800": $teal-800,
    "dark-teal-400": $dark-teal-400,
    "dark-teal-500": $dark-teal-500,
    "green-200": $green-200,
    "green-400": $green-400,
    "light-green-400": $light-green-400,
    "persian-green-200": $persian-green-200,
    "persian-green-300": $persian-green-300,
    "persian-green": $persian-green,
    "pink-400": $pink-400,
    "pink-500": $pink-500,
    "white-800": $white-800,
    "blueish-gray-100": $blueish-gray-100,
    "blueish-gray-700": $blueish-gray-700,
    "dark-blue-550": $dark-blue-550,
    "dark-blue-600": $dark-blue-600,
    "magenta-600": $magenta-600,
);
