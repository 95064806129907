@use "../theme/colors" as *;
@use "../theme/breakpoints" as *;
@use "../theme/mixins" as *;

.ui-kit.product-card {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    box-shadow: rgb(0 0 0 / 16%) 0 1px 4px;
    border-radius: 10px;
    padding: 2rem 1rem;
    font-size: 0.875rem;
    font-weight: 300;

    &.disabled {
        pointer-events: none;
        filter: grayscale(1);
    }

    @include breakpoint($md) {
        padding-left: 280px;
        padding-right: 1.5rem;
    }

    @include breakpoint($lg) {
        padding-top: 170px;
        padding-bottom: 170px;
        min-height: 476px;
    }

    &:hover {
        @include breakpoint($lg) {
            padding-top: 40px;
            padding-bottom: 2rem;

            .card-caller {
                height: auto;
                visibility: visible;
                opacity: 1;
            }

            h4 {
                text-align: left;
                font-size: 1.5rem;
            }
        }
    }

    @include dark {
        border: none;
        box-shadow: unset;
    }

    ul {
        padding: 0;
        margin-top: 1rem;

        li {
            padding-bottom: 0.5rem;
            margin-bottom: 0.5rem;
            border-bottom: 1px solid $gray-300;
        }
    }

    a {
        color: $primary;

        @include dark {
            color: $tertiary;
        }
    }

    h4 {
        text-align: center;
        margin-bottom: 1rem;
        font-size: 1.5rem;

        @include breakpoint($lg) {
            font-size: 3.125rem;
        }
    }

    .card-content {
        flex: 1;

        @include breakpoint($lg) {
            align-self: center;
        }
    }

    .card-caller {
        position: relative;
        z-index: 10;
        display: flex;
        flex: 1;
        flex-direction: column;

        @include breakpoint($lg) {
            height: 0;
            visibility: hidden;
            opacity: 0;
        }
    }

    img {
        position: absolute;
        height: auto;
        display: none;

        @include breakpoint($md) {
            display: block;
        }
    }

    .button-container {
        align-self: center;
    }
}

.ui-kit.subscription-card {
    flex-direction: column;
    width: 100%;
    box-shadow: rgb(0 0 0 / 16%) 0 1px 4px;
    padding: 2.68rem;
    border-radius: 20px;
    text-align: center;
    background-color: $white;

    &.show-icon {
        padding-top: 0;
    }

    @include breakpoint($lg) {
        width: fit-content;
    }

    @include dark {
        background-color: $black;
    }

    img {
        margin-top: -22px;
        margin-bottom: 1.875rem;
    }

    p {
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 1.5rem;
    }

    div {
        display: flex;
        justify-content: center;
    }

    .card-title {
        font-size: 2.125rem;
        font-weight: 300;
        color: $primary;
        margin-bottom: 0.5rem;

        @include dark {
            color: $tertiary;
        }
    }

    .card-price {
        font-size: 2.125rem;
        font-weight: bold;
    }

    .card-badge {
        margin-top: -22px;
        background-color: $primary;
        color: $white;
        font-size: 20px;
        font-weight: bold;
        padding: 8px 40px;
        border-radius: 100px;
    }
}

.ui-kit.benefits-card {
    background-color: $light-gray-300;
    font-weight: 300;
    padding: 2rem;
    border-radius: 20px;

    @include dark {
        background-color: $black;
    }

    h4 {
        font-size: 1.5rem;
        font-weight: 300;
        margin-bottom: 1.75rem;

        @include breakpoint($xl) {
            font-size: 2rem;
        }
    }

    .benefit-description {
        font-size: 1rem;
        margin-bottom: 2rem;
        font-weight: 300;

        @include breakpoint($xl) {
            font-size: 1.5rem;
        }
    }

    .benefits-list {
        padding: 0;
        margin: 0;
    }

    .benefit-item {
        display: flex;
        align-items: baseline;
        gap: 0.8rem;

        p {
            font-weight: 300;
            padding: 0.5rem 0;
            border-bottom: 1px solid $light-gray-400;
            width: 100%;
        }

        &.last-item p {
            border-bottom: none;
        }

        img {
            position: relative;
            top: 4px;
        }

        img,
        p {
            display: block;
            margin: 0;
        }
    }
}

.ui-kit.ui-titled-card {
    background-color: $white;
    box-shadow: rgb(0 0 0 / 16%) 0 1px 4px;

    @include dark {
        background-color: $dark-blue-400;
        box-shadow: #0bb7a670 0 1px 8px;

        input {
            color: $white;
        }
    }
}

.ui-kit.ui-report-data-card {
    .card-header {
        background-color: $light-purple-100;
        border-radius: 15px 15px 0 0;
        border: 2px solid $transparent;
        border-bottom: 0;

        @include dark {
            border: 2px solid $white;
            border-bottom: 0;
            color: $black;
        }

        &.header-blue {
            background-color: $blue-350;

            @include dark {
                background-color: $blue-300;
            }
        }
    }

    .card-body {
        border-radius: 0 0 15px 15px;
        border: 2px solid $light-purple-100;
        border-top: none;

        &.body-white {
            @include dark {
                border: 2px solid $white;
                border-top: none;
            }
        }
    }
}

.card-bubble {
    position: relative;
    background-color: $white;

    &.bubble-arrow::before,
    &.up-arrow::before {
        content: "";
        position: absolute;
        display: block;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid $white;
        filter: drop-shadow(0 -2px 0 $light-purple-100);

        @include dark {
            border-bottom: 20px solid $dark-blue-400;
            filter: drop-shadow(0 -2px 0 $white-800);
        }
    }

    &.down-arrow::after {
        content: "";
        position: absolute;
        display: block;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid $white;
        filter: drop-shadow(0 2px 0 $light-purple-100);

        @include dark {
            filter: drop-shadow(0 2px 0 $navy-blue-300);
        }
    }
}

.card-divider {
    border-bottom: 2px solid $gray-350;

    &.divider-purple {
        border-color: $light-purple-100;
    }

    &.divider-md-right {
        @include breakpoint($md) {
            border-bottom: none;
            border-right: 2px solid $gray-350;
        }
    }
}

.body-white .card-divider {
    @include dark {
        border-color: $white;
    }
}

.border-purple-white {
    border: 2px solid $light-purple-100;

    @include dark {
        border: 2px solid $white-800;
    }
}

.shadow-blue {
    box-shadow: 0 0 10px 0 rgb(3 3 209 / 32%);

    @include dark {
        box-shadow: 0 0 10px 0 $light-blue-400;
    }
}

.full-icon-card {
    border: 3px solid $light-gray-100;

    @include dark {
        border: 3px solid $dark-blue-300;
    }

    .full-icon-card-banner {
        background-color: $light-purple-100;
        outline: 3px solid $light-purple-100;

        @include dark {
            outline: 3px solid $dark-blue-300;
        }
    }
}

.border-xs-blue {
    @include breakpoint($xs) {
        border: 2px solid #e6e6ff;

        @include dark {
            border: 2px solid $light-blue-400;
        }
    }
}

.shadow-xs-blue {
    @include breakpoint($xs) {
        box-shadow: 0 0 10px rgb(3 3 209 / 32%);

        @include dark {
            box-shadow: 0 0 10px rgb(100 146 163 / 32%);
        }
    }
}

.figure-card {
    border: 2px solid $light-purple-100;
}

.figure-card-divider {
    @include breakpoint($xl) {
        border-right: 1px solid gray;
    }
}
