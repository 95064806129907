@use "../theme/colors" as *;
@use "../theme/breakpoints" as *;
@use "../theme/mixins" as *;

.ui-kit.trial-days {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include breakpoint($sm) {
        align-items: flex-end;
    }

    & > div {
        width: fit-content;
        padding: 0.5rem 1.5rem;
        text-align: center;
        border-radius: 20px;
        margin-bottom: 0.5rem;
        font-weight: bold;
    }

    & > p,
    .trial-warning {
        font-weight: 300;
        font-size: 0.875rem;
        text-align: right;
    }

    &.trial-days-left > div {
        color: $white;
        background-color: $primary;
    }

    &.trial-days-over > div {
        background-color: $transparent;
        border: 2px solid $red-400;
        color: $red-400;
    }

    .trial-warning {
        color: $red-400;
        margin-bottom: 0.5rem;
    }
}

.ui-kit.plus-minus-icon {
    @include plus-minus-icon($thickness: 2px);
}
