@use "../theme/colors" as *;
@use "../theme/breakpoints" as *;
@use "../theme/mixins" as *;

#server-error-page,
#reset-password-page {
    color: $black;

    section {
        display: flex;
        align-items: center;
    }

    h2 {
        font-weight: 300;
        font-size: 2.5rem;
    }

    @include dark {
        color: $white;
    }
}

#server-error-page {
    padding-top: 3rem;

    .buttons-container {
        margin-top: 3rem;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;

        @include breakpoint($lg) {
            justify-content: flex-start;
            gap: 5rem;
        }
    }
}

#reset-password-page {
    .email-sent-message {
        font-size: 0.875rem;

        & > span {
            color: $primary;

            @include dark {
                color: $tertiary;
            }
        }
    }
}
