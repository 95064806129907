@use "../theme/colors" as clr;
@use "../theme/breakpoints" as bp;
@use "../theme/mixins" as mx;

$container-max-width: 1294px;

.dark-gradient-body {
    @include mx.dark {
        background-color: #152b4b;
        background-image: linear-gradient(#152b4b, #010314);
        background-size: cover;
        background-attachment: fixed;
    }
}

.login-header {
    padding-top: 35%;

    @include mx.breakpoint(bp.$xs) {
        padding-top: 20px;
    }

    @include mx.breakpoint(bp.$lg) {
        padding-top: 0;
    }
}

.login-container {
    max-width: $container-max-width;
    padding: 0 20px;

    @include mx.breakpoint(bp.$lg) {
        min-height: calc(100vh - 200px);
    }
}

.graphic-eye {
    position: absolute;
    top: 0;

    @include mx.breakpoint(bp.$lg) {
        position: fixed;
        top: 50%;
    }
}

.spin-loop-animation {
    width: 100%;
    animation: spin-loop-mobile 8s infinite linear;

    @include mx.breakpoint(bp.$lg) {
        width: auto;
        animation: spin-loop 8s infinite linear;
    }
}

.mobile-img-screen {
    animation: floating-screen 3s ease-in-out infinite;
}

@media screen and (min-width: $container-max-width) {
    .login-container {
        padding: 0;
    }
}

@keyframes floating-screen {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes spin-loop-mobile {
    0% {
        transform: translate(-50%, 0) rotate(0deg);
    }

    100% {
        transform: translate(-50%, 0) rotate(360deg);
    }
}

@keyframes spin-loop {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
