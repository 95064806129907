@use "../theme/colors" as *;
@use "../theme/breakpoints" as *;
@use "../theme/mixins" as *;

.ui-kit.sidebar-layout {
    color: $black;
    display: flex;
    min-height: calc(100vh - 350px);
    width: 100%;

    section {
        flex: 1;
    }

    @include dark {
        color: $white;
    }

    .aside-wrapper {
        @include breakpoint($lg) {
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            z-index: 5000;
        }
    }

    .aside-navigator {
        position: fixed;
        scrollbar-color: rgb(0 0 0 / 0%);
        scrollbar-width: none;
        z-index: 200;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: $dark-gray-700;
        transition: width 0.4s;
        border-top: 1px solid $dark-gray-450;

        &::-webkit-scrollbar {
            width: 0;
        }

        @include dark {
            background-color: $black-900;
        }

        @include breakpoint($lg) {
            border-top: none;
            top: 0;
            height: 100%;
            padding: 3.125rem 0.75rem;
            position: static;
            overflow-y: scroll;
            width: $sidebar-width-open;

            .icon-label {
                display: inline !important;
            }

            .sidebar-icons-container {
                @include breakpoint($lg) {
                    gap: 2.8rem;
                }

                @include breakpoint($lxl) {
                    gap: 2.8rem;
                }
            }
        }
    }

    .main-content {
        @include sidebar-padding;

        margin-bottom: 1rem;

        @include breakpoint($lg) {
            margin-bottom: 1rem;
        }
    }

    .sidebar-icons-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @include breakpoint($lg) {
            width: 100%;
            flex-direction: column;
            gap: 2.8rem;
        }

        @include breakpoint($lxl) {
            gap: 2.8rem;
        }
    }

    .language-flag {
        opacity: 0;
        transition: padding 0.4s, opacity 0.2s ease-out, transform 0.2s ease-out;
        padding-right: 13px;
        transform: translateX(-20px);
        pointer-events: none;
    }

    .language-selector {
        display: flex;

        .language-link {
            cursor: pointer;
        }

        .language-item {
            position: relative;

            &:hover span {
                color: $white;
            }

            & > .language-link {
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 100px;
                    transform: scaleX(0.7);
                    transition: transform 0.4s ease-out,
                        background-color 0.4s ease-out;
                    background-color: transparent;
                    z-index: -1;
                }
            }

            &:hover {
                & > .language-link {
                    &::before {
                        background-color: rgba(255 255 255 / 20%);
                        transform: scaleX(1);
                        transition: background-color 0.4s;
                    }

                    transition: background-color 0.4s;
                }

                .language-flag {
                    visibility: visible;
                    padding: 0 13px;
                    opacity: 1;
                    transform: translateX(0);
                    pointer-events: auto;
                }
            }

            &.active,
            &:active {
                .language-flag {
                    visibility: visible;
                    opacity: 1;
                    padding: 0 13px;
                    transform: translateX(0);
                    pointer-events: auto;
                }
            }
        }
    }

    .aside-linked,
    & + .aside-linked {
        @include open-sidebar-padding;
    }
}

.p-sidebar {
    padding: 3.125rem 1rem;
    @include breakpoint($md) {
        padding: 3.125rem 2.125rem;
    }
}

.py-sidebar {
    padding: 3.125rem 0.75rem;
}

.w-sidebar {
    width: 100%;

    @include breakpoint($lg) {
        width: max-content;
        max-width: 500px;
    }
}

.sidebar-section {
    background-color: $dark-gray-700;
    color: white;
    position: fixed;
    border-radius: 20px 20px 0 0;
    left: 0;
    top: unset;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    z-index: -1;
    overflow-y: auto;

    @include dark {
        background-color: $black-900;
    }

    @include breakpoint($lg) {
        border-left: 1px solid $dark-gray-450;
        border-bottom: none;
        border-radius: 0 20px 20px 0;
        width: auto;
        height: 100%;
        left: 100px;
        top: 0;
    }
}

.sidebar-button {
    border-radius: 10px;
    padding: 0.625rem;
    transition: background-color 0.4s ease;

    &:hover {
        background-color: $white-alpha-20;
    }

    &[aria-expanded="true"] {
        background-color: $white-alpha-10;
    }
}

.sidebar-section.collapsing {
    animation: fade 0.6s ease;
}

.sidebar-item {
    padding: 0.625rem 0.75rem;
    transition: background-color 0.4s ease;
    background-color: $transparent;
    border-radius: 100px;

    &:hover {
        background-color: $white-alpha-20;
        color: $white;
    }
}

@keyframes fade {
    from {
        opacity: 0.5;
    }

    to {
        opacity: 1;
    }
}
