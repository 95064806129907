@use "./breakpoints.scss" as breakpoints;
@use "./mixins.scss" as mixins;
@use "./functions.scss" as f;

$spacing: (
    s0: 0,
    s2: f.px-to-rem(2),
    s3: f.px-to-rem(3),
    s4: f.px-to-rem(4),
    s5: f.px-to-rem(5),
    s6: f.px-to-rem(6),
    s7: f.px-to-rem(7),
    s8: f.px-to-rem(8),
    s9: f.px-to-rem(9),
    s10: f.px-to-rem(10),
    s12: f.px-to-rem(12),
    s13: f.px-to-rem(13),
    s14: f.px-to-rem(14),
    s15: f.px-to-rem(15),
    s16: f.px-to-rem(16),
    s18: f.px-to-rem(18),
    s20: f.px-to-rem(20),
    s22: f.px-to-rem(22),
    s24: f.px-to-rem(24),
    s25: f.px-to-rem(25),
    s27: f.px-to-rem(27),
    s29: f.px-to-rem(29),
    s30: f.px-to-rem(30),
    s32: f.px-to-rem(32),
    s35: f.px-to-rem(35),
    s40: f.px-to-rem(40),
    s45: f.px-to-rem(45),
    s48: f.px-to-rem(48),
    s50: f.px-to-rem(50),
    s60: f.px-to-rem(60),
    s70: f.px-to-rem(70),
    s80: f.px-to-rem(80),
    s100: f.px-to-rem(100),
    s120: f.px-to-rem(120),
    s150: f.px-to-rem(150),
);
$margin-classes: (
    "m": margin,
    "mt": margin-top,
    "mb": margin-bottom,
    "mr": margin-right,
    "ml": margin-left,
);
$padding-classes: (
    "p": padding,
    "pt": padding-top,
    "pb": padding-bottom,
    "pr": padding-right,
    "pl": padding-left,
);

@mixin generate-spacings($spacing-key, $spacing-value, $divider: "-") {
    @each $margin-key, $margin-value in $margin-classes {
        .#{$margin-key}#{$divider}#{$spacing-key} {
            #{$margin-value}: #{$spacing-value};
        }

        .-#{$margin-key}#{$divider}#{$spacing-key} {
            #{$margin-value}: -#{$spacing-value};
        }
    }

    @each $padding-key, $padding-value in $padding-classes {
        .#{$padding-key}#{$divider}#{$spacing-key} {
            #{$padding-value}: #{$spacing-value};
        }

        .-#{$padding-key}#{$divider}#{$spacing-key} {
            #{$padding-value}: -#{$spacing-value};
        }
    }

    .mx#{$divider}#{$spacing-key} {
        margin-left: $spacing-value;
        margin-right: $spacing-value;
    }

    .my#{$divider}#{$spacing-key} {
        margin-top: $spacing-value;
        margin-bottom: $spacing-value;
    }

    .px#{$divider}#{$spacing-key} {
        padding-left: $spacing-value;
        padding-right: $spacing-value;
    }

    .py#{$divider}#{$spacing-key} {
        padding-top: $spacing-value;
        padding-bottom: $spacing-value;
    }

    .gap#{$divider}#{$spacing-key} {
        gap: $spacing-value;
    }

    .pm#{$divider}#{$spacing-key} {
        padding-left: calc($spacing-value / 2);
        padding-right: calc($spacing-value / 2);
    }

    .gx#{$divider}#{$spacing-key} {
        & > .gx-child {
            &:first-of-type {
                padding-left: 0;
                padding-right: $spacing-value;
            }

            &:last-of-type {
                padding-left: $spacing-value;
                padding-right: 0;
            }

            padding-left: calc($spacing-value / 2);
            padding-right: calc($spacing-value / 2);
        }
    }
}

@each $bp-key, $bp-value in breakpoints.$breakpoints-map {
    @each $spacing-key, $spacing-value in $spacing {
        @if $bp-key == base {
            @include generate-spacings($spacing-key, $spacing-value);
        } @else {
            @include mixins.toplevel-breakpoint($bp-value) {
                @include generate-spacings(
                    $spacing-key,
                    $spacing-value,
                    "-#{$bp-key}-"
                );
            }
        }
    }
}
