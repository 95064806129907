@use "../theme/colors" as *;
@use "../theme/breakpoints" as *;
@use "../theme/mixins" as *;

.ui-kit.ui-footer {
    background-color: $white;
    color: $black;
    font-weight: 300;
    font-size: 1rem;
    padding: 1rem;
    padding-top: 0;

    &.footer-transparent {
        background-color: $transparent;
    }

    @include dark {
        color: $white;
        background-color: $dark-blue-400;

        &.footer-transparent {
            background-color: $transparent;
        }
    }

    @include breakpoint($sm) {
        padding: 1rem 3.125rem;
        padding-top: 0;
    }

    .footer-row {
        align-items: flex-start;
        justify-content: flex-start;
        border-top: solid $light-gray-150;

        @include dark {
            &.row-white {
                border-top: solid $white;
            }

            border-top: solid $teal-800;
        }
    }

    .footer-links {
        li > a {
            color: $primary;

            &:hover {
                text-decoration: underline;
            }

            @include dark {
                color: $light-blue-400;
            }
        }

        h6,
        li {
            font-weight: 300;
            margin-bottom: 0.5rem;
        }
    }
}

.sidebar-layout + .ui-kit.ui-footer {
    @include sidebar-padding;

    padding-bottom: 8rem;
}
