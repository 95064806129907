@use "./theme/colors" as *;
@use "./theme/breakpoints" as *;
@use "./theme/functions.scss" as *;
@use "./theme/mixins" as *;
@use "./theme/typography.scss" as *;
@use "./theme/spacing.scss" as *;
@use "./theme/buttons.scss" as *;

@use "./components/inputs.scss";
@use "./components/logos.scss";
@use "./components/toggles.scss";
@use "./components/cards.scss";
@use "./components/widgets.scss";
@use "./components/accordion.scss";
@use "./components/tooltips.scss";
@use "./components/autocomplete.scss";

@use "./layouts/header.scss";
@use "./layouts/footer.scss";
@use "./layouts/columns.scss";
@use "./layouts/sidebar.scss";

@use "./pages/index.scss";
@use "./pages/user.scss";
@use "./pages/errors.scss";
@use "./pages/meye.scss";
@use "./pages/login.scss";
@use "./pages/score.scss";
@use "../shame.scss";

.preload-transitions * {
    transition: none !important;
}

.linked-field {
    label {
        min-width: 100px;
    }

    input {
        border: none;
        border-bottom: 1px solid gray;
        outline: none;
    }
}

body {
    background-color: $white;
    min-height: 100vh;
    color: black;

    @include dark {
        background-color: $dark-blue-400;
        color: white;
    }
}

.no-style-dropdown select {
    appearance: none;
    border-radius: 0;
}

.no-style-dropdown {
    position: relative;

    &::after {
        content: "\2304";
        position: absolute;
        right: 0;
        top: 40%;
        transform: translateY(-50%);
        display: inline-block;
        color: #949494;
    }
}

.top-input-container {
    position: relative;
    flex-basis: 70px;
}

.plus-sign {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    font-size: 14px;
}

.ui-kit.heading-1 {
    font-weight: 300;
    font-size: 1.5rem;
    margin-bottom: 3.6rem;

    @include breakpoint($sm) {
        font-size: 2.5rem;
    }
}

.ui-kit.heading-2 {
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 3.6rem;

    &.heading-border {
        padding-bottom: 1.875rem;
        border-bottom: 2px solid $light-gray-100;

        @include dark {
            border-color: $gray-400;
        }
    }

    @include breakpoint($sm) {
        font-size: 1.5rem;
    }
}

.ui-kit.heading-3 {
    font-weight: 300;
    font-size: 1.25rem;
    color: $gray-400;
    margin-bottom: 3.52rem;

    @include dark {
        color: $gray-300;
    }
}

.featured-text {
    font-weight: 300;
    color: $tertiary;
    margin-bottom: 3.6rem;

    @include breakpoint($xxl) {
        margin-left: 20rem;
    }
}

.ui-kit.margin-b-3 {
    margin-bottom: 3.6rem;
}

.ui-kit.flex-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex {
    display: flex;
}

.flex-0-8 {
    flex: 0.8;
}

.flex-1 {
    flex: 1;
}

.flex-1-mobile {
    @include breakpoint-range($base, $lg);
}

.flex-1-5 {
    flex: 1.5;
}

.flex-2 {
    flex: 2;
}

.flex-lg-default {
    @include breakpoint($lg) {
        flex: 0 1 auto;
    }
}

.flex-lxl-unset {
    @include breakpoint($lxl) {
        flex: unset;
    }
}

.font-light {
    font-weight: 200;
}

.font-normal {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.font-lg-medium {
    @include breakpoint($lg) {
        font-weight: 500;
    }
}

.banner-button {
    @include breakpoint-range($base, $lg) {
        display: block;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}

.underline {
    text-decoration: underline;
}

.text-sm {
    font-size: 0.875rem;
}

.text-right {
    text-align: right;
}

.text-gray {
    color: $dark-gray-400;
}

.anchor-hover:hover {
    color: $primary;
    text-decoration: underline;
}

.basis-515 {
    flex-basis: 515px;
}

.w-s100 {
    width: 100%;
}

.w-sm-s50 {
    @include breakpoint($sm) {
        width: 50%;
    }
}

.w-sm-auto {
    @include breakpoint($sm) {
        width: auto;
    }
}

.w-lg-100 {
    @include breakpoint($lg) {
        width: 100%;
    }
}

.w-fit {
    width: fit-content;
}

.w-lg-fit {
    @include breakpoint($lg) {
        width: fit-content;
    }
}

.w-xl-30 {
    @include breakpoint($xl) {
        width: calc(100% / 3);
    }
}

.w-md-90 {
    @include breakpoint($md) {
        width: 90%;
    }
}

.max-w-lg-575 {
    @include breakpoint($lg) {
        max-width: 575px;
    }
}

.max-w-620 {
    max-width: 620px;
}

.max-h-150 {
    max-height: 150px;
}

.indication-flex {
    flex: 1 0 50px;
}

.rounded-10 {
    border-radius: 10px;
}

.rounded-15 {
    border-radius: 15px;
}

.rounded-b-15 {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.rounded-16 {
    border-radius: 16px;
}

.rounded-20 {
    border-radius: 20px;
}

.rounded-25 {
    border-radius: 25px;
}

.rounded-t-15 {
    border-radius: 15px 15px 0 0;
}

.rounded-lg-15 {
    @include breakpoint($lg) {
        border-radius: 15px;
    }
}

.rounded-l-md-15 {
    @include breakpoint($md) {
        border-radius: 15px 0 0 15px;
    }
}

.box-shadow-lg {
    box-shadow: 0 0 0.5rem rgb(0 0 0 / 7.5%);
}

.flip-y {
    transform: scaleY(-1);
}

.cursor-pointer {
    cursor: pointer;
}

.absolute-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.tools-spinner {
    gap: 2rem;
    border-radius: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;
    width: calc(100% - 30px);
    padding: 5rem 2rem;

    @include breakpoint($md) {
        padding: 4.375rem 6.25rem;
        width: auto;
        max-width: 741px;
    }
}

.theme-spinner {
    width: 75px;
    height: 75px;
    border: 10px solid black;
    border-right-color: $transparent;
}

.spinner-blue-500 {
    border-color: $blue-500;
    border-right-color: $transparent;

    @include dark {
        border-color: $blue-400;
        border-right-color: $transparent;
    }
}

.spinner-purple-500 {
    border-color: $purple-500;
    border-right-color: $transparent;

    @include dark {
        border-color: $purple-400;
        border-right-color: $transparent;
    }
}

.submit-container {
    display: flex;
    justify-content: center;
}

.submit-container button:disabled {
    background-color: $gray-350;
    color: white;
    border-color: white;
    pointer-events: none;
}

.d-dark-none {
    display: block;
    @include dark {
        display: none;
    }
}

.d-dark-block {
    display: none;

    @include dark {
        display: block;
    }
}

.theme-switcher {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;

    .theme-icon {
        position: relative;
        width: 40px;
        height: 40px;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: opacity 0.3s ease-in-out;
        }

        .theme-light {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

            @include dark {
                opacity: 1;
                visibility: visible;
            }
        }

        .theme-dark {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

            @include dark {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    .hover-label-light,
    .hover-label-dark {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    &:hover {
        .hover-label-light {
            @include dark {
                opacity: 1;
                visibility: visible;
            }
        }

        .hover-label-dark {
            opacity: 1;
            visibility: visible;

            @include dark {
                opacity: 0;
                visibility: hidden;
            }
        }

        .theme-light {
            opacity: 1;
            visibility: visible;

            @include dark {
                opacity: 0;
                visibility: hidden;
            }
        }

        .theme-dark {
            opacity: 0;
            visibility: hidden;

            @include dark {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.dark-stroke-white {
    @include dark {
        stroke: $white;
    }
}

.dark-stroke-navy {
    @include dark {
        stroke: $navy-blue-700;
    }
}

.dark-fill {
    @include dark {
        fill: $navy-blue-700;
    }
}

.fill-blue-50 {
    fill: transparent;
}

.fill-blue-350 {
    @include dark {
        fill: $blue-350;
    }
}

.dark-fill-navy-blue-300 {
    @include dark {
        fill: $navy-blue-300;
    }
}

.dark-fill-blue {
    @include dark {
        fill: $navy-blue-400;
    }
}

.dark-fill-light-blue {
    @include dark {
        fill: $light-blue-400;
    }
}

.dark-fill-black {
    @include dark {
        fill: $black;
    }
}

.light-text-fill {
    fill: $black;
}

.dark-text-fill {
    @include dark {
        fill: $light-green-400;
    }
}

.dark-text-fill-white {
    @include dark {
        fill: $white;
    }
}

.w-md-max-content {
    @include breakpoint($sm) {
        width: max-content;
    }
}

.border-blue {
    border: 2px solid $light-purple-100;

    @include dark {
        border: 2px solid $navy-blue-300;
    }
}

.border-xl-blue {
    @include breakpoint($xl) {
        border: 2px solid $light-purple-100;

        @include dark {
            border: 2px solid $navy-blue-300;
        }
    }
}

.border-b-subtle {
    border-bottom: 1px solid rgb(255 255 255 / 30%);
}

.border-purple {
    border: 2px solid $light-purple-100;

    @include dark {
        border: 2px solid $light-blue-400;
    }
}

.border-lg-purple {
    @include breakpoint($lg) {
        border: 2px solid $light-purple-100;

        @include dark {
            border: 2px solid $light-blue-400;
        }
    }
}

.border-responsive {
    border-top: 2px solid $gray-350;

    @include breakpoint($lg) {
        border-top: none;
        border-left: 2px solid $gray-350;
    }
}

.border-top-mobile {
    border-top: 1px solid $gray-350;

    @include breakpoint($lg) {
        border-top: none;
    }
}

.black-popover .popover-arrow::after {
    border-color: transparent rgb(0 0 0 / 0%) !important;
}

.black-popover {
    min-width: 200px;

    --bs-popover-max-width: auto;
    --bs-popover-border-color: transparent;
    --bs-popover-body-padding-x: 0;
    --bs-popover-body-padding-y: 0;
    --bs-popover-body-color: #fff;
    --bs-popover-bg: #222;

    @include dark {
        --bs-popover-bg: #012c3c;
    }
}

.dot-simulator-container {
    .collapsing {
        transition: none;
    }

    .accordion-button[aria-expanded="true"],
    .dot-lens-button.active {
        user-select: none;
        pointer-events: none;
        background-color: #7120ce;
    }
}

.list-disc {
    list-style-type: disc;
}

.icon-svg-container {
    max-width: 24px;

    @include breakpoint($md) {
        max-width: 35px;
    }

    svg {
        width: 100%;
    }
}

.icon-svg-container-sm {
    max-width: 15px;

    @include breakpoint($md) {
        max-width: 24px;
    }

    svg {
        width: 100%;
    }
}

.icon-container {
    max-height: 35px;
    max-width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 50%;
    transition: background-color 0.4s;
    cursor: pointer;

    &:hover {
        background-color: $light-gray-175;

        .hover-path {
            fill: $white;
        }
    }
}

.transition-fill {
    transition: fill 0.4s;
}

.icon-effect {
    &:hover {
        box-shadow: 0 2.5px 2.5px rgba(0 0 0 / 25%);

        .hover-info-icon {
            fill: $blue-600;
        }
    }
}

.copied-text {
    color: rgba(255 255 255 / 50%);
}

.hover-blue-350:hover {
    color: $blue-350;
}

.hover-blue:hover {
    color: $blue-500;
    text-decoration: underline;

    @include dark {
        color: $light-blue-400;
    }
}

.mz-1 {
    z-index: -1;
}

.z-10 {
    z-index: 10;
}

.flex-centered {
    align-items: center;
    justify-content: center;
}

.text-placeholder::placeholder {
    color: #c1c1c1;
}

.border-y {
    border-top: 1px solid $gray-400;
    border-bottom: 1px solid $gray-400;
}

.border-b {
    border-bottom: 1px solid $gray-400;
}

.border-dark-gray-450 {
    border-color: $dark-gray-450;
}

.rotate-180 {
    transform: rotate(180deg);
}

.expand-rotate {
    .accordion-chevron-icon svg {
        transition: transform 0.4s ease;
    }

    .display-more {
        display: block;
    }

    .display-less {
        display: none;
    }

    .read-container {
        display: inline-flex;
        opacity: 1;
        transition: opacity 0.4s ease 0.2s;
    }

    &[aria-expanded="true"] {
        .display-more {
            display: none;
        }

        .display-less {
            display: block;
        }

        .read-container {
            visibility: hidden;
            display: none;
            opacity: 0;
        }

        @include breakpoint($lg) {
            .read-container {
                visibility: visible;
                display: inline-flex;
                opacity: 1;
            }
        }
    }
}

.tab-content .active-md {
    @include breakpoint($md) {
        display: block;
    }
}

.dot-index-indicator {
    background-color: #d9d9d9;

    &.active {
        background-color: #06060680;
    }

    transition: background-color 0.4s ease;
}

.score-scroll-snap {
    @include breakpoint-range(0, $md) {
        scroll-snap-type: x mandatory;
        gap: 17px;
        scrollbar-color: rgb(0 0 0 / 0%);
        scrollbar-width: none;

        &::-webkit-scrollbar {
            width: 0;
        }
    }

    @include breakpoint($md) {
        display: block;
        max-height: 150px;
    }

    @include breakpoint($xl) {
        max-height: 210px;
    }
}

.score-snap-child {
    @include breakpoint-range(0, $md) {
        scroll-snap-stop: always;
        scroll-snap-align: center;
    }
}

.date-padding {
    padding: 5px;
    @include breakpoint($md) {
        padding: 5px 5px 5px 10px;
    }
}

@media all and (min-width: $md) {
    .score-snap-child:nth-of-type(1) {
        .label-highlight {
            background-color: $light-purple-150;
        }

        .report-link {
            visibility: hidden;
        }
    }

    .radius-left {
        border-radius: 50px 0 0 50px;
    }

    .radius-right {
        border-radius: 0 50px 50px 0;
    }
}

.table-snap-container {
    border: 1px solid #e7e7e7;

    &.responsive {
        @include breakpoint-range(0, $md) {
            overflow: hidden;
            max-width: calc(100vw - 32px);
        }
    }
}

.input-readonly {
    border: none;
    outline: none;
    font-size: 12px;
    padding: 0;
    pointer-events: none;
    color: $black;

    &.bg-arrow {
        background-image: none;
    }

    @include breakpoint($lxl) {
        font-size: 18px;
    }

    @include dark {
        color: $white;
    }
}

.meye-date-picker {
    background-color: $transparent;
    border: none;
    outline: none;
    color: rgba(0 0 0 / 20%);
    position: relative;
    cursor: text;

    &::placeholder {
        @include dark {
            color: rgba(255 255 255 / 30%);
        }
    }

    @include dark {
        color: rgba(255 255 255 / 30%);
    }

    &.input-readonly {
        color: $black;
    }
}

.row-display {
    .calendar-icon {
        display: none;
    }

    .meye-date-picker {
        width: 100%;
    }

    .row-input {
        border: none;
        padding: 0;
    }

    .date-container {
        margin-left: 0;
    }
}

.row-input {
    border: 1px solid $gray-350;
    background-color: $light-gray-450;
    border-radius: 100px;
    display: block;
    width: 100%;
    padding: 5px 15px;
    color: rgba(0 0 0 / 0%);

    &::placeholder {
        color: rgba(0 0 0 / 20%);

        @include dark {
            color: rgba(255 255 255 / 30%);
        }
    }

    @include dark {
        background-color: rgba(255 255 255 / 15%);
    }
}

.meye-date-picker.focused-input {
    color: $black;
}

.meye-date-picker.input-error {
    color: $red-400 !important;
}

.focused-inputs {
    .row-input,
    .meye-date-picker {
        background-color: transparent;
        color: $black;

        &.input-error {
            color: $red-400;
        }

        @include dark {
            color: $white;
        }
    }
}

.disabled-gray:disabled {
    background-color: $gray-350;
}

.entering {
    animation: entering 0.4s ease;
}

.exiting {
    animation: exiting 0.4s ease;
}

@keyframes entering {
    0% {
        opacity: 0;
        max-height: 0;
    }

    100% {
        opacity: 1;
        max-height: 1000px;
    }
}

@keyframes exiting {
    0% {
        opacity: 1;
        max-height: 1000px;
    }

    100% {
        opacity: 1;
        max-height: 0;
    }
}

@include number-field-without-arrows;

.circle-button {
    width: 25px;
    height: 25px;

    svg {
        transform: scale(0.7);
    }

    @include breakpoint($xlx) {
        width: 35px;
        height: 35px;

        svg {
            transform: scale(1);
        }
    }
}

.border-1 {
    border-width: 1px;
}

.border-solid {
    border: 1px solid;
}

.border-style-solid {
    border-style: solid;
}

.text-break-all {
    word-break: break-all;
}

.truncate-text {
    display: none;
    @include breakpoint($xl) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.expand .truncate-text {
    display: block;
    margin-bottom: 24px;
    @include breakpoint($xl) {
        -webkit-line-clamp: unset;
        margin-bottom: 0;
    }
}

.read-figure {
    @include breakpoint($xl) {
        height: 80px;
    }
}

.expand .figure-card-divider {
    @include breakpoint($xl) {
        flex-direction: column;
    }
}

.expand .read-figure {
    @include breakpoint($xl) {
        height: auto;
        padding-right: 0;
    }
}

.expand .figure-card-title {
    margin-bottom: 50px;

    @include breakpoint($xl) {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 0;
    }
}

.expand .text-more {
    display: none;
}

.text-less {
    display: none;
}

.expand .text-less {
    display: block;
}

.expand .expand-rotate {
    .accordion-chevron-icon svg {
        transform: rotate(180deg);
    }
}

.circle-background,
.circle-progress {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.circle-text {
    font-size: 3.5em;
    font-weight: bold;
}

.circle-text-small {
    font-size: 3em;
    font-weight: bold;
}

svg.progress-circle-svg {
    max-width: 200px;

    @include breakpoint($xl) {
        max-width: unset;
    }
}

.appearance-none {
    appearance: none;
}

.score-date-picker {
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);

    @include breakpoint($xlx) {
        right: -10px;
    }
}

.treatments-toggle {
    visibility: visible;
    max-height: 1000vh;
    opacity: 1;
    transition: max-height 0.4s, visibility 0.4s, opacity 0.8s;

    &.toggle {
        visibility: hidden;
        max-height: 0;
        opacity: 0;
    }
}

.bg-arrow {
    background-image: url("../../images/chevron.svg");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 20px);
    background-position-y: 50%;
    padding-right: 50px;
}

.object-fit-cover {
    object-fit: cover;
}

.hover-none {
    &:hover {
        color: initial;
    }
}

.invert-color-scheme {
    color-scheme: dark;

    @include dark {
        color-scheme: light;
    }
}

.tabular-nums {
    font-variant-numeric: tabular-nums;
}

.grid-layers {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.grid-layers > .grid-layer {
    grid-column: 1/2;
    grid-row: 1/2;
}

.repeater-container {
    border: 1px solid #e7e7e7;

    &.error,
    &.extra-error {
        border: 1px solid $red-400;
    }
}

.blink-border {
    animation: blink 1s;
}

@keyframes blink {
    50% {
        border-color: #897d98b5;
    }
}

.rounded-left-s20 {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.space-nowrap {
    white-space: nowrap;
}

.loader-dots {
    animation: spin 2s cubic-bezier(0.6, 0, 0.4, 1) infinite;
}

@media (max-width: $lg) {
    .d-mobile-none {
        display: none !important;
    }
}

/* ==================== Safari-only styles ==================== */
@supports (font: -apple-system-body) and (-webkit-appearance: none) {
    input[type="date"] {
        padding-right: px-to-rem(40);
    }
}

/* ==================== Safari-only styles ==================== */
