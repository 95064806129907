@use "../theme/colors" as *;
@use "../theme/breakpoints" as *;
@use "../theme/mixins" as *;

.accordion-container {
    display: flex;
    gap: 4rem;
    flex-direction: column;

    @include breakpoint($lg) {
        flex-direction: row;
    }

    & > div:nth-of-type(2) {
        flex: 1;
    }
}

.content-accordion {
    transform: translateX(5rem);
    max-height: 0;
    overflow: hidden;
    opacity: 0;

    h4 {
        font-weight: 200;
        font-size: 1.25rem;
    }

    p {
        font-size: 1rem;
        font-weight: 200;
    }
}

.content-accordion.show-accordion {
    transform: translateX(0);
    opacity: 1;
    max-height: 100%;
    transition: all 0.4s ease-in-out;
}

.content-selectors {
    top: 10px;
    align-self: flex-start;
    width: 100%;

    @include breakpoint($lg) {
        width: auto;
        position: sticky;
    }

    h4 {
        font-size: 1.875rem;
        font-weight: 200;
    }

    & > div {
        box-shadow: rgb(0 0 0 / 16%) 0 1px 4px;
        border-radius: 28px;
        padding: 1rem 2rem;

        @include dark {
            background-color: $black;
        }

        &:nth-of-type(1) {
            margin-bottom: 1rem;
        }
    }
}

.content-selector {
    ul {
        margin: 0;
        padding: 0;
    }
}

.content-selector-header {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.content-selector-topics {
    max-height: 0;
    opacity: 0;
    overflow: hidden;

    &.show-topics {
        max-height: 100%;
        opacity: 1;
        transition: all 0.4s ease-in-out;
    }
}

.content-selector-button {
    background-color: transparent;
    border: none;
    padding: 1rem;
    border-left: 2px solid rgb(199 199 199);
    margin: 0;
    outline: none;
    transition: border-left 0.4s;

    @include dark {
        color: $white;
    }

    &.show-accordion {
        border-left: 2px solid $primary;
        color: $primary;

        @include dark {
            border-left-color: $tertiary;
            color: $tertiary;
        }
    }
}

a.accordion-item {
    &:hover {
        text-decoration: none;
        color: unset;
    }

    &:active {
        color: unset;
    }
}

.content-accordion-item {
    border-bottom: 1px solid $gray-400;

    @include dark {
        border-color: $white;
    }

    .collapsable-body {
        border-top: 1px solid $light-gray-300;
        overflow: hidden;
        max-height: 0;
        opacity: 0;
        transition: all 0.4s ease-out;
        padding: 0 1rem;

        @include dark {
            border-color: rgb(255 255 255 / 20%);
        }

        &.active {
            padding: 1rem 1.25rem 1rem 1rem;
            max-height: 1000vh;
            opacity: 1;
        }
    }

    .collapsable-header {
        cursor: pointer;
        gap: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.25rem;
    }
}
