@use "../theme/colors" as *;
@use "../theme/breakpoints" as *;
@use "../theme/mixins" as *;
@use "../theme/buttons" as buttons;

.error-red {
    color: $red-400;
    max-height: 12px;

    &.fluid {
        max-height: auto;
    }

    &.fluid.invisible {
        height: 0;
    }
}

.ui-kit.ui-field {
    display: flex;
    align-items: baseline;
    gap: 0.8rem;
    border-bottom: 1px solid $gray-400;

    @include dark {
        border-bottom: 1px solid $gray-300;

        &.contained {
            border: 1px solid $blueish-gray-100;
        }
    }

    &.contained {
        border: 1px solid $blueish-gray-100;
        border-radius: 8px;
        padding: 8px 16px;
    }

    input {
        background-color: transparent;
        width: 100%;
        outline: none;
        border: none;
        font-size: 1rem;
        font-weight: 300;
        color: $black;

        @include dark {
            color: $white;
        }

        &::placeholder {
            color: $gray-400;

            @include dark {
                color: $gray-300;
            }
        }
    }

    img {
        width: auto;
        height: 18px;
    }

    &.error {
        @include input-error;
    }
}

.ui-kit.ui-checkbox {
    display: flex;
    align-items: baseline;
    gap: 0.8rem;
    font-size: 0.875rem;
    font-weight: 300;
    color: $black;

    input {
        accent-color: $primary;
    }

    a {
        color: $persian-green;

        @include dark {
            color: $tertiary;
        }
    }

    @include dark {
        color: $white;
    }
}

.ui-kit.ui-button {
    display: block;
    font-size: 0.875rem;
    font-weight: 300;
    border-radius: 2.25rem;
    padding: 1rem 5rem;
    outline: none;
    width: fit-content;
    position: relative;
    overflow: hidden;
    z-index: 5;

    @include breakpoint($xlx) {
        font-size: 1rem;
    }

    @include breakpoint($md) {
        padding: 1.25rem 7.5rem;
    }

    @include breakpoint($xlx) {
        padding: 1.25rem 7.5rem;
    }

    &.small {
        font-size: 0.875rem;
        padding: 1rem;

        @include breakpoint($md) {
            font-size: 1.125rem;
        }
    }

    &.medium {
        font-size: 0.875rem;
        padding: 1rem;

        @include breakpoint($md) {
            font-size: 1.125rem;
            padding: 1.25rem 0.25rem;
        }
    }

    &.wide {
        width: 100%;
        padding: 1.25rem;

        @include breakpoint($sm) {
            width: 100%;
            max-width: 416px;
        }
    }

    &.fs-14 {
        font-size: 0.875rem;
    }

    &.lg-large {
        @include breakpoint($lg) {
            font-size: 1.25rem;
        }
    }

    &.p-responsive {
        padding: 1rem;

        @include breakpoint($xl) {
            padding: 1.2rem 2.5rem;
        }
    }

    &:hover .button-cover {
        width: 100%;
    }

    .button-cover {
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        border-radius: inherit;
        transition: width 0.2s, background-color 0.2s;
    }

    &-solid {
        background-color: $primary;
        color: white;
        border: none;
        font-weight: 600;

        &:hover .button-cover {
            width: 100%;
            background-color: $secondary;

            @include dark {
                background-color: $tertiary;
            }
        }
    }

    &-outline {
        background-color: transparent;
        border: 2px solid $primary;
        color: $primary;
        transition: background-color 0.4s;

        @include dark {
            color: $tertiary;
        }

        &:hover {
            color: $white;
            background-color: $primary;

            & .button-cover {
                background-color: $primary;
                width: 100%;
            }
        }
    }

    @each $theme-key, $theme-value in buttons.$solid-button-themes {
        @include buttons.create-solid-button-theme(
            $theme-key,
            map-get($theme-value, "base"),
            map-get($theme-value, "cover")
        );
    }

    @each $theme-key, $theme-value in buttons.$outlined-button-themes {
        @include buttons.create-outlined-button-theme(
            $theme-key,
            map-get($theme-value, "base"),
            map-get($theme-value, "cover")
        );
    }

    @each $theme-key, $theme-value in buttons.$mixed-button-themes {
        @include buttons.create-mixed-button-theme(
            $theme-key,
            map-get($theme-value, "base"),
            map-get($theme-value, "cover")
        );
    }
}

.ui-kit.ui-select {
    font-weight: 200;
    background-color: $white;
    color: $gray-400;
    border: none;
    outline: none;
    font-size: 0.875rem;

    img {
        height: 18px;
        width: auto;
    }

    @include breakpoint($lg) {
        font-size: 1.125rem;
    }

    @include dark {
        color: $gray-300;
        background-color: $dark-blue-400;
    }
}

.ui-kit.field-help {
    font-weight: 300;
    font-size: 1rem;

    @include dark {
        color: $gray-300;
    }
}

.ui-kit.icon-link {
    position: relative;
    text-align: center;

    a,
    button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
    }

    a,
    p {
        margin: 0;
        color: $white;
    }

    button {
        background-color: $transparent;
        outline: none;
        border: none;
    }
}

.field-container {
    display: flex;
    flex-direction: column;

    @include breakpoint($lg) {
        gap: 6.25rem;
        flex-direction: row;
        margin-bottom: 3.125rem;
    }

    & > * {
        flex: 1;
        margin-bottom: 3.125rem;

        @include breakpoint($lg) {
            margin-bottom: 0;
        }
    }
}

.icon-link-tooltip {
    visibility: hidden;
    position: absolute;
    left: 55px;
    background-color: $black;
    color: $white;
    font-size: 0.68rem;
    font-weight: 200;
    border-radius: 5px;
    padding: 0.4rem 0.75rem;
    z-index: 10;

    &::before {
        content: "";
        display: block;
        position: absolute;
        left: -0.4rem;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-right: 7px solid $black;
    }

    &.tooltip-right {
        left: unset;
        right: 75px;
        top: 50%;
        transform: translateY(-50%);
        background-color: $dark-gray-700;
        width: max-content;
        max-width: 200px;

        @include dark {
            background-color: $navy-blue-700;
        }

        &::before {
            left: unset;
            right: -0.8rem;
            border-right: 7px solid transparent;
            border-left: 7px solid $dark-gray-700;

            @include dark {
                border-left: 7px solid $navy-blue-700;
            }
        }
    }
}

.wtform-field {
    &.error {
        color: $red-400;

        input,
        select {
            border-color: $red-400;
        }
    }

    .field-content {
        display: flex;
        gap: 1rem;
    }

    &.card-range {
        min-width: 40%;
        flex: 1;

        input[type="range"] {
            margin: 1.5rem 0 1rem;
        }

        @include breakpoint($lg) {
            min-width: unset;
        }

        @include number-field-without-arrows;
    }

    .top-input {
        text-align: center;
        max-width: 70px;
        padding: 0.2813rem 0.5rem;
    }

    .input-increment .top-input {
        padding: 0.2813rem 0;
    }

    .row-value {
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
            border-radius: 50px;
            background-color: $transparent;
            border: 1px solid $gray-350;

            &[type="range"] {
                border: none;
            }
        }
    }

    @include custom-range-input($primary, $primary, 15px, 15px, -6px, 3px);
}

.field-error-message {
    color: $red-400;
    font-size: 14px;

    &.under-input-error {
        margin-left: 118px;
    }
}

.row-value .input-increment {
    border: 1px solid $gray-350;
    overflow: hidden;
    border-radius: 100px;

    @include dark {
        border: 1px solid $white;
    }

    button {
        background-color: $blue-450 !important;
        padding: 0 14px;

        @include breakpoint($lg) {
            padding: 0 10px;
        }

        @include breakpoint($xl) {
            padding: 0 14px;
        }
    }

    input {
        min-width: 60px;
        border: none;
        border-radius: 0;

        @include breakpoint($lg) {
            min-width: 50px;
        }

        @include breakpoint($xl) {
            min-width: 60px;
        }
    }

    .increment-minus {
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
    }

    .increment-plus {
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
    }
}

.login-field {
    border: 1px solid $blueish-gray-100;
    border-radius: 8px;
}

.row-input.input-error,
select.input-error {
    @include input-error;
}

.date-container:not(.d-none).input-error + select {
    @include input-error;
}

.score-radio {
    accent-color: $purple-400;
    transform: scale(1.4);
}
