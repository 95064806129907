@use "../theme/breakpoints" as *;
@use "../theme/colors" as *;

$sidebar-width: 60px;
$sidebar-width-open: 100px;

@mixin breakpoint($size) {
    @media screen and (min-width: $size) {
        & {
            @content;
        }
    }
}

@mixin breakpoint-range($lower-size, $upper-size) {
    @media screen and (max-width: $upper-size) and (min-width: $lower-size) {
        & {
            @content;
        }
    }
}

@mixin toplevel-breakpoint($size) {
    @media screen and (min-width: $size) {
        @content;
    }
}

@mixin dark {
    @at-root .dark & {
        @content;
    }
}

@mixin sidebar-padding {
    padding: 0.625rem 1rem 1.5rem;

    @include breakpoint($lg) {
        padding: 3.125rem 3.125rem 0 calc(3.125rem + $sidebar-width);
    }

    @include breakpoint($xl) {
        padding: 3.125rem 3.125rem 0 calc(3.125rem + $sidebar-width);
    }
}

@mixin open-sidebar-padding {
    @include breakpoint($lg) {
        padding-left: calc(3.125rem + $sidebar-width-open) !important;
    }
}

@keyframes floating {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(0, 15px);
    }

    100% {
        transform: translate(0, -0);
    }
}

@mixin animation-top(
    $name,
    $y-before,
    $y-after,
    $time: 2s,
    $floating-time: 3s
) {
    @keyframes #{$name} {
        0% {
            top: $y-before;
        }

        100% {
            top: $y-after;
        }
    }

    & {
        animation: $name $time linear forwards,
            floating $floating-time ease-in-out infinite;
    }
}

@mixin hidden-thumb {
    appearance: none;
    background: transparent;
    opacity: 0;
    box-shadow: none;
}

@mixin custom-range-input(
    $slider-color,
    $thumb-color,
    $thumb-width,
    $thumb-height,
    $thumb-margin,
    $slider-height,
    $border-radius: 50%,
    $margin: 2rem 0 3rem 0,
    $hide-thumb: false
) {
    input[type="range"] {
        appearance: none;
        width: 100%;
        cursor: pointer;
        margin: $margin;
        border: none;
        outline: none;
        background: none;
    }

    input[type="range"]:focus {
        outline: none;
    }

    input[type="range"]::-webkit-slider-runnable-track {
        background-color: $slider-color;
        border: 0;
        border-radius: 6px;
        width: 100%;
        height: $slider-height;
        cursor: pointer;
    }

    input::-webkit-slider-thumb {
        margin-top: $thumb-margin;
        appearance: none;
        background: $thumb-color;
        width: $thumb-width;
        height: $thumb-height;
        border-radius: $border-radius;
        border: none;
        outline: none;

        @if $hide-thumb {
            @include hidden-thumb;
        }
    }

    input[type="range"]::-moz-range-track {
        background-color: $slider-color;
        border: 0;
        border-radius: 6px;
        width: 100%;
        height: $slider-height;
        cursor: pointer;
    }

    input[type="range"]::-moz-range-thumb {
        margin-top: $thumb-margin;
        appearance: none;
        background-color: $thumb-color;
        width: $thumb-width;
        height: $thumb-height;
        border-radius: $border-radius;
        border: none;
        outline: none;

        @if $hide-thumb {
            @include hidden-thumb;
        }
    }

    input[type="range"]::-ms-track {
        background-color: $slider-color;
        border: 0;
        border-radius: 6px;
        width: 100%;
        height: $slider-height;
        cursor: pointer;
    }

    input[type="range"]::-ms-thumb {
        width: $thumb-width;
        height: $thumb-height;
        background: $thumb-color;
        border-radius: $border-radius;
        cursor: pointer;
        margin-top: 0;
        border: none;
        outline: none;

        @if $hide-thumb {
            @include hidden-thumb;
        }
    }

    .diopter-label {
        background-color: $slider-color;
        width: $thumb-width;
        height: $thumb-height;
        border-radius: $border-radius;
        right: calc(50% - 30px);

        @include breakpoint($md) {
            right: calc(50% - 41.5px);
        }

        &.transition {
            transition: right 0.5s linear;
        }
    }
}

@mixin plus-minus-icon($color: $gray-400, $size: 15px, $thickness: 1px) {
    display: inline-block;
    flex-shrink: 0;
    width: $size;

    &.plus {
        height: $size;
        background: conic-gradient(
                from 90deg at $thickness $thickness,
                transparent 90deg,
                $color 0
            )
            calc(100% + $thickness/2) calc(100% + $thickness/2) /
            calc(50% + $thickness) calc(50% + $thickness);
    }

    &.minus {
        height: $thickness;
        background-color: $color;
    }
}

@mixin number-field-without-arrows {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
    }

    input[type="number"] {
        appearance: textfield;
    }
}

@mixin position($top, $right, $bottom, $left) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin pseudo-tips($width, $height, $color, $positions...) {
    content: "";
    z-index: -1;
    width: $width;
    height: $height;
    position: absolute;
    background-color: $color;
    display: block;
    border-radius: $width;
    @include position($positions...);
}

@mixin arc-range-input(
    $name,
    $track-width,
    $thumb-size,
    $max-width,
    $track-color: $primary,
    $thumb-color: $primary
) {
    $track-center: calc($thumb-size / 2 + $track-width / 2);

    .#{$name}.arc-range-input {
        min-width: 150px;
        max-width: $max-width;
        position: relative;
        height: auto;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        cursor: pointer;
        padding: 10px;

        &.arc-reverse {
            transform: scale(-1);
        }

        input[type="range"] {
            display: none;
        }

        @include breakpoint($lg) {
            min-width: 180px;
        }

        .arc-shape {
            border: $track-width solid $track-color;
            border-top: 0;
            width: 100%;
            border-radius: 0 0 50% 50% / 0 0 100% 100%;
            position: relative;
            aspect-ratio: 2/1;
            transform: scaleX(1.05);

            &::before {
                @include pseudo-tips(
                    13px,
                    3px,
                    $track-color,
                    calc(-1 * $track-width / 2),
                    unset,
                    unset,
                    calc((-13px / 2) - ($track-width / 2))
                );
            }

            &::after {
                @include pseudo-tips(
                    13px,
                    3px,
                    $track-color,
                    calc(-1 * $track-width/ 2),
                    calc((-13px / 2) - ($track-width / 2)),
                    unset,
                    unset
                );
            }

            .arc-pointer {
                border-radius: 50%;
                width: 100%;
                height: 200%;
                position: absolute;
                bottom: 0;
                cursor: pointer;
                transition: transform 0.03s linear 0s;
                clip-path: inset(50% 0 -20px 0);

                &::after {
                    content: "";
                    width: $thumb-size;
                    height: $thumb-size;
                    position: absolute;
                    bottom: -$track-center;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: $thumb-color;
                    display: block;
                    border-radius: 50%;
                    cursor: pointer;
                }
            }
        }
    }

    .#{$name}-arc-range-max-width {
        max-width: $max-width;
        margin-left: $track-width * 2;
    }
}

@mixin input-error {
    color: $red-400;
    border-color: $red-400;

    input,
    select {
        border-color: $red-400;

        &::placeholder {
            color: $red-400;
        }
    }

    select {
        color: $red-400;
    }
}

@each $color-key, $color-value in $colors-map {
    .bgc-#{$color-key} {
        background-color: $color-value;
    }

    .color-#{$color-key} {
        color: $color-value;
    }

    .border-#{$color-key} {
        border-color: $color-value;
    }

    .bgc-dark-#{$color-key} {
        @include dark {
            background-color: $color-value;
        }
    }

    .border-dark-#{$color-key} {
        border-color: $color-value;
    }

    .color-dark-#{$color-key} {
        @include dark {
            color: $color-value;
        }
    }
}
