@use "../theme/colors" as colors;
@use "../theme/breakpoints" as bp;
@use "../theme/mixins" as mx;

@mixin svg-active($hover: false) {
    svg .active-stroke {
        transition: stroke 0.4s;
        stroke: colors.$blue-500;

        @include mx.dark {
            @if $hover {
                stroke: colors.$navy-blue-800;
            } @else {
                stroke: colors.$navy-blue-100;
            }
        }
    }

    svg .active-fill {
        transition: fill 0.4s;
        fill: colors.$blue-500;

        @include mx.dark {
            @if $hover {
                fill: colors.$navy-blue-800;
            } @else {
                fill: colors.$navy-blue-100;
            }
        }
    }
}

.dark-gradient-body {
    @include mx.dark {
        background-color: colors.$navy-blue-800;
        background-image: linear-gradient(
            colors.$navy-blue-800,
            colors.$black-900
        );
        background-size: cover;
        background-attachment: fixed;
    }
}

#meye-guide-page,
#meye-gauge-page,
#meye-score-page {
    position: relative;

    .flex-field {
        flex-direction: column;
        flex: 1;

        @include mx.breakpoint(bp.$xs) {
            flex-direction: row;
        }

        label {
            min-width: 110px;
            font-weight: 200;
        }
    }

    .field-border {
        border-bottom: 1px solid colors.$gray-400;
    }

    .field-border-error {
        border-color: colors.$red-400;

        input::placeholder {
            color: colors.$red-400;
        }
    }

    .kr-container > .card-range {
        display: none;

        &.show {
            display: block;
        }
    }

    #current-age {
        overflow: auto;
        white-space: nowrap;
        color: colors.$gray-400;
    }

    #current-age,
    #current-age-months {
        display: none;
    }

    .axial-length-button {
        display: flex;
        align-items: center;
        text-align: left;
        border: none;
        background-color: inherit;

        .accordion-chevron-icon {
            margin-left: 15px;

            svg {
                width: 20px;
            }
        }
    }

    .axial-length-container {
        border-radius: 0 0 20px 20px;

        button {
            border: none;
            background-color: inherit;
        }

        @include mx.breakpoint(bp.$lg) {
            border-radius: 0 20px 20px 0;
        }

        .badge {
            background-color: colors.$blue-450;
            color: colors.$white;
        }
    }

    .axial-range-field {
        @include mx.breakpoint(bp.$sm) {
            flex: 1;
        }
    }

    .axial-length-item {
        flex: 0.7;
    }

    .card-wrapper {
        border-radius: 20px;
    }

    .patient-input-row {
        gap: 2rem;

        @include mx.breakpoint(bp.$xl) {
            gap: 3rem;
        }
    }

    svg .al-shape path,
    svg .ruler-shape text {
        fill: colors.$black;
    }

    svg .al-shape text {
        font-weight: bold;
    }

    svg line {
        fill: none;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-width: 1px;
    }

    svg.balance g.circle-untreated > text,
    svg.balance g.circle-treated > text {
        font-size: 3.4rem;
    }

    svg.balance g.transform-untreated {
        transform: scale(1.5) translateX(-8px);

        @include mx.breakpoint(bp.$xs) {
            transform: scale(1.25) translateX(-8px);
        }

        @include mx.breakpoint(bp.$sm) {
            transform: none;
        }
    }

    svg.balance g.transform-treated {
        transform: scale(1.5) translateX(-210px);

        @include mx.breakpoint(bp.$xs) {
            transform: scale(1.25) translateX(-120px);
        }

        @include mx.breakpoint(bp.$sm) {
            transform: none;
        }
    }

    .simulator-tooltip:nth-child(odd) {
        background-color: colors.$dark-gray-700;
        color: colors.$white;
    }

    .simulator-images-container {
        .handle {
            width: 30px;
            margin-left: -15px;

            @include mx.breakpoint(bp.$md) {
                width: 60px;
                margin-left: -30px;
            }

            .controller {
                width: 30px;
                height: 30px;

                .controller-icon {
                    width: 12px;
                    aspect-ratio: 4/5;
                    mask-image: url("https://resources.ocumetra.com/images/icons/double_chevron.svg");
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-size: contain;
                    background-color: colors.$white;

                    @include mx.breakpoint(bp.$md) {
                        width: 25px;
                    }
                }

                .control-arrow {
                    display: none;
                }

                @include mx.breakpoint(bp.$md) {
                    width: 60px;
                    height: 60px;
                }

                &::before {
                    bottom: calc(50% + 2px);
                    margin-bottom: 12.5px;

                    @include mx.breakpoint(bp.$md) {
                        bottom: calc(50% + 3px);
                        margin-bottom: 25px;
                    }
                }

                &::after {
                    top: calc(50% + 2px);
                    margin-top: 12.5px;

                    @include mx.breakpoint(bp.$md) {
                        top: calc(50% + 3px);
                        margin-top: 25px;
                    }
                }
            }
        }
    }

    .sharing-options-item svg line {
        stroke: #fff;
    }

    .header-actions {
        @include mx.breakpoint(bp.$sm) {
            right: 16px;
        }

        @include mx.breakpoint(bp.$lg) {
            right: 50px;
        }
    }

    @include mx.arc-range-input("meye-form", 3px, 13px, 262px);
}

#meye-guide-page {
    @include mx.custom-range-input(
        colors.$gray-250,
        colors.$blue-400,
        20px,
        20px,
        -7px,
        6px,
        $margin: 0.625rem 0 10px 0
    );
    @include mx.arc-range-input(
        "meye-form",
        3px,
        13px,
        220px,
        colors.$gray-250,
        colors.$blue-400
    );
}

.icon-line-blue {
    line {
        fill: none;
        stroke: colors.$light-purple-100;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-width: 2px;

        @include mx.dark {
            stroke: colors.$navy-blue-700;
        }
    }
}

.report-link,
.report-link:hover {
    color: colors.$blue-500;

    @include mx.dark {
        color: colors.$light-green-400;
    }
}

.report-logo {
    max-width: 206px;

    @include mx.breakpoint(bp.$md) {
        max-width: 363px;
    }
}

.report-data-container {
    border: 1px solid colors.$gray-200;
    @include mx.dark {
        border: 1px solid colors.$white-800;
    }
}

.accordion-chevron-icon {
    transition: transform 0.4s;
}

.accordion-button {
    border: 3px solid colors.$light-purple-100;
    transition: all 0.4s;

    @include mx.dark {
        border: 3px solid transparent;
        box-shadow: 0 0 10 0 rgba(69 142 225 / 100%);
    }

    &:hover {
        border: 3px solid transparent;
        background-color: colors.$white;
        box-shadow: 0 0 10 10 rgba(230 230 255 / 50%);

        @include mx.dark {
            border: 3px solid colors.$navy-blue-500;
            background-color: colors.$white-800;
            box-shadow: 0 0 10 0 rgba(69 142 225 / 100%);

            .accordion-button-hover {
                color: colors.$black-900;
            }

            .dark-stroke-white:not(.white-hover) {
                stroke: colors.$black-900;
            }
        }

        @include svg-active(true);
    }

    &[aria-expanded="true"] {
        border: 3px solid transparent;
        background-color: colors.$white;

        @include mx.dark {
            background-color: colors.$dark-blue-400;
        }

        .accordion-chevron-icon {
            transform: rotate(180deg);
        }

        @include svg-active;
    }
}

.show-wrapper {
    border: 2px solid colors.$light-purple-100;
    box-shadow: 0 0 10 10 rgba(230 230 255 / 50%);

    @include mx.dark {
        box-shadow: 0 0 10 0 rgba(230 230 255 / 50%);
    }

    .accordion-button {
        border: 3px solid transparent;
        background-color: colors.$white;

        @include mx.dark {
            background-color: colors.$transparent;
            border: 3px solid colors.$transparent;
            box-shadow: none;

            &:hover {
                background-color: colors.$white-800;
            }
        }

        &[aria-expanded="true"] {
            .accordion-chevron-icon {
                transform: rotate(180deg);
            }
        }

        @include svg-active;

        &:hover {
            @include svg-active(true);
        }
    }

    @include mx.dark {
        border: 2px solid colors.$white-800;
    }
}

.eye-chart-button.active {
    display: none;
}

.report-footer {
    border-top: 2px solid colors.$light-gray-350;
}

.report-wrapper {
    background-color: white;
    border-radius: 25px;

    @include mx.dark {
        background-color: colors.$transparent;
    }
}

.report-section {
    background-color: transparent;
    padding: 10px;

    @include mx.breakpoint(bp.$lg) {
        max-width: 1070px;
        width: min(1070px, 85%);
        margin-left: auto;
        margin-right: auto;
        padding: 30px 0;
    }
}

.report-text-section {
    background-color: transparent;
    padding: 5px 24px 24px;

    @include mx.breakpoint(bp.$lg) {
        margin-left: auto;
        margin-right: auto;
        padding: 5px 80px;
    }
}

.bg-purple-navy {
    background-color: colors.$light-purple-100;

    @include mx.dark {
        background-color: colors.$navy-blue-700;
    }
}

.bg-dark-gray {
    background-color: colors.$dark-gray-100;
}

.grey-border {
    box-shadow: 0 10 10px rgb(0 0 0 / 10%);

    @include mx.dark {
        box-shadow: 0 0 5px 0 colors.$light-blue-400;
    }
}

.gray-border-bottom {
    border-bottom: 2px solid colors.$light-gray-100;

    @include mx.dark {
        border-bottom: 2px solid colors.$light-green-400;
    }
}

.u-border {
    border: 2px solid colors.$light-purple-100;
    border-top: none;
    border-radius: 0 0 15px 15px;

    @include mx.dark {
        border: 2px solid colors.$white-800;
    }

    @include mx.breakpoint(bp.$md) {
        border: 2px solid colors.$light-purple-100;
        border-left: none;
        border-radius: 0 15px 15px 0;

        @include mx.dark {
            border: 2px solid colors.$white-800;
            border-left: none;
        }
    }
}

.border-left {
    border: 2px solid colors.$transparent;
    border-radius: 15px;

    @include mx.breakpoint(bp.$lg) {
        border-right: 0;
    }

    @include mx.dark {
        border: 2px solid colors.$white-800;

        @include mx.breakpoint(bp.$lg) {
            border-right: 0;
        }
    }
}

.report-toggle-container {
    max-width: 381px;
    border-radius: 100px;

    @include mx.dark {
        box-shadow: 0 0 10px 1px colors.$teal-675;
    }

    @include mx.breakpoint(bp.$md) {
        margin-left: auto;
        margin-right: auto;
    }
}

.report-image-container {
    @include mx.breakpoint(bp.$lg) {
        max-width: 50%;
    }
}

#meye-gauge-page {
    @include mx.custom-range-input(
        colors.$gray-250,
        colors.$pink-500,
        20px,
        20px,
        -7px,
        6px,
        $margin: 2rem 0 15px 0
    );

    @include mx.arc-range-input(
        "meye-form",
        6px,
        20px,
        262px,
        colors.$gray-250,
        colors.$pink-500
    );
}

.flex-divider {
    background-color: colors.$gray-350;
    flex: 0 0 2px;
    height: 50px;
}

.drop-shadow-blue {
    backdrop-filter: blur(0);
    filter: drop-shadow(0 0 35px rgb(119 143 239 / 75.6%));
}

.qrcode-container {
    width: 100%;
    max-width: 288px;
    border-radius: inherit;
}

.qrcode-image {
    width: 100%;
    max-width: 24em;
    border-radius: 15px;
}

.gray-popover {
    font-family: Montserrat, Arial, sans-serif;
    background-color: colors.$light-gray-100;
    max-width: calc(50% - 20px);
}

.popover .popover-arrow::after {
    border-color: colors.$light-gray-100 transparent colors.$light-gray-100
        transparent !important;
}

.popover-left {
    &.popover .popover-arrow::after {
        border-color: transparent colors.$light-gray-100 transparent
            colors.$light-gray-100 !important;
    }
}

.pill-badge {
    background-color: colors.$light-purple-100;
    transform: translateY(-50%);
}

.pill-badge-height {
    height: calc(100% - 30px);
}

.chart-wrapper {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
}

.report-chart-container {
    svg {
        width: 100%;
        height: auto;
        border-radius: inherit;
    }
}

#collapsible-content {
    width: 100%;
    margin-bottom: 20px;
}

.card-collapsible {
    background-color: var(--color-bg-card);
    border-radius: 19px;
}

.box-container {
    display: flex;
    justify-content: space-between;
    color: var(--color-title);
    width: 100%;
    padding: 15px;
    margin-top: 15px;
}

.card-collapse {
    display: none;
    padding-bottom: 25px;
}

.header-box {
    align-items: center;
}

.img-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin: 0 3%;
}

.img-header img {
    width: 100%;
}

.txt-header {
    width: 100%;
}

.txt-header h2 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 25px;
    margin: 0;
}

.txt-header p {
    font-size: 34px;
    font-weight: 200;
    margin-bottom: 0;
    max-height: 100px;
    margin-top: 5px;
    overflow: hidden;
}

.disclaimer-options {
    color: var(--color-title);
    margin: 0 60px 30px;
}

.disclaimer-options h2 {
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 20px;
}

.disclaimer-options p {
    font-size: 25px;
    font-weight: 300;
}

.decimal-list-item {
    list-style-type: decimal;
}

.disclaimer-options .decimal-list-item-text {
    font-size: 1.1rem;
}

:is(ul, ol) {
    &.list-pl-0 {
        padding-left: 0;
    }

    &.list-pl-1 {
        padding-left: 1rem;
    }
}

.card-option-collapsible {
    width: 100%;
    background-color: #e0e0e0;
    background-position: top;
    cursor: pointer;
}

.ad-card-control .ad-list {
    height: auto;
}

.margin-container {
    height: 200px;
}

.info-container {
    background-color: colors.$light-gray-100;
    border-radius: 20px 20px 0 0;
}

.info-container .mco-title {
    padding: 10px 20px;
    font-size: 20px;
    color: colors.$blue-500;

    @include mx.breakpoint(bp.$md) {
        font-size: 25px;
    }
}

.read-more-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--color-txt);
    font-size: 14px;
    font-weight: bold;
    border-top: 1px solid #3b50656a;
    padding: 10px 20px;

    @include mx.breakpoint(bp.$md) {
        font-size: 20px;
    }
}

.read-more-container .arrow {
    border-color: var(--color-txt);
    margin: 10px;
    transform: rotate(315deg);

    &.arrow-dark {
        @include mx.dark {
            border-color: colors.$white;
        }
    }
}

.available-treatments-container .show .read-more-container .arrow {
    transform: rotate(225deg);
}

.available-treatments-container .read-less {
    display: none;
}

.available-treatments-container .show .read-less {
    display: block;
}

.available-treatments-container .show .read-more {
    display: none;
}

.body-read-more {
    max-height: 0;
    overflow: hidden;
    transition: 0.5s;
}

.available-treatments-container .show.body-read-more {
    max-height: 5000px;
}

.text-container {
    background-color: #fff;
    color: var(--color-txt);
    padding: 20px;
    border-radius: 20px 20px 0 0;
    font-size: 12px;

    @include mx.breakpoint(bp.$md) {
        font-size: 16px;
    }
}

.pros-cons-container {
    display: flex;
}

.pros-cons-container .pros-cons:first-child {
    margin-right: 100px;
}

.text-container p {
    margin-bottom: 20px;
}

.pros-cons ul {
    margin-bottom: 20px;
    padding: 0;
}

.pros-cons li {
    list-style-type: none;
}

.pros-cons span {
    margin-bottom: 15px;
    display: block;
}

.mco-option-img {
    background-color: colors.$light-gray-100;
    background-repeat: no-repeat;
    background-position: top;

    @include mx.breakpoint(bp.$xxl) {
        background-size: 100%, 200px, cover;
    }
}

#mco1-option1-img {
    background-image: url("https://resources.ocumetra.com/images/pictures/spectacles.webp");
}

#mco1-option2-img {
    background-image: url("https://resources.ocumetra.com/images/pictures/daytime_contact_lenses.webp");
}

#mco1-option3-img {
    background-image: url("https://resources.ocumetra.com/images/pictures/overnight_contact_lenses.webp");
}

#mco1-option4-img {
    background-image: url("https://resources.ocumetra.com/images/pictures/atropine_drops.webp");
}

#mco2-option1-img {
    background-image: url("https://resources.ocumetra.com/images/pictures/outdoor_time.webp");
}

#mco2-option2-img {
    background-image: url("https://resources.ocumetra.com/images/pictures/optimise_near_work.webp");
}

#mco2-option3-img {
    background-image: url("https://resources.ocumetra.com/images/pictures/limit_screen_time.webp");
}

#mco2-option4-img {
    background-image: url("https://resources.ocumetra.com/images/pictures/regular_eye_checks.webp");
}

.guidelines-screen-time-container h2 {
    font-size: 18px;
    color: #3a5067;
    margin-bottom: 20px;

    @include mx.breakpoint(bp.$md) {
        font-size: 23px;
    }
}

.guidelines-screen-time-container a {
    color: #549a85;
}

.children-age-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.children-age-container span {
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    color: #3a5067;
    font-weight: bold;

    @include mx.breakpoint(bp.$md) {
        font-size: 15px;
    }
}

.children-age-container h2 {
    font-size: 16px;
    font-weight: bold;
    color: var(--color-title-card);
    margin: 0;

    @include mx.breakpoint(bp.$md) {
        font-size: min(calc(15px + 0.8vw), 25px);
    }
}

.children-age-container div:first-child {
    width: 25%;
}

.children-age-container div:nth-child(2) {
    width: 70%;
}

.pill-purple-subtle {
    background-color: colors.$light-purple-100;
}

.pill-purple {
    background-color: colors.$light-purple-400;
}

.morph-card-label {
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;

    @include mx.breakpoint(bp.$md) {
        border-radius: 100px;
        margin-bottom: -50px;
        transform: translateY(-50%);
        width: 100%;
        max-width: 54%;
        min-width: fit-content;
    }

    @include mx.breakpoint(bp.$lg) {
        max-width: 64%;
    }
}

.morph-border {
    border-bottom: 2px solid colors.$gray-350;

    @include mx.breakpoint(bp.$md) {
        border-bottom: 0;
        border-right: 2px solid colors.$gray-350;
    }
}

.estimate-container {
    position: relative;
}

.floating-tip {
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 60px;
    box-shadow: 0 3px 6px rgba(0 0 0 / 25%);
    border-radius: 50%;
}

.axial-tooltip {
    background-color: rgba(0 0 0 / 70%);
    color: colors.$white;
    backdrop-filter: blur(10px);
}

.subtle-label {
    background-color: colors.$light-purple-100;
    color: colors.$black;
}

.sharing-options {
    position: fixed;
    background-color: colors.$dark-gray-700;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    right: 0;
    bottom: 20px;
    z-index: 999;
    cursor: pointer;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
        border-radius: 15px;

        input[name="sharing-options-input"] {
            visibility: hidden;
            height: 0;
        }

        input[name="sharing-options-input"]:checked {
            & + .sharing-options-list {
                max-height: 100vh;
                padding-top: 30px;
            }

            & + .sharing-options-list > .sharing-options-trigger {
                max-height: 0;
                visibility: hidden;
                opacity: 0;
                padding: 0;
            }

            & + .sharing-options-list > .sharing-options-item {
                height: 33px;
                opacity: 1;
                margin-bottom: 40px;
                z-index: auto;
            }
        }
    }

    @include mx.breakpoint(bp.$lg) {
        position: sticky;
        transform: translate(50px, 0);
        top: 60px;
    }
}

.sidebar-layout .sharing-options {
    right: 10px;
    bottom: 85px;
}

.sharing-options-trigger {
    max-height: 100vh;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s;
    padding: 15px;
}

.icon-wrapper.icon-visible:hover .icon-link-tooltip {
    visibility: visible;
}

.sharing-options-item {
    height: 0;
    opacity: 0;
    transition: opacity 0.4s, height 0.4s, margin-bottom 0.4s;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: -1;

    @include mx.breakpoint(bp.$lg) {
        height: auto;
        z-index: auto;
        opacity: 1;
        transition: background-color 0.4s;

        &:hover {
            background-color: colors.$dark-gray-500;

            @include mx.dark {
                background-color: colors.$navy-blue-600;
            }
        }
    }

    &.last-option-item {
        transition: opacity 0.2s;
    }
}

.sharing-options-list {
    max-height: 200px;
    transition: max-height 0.4s;

    @include mx.breakpoint(bp.$lg) {
        max-height: unset;
    }
}

.report-help-container {
    max-width: 450px;

    @include mx.breakpoint(bp.$lg) {
        max-width: 1070px;
    }
}

.disabled-measured {
    filter: grayscale(1);
}

.disabled-estimated {
    filter: grayscale(1);
    pointer-events: none;
}

.meye-tool-logo {
    width: 220px;

    @include mx.breakpoint(bp.$md) {
        width: auto;
    }
}

.container-dob {
    visibility: hidden;
    z-index: 5;
    min-height: 40px;
}

.neon-shadow {
    @include mx.dark {
        box-shadow: 0 0 5px 0 colors.$light-blue-400;
    }
}

.centile-chart-dark {
    @include mx.dark {
        svg {
            use {
                fill: colors.$white;
            }

            /* stylelint-disable */
            g#patch_1 > path,
            g#patch_2 > path {
                fill: colors.$transparent !important;
            }

            g#patch_3 > path,
            g#patch_4 > path {
                stroke: colors.$white !important;
            }
        }
    }
}

.qr-modal-dialog {
    max-width: 500px;
}

.eye-toggle-basis {
    flex-basis: 385px;
}

.chart-border {
    border: 2px solid colors.$light-purple-100;

    @include mx.dark {
        border: 2px solid colors.$white-800;
    }
}

.report-section-container {
    max-width: 1070px;
}

.centile-chart-container > svg {
    width: 100%;
    height: auto;
    border-radius: inherit;
}

.accordion-item-body.collapsing {
    transition: none;
    display: none;
}

.invalid-dob {
    margin-top: -13.5px;
}

.report-button-container {
    max-width: 300px;
}

.fs-eye-data {
    font-size: 14px;

    @include mx.breakpoint(bp.$md) {
        font-size: 20px;
    }
}

.report-disclaimer-text {
    font-size: 12px;

    @include mx.breakpoint(bp.$md) {
        font-size: 16px;
    }
}

.fs-eye-title {
    font-size: 14px;

    @include mx.breakpoint(bp.$md) {
        font-size: 22px;
    }
}

@include mx.toplevel-breakpoint(bp.$lg) {
    .fs-eye-data {
        font-size: 16px;
    }

    .fs-eye-title {
        font-size: 18px;
    }

    .report-disclaimer-text {
        font-size: 12px;
    }
}

@include mx.toplevel-breakpoint(bp.$xl) {
    .fs-eye-data {
        font-size: 20px;
    }

    .fs-eye-title {
        font-size: 22px;
    }

    .report-disclaimer-text {
        font-size: 16px;
    }
}

.report-button-height {
    height: 100%;
    max-height: 70px;
}

.score-table-border {
    @include mx.breakpoint-range(0, bp.$md) {
        border-bottom: 1px solid #e2e5f1;
    }
}

.score-table-border-md {
    @include mx.breakpoint(bp.$md) {
        border-bottom: 1px solid #e2e5f1;
    }
}

.score-cell-sm {
    @include mx.breakpoint(bp.$md) {
        max-width: 16%;
    }
}

.score-cell-md {
    @include mx.breakpoint(bp.$md) {
        max-width: 24%;
    }
}

.score-cell-lg {
    @include mx.breakpoint(bp.$md) {
        max-width: 28%;
    }
}

.score-chart-logo {
    max-width: 84px;

    @include mx.breakpoint(bp.$xl) {
        max-width: 129px;
    }
}
