@use "../theme/colors" as *;
@use "../theme/breakpoints" as *;
@use "../theme/mixins" as *;

.ui-kit.ui-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 1.5rem 1rem;
    gap: 0.5rem;

    @include breakpoint($sm) {
        align-items: center;
        padding: 3.125rem 3.125rem 2rem;
    }

    &.register-header {
        width: 100%;
        margin: 0 auto;
        max-width: 1296px;
        padding: 1.875rem 0 0;

        @include breakpoint($xlx) {
            max-width: unset;
        }
    }

    .footer-logo {
        max-width: 180px;
        height: auto;
    }
    @media (max-width: $md) {
        .footer-logo {
            max-width: 120px;
        }
    }

    @media (max-width: $sm) {
        .footer-logo {
            max-width: 100px;
        }
    }

    a > img {
        max-width: 210px;

        @include breakpoint($sm) {
            max-width: 100%;
        }

        @include dark {
            filter: brightness(0) invert(1);
        }
    }

    .left-header {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: 1;

        @include breakpoint($sm) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }

    .header-actions {
        display: flex;
        align-items: center;
        gap: 1rem;
        z-index: 1000;
        position: absolute;
        top: 60px;

        &.top-unset {
            top: unset;
        }

        @include breakpoint($lg) {
            right: 50px;
        }
    }

    .auth-cta {
        display: flex;
        align-items: baseline;
        gap: 1rem;

        @include breakpoint($lg) {
            gap: 1.875rem;
        }

        a {
            background-color: $transparent;
            border: 1px solid $persian-green;
            color: $black;
            padding: 16px 44px;
            font-size: 1rem;
            line-height: 1rem;
            font-weight: 600;
            border-radius: 100px;
            text-align: center;

            @include dark {
                color: $white;
            }
        }
    }

    .header-sponsor-logo {
        max-width: 210px;

        @include breakpoint($lg) {
            max-width: 305px;
        }
    }

    &.with-banner {
        padding-top: 50px !important;
    }
}

.sidebar-layout .ui-kit.ui-header {
    @include sidebar-padding;
}
