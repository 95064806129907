@use "../theme/colors" as *;
@use "../theme/breakpoints" as *;
@use "../theme/mixins" as *;

#register-page,
#profile-page {
    color: $black;

    @include dark {
        color: $white;
    }

    .main-content select {
        font-size: 0.875rem;
        border-bottom: 1px solid $gray-400;
        text-align: left;

        @include dark {
            border-color: $gray-300;
        }
    }

    .custom-field {
        display: flex;
        align-items: baseline;
        gap: 0.5rem;
        border-bottom: 1px solid $gray-400;

        &.contained {
            border: 1px solid $blueish-gray-100;
            border-radius: 8px;
            padding: 8px 16px;
        }

        img {
            height: 18px;
            width: auto;
        }

        select,
        input {
            font-size: 1rem;
            width: 100%;
            border: none;
            padding: 2.75px 0;
        }

        &.no-margin {
            margin-bottom: 0;
        }

        &.error {
            @include input-error;
        }
    }

    .current-email {
        display: inline-flex;
        align-items: center;
        gap: 1.3rem;
        margin-bottom: 3.25rem;

        p {
            margin: 0;
        }
    }

    .help-container {
        @include breakpoint($xl) {
            min-height: 150px;
        }
    }
}

.ui-kit.ui-header .header-actions {
    &.register-actions {
        position: static;
        padding-left: 20px;
    }
}

.top-banner {
    z-index: 2000;

    .environment-container {
        background-color: $blue-350;
        height: 40px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 0 0 0 30px;
        display: flex;
        align-items: center;

        p {
            color: white;
            font-weight: 700;
        }
    }
}

.auth-container {
    width: 100%;
    margin: 0 auto;
    max-width: 1296px;
}
