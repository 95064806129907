@use "../theme/colors" as clr;
@use "../theme/breakpoints" as bp;
@use "../theme/mixins" as mx;

.autocomplete-list {
    overflow: hidden;
    padding: 0;
}

.autocomplete-list > li {
    overflow: hidden;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 12px;
    border: 1px solid clr.$gray-350;
    border-top: 0;

    @include mx.breakpoint(bp.$xl) {
        font-size: 14px;
    }

    @include mx.breakpoint(bp.$xlx) {
        font-size: 18px;
    }

    &:last-of-type {
        border-radius: 0 0 10px 10px;
    }
}

.autocomplete-list li,
.autocomplete-list:hover li,
.autocomplete-list li:hover ~ .focused {
    background-color: clr.$white;

    @include mx.dark {
        background-color: clr.$black;
    }
}

.autocomplete-list .focused,
.autocomplete-list li:hover {
    background-color: clr.$light-purple-200;

    @include mx.dark {
        background-color: clr.$light-purple-200;
        color: clr.$black;
    }
}

.autocomplete-input {
    border: 1px solid clr.$gray-350;

    &.input-error {
        border: 1px solid clr.$red-400;

        &::placeholder {
            color: clr.$red-400;
        }
    }

    &::placeholder {
        color: clr.$black;

        @include mx.dark {
            color: clr.$white;
        }
    }
}
