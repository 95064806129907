@-webkit-keyframes floating {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  50% {
    -webkit-transform: translate(0, 15px);
    transform: translate(0, 15px);
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes floating {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  50% {
    -webkit-transform: translate(0, 15px);
    transform: translate(0, 15px);
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.bgc-primary {
  background-color: #00725c;
}

.color-primary {
  color: #00725c;
}

.border-primary {
  border-color: #00725c;
}

.dark .bgc-dark-primary {
  background-color: #00725c;
}

.border-dark-primary {
  border-color: #00725c;
}

.dark .color-dark-primary {
  color: #00725c;
}

.bgc-secondary {
  background-color: #005252;
}

.color-secondary {
  color: #005252;
}

.border-secondary {
  border-color: #005252;
}

.dark .bgc-dark-secondary {
  background-color: #005252;
}

.border-dark-secondary {
  border-color: #005252;
}

.dark .color-dark-secondary {
  color: #005252;
}

.bgc-tertiary {
  background-color: #01a998;
}

.color-tertiary {
  color: #01a998;
}

.border-tertiary {
  border-color: #01a998;
}

.dark .bgc-dark-tertiary {
  background-color: #01a998;
}

.border-dark-tertiary {
  border-color: #01a998;
}

.dark .color-dark-tertiary {
  color: #01a998;
}

.bgc-warning-400 {
  background-color: #e9d502;
}

.color-warning-400 {
  color: #e9d502;
}

.border-warning-400 {
  border-color: #e9d502;
}

.dark .bgc-dark-warning-400 {
  background-color: #e9d502;
}

.border-dark-warning-400 {
  border-color: #e9d502;
}

.dark .color-dark-warning-400 {
  color: #e9d502;
}

.bgc-warning-500 {
  background-color: #af8d00;
}

.color-warning-500 {
  color: #af8d00;
}

.border-warning-500 {
  border-color: #af8d00;
}

.dark .bgc-dark-warning-500 {
  background-color: #af8d00;
}

.border-dark-warning-500 {
  border-color: #af8d00;
}

.dark .color-dark-warning-500 {
  color: #af8d00;
}

.bgc-success-400 {
  background-color: #4bb543;
}

.color-success-400 {
  color: #4bb543;
}

.border-success-400 {
  border-color: #4bb543;
}

.dark .bgc-dark-success-400 {
  background-color: #4bb543;
}

.border-dark-success-400 {
  border-color: #4bb543;
}

.dark .color-dark-success-400 {
  color: #4bb543;
}

.bgc-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.color-transparent {
  color: rgba(0, 0, 0, 0);
}

.border-transparent {
  border-color: rgba(0, 0, 0, 0);
}

.dark .bgc-dark-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.border-dark-transparent {
  border-color: rgba(0, 0, 0, 0);
}

.dark .color-dark-transparent {
  color: rgba(0, 0, 0, 0);
}

.bgc-white {
  background-color: #fff;
}

.color-white {
  color: #fff;
}

.border-white {
  border-color: #fff;
}

.dark .bgc-dark-white {
  background-color: #fff;
}

.border-dark-white {
  border-color: #fff;
}

.dark .color-dark-white {
  color: #fff;
}

.bgc-white-250 {
  background-color: #fdfdfd;
}

.color-white-250 {
  color: #fdfdfd;
}

.border-white-250 {
  border-color: #fdfdfd;
}

.dark .bgc-dark-white-250 {
  background-color: #fdfdfd;
}

.border-dark-white-250 {
  border-color: #fdfdfd;
}

.dark .color-dark-white-250 {
  color: #fdfdfd;
}

.bgc-white-900 {
  background-color: #e6e6e8;
}

.color-white-900 {
  color: #e6e6e8;
}

.border-white-900 {
  border-color: #e6e6e8;
}

.dark .bgc-dark-white-900 {
  background-color: #e6e6e8;
}

.border-dark-white-900 {
  border-color: #e6e6e8;
}

.dark .color-dark-white-900 {
  color: #e6e6e8;
}

.bgc-white-alpha-10 {
  background-color: rgba(255, 255, 255, .1);
}

.color-white-alpha-10 {
  color: rgba(255, 255, 255, .1);
}

.border-white-alpha-10 {
  border-color: rgba(255, 255, 255, .1);
}

.dark .bgc-dark-white-alpha-10 {
  background-color: rgba(255, 255, 255, .1);
}

.border-dark-white-alpha-10 {
  border-color: rgba(255, 255, 255, .1);
}

.dark .color-dark-white-alpha-10 {
  color: rgba(255, 255, 255, .1);
}

.bgc-white-alpha-20 {
  background-color: rgba(255, 255, 255, .2);
}

.color-white-alpha-20 {
  color: rgba(255, 255, 255, .2);
}

.border-white-alpha-20 {
  border-color: rgba(255, 255, 255, .2);
}

.dark .bgc-dark-white-alpha-20 {
  background-color: rgba(255, 255, 255, .2);
}

.border-dark-white-alpha-20 {
  border-color: rgba(255, 255, 255, .2);
}

.dark .color-dark-white-alpha-20 {
  color: rgba(255, 255, 255, .2);
}

.bgc-black {
  background-color: #000;
}

.color-black {
  color: #000;
}

.border-black {
  border-color: #000;
}

.dark .bgc-dark-black {
  background-color: #000;
}

.border-dark-black {
  border-color: #000;
}

.dark .color-dark-black {
  color: #000;
}

.bgc-black-900 {
  background-color: #010314;
}

.color-black-900 {
  color: #010314;
}

.border-black-900 {
  border-color: #010314;
}

.dark .bgc-dark-black-900 {
  background-color: #010314;
}

.border-dark-black-900 {
  border-color: #010314;
}

.dark .color-dark-black-900 {
  color: #010314;
}

.bgc-red-200 {
  background-color: #ee8681;
}

.color-red-200 {
  color: #ee8681;
}

.border-red-200 {
  border-color: #ee8681;
}

.dark .bgc-dark-red-200 {
  background-color: #ee8681;
}

.border-dark-red-200 {
  border-color: #ee8681;
}

.dark .color-dark-red-200 {
  color: #ee8681;
}

.bgc-red-400 {
  background-color: red;
}

.color-red-400 {
  color: red;
}

.border-red-400 {
  border-color: red;
}

.dark .bgc-dark-red-400 {
  background-color: red;
}

.border-dark-red-400 {
  border-color: red;
}

.dark .color-dark-red-400 {
  color: red;
}

.bgc-pale-blue-400 {
  background-color: #f7f8fc;
}

.color-pale-blue-400 {
  color: #f7f8fc;
}

.border-pale-blue-400 {
  border-color: #f7f8fc;
}

.dark .bgc-dark-pale-blue-400 {
  background-color: #f7f8fc;
}

.border-dark-pale-blue-400 {
  border-color: #f7f8fc;
}

.dark .color-dark-pale-blue-400 {
  color: #f7f8fc;
}

.bgc-pale-blue-500 {
  background-color: #f0f5ff;
}

.color-pale-blue-500 {
  color: #f0f5ff;
}

.border-pale-blue-500 {
  border-color: #f0f5ff;
}

.dark .bgc-dark-pale-blue-500 {
  background-color: #f0f5ff;
}

.border-dark-pale-blue-500 {
  border-color: #f0f5ff;
}

.dark .color-dark-pale-blue-500 {
  color: #f0f5ff;
}

.bgc-light-blue-400 {
  background-color: #2ca8b1;
}

.color-light-blue-400 {
  color: #2ca8b1;
}

.border-light-blue-400 {
  border-color: #2ca8b1;
}

.dark .bgc-dark-light-blue-400 {
  background-color: #2ca8b1;
}

.border-dark-light-blue-400 {
  border-color: #2ca8b1;
}

.dark .color-dark-light-blue-400 {
  color: #2ca8b1;
}

.bgc-blue-50 {
  background-color: #c6c5ff;
}

.color-blue-50 {
  color: #c6c5ff;
}

.border-blue-50 {
  border-color: #c6c5ff;
}

.dark .bgc-dark-blue-50 {
  background-color: #c6c5ff;
}

.border-dark-blue-50 {
  border-color: #c6c5ff;
}

.dark .color-dark-blue-50 {
  color: #c6c5ff;
}

.bgc-blue-200 {
  background-color: #8d8dff;
}

.color-blue-200 {
  color: #8d8dff;
}

.border-blue-200 {
  border-color: #8d8dff;
}

.dark .bgc-dark-blue-200 {
  background-color: #8d8dff;
}

.border-dark-blue-200 {
  border-color: #8d8dff;
}

.dark .color-dark-blue-200 {
  color: #8d8dff;
}

.bgc-blue-225 {
  background-color: #6366f1;
}

.color-blue-225 {
  color: #6366f1;
}

.border-blue-225 {
  border-color: #6366f1;
}

.dark .bgc-dark-blue-225 {
  background-color: #6366f1;
}

.border-dark-blue-225 {
  border-color: #6366f1;
}

.dark .color-dark-blue-225 {
  color: #6366f1;
}

.bgc-blue-250 {
  background-color: #3b3beb;
}

.color-blue-250 {
  color: #3b3beb;
}

.border-blue-250 {
  border-color: #3b3beb;
}

.dark .bgc-dark-blue-250 {
  background-color: #3b3beb;
}

.border-dark-blue-250 {
  border-color: #3b3beb;
}

.dark .color-dark-blue-250 {
  color: #3b3beb;
}

.bgc-blue-300 {
  background-color: #3838f8;
}

.color-blue-300 {
  color: #3838f8;
}

.border-blue-300 {
  border-color: #3838f8;
}

.dark .bgc-dark-blue-300 {
  background-color: #3838f8;
}

.border-dark-blue-300 {
  border-color: #3838f8;
}

.dark .color-dark-blue-300 {
  color: #3838f8;
}

.bgc-blue-350 {
  background-color: #3a3af2;
}

.color-blue-350 {
  color: #3a3af2;
}

.border-blue-350 {
  border-color: #3a3af2;
}

.dark .bgc-dark-blue-350 {
  background-color: #3a3af2;
}

.border-dark-blue-350 {
  border-color: #3a3af2;
}

.dark .color-dark-blue-350 {
  color: #3a3af2;
}

.bgc-blue-400 {
  background-color: #3a3aea;
}

.color-blue-400 {
  color: #3a3aea;
}

.border-blue-400 {
  border-color: #3a3aea;
}

.dark .bgc-dark-blue-400 {
  background-color: #3a3aea;
}

.border-dark-blue-400 {
  border-color: #3a3aea;
}

.dark .color-dark-blue-400 {
  color: #3a3aea;
}

.bgc-blue-450 {
  background-color: #443ae1;
}

.color-blue-450 {
  color: #443ae1;
}

.border-blue-450 {
  border-color: #443ae1;
}

.dark .bgc-dark-blue-450 {
  background-color: #443ae1;
}

.border-dark-blue-450 {
  border-color: #443ae1;
}

.dark .color-dark-blue-450 {
  color: #443ae1;
}

.bgc-blue-500 {
  background-color: #0303d1;
}

.color-blue-500 {
  color: #0303d1;
}

.border-blue-500 {
  border-color: #0303d1;
}

.dark .bgc-dark-blue-500 {
  background-color: #0303d1;
}

.border-dark-blue-500 {
  border-color: #0303d1;
}

.dark .color-dark-blue-500 {
  color: #0303d1;
}

.bgc-blue-600 {
  background-color: #0000ad;
}

.color-blue-600 {
  color: #0000ad;
}

.border-blue-600 {
  border-color: #0000ad;
}

.dark .bgc-dark-blue-600 {
  background-color: #0000ad;
}

.border-dark-blue-600 {
  border-color: #0000ad;
}

.dark .color-dark-blue-600 {
  color: #0000ad;
}

.bgc-blue-800 {
  background-color: #000081;
}

.color-blue-800 {
  color: #000081;
}

.border-blue-800 {
  border-color: #000081;
}

.dark .bgc-dark-blue-800 {
  background-color: #000081;
}

.border-dark-blue-800 {
  border-color: #000081;
}

.dark .color-dark-blue-800 {
  color: #000081;
}

.bgc-navy-blue-100 {
  background-color: #80b5f5;
}

.color-navy-blue-100 {
  color: #80b5f5;
}

.border-navy-blue-100 {
  border-color: #80b5f5;
}

.dark .bgc-dark-navy-blue-100 {
  background-color: #80b5f5;
}

.border-dark-navy-blue-100 {
  border-color: #80b5f5;
}

.dark .color-dark-navy-blue-100 {
  color: #80b5f5;
}

.bgc-navy-blue-400 {
  background-color: #005298;
}

.color-navy-blue-400 {
  color: #005298;
}

.border-navy-blue-400 {
  border-color: #005298;
}

.dark .bgc-dark-navy-blue-400 {
  background-color: #005298;
}

.border-dark-navy-blue-400 {
  border-color: #005298;
}

.dark .color-dark-navy-blue-400 {
  color: #005298;
}

.bgc-navy-blue-300 {
  background-color: #2b77bd;
}

.color-navy-blue-300 {
  color: #2b77bd;
}

.border-navy-blue-300 {
  border-color: #2b77bd;
}

.dark .bgc-dark-navy-blue-300 {
  background-color: #2b77bd;
}

.border-dark-navy-blue-300 {
  border-color: #2b77bd;
}

.dark .color-dark-navy-blue-300 {
  color: #2b77bd;
}

.bgc-navy-blue-500 {
  background-color: #2b517c;
}

.color-navy-blue-500 {
  color: #2b517c;
}

.border-navy-blue-500 {
  border-color: #2b517c;
}

.dark .bgc-dark-navy-blue-500 {
  background-color: #2b517c;
}

.border-dark-navy-blue-500 {
  border-color: #2b517c;
}

.dark .color-dark-navy-blue-500 {
  color: #2b517c;
}

.bgc-navy-blue-600 {
  background-color: #2a537e;
}

.color-navy-blue-600 {
  color: #2a537e;
}

.border-navy-blue-600 {
  border-color: #2a537e;
}

.dark .bgc-dark-navy-blue-600 {
  background-color: #2a537e;
}

.border-dark-navy-blue-600 {
  border-color: #2a537e;
}

.dark .color-dark-navy-blue-600 {
  color: #2a537e;
}

.bgc-navy-blue-700 {
  background-color: #1d3b5e;
}

.color-navy-blue-700 {
  color: #1d3b5e;
}

.border-navy-blue-700 {
  border-color: #1d3b5e;
}

.dark .bgc-dark-navy-blue-700 {
  background-color: #1d3b5e;
}

.border-dark-navy-blue-700 {
  border-color: #1d3b5e;
}

.dark .color-dark-navy-blue-700 {
  color: #1d3b5e;
}

.bgc-navy-blue-800 {
  background-color: #152b4b;
}

.color-navy-blue-800 {
  color: #152b4b;
}

.border-navy-blue-800 {
  border-color: #152b4b;
}

.dark .bgc-dark-navy-blue-800 {
  background-color: #152b4b;
}

.border-dark-navy-blue-800 {
  border-color: #152b4b;
}

.dark .color-dark-navy-blue-800 {
  color: #152b4b;
}

.bgc-dark-blue-300 {
  background-color: #012c3c;
}

.color-dark-blue-300 {
  color: #012c3c;
}

.border-dark-blue-300 {
  border-color: #012c3c;
}

.dark .bgc-dark-dark-blue-300 {
  background-color: #012c3c;
}

.border-dark-dark-blue-300 {
  border-color: #012c3c;
}

.dark .color-dark-dark-blue-300 {
  color: #012c3c;
}

.bgc-dark-blue-350 {
  background-color: #002432;
}

.color-dark-blue-350 {
  color: #002432;
}

.border-dark-blue-350 {
  border-color: #002432;
}

.dark .bgc-dark-dark-blue-350 {
  background-color: #002432;
}

.border-dark-dark-blue-350 {
  border-color: #002432;
}

.dark .color-dark-dark-blue-350 {
  color: #002432;
}

.bgc-dark-blue-400 {
  background-color: #001923;
}

.color-dark-blue-400 {
  color: #001923;
}

.border-dark-blue-400 {
  border-color: #001923;
}

.dark .bgc-dark-dark-blue-400 {
  background-color: #001923;
}

.border-dark-dark-blue-400 {
  border-color: #001923;
}

.dark .color-dark-dark-blue-400 {
  color: #001923;
}

.bgc-dark-blue-500 {
  background-color: #000517;
}

.color-dark-blue-500 {
  color: #000517;
}

.border-dark-blue-500 {
  border-color: #000517;
}

.dark .bgc-dark-dark-blue-500 {
  background-color: #000517;
}

.border-dark-dark-blue-500 {
  border-color: #000517;
}

.dark .color-dark-dark-blue-500 {
  color: #000517;
}

.bgc-light-gray-50 {
  background-color: rgba(245, 245, 245, .796);
}

.color-light-gray-50 {
  color: rgba(245, 245, 245, .796);
}

.border-light-gray-50 {
  border-color: rgba(245, 245, 245, .796);
}

.dark .bgc-dark-light-gray-50 {
  background-color: rgba(245, 245, 245, .796);
}

.border-dark-light-gray-50 {
  border-color: rgba(245, 245, 245, .796);
}

.dark .color-dark-light-gray-50 {
  color: rgba(245, 245, 245, .796);
}

.bgc-light-gray-100 {
  background-color: #f5f5f5;
}

.color-light-gray-100 {
  color: #f5f5f5;
}

.border-light-gray-100 {
  border-color: #f5f5f5;
}

.dark .bgc-dark-light-gray-100 {
  background-color: #f5f5f5;
}

.border-dark-light-gray-100 {
  border-color: #f5f5f5;
}

.dark .color-dark-light-gray-100 {
  color: #f5f5f5;
}

.bgc-light-gray-125 {
  background-color: #f7f7f7;
}

.color-light-gray-125 {
  color: #f7f7f7;
}

.border-light-gray-125 {
  border-color: #f7f7f7;
}

.dark .bgc-dark-light-gray-125 {
  background-color: #f7f7f7;
}

.border-dark-light-gray-125 {
  border-color: #f7f7f7;
}

.dark .color-dark-light-gray-125 {
  color: #f7f7f7;
}

.bgc-light-gray-150 {
  background-color: #e3e3e3;
}

.color-light-gray-150 {
  color: #e3e3e3;
}

.border-light-gray-150 {
  border-color: #e3e3e3;
}

.dark .bgc-dark-light-gray-150 {
  background-color: #e3e3e3;
}

.border-dark-light-gray-150 {
  border-color: #e3e3e3;
}

.dark .color-dark-light-gray-150 {
  color: #e3e3e3;
}

.bgc-light-gray-175 {
  background-color: #dcdcdc;
}

.color-light-gray-175 {
  color: #dcdcdc;
}

.border-light-gray-175 {
  border-color: #dcdcdc;
}

.dark .bgc-dark-light-gray-175 {
  background-color: #dcdcdc;
}

.border-dark-light-gray-175 {
  border-color: #dcdcdc;
}

.dark .color-dark-light-gray-175 {
  color: #dcdcdc;
}

.bgc-light-gray-200 {
  background-color: #e5e5e5;
}

.color-light-gray-200 {
  color: #e5e5e5;
}

.border-light-gray-200 {
  border-color: #e5e5e5;
}

.dark .bgc-dark-light-gray-200 {
  background-color: #e5e5e5;
}

.border-dark-light-gray-200 {
  border-color: #e5e5e5;
}

.dark .color-dark-light-gray-200 {
  color: #e5e5e5;
}

.bgc-light-gray-300 {
  background-color: #ebebeb;
}

.color-light-gray-300 {
  color: #ebebeb;
}

.border-light-gray-300 {
  border-color: #ebebeb;
}

.dark .bgc-dark-light-gray-300 {
  background-color: #ebebeb;
}

.border-dark-light-gray-300 {
  border-color: #ebebeb;
}

.dark .color-dark-light-gray-300 {
  color: #ebebeb;
}

.bgc-light-gray-350 {
  background-color: #dedede;
}

.color-light-gray-350 {
  color: #dedede;
}

.border-light-gray-350 {
  border-color: #dedede;
}

.dark .bgc-dark-light-gray-350 {
  background-color: #dedede;
}

.border-dark-light-gray-350 {
  border-color: #dedede;
}

.dark .color-dark-light-gray-350 {
  color: #dedede;
}

.bgc-light-gray-400 {
  background-color: #d6d6d6;
}

.color-light-gray-400 {
  color: #d6d6d6;
}

.border-light-gray-400 {
  border-color: #d6d6d6;
}

.dark .bgc-dark-light-gray-400 {
  background-color: #d6d6d6;
}

.border-dark-light-gray-400 {
  border-color: #d6d6d6;
}

.dark .color-dark-light-gray-400 {
  color: #d6d6d6;
}

.bgc-light-gray-450 {
  background-color: #fcfcfc;
}

.color-light-gray-450 {
  color: #fcfcfc;
}

.border-light-gray-450 {
  border-color: #fcfcfc;
}

.dark .bgc-dark-light-gray-450 {
  background-color: #fcfcfc;
}

.border-dark-light-gray-450 {
  border-color: #fcfcfc;
}

.dark .color-dark-light-gray-450 {
  color: #fcfcfc;
}

.bgc-light-purple-100 {
  background-color: #e6e6ff;
}

.color-light-purple-100 {
  color: #e6e6ff;
}

.border-light-purple-100 {
  border-color: #e6e6ff;
}

.dark .bgc-dark-light-purple-100 {
  background-color: #e6e6ff;
}

.border-dark-light-purple-100 {
  border-color: #e6e6ff;
}

.dark .color-dark-light-purple-100 {
  color: #e6e6ff;
}

.bgc-light-purple-200 {
  background-color: #f2e9ff;
}

.color-light-purple-200 {
  color: #f2e9ff;
}

.border-light-purple-200 {
  border-color: #f2e9ff;
}

.dark .bgc-dark-light-purple-200 {
  background-color: #f2e9ff;
}

.border-dark-light-purple-200 {
  border-color: #f2e9ff;
}

.dark .color-dark-light-purple-200 {
  color: #f2e9ff;
}

.bgc-light-purple-500 {
  background-color: #a7a7f9;
}

.color-light-purple-500 {
  color: #a7a7f9;
}

.border-light-purple-500 {
  border-color: #a7a7f9;
}

.dark .bgc-dark-light-purple-500 {
  background-color: #a7a7f9;
}

.border-dark-light-purple-500 {
  border-color: #a7a7f9;
}

.dark .color-dark-light-purple-500 {
  color: #a7a7f9;
}

.bgc-purple-200 {
  background-color: #362547;
}

.color-purple-200 {
  color: #362547;
}

.border-purple-200 {
  border-color: #362547;
}

.dark .bgc-dark-purple-200 {
  background-color: #362547;
}

.border-dark-purple-200 {
  border-color: #362547;
}

.dark .color-dark-purple-200 {
  color: #362547;
}

.bgc-purple-400 {
  background-color: #7120ce;
}

.color-purple-400 {
  color: #7120ce;
}

.border-purple-400 {
  border-color: #7120ce;
}

.dark .bgc-dark-purple-400 {
  background-color: #7120ce;
}

.border-dark-purple-400 {
  border-color: #7120ce;
}

.dark .color-dark-purple-400 {
  color: #7120ce;
}

.bgc-purple-500 {
  background-color: #6e37bd;
}

.color-purple-500 {
  color: #6e37bd;
}

.border-purple-500 {
  border-color: #6e37bd;
}

.dark .bgc-dark-purple-500 {
  background-color: #6e37bd;
}

.border-dark-purple-500 {
  border-color: #6e37bd;
}

.dark .color-dark-purple-500 {
  color: #6e37bd;
}

.bgc-gray-200 {
  background-color: #e1e1e1;
}

.color-gray-200 {
  color: #e1e1e1;
}

.border-gray-200 {
  border-color: #e1e1e1;
}

.dark .bgc-dark-gray-200 {
  background-color: #e1e1e1;
}

.border-dark-gray-200 {
  border-color: #e1e1e1;
}

.dark .color-dark-gray-200 {
  color: #e1e1e1;
}

.bgc-gray-225 {
  background-color: #d9d9d9;
}

.color-gray-225 {
  color: #d9d9d9;
}

.border-gray-225 {
  border-color: #d9d9d9;
}

.dark .bgc-dark-gray-225 {
  background-color: #d9d9d9;
}

.border-dark-gray-225 {
  border-color: #d9d9d9;
}

.dark .color-dark-gray-225 {
  color: #d9d9d9;
}

.bgc-gray-250 {
  background-color: #d3d3d3;
}

.color-gray-250 {
  color: #d3d3d3;
}

.border-gray-250 {
  border-color: #d3d3d3;
}

.dark .bgc-dark-gray-250 {
  background-color: #d3d3d3;
}

.border-dark-gray-250 {
  border-color: #d3d3d3;
}

.dark .color-dark-gray-250 {
  color: #d3d3d3;
}

.bgc-gray-300 {
  background-color: #c7c7c7;
}

.color-gray-300 {
  color: #c7c7c7;
}

.border-gray-300 {
  border-color: #c7c7c7;
}

.dark .bgc-dark-gray-300 {
  background-color: #c7c7c7;
}

.border-dark-gray-300 {
  border-color: #c7c7c7;
}

.dark .color-dark-gray-300 {
  color: #c7c7c7;
}

.bgc-gray-350 {
  background-color: #bababa;
}

.color-gray-350 {
  color: #bababa;
}

.border-gray-350 {
  border-color: #bababa;
}

.dark .bgc-dark-gray-350 {
  background-color: #bababa;
}

.border-dark-gray-350 {
  border-color: #bababa;
}

.dark .color-dark-gray-350 {
  color: #bababa;
}

.bgc-gray-360 {
  background-color: #bdbdbd;
}

.color-gray-360 {
  color: #bdbdbd;
}

.border-gray-360 {
  border-color: #bdbdbd;
}

.dark .bgc-dark-gray-360 {
  background-color: #bdbdbd;
}

.border-dark-gray-360 {
  border-color: #bdbdbd;
}

.dark .color-dark-gray-360 {
  color: #bdbdbd;
}

.bgc-gray-375 {
  background-color: #8e95a2;
}

.color-gray-375 {
  color: #8e95a2;
}

.border-gray-375 {
  border-color: #8e95a2;
}

.dark .bgc-dark-gray-375 {
  background-color: #8e95a2;
}

.border-dark-gray-375 {
  border-color: #8e95a2;
}

.dark .color-dark-gray-375 {
  color: #8e95a2;
}

.bgc-gray-400 {
  background-color: #949494;
}

.color-gray-400 {
  color: #949494;
}

.border-gray-400 {
  border-color: #949494;
}

.dark .bgc-dark-gray-400 {
  background-color: #949494;
}

.border-dark-gray-400 {
  border-color: #949494;
}

.dark .color-dark-gray-400 {
  color: #949494;
}

.bgc-medium-gray {
  background-color: #b7b7b7;
}

.color-medium-gray {
  color: #b7b7b7;
}

.border-medium-gray {
  border-color: #b7b7b7;
}

.dark .bgc-dark-medium-gray {
  background-color: #b7b7b7;
}

.border-dark-medium-gray {
  border-color: #b7b7b7;
}

.dark .color-dark-medium-gray {
  color: #b7b7b7;
}

.bgc-dark-gray-100 {
  background-color: #efefef;
}

.color-dark-gray-100 {
  color: #efefef;
}

.border-dark-gray-100 {
  border-color: #efefef;
}

.dark .bgc-dark-dark-gray-100 {
  background-color: #efefef;
}

.border-dark-dark-gray-100 {
  border-color: #efefef;
}

.dark .color-dark-dark-gray-100 {
  color: #efefef;
}

.bgc-dark-gray-150 {
  background-color: #7e7e7e;
}

.color-dark-gray-150 {
  color: #7e7e7e;
}

.border-dark-gray-150 {
  border-color: #7e7e7e;
}

.dark .bgc-dark-dark-gray-150 {
  background-color: #7e7e7e;
}

.border-dark-dark-gray-150 {
  border-color: #7e7e7e;
}

.dark .color-dark-dark-gray-150 {
  color: #7e7e7e;
}

.bgc-dark-gray-200 {
  background-color: #707070;
}

.color-dark-gray-200 {
  color: #707070;
}

.border-dark-gray-200 {
  border-color: #707070;
}

.dark .bgc-dark-dark-gray-200 {
  background-color: #707070;
}

.border-dark-dark-gray-200 {
  border-color: #707070;
}

.dark .color-dark-dark-gray-200 {
  color: #707070;
}

.bgc-dark-gray-300 {
  background-color: rgba(255, 255, 255, .3);
}

.color-dark-gray-300 {
  color: rgba(255, 255, 255, .3);
}

.border-dark-gray-300 {
  border-color: rgba(255, 255, 255, .3);
}

.dark .bgc-dark-dark-gray-300 {
  background-color: rgba(255, 255, 255, .3);
}

.border-dark-dark-gray-300 {
  border-color: rgba(255, 255, 255, .3);
}

.dark .color-dark-dark-gray-300 {
  color: rgba(255, 255, 255, .3);
}

.bgc-dark-gray-350 {
  background-color: #696969;
}

.color-dark-gray-350 {
  color: #696969;
}

.border-dark-gray-350 {
  border-color: #696969;
}

.dark .bgc-dark-dark-gray-350 {
  background-color: #696969;
}

.border-dark-dark-gray-350 {
  border-color: #696969;
}

.dark .color-dark-dark-gray-350 {
  color: #696969;
}

.bgc-dark-gray-400 {
  background-color: #5a5a5a;
}

.color-dark-gray-400 {
  color: #5a5a5a;
}

.border-dark-gray-400 {
  border-color: #5a5a5a;
}

.dark .bgc-dark-dark-gray-400 {
  background-color: #5a5a5a;
}

.border-dark-dark-gray-400 {
  border-color: #5a5a5a;
}

.dark .color-dark-dark-gray-400 {
  color: #5a5a5a;
}

.bgc-dark-gray-450 {
  background-color: #615e5e;
}

.color-dark-gray-450 {
  color: #615e5e;
}

.border-dark-gray-450 {
  border-color: #615e5e;
}

.dark .bgc-dark-dark-gray-450 {
  background-color: #615e5e;
}

.border-dark-dark-gray-450 {
  border-color: #615e5e;
}

.dark .color-dark-dark-gray-450 {
  color: #615e5e;
}

.bgc-dark-gray-500 {
  background-color: #4b4b4b;
}

.color-dark-gray-500 {
  color: #4b4b4b;
}

.border-dark-gray-500 {
  border-color: #4b4b4b;
}

.dark .bgc-dark-dark-gray-500 {
  background-color: #4b4b4b;
}

.border-dark-dark-gray-500 {
  border-color: #4b4b4b;
}

.dark .color-dark-dark-gray-500 {
  color: #4b4b4b;
}

.bgc-dark-gray-700 {
  background-color: #222;
}

.color-dark-gray-700 {
  color: #222;
}

.border-dark-gray-700 {
  border-color: #222;
}

.dark .bgc-dark-dark-gray-700 {
  background-color: #222;
}

.border-dark-dark-gray-700 {
  border-color: #222;
}

.dark .color-dark-dark-gray-700 {
  color: #222;
}

.bgc-dark-gray-800 {
  background-color: #121212;
}

.color-dark-gray-800 {
  color: #121212;
}

.border-dark-gray-800 {
  border-color: #121212;
}

.dark .bgc-dark-dark-gray-800 {
  background-color: #121212;
}

.border-dark-dark-gray-800 {
  border-color: #121212;
}

.dark .color-dark-dark-gray-800 {
  color: #121212;
}

.bgc-teal-400 {
  background-color: #01a998;
}

.color-teal-400 {
  color: #01a998;
}

.border-teal-400 {
  border-color: #01a998;
}

.dark .bgc-dark-teal-400 {
  background-color: #01a998;
}

.border-dark-teal-400 {
  border-color: #01a998;
}

.dark .color-dark-teal-400 {
  color: #01a998;
}

.bgc-teal-600 {
  background-color: #006464;
}

.color-teal-600 {
  color: #006464;
}

.border-teal-600 {
  border-color: #006464;
}

.dark .bgc-dark-teal-600 {
  background-color: #006464;
}

.border-dark-teal-600 {
  border-color: #006464;
}

.dark .color-dark-teal-600 {
  color: #006464;
}

.bgc-teal-650 {
  background-color: #025858;
}

.color-teal-650 {
  color: #025858;
}

.border-teal-650 {
  border-color: #025858;
}

.dark .bgc-dark-teal-650 {
  background-color: #025858;
}

.border-dark-teal-650 {
  border-color: #025858;
}

.dark .color-dark-teal-650 {
  color: #025858;
}

.bgc-teal-675 {
  background-color: #035577;
}

.color-teal-675 {
  color: #035577;
}

.border-teal-675 {
  border-color: #035577;
}

.dark .bgc-dark-teal-675 {
  background-color: #035577;
}

.border-dark-teal-675 {
  border-color: #035577;
}

.dark .color-dark-teal-675 {
  color: #035577;
}

.bgc-teal-700 {
  background-color: #043f3f;
}

.color-teal-700 {
  color: #043f3f;
}

.border-teal-700 {
  border-color: #043f3f;
}

.dark .bgc-dark-teal-700 {
  background-color: #043f3f;
}

.border-dark-teal-700 {
  border-color: #043f3f;
}

.dark .color-dark-teal-700 {
  color: #043f3f;
}

.bgc-teal-800 {
  background-color: #003d3d;
}

.color-teal-800 {
  color: #003d3d;
}

.border-teal-800 {
  border-color: #003d3d;
}

.dark .bgc-dark-teal-800 {
  background-color: #003d3d;
}

.border-dark-teal-800 {
  border-color: #003d3d;
}

.dark .color-dark-teal-800 {
  color: #003d3d;
}

.bgc-dark-teal-400 {
  background-color: #02192d;
}

.color-dark-teal-400 {
  color: #02192d;
}

.border-dark-teal-400 {
  border-color: #02192d;
}

.dark .bgc-dark-dark-teal-400 {
  background-color: #02192d;
}

.border-dark-dark-teal-400 {
  border-color: #02192d;
}

.dark .color-dark-dark-teal-400 {
  color: #02192d;
}

.bgc-dark-teal-500 {
  background-color: #011923;
}

.color-dark-teal-500 {
  color: #011923;
}

.border-dark-teal-500 {
  border-color: #011923;
}

.dark .bgc-dark-dark-teal-500 {
  background-color: #011923;
}

.border-dark-dark-teal-500 {
  border-color: #011923;
}

.dark .color-dark-dark-teal-500 {
  color: #011923;
}

.bgc-green-200 {
  background-color: #90bf6f;
}

.color-green-200 {
  color: #90bf6f;
}

.border-green-200 {
  border-color: #90bf6f;
}

.dark .bgc-dark-green-200 {
  background-color: #90bf6f;
}

.border-dark-green-200 {
  border-color: #90bf6f;
}

.dark .color-dark-green-200 {
  color: #90bf6f;
}

.bgc-green-400 {
  background-color: #6ccc82;
}

.color-green-400 {
  color: #6ccc82;
}

.border-green-400 {
  border-color: #6ccc82;
}

.dark .bgc-dark-green-400 {
  background-color: #6ccc82;
}

.border-dark-green-400 {
  border-color: #6ccc82;
}

.dark .color-dark-green-400 {
  color: #6ccc82;
}

.bgc-light-green-400 {
  background-color: #c5eef1;
}

.color-light-green-400 {
  color: #c5eef1;
}

.border-light-green-400 {
  border-color: #c5eef1;
}

.dark .bgc-dark-light-green-400 {
  background-color: #c5eef1;
}

.border-dark-light-green-400 {
  border-color: #c5eef1;
}

.dark .color-dark-light-green-400 {
  color: #c5eef1;
}

.bgc-persian-green-200 {
  background-color: #00e6ba;
}

.color-persian-green-200 {
  color: #00e6ba;
}

.border-persian-green-200 {
  border-color: #00e6ba;
}

.dark .bgc-dark-persian-green-200 {
  background-color: #00e6ba;
}

.border-dark-persian-green-200 {
  border-color: #00e6ba;
}

.dark .color-dark-persian-green-200 {
  color: #00e6ba;
}

.bgc-persian-green-300 {
  background-color: #00c7a2;
}

.color-persian-green-300 {
  color: #00c7a2;
}

.border-persian-green-300 {
  border-color: #00c7a2;
}

.dark .bgc-dark-persian-green-300 {
  background-color: #00c7a2;
}

.border-dark-persian-green-300 {
  border-color: #00c7a2;
}

.dark .color-dark-persian-green-300 {
  color: #00c7a2;
}

.bgc-persian-green {
  background-color: #009e80;
}

.color-persian-green {
  color: #009e80;
}

.border-persian-green {
  border-color: #009e80;
}

.dark .bgc-dark-persian-green {
  background-color: #009e80;
}

.border-dark-persian-green {
  border-color: #009e80;
}

.dark .color-dark-persian-green {
  color: #009e80;
}

.bgc-pink-400 {
  background-color: #ee4d99;
}

.color-pink-400 {
  color: #ee4d99;
}

.border-pink-400 {
  border-color: #ee4d99;
}

.dark .bgc-dark-pink-400 {
  background-color: #ee4d99;
}

.border-dark-pink-400 {
  border-color: #ee4d99;
}

.dark .color-dark-pink-400 {
  color: #ee4d99;
}

.bgc-pink-500 {
  background-color: #cc2e78;
}

.color-pink-500 {
  color: #cc2e78;
}

.border-pink-500 {
  border-color: #cc2e78;
}

.dark .bgc-dark-pink-500 {
  background-color: #cc2e78;
}

.border-dark-pink-500 {
  border-color: #cc2e78;
}

.dark .color-dark-pink-500 {
  color: #cc2e78;
}

.bgc-white-800 {
  background-color: #dde8e9;
}

.color-white-800 {
  color: #dde8e9;
}

.border-white-800 {
  border-color: #dde8e9;
}

.dark .bgc-dark-white-800 {
  background-color: #dde8e9;
}

.border-dark-white-800 {
  border-color: #dde8e9;
}

.dark .color-dark-white-800 {
  color: #dde8e9;
}

.bgc-blueish-gray-100 {
  background-color: #d4d7e5;
}

.color-blueish-gray-100 {
  color: #d4d7e5;
}

.border-blueish-gray-100 {
  border-color: #d4d7e5;
}

.dark .bgc-dark-blueish-gray-100 {
  background-color: #d4d7e5;
}

.border-dark-blueish-gray-100 {
  border-color: #d4d7e5;
}

.dark .color-dark-blueish-gray-100 {
  color: #d4d7e5;
}

.bgc-blueish-gray-700 {
  background-color: #565973;
}

.color-blueish-gray-700 {
  color: #565973;
}

.border-blueish-gray-700 {
  border-color: #565973;
}

.dark .bgc-dark-blueish-gray-700 {
  background-color: #565973;
}

.border-dark-blueish-gray-700 {
  border-color: #565973;
}

.dark .color-dark-blueish-gray-700 {
  color: #565973;
}

.bgc-dark-blue-550 {
  background-color: #0c1933;
}

.color-dark-blue-550 {
  color: #0c1933;
}

.border-dark-blue-550 {
  border-color: #0c1933;
}

.dark .bgc-dark-dark-blue-550 {
  background-color: #0c1933;
}

.border-dark-dark-blue-550 {
  border-color: #0c1933;
}

.dark .color-dark-dark-blue-550 {
  color: #0c1933;
}

.bgc-dark-blue-600 {
  background-color: #010314;
}

.color-dark-blue-600 {
  color: #010314;
}

.border-dark-blue-600 {
  border-color: #010314;
}

.dark .bgc-dark-dark-blue-600 {
  background-color: #010314;
}

.border-dark-dark-blue-600 {
  border-color: #010314;
}

.dark .color-dark-dark-blue-600 {
  color: #010314;
}

.bgc-magenta-600 {
  background-color: #b70edb;
}

.color-magenta-600 {
  color: #b70edb;
}

.border-magenta-600 {
  border-color: #b70edb;
}

.dark .bgc-dark-magenta-600 {
  background-color: #b70edb;
}

.border-dark-magenta-600 {
  border-color: #b70edb;
}

.dark .color-dark-magenta-600 {
  color: #b70edb;
}

.fs-5 {
  font-size: .3125rem;
}

.lh-s5 {
  line-height: .3125rem;
}

.fs-7 {
  font-size: .4375rem;
}

.lh-s7 {
  line-height: .4375rem;
}

.fs-8 {
  font-size: .5rem;
}

.lh-s8 {
  line-height: .5rem;
}

.fs-9 {
  font-size: .5625rem;
}

.lh-s9 {
  line-height: .5625rem;
}

.fs-10 {
  font-size: .625rem;
}

.lh-s10 {
  line-height: .625rem;
}

.fs-11 {
  font-size: .6875rem;
}

.lh-s11 {
  line-height: .6875rem;
}

.fs-12 {
  font-size: .75rem;
}

.lh-s12 {
  line-height: .75rem;
}

.fs-13 {
  font-size: .8125rem;
}

.lh-s13 {
  line-height: .8125rem;
}

.fs-14 {
  font-size: .875rem;
}

.lh-s14 {
  line-height: .875rem;
}

.fs-15 {
  font-size: .9375rem;
}

.lh-s15 {
  line-height: .9375rem;
}

.fs-16 {
  font-size: 1rem;
}

.lh-s16 {
  line-height: 1rem;
}

.fs-17 {
  font-size: 1.0625rem;
}

.lh-s17 {
  line-height: 1.0625rem;
}

.fs-18 {
  font-size: 1.125rem;
}

.lh-s18 {
  line-height: 1.125rem;
}

.fs-19 {
  font-size: 1.1875rem;
}

.lh-s19 {
  line-height: 1.1875rem;
}

.fs-20 {
  font-size: 1.25rem;
}

.lh-s20 {
  line-height: 1.25rem;
}

.fs-21 {
  font-size: 1.3125rem;
}

.lh-s21 {
  line-height: 1.3125rem;
}

.fs-22 {
  font-size: 1.375rem;
}

.lh-s22 {
  line-height: 1.375rem;
}

.fs-24 {
  font-size: 1.5rem;
}

.lh-s24 {
  line-height: 1.5rem;
}

.fs-25 {
  font-size: 1.5625rem;
}

.lh-s25 {
  line-height: 1.5625rem;
}

.fs-26 {
  font-size: 1.625rem;
}

.lh-s26 {
  line-height: 1.625rem;
}

.fs-28 {
  font-size: 1.75rem;
}

.lh-s28 {
  line-height: 1.75rem;
}

.fs-29 {
  font-size: 1.8125rem;
}

.lh-s29 {
  line-height: 1.8125rem;
}

.fs-30 {
  font-size: 1.875rem;
}

.lh-s30 {
  line-height: 1.875rem;
}

.fs-32 {
  font-size: 2rem;
}

.lh-s32 {
  line-height: 2rem;
}

.fs-34 {
  font-size: 2.125rem;
}

.lh-s34 {
  line-height: 2.125rem;
}

.fs-35 {
  font-size: 2.1875rem;
}

.lh-s35 {
  line-height: 2.1875rem;
}

.fs-36 {
  font-size: 2.25rem;
}

.lh-s36 {
  line-height: 2.25rem;
}

.fs-38 {
  font-size: 2.375rem;
}

.lh-s38 {
  line-height: 2.375rem;
}

.fs-40 {
  font-size: 2.5rem;
}

.lh-s40 {
  line-height: 2.5rem;
}

.fs-45 {
  font-size: 2.8125rem;
}

.lh-s45 {
  line-height: 2.8125rem;
}

.fs-50 {
  font-size: 3.125rem;
}

.lh-s50 {
  line-height: 3.125rem;
}

.fs-56 {
  font-size: 3.5rem;
}

.lh-s56 {
  line-height: 3.5rem;
}

@media screen and (min-width: 390px) {
  .fs-xs-5 {
    font-size: .3125rem;
  }

  .lh-xs-s5 {
    line-height: .3125rem;
  }

  .fs-xs-7 {
    font-size: .4375rem;
  }

  .lh-xs-s7 {
    line-height: .4375rem;
  }

  .fs-xs-8 {
    font-size: .5rem;
  }

  .lh-xs-s8 {
    line-height: .5rem;
  }

  .fs-xs-9 {
    font-size: .5625rem;
  }

  .lh-xs-s9 {
    line-height: .5625rem;
  }

  .fs-xs-10 {
    font-size: .625rem;
  }

  .lh-xs-s10 {
    line-height: .625rem;
  }

  .fs-xs-11 {
    font-size: .6875rem;
  }

  .lh-xs-s11 {
    line-height: .6875rem;
  }

  .fs-xs-12 {
    font-size: .75rem;
  }

  .lh-xs-s12 {
    line-height: .75rem;
  }

  .fs-xs-13 {
    font-size: .8125rem;
  }

  .lh-xs-s13 {
    line-height: .8125rem;
  }

  .fs-xs-14 {
    font-size: .875rem;
  }

  .lh-xs-s14 {
    line-height: .875rem;
  }

  .fs-xs-15 {
    font-size: .9375rem;
  }

  .lh-xs-s15 {
    line-height: .9375rem;
  }

  .fs-xs-16 {
    font-size: 1rem;
  }

  .lh-xs-s16 {
    line-height: 1rem;
  }

  .fs-xs-17 {
    font-size: 1.0625rem;
  }

  .lh-xs-s17 {
    line-height: 1.0625rem;
  }

  .fs-xs-18 {
    font-size: 1.125rem;
  }

  .lh-xs-s18 {
    line-height: 1.125rem;
  }

  .fs-xs-19 {
    font-size: 1.1875rem;
  }

  .lh-xs-s19 {
    line-height: 1.1875rem;
  }

  .fs-xs-20 {
    font-size: 1.25rem;
  }

  .lh-xs-s20 {
    line-height: 1.25rem;
  }

  .fs-xs-21 {
    font-size: 1.3125rem;
  }

  .lh-xs-s21 {
    line-height: 1.3125rem;
  }

  .fs-xs-22 {
    font-size: 1.375rem;
  }

  .lh-xs-s22 {
    line-height: 1.375rem;
  }

  .fs-xs-24 {
    font-size: 1.5rem;
  }

  .lh-xs-s24 {
    line-height: 1.5rem;
  }

  .fs-xs-25 {
    font-size: 1.5625rem;
  }

  .lh-xs-s25 {
    line-height: 1.5625rem;
  }

  .fs-xs-26 {
    font-size: 1.625rem;
  }

  .lh-xs-s26 {
    line-height: 1.625rem;
  }

  .fs-xs-28 {
    font-size: 1.75rem;
  }

  .lh-xs-s28 {
    line-height: 1.75rem;
  }

  .fs-xs-29 {
    font-size: 1.8125rem;
  }

  .lh-xs-s29 {
    line-height: 1.8125rem;
  }

  .fs-xs-30 {
    font-size: 1.875rem;
  }

  .lh-xs-s30 {
    line-height: 1.875rem;
  }

  .fs-xs-32 {
    font-size: 2rem;
  }

  .lh-xs-s32 {
    line-height: 2rem;
  }

  .fs-xs-34 {
    font-size: 2.125rem;
  }

  .lh-xs-s34 {
    line-height: 2.125rem;
  }

  .fs-xs-35 {
    font-size: 2.1875rem;
  }

  .lh-xs-s35 {
    line-height: 2.1875rem;
  }

  .fs-xs-36 {
    font-size: 2.25rem;
  }

  .lh-xs-s36 {
    line-height: 2.25rem;
  }

  .fs-xs-38 {
    font-size: 2.375rem;
  }

  .lh-xs-s38 {
    line-height: 2.375rem;
  }

  .fs-xs-40 {
    font-size: 2.5rem;
  }

  .lh-xs-s40 {
    line-height: 2.5rem;
  }

  .fs-xs-45 {
    font-size: 2.8125rem;
  }

  .lh-xs-s45 {
    line-height: 2.8125rem;
  }

  .fs-xs-50 {
    font-size: 3.125rem;
  }

  .lh-xs-s50 {
    line-height: 3.125rem;
  }

  .fs-xs-56 {
    font-size: 3.5rem;
  }

  .lh-xs-s56 {
    line-height: 3.5rem;
  }
}

@media screen and (min-width: 640px) {
  .fs-sm-5 {
    font-size: .3125rem;
  }

  .lh-sm-s5 {
    line-height: .3125rem;
  }

  .fs-sm-7 {
    font-size: .4375rem;
  }

  .lh-sm-s7 {
    line-height: .4375rem;
  }

  .fs-sm-8 {
    font-size: .5rem;
  }

  .lh-sm-s8 {
    line-height: .5rem;
  }

  .fs-sm-9 {
    font-size: .5625rem;
  }

  .lh-sm-s9 {
    line-height: .5625rem;
  }

  .fs-sm-10 {
    font-size: .625rem;
  }

  .lh-sm-s10 {
    line-height: .625rem;
  }

  .fs-sm-11 {
    font-size: .6875rem;
  }

  .lh-sm-s11 {
    line-height: .6875rem;
  }

  .fs-sm-12 {
    font-size: .75rem;
  }

  .lh-sm-s12 {
    line-height: .75rem;
  }

  .fs-sm-13 {
    font-size: .8125rem;
  }

  .lh-sm-s13 {
    line-height: .8125rem;
  }

  .fs-sm-14 {
    font-size: .875rem;
  }

  .lh-sm-s14 {
    line-height: .875rem;
  }

  .fs-sm-15 {
    font-size: .9375rem;
  }

  .lh-sm-s15 {
    line-height: .9375rem;
  }

  .fs-sm-16 {
    font-size: 1rem;
  }

  .lh-sm-s16 {
    line-height: 1rem;
  }

  .fs-sm-17 {
    font-size: 1.0625rem;
  }

  .lh-sm-s17 {
    line-height: 1.0625rem;
  }

  .fs-sm-18 {
    font-size: 1.125rem;
  }

  .lh-sm-s18 {
    line-height: 1.125rem;
  }

  .fs-sm-19 {
    font-size: 1.1875rem;
  }

  .lh-sm-s19 {
    line-height: 1.1875rem;
  }

  .fs-sm-20 {
    font-size: 1.25rem;
  }

  .lh-sm-s20 {
    line-height: 1.25rem;
  }

  .fs-sm-21 {
    font-size: 1.3125rem;
  }

  .lh-sm-s21 {
    line-height: 1.3125rem;
  }

  .fs-sm-22 {
    font-size: 1.375rem;
  }

  .lh-sm-s22 {
    line-height: 1.375rem;
  }

  .fs-sm-24 {
    font-size: 1.5rem;
  }

  .lh-sm-s24 {
    line-height: 1.5rem;
  }

  .fs-sm-25 {
    font-size: 1.5625rem;
  }

  .lh-sm-s25 {
    line-height: 1.5625rem;
  }

  .fs-sm-26 {
    font-size: 1.625rem;
  }

  .lh-sm-s26 {
    line-height: 1.625rem;
  }

  .fs-sm-28 {
    font-size: 1.75rem;
  }

  .lh-sm-s28 {
    line-height: 1.75rem;
  }

  .fs-sm-29 {
    font-size: 1.8125rem;
  }

  .lh-sm-s29 {
    line-height: 1.8125rem;
  }

  .fs-sm-30 {
    font-size: 1.875rem;
  }

  .lh-sm-s30 {
    line-height: 1.875rem;
  }

  .fs-sm-32 {
    font-size: 2rem;
  }

  .lh-sm-s32 {
    line-height: 2rem;
  }

  .fs-sm-34 {
    font-size: 2.125rem;
  }

  .lh-sm-s34 {
    line-height: 2.125rem;
  }

  .fs-sm-35 {
    font-size: 2.1875rem;
  }

  .lh-sm-s35 {
    line-height: 2.1875rem;
  }

  .fs-sm-36 {
    font-size: 2.25rem;
  }

  .lh-sm-s36 {
    line-height: 2.25rem;
  }

  .fs-sm-38 {
    font-size: 2.375rem;
  }

  .lh-sm-s38 {
    line-height: 2.375rem;
  }

  .fs-sm-40 {
    font-size: 2.5rem;
  }

  .lh-sm-s40 {
    line-height: 2.5rem;
  }

  .fs-sm-45 {
    font-size: 2.8125rem;
  }

  .lh-sm-s45 {
    line-height: 2.8125rem;
  }

  .fs-sm-50 {
    font-size: 3.125rem;
  }

  .lh-sm-s50 {
    line-height: 3.125rem;
  }

  .fs-sm-56 {
    font-size: 3.5rem;
  }

  .lh-sm-s56 {
    line-height: 3.5rem;
  }
}

@media screen and (min-width: 768px) {
  .fs-md-5 {
    font-size: .3125rem;
  }

  .lh-md-s5 {
    line-height: .3125rem;
  }

  .fs-md-7 {
    font-size: .4375rem;
  }

  .lh-md-s7 {
    line-height: .4375rem;
  }

  .fs-md-8 {
    font-size: .5rem;
  }

  .lh-md-s8 {
    line-height: .5rem;
  }

  .fs-md-9 {
    font-size: .5625rem;
  }

  .lh-md-s9 {
    line-height: .5625rem;
  }

  .fs-md-10 {
    font-size: .625rem;
  }

  .lh-md-s10 {
    line-height: .625rem;
  }

  .fs-md-11 {
    font-size: .6875rem;
  }

  .lh-md-s11 {
    line-height: .6875rem;
  }

  .fs-md-12 {
    font-size: .75rem;
  }

  .lh-md-s12 {
    line-height: .75rem;
  }

  .fs-md-13 {
    font-size: .8125rem;
  }

  .lh-md-s13 {
    line-height: .8125rem;
  }

  .fs-md-14 {
    font-size: .875rem;
  }

  .lh-md-s14 {
    line-height: .875rem;
  }

  .fs-md-15 {
    font-size: .9375rem;
  }

  .lh-md-s15 {
    line-height: .9375rem;
  }

  .fs-md-16 {
    font-size: 1rem;
  }

  .lh-md-s16 {
    line-height: 1rem;
  }

  .fs-md-17 {
    font-size: 1.0625rem;
  }

  .lh-md-s17 {
    line-height: 1.0625rem;
  }

  .fs-md-18 {
    font-size: 1.125rem;
  }

  .lh-md-s18 {
    line-height: 1.125rem;
  }

  .fs-md-19 {
    font-size: 1.1875rem;
  }

  .lh-md-s19 {
    line-height: 1.1875rem;
  }

  .fs-md-20 {
    font-size: 1.25rem;
  }

  .lh-md-s20 {
    line-height: 1.25rem;
  }

  .fs-md-21 {
    font-size: 1.3125rem;
  }

  .lh-md-s21 {
    line-height: 1.3125rem;
  }

  .fs-md-22 {
    font-size: 1.375rem;
  }

  .lh-md-s22 {
    line-height: 1.375rem;
  }

  .fs-md-24 {
    font-size: 1.5rem;
  }

  .lh-md-s24 {
    line-height: 1.5rem;
  }

  .fs-md-25 {
    font-size: 1.5625rem;
  }

  .lh-md-s25 {
    line-height: 1.5625rem;
  }

  .fs-md-26 {
    font-size: 1.625rem;
  }

  .lh-md-s26 {
    line-height: 1.625rem;
  }

  .fs-md-28 {
    font-size: 1.75rem;
  }

  .lh-md-s28 {
    line-height: 1.75rem;
  }

  .fs-md-29 {
    font-size: 1.8125rem;
  }

  .lh-md-s29 {
    line-height: 1.8125rem;
  }

  .fs-md-30 {
    font-size: 1.875rem;
  }

  .lh-md-s30 {
    line-height: 1.875rem;
  }

  .fs-md-32 {
    font-size: 2rem;
  }

  .lh-md-s32 {
    line-height: 2rem;
  }

  .fs-md-34 {
    font-size: 2.125rem;
  }

  .lh-md-s34 {
    line-height: 2.125rem;
  }

  .fs-md-35 {
    font-size: 2.1875rem;
  }

  .lh-md-s35 {
    line-height: 2.1875rem;
  }

  .fs-md-36 {
    font-size: 2.25rem;
  }

  .lh-md-s36 {
    line-height: 2.25rem;
  }

  .fs-md-38 {
    font-size: 2.375rem;
  }

  .lh-md-s38 {
    line-height: 2.375rem;
  }

  .fs-md-40 {
    font-size: 2.5rem;
  }

  .lh-md-s40 {
    line-height: 2.5rem;
  }

  .fs-md-45 {
    font-size: 2.8125rem;
  }

  .lh-md-s45 {
    line-height: 2.8125rem;
  }

  .fs-md-50 {
    font-size: 3.125rem;
  }

  .lh-md-s50 {
    line-height: 3.125rem;
  }

  .fs-md-56 {
    font-size: 3.5rem;
  }

  .lh-md-s56 {
    line-height: 3.5rem;
  }
}

@media screen and (min-width: 1025px) {
  .fs-lg-5 {
    font-size: .3125rem;
  }

  .lh-lg-s5 {
    line-height: .3125rem;
  }

  .fs-lg-7 {
    font-size: .4375rem;
  }

  .lh-lg-s7 {
    line-height: .4375rem;
  }

  .fs-lg-8 {
    font-size: .5rem;
  }

  .lh-lg-s8 {
    line-height: .5rem;
  }

  .fs-lg-9 {
    font-size: .5625rem;
  }

  .lh-lg-s9 {
    line-height: .5625rem;
  }

  .fs-lg-10 {
    font-size: .625rem;
  }

  .lh-lg-s10 {
    line-height: .625rem;
  }

  .fs-lg-11 {
    font-size: .6875rem;
  }

  .lh-lg-s11 {
    line-height: .6875rem;
  }

  .fs-lg-12 {
    font-size: .75rem;
  }

  .lh-lg-s12 {
    line-height: .75rem;
  }

  .fs-lg-13 {
    font-size: .8125rem;
  }

  .lh-lg-s13 {
    line-height: .8125rem;
  }

  .fs-lg-14 {
    font-size: .875rem;
  }

  .lh-lg-s14 {
    line-height: .875rem;
  }

  .fs-lg-15 {
    font-size: .9375rem;
  }

  .lh-lg-s15 {
    line-height: .9375rem;
  }

  .fs-lg-16 {
    font-size: 1rem;
  }

  .lh-lg-s16 {
    line-height: 1rem;
  }

  .fs-lg-17 {
    font-size: 1.0625rem;
  }

  .lh-lg-s17 {
    line-height: 1.0625rem;
  }

  .fs-lg-18 {
    font-size: 1.125rem;
  }

  .lh-lg-s18 {
    line-height: 1.125rem;
  }

  .fs-lg-19 {
    font-size: 1.1875rem;
  }

  .lh-lg-s19 {
    line-height: 1.1875rem;
  }

  .fs-lg-20 {
    font-size: 1.25rem;
  }

  .lh-lg-s20 {
    line-height: 1.25rem;
  }

  .fs-lg-21 {
    font-size: 1.3125rem;
  }

  .lh-lg-s21 {
    line-height: 1.3125rem;
  }

  .fs-lg-22 {
    font-size: 1.375rem;
  }

  .lh-lg-s22 {
    line-height: 1.375rem;
  }

  .fs-lg-24 {
    font-size: 1.5rem;
  }

  .lh-lg-s24 {
    line-height: 1.5rem;
  }

  .fs-lg-25 {
    font-size: 1.5625rem;
  }

  .lh-lg-s25 {
    line-height: 1.5625rem;
  }

  .fs-lg-26 {
    font-size: 1.625rem;
  }

  .lh-lg-s26 {
    line-height: 1.625rem;
  }

  .fs-lg-28 {
    font-size: 1.75rem;
  }

  .lh-lg-s28 {
    line-height: 1.75rem;
  }

  .fs-lg-29 {
    font-size: 1.8125rem;
  }

  .lh-lg-s29 {
    line-height: 1.8125rem;
  }

  .fs-lg-30 {
    font-size: 1.875rem;
  }

  .lh-lg-s30 {
    line-height: 1.875rem;
  }

  .fs-lg-32 {
    font-size: 2rem;
  }

  .lh-lg-s32 {
    line-height: 2rem;
  }

  .fs-lg-34 {
    font-size: 2.125rem;
  }

  .lh-lg-s34 {
    line-height: 2.125rem;
  }

  .fs-lg-35 {
    font-size: 2.1875rem;
  }

  .lh-lg-s35 {
    line-height: 2.1875rem;
  }

  .fs-lg-36 {
    font-size: 2.25rem;
  }

  .lh-lg-s36 {
    line-height: 2.25rem;
  }

  .fs-lg-38 {
    font-size: 2.375rem;
  }

  .lh-lg-s38 {
    line-height: 2.375rem;
  }

  .fs-lg-40 {
    font-size: 2.5rem;
  }

  .lh-lg-s40 {
    line-height: 2.5rem;
  }

  .fs-lg-45 {
    font-size: 2.8125rem;
  }

  .lh-lg-s45 {
    line-height: 2.8125rem;
  }

  .fs-lg-50 {
    font-size: 3.125rem;
  }

  .lh-lg-s50 {
    line-height: 3.125rem;
  }

  .fs-lg-56 {
    font-size: 3.5rem;
  }

  .lh-lg-s56 {
    line-height: 3.5rem;
  }
}

@media screen and (min-width: 1280px) {
  .fs-xl-5 {
    font-size: .3125rem;
  }

  .lh-xl-s5 {
    line-height: .3125rem;
  }

  .fs-xl-7 {
    font-size: .4375rem;
  }

  .lh-xl-s7 {
    line-height: .4375rem;
  }

  .fs-xl-8 {
    font-size: .5rem;
  }

  .lh-xl-s8 {
    line-height: .5rem;
  }

  .fs-xl-9 {
    font-size: .5625rem;
  }

  .lh-xl-s9 {
    line-height: .5625rem;
  }

  .fs-xl-10 {
    font-size: .625rem;
  }

  .lh-xl-s10 {
    line-height: .625rem;
  }

  .fs-xl-11 {
    font-size: .6875rem;
  }

  .lh-xl-s11 {
    line-height: .6875rem;
  }

  .fs-xl-12 {
    font-size: .75rem;
  }

  .lh-xl-s12 {
    line-height: .75rem;
  }

  .fs-xl-13 {
    font-size: .8125rem;
  }

  .lh-xl-s13 {
    line-height: .8125rem;
  }

  .fs-xl-14 {
    font-size: .875rem;
  }

  .lh-xl-s14 {
    line-height: .875rem;
  }

  .fs-xl-15 {
    font-size: .9375rem;
  }

  .lh-xl-s15 {
    line-height: .9375rem;
  }

  .fs-xl-16 {
    font-size: 1rem;
  }

  .lh-xl-s16 {
    line-height: 1rem;
  }

  .fs-xl-17 {
    font-size: 1.0625rem;
  }

  .lh-xl-s17 {
    line-height: 1.0625rem;
  }

  .fs-xl-18 {
    font-size: 1.125rem;
  }

  .lh-xl-s18 {
    line-height: 1.125rem;
  }

  .fs-xl-19 {
    font-size: 1.1875rem;
  }

  .lh-xl-s19 {
    line-height: 1.1875rem;
  }

  .fs-xl-20 {
    font-size: 1.25rem;
  }

  .lh-xl-s20 {
    line-height: 1.25rem;
  }

  .fs-xl-21 {
    font-size: 1.3125rem;
  }

  .lh-xl-s21 {
    line-height: 1.3125rem;
  }

  .fs-xl-22 {
    font-size: 1.375rem;
  }

  .lh-xl-s22 {
    line-height: 1.375rem;
  }

  .fs-xl-24 {
    font-size: 1.5rem;
  }

  .lh-xl-s24 {
    line-height: 1.5rem;
  }

  .fs-xl-25 {
    font-size: 1.5625rem;
  }

  .lh-xl-s25 {
    line-height: 1.5625rem;
  }

  .fs-xl-26 {
    font-size: 1.625rem;
  }

  .lh-xl-s26 {
    line-height: 1.625rem;
  }

  .fs-xl-28 {
    font-size: 1.75rem;
  }

  .lh-xl-s28 {
    line-height: 1.75rem;
  }

  .fs-xl-29 {
    font-size: 1.8125rem;
  }

  .lh-xl-s29 {
    line-height: 1.8125rem;
  }

  .fs-xl-30 {
    font-size: 1.875rem;
  }

  .lh-xl-s30 {
    line-height: 1.875rem;
  }

  .fs-xl-32 {
    font-size: 2rem;
  }

  .lh-xl-s32 {
    line-height: 2rem;
  }

  .fs-xl-34 {
    font-size: 2.125rem;
  }

  .lh-xl-s34 {
    line-height: 2.125rem;
  }

  .fs-xl-35 {
    font-size: 2.1875rem;
  }

  .lh-xl-s35 {
    line-height: 2.1875rem;
  }

  .fs-xl-36 {
    font-size: 2.25rem;
  }

  .lh-xl-s36 {
    line-height: 2.25rem;
  }

  .fs-xl-38 {
    font-size: 2.375rem;
  }

  .lh-xl-s38 {
    line-height: 2.375rem;
  }

  .fs-xl-40 {
    font-size: 2.5rem;
  }

  .lh-xl-s40 {
    line-height: 2.5rem;
  }

  .fs-xl-45 {
    font-size: 2.8125rem;
  }

  .lh-xl-s45 {
    line-height: 2.8125rem;
  }

  .fs-xl-50 {
    font-size: 3.125rem;
  }

  .lh-xl-s50 {
    line-height: 3.125rem;
  }

  .fs-xl-56 {
    font-size: 3.5rem;
  }

  .lh-xl-s56 {
    line-height: 3.5rem;
  }
}

@media screen and (min-width: 1400px) {
  .fs-lxl-5 {
    font-size: .3125rem;
  }

  .lh-lxl-s5 {
    line-height: .3125rem;
  }

  .fs-lxl-7 {
    font-size: .4375rem;
  }

  .lh-lxl-s7 {
    line-height: .4375rem;
  }

  .fs-lxl-8 {
    font-size: .5rem;
  }

  .lh-lxl-s8 {
    line-height: .5rem;
  }

  .fs-lxl-9 {
    font-size: .5625rem;
  }

  .lh-lxl-s9 {
    line-height: .5625rem;
  }

  .fs-lxl-10 {
    font-size: .625rem;
  }

  .lh-lxl-s10 {
    line-height: .625rem;
  }

  .fs-lxl-11 {
    font-size: .6875rem;
  }

  .lh-lxl-s11 {
    line-height: .6875rem;
  }

  .fs-lxl-12 {
    font-size: .75rem;
  }

  .lh-lxl-s12 {
    line-height: .75rem;
  }

  .fs-lxl-13 {
    font-size: .8125rem;
  }

  .lh-lxl-s13 {
    line-height: .8125rem;
  }

  .fs-lxl-14 {
    font-size: .875rem;
  }

  .lh-lxl-s14 {
    line-height: .875rem;
  }

  .fs-lxl-15 {
    font-size: .9375rem;
  }

  .lh-lxl-s15 {
    line-height: .9375rem;
  }

  .fs-lxl-16 {
    font-size: 1rem;
  }

  .lh-lxl-s16 {
    line-height: 1rem;
  }

  .fs-lxl-17 {
    font-size: 1.0625rem;
  }

  .lh-lxl-s17 {
    line-height: 1.0625rem;
  }

  .fs-lxl-18 {
    font-size: 1.125rem;
  }

  .lh-lxl-s18 {
    line-height: 1.125rem;
  }

  .fs-lxl-19 {
    font-size: 1.1875rem;
  }

  .lh-lxl-s19 {
    line-height: 1.1875rem;
  }

  .fs-lxl-20 {
    font-size: 1.25rem;
  }

  .lh-lxl-s20 {
    line-height: 1.25rem;
  }

  .fs-lxl-21 {
    font-size: 1.3125rem;
  }

  .lh-lxl-s21 {
    line-height: 1.3125rem;
  }

  .fs-lxl-22 {
    font-size: 1.375rem;
  }

  .lh-lxl-s22 {
    line-height: 1.375rem;
  }

  .fs-lxl-24 {
    font-size: 1.5rem;
  }

  .lh-lxl-s24 {
    line-height: 1.5rem;
  }

  .fs-lxl-25 {
    font-size: 1.5625rem;
  }

  .lh-lxl-s25 {
    line-height: 1.5625rem;
  }

  .fs-lxl-26 {
    font-size: 1.625rem;
  }

  .lh-lxl-s26 {
    line-height: 1.625rem;
  }

  .fs-lxl-28 {
    font-size: 1.75rem;
  }

  .lh-lxl-s28 {
    line-height: 1.75rem;
  }

  .fs-lxl-29 {
    font-size: 1.8125rem;
  }

  .lh-lxl-s29 {
    line-height: 1.8125rem;
  }

  .fs-lxl-30 {
    font-size: 1.875rem;
  }

  .lh-lxl-s30 {
    line-height: 1.875rem;
  }

  .fs-lxl-32 {
    font-size: 2rem;
  }

  .lh-lxl-s32 {
    line-height: 2rem;
  }

  .fs-lxl-34 {
    font-size: 2.125rem;
  }

  .lh-lxl-s34 {
    line-height: 2.125rem;
  }

  .fs-lxl-35 {
    font-size: 2.1875rem;
  }

  .lh-lxl-s35 {
    line-height: 2.1875rem;
  }

  .fs-lxl-36 {
    font-size: 2.25rem;
  }

  .lh-lxl-s36 {
    line-height: 2.25rem;
  }

  .fs-lxl-38 {
    font-size: 2.375rem;
  }

  .lh-lxl-s38 {
    line-height: 2.375rem;
  }

  .fs-lxl-40 {
    font-size: 2.5rem;
  }

  .lh-lxl-s40 {
    line-height: 2.5rem;
  }

  .fs-lxl-45 {
    font-size: 2.8125rem;
  }

  .lh-lxl-s45 {
    line-height: 2.8125rem;
  }

  .fs-lxl-50 {
    font-size: 3.125rem;
  }

  .lh-lxl-s50 {
    line-height: 3.125rem;
  }

  .fs-lxl-56 {
    font-size: 3.5rem;
  }

  .lh-lxl-s56 {
    line-height: 3.5rem;
  }
}

@media screen and (min-width: 1512px) {
  .fs-xlx-5 {
    font-size: .3125rem;
  }

  .lh-xlx-s5 {
    line-height: .3125rem;
  }

  .fs-xlx-7 {
    font-size: .4375rem;
  }

  .lh-xlx-s7 {
    line-height: .4375rem;
  }

  .fs-xlx-8 {
    font-size: .5rem;
  }

  .lh-xlx-s8 {
    line-height: .5rem;
  }

  .fs-xlx-9 {
    font-size: .5625rem;
  }

  .lh-xlx-s9 {
    line-height: .5625rem;
  }

  .fs-xlx-10 {
    font-size: .625rem;
  }

  .lh-xlx-s10 {
    line-height: .625rem;
  }

  .fs-xlx-11 {
    font-size: .6875rem;
  }

  .lh-xlx-s11 {
    line-height: .6875rem;
  }

  .fs-xlx-12 {
    font-size: .75rem;
  }

  .lh-xlx-s12 {
    line-height: .75rem;
  }

  .fs-xlx-13 {
    font-size: .8125rem;
  }

  .lh-xlx-s13 {
    line-height: .8125rem;
  }

  .fs-xlx-14 {
    font-size: .875rem;
  }

  .lh-xlx-s14 {
    line-height: .875rem;
  }

  .fs-xlx-15 {
    font-size: .9375rem;
  }

  .lh-xlx-s15 {
    line-height: .9375rem;
  }

  .fs-xlx-16 {
    font-size: 1rem;
  }

  .lh-xlx-s16 {
    line-height: 1rem;
  }

  .fs-xlx-17 {
    font-size: 1.0625rem;
  }

  .lh-xlx-s17 {
    line-height: 1.0625rem;
  }

  .fs-xlx-18 {
    font-size: 1.125rem;
  }

  .lh-xlx-s18 {
    line-height: 1.125rem;
  }

  .fs-xlx-19 {
    font-size: 1.1875rem;
  }

  .lh-xlx-s19 {
    line-height: 1.1875rem;
  }

  .fs-xlx-20 {
    font-size: 1.25rem;
  }

  .lh-xlx-s20 {
    line-height: 1.25rem;
  }

  .fs-xlx-21 {
    font-size: 1.3125rem;
  }

  .lh-xlx-s21 {
    line-height: 1.3125rem;
  }

  .fs-xlx-22 {
    font-size: 1.375rem;
  }

  .lh-xlx-s22 {
    line-height: 1.375rem;
  }

  .fs-xlx-24 {
    font-size: 1.5rem;
  }

  .lh-xlx-s24 {
    line-height: 1.5rem;
  }

  .fs-xlx-25 {
    font-size: 1.5625rem;
  }

  .lh-xlx-s25 {
    line-height: 1.5625rem;
  }

  .fs-xlx-26 {
    font-size: 1.625rem;
  }

  .lh-xlx-s26 {
    line-height: 1.625rem;
  }

  .fs-xlx-28 {
    font-size: 1.75rem;
  }

  .lh-xlx-s28 {
    line-height: 1.75rem;
  }

  .fs-xlx-29 {
    font-size: 1.8125rem;
  }

  .lh-xlx-s29 {
    line-height: 1.8125rem;
  }

  .fs-xlx-30 {
    font-size: 1.875rem;
  }

  .lh-xlx-s30 {
    line-height: 1.875rem;
  }

  .fs-xlx-32 {
    font-size: 2rem;
  }

  .lh-xlx-s32 {
    line-height: 2rem;
  }

  .fs-xlx-34 {
    font-size: 2.125rem;
  }

  .lh-xlx-s34 {
    line-height: 2.125rem;
  }

  .fs-xlx-35 {
    font-size: 2.1875rem;
  }

  .lh-xlx-s35 {
    line-height: 2.1875rem;
  }

  .fs-xlx-36 {
    font-size: 2.25rem;
  }

  .lh-xlx-s36 {
    line-height: 2.25rem;
  }

  .fs-xlx-38 {
    font-size: 2.375rem;
  }

  .lh-xlx-s38 {
    line-height: 2.375rem;
  }

  .fs-xlx-40 {
    font-size: 2.5rem;
  }

  .lh-xlx-s40 {
    line-height: 2.5rem;
  }

  .fs-xlx-45 {
    font-size: 2.8125rem;
  }

  .lh-xlx-s45 {
    line-height: 2.8125rem;
  }

  .fs-xlx-50 {
    font-size: 3.125rem;
  }

  .lh-xlx-s50 {
    line-height: 3.125rem;
  }

  .fs-xlx-56 {
    font-size: 3.5rem;
  }

  .lh-xlx-s56 {
    line-height: 3.5rem;
  }
}

@media screen and (min-width: 1920px) {
  .fs-xxl-5 {
    font-size: .3125rem;
  }

  .lh-xxl-s5 {
    line-height: .3125rem;
  }

  .fs-xxl-7 {
    font-size: .4375rem;
  }

  .lh-xxl-s7 {
    line-height: .4375rem;
  }

  .fs-xxl-8 {
    font-size: .5rem;
  }

  .lh-xxl-s8 {
    line-height: .5rem;
  }

  .fs-xxl-9 {
    font-size: .5625rem;
  }

  .lh-xxl-s9 {
    line-height: .5625rem;
  }

  .fs-xxl-10 {
    font-size: .625rem;
  }

  .lh-xxl-s10 {
    line-height: .625rem;
  }

  .fs-xxl-11 {
    font-size: .6875rem;
  }

  .lh-xxl-s11 {
    line-height: .6875rem;
  }

  .fs-xxl-12 {
    font-size: .75rem;
  }

  .lh-xxl-s12 {
    line-height: .75rem;
  }

  .fs-xxl-13 {
    font-size: .8125rem;
  }

  .lh-xxl-s13 {
    line-height: .8125rem;
  }

  .fs-xxl-14 {
    font-size: .875rem;
  }

  .lh-xxl-s14 {
    line-height: .875rem;
  }

  .fs-xxl-15 {
    font-size: .9375rem;
  }

  .lh-xxl-s15 {
    line-height: .9375rem;
  }

  .fs-xxl-16 {
    font-size: 1rem;
  }

  .lh-xxl-s16 {
    line-height: 1rem;
  }

  .fs-xxl-17 {
    font-size: 1.0625rem;
  }

  .lh-xxl-s17 {
    line-height: 1.0625rem;
  }

  .fs-xxl-18 {
    font-size: 1.125rem;
  }

  .lh-xxl-s18 {
    line-height: 1.125rem;
  }

  .fs-xxl-19 {
    font-size: 1.1875rem;
  }

  .lh-xxl-s19 {
    line-height: 1.1875rem;
  }

  .fs-xxl-20 {
    font-size: 1.25rem;
  }

  .lh-xxl-s20 {
    line-height: 1.25rem;
  }

  .fs-xxl-21 {
    font-size: 1.3125rem;
  }

  .lh-xxl-s21 {
    line-height: 1.3125rem;
  }

  .fs-xxl-22 {
    font-size: 1.375rem;
  }

  .lh-xxl-s22 {
    line-height: 1.375rem;
  }

  .fs-xxl-24 {
    font-size: 1.5rem;
  }

  .lh-xxl-s24 {
    line-height: 1.5rem;
  }

  .fs-xxl-25 {
    font-size: 1.5625rem;
  }

  .lh-xxl-s25 {
    line-height: 1.5625rem;
  }

  .fs-xxl-26 {
    font-size: 1.625rem;
  }

  .lh-xxl-s26 {
    line-height: 1.625rem;
  }

  .fs-xxl-28 {
    font-size: 1.75rem;
  }

  .lh-xxl-s28 {
    line-height: 1.75rem;
  }

  .fs-xxl-29 {
    font-size: 1.8125rem;
  }

  .lh-xxl-s29 {
    line-height: 1.8125rem;
  }

  .fs-xxl-30 {
    font-size: 1.875rem;
  }

  .lh-xxl-s30 {
    line-height: 1.875rem;
  }

  .fs-xxl-32 {
    font-size: 2rem;
  }

  .lh-xxl-s32 {
    line-height: 2rem;
  }

  .fs-xxl-34 {
    font-size: 2.125rem;
  }

  .lh-xxl-s34 {
    line-height: 2.125rem;
  }

  .fs-xxl-35 {
    font-size: 2.1875rem;
  }

  .lh-xxl-s35 {
    line-height: 2.1875rem;
  }

  .fs-xxl-36 {
    font-size: 2.25rem;
  }

  .lh-xxl-s36 {
    line-height: 2.25rem;
  }

  .fs-xxl-38 {
    font-size: 2.375rem;
  }

  .lh-xxl-s38 {
    line-height: 2.375rem;
  }

  .fs-xxl-40 {
    font-size: 2.5rem;
  }

  .lh-xxl-s40 {
    line-height: 2.5rem;
  }

  .fs-xxl-45 {
    font-size: 2.8125rem;
  }

  .lh-xxl-s45 {
    line-height: 2.8125rem;
  }

  .fs-xxl-50 {
    font-size: 3.125rem;
  }

  .lh-xxl-s50 {
    line-height: 3.125rem;
  }

  .fs-xxl-56 {
    font-size: 3.5rem;
  }

  .lh-xxl-s56 {
    line-height: 3.5rem;
  }
}

.m-s0, .-m-s0 {
  margin: 0;
}

.mt-s0, .-mt-s0 {
  margin-top: 0;
}

.mb-s0, .-mb-s0 {
  margin-bottom: 0;
}

.mr-s0, .-mr-s0 {
  margin-right: 0;
}

.ml-s0, .-ml-s0 {
  margin-left: 0;
}

.p-s0, .-p-s0 {
  padding: 0;
}

.pt-s0, .-pt-s0 {
  padding-top: 0;
}

.pb-s0, .-pb-s0 {
  padding-bottom: 0;
}

.pr-s0, .-pr-s0 {
  padding-right: 0;
}

.pl-s0, .-pl-s0 {
  padding-left: 0;
}

.mx-s0 {
  margin-left: 0;
  margin-right: 0;
}

.my-s0 {
  margin-top: 0;
  margin-bottom: 0;
}

.px-s0 {
  padding-left: 0;
  padding-right: 0;
}

.py-s0 {
  padding-top: 0;
  padding-bottom: 0;
}

.gap-s0 {
  gap: 0;
}

.pm-s0, .gx-s0 > .gx-child, .gx-s0 > .gx-child:first-of-type, .gx-s0 > .gx-child:last-of-type {
  padding-left: 0;
  padding-right: 0;
}

.m-s2 {
  margin: .125rem;
}

.-m-s2 {
  margin: -.125rem;
}

.mt-s2 {
  margin-top: .125rem;
}

.-mt-s2 {
  margin-top: -.125rem;
}

.mb-s2 {
  margin-bottom: .125rem;
}

.-mb-s2 {
  margin-bottom: -.125rem;
}

.mr-s2 {
  margin-right: .125rem;
}

.-mr-s2 {
  margin-right: -.125rem;
}

.ml-s2 {
  margin-left: .125rem;
}

.-ml-s2 {
  margin-left: -.125rem;
}

.p-s2 {
  padding: .125rem;
}

.-p-s2 {
  padding: -.125rem;
}

.pt-s2 {
  padding-top: .125rem;
}

.-pt-s2 {
  padding-top: -.125rem;
}

.pb-s2 {
  padding-bottom: .125rem;
}

.-pb-s2 {
  padding-bottom: -.125rem;
}

.pr-s2 {
  padding-right: .125rem;
}

.-pr-s2 {
  padding-right: -.125rem;
}

.pl-s2 {
  padding-left: .125rem;
}

.-pl-s2 {
  padding-left: -.125rem;
}

.mx-s2 {
  margin-left: .125rem;
  margin-right: .125rem;
}

.my-s2 {
  margin-top: .125rem;
  margin-bottom: .125rem;
}

.px-s2 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.py-s2 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.gap-s2 {
  gap: .125rem;
}

.pm-s2, .gx-s2 > .gx-child {
  padding-left: .0625rem;
  padding-right: .0625rem;
}

.gx-s2 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: .125rem;
}

.gx-s2 > .gx-child:last-of-type {
  padding-left: .125rem;
  padding-right: 0;
}

.m-s3 {
  margin: .1875rem;
}

.-m-s3 {
  margin: -.1875rem;
}

.mt-s3 {
  margin-top: .1875rem;
}

.-mt-s3 {
  margin-top: -.1875rem;
}

.mb-s3 {
  margin-bottom: .1875rem;
}

.-mb-s3 {
  margin-bottom: -.1875rem;
}

.mr-s3 {
  margin-right: .1875rem;
}

.-mr-s3 {
  margin-right: -.1875rem;
}

.ml-s3 {
  margin-left: .1875rem;
}

.-ml-s3 {
  margin-left: -.1875rem;
}

.p-s3 {
  padding: .1875rem;
}

.-p-s3 {
  padding: -.1875rem;
}

.pt-s3 {
  padding-top: .1875rem;
}

.-pt-s3 {
  padding-top: -.1875rem;
}

.pb-s3 {
  padding-bottom: .1875rem;
}

.-pb-s3 {
  padding-bottom: -.1875rem;
}

.pr-s3 {
  padding-right: .1875rem;
}

.-pr-s3 {
  padding-right: -.1875rem;
}

.pl-s3 {
  padding-left: .1875rem;
}

.-pl-s3 {
  padding-left: -.1875rem;
}

.mx-s3 {
  margin-left: .1875rem;
  margin-right: .1875rem;
}

.my-s3 {
  margin-top: .1875rem;
  margin-bottom: .1875rem;
}

.px-s3 {
  padding-left: .1875rem;
  padding-right: .1875rem;
}

.py-s3 {
  padding-top: .1875rem;
  padding-bottom: .1875rem;
}

.gap-s3 {
  gap: .1875rem;
}

.pm-s3, .gx-s3 > .gx-child {
  padding-left: .09375rem;
  padding-right: .09375rem;
}

.gx-s3 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: .1875rem;
}

.gx-s3 > .gx-child:last-of-type {
  padding-left: .1875rem;
  padding-right: 0;
}

.m-s4 {
  margin: .25rem;
}

.-m-s4 {
  margin: -.25rem;
}

.mt-s4 {
  margin-top: .25rem;
}

.-mt-s4 {
  margin-top: -.25rem;
}

.mb-s4 {
  margin-bottom: .25rem;
}

.-mb-s4 {
  margin-bottom: -.25rem;
}

.mr-s4 {
  margin-right: .25rem;
}

.-mr-s4 {
  margin-right: -.25rem;
}

.ml-s4 {
  margin-left: .25rem;
}

.-ml-s4 {
  margin-left: -.25rem;
}

.p-s4 {
  padding: .25rem;
}

.-p-s4 {
  padding: -.25rem;
}

.pt-s4 {
  padding-top: .25rem;
}

.-pt-s4 {
  padding-top: -.25rem;
}

.pb-s4 {
  padding-bottom: .25rem;
}

.-pb-s4 {
  padding-bottom: -.25rem;
}

.pr-s4 {
  padding-right: .25rem;
}

.-pr-s4 {
  padding-right: -.25rem;
}

.pl-s4 {
  padding-left: .25rem;
}

.-pl-s4 {
  padding-left: -.25rem;
}

.mx-s4 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.my-s4 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.px-s4 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.py-s4 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.gap-s4 {
  gap: .25rem;
}

.pm-s4, .gx-s4 > .gx-child {
  padding-left: .125rem;
  padding-right: .125rem;
}

.gx-s4 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: .25rem;
}

.gx-s4 > .gx-child:last-of-type {
  padding-left: .25rem;
  padding-right: 0;
}

.m-s5 {
  margin: .3125rem;
}

.-m-s5 {
  margin: -.3125rem;
}

.mt-s5 {
  margin-top: .3125rem;
}

.-mt-s5 {
  margin-top: -.3125rem;
}

.mb-s5 {
  margin-bottom: .3125rem;
}

.-mb-s5 {
  margin-bottom: -.3125rem;
}

.mr-s5 {
  margin-right: .3125rem;
}

.-mr-s5 {
  margin-right: -.3125rem;
}

.ml-s5 {
  margin-left: .3125rem;
}

.-ml-s5 {
  margin-left: -.3125rem;
}

.p-s5 {
  padding: .3125rem;
}

.-p-s5 {
  padding: -.3125rem;
}

.pt-s5 {
  padding-top: .3125rem;
}

.-pt-s5 {
  padding-top: -.3125rem;
}

.pb-s5 {
  padding-bottom: .3125rem;
}

.-pb-s5 {
  padding-bottom: -.3125rem;
}

.pr-s5 {
  padding-right: .3125rem;
}

.-pr-s5 {
  padding-right: -.3125rem;
}

.pl-s5 {
  padding-left: .3125rem;
}

.-pl-s5 {
  padding-left: -.3125rem;
}

.mx-s5 {
  margin-left: .3125rem;
  margin-right: .3125rem;
}

.my-s5 {
  margin-top: .3125rem;
  margin-bottom: .3125rem;
}

.px-s5 {
  padding-left: .3125rem;
  padding-right: .3125rem;
}

.py-s5 {
  padding-top: .3125rem;
  padding-bottom: .3125rem;
}

.gap-s5 {
  gap: .3125rem;
}

.pm-s5, .gx-s5 > .gx-child {
  padding-left: .15625rem;
  padding-right: .15625rem;
}

.gx-s5 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: .3125rem;
}

.gx-s5 > .gx-child:last-of-type {
  padding-left: .3125rem;
  padding-right: 0;
}

.m-s6 {
  margin: .375rem;
}

.-m-s6 {
  margin: -.375rem;
}

.mt-s6 {
  margin-top: .375rem;
}

.-mt-s6 {
  margin-top: -.375rem;
}

.mb-s6 {
  margin-bottom: .375rem;
}

.-mb-s6 {
  margin-bottom: -.375rem;
}

.mr-s6 {
  margin-right: .375rem;
}

.-mr-s6 {
  margin-right: -.375rem;
}

.ml-s6 {
  margin-left: .375rem;
}

.-ml-s6 {
  margin-left: -.375rem;
}

.p-s6 {
  padding: .375rem;
}

.-p-s6 {
  padding: -.375rem;
}

.pt-s6 {
  padding-top: .375rem;
}

.-pt-s6 {
  padding-top: -.375rem;
}

.pb-s6 {
  padding-bottom: .375rem;
}

.-pb-s6 {
  padding-bottom: -.375rem;
}

.pr-s6 {
  padding-right: .375rem;
}

.-pr-s6 {
  padding-right: -.375rem;
}

.pl-s6 {
  padding-left: .375rem;
}

.-pl-s6 {
  padding-left: -.375rem;
}

.mx-s6 {
  margin-left: .375rem;
  margin-right: .375rem;
}

.my-s6 {
  margin-top: .375rem;
  margin-bottom: .375rem;
}

.px-s6 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.py-s6 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.gap-s6 {
  gap: .375rem;
}

.pm-s6, .gx-s6 > .gx-child {
  padding-left: .1875rem;
  padding-right: .1875rem;
}

.gx-s6 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: .375rem;
}

.gx-s6 > .gx-child:last-of-type {
  padding-left: .375rem;
  padding-right: 0;
}

.m-s7 {
  margin: .4375rem;
}

.-m-s7 {
  margin: -.4375rem;
}

.mt-s7 {
  margin-top: .4375rem;
}

.-mt-s7 {
  margin-top: -.4375rem;
}

.mb-s7 {
  margin-bottom: .4375rem;
}

.-mb-s7 {
  margin-bottom: -.4375rem;
}

.mr-s7 {
  margin-right: .4375rem;
}

.-mr-s7 {
  margin-right: -.4375rem;
}

.ml-s7 {
  margin-left: .4375rem;
}

.-ml-s7 {
  margin-left: -.4375rem;
}

.p-s7 {
  padding: .4375rem;
}

.-p-s7 {
  padding: -.4375rem;
}

.pt-s7 {
  padding-top: .4375rem;
}

.-pt-s7 {
  padding-top: -.4375rem;
}

.pb-s7 {
  padding-bottom: .4375rem;
}

.-pb-s7 {
  padding-bottom: -.4375rem;
}

.pr-s7 {
  padding-right: .4375rem;
}

.-pr-s7 {
  padding-right: -.4375rem;
}

.pl-s7 {
  padding-left: .4375rem;
}

.-pl-s7 {
  padding-left: -.4375rem;
}

.mx-s7 {
  margin-left: .4375rem;
  margin-right: .4375rem;
}

.my-s7 {
  margin-top: .4375rem;
  margin-bottom: .4375rem;
}

.px-s7 {
  padding-left: .4375rem;
  padding-right: .4375rem;
}

.py-s7 {
  padding-top: .4375rem;
  padding-bottom: .4375rem;
}

.gap-s7 {
  gap: .4375rem;
}

.pm-s7, .gx-s7 > .gx-child {
  padding-left: .21875rem;
  padding-right: .21875rem;
}

.gx-s7 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: .4375rem;
}

.gx-s7 > .gx-child:last-of-type {
  padding-left: .4375rem;
  padding-right: 0;
}

.m-s8 {
  margin: .5rem;
}

.-m-s8 {
  margin: -.5rem;
}

.mt-s8 {
  margin-top: .5rem;
}

.-mt-s8 {
  margin-top: -.5rem;
}

.mb-s8 {
  margin-bottom: .5rem;
}

.-mb-s8 {
  margin-bottom: -.5rem;
}

.mr-s8 {
  margin-right: .5rem;
}

.-mr-s8 {
  margin-right: -.5rem;
}

.ml-s8 {
  margin-left: .5rem;
}

.-ml-s8 {
  margin-left: -.5rem;
}

.p-s8 {
  padding: .5rem;
}

.-p-s8 {
  padding: -.5rem;
}

.pt-s8 {
  padding-top: .5rem;
}

.-pt-s8 {
  padding-top: -.5rem;
}

.pb-s8 {
  padding-bottom: .5rem;
}

.-pb-s8 {
  padding-bottom: -.5rem;
}

.pr-s8 {
  padding-right: .5rem;
}

.-pr-s8 {
  padding-right: -.5rem;
}

.pl-s8 {
  padding-left: .5rem;
}

.-pl-s8 {
  padding-left: -.5rem;
}

.mx-s8 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-s8 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.px-s8 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-s8 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.gap-s8 {
  gap: .5rem;
}

.pm-s8, .gx-s8 > .gx-child {
  padding-left: .25rem;
  padding-right: .25rem;
}

.gx-s8 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: .5rem;
}

.gx-s8 > .gx-child:last-of-type {
  padding-left: .5rem;
  padding-right: 0;
}

.m-s9 {
  margin: .5625rem;
}

.-m-s9 {
  margin: -.5625rem;
}

.mt-s9 {
  margin-top: .5625rem;
}

.-mt-s9 {
  margin-top: -.5625rem;
}

.mb-s9 {
  margin-bottom: .5625rem;
}

.-mb-s9 {
  margin-bottom: -.5625rem;
}

.mr-s9 {
  margin-right: .5625rem;
}

.-mr-s9 {
  margin-right: -.5625rem;
}

.ml-s9 {
  margin-left: .5625rem;
}

.-ml-s9 {
  margin-left: -.5625rem;
}

.p-s9 {
  padding: .5625rem;
}

.-p-s9 {
  padding: -.5625rem;
}

.pt-s9 {
  padding-top: .5625rem;
}

.-pt-s9 {
  padding-top: -.5625rem;
}

.pb-s9 {
  padding-bottom: .5625rem;
}

.-pb-s9 {
  padding-bottom: -.5625rem;
}

.pr-s9 {
  padding-right: .5625rem;
}

.-pr-s9 {
  padding-right: -.5625rem;
}

.pl-s9 {
  padding-left: .5625rem;
}

.-pl-s9 {
  padding-left: -.5625rem;
}

.mx-s9 {
  margin-left: .5625rem;
  margin-right: .5625rem;
}

.my-s9 {
  margin-top: .5625rem;
  margin-bottom: .5625rem;
}

.px-s9 {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.py-s9 {
  padding-top: .5625rem;
  padding-bottom: .5625rem;
}

.gap-s9 {
  gap: .5625rem;
}

.pm-s9, .gx-s9 > .gx-child {
  padding-left: .28125rem;
  padding-right: .28125rem;
}

.gx-s9 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: .5625rem;
}

.gx-s9 > .gx-child:last-of-type {
  padding-left: .5625rem;
  padding-right: 0;
}

.m-s10 {
  margin: .625rem;
}

.-m-s10 {
  margin: -.625rem;
}

.mt-s10 {
  margin-top: .625rem;
}

.-mt-s10 {
  margin-top: -.625rem;
}

.mb-s10 {
  margin-bottom: .625rem;
}

.-mb-s10 {
  margin-bottom: -.625rem;
}

.mr-s10 {
  margin-right: .625rem;
}

.-mr-s10 {
  margin-right: -.625rem;
}

.ml-s10 {
  margin-left: .625rem;
}

.-ml-s10 {
  margin-left: -.625rem;
}

.p-s10 {
  padding: .625rem;
}

.-p-s10 {
  padding: -.625rem;
}

.pt-s10 {
  padding-top: .625rem;
}

.-pt-s10 {
  padding-top: -.625rem;
}

.pb-s10 {
  padding-bottom: .625rem;
}

.-pb-s10 {
  padding-bottom: -.625rem;
}

.pr-s10 {
  padding-right: .625rem;
}

.-pr-s10 {
  padding-right: -.625rem;
}

.pl-s10 {
  padding-left: .625rem;
}

.-pl-s10 {
  padding-left: -.625rem;
}

.mx-s10 {
  margin-left: .625rem;
  margin-right: .625rem;
}

.my-s10 {
  margin-top: .625rem;
  margin-bottom: .625rem;
}

.px-s10 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.py-s10 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.gap-s10 {
  gap: .625rem;
}

.pm-s10, .gx-s10 > .gx-child {
  padding-left: .3125rem;
  padding-right: .3125rem;
}

.gx-s10 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: .625rem;
}

.gx-s10 > .gx-child:last-of-type {
  padding-left: .625rem;
  padding-right: 0;
}

.m-s12 {
  margin: .75rem;
}

.-m-s12 {
  margin: -.75rem;
}

.mt-s12 {
  margin-top: .75rem;
}

.-mt-s12 {
  margin-top: -.75rem;
}

.mb-s12 {
  margin-bottom: .75rem;
}

.-mb-s12 {
  margin-bottom: -.75rem;
}

.mr-s12 {
  margin-right: .75rem;
}

.-mr-s12 {
  margin-right: -.75rem;
}

.ml-s12 {
  margin-left: .75rem;
}

.-ml-s12 {
  margin-left: -.75rem;
}

.p-s12 {
  padding: .75rem;
}

.-p-s12 {
  padding: -.75rem;
}

.pt-s12 {
  padding-top: .75rem;
}

.-pt-s12 {
  padding-top: -.75rem;
}

.pb-s12 {
  padding-bottom: .75rem;
}

.-pb-s12 {
  padding-bottom: -.75rem;
}

.pr-s12 {
  padding-right: .75rem;
}

.-pr-s12 {
  padding-right: -.75rem;
}

.pl-s12 {
  padding-left: .75rem;
}

.-pl-s12 {
  padding-left: -.75rem;
}

.mx-s12 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.my-s12 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.px-s12 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-s12 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.gap-s12 {
  gap: .75rem;
}

.pm-s12, .gx-s12 > .gx-child {
  padding-left: .375rem;
  padding-right: .375rem;
}

.gx-s12 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: .75rem;
}

.gx-s12 > .gx-child:last-of-type {
  padding-left: .75rem;
  padding-right: 0;
}

.m-s13 {
  margin: .8125rem;
}

.-m-s13 {
  margin: -.8125rem;
}

.mt-s13 {
  margin-top: .8125rem;
}

.-mt-s13 {
  margin-top: -.8125rem;
}

.mb-s13 {
  margin-bottom: .8125rem;
}

.-mb-s13 {
  margin-bottom: -.8125rem;
}

.mr-s13 {
  margin-right: .8125rem;
}

.-mr-s13 {
  margin-right: -.8125rem;
}

.ml-s13 {
  margin-left: .8125rem;
}

.-ml-s13 {
  margin-left: -.8125rem;
}

.p-s13 {
  padding: .8125rem;
}

.-p-s13 {
  padding: -.8125rem;
}

.pt-s13 {
  padding-top: .8125rem;
}

.-pt-s13 {
  padding-top: -.8125rem;
}

.pb-s13 {
  padding-bottom: .8125rem;
}

.-pb-s13 {
  padding-bottom: -.8125rem;
}

.pr-s13 {
  padding-right: .8125rem;
}

.-pr-s13 {
  padding-right: -.8125rem;
}

.pl-s13 {
  padding-left: .8125rem;
}

.-pl-s13 {
  padding-left: -.8125rem;
}

.mx-s13 {
  margin-left: .8125rem;
  margin-right: .8125rem;
}

.my-s13 {
  margin-top: .8125rem;
  margin-bottom: .8125rem;
}

.px-s13 {
  padding-left: .8125rem;
  padding-right: .8125rem;
}

.py-s13 {
  padding-top: .8125rem;
  padding-bottom: .8125rem;
}

.gap-s13 {
  gap: .8125rem;
}

.pm-s13, .gx-s13 > .gx-child {
  padding-left: .40625rem;
  padding-right: .40625rem;
}

.gx-s13 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: .8125rem;
}

.gx-s13 > .gx-child:last-of-type {
  padding-left: .8125rem;
  padding-right: 0;
}

.m-s14 {
  margin: .875rem;
}

.-m-s14 {
  margin: -.875rem;
}

.mt-s14 {
  margin-top: .875rem;
}

.-mt-s14 {
  margin-top: -.875rem;
}

.mb-s14 {
  margin-bottom: .875rem;
}

.-mb-s14 {
  margin-bottom: -.875rem;
}

.mr-s14 {
  margin-right: .875rem;
}

.-mr-s14 {
  margin-right: -.875rem;
}

.ml-s14 {
  margin-left: .875rem;
}

.-ml-s14 {
  margin-left: -.875rem;
}

.p-s14 {
  padding: .875rem;
}

.-p-s14 {
  padding: -.875rem;
}

.pt-s14 {
  padding-top: .875rem;
}

.-pt-s14 {
  padding-top: -.875rem;
}

.pb-s14 {
  padding-bottom: .875rem;
}

.-pb-s14 {
  padding-bottom: -.875rem;
}

.pr-s14 {
  padding-right: .875rem;
}

.-pr-s14 {
  padding-right: -.875rem;
}

.pl-s14 {
  padding-left: .875rem;
}

.-pl-s14 {
  padding-left: -.875rem;
}

.mx-s14 {
  margin-left: .875rem;
  margin-right: .875rem;
}

.my-s14 {
  margin-top: .875rem;
  margin-bottom: .875rem;
}

.px-s14 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.py-s14 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.gap-s14 {
  gap: .875rem;
}

.pm-s14, .gx-s14 > .gx-child {
  padding-left: .4375rem;
  padding-right: .4375rem;
}

.gx-s14 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: .875rem;
}

.gx-s14 > .gx-child:last-of-type {
  padding-left: .875rem;
  padding-right: 0;
}

.m-s15 {
  margin: .9375rem;
}

.-m-s15 {
  margin: -.9375rem;
}

.mt-s15 {
  margin-top: .9375rem;
}

.-mt-s15 {
  margin-top: -.9375rem;
}

.mb-s15 {
  margin-bottom: .9375rem;
}

.-mb-s15 {
  margin-bottom: -.9375rem;
}

.mr-s15 {
  margin-right: .9375rem;
}

.-mr-s15 {
  margin-right: -.9375rem;
}

.ml-s15 {
  margin-left: .9375rem;
}

.-ml-s15 {
  margin-left: -.9375rem;
}

.p-s15 {
  padding: .9375rem;
}

.-p-s15 {
  padding: -.9375rem;
}

.pt-s15 {
  padding-top: .9375rem;
}

.-pt-s15 {
  padding-top: -.9375rem;
}

.pb-s15 {
  padding-bottom: .9375rem;
}

.-pb-s15 {
  padding-bottom: -.9375rem;
}

.pr-s15 {
  padding-right: .9375rem;
}

.-pr-s15 {
  padding-right: -.9375rem;
}

.pl-s15 {
  padding-left: .9375rem;
}

.-pl-s15 {
  padding-left: -.9375rem;
}

.mx-s15 {
  margin-left: .9375rem;
  margin-right: .9375rem;
}

.my-s15 {
  margin-top: .9375rem;
  margin-bottom: .9375rem;
}

.px-s15 {
  padding-left: .9375rem;
  padding-right: .9375rem;
}

.py-s15 {
  padding-top: .9375rem;
  padding-bottom: .9375rem;
}

.gap-s15 {
  gap: .9375rem;
}

.pm-s15, .gx-s15 > .gx-child {
  padding-left: .46875rem;
  padding-right: .46875rem;
}

.gx-s15 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: .9375rem;
}

.gx-s15 > .gx-child:last-of-type {
  padding-left: .9375rem;
  padding-right: 0;
}

.m-s16 {
  margin: 1rem;
}

.-m-s16 {
  margin: -1rem;
}

.mt-s16 {
  margin-top: 1rem;
}

.-mt-s16 {
  margin-top: -1rem;
}

.mb-s16 {
  margin-bottom: 1rem;
}

.-mb-s16 {
  margin-bottom: -1rem;
}

.mr-s16 {
  margin-right: 1rem;
}

.-mr-s16 {
  margin-right: -1rem;
}

.ml-s16 {
  margin-left: 1rem;
}

.-ml-s16 {
  margin-left: -1rem;
}

.p-s16 {
  padding: 1rem;
}

.-p-s16 {
  padding: -1rem;
}

.pt-s16 {
  padding-top: 1rem;
}

.-pt-s16 {
  padding-top: -1rem;
}

.pb-s16 {
  padding-bottom: 1rem;
}

.-pb-s16 {
  padding-bottom: -1rem;
}

.pr-s16 {
  padding-right: 1rem;
}

.-pr-s16 {
  padding-right: -1rem;
}

.pl-s16 {
  padding-left: 1rem;
}

.-pl-s16 {
  padding-left: -1rem;
}

.mx-s16 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-s16 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.px-s16 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-s16 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.gap-s16 {
  gap: 1rem;
}

.pm-s16, .gx-s16 > .gx-child {
  padding-left: .5rem;
  padding-right: .5rem;
}

.gx-s16 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 1rem;
}

.gx-s16 > .gx-child:last-of-type {
  padding-left: 1rem;
  padding-right: 0;
}

.m-s18 {
  margin: 1.125rem;
}

.-m-s18 {
  margin: -1.125rem;
}

.mt-s18 {
  margin-top: 1.125rem;
}

.-mt-s18 {
  margin-top: -1.125rem;
}

.mb-s18 {
  margin-bottom: 1.125rem;
}

.-mb-s18 {
  margin-bottom: -1.125rem;
}

.mr-s18 {
  margin-right: 1.125rem;
}

.-mr-s18 {
  margin-right: -1.125rem;
}

.ml-s18 {
  margin-left: 1.125rem;
}

.-ml-s18 {
  margin-left: -1.125rem;
}

.p-s18 {
  padding: 1.125rem;
}

.-p-s18 {
  padding: -1.125rem;
}

.pt-s18 {
  padding-top: 1.125rem;
}

.-pt-s18 {
  padding-top: -1.125rem;
}

.pb-s18 {
  padding-bottom: 1.125rem;
}

.-pb-s18 {
  padding-bottom: -1.125rem;
}

.pr-s18 {
  padding-right: 1.125rem;
}

.-pr-s18 {
  padding-right: -1.125rem;
}

.pl-s18 {
  padding-left: 1.125rem;
}

.-pl-s18 {
  padding-left: -1.125rem;
}

.mx-s18 {
  margin-left: 1.125rem;
  margin-right: 1.125rem;
}

.my-s18 {
  margin-top: 1.125rem;
  margin-bottom: 1.125rem;
}

.px-s18 {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.py-s18 {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}

.gap-s18 {
  gap: 1.125rem;
}

.pm-s18, .gx-s18 > .gx-child {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.gx-s18 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 1.125rem;
}

.gx-s18 > .gx-child:last-of-type {
  padding-left: 1.125rem;
  padding-right: 0;
}

.m-s20 {
  margin: 1.25rem;
}

.-m-s20 {
  margin: -1.25rem;
}

.mt-s20 {
  margin-top: 1.25rem;
}

.-mt-s20 {
  margin-top: -1.25rem;
}

.mb-s20 {
  margin-bottom: 1.25rem;
}

.-mb-s20 {
  margin-bottom: -1.25rem;
}

.mr-s20 {
  margin-right: 1.25rem;
}

.-mr-s20 {
  margin-right: -1.25rem;
}

.ml-s20 {
  margin-left: 1.25rem;
}

.-ml-s20 {
  margin-left: -1.25rem;
}

.p-s20 {
  padding: 1.25rem;
}

.-p-s20 {
  padding: -1.25rem;
}

.pt-s20 {
  padding-top: 1.25rem;
}

.-pt-s20 {
  padding-top: -1.25rem;
}

.pb-s20 {
  padding-bottom: 1.25rem;
}

.-pb-s20 {
  padding-bottom: -1.25rem;
}

.pr-s20 {
  padding-right: 1.25rem;
}

.-pr-s20 {
  padding-right: -1.25rem;
}

.pl-s20 {
  padding-left: 1.25rem;
}

.-pl-s20 {
  padding-left: -1.25rem;
}

.mx-s20 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-s20 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.px-s20 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-s20 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.gap-s20 {
  gap: 1.25rem;
}

.pm-s20, .gx-s20 > .gx-child {
  padding-left: .625rem;
  padding-right: .625rem;
}

.gx-s20 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 1.25rem;
}

.gx-s20 > .gx-child:last-of-type {
  padding-left: 1.25rem;
  padding-right: 0;
}

.m-s22 {
  margin: 1.375rem;
}

.-m-s22 {
  margin: -1.375rem;
}

.mt-s22 {
  margin-top: 1.375rem;
}

.-mt-s22 {
  margin-top: -1.375rem;
}

.mb-s22 {
  margin-bottom: 1.375rem;
}

.-mb-s22 {
  margin-bottom: -1.375rem;
}

.mr-s22 {
  margin-right: 1.375rem;
}

.-mr-s22 {
  margin-right: -1.375rem;
}

.ml-s22 {
  margin-left: 1.375rem;
}

.-ml-s22 {
  margin-left: -1.375rem;
}

.p-s22 {
  padding: 1.375rem;
}

.-p-s22 {
  padding: -1.375rem;
}

.pt-s22 {
  padding-top: 1.375rem;
}

.-pt-s22 {
  padding-top: -1.375rem;
}

.pb-s22 {
  padding-bottom: 1.375rem;
}

.-pb-s22 {
  padding-bottom: -1.375rem;
}

.pr-s22 {
  padding-right: 1.375rem;
}

.-pr-s22 {
  padding-right: -1.375rem;
}

.pl-s22 {
  padding-left: 1.375rem;
}

.-pl-s22 {
  padding-left: -1.375rem;
}

.mx-s22 {
  margin-left: 1.375rem;
  margin-right: 1.375rem;
}

.my-s22 {
  margin-top: 1.375rem;
  margin-bottom: 1.375rem;
}

.px-s22 {
  padding-left: 1.375rem;
  padding-right: 1.375rem;
}

.py-s22 {
  padding-top: 1.375rem;
  padding-bottom: 1.375rem;
}

.gap-s22 {
  gap: 1.375rem;
}

.pm-s22, .gx-s22 > .gx-child {
  padding-left: .6875rem;
  padding-right: .6875rem;
}

.gx-s22 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 1.375rem;
}

.gx-s22 > .gx-child:last-of-type {
  padding-left: 1.375rem;
  padding-right: 0;
}

.m-s24 {
  margin: 1.5rem;
}

.-m-s24 {
  margin: -1.5rem;
}

.mt-s24 {
  margin-top: 1.5rem;
}

.-mt-s24 {
  margin-top: -1.5rem;
}

.mb-s24 {
  margin-bottom: 1.5rem;
}

.-mb-s24 {
  margin-bottom: -1.5rem;
}

.mr-s24 {
  margin-right: 1.5rem;
}

.-mr-s24 {
  margin-right: -1.5rem;
}

.ml-s24 {
  margin-left: 1.5rem;
}

.-ml-s24 {
  margin-left: -1.5rem;
}

.p-s24 {
  padding: 1.5rem;
}

.-p-s24 {
  padding: -1.5rem;
}

.pt-s24 {
  padding-top: 1.5rem;
}

.-pt-s24 {
  padding-top: -1.5rem;
}

.pb-s24 {
  padding-bottom: 1.5rem;
}

.-pb-s24 {
  padding-bottom: -1.5rem;
}

.pr-s24 {
  padding-right: 1.5rem;
}

.-pr-s24 {
  padding-right: -1.5rem;
}

.pl-s24 {
  padding-left: 1.5rem;
}

.-pl-s24 {
  padding-left: -1.5rem;
}

.mx-s24 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-s24 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.px-s24 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-s24 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.gap-s24 {
  gap: 1.5rem;
}

.pm-s24, .gx-s24 > .gx-child {
  padding-left: .75rem;
  padding-right: .75rem;
}

.gx-s24 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 1.5rem;
}

.gx-s24 > .gx-child:last-of-type {
  padding-left: 1.5rem;
  padding-right: 0;
}

.m-s25 {
  margin: 1.5625rem;
}

.-m-s25 {
  margin: -1.5625rem;
}

.mt-s25 {
  margin-top: 1.5625rem;
}

.-mt-s25 {
  margin-top: -1.5625rem;
}

.mb-s25 {
  margin-bottom: 1.5625rem;
}

.-mb-s25 {
  margin-bottom: -1.5625rem;
}

.mr-s25 {
  margin-right: 1.5625rem;
}

.-mr-s25 {
  margin-right: -1.5625rem;
}

.ml-s25 {
  margin-left: 1.5625rem;
}

.-ml-s25 {
  margin-left: -1.5625rem;
}

.p-s25 {
  padding: 1.5625rem;
}

.-p-s25 {
  padding: -1.5625rem;
}

.pt-s25 {
  padding-top: 1.5625rem;
}

.-pt-s25 {
  padding-top: -1.5625rem;
}

.pb-s25 {
  padding-bottom: 1.5625rem;
}

.-pb-s25 {
  padding-bottom: -1.5625rem;
}

.pr-s25 {
  padding-right: 1.5625rem;
}

.-pr-s25 {
  padding-right: -1.5625rem;
}

.pl-s25 {
  padding-left: 1.5625rem;
}

.-pl-s25 {
  padding-left: -1.5625rem;
}

.mx-s25 {
  margin-left: 1.5625rem;
  margin-right: 1.5625rem;
}

.my-s25 {
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
}

.px-s25 {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

.py-s25 {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
}

.gap-s25 {
  gap: 1.5625rem;
}

.pm-s25, .gx-s25 > .gx-child {
  padding-left: .78125rem;
  padding-right: .78125rem;
}

.gx-s25 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 1.5625rem;
}

.gx-s25 > .gx-child:last-of-type {
  padding-left: 1.5625rem;
  padding-right: 0;
}

.m-s27 {
  margin: 1.6875rem;
}

.-m-s27 {
  margin: -1.6875rem;
}

.mt-s27 {
  margin-top: 1.6875rem;
}

.-mt-s27 {
  margin-top: -1.6875rem;
}

.mb-s27 {
  margin-bottom: 1.6875rem;
}

.-mb-s27 {
  margin-bottom: -1.6875rem;
}

.mr-s27 {
  margin-right: 1.6875rem;
}

.-mr-s27 {
  margin-right: -1.6875rem;
}

.ml-s27 {
  margin-left: 1.6875rem;
}

.-ml-s27 {
  margin-left: -1.6875rem;
}

.p-s27 {
  padding: 1.6875rem;
}

.-p-s27 {
  padding: -1.6875rem;
}

.pt-s27 {
  padding-top: 1.6875rem;
}

.-pt-s27 {
  padding-top: -1.6875rem;
}

.pb-s27 {
  padding-bottom: 1.6875rem;
}

.-pb-s27 {
  padding-bottom: -1.6875rem;
}

.pr-s27 {
  padding-right: 1.6875rem;
}

.-pr-s27 {
  padding-right: -1.6875rem;
}

.pl-s27 {
  padding-left: 1.6875rem;
}

.-pl-s27 {
  padding-left: -1.6875rem;
}

.mx-s27 {
  margin-left: 1.6875rem;
  margin-right: 1.6875rem;
}

.my-s27 {
  margin-top: 1.6875rem;
  margin-bottom: 1.6875rem;
}

.px-s27 {
  padding-left: 1.6875rem;
  padding-right: 1.6875rem;
}

.py-s27 {
  padding-top: 1.6875rem;
  padding-bottom: 1.6875rem;
}

.gap-s27 {
  gap: 1.6875rem;
}

.pm-s27, .gx-s27 > .gx-child {
  padding-left: .84375rem;
  padding-right: .84375rem;
}

.gx-s27 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 1.6875rem;
}

.gx-s27 > .gx-child:last-of-type {
  padding-left: 1.6875rem;
  padding-right: 0;
}

.m-s29 {
  margin: 1.8125rem;
}

.-m-s29 {
  margin: -1.8125rem;
}

.mt-s29 {
  margin-top: 1.8125rem;
}

.-mt-s29 {
  margin-top: -1.8125rem;
}

.mb-s29 {
  margin-bottom: 1.8125rem;
}

.-mb-s29 {
  margin-bottom: -1.8125rem;
}

.mr-s29 {
  margin-right: 1.8125rem;
}

.-mr-s29 {
  margin-right: -1.8125rem;
}

.ml-s29 {
  margin-left: 1.8125rem;
}

.-ml-s29 {
  margin-left: -1.8125rem;
}

.p-s29 {
  padding: 1.8125rem;
}

.-p-s29 {
  padding: -1.8125rem;
}

.pt-s29 {
  padding-top: 1.8125rem;
}

.-pt-s29 {
  padding-top: -1.8125rem;
}

.pb-s29 {
  padding-bottom: 1.8125rem;
}

.-pb-s29 {
  padding-bottom: -1.8125rem;
}

.pr-s29 {
  padding-right: 1.8125rem;
}

.-pr-s29 {
  padding-right: -1.8125rem;
}

.pl-s29 {
  padding-left: 1.8125rem;
}

.-pl-s29 {
  padding-left: -1.8125rem;
}

.mx-s29 {
  margin-left: 1.8125rem;
  margin-right: 1.8125rem;
}

.my-s29 {
  margin-top: 1.8125rem;
  margin-bottom: 1.8125rem;
}

.px-s29 {
  padding-left: 1.8125rem;
  padding-right: 1.8125rem;
}

.py-s29 {
  padding-top: 1.8125rem;
  padding-bottom: 1.8125rem;
}

.gap-s29 {
  gap: 1.8125rem;
}

.pm-s29, .gx-s29 > .gx-child {
  padding-left: .90625rem;
  padding-right: .90625rem;
}

.gx-s29 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 1.8125rem;
}

.gx-s29 > .gx-child:last-of-type {
  padding-left: 1.8125rem;
  padding-right: 0;
}

.m-s30 {
  margin: 1.875rem;
}

.-m-s30 {
  margin: -1.875rem;
}

.mt-s30 {
  margin-top: 1.875rem;
}

.-mt-s30 {
  margin-top: -1.875rem;
}

.mb-s30 {
  margin-bottom: 1.875rem;
}

.-mb-s30 {
  margin-bottom: -1.875rem;
}

.mr-s30 {
  margin-right: 1.875rem;
}

.-mr-s30 {
  margin-right: -1.875rem;
}

.ml-s30 {
  margin-left: 1.875rem;
}

.-ml-s30 {
  margin-left: -1.875rem;
}

.p-s30 {
  padding: 1.875rem;
}

.-p-s30 {
  padding: -1.875rem;
}

.pt-s30 {
  padding-top: 1.875rem;
}

.-pt-s30 {
  padding-top: -1.875rem;
}

.pb-s30 {
  padding-bottom: 1.875rem;
}

.-pb-s30 {
  padding-bottom: -1.875rem;
}

.pr-s30 {
  padding-right: 1.875rem;
}

.-pr-s30 {
  padding-right: -1.875rem;
}

.pl-s30 {
  padding-left: 1.875rem;
}

.-pl-s30 {
  padding-left: -1.875rem;
}

.mx-s30 {
  margin-left: 1.875rem;
  margin-right: 1.875rem;
}

.my-s30 {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem;
}

.px-s30 {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.py-s30 {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.gap-s30 {
  gap: 1.875rem;
}

.pm-s30, .gx-s30 > .gx-child {
  padding-left: .9375rem;
  padding-right: .9375rem;
}

.gx-s30 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 1.875rem;
}

.gx-s30 > .gx-child:last-of-type {
  padding-left: 1.875rem;
  padding-right: 0;
}

.m-s32 {
  margin: 2rem;
}

.-m-s32 {
  margin: -2rem;
}

.mt-s32 {
  margin-top: 2rem;
}

.-mt-s32 {
  margin-top: -2rem;
}

.mb-s32 {
  margin-bottom: 2rem;
}

.-mb-s32 {
  margin-bottom: -2rem;
}

.mr-s32 {
  margin-right: 2rem;
}

.-mr-s32 {
  margin-right: -2rem;
}

.ml-s32 {
  margin-left: 2rem;
}

.-ml-s32 {
  margin-left: -2rem;
}

.p-s32 {
  padding: 2rem;
}

.-p-s32 {
  padding: -2rem;
}

.pt-s32 {
  padding-top: 2rem;
}

.-pt-s32 {
  padding-top: -2rem;
}

.pb-s32 {
  padding-bottom: 2rem;
}

.-pb-s32 {
  padding-bottom: -2rem;
}

.pr-s32 {
  padding-right: 2rem;
}

.-pr-s32 {
  padding-right: -2rem;
}

.pl-s32 {
  padding-left: 2rem;
}

.-pl-s32 {
  padding-left: -2rem;
}

.mx-s32 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-s32 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.px-s32 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-s32 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.gap-s32 {
  gap: 2rem;
}

.pm-s32, .gx-s32 > .gx-child {
  padding-left: 1rem;
  padding-right: 1rem;
}

.gx-s32 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 2rem;
}

.gx-s32 > .gx-child:last-of-type {
  padding-left: 2rem;
  padding-right: 0;
}

.m-s35 {
  margin: 2.1875rem;
}

.-m-s35 {
  margin: -2.1875rem;
}

.mt-s35 {
  margin-top: 2.1875rem;
}

.-mt-s35 {
  margin-top: -2.1875rem;
}

.mb-s35 {
  margin-bottom: 2.1875rem;
}

.-mb-s35 {
  margin-bottom: -2.1875rem;
}

.mr-s35 {
  margin-right: 2.1875rem;
}

.-mr-s35 {
  margin-right: -2.1875rem;
}

.ml-s35 {
  margin-left: 2.1875rem;
}

.-ml-s35 {
  margin-left: -2.1875rem;
}

.p-s35 {
  padding: 2.1875rem;
}

.-p-s35 {
  padding: -2.1875rem;
}

.pt-s35 {
  padding-top: 2.1875rem;
}

.-pt-s35 {
  padding-top: -2.1875rem;
}

.pb-s35 {
  padding-bottom: 2.1875rem;
}

.-pb-s35 {
  padding-bottom: -2.1875rem;
}

.pr-s35 {
  padding-right: 2.1875rem;
}

.-pr-s35 {
  padding-right: -2.1875rem;
}

.pl-s35 {
  padding-left: 2.1875rem;
}

.-pl-s35 {
  padding-left: -2.1875rem;
}

.mx-s35 {
  margin-left: 2.1875rem;
  margin-right: 2.1875rem;
}

.my-s35 {
  margin-top: 2.1875rem;
  margin-bottom: 2.1875rem;
}

.px-s35 {
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
}

.py-s35 {
  padding-top: 2.1875rem;
  padding-bottom: 2.1875rem;
}

.gap-s35 {
  gap: 2.1875rem;
}

.pm-s35, .gx-s35 > .gx-child {
  padding-left: 1.09375rem;
  padding-right: 1.09375rem;
}

.gx-s35 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 2.1875rem;
}

.gx-s35 > .gx-child:last-of-type {
  padding-left: 2.1875rem;
  padding-right: 0;
}

.m-s40 {
  margin: 2.5rem;
}

.-m-s40 {
  margin: -2.5rem;
}

.mt-s40 {
  margin-top: 2.5rem;
}

.-mt-s40 {
  margin-top: -2.5rem;
}

.mb-s40 {
  margin-bottom: 2.5rem;
}

.-mb-s40 {
  margin-bottom: -2.5rem;
}

.mr-s40 {
  margin-right: 2.5rem;
}

.-mr-s40 {
  margin-right: -2.5rem;
}

.ml-s40 {
  margin-left: 2.5rem;
}

.-ml-s40 {
  margin-left: -2.5rem;
}

.p-s40 {
  padding: 2.5rem;
}

.-p-s40 {
  padding: -2.5rem;
}

.pt-s40 {
  padding-top: 2.5rem;
}

.-pt-s40 {
  padding-top: -2.5rem;
}

.pb-s40 {
  padding-bottom: 2.5rem;
}

.-pb-s40 {
  padding-bottom: -2.5rem;
}

.pr-s40 {
  padding-right: 2.5rem;
}

.-pr-s40 {
  padding-right: -2.5rem;
}

.pl-s40 {
  padding-left: 2.5rem;
}

.-pl-s40 {
  padding-left: -2.5rem;
}

.mx-s40 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.my-s40 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.px-s40 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-s40 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.gap-s40 {
  gap: 2.5rem;
}

.pm-s40, .gx-s40 > .gx-child {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.gx-s40 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 2.5rem;
}

.gx-s40 > .gx-child:last-of-type {
  padding-left: 2.5rem;
  padding-right: 0;
}

.m-s45 {
  margin: 2.8125rem;
}

.-m-s45 {
  margin: -2.8125rem;
}

.mt-s45 {
  margin-top: 2.8125rem;
}

.-mt-s45 {
  margin-top: -2.8125rem;
}

.mb-s45 {
  margin-bottom: 2.8125rem;
}

.-mb-s45 {
  margin-bottom: -2.8125rem;
}

.mr-s45 {
  margin-right: 2.8125rem;
}

.-mr-s45 {
  margin-right: -2.8125rem;
}

.ml-s45 {
  margin-left: 2.8125rem;
}

.-ml-s45 {
  margin-left: -2.8125rem;
}

.p-s45 {
  padding: 2.8125rem;
}

.-p-s45 {
  padding: -2.8125rem;
}

.pt-s45 {
  padding-top: 2.8125rem;
}

.-pt-s45 {
  padding-top: -2.8125rem;
}

.pb-s45 {
  padding-bottom: 2.8125rem;
}

.-pb-s45 {
  padding-bottom: -2.8125rem;
}

.pr-s45 {
  padding-right: 2.8125rem;
}

.-pr-s45 {
  padding-right: -2.8125rem;
}

.pl-s45 {
  padding-left: 2.8125rem;
}

.-pl-s45 {
  padding-left: -2.8125rem;
}

.mx-s45 {
  margin-left: 2.8125rem;
  margin-right: 2.8125rem;
}

.my-s45 {
  margin-top: 2.8125rem;
  margin-bottom: 2.8125rem;
}

.px-s45 {
  padding-left: 2.8125rem;
  padding-right: 2.8125rem;
}

.py-s45 {
  padding-top: 2.8125rem;
  padding-bottom: 2.8125rem;
}

.gap-s45 {
  gap: 2.8125rem;
}

.pm-s45, .gx-s45 > .gx-child {
  padding-left: 1.40625rem;
  padding-right: 1.40625rem;
}

.gx-s45 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 2.8125rem;
}

.gx-s45 > .gx-child:last-of-type {
  padding-left: 2.8125rem;
  padding-right: 0;
}

.m-s48 {
  margin: 3rem;
}

.-m-s48 {
  margin: -3rem;
}

.mt-s48 {
  margin-top: 3rem;
}

.-mt-s48 {
  margin-top: -3rem;
}

.mb-s48 {
  margin-bottom: 3rem;
}

.-mb-s48 {
  margin-bottom: -3rem;
}

.mr-s48 {
  margin-right: 3rem;
}

.-mr-s48 {
  margin-right: -3rem;
}

.ml-s48 {
  margin-left: 3rem;
}

.-ml-s48 {
  margin-left: -3rem;
}

.p-s48 {
  padding: 3rem;
}

.-p-s48 {
  padding: -3rem;
}

.pt-s48 {
  padding-top: 3rem;
}

.-pt-s48 {
  padding-top: -3rem;
}

.pb-s48 {
  padding-bottom: 3rem;
}

.-pb-s48 {
  padding-bottom: -3rem;
}

.pr-s48 {
  padding-right: 3rem;
}

.-pr-s48 {
  padding-right: -3rem;
}

.pl-s48 {
  padding-left: 3rem;
}

.-pl-s48 {
  padding-left: -3rem;
}

.mx-s48 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.my-s48 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.px-s48 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-s48 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.gap-s48 {
  gap: 3rem;
}

.pm-s48, .gx-s48 > .gx-child {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.gx-s48 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 3rem;
}

.gx-s48 > .gx-child:last-of-type {
  padding-left: 3rem;
  padding-right: 0;
}

.m-s50 {
  margin: 3.125rem;
}

.-m-s50 {
  margin: -3.125rem;
}

.mt-s50 {
  margin-top: 3.125rem;
}

.-mt-s50 {
  margin-top: -3.125rem;
}

.mb-s50 {
  margin-bottom: 3.125rem;
}

.-mb-s50 {
  margin-bottom: -3.125rem;
}

.mr-s50 {
  margin-right: 3.125rem;
}

.-mr-s50 {
  margin-right: -3.125rem;
}

.ml-s50 {
  margin-left: 3.125rem;
}

.-ml-s50 {
  margin-left: -3.125rem;
}

.p-s50 {
  padding: 3.125rem;
}

.-p-s50 {
  padding: -3.125rem;
}

.pt-s50 {
  padding-top: 3.125rem;
}

.-pt-s50 {
  padding-top: -3.125rem;
}

.pb-s50 {
  padding-bottom: 3.125rem;
}

.-pb-s50 {
  padding-bottom: -3.125rem;
}

.pr-s50 {
  padding-right: 3.125rem;
}

.-pr-s50 {
  padding-right: -3.125rem;
}

.pl-s50 {
  padding-left: 3.125rem;
}

.-pl-s50 {
  padding-left: -3.125rem;
}

.mx-s50 {
  margin-left: 3.125rem;
  margin-right: 3.125rem;
}

.my-s50 {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}

.px-s50 {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
}

.py-s50 {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

.gap-s50 {
  gap: 3.125rem;
}

.pm-s50, .gx-s50 > .gx-child {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

.gx-s50 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 3.125rem;
}

.gx-s50 > .gx-child:last-of-type {
  padding-left: 3.125rem;
  padding-right: 0;
}

.m-s60 {
  margin: 3.75rem;
}

.-m-s60 {
  margin: -3.75rem;
}

.mt-s60 {
  margin-top: 3.75rem;
}

.-mt-s60 {
  margin-top: -3.75rem;
}

.mb-s60 {
  margin-bottom: 3.75rem;
}

.-mb-s60 {
  margin-bottom: -3.75rem;
}

.mr-s60 {
  margin-right: 3.75rem;
}

.-mr-s60 {
  margin-right: -3.75rem;
}

.ml-s60 {
  margin-left: 3.75rem;
}

.-ml-s60 {
  margin-left: -3.75rem;
}

.p-s60 {
  padding: 3.75rem;
}

.-p-s60 {
  padding: -3.75rem;
}

.pt-s60 {
  padding-top: 3.75rem;
}

.-pt-s60 {
  padding-top: -3.75rem;
}

.pb-s60 {
  padding-bottom: 3.75rem;
}

.-pb-s60 {
  padding-bottom: -3.75rem;
}

.pr-s60 {
  padding-right: 3.75rem;
}

.-pr-s60 {
  padding-right: -3.75rem;
}

.pl-s60 {
  padding-left: 3.75rem;
}

.-pl-s60 {
  padding-left: -3.75rem;
}

.mx-s60 {
  margin-left: 3.75rem;
  margin-right: 3.75rem;
}

.my-s60 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

.px-s60 {
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}

.py-s60 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

.gap-s60 {
  gap: 3.75rem;
}

.pm-s60, .gx-s60 > .gx-child {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.gx-s60 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 3.75rem;
}

.gx-s60 > .gx-child:last-of-type {
  padding-left: 3.75rem;
  padding-right: 0;
}

.m-s70 {
  margin: 4.375rem;
}

.-m-s70 {
  margin: -4.375rem;
}

.mt-s70 {
  margin-top: 4.375rem;
}

.-mt-s70 {
  margin-top: -4.375rem;
}

.mb-s70 {
  margin-bottom: 4.375rem;
}

.-mb-s70 {
  margin-bottom: -4.375rem;
}

.mr-s70 {
  margin-right: 4.375rem;
}

.-mr-s70 {
  margin-right: -4.375rem;
}

.ml-s70 {
  margin-left: 4.375rem;
}

.-ml-s70 {
  margin-left: -4.375rem;
}

.p-s70 {
  padding: 4.375rem;
}

.-p-s70 {
  padding: -4.375rem;
}

.pt-s70 {
  padding-top: 4.375rem;
}

.-pt-s70 {
  padding-top: -4.375rem;
}

.pb-s70 {
  padding-bottom: 4.375rem;
}

.-pb-s70 {
  padding-bottom: -4.375rem;
}

.pr-s70 {
  padding-right: 4.375rem;
}

.-pr-s70 {
  padding-right: -4.375rem;
}

.pl-s70 {
  padding-left: 4.375rem;
}

.-pl-s70 {
  padding-left: -4.375rem;
}

.mx-s70 {
  margin-left: 4.375rem;
  margin-right: 4.375rem;
}

.my-s70 {
  margin-top: 4.375rem;
  margin-bottom: 4.375rem;
}

.px-s70 {
  padding-left: 4.375rem;
  padding-right: 4.375rem;
}

.py-s70 {
  padding-top: 4.375rem;
  padding-bottom: 4.375rem;
}

.gap-s70 {
  gap: 4.375rem;
}

.pm-s70, .gx-s70 > .gx-child {
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
}

.gx-s70 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 4.375rem;
}

.gx-s70 > .gx-child:last-of-type {
  padding-left: 4.375rem;
  padding-right: 0;
}

.m-s80 {
  margin: 5rem;
}

.-m-s80 {
  margin: -5rem;
}

.mt-s80 {
  margin-top: 5rem;
}

.-mt-s80 {
  margin-top: -5rem;
}

.mb-s80 {
  margin-bottom: 5rem;
}

.-mb-s80 {
  margin-bottom: -5rem;
}

.mr-s80 {
  margin-right: 5rem;
}

.-mr-s80 {
  margin-right: -5rem;
}

.ml-s80 {
  margin-left: 5rem;
}

.-ml-s80 {
  margin-left: -5rem;
}

.p-s80 {
  padding: 5rem;
}

.-p-s80 {
  padding: -5rem;
}

.pt-s80 {
  padding-top: 5rem;
}

.-pt-s80 {
  padding-top: -5rem;
}

.pb-s80 {
  padding-bottom: 5rem;
}

.-pb-s80 {
  padding-bottom: -5rem;
}

.pr-s80 {
  padding-right: 5rem;
}

.-pr-s80 {
  padding-right: -5rem;
}

.pl-s80 {
  padding-left: 5rem;
}

.-pl-s80 {
  padding-left: -5rem;
}

.mx-s80 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.my-s80 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.px-s80 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-s80 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.gap-s80 {
  gap: 5rem;
}

.pm-s80, .gx-s80 > .gx-child {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.gx-s80 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 5rem;
}

.gx-s80 > .gx-child:last-of-type {
  padding-left: 5rem;
  padding-right: 0;
}

.m-s100 {
  margin: 6.25rem;
}

.-m-s100 {
  margin: -6.25rem;
}

.mt-s100 {
  margin-top: 6.25rem;
}

.-mt-s100 {
  margin-top: -6.25rem;
}

.mb-s100 {
  margin-bottom: 6.25rem;
}

.-mb-s100 {
  margin-bottom: -6.25rem;
}

.mr-s100 {
  margin-right: 6.25rem;
}

.-mr-s100 {
  margin-right: -6.25rem;
}

.ml-s100 {
  margin-left: 6.25rem;
}

.-ml-s100 {
  margin-left: -6.25rem;
}

.p-s100 {
  padding: 6.25rem;
}

.-p-s100 {
  padding: -6.25rem;
}

.pt-s100 {
  padding-top: 6.25rem;
}

.-pt-s100 {
  padding-top: -6.25rem;
}

.pb-s100 {
  padding-bottom: 6.25rem;
}

.-pb-s100 {
  padding-bottom: -6.25rem;
}

.pr-s100 {
  padding-right: 6.25rem;
}

.-pr-s100 {
  padding-right: -6.25rem;
}

.pl-s100 {
  padding-left: 6.25rem;
}

.-pl-s100 {
  padding-left: -6.25rem;
}

.mx-s100 {
  margin-left: 6.25rem;
  margin-right: 6.25rem;
}

.my-s100 {
  margin-top: 6.25rem;
  margin-bottom: 6.25rem;
}

.px-s100 {
  padding-left: 6.25rem;
  padding-right: 6.25rem;
}

.py-s100 {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}

.gap-s100 {
  gap: 6.25rem;
}

.pm-s100, .gx-s100 > .gx-child {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
}

.gx-s100 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 6.25rem;
}

.gx-s100 > .gx-child:last-of-type {
  padding-left: 6.25rem;
  padding-right: 0;
}

.m-s120 {
  margin: 7.5rem;
}

.-m-s120 {
  margin: -7.5rem;
}

.mt-s120 {
  margin-top: 7.5rem;
}

.-mt-s120 {
  margin-top: -7.5rem;
}

.mb-s120 {
  margin-bottom: 7.5rem;
}

.-mb-s120 {
  margin-bottom: -7.5rem;
}

.mr-s120 {
  margin-right: 7.5rem;
}

.-mr-s120 {
  margin-right: -7.5rem;
}

.ml-s120 {
  margin-left: 7.5rem;
}

.-ml-s120 {
  margin-left: -7.5rem;
}

.p-s120 {
  padding: 7.5rem;
}

.-p-s120 {
  padding: -7.5rem;
}

.pt-s120 {
  padding-top: 7.5rem;
}

.-pt-s120 {
  padding-top: -7.5rem;
}

.pb-s120 {
  padding-bottom: 7.5rem;
}

.-pb-s120 {
  padding-bottom: -7.5rem;
}

.pr-s120 {
  padding-right: 7.5rem;
}

.-pr-s120 {
  padding-right: -7.5rem;
}

.pl-s120 {
  padding-left: 7.5rem;
}

.-pl-s120 {
  padding-left: -7.5rem;
}

.mx-s120 {
  margin-left: 7.5rem;
  margin-right: 7.5rem;
}

.my-s120 {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}

.px-s120 {
  padding-left: 7.5rem;
  padding-right: 7.5rem;
}

.py-s120 {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.gap-s120 {
  gap: 7.5rem;
}

.pm-s120, .gx-s120 > .gx-child {
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}

.gx-s120 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 7.5rem;
}

.gx-s120 > .gx-child:last-of-type {
  padding-left: 7.5rem;
  padding-right: 0;
}

.m-s150 {
  margin: 9.375rem;
}

.-m-s150 {
  margin: -9.375rem;
}

.mt-s150 {
  margin-top: 9.375rem;
}

.-mt-s150 {
  margin-top: -9.375rem;
}

.mb-s150 {
  margin-bottom: 9.375rem;
}

.-mb-s150 {
  margin-bottom: -9.375rem;
}

.mr-s150 {
  margin-right: 9.375rem;
}

.-mr-s150 {
  margin-right: -9.375rem;
}

.ml-s150 {
  margin-left: 9.375rem;
}

.-ml-s150 {
  margin-left: -9.375rem;
}

.p-s150 {
  padding: 9.375rem;
}

.-p-s150 {
  padding: -9.375rem;
}

.pt-s150 {
  padding-top: 9.375rem;
}

.-pt-s150 {
  padding-top: -9.375rem;
}

.pb-s150 {
  padding-bottom: 9.375rem;
}

.-pb-s150 {
  padding-bottom: -9.375rem;
}

.pr-s150 {
  padding-right: 9.375rem;
}

.-pr-s150 {
  padding-right: -9.375rem;
}

.pl-s150 {
  padding-left: 9.375rem;
}

.-pl-s150 {
  padding-left: -9.375rem;
}

.mx-s150 {
  margin-left: 9.375rem;
  margin-right: 9.375rem;
}

.my-s150 {
  margin-top: 9.375rem;
  margin-bottom: 9.375rem;
}

.px-s150 {
  padding-left: 9.375rem;
  padding-right: 9.375rem;
}

.py-s150 {
  padding-top: 9.375rem;
  padding-bottom: 9.375rem;
}

.gap-s150 {
  gap: 9.375rem;
}

.pm-s150, .gx-s150 > .gx-child {
  padding-left: 4.6875rem;
  padding-right: 4.6875rem;
}

.gx-s150 > .gx-child:first-of-type {
  padding-left: 0;
  padding-right: 9.375rem;
}

.gx-s150 > .gx-child:last-of-type {
  padding-left: 9.375rem;
  padding-right: 0;
}

@media screen and (min-width: 390px) {
  .m-xs-s0, .-m-xs-s0 {
    margin: 0;
  }

  .mt-xs-s0, .-mt-xs-s0 {
    margin-top: 0;
  }

  .mb-xs-s0, .-mb-xs-s0 {
    margin-bottom: 0;
  }

  .mr-xs-s0, .-mr-xs-s0 {
    margin-right: 0;
  }

  .ml-xs-s0, .-ml-xs-s0 {
    margin-left: 0;
  }

  .p-xs-s0, .-p-xs-s0 {
    padding: 0;
  }

  .pt-xs-s0, .-pt-xs-s0 {
    padding-top: 0;
  }

  .pb-xs-s0, .-pb-xs-s0 {
    padding-bottom: 0;
  }

  .pr-xs-s0, .-pr-xs-s0 {
    padding-right: 0;
  }

  .pl-xs-s0, .-pl-xs-s0 {
    padding-left: 0;
  }

  .mx-xs-s0 {
    margin-left: 0;
    margin-right: 0;
  }

  .my-xs-s0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .px-xs-s0 {
    padding-left: 0;
    padding-right: 0;
  }

  .py-xs-s0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .gap-xs-s0 {
    gap: 0;
  }

  .pm-xs-s0, .gx-xs-s0 > .gx-child, .gx-xs-s0 > .gx-child:first-of-type, .gx-xs-s0 > .gx-child:last-of-type {
    padding-left: 0;
    padding-right: 0;
  }

  .m-xs-s2 {
    margin: .125rem;
  }

  .-m-xs-s2 {
    margin: -.125rem;
  }

  .mt-xs-s2 {
    margin-top: .125rem;
  }

  .-mt-xs-s2 {
    margin-top: -.125rem;
  }

  .mb-xs-s2 {
    margin-bottom: .125rem;
  }

  .-mb-xs-s2 {
    margin-bottom: -.125rem;
  }

  .mr-xs-s2 {
    margin-right: .125rem;
  }

  .-mr-xs-s2 {
    margin-right: -.125rem;
  }

  .ml-xs-s2 {
    margin-left: .125rem;
  }

  .-ml-xs-s2 {
    margin-left: -.125rem;
  }

  .p-xs-s2 {
    padding: .125rem;
  }

  .-p-xs-s2 {
    padding: -.125rem;
  }

  .pt-xs-s2 {
    padding-top: .125rem;
  }

  .-pt-xs-s2 {
    padding-top: -.125rem;
  }

  .pb-xs-s2 {
    padding-bottom: .125rem;
  }

  .-pb-xs-s2 {
    padding-bottom: -.125rem;
  }

  .pr-xs-s2 {
    padding-right: .125rem;
  }

  .-pr-xs-s2 {
    padding-right: -.125rem;
  }

  .pl-xs-s2 {
    padding-left: .125rem;
  }

  .-pl-xs-s2 {
    padding-left: -.125rem;
  }

  .mx-xs-s2 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .my-xs-s2 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .px-xs-s2 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .py-xs-s2 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .gap-xs-s2 {
    gap: .125rem;
  }

  .pm-xs-s2, .gx-xs-s2 > .gx-child {
    padding-left: .0625rem;
    padding-right: .0625rem;
  }

  .gx-xs-s2 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .125rem;
  }

  .gx-xs-s2 > .gx-child:last-of-type {
    padding-left: .125rem;
    padding-right: 0;
  }

  .m-xs-s3 {
    margin: .1875rem;
  }

  .-m-xs-s3 {
    margin: -.1875rem;
  }

  .mt-xs-s3 {
    margin-top: .1875rem;
  }

  .-mt-xs-s3 {
    margin-top: -.1875rem;
  }

  .mb-xs-s3 {
    margin-bottom: .1875rem;
  }

  .-mb-xs-s3 {
    margin-bottom: -.1875rem;
  }

  .mr-xs-s3 {
    margin-right: .1875rem;
  }

  .-mr-xs-s3 {
    margin-right: -.1875rem;
  }

  .ml-xs-s3 {
    margin-left: .1875rem;
  }

  .-ml-xs-s3 {
    margin-left: -.1875rem;
  }

  .p-xs-s3 {
    padding: .1875rem;
  }

  .-p-xs-s3 {
    padding: -.1875rem;
  }

  .pt-xs-s3 {
    padding-top: .1875rem;
  }

  .-pt-xs-s3 {
    padding-top: -.1875rem;
  }

  .pb-xs-s3 {
    padding-bottom: .1875rem;
  }

  .-pb-xs-s3 {
    padding-bottom: -.1875rem;
  }

  .pr-xs-s3 {
    padding-right: .1875rem;
  }

  .-pr-xs-s3 {
    padding-right: -.1875rem;
  }

  .pl-xs-s3 {
    padding-left: .1875rem;
  }

  .-pl-xs-s3 {
    padding-left: -.1875rem;
  }

  .mx-xs-s3 {
    margin-left: .1875rem;
    margin-right: .1875rem;
  }

  .my-xs-s3 {
    margin-top: .1875rem;
    margin-bottom: .1875rem;
  }

  .px-xs-s3 {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .py-xs-s3 {
    padding-top: .1875rem;
    padding-bottom: .1875rem;
  }

  .gap-xs-s3 {
    gap: .1875rem;
  }

  .pm-xs-s3, .gx-xs-s3 > .gx-child {
    padding-left: .09375rem;
    padding-right: .09375rem;
  }

  .gx-xs-s3 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .1875rem;
  }

  .gx-xs-s3 > .gx-child:last-of-type {
    padding-left: .1875rem;
    padding-right: 0;
  }

  .m-xs-s4 {
    margin: .25rem;
  }

  .-m-xs-s4 {
    margin: -.25rem;
  }

  .mt-xs-s4 {
    margin-top: .25rem;
  }

  .-mt-xs-s4 {
    margin-top: -.25rem;
  }

  .mb-xs-s4 {
    margin-bottom: .25rem;
  }

  .-mb-xs-s4 {
    margin-bottom: -.25rem;
  }

  .mr-xs-s4 {
    margin-right: .25rem;
  }

  .-mr-xs-s4 {
    margin-right: -.25rem;
  }

  .ml-xs-s4 {
    margin-left: .25rem;
  }

  .-ml-xs-s4 {
    margin-left: -.25rem;
  }

  .p-xs-s4 {
    padding: .25rem;
  }

  .-p-xs-s4 {
    padding: -.25rem;
  }

  .pt-xs-s4 {
    padding-top: .25rem;
  }

  .-pt-xs-s4 {
    padding-top: -.25rem;
  }

  .pb-xs-s4 {
    padding-bottom: .25rem;
  }

  .-pb-xs-s4 {
    padding-bottom: -.25rem;
  }

  .pr-xs-s4 {
    padding-right: .25rem;
  }

  .-pr-xs-s4 {
    padding-right: -.25rem;
  }

  .pl-xs-s4 {
    padding-left: .25rem;
  }

  .-pl-xs-s4 {
    padding-left: -.25rem;
  }

  .mx-xs-s4 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .my-xs-s4 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .px-xs-s4 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .py-xs-s4 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .gap-xs-s4 {
    gap: .25rem;
  }

  .pm-xs-s4, .gx-xs-s4 > .gx-child {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .gx-xs-s4 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .25rem;
  }

  .gx-xs-s4 > .gx-child:last-of-type {
    padding-left: .25rem;
    padding-right: 0;
  }

  .m-xs-s5 {
    margin: .3125rem;
  }

  .-m-xs-s5 {
    margin: -.3125rem;
  }

  .mt-xs-s5 {
    margin-top: .3125rem;
  }

  .-mt-xs-s5 {
    margin-top: -.3125rem;
  }

  .mb-xs-s5 {
    margin-bottom: .3125rem;
  }

  .-mb-xs-s5 {
    margin-bottom: -.3125rem;
  }

  .mr-xs-s5 {
    margin-right: .3125rem;
  }

  .-mr-xs-s5 {
    margin-right: -.3125rem;
  }

  .ml-xs-s5 {
    margin-left: .3125rem;
  }

  .-ml-xs-s5 {
    margin-left: -.3125rem;
  }

  .p-xs-s5 {
    padding: .3125rem;
  }

  .-p-xs-s5 {
    padding: -.3125rem;
  }

  .pt-xs-s5 {
    padding-top: .3125rem;
  }

  .-pt-xs-s5 {
    padding-top: -.3125rem;
  }

  .pb-xs-s5 {
    padding-bottom: .3125rem;
  }

  .-pb-xs-s5 {
    padding-bottom: -.3125rem;
  }

  .pr-xs-s5 {
    padding-right: .3125rem;
  }

  .-pr-xs-s5 {
    padding-right: -.3125rem;
  }

  .pl-xs-s5 {
    padding-left: .3125rem;
  }

  .-pl-xs-s5 {
    padding-left: -.3125rem;
  }

  .mx-xs-s5 {
    margin-left: .3125rem;
    margin-right: .3125rem;
  }

  .my-xs-s5 {
    margin-top: .3125rem;
    margin-bottom: .3125rem;
  }

  .px-xs-s5 {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .py-xs-s5 {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
  }

  .gap-xs-s5 {
    gap: .3125rem;
  }

  .pm-xs-s5, .gx-xs-s5 > .gx-child {
    padding-left: .15625rem;
    padding-right: .15625rem;
  }

  .gx-xs-s5 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .3125rem;
  }

  .gx-xs-s5 > .gx-child:last-of-type {
    padding-left: .3125rem;
    padding-right: 0;
  }

  .m-xs-s6 {
    margin: .375rem;
  }

  .-m-xs-s6 {
    margin: -.375rem;
  }

  .mt-xs-s6 {
    margin-top: .375rem;
  }

  .-mt-xs-s6 {
    margin-top: -.375rem;
  }

  .mb-xs-s6 {
    margin-bottom: .375rem;
  }

  .-mb-xs-s6 {
    margin-bottom: -.375rem;
  }

  .mr-xs-s6 {
    margin-right: .375rem;
  }

  .-mr-xs-s6 {
    margin-right: -.375rem;
  }

  .ml-xs-s6 {
    margin-left: .375rem;
  }

  .-ml-xs-s6 {
    margin-left: -.375rem;
  }

  .p-xs-s6 {
    padding: .375rem;
  }

  .-p-xs-s6 {
    padding: -.375rem;
  }

  .pt-xs-s6 {
    padding-top: .375rem;
  }

  .-pt-xs-s6 {
    padding-top: -.375rem;
  }

  .pb-xs-s6 {
    padding-bottom: .375rem;
  }

  .-pb-xs-s6 {
    padding-bottom: -.375rem;
  }

  .pr-xs-s6 {
    padding-right: .375rem;
  }

  .-pr-xs-s6 {
    padding-right: -.375rem;
  }

  .pl-xs-s6 {
    padding-left: .375rem;
  }

  .-pl-xs-s6 {
    padding-left: -.375rem;
  }

  .mx-xs-s6 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .my-xs-s6 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .px-xs-s6 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .py-xs-s6 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .gap-xs-s6 {
    gap: .375rem;
  }

  .pm-xs-s6, .gx-xs-s6 > .gx-child {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .gx-xs-s6 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .375rem;
  }

  .gx-xs-s6 > .gx-child:last-of-type {
    padding-left: .375rem;
    padding-right: 0;
  }

  .m-xs-s7 {
    margin: .4375rem;
  }

  .-m-xs-s7 {
    margin: -.4375rem;
  }

  .mt-xs-s7 {
    margin-top: .4375rem;
  }

  .-mt-xs-s7 {
    margin-top: -.4375rem;
  }

  .mb-xs-s7 {
    margin-bottom: .4375rem;
  }

  .-mb-xs-s7 {
    margin-bottom: -.4375rem;
  }

  .mr-xs-s7 {
    margin-right: .4375rem;
  }

  .-mr-xs-s7 {
    margin-right: -.4375rem;
  }

  .ml-xs-s7 {
    margin-left: .4375rem;
  }

  .-ml-xs-s7 {
    margin-left: -.4375rem;
  }

  .p-xs-s7 {
    padding: .4375rem;
  }

  .-p-xs-s7 {
    padding: -.4375rem;
  }

  .pt-xs-s7 {
    padding-top: .4375rem;
  }

  .-pt-xs-s7 {
    padding-top: -.4375rem;
  }

  .pb-xs-s7 {
    padding-bottom: .4375rem;
  }

  .-pb-xs-s7 {
    padding-bottom: -.4375rem;
  }

  .pr-xs-s7 {
    padding-right: .4375rem;
  }

  .-pr-xs-s7 {
    padding-right: -.4375rem;
  }

  .pl-xs-s7 {
    padding-left: .4375rem;
  }

  .-pl-xs-s7 {
    padding-left: -.4375rem;
  }

  .mx-xs-s7 {
    margin-left: .4375rem;
    margin-right: .4375rem;
  }

  .my-xs-s7 {
    margin-top: .4375rem;
    margin-bottom: .4375rem;
  }

  .px-xs-s7 {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .py-xs-s7 {
    padding-top: .4375rem;
    padding-bottom: .4375rem;
  }

  .gap-xs-s7 {
    gap: .4375rem;
  }

  .pm-xs-s7, .gx-xs-s7 > .gx-child {
    padding-left: .21875rem;
    padding-right: .21875rem;
  }

  .gx-xs-s7 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .4375rem;
  }

  .gx-xs-s7 > .gx-child:last-of-type {
    padding-left: .4375rem;
    padding-right: 0;
  }

  .m-xs-s8 {
    margin: .5rem;
  }

  .-m-xs-s8 {
    margin: -.5rem;
  }

  .mt-xs-s8 {
    margin-top: .5rem;
  }

  .-mt-xs-s8 {
    margin-top: -.5rem;
  }

  .mb-xs-s8 {
    margin-bottom: .5rem;
  }

  .-mb-xs-s8 {
    margin-bottom: -.5rem;
  }

  .mr-xs-s8 {
    margin-right: .5rem;
  }

  .-mr-xs-s8 {
    margin-right: -.5rem;
  }

  .ml-xs-s8 {
    margin-left: .5rem;
  }

  .-ml-xs-s8 {
    margin-left: -.5rem;
  }

  .p-xs-s8 {
    padding: .5rem;
  }

  .-p-xs-s8 {
    padding: -.5rem;
  }

  .pt-xs-s8 {
    padding-top: .5rem;
  }

  .-pt-xs-s8 {
    padding-top: -.5rem;
  }

  .pb-xs-s8 {
    padding-bottom: .5rem;
  }

  .-pb-xs-s8 {
    padding-bottom: -.5rem;
  }

  .pr-xs-s8 {
    padding-right: .5rem;
  }

  .-pr-xs-s8 {
    padding-right: -.5rem;
  }

  .pl-xs-s8 {
    padding-left: .5rem;
  }

  .-pl-xs-s8 {
    padding-left: -.5rem;
  }

  .mx-xs-s8 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .my-xs-s8 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .px-xs-s8 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .py-xs-s8 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .gap-xs-s8 {
    gap: .5rem;
  }

  .pm-xs-s8, .gx-xs-s8 > .gx-child {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .gx-xs-s8 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5rem;
  }

  .gx-xs-s8 > .gx-child:last-of-type {
    padding-left: .5rem;
    padding-right: 0;
  }

  .m-xs-s9 {
    margin: .5625rem;
  }

  .-m-xs-s9 {
    margin: -.5625rem;
  }

  .mt-xs-s9 {
    margin-top: .5625rem;
  }

  .-mt-xs-s9 {
    margin-top: -.5625rem;
  }

  .mb-xs-s9 {
    margin-bottom: .5625rem;
  }

  .-mb-xs-s9 {
    margin-bottom: -.5625rem;
  }

  .mr-xs-s9 {
    margin-right: .5625rem;
  }

  .-mr-xs-s9 {
    margin-right: -.5625rem;
  }

  .ml-xs-s9 {
    margin-left: .5625rem;
  }

  .-ml-xs-s9 {
    margin-left: -.5625rem;
  }

  .p-xs-s9 {
    padding: .5625rem;
  }

  .-p-xs-s9 {
    padding: -.5625rem;
  }

  .pt-xs-s9 {
    padding-top: .5625rem;
  }

  .-pt-xs-s9 {
    padding-top: -.5625rem;
  }

  .pb-xs-s9 {
    padding-bottom: .5625rem;
  }

  .-pb-xs-s9 {
    padding-bottom: -.5625rem;
  }

  .pr-xs-s9 {
    padding-right: .5625rem;
  }

  .-pr-xs-s9 {
    padding-right: -.5625rem;
  }

  .pl-xs-s9 {
    padding-left: .5625rem;
  }

  .-pl-xs-s9 {
    padding-left: -.5625rem;
  }

  .mx-xs-s9 {
    margin-left: .5625rem;
    margin-right: .5625rem;
  }

  .my-xs-s9 {
    margin-top: .5625rem;
    margin-bottom: .5625rem;
  }

  .px-xs-s9 {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .py-xs-s9 {
    padding-top: .5625rem;
    padding-bottom: .5625rem;
  }

  .gap-xs-s9 {
    gap: .5625rem;
  }

  .pm-xs-s9, .gx-xs-s9 > .gx-child {
    padding-left: .28125rem;
    padding-right: .28125rem;
  }

  .gx-xs-s9 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5625rem;
  }

  .gx-xs-s9 > .gx-child:last-of-type {
    padding-left: .5625rem;
    padding-right: 0;
  }

  .m-xs-s10 {
    margin: .625rem;
  }

  .-m-xs-s10 {
    margin: -.625rem;
  }

  .mt-xs-s10 {
    margin-top: .625rem;
  }

  .-mt-xs-s10 {
    margin-top: -.625rem;
  }

  .mb-xs-s10 {
    margin-bottom: .625rem;
  }

  .-mb-xs-s10 {
    margin-bottom: -.625rem;
  }

  .mr-xs-s10 {
    margin-right: .625rem;
  }

  .-mr-xs-s10 {
    margin-right: -.625rem;
  }

  .ml-xs-s10 {
    margin-left: .625rem;
  }

  .-ml-xs-s10 {
    margin-left: -.625rem;
  }

  .p-xs-s10 {
    padding: .625rem;
  }

  .-p-xs-s10 {
    padding: -.625rem;
  }

  .pt-xs-s10 {
    padding-top: .625rem;
  }

  .-pt-xs-s10 {
    padding-top: -.625rem;
  }

  .pb-xs-s10 {
    padding-bottom: .625rem;
  }

  .-pb-xs-s10 {
    padding-bottom: -.625rem;
  }

  .pr-xs-s10 {
    padding-right: .625rem;
  }

  .-pr-xs-s10 {
    padding-right: -.625rem;
  }

  .pl-xs-s10 {
    padding-left: .625rem;
  }

  .-pl-xs-s10 {
    padding-left: -.625rem;
  }

  .mx-xs-s10 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .my-xs-s10 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .px-xs-s10 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .py-xs-s10 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .gap-xs-s10 {
    gap: .625rem;
  }

  .pm-xs-s10, .gx-xs-s10 > .gx-child {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .gx-xs-s10 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .625rem;
  }

  .gx-xs-s10 > .gx-child:last-of-type {
    padding-left: .625rem;
    padding-right: 0;
  }

  .m-xs-s12 {
    margin: .75rem;
  }

  .-m-xs-s12 {
    margin: -.75rem;
  }

  .mt-xs-s12 {
    margin-top: .75rem;
  }

  .-mt-xs-s12 {
    margin-top: -.75rem;
  }

  .mb-xs-s12 {
    margin-bottom: .75rem;
  }

  .-mb-xs-s12 {
    margin-bottom: -.75rem;
  }

  .mr-xs-s12 {
    margin-right: .75rem;
  }

  .-mr-xs-s12 {
    margin-right: -.75rem;
  }

  .ml-xs-s12 {
    margin-left: .75rem;
  }

  .-ml-xs-s12 {
    margin-left: -.75rem;
  }

  .p-xs-s12 {
    padding: .75rem;
  }

  .-p-xs-s12 {
    padding: -.75rem;
  }

  .pt-xs-s12 {
    padding-top: .75rem;
  }

  .-pt-xs-s12 {
    padding-top: -.75rem;
  }

  .pb-xs-s12 {
    padding-bottom: .75rem;
  }

  .-pb-xs-s12 {
    padding-bottom: -.75rem;
  }

  .pr-xs-s12 {
    padding-right: .75rem;
  }

  .-pr-xs-s12 {
    padding-right: -.75rem;
  }

  .pl-xs-s12 {
    padding-left: .75rem;
  }

  .-pl-xs-s12 {
    padding-left: -.75rem;
  }

  .mx-xs-s12 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .my-xs-s12 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .px-xs-s12 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .py-xs-s12 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .gap-xs-s12 {
    gap: .75rem;
  }

  .pm-xs-s12, .gx-xs-s12 > .gx-child {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .gx-xs-s12 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .75rem;
  }

  .gx-xs-s12 > .gx-child:last-of-type {
    padding-left: .75rem;
    padding-right: 0;
  }

  .m-xs-s13 {
    margin: .8125rem;
  }

  .-m-xs-s13 {
    margin: -.8125rem;
  }

  .mt-xs-s13 {
    margin-top: .8125rem;
  }

  .-mt-xs-s13 {
    margin-top: -.8125rem;
  }

  .mb-xs-s13 {
    margin-bottom: .8125rem;
  }

  .-mb-xs-s13 {
    margin-bottom: -.8125rem;
  }

  .mr-xs-s13 {
    margin-right: .8125rem;
  }

  .-mr-xs-s13 {
    margin-right: -.8125rem;
  }

  .ml-xs-s13 {
    margin-left: .8125rem;
  }

  .-ml-xs-s13 {
    margin-left: -.8125rem;
  }

  .p-xs-s13 {
    padding: .8125rem;
  }

  .-p-xs-s13 {
    padding: -.8125rem;
  }

  .pt-xs-s13 {
    padding-top: .8125rem;
  }

  .-pt-xs-s13 {
    padding-top: -.8125rem;
  }

  .pb-xs-s13 {
    padding-bottom: .8125rem;
  }

  .-pb-xs-s13 {
    padding-bottom: -.8125rem;
  }

  .pr-xs-s13 {
    padding-right: .8125rem;
  }

  .-pr-xs-s13 {
    padding-right: -.8125rem;
  }

  .pl-xs-s13 {
    padding-left: .8125rem;
  }

  .-pl-xs-s13 {
    padding-left: -.8125rem;
  }

  .mx-xs-s13 {
    margin-left: .8125rem;
    margin-right: .8125rem;
  }

  .my-xs-s13 {
    margin-top: .8125rem;
    margin-bottom: .8125rem;
  }

  .px-xs-s13 {
    padding-left: .8125rem;
    padding-right: .8125rem;
  }

  .py-xs-s13 {
    padding-top: .8125rem;
    padding-bottom: .8125rem;
  }

  .gap-xs-s13 {
    gap: .8125rem;
  }

  .pm-xs-s13, .gx-xs-s13 > .gx-child {
    padding-left: .40625rem;
    padding-right: .40625rem;
  }

  .gx-xs-s13 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .8125rem;
  }

  .gx-xs-s13 > .gx-child:last-of-type {
    padding-left: .8125rem;
    padding-right: 0;
  }

  .m-xs-s14 {
    margin: .875rem;
  }

  .-m-xs-s14 {
    margin: -.875rem;
  }

  .mt-xs-s14 {
    margin-top: .875rem;
  }

  .-mt-xs-s14 {
    margin-top: -.875rem;
  }

  .mb-xs-s14 {
    margin-bottom: .875rem;
  }

  .-mb-xs-s14 {
    margin-bottom: -.875rem;
  }

  .mr-xs-s14 {
    margin-right: .875rem;
  }

  .-mr-xs-s14 {
    margin-right: -.875rem;
  }

  .ml-xs-s14 {
    margin-left: .875rem;
  }

  .-ml-xs-s14 {
    margin-left: -.875rem;
  }

  .p-xs-s14 {
    padding: .875rem;
  }

  .-p-xs-s14 {
    padding: -.875rem;
  }

  .pt-xs-s14 {
    padding-top: .875rem;
  }

  .-pt-xs-s14 {
    padding-top: -.875rem;
  }

  .pb-xs-s14 {
    padding-bottom: .875rem;
  }

  .-pb-xs-s14 {
    padding-bottom: -.875rem;
  }

  .pr-xs-s14 {
    padding-right: .875rem;
  }

  .-pr-xs-s14 {
    padding-right: -.875rem;
  }

  .pl-xs-s14 {
    padding-left: .875rem;
  }

  .-pl-xs-s14 {
    padding-left: -.875rem;
  }

  .mx-xs-s14 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .my-xs-s14 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .px-xs-s14 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .py-xs-s14 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .gap-xs-s14 {
    gap: .875rem;
  }

  .pm-xs-s14, .gx-xs-s14 > .gx-child {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .gx-xs-s14 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .875rem;
  }

  .gx-xs-s14 > .gx-child:last-of-type {
    padding-left: .875rem;
    padding-right: 0;
  }

  .m-xs-s15 {
    margin: .9375rem;
  }

  .-m-xs-s15 {
    margin: -.9375rem;
  }

  .mt-xs-s15 {
    margin-top: .9375rem;
  }

  .-mt-xs-s15 {
    margin-top: -.9375rem;
  }

  .mb-xs-s15 {
    margin-bottom: .9375rem;
  }

  .-mb-xs-s15 {
    margin-bottom: -.9375rem;
  }

  .mr-xs-s15 {
    margin-right: .9375rem;
  }

  .-mr-xs-s15 {
    margin-right: -.9375rem;
  }

  .ml-xs-s15 {
    margin-left: .9375rem;
  }

  .-ml-xs-s15 {
    margin-left: -.9375rem;
  }

  .p-xs-s15 {
    padding: .9375rem;
  }

  .-p-xs-s15 {
    padding: -.9375rem;
  }

  .pt-xs-s15 {
    padding-top: .9375rem;
  }

  .-pt-xs-s15 {
    padding-top: -.9375rem;
  }

  .pb-xs-s15 {
    padding-bottom: .9375rem;
  }

  .-pb-xs-s15 {
    padding-bottom: -.9375rem;
  }

  .pr-xs-s15 {
    padding-right: .9375rem;
  }

  .-pr-xs-s15 {
    padding-right: -.9375rem;
  }

  .pl-xs-s15 {
    padding-left: .9375rem;
  }

  .-pl-xs-s15 {
    padding-left: -.9375rem;
  }

  .mx-xs-s15 {
    margin-left: .9375rem;
    margin-right: .9375rem;
  }

  .my-xs-s15 {
    margin-top: .9375rem;
    margin-bottom: .9375rem;
  }

  .px-xs-s15 {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .py-xs-s15 {
    padding-top: .9375rem;
    padding-bottom: .9375rem;
  }

  .gap-xs-s15 {
    gap: .9375rem;
  }

  .pm-xs-s15, .gx-xs-s15 > .gx-child {
    padding-left: .46875rem;
    padding-right: .46875rem;
  }

  .gx-xs-s15 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .9375rem;
  }

  .gx-xs-s15 > .gx-child:last-of-type {
    padding-left: .9375rem;
    padding-right: 0;
  }

  .m-xs-s16 {
    margin: 1rem;
  }

  .-m-xs-s16 {
    margin: -1rem;
  }

  .mt-xs-s16 {
    margin-top: 1rem;
  }

  .-mt-xs-s16 {
    margin-top: -1rem;
  }

  .mb-xs-s16 {
    margin-bottom: 1rem;
  }

  .-mb-xs-s16 {
    margin-bottom: -1rem;
  }

  .mr-xs-s16 {
    margin-right: 1rem;
  }

  .-mr-xs-s16 {
    margin-right: -1rem;
  }

  .ml-xs-s16 {
    margin-left: 1rem;
  }

  .-ml-xs-s16 {
    margin-left: -1rem;
  }

  .p-xs-s16 {
    padding: 1rem;
  }

  .-p-xs-s16 {
    padding: -1rem;
  }

  .pt-xs-s16 {
    padding-top: 1rem;
  }

  .-pt-xs-s16 {
    padding-top: -1rem;
  }

  .pb-xs-s16 {
    padding-bottom: 1rem;
  }

  .-pb-xs-s16 {
    padding-bottom: -1rem;
  }

  .pr-xs-s16 {
    padding-right: 1rem;
  }

  .-pr-xs-s16 {
    padding-right: -1rem;
  }

  .pl-xs-s16 {
    padding-left: 1rem;
  }

  .-pl-xs-s16 {
    padding-left: -1rem;
  }

  .mx-xs-s16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .my-xs-s16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .px-xs-s16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .py-xs-s16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .gap-xs-s16 {
    gap: 1rem;
  }

  .pm-xs-s16, .gx-xs-s16 > .gx-child {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .gx-xs-s16 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1rem;
  }

  .gx-xs-s16 > .gx-child:last-of-type {
    padding-left: 1rem;
    padding-right: 0;
  }

  .m-xs-s18 {
    margin: 1.125rem;
  }

  .-m-xs-s18 {
    margin: -1.125rem;
  }

  .mt-xs-s18 {
    margin-top: 1.125rem;
  }

  .-mt-xs-s18 {
    margin-top: -1.125rem;
  }

  .mb-xs-s18 {
    margin-bottom: 1.125rem;
  }

  .-mb-xs-s18 {
    margin-bottom: -1.125rem;
  }

  .mr-xs-s18 {
    margin-right: 1.125rem;
  }

  .-mr-xs-s18 {
    margin-right: -1.125rem;
  }

  .ml-xs-s18 {
    margin-left: 1.125rem;
  }

  .-ml-xs-s18 {
    margin-left: -1.125rem;
  }

  .p-xs-s18 {
    padding: 1.125rem;
  }

  .-p-xs-s18 {
    padding: -1.125rem;
  }

  .pt-xs-s18 {
    padding-top: 1.125rem;
  }

  .-pt-xs-s18 {
    padding-top: -1.125rem;
  }

  .pb-xs-s18 {
    padding-bottom: 1.125rem;
  }

  .-pb-xs-s18 {
    padding-bottom: -1.125rem;
  }

  .pr-xs-s18 {
    padding-right: 1.125rem;
  }

  .-pr-xs-s18 {
    padding-right: -1.125rem;
  }

  .pl-xs-s18 {
    padding-left: 1.125rem;
  }

  .-pl-xs-s18 {
    padding-left: -1.125rem;
  }

  .mx-xs-s18 {
    margin-left: 1.125rem;
    margin-right: 1.125rem;
  }

  .my-xs-s18 {
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
  }

  .px-xs-s18 {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .py-xs-s18 {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }

  .gap-xs-s18 {
    gap: 1.125rem;
  }

  .pm-xs-s18, .gx-xs-s18 > .gx-child {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .gx-xs-s18 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.125rem;
  }

  .gx-xs-s18 > .gx-child:last-of-type {
    padding-left: 1.125rem;
    padding-right: 0;
  }

  .m-xs-s20 {
    margin: 1.25rem;
  }

  .-m-xs-s20 {
    margin: -1.25rem;
  }

  .mt-xs-s20 {
    margin-top: 1.25rem;
  }

  .-mt-xs-s20 {
    margin-top: -1.25rem;
  }

  .mb-xs-s20 {
    margin-bottom: 1.25rem;
  }

  .-mb-xs-s20 {
    margin-bottom: -1.25rem;
  }

  .mr-xs-s20 {
    margin-right: 1.25rem;
  }

  .-mr-xs-s20 {
    margin-right: -1.25rem;
  }

  .ml-xs-s20 {
    margin-left: 1.25rem;
  }

  .-ml-xs-s20 {
    margin-left: -1.25rem;
  }

  .p-xs-s20 {
    padding: 1.25rem;
  }

  .-p-xs-s20 {
    padding: -1.25rem;
  }

  .pt-xs-s20 {
    padding-top: 1.25rem;
  }

  .-pt-xs-s20 {
    padding-top: -1.25rem;
  }

  .pb-xs-s20 {
    padding-bottom: 1.25rem;
  }

  .-pb-xs-s20 {
    padding-bottom: -1.25rem;
  }

  .pr-xs-s20 {
    padding-right: 1.25rem;
  }

  .-pr-xs-s20 {
    padding-right: -1.25rem;
  }

  .pl-xs-s20 {
    padding-left: 1.25rem;
  }

  .-pl-xs-s20 {
    padding-left: -1.25rem;
  }

  .mx-xs-s20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .my-xs-s20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .px-xs-s20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .py-xs-s20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .gap-xs-s20 {
    gap: 1.25rem;
  }

  .pm-xs-s20, .gx-xs-s20 > .gx-child {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .gx-xs-s20 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.25rem;
  }

  .gx-xs-s20 > .gx-child:last-of-type {
    padding-left: 1.25rem;
    padding-right: 0;
  }

  .m-xs-s22 {
    margin: 1.375rem;
  }

  .-m-xs-s22 {
    margin: -1.375rem;
  }

  .mt-xs-s22 {
    margin-top: 1.375rem;
  }

  .-mt-xs-s22 {
    margin-top: -1.375rem;
  }

  .mb-xs-s22 {
    margin-bottom: 1.375rem;
  }

  .-mb-xs-s22 {
    margin-bottom: -1.375rem;
  }

  .mr-xs-s22 {
    margin-right: 1.375rem;
  }

  .-mr-xs-s22 {
    margin-right: -1.375rem;
  }

  .ml-xs-s22 {
    margin-left: 1.375rem;
  }

  .-ml-xs-s22 {
    margin-left: -1.375rem;
  }

  .p-xs-s22 {
    padding: 1.375rem;
  }

  .-p-xs-s22 {
    padding: -1.375rem;
  }

  .pt-xs-s22 {
    padding-top: 1.375rem;
  }

  .-pt-xs-s22 {
    padding-top: -1.375rem;
  }

  .pb-xs-s22 {
    padding-bottom: 1.375rem;
  }

  .-pb-xs-s22 {
    padding-bottom: -1.375rem;
  }

  .pr-xs-s22 {
    padding-right: 1.375rem;
  }

  .-pr-xs-s22 {
    padding-right: -1.375rem;
  }

  .pl-xs-s22 {
    padding-left: 1.375rem;
  }

  .-pl-xs-s22 {
    padding-left: -1.375rem;
  }

  .mx-xs-s22 {
    margin-left: 1.375rem;
    margin-right: 1.375rem;
  }

  .my-xs-s22 {
    margin-top: 1.375rem;
    margin-bottom: 1.375rem;
  }

  .px-xs-s22 {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }

  .py-xs-s22 {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
  }

  .gap-xs-s22 {
    gap: 1.375rem;
  }

  .pm-xs-s22, .gx-xs-s22 > .gx-child {
    padding-left: .6875rem;
    padding-right: .6875rem;
  }

  .gx-xs-s22 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.375rem;
  }

  .gx-xs-s22 > .gx-child:last-of-type {
    padding-left: 1.375rem;
    padding-right: 0;
  }

  .m-xs-s24 {
    margin: 1.5rem;
  }

  .-m-xs-s24 {
    margin: -1.5rem;
  }

  .mt-xs-s24 {
    margin-top: 1.5rem;
  }

  .-mt-xs-s24 {
    margin-top: -1.5rem;
  }

  .mb-xs-s24 {
    margin-bottom: 1.5rem;
  }

  .-mb-xs-s24 {
    margin-bottom: -1.5rem;
  }

  .mr-xs-s24 {
    margin-right: 1.5rem;
  }

  .-mr-xs-s24 {
    margin-right: -1.5rem;
  }

  .ml-xs-s24 {
    margin-left: 1.5rem;
  }

  .-ml-xs-s24 {
    margin-left: -1.5rem;
  }

  .p-xs-s24 {
    padding: 1.5rem;
  }

  .-p-xs-s24 {
    padding: -1.5rem;
  }

  .pt-xs-s24 {
    padding-top: 1.5rem;
  }

  .-pt-xs-s24 {
    padding-top: -1.5rem;
  }

  .pb-xs-s24 {
    padding-bottom: 1.5rem;
  }

  .-pb-xs-s24 {
    padding-bottom: -1.5rem;
  }

  .pr-xs-s24 {
    padding-right: 1.5rem;
  }

  .-pr-xs-s24 {
    padding-right: -1.5rem;
  }

  .pl-xs-s24 {
    padding-left: 1.5rem;
  }

  .-pl-xs-s24 {
    padding-left: -1.5rem;
  }

  .mx-xs-s24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .my-xs-s24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .px-xs-s24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .py-xs-s24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .gap-xs-s24 {
    gap: 1.5rem;
  }

  .pm-xs-s24, .gx-xs-s24 > .gx-child {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .gx-xs-s24 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5rem;
  }

  .gx-xs-s24 > .gx-child:last-of-type {
    padding-left: 1.5rem;
    padding-right: 0;
  }

  .m-xs-s25 {
    margin: 1.5625rem;
  }

  .-m-xs-s25 {
    margin: -1.5625rem;
  }

  .mt-xs-s25 {
    margin-top: 1.5625rem;
  }

  .-mt-xs-s25 {
    margin-top: -1.5625rem;
  }

  .mb-xs-s25 {
    margin-bottom: 1.5625rem;
  }

  .-mb-xs-s25 {
    margin-bottom: -1.5625rem;
  }

  .mr-xs-s25 {
    margin-right: 1.5625rem;
  }

  .-mr-xs-s25 {
    margin-right: -1.5625rem;
  }

  .ml-xs-s25 {
    margin-left: 1.5625rem;
  }

  .-ml-xs-s25 {
    margin-left: -1.5625rem;
  }

  .p-xs-s25 {
    padding: 1.5625rem;
  }

  .-p-xs-s25 {
    padding: -1.5625rem;
  }

  .pt-xs-s25 {
    padding-top: 1.5625rem;
  }

  .-pt-xs-s25 {
    padding-top: -1.5625rem;
  }

  .pb-xs-s25 {
    padding-bottom: 1.5625rem;
  }

  .-pb-xs-s25 {
    padding-bottom: -1.5625rem;
  }

  .pr-xs-s25 {
    padding-right: 1.5625rem;
  }

  .-pr-xs-s25 {
    padding-right: -1.5625rem;
  }

  .pl-xs-s25 {
    padding-left: 1.5625rem;
  }

  .-pl-xs-s25 {
    padding-left: -1.5625rem;
  }

  .mx-xs-s25 {
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
  }

  .my-xs-s25 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }

  .px-xs-s25 {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .py-xs-s25 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }

  .gap-xs-s25 {
    gap: 1.5625rem;
  }

  .pm-xs-s25, .gx-xs-s25 > .gx-child {
    padding-left: .78125rem;
    padding-right: .78125rem;
  }

  .gx-xs-s25 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5625rem;
  }

  .gx-xs-s25 > .gx-child:last-of-type {
    padding-left: 1.5625rem;
    padding-right: 0;
  }

  .m-xs-s27 {
    margin: 1.6875rem;
  }

  .-m-xs-s27 {
    margin: -1.6875rem;
  }

  .mt-xs-s27 {
    margin-top: 1.6875rem;
  }

  .-mt-xs-s27 {
    margin-top: -1.6875rem;
  }

  .mb-xs-s27 {
    margin-bottom: 1.6875rem;
  }

  .-mb-xs-s27 {
    margin-bottom: -1.6875rem;
  }

  .mr-xs-s27 {
    margin-right: 1.6875rem;
  }

  .-mr-xs-s27 {
    margin-right: -1.6875rem;
  }

  .ml-xs-s27 {
    margin-left: 1.6875rem;
  }

  .-ml-xs-s27 {
    margin-left: -1.6875rem;
  }

  .p-xs-s27 {
    padding: 1.6875rem;
  }

  .-p-xs-s27 {
    padding: -1.6875rem;
  }

  .pt-xs-s27 {
    padding-top: 1.6875rem;
  }

  .-pt-xs-s27 {
    padding-top: -1.6875rem;
  }

  .pb-xs-s27 {
    padding-bottom: 1.6875rem;
  }

  .-pb-xs-s27 {
    padding-bottom: -1.6875rem;
  }

  .pr-xs-s27 {
    padding-right: 1.6875rem;
  }

  .-pr-xs-s27 {
    padding-right: -1.6875rem;
  }

  .pl-xs-s27 {
    padding-left: 1.6875rem;
  }

  .-pl-xs-s27 {
    padding-left: -1.6875rem;
  }

  .mx-xs-s27 {
    margin-left: 1.6875rem;
    margin-right: 1.6875rem;
  }

  .my-xs-s27 {
    margin-top: 1.6875rem;
    margin-bottom: 1.6875rem;
  }

  .px-xs-s27 {
    padding-left: 1.6875rem;
    padding-right: 1.6875rem;
  }

  .py-xs-s27 {
    padding-top: 1.6875rem;
    padding-bottom: 1.6875rem;
  }

  .gap-xs-s27 {
    gap: 1.6875rem;
  }

  .pm-xs-s27, .gx-xs-s27 > .gx-child {
    padding-left: .84375rem;
    padding-right: .84375rem;
  }

  .gx-xs-s27 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.6875rem;
  }

  .gx-xs-s27 > .gx-child:last-of-type {
    padding-left: 1.6875rem;
    padding-right: 0;
  }

  .m-xs-s29 {
    margin: 1.8125rem;
  }

  .-m-xs-s29 {
    margin: -1.8125rem;
  }

  .mt-xs-s29 {
    margin-top: 1.8125rem;
  }

  .-mt-xs-s29 {
    margin-top: -1.8125rem;
  }

  .mb-xs-s29 {
    margin-bottom: 1.8125rem;
  }

  .-mb-xs-s29 {
    margin-bottom: -1.8125rem;
  }

  .mr-xs-s29 {
    margin-right: 1.8125rem;
  }

  .-mr-xs-s29 {
    margin-right: -1.8125rem;
  }

  .ml-xs-s29 {
    margin-left: 1.8125rem;
  }

  .-ml-xs-s29 {
    margin-left: -1.8125rem;
  }

  .p-xs-s29 {
    padding: 1.8125rem;
  }

  .-p-xs-s29 {
    padding: -1.8125rem;
  }

  .pt-xs-s29 {
    padding-top: 1.8125rem;
  }

  .-pt-xs-s29 {
    padding-top: -1.8125rem;
  }

  .pb-xs-s29 {
    padding-bottom: 1.8125rem;
  }

  .-pb-xs-s29 {
    padding-bottom: -1.8125rem;
  }

  .pr-xs-s29 {
    padding-right: 1.8125rem;
  }

  .-pr-xs-s29 {
    padding-right: -1.8125rem;
  }

  .pl-xs-s29 {
    padding-left: 1.8125rem;
  }

  .-pl-xs-s29 {
    padding-left: -1.8125rem;
  }

  .mx-xs-s29 {
    margin-left: 1.8125rem;
    margin-right: 1.8125rem;
  }

  .my-xs-s29 {
    margin-top: 1.8125rem;
    margin-bottom: 1.8125rem;
  }

  .px-xs-s29 {
    padding-left: 1.8125rem;
    padding-right: 1.8125rem;
  }

  .py-xs-s29 {
    padding-top: 1.8125rem;
    padding-bottom: 1.8125rem;
  }

  .gap-xs-s29 {
    gap: 1.8125rem;
  }

  .pm-xs-s29, .gx-xs-s29 > .gx-child {
    padding-left: .90625rem;
    padding-right: .90625rem;
  }

  .gx-xs-s29 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.8125rem;
  }

  .gx-xs-s29 > .gx-child:last-of-type {
    padding-left: 1.8125rem;
    padding-right: 0;
  }

  .m-xs-s30 {
    margin: 1.875rem;
  }

  .-m-xs-s30 {
    margin: -1.875rem;
  }

  .mt-xs-s30 {
    margin-top: 1.875rem;
  }

  .-mt-xs-s30 {
    margin-top: -1.875rem;
  }

  .mb-xs-s30 {
    margin-bottom: 1.875rem;
  }

  .-mb-xs-s30 {
    margin-bottom: -1.875rem;
  }

  .mr-xs-s30 {
    margin-right: 1.875rem;
  }

  .-mr-xs-s30 {
    margin-right: -1.875rem;
  }

  .ml-xs-s30 {
    margin-left: 1.875rem;
  }

  .-ml-xs-s30 {
    margin-left: -1.875rem;
  }

  .p-xs-s30 {
    padding: 1.875rem;
  }

  .-p-xs-s30 {
    padding: -1.875rem;
  }

  .pt-xs-s30 {
    padding-top: 1.875rem;
  }

  .-pt-xs-s30 {
    padding-top: -1.875rem;
  }

  .pb-xs-s30 {
    padding-bottom: 1.875rem;
  }

  .-pb-xs-s30 {
    padding-bottom: -1.875rem;
  }

  .pr-xs-s30 {
    padding-right: 1.875rem;
  }

  .-pr-xs-s30 {
    padding-right: -1.875rem;
  }

  .pl-xs-s30 {
    padding-left: 1.875rem;
  }

  .-pl-xs-s30 {
    padding-left: -1.875rem;
  }

  .mx-xs-s30 {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }

  .my-xs-s30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .px-xs-s30 {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .py-xs-s30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .gap-xs-s30 {
    gap: 1.875rem;
  }

  .pm-xs-s30, .gx-xs-s30 > .gx-child {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .gx-xs-s30 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.875rem;
  }

  .gx-xs-s30 > .gx-child:last-of-type {
    padding-left: 1.875rem;
    padding-right: 0;
  }

  .m-xs-s32 {
    margin: 2rem;
  }

  .-m-xs-s32 {
    margin: -2rem;
  }

  .mt-xs-s32 {
    margin-top: 2rem;
  }

  .-mt-xs-s32 {
    margin-top: -2rem;
  }

  .mb-xs-s32 {
    margin-bottom: 2rem;
  }

  .-mb-xs-s32 {
    margin-bottom: -2rem;
  }

  .mr-xs-s32 {
    margin-right: 2rem;
  }

  .-mr-xs-s32 {
    margin-right: -2rem;
  }

  .ml-xs-s32 {
    margin-left: 2rem;
  }

  .-ml-xs-s32 {
    margin-left: -2rem;
  }

  .p-xs-s32 {
    padding: 2rem;
  }

  .-p-xs-s32 {
    padding: -2rem;
  }

  .pt-xs-s32 {
    padding-top: 2rem;
  }

  .-pt-xs-s32 {
    padding-top: -2rem;
  }

  .pb-xs-s32 {
    padding-bottom: 2rem;
  }

  .-pb-xs-s32 {
    padding-bottom: -2rem;
  }

  .pr-xs-s32 {
    padding-right: 2rem;
  }

  .-pr-xs-s32 {
    padding-right: -2rem;
  }

  .pl-xs-s32 {
    padding-left: 2rem;
  }

  .-pl-xs-s32 {
    padding-left: -2rem;
  }

  .mx-xs-s32 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .my-xs-s32 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .px-xs-s32 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .py-xs-s32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .gap-xs-s32 {
    gap: 2rem;
  }

  .pm-xs-s32, .gx-xs-s32 > .gx-child {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .gx-xs-s32 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2rem;
  }

  .gx-xs-s32 > .gx-child:last-of-type {
    padding-left: 2rem;
    padding-right: 0;
  }

  .m-xs-s35 {
    margin: 2.1875rem;
  }

  .-m-xs-s35 {
    margin: -2.1875rem;
  }

  .mt-xs-s35 {
    margin-top: 2.1875rem;
  }

  .-mt-xs-s35 {
    margin-top: -2.1875rem;
  }

  .mb-xs-s35 {
    margin-bottom: 2.1875rem;
  }

  .-mb-xs-s35 {
    margin-bottom: -2.1875rem;
  }

  .mr-xs-s35 {
    margin-right: 2.1875rem;
  }

  .-mr-xs-s35 {
    margin-right: -2.1875rem;
  }

  .ml-xs-s35 {
    margin-left: 2.1875rem;
  }

  .-ml-xs-s35 {
    margin-left: -2.1875rem;
  }

  .p-xs-s35 {
    padding: 2.1875rem;
  }

  .-p-xs-s35 {
    padding: -2.1875rem;
  }

  .pt-xs-s35 {
    padding-top: 2.1875rem;
  }

  .-pt-xs-s35 {
    padding-top: -2.1875rem;
  }

  .pb-xs-s35 {
    padding-bottom: 2.1875rem;
  }

  .-pb-xs-s35 {
    padding-bottom: -2.1875rem;
  }

  .pr-xs-s35 {
    padding-right: 2.1875rem;
  }

  .-pr-xs-s35 {
    padding-right: -2.1875rem;
  }

  .pl-xs-s35 {
    padding-left: 2.1875rem;
  }

  .-pl-xs-s35 {
    padding-left: -2.1875rem;
  }

  .mx-xs-s35 {
    margin-left: 2.1875rem;
    margin-right: 2.1875rem;
  }

  .my-xs-s35 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }

  .px-xs-s35 {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .py-xs-s35 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }

  .gap-xs-s35 {
    gap: 2.1875rem;
  }

  .pm-xs-s35, .gx-xs-s35 > .gx-child {
    padding-left: 1.09375rem;
    padding-right: 1.09375rem;
  }

  .gx-xs-s35 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.1875rem;
  }

  .gx-xs-s35 > .gx-child:last-of-type {
    padding-left: 2.1875rem;
    padding-right: 0;
  }

  .m-xs-s40 {
    margin: 2.5rem;
  }

  .-m-xs-s40 {
    margin: -2.5rem;
  }

  .mt-xs-s40 {
    margin-top: 2.5rem;
  }

  .-mt-xs-s40 {
    margin-top: -2.5rem;
  }

  .mb-xs-s40 {
    margin-bottom: 2.5rem;
  }

  .-mb-xs-s40 {
    margin-bottom: -2.5rem;
  }

  .mr-xs-s40 {
    margin-right: 2.5rem;
  }

  .-mr-xs-s40 {
    margin-right: -2.5rem;
  }

  .ml-xs-s40 {
    margin-left: 2.5rem;
  }

  .-ml-xs-s40 {
    margin-left: -2.5rem;
  }

  .p-xs-s40 {
    padding: 2.5rem;
  }

  .-p-xs-s40 {
    padding: -2.5rem;
  }

  .pt-xs-s40 {
    padding-top: 2.5rem;
  }

  .-pt-xs-s40 {
    padding-top: -2.5rem;
  }

  .pb-xs-s40 {
    padding-bottom: 2.5rem;
  }

  .-pb-xs-s40 {
    padding-bottom: -2.5rem;
  }

  .pr-xs-s40 {
    padding-right: 2.5rem;
  }

  .-pr-xs-s40 {
    padding-right: -2.5rem;
  }

  .pl-xs-s40 {
    padding-left: 2.5rem;
  }

  .-pl-xs-s40 {
    padding-left: -2.5rem;
  }

  .mx-xs-s40 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .my-xs-s40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .px-xs-s40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .py-xs-s40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .gap-xs-s40 {
    gap: 2.5rem;
  }

  .pm-xs-s40, .gx-xs-s40 > .gx-child {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .gx-xs-s40 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.5rem;
  }

  .gx-xs-s40 > .gx-child:last-of-type {
    padding-left: 2.5rem;
    padding-right: 0;
  }

  .m-xs-s45 {
    margin: 2.8125rem;
  }

  .-m-xs-s45 {
    margin: -2.8125rem;
  }

  .mt-xs-s45 {
    margin-top: 2.8125rem;
  }

  .-mt-xs-s45 {
    margin-top: -2.8125rem;
  }

  .mb-xs-s45 {
    margin-bottom: 2.8125rem;
  }

  .-mb-xs-s45 {
    margin-bottom: -2.8125rem;
  }

  .mr-xs-s45 {
    margin-right: 2.8125rem;
  }

  .-mr-xs-s45 {
    margin-right: -2.8125rem;
  }

  .ml-xs-s45 {
    margin-left: 2.8125rem;
  }

  .-ml-xs-s45 {
    margin-left: -2.8125rem;
  }

  .p-xs-s45 {
    padding: 2.8125rem;
  }

  .-p-xs-s45 {
    padding: -2.8125rem;
  }

  .pt-xs-s45 {
    padding-top: 2.8125rem;
  }

  .-pt-xs-s45 {
    padding-top: -2.8125rem;
  }

  .pb-xs-s45 {
    padding-bottom: 2.8125rem;
  }

  .-pb-xs-s45 {
    padding-bottom: -2.8125rem;
  }

  .pr-xs-s45 {
    padding-right: 2.8125rem;
  }

  .-pr-xs-s45 {
    padding-right: -2.8125rem;
  }

  .pl-xs-s45 {
    padding-left: 2.8125rem;
  }

  .-pl-xs-s45 {
    padding-left: -2.8125rem;
  }

  .mx-xs-s45 {
    margin-left: 2.8125rem;
    margin-right: 2.8125rem;
  }

  .my-xs-s45 {
    margin-top: 2.8125rem;
    margin-bottom: 2.8125rem;
  }

  .px-xs-s45 {
    padding-left: 2.8125rem;
    padding-right: 2.8125rem;
  }

  .py-xs-s45 {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }

  .gap-xs-s45 {
    gap: 2.8125rem;
  }

  .pm-xs-s45, .gx-xs-s45 > .gx-child {
    padding-left: 1.40625rem;
    padding-right: 1.40625rem;
  }

  .gx-xs-s45 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.8125rem;
  }

  .gx-xs-s45 > .gx-child:last-of-type {
    padding-left: 2.8125rem;
    padding-right: 0;
  }

  .m-xs-s48 {
    margin: 3rem;
  }

  .-m-xs-s48 {
    margin: -3rem;
  }

  .mt-xs-s48 {
    margin-top: 3rem;
  }

  .-mt-xs-s48 {
    margin-top: -3rem;
  }

  .mb-xs-s48 {
    margin-bottom: 3rem;
  }

  .-mb-xs-s48 {
    margin-bottom: -3rem;
  }

  .mr-xs-s48 {
    margin-right: 3rem;
  }

  .-mr-xs-s48 {
    margin-right: -3rem;
  }

  .ml-xs-s48 {
    margin-left: 3rem;
  }

  .-ml-xs-s48 {
    margin-left: -3rem;
  }

  .p-xs-s48 {
    padding: 3rem;
  }

  .-p-xs-s48 {
    padding: -3rem;
  }

  .pt-xs-s48 {
    padding-top: 3rem;
  }

  .-pt-xs-s48 {
    padding-top: -3rem;
  }

  .pb-xs-s48 {
    padding-bottom: 3rem;
  }

  .-pb-xs-s48 {
    padding-bottom: -3rem;
  }

  .pr-xs-s48 {
    padding-right: 3rem;
  }

  .-pr-xs-s48 {
    padding-right: -3rem;
  }

  .pl-xs-s48 {
    padding-left: 3rem;
  }

  .-pl-xs-s48 {
    padding-left: -3rem;
  }

  .mx-xs-s48 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .my-xs-s48 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .px-xs-s48 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .py-xs-s48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .gap-xs-s48 {
    gap: 3rem;
  }

  .pm-xs-s48, .gx-xs-s48 > .gx-child {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .gx-xs-s48 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3rem;
  }

  .gx-xs-s48 > .gx-child:last-of-type {
    padding-left: 3rem;
    padding-right: 0;
  }

  .m-xs-s50 {
    margin: 3.125rem;
  }

  .-m-xs-s50 {
    margin: -3.125rem;
  }

  .mt-xs-s50 {
    margin-top: 3.125rem;
  }

  .-mt-xs-s50 {
    margin-top: -3.125rem;
  }

  .mb-xs-s50 {
    margin-bottom: 3.125rem;
  }

  .-mb-xs-s50 {
    margin-bottom: -3.125rem;
  }

  .mr-xs-s50 {
    margin-right: 3.125rem;
  }

  .-mr-xs-s50 {
    margin-right: -3.125rem;
  }

  .ml-xs-s50 {
    margin-left: 3.125rem;
  }

  .-ml-xs-s50 {
    margin-left: -3.125rem;
  }

  .p-xs-s50 {
    padding: 3.125rem;
  }

  .-p-xs-s50 {
    padding: -3.125rem;
  }

  .pt-xs-s50 {
    padding-top: 3.125rem;
  }

  .-pt-xs-s50 {
    padding-top: -3.125rem;
  }

  .pb-xs-s50 {
    padding-bottom: 3.125rem;
  }

  .-pb-xs-s50 {
    padding-bottom: -3.125rem;
  }

  .pr-xs-s50 {
    padding-right: 3.125rem;
  }

  .-pr-xs-s50 {
    padding-right: -3.125rem;
  }

  .pl-xs-s50 {
    padding-left: 3.125rem;
  }

  .-pl-xs-s50 {
    padding-left: -3.125rem;
  }

  .mx-xs-s50 {
    margin-left: 3.125rem;
    margin-right: 3.125rem;
  }

  .my-xs-s50 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }

  .px-xs-s50 {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .py-xs-s50 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }

  .gap-xs-s50 {
    gap: 3.125rem;
  }

  .pm-xs-s50, .gx-xs-s50 > .gx-child {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .gx-xs-s50 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.125rem;
  }

  .gx-xs-s50 > .gx-child:last-of-type {
    padding-left: 3.125rem;
    padding-right: 0;
  }

  .m-xs-s60 {
    margin: 3.75rem;
  }

  .-m-xs-s60 {
    margin: -3.75rem;
  }

  .mt-xs-s60 {
    margin-top: 3.75rem;
  }

  .-mt-xs-s60 {
    margin-top: -3.75rem;
  }

  .mb-xs-s60 {
    margin-bottom: 3.75rem;
  }

  .-mb-xs-s60 {
    margin-bottom: -3.75rem;
  }

  .mr-xs-s60 {
    margin-right: 3.75rem;
  }

  .-mr-xs-s60 {
    margin-right: -3.75rem;
  }

  .ml-xs-s60 {
    margin-left: 3.75rem;
  }

  .-ml-xs-s60 {
    margin-left: -3.75rem;
  }

  .p-xs-s60 {
    padding: 3.75rem;
  }

  .-p-xs-s60 {
    padding: -3.75rem;
  }

  .pt-xs-s60 {
    padding-top: 3.75rem;
  }

  .-pt-xs-s60 {
    padding-top: -3.75rem;
  }

  .pb-xs-s60 {
    padding-bottom: 3.75rem;
  }

  .-pb-xs-s60 {
    padding-bottom: -3.75rem;
  }

  .pr-xs-s60 {
    padding-right: 3.75rem;
  }

  .-pr-xs-s60 {
    padding-right: -3.75rem;
  }

  .pl-xs-s60 {
    padding-left: 3.75rem;
  }

  .-pl-xs-s60 {
    padding-left: -3.75rem;
  }

  .mx-xs-s60 {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
  }

  .my-xs-s60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .px-xs-s60 {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .py-xs-s60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  .gap-xs-s60 {
    gap: 3.75rem;
  }

  .pm-xs-s60, .gx-xs-s60 > .gx-child {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .gx-xs-s60 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.75rem;
  }

  .gx-xs-s60 > .gx-child:last-of-type {
    padding-left: 3.75rem;
    padding-right: 0;
  }

  .m-xs-s70 {
    margin: 4.375rem;
  }

  .-m-xs-s70 {
    margin: -4.375rem;
  }

  .mt-xs-s70 {
    margin-top: 4.375rem;
  }

  .-mt-xs-s70 {
    margin-top: -4.375rem;
  }

  .mb-xs-s70 {
    margin-bottom: 4.375rem;
  }

  .-mb-xs-s70 {
    margin-bottom: -4.375rem;
  }

  .mr-xs-s70 {
    margin-right: 4.375rem;
  }

  .-mr-xs-s70 {
    margin-right: -4.375rem;
  }

  .ml-xs-s70 {
    margin-left: 4.375rem;
  }

  .-ml-xs-s70 {
    margin-left: -4.375rem;
  }

  .p-xs-s70 {
    padding: 4.375rem;
  }

  .-p-xs-s70 {
    padding: -4.375rem;
  }

  .pt-xs-s70 {
    padding-top: 4.375rem;
  }

  .-pt-xs-s70 {
    padding-top: -4.375rem;
  }

  .pb-xs-s70 {
    padding-bottom: 4.375rem;
  }

  .-pb-xs-s70 {
    padding-bottom: -4.375rem;
  }

  .pr-xs-s70 {
    padding-right: 4.375rem;
  }

  .-pr-xs-s70 {
    padding-right: -4.375rem;
  }

  .pl-xs-s70 {
    padding-left: 4.375rem;
  }

  .-pl-xs-s70 {
    padding-left: -4.375rem;
  }

  .mx-xs-s70 {
    margin-left: 4.375rem;
    margin-right: 4.375rem;
  }

  .my-xs-s70 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }

  .px-xs-s70 {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }

  .py-xs-s70 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }

  .gap-xs-s70 {
    gap: 4.375rem;
  }

  .pm-xs-s70, .gx-xs-s70 > .gx-child {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .gx-xs-s70 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 4.375rem;
  }

  .gx-xs-s70 > .gx-child:last-of-type {
    padding-left: 4.375rem;
    padding-right: 0;
  }

  .m-xs-s80 {
    margin: 5rem;
  }

  .-m-xs-s80 {
    margin: -5rem;
  }

  .mt-xs-s80 {
    margin-top: 5rem;
  }

  .-mt-xs-s80 {
    margin-top: -5rem;
  }

  .mb-xs-s80 {
    margin-bottom: 5rem;
  }

  .-mb-xs-s80 {
    margin-bottom: -5rem;
  }

  .mr-xs-s80 {
    margin-right: 5rem;
  }

  .-mr-xs-s80 {
    margin-right: -5rem;
  }

  .ml-xs-s80 {
    margin-left: 5rem;
  }

  .-ml-xs-s80 {
    margin-left: -5rem;
  }

  .p-xs-s80 {
    padding: 5rem;
  }

  .-p-xs-s80 {
    padding: -5rem;
  }

  .pt-xs-s80 {
    padding-top: 5rem;
  }

  .-pt-xs-s80 {
    padding-top: -5rem;
  }

  .pb-xs-s80 {
    padding-bottom: 5rem;
  }

  .-pb-xs-s80 {
    padding-bottom: -5rem;
  }

  .pr-xs-s80 {
    padding-right: 5rem;
  }

  .-pr-xs-s80 {
    padding-right: -5rem;
  }

  .pl-xs-s80 {
    padding-left: 5rem;
  }

  .-pl-xs-s80 {
    padding-left: -5rem;
  }

  .mx-xs-s80 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .my-xs-s80 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .px-xs-s80 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .py-xs-s80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .gap-xs-s80 {
    gap: 5rem;
  }

  .pm-xs-s80, .gx-xs-s80 > .gx-child {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .gx-xs-s80 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 5rem;
  }

  .gx-xs-s80 > .gx-child:last-of-type {
    padding-left: 5rem;
    padding-right: 0;
  }

  .m-xs-s100 {
    margin: 6.25rem;
  }

  .-m-xs-s100 {
    margin: -6.25rem;
  }

  .mt-xs-s100 {
    margin-top: 6.25rem;
  }

  .-mt-xs-s100 {
    margin-top: -6.25rem;
  }

  .mb-xs-s100 {
    margin-bottom: 6.25rem;
  }

  .-mb-xs-s100 {
    margin-bottom: -6.25rem;
  }

  .mr-xs-s100 {
    margin-right: 6.25rem;
  }

  .-mr-xs-s100 {
    margin-right: -6.25rem;
  }

  .ml-xs-s100 {
    margin-left: 6.25rem;
  }

  .-ml-xs-s100 {
    margin-left: -6.25rem;
  }

  .p-xs-s100 {
    padding: 6.25rem;
  }

  .-p-xs-s100 {
    padding: -6.25rem;
  }

  .pt-xs-s100 {
    padding-top: 6.25rem;
  }

  .-pt-xs-s100 {
    padding-top: -6.25rem;
  }

  .pb-xs-s100 {
    padding-bottom: 6.25rem;
  }

  .-pb-xs-s100 {
    padding-bottom: -6.25rem;
  }

  .pr-xs-s100 {
    padding-right: 6.25rem;
  }

  .-pr-xs-s100 {
    padding-right: -6.25rem;
  }

  .pl-xs-s100 {
    padding-left: 6.25rem;
  }

  .-pl-xs-s100 {
    padding-left: -6.25rem;
  }

  .mx-xs-s100 {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
  }

  .my-xs-s100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }

  .px-xs-s100 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }

  .py-xs-s100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  .gap-xs-s100 {
    gap: 6.25rem;
  }

  .pm-xs-s100, .gx-xs-s100 > .gx-child {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .gx-xs-s100 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 6.25rem;
  }

  .gx-xs-s100 > .gx-child:last-of-type {
    padding-left: 6.25rem;
    padding-right: 0;
  }

  .m-xs-s120 {
    margin: 7.5rem;
  }

  .-m-xs-s120 {
    margin: -7.5rem;
  }

  .mt-xs-s120 {
    margin-top: 7.5rem;
  }

  .-mt-xs-s120 {
    margin-top: -7.5rem;
  }

  .mb-xs-s120 {
    margin-bottom: 7.5rem;
  }

  .-mb-xs-s120 {
    margin-bottom: -7.5rem;
  }

  .mr-xs-s120 {
    margin-right: 7.5rem;
  }

  .-mr-xs-s120 {
    margin-right: -7.5rem;
  }

  .ml-xs-s120 {
    margin-left: 7.5rem;
  }

  .-ml-xs-s120 {
    margin-left: -7.5rem;
  }

  .p-xs-s120 {
    padding: 7.5rem;
  }

  .-p-xs-s120 {
    padding: -7.5rem;
  }

  .pt-xs-s120 {
    padding-top: 7.5rem;
  }

  .-pt-xs-s120 {
    padding-top: -7.5rem;
  }

  .pb-xs-s120 {
    padding-bottom: 7.5rem;
  }

  .-pb-xs-s120 {
    padding-bottom: -7.5rem;
  }

  .pr-xs-s120 {
    padding-right: 7.5rem;
  }

  .-pr-xs-s120 {
    padding-right: -7.5rem;
  }

  .pl-xs-s120 {
    padding-left: 7.5rem;
  }

  .-pl-xs-s120 {
    padding-left: -7.5rem;
  }

  .mx-xs-s120 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }

  .my-xs-s120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }

  .px-xs-s120 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }

  .py-xs-s120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }

  .gap-xs-s120 {
    gap: 7.5rem;
  }

  .pm-xs-s120, .gx-xs-s120 > .gx-child {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .gx-xs-s120 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 7.5rem;
  }

  .gx-xs-s120 > .gx-child:last-of-type {
    padding-left: 7.5rem;
    padding-right: 0;
  }

  .m-xs-s150 {
    margin: 9.375rem;
  }

  .-m-xs-s150 {
    margin: -9.375rem;
  }

  .mt-xs-s150 {
    margin-top: 9.375rem;
  }

  .-mt-xs-s150 {
    margin-top: -9.375rem;
  }

  .mb-xs-s150 {
    margin-bottom: 9.375rem;
  }

  .-mb-xs-s150 {
    margin-bottom: -9.375rem;
  }

  .mr-xs-s150 {
    margin-right: 9.375rem;
  }

  .-mr-xs-s150 {
    margin-right: -9.375rem;
  }

  .ml-xs-s150 {
    margin-left: 9.375rem;
  }

  .-ml-xs-s150 {
    margin-left: -9.375rem;
  }

  .p-xs-s150 {
    padding: 9.375rem;
  }

  .-p-xs-s150 {
    padding: -9.375rem;
  }

  .pt-xs-s150 {
    padding-top: 9.375rem;
  }

  .-pt-xs-s150 {
    padding-top: -9.375rem;
  }

  .pb-xs-s150 {
    padding-bottom: 9.375rem;
  }

  .-pb-xs-s150 {
    padding-bottom: -9.375rem;
  }

  .pr-xs-s150 {
    padding-right: 9.375rem;
  }

  .-pr-xs-s150 {
    padding-right: -9.375rem;
  }

  .pl-xs-s150 {
    padding-left: 9.375rem;
  }

  .-pl-xs-s150 {
    padding-left: -9.375rem;
  }

  .mx-xs-s150 {
    margin-left: 9.375rem;
    margin-right: 9.375rem;
  }

  .my-xs-s150 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }

  .px-xs-s150 {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
  }

  .py-xs-s150 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }

  .gap-xs-s150 {
    gap: 9.375rem;
  }

  .pm-xs-s150, .gx-xs-s150 > .gx-child {
    padding-left: 4.6875rem;
    padding-right: 4.6875rem;
  }

  .gx-xs-s150 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 9.375rem;
  }

  .gx-xs-s150 > .gx-child:last-of-type {
    padding-left: 9.375rem;
    padding-right: 0;
  }
}

@media screen and (min-width: 640px) {
  .m-sm-s0, .-m-sm-s0 {
    margin: 0;
  }

  .mt-sm-s0, .-mt-sm-s0 {
    margin-top: 0;
  }

  .mb-sm-s0, .-mb-sm-s0 {
    margin-bottom: 0;
  }

  .mr-sm-s0, .-mr-sm-s0 {
    margin-right: 0;
  }

  .ml-sm-s0, .-ml-sm-s0 {
    margin-left: 0;
  }

  .p-sm-s0, .-p-sm-s0 {
    padding: 0;
  }

  .pt-sm-s0, .-pt-sm-s0 {
    padding-top: 0;
  }

  .pb-sm-s0, .-pb-sm-s0 {
    padding-bottom: 0;
  }

  .pr-sm-s0, .-pr-sm-s0 {
    padding-right: 0;
  }

  .pl-sm-s0, .-pl-sm-s0 {
    padding-left: 0;
  }

  .mx-sm-s0 {
    margin-left: 0;
    margin-right: 0;
  }

  .my-sm-s0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .px-sm-s0 {
    padding-left: 0;
    padding-right: 0;
  }

  .py-sm-s0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .gap-sm-s0 {
    gap: 0;
  }

  .pm-sm-s0, .gx-sm-s0 > .gx-child, .gx-sm-s0 > .gx-child:first-of-type, .gx-sm-s0 > .gx-child:last-of-type {
    padding-left: 0;
    padding-right: 0;
  }

  .m-sm-s2 {
    margin: .125rem;
  }

  .-m-sm-s2 {
    margin: -.125rem;
  }

  .mt-sm-s2 {
    margin-top: .125rem;
  }

  .-mt-sm-s2 {
    margin-top: -.125rem;
  }

  .mb-sm-s2 {
    margin-bottom: .125rem;
  }

  .-mb-sm-s2 {
    margin-bottom: -.125rem;
  }

  .mr-sm-s2 {
    margin-right: .125rem;
  }

  .-mr-sm-s2 {
    margin-right: -.125rem;
  }

  .ml-sm-s2 {
    margin-left: .125rem;
  }

  .-ml-sm-s2 {
    margin-left: -.125rem;
  }

  .p-sm-s2 {
    padding: .125rem;
  }

  .-p-sm-s2 {
    padding: -.125rem;
  }

  .pt-sm-s2 {
    padding-top: .125rem;
  }

  .-pt-sm-s2 {
    padding-top: -.125rem;
  }

  .pb-sm-s2 {
    padding-bottom: .125rem;
  }

  .-pb-sm-s2 {
    padding-bottom: -.125rem;
  }

  .pr-sm-s2 {
    padding-right: .125rem;
  }

  .-pr-sm-s2 {
    padding-right: -.125rem;
  }

  .pl-sm-s2 {
    padding-left: .125rem;
  }

  .-pl-sm-s2 {
    padding-left: -.125rem;
  }

  .mx-sm-s2 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .my-sm-s2 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .px-sm-s2 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .py-sm-s2 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .gap-sm-s2 {
    gap: .125rem;
  }

  .pm-sm-s2, .gx-sm-s2 > .gx-child {
    padding-left: .0625rem;
    padding-right: .0625rem;
  }

  .gx-sm-s2 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .125rem;
  }

  .gx-sm-s2 > .gx-child:last-of-type {
    padding-left: .125rem;
    padding-right: 0;
  }

  .m-sm-s3 {
    margin: .1875rem;
  }

  .-m-sm-s3 {
    margin: -.1875rem;
  }

  .mt-sm-s3 {
    margin-top: .1875rem;
  }

  .-mt-sm-s3 {
    margin-top: -.1875rem;
  }

  .mb-sm-s3 {
    margin-bottom: .1875rem;
  }

  .-mb-sm-s3 {
    margin-bottom: -.1875rem;
  }

  .mr-sm-s3 {
    margin-right: .1875rem;
  }

  .-mr-sm-s3 {
    margin-right: -.1875rem;
  }

  .ml-sm-s3 {
    margin-left: .1875rem;
  }

  .-ml-sm-s3 {
    margin-left: -.1875rem;
  }

  .p-sm-s3 {
    padding: .1875rem;
  }

  .-p-sm-s3 {
    padding: -.1875rem;
  }

  .pt-sm-s3 {
    padding-top: .1875rem;
  }

  .-pt-sm-s3 {
    padding-top: -.1875rem;
  }

  .pb-sm-s3 {
    padding-bottom: .1875rem;
  }

  .-pb-sm-s3 {
    padding-bottom: -.1875rem;
  }

  .pr-sm-s3 {
    padding-right: .1875rem;
  }

  .-pr-sm-s3 {
    padding-right: -.1875rem;
  }

  .pl-sm-s3 {
    padding-left: .1875rem;
  }

  .-pl-sm-s3 {
    padding-left: -.1875rem;
  }

  .mx-sm-s3 {
    margin-left: .1875rem;
    margin-right: .1875rem;
  }

  .my-sm-s3 {
    margin-top: .1875rem;
    margin-bottom: .1875rem;
  }

  .px-sm-s3 {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .py-sm-s3 {
    padding-top: .1875rem;
    padding-bottom: .1875rem;
  }

  .gap-sm-s3 {
    gap: .1875rem;
  }

  .pm-sm-s3, .gx-sm-s3 > .gx-child {
    padding-left: .09375rem;
    padding-right: .09375rem;
  }

  .gx-sm-s3 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .1875rem;
  }

  .gx-sm-s3 > .gx-child:last-of-type {
    padding-left: .1875rem;
    padding-right: 0;
  }

  .m-sm-s4 {
    margin: .25rem;
  }

  .-m-sm-s4 {
    margin: -.25rem;
  }

  .mt-sm-s4 {
    margin-top: .25rem;
  }

  .-mt-sm-s4 {
    margin-top: -.25rem;
  }

  .mb-sm-s4 {
    margin-bottom: .25rem;
  }

  .-mb-sm-s4 {
    margin-bottom: -.25rem;
  }

  .mr-sm-s4 {
    margin-right: .25rem;
  }

  .-mr-sm-s4 {
    margin-right: -.25rem;
  }

  .ml-sm-s4 {
    margin-left: .25rem;
  }

  .-ml-sm-s4 {
    margin-left: -.25rem;
  }

  .p-sm-s4 {
    padding: .25rem;
  }

  .-p-sm-s4 {
    padding: -.25rem;
  }

  .pt-sm-s4 {
    padding-top: .25rem;
  }

  .-pt-sm-s4 {
    padding-top: -.25rem;
  }

  .pb-sm-s4 {
    padding-bottom: .25rem;
  }

  .-pb-sm-s4 {
    padding-bottom: -.25rem;
  }

  .pr-sm-s4 {
    padding-right: .25rem;
  }

  .-pr-sm-s4 {
    padding-right: -.25rem;
  }

  .pl-sm-s4 {
    padding-left: .25rem;
  }

  .-pl-sm-s4 {
    padding-left: -.25rem;
  }

  .mx-sm-s4 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .my-sm-s4 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .px-sm-s4 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .py-sm-s4 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .gap-sm-s4 {
    gap: .25rem;
  }

  .pm-sm-s4, .gx-sm-s4 > .gx-child {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .gx-sm-s4 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .25rem;
  }

  .gx-sm-s4 > .gx-child:last-of-type {
    padding-left: .25rem;
    padding-right: 0;
  }

  .m-sm-s5 {
    margin: .3125rem;
  }

  .-m-sm-s5 {
    margin: -.3125rem;
  }

  .mt-sm-s5 {
    margin-top: .3125rem;
  }

  .-mt-sm-s5 {
    margin-top: -.3125rem;
  }

  .mb-sm-s5 {
    margin-bottom: .3125rem;
  }

  .-mb-sm-s5 {
    margin-bottom: -.3125rem;
  }

  .mr-sm-s5 {
    margin-right: .3125rem;
  }

  .-mr-sm-s5 {
    margin-right: -.3125rem;
  }

  .ml-sm-s5 {
    margin-left: .3125rem;
  }

  .-ml-sm-s5 {
    margin-left: -.3125rem;
  }

  .p-sm-s5 {
    padding: .3125rem;
  }

  .-p-sm-s5 {
    padding: -.3125rem;
  }

  .pt-sm-s5 {
    padding-top: .3125rem;
  }

  .-pt-sm-s5 {
    padding-top: -.3125rem;
  }

  .pb-sm-s5 {
    padding-bottom: .3125rem;
  }

  .-pb-sm-s5 {
    padding-bottom: -.3125rem;
  }

  .pr-sm-s5 {
    padding-right: .3125rem;
  }

  .-pr-sm-s5 {
    padding-right: -.3125rem;
  }

  .pl-sm-s5 {
    padding-left: .3125rem;
  }

  .-pl-sm-s5 {
    padding-left: -.3125rem;
  }

  .mx-sm-s5 {
    margin-left: .3125rem;
    margin-right: .3125rem;
  }

  .my-sm-s5 {
    margin-top: .3125rem;
    margin-bottom: .3125rem;
  }

  .px-sm-s5 {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .py-sm-s5 {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
  }

  .gap-sm-s5 {
    gap: .3125rem;
  }

  .pm-sm-s5, .gx-sm-s5 > .gx-child {
    padding-left: .15625rem;
    padding-right: .15625rem;
  }

  .gx-sm-s5 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .3125rem;
  }

  .gx-sm-s5 > .gx-child:last-of-type {
    padding-left: .3125rem;
    padding-right: 0;
  }

  .m-sm-s6 {
    margin: .375rem;
  }

  .-m-sm-s6 {
    margin: -.375rem;
  }

  .mt-sm-s6 {
    margin-top: .375rem;
  }

  .-mt-sm-s6 {
    margin-top: -.375rem;
  }

  .mb-sm-s6 {
    margin-bottom: .375rem;
  }

  .-mb-sm-s6 {
    margin-bottom: -.375rem;
  }

  .mr-sm-s6 {
    margin-right: .375rem;
  }

  .-mr-sm-s6 {
    margin-right: -.375rem;
  }

  .ml-sm-s6 {
    margin-left: .375rem;
  }

  .-ml-sm-s6 {
    margin-left: -.375rem;
  }

  .p-sm-s6 {
    padding: .375rem;
  }

  .-p-sm-s6 {
    padding: -.375rem;
  }

  .pt-sm-s6 {
    padding-top: .375rem;
  }

  .-pt-sm-s6 {
    padding-top: -.375rem;
  }

  .pb-sm-s6 {
    padding-bottom: .375rem;
  }

  .-pb-sm-s6 {
    padding-bottom: -.375rem;
  }

  .pr-sm-s6 {
    padding-right: .375rem;
  }

  .-pr-sm-s6 {
    padding-right: -.375rem;
  }

  .pl-sm-s6 {
    padding-left: .375rem;
  }

  .-pl-sm-s6 {
    padding-left: -.375rem;
  }

  .mx-sm-s6 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .my-sm-s6 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .px-sm-s6 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .py-sm-s6 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .gap-sm-s6 {
    gap: .375rem;
  }

  .pm-sm-s6, .gx-sm-s6 > .gx-child {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .gx-sm-s6 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .375rem;
  }

  .gx-sm-s6 > .gx-child:last-of-type {
    padding-left: .375rem;
    padding-right: 0;
  }

  .m-sm-s7 {
    margin: .4375rem;
  }

  .-m-sm-s7 {
    margin: -.4375rem;
  }

  .mt-sm-s7 {
    margin-top: .4375rem;
  }

  .-mt-sm-s7 {
    margin-top: -.4375rem;
  }

  .mb-sm-s7 {
    margin-bottom: .4375rem;
  }

  .-mb-sm-s7 {
    margin-bottom: -.4375rem;
  }

  .mr-sm-s7 {
    margin-right: .4375rem;
  }

  .-mr-sm-s7 {
    margin-right: -.4375rem;
  }

  .ml-sm-s7 {
    margin-left: .4375rem;
  }

  .-ml-sm-s7 {
    margin-left: -.4375rem;
  }

  .p-sm-s7 {
    padding: .4375rem;
  }

  .-p-sm-s7 {
    padding: -.4375rem;
  }

  .pt-sm-s7 {
    padding-top: .4375rem;
  }

  .-pt-sm-s7 {
    padding-top: -.4375rem;
  }

  .pb-sm-s7 {
    padding-bottom: .4375rem;
  }

  .-pb-sm-s7 {
    padding-bottom: -.4375rem;
  }

  .pr-sm-s7 {
    padding-right: .4375rem;
  }

  .-pr-sm-s7 {
    padding-right: -.4375rem;
  }

  .pl-sm-s7 {
    padding-left: .4375rem;
  }

  .-pl-sm-s7 {
    padding-left: -.4375rem;
  }

  .mx-sm-s7 {
    margin-left: .4375rem;
    margin-right: .4375rem;
  }

  .my-sm-s7 {
    margin-top: .4375rem;
    margin-bottom: .4375rem;
  }

  .px-sm-s7 {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .py-sm-s7 {
    padding-top: .4375rem;
    padding-bottom: .4375rem;
  }

  .gap-sm-s7 {
    gap: .4375rem;
  }

  .pm-sm-s7, .gx-sm-s7 > .gx-child {
    padding-left: .21875rem;
    padding-right: .21875rem;
  }

  .gx-sm-s7 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .4375rem;
  }

  .gx-sm-s7 > .gx-child:last-of-type {
    padding-left: .4375rem;
    padding-right: 0;
  }

  .m-sm-s8 {
    margin: .5rem;
  }

  .-m-sm-s8 {
    margin: -.5rem;
  }

  .mt-sm-s8 {
    margin-top: .5rem;
  }

  .-mt-sm-s8 {
    margin-top: -.5rem;
  }

  .mb-sm-s8 {
    margin-bottom: .5rem;
  }

  .-mb-sm-s8 {
    margin-bottom: -.5rem;
  }

  .mr-sm-s8 {
    margin-right: .5rem;
  }

  .-mr-sm-s8 {
    margin-right: -.5rem;
  }

  .ml-sm-s8 {
    margin-left: .5rem;
  }

  .-ml-sm-s8 {
    margin-left: -.5rem;
  }

  .p-sm-s8 {
    padding: .5rem;
  }

  .-p-sm-s8 {
    padding: -.5rem;
  }

  .pt-sm-s8 {
    padding-top: .5rem;
  }

  .-pt-sm-s8 {
    padding-top: -.5rem;
  }

  .pb-sm-s8 {
    padding-bottom: .5rem;
  }

  .-pb-sm-s8 {
    padding-bottom: -.5rem;
  }

  .pr-sm-s8 {
    padding-right: .5rem;
  }

  .-pr-sm-s8 {
    padding-right: -.5rem;
  }

  .pl-sm-s8 {
    padding-left: .5rem;
  }

  .-pl-sm-s8 {
    padding-left: -.5rem;
  }

  .mx-sm-s8 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .my-sm-s8 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .px-sm-s8 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .py-sm-s8 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .gap-sm-s8 {
    gap: .5rem;
  }

  .pm-sm-s8, .gx-sm-s8 > .gx-child {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .gx-sm-s8 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5rem;
  }

  .gx-sm-s8 > .gx-child:last-of-type {
    padding-left: .5rem;
    padding-right: 0;
  }

  .m-sm-s9 {
    margin: .5625rem;
  }

  .-m-sm-s9 {
    margin: -.5625rem;
  }

  .mt-sm-s9 {
    margin-top: .5625rem;
  }

  .-mt-sm-s9 {
    margin-top: -.5625rem;
  }

  .mb-sm-s9 {
    margin-bottom: .5625rem;
  }

  .-mb-sm-s9 {
    margin-bottom: -.5625rem;
  }

  .mr-sm-s9 {
    margin-right: .5625rem;
  }

  .-mr-sm-s9 {
    margin-right: -.5625rem;
  }

  .ml-sm-s9 {
    margin-left: .5625rem;
  }

  .-ml-sm-s9 {
    margin-left: -.5625rem;
  }

  .p-sm-s9 {
    padding: .5625rem;
  }

  .-p-sm-s9 {
    padding: -.5625rem;
  }

  .pt-sm-s9 {
    padding-top: .5625rem;
  }

  .-pt-sm-s9 {
    padding-top: -.5625rem;
  }

  .pb-sm-s9 {
    padding-bottom: .5625rem;
  }

  .-pb-sm-s9 {
    padding-bottom: -.5625rem;
  }

  .pr-sm-s9 {
    padding-right: .5625rem;
  }

  .-pr-sm-s9 {
    padding-right: -.5625rem;
  }

  .pl-sm-s9 {
    padding-left: .5625rem;
  }

  .-pl-sm-s9 {
    padding-left: -.5625rem;
  }

  .mx-sm-s9 {
    margin-left: .5625rem;
    margin-right: .5625rem;
  }

  .my-sm-s9 {
    margin-top: .5625rem;
    margin-bottom: .5625rem;
  }

  .px-sm-s9 {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .py-sm-s9 {
    padding-top: .5625rem;
    padding-bottom: .5625rem;
  }

  .gap-sm-s9 {
    gap: .5625rem;
  }

  .pm-sm-s9, .gx-sm-s9 > .gx-child {
    padding-left: .28125rem;
    padding-right: .28125rem;
  }

  .gx-sm-s9 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5625rem;
  }

  .gx-sm-s9 > .gx-child:last-of-type {
    padding-left: .5625rem;
    padding-right: 0;
  }

  .m-sm-s10 {
    margin: .625rem;
  }

  .-m-sm-s10 {
    margin: -.625rem;
  }

  .mt-sm-s10 {
    margin-top: .625rem;
  }

  .-mt-sm-s10 {
    margin-top: -.625rem;
  }

  .mb-sm-s10 {
    margin-bottom: .625rem;
  }

  .-mb-sm-s10 {
    margin-bottom: -.625rem;
  }

  .mr-sm-s10 {
    margin-right: .625rem;
  }

  .-mr-sm-s10 {
    margin-right: -.625rem;
  }

  .ml-sm-s10 {
    margin-left: .625rem;
  }

  .-ml-sm-s10 {
    margin-left: -.625rem;
  }

  .p-sm-s10 {
    padding: .625rem;
  }

  .-p-sm-s10 {
    padding: -.625rem;
  }

  .pt-sm-s10 {
    padding-top: .625rem;
  }

  .-pt-sm-s10 {
    padding-top: -.625rem;
  }

  .pb-sm-s10 {
    padding-bottom: .625rem;
  }

  .-pb-sm-s10 {
    padding-bottom: -.625rem;
  }

  .pr-sm-s10 {
    padding-right: .625rem;
  }

  .-pr-sm-s10 {
    padding-right: -.625rem;
  }

  .pl-sm-s10 {
    padding-left: .625rem;
  }

  .-pl-sm-s10 {
    padding-left: -.625rem;
  }

  .mx-sm-s10 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .my-sm-s10 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .px-sm-s10 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .py-sm-s10 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .gap-sm-s10 {
    gap: .625rem;
  }

  .pm-sm-s10, .gx-sm-s10 > .gx-child {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .gx-sm-s10 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .625rem;
  }

  .gx-sm-s10 > .gx-child:last-of-type {
    padding-left: .625rem;
    padding-right: 0;
  }

  .m-sm-s12 {
    margin: .75rem;
  }

  .-m-sm-s12 {
    margin: -.75rem;
  }

  .mt-sm-s12 {
    margin-top: .75rem;
  }

  .-mt-sm-s12 {
    margin-top: -.75rem;
  }

  .mb-sm-s12 {
    margin-bottom: .75rem;
  }

  .-mb-sm-s12 {
    margin-bottom: -.75rem;
  }

  .mr-sm-s12 {
    margin-right: .75rem;
  }

  .-mr-sm-s12 {
    margin-right: -.75rem;
  }

  .ml-sm-s12 {
    margin-left: .75rem;
  }

  .-ml-sm-s12 {
    margin-left: -.75rem;
  }

  .p-sm-s12 {
    padding: .75rem;
  }

  .-p-sm-s12 {
    padding: -.75rem;
  }

  .pt-sm-s12 {
    padding-top: .75rem;
  }

  .-pt-sm-s12 {
    padding-top: -.75rem;
  }

  .pb-sm-s12 {
    padding-bottom: .75rem;
  }

  .-pb-sm-s12 {
    padding-bottom: -.75rem;
  }

  .pr-sm-s12 {
    padding-right: .75rem;
  }

  .-pr-sm-s12 {
    padding-right: -.75rem;
  }

  .pl-sm-s12 {
    padding-left: .75rem;
  }

  .-pl-sm-s12 {
    padding-left: -.75rem;
  }

  .mx-sm-s12 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .my-sm-s12 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .px-sm-s12 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .py-sm-s12 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .gap-sm-s12 {
    gap: .75rem;
  }

  .pm-sm-s12, .gx-sm-s12 > .gx-child {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .gx-sm-s12 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .75rem;
  }

  .gx-sm-s12 > .gx-child:last-of-type {
    padding-left: .75rem;
    padding-right: 0;
  }

  .m-sm-s13 {
    margin: .8125rem;
  }

  .-m-sm-s13 {
    margin: -.8125rem;
  }

  .mt-sm-s13 {
    margin-top: .8125rem;
  }

  .-mt-sm-s13 {
    margin-top: -.8125rem;
  }

  .mb-sm-s13 {
    margin-bottom: .8125rem;
  }

  .-mb-sm-s13 {
    margin-bottom: -.8125rem;
  }

  .mr-sm-s13 {
    margin-right: .8125rem;
  }

  .-mr-sm-s13 {
    margin-right: -.8125rem;
  }

  .ml-sm-s13 {
    margin-left: .8125rem;
  }

  .-ml-sm-s13 {
    margin-left: -.8125rem;
  }

  .p-sm-s13 {
    padding: .8125rem;
  }

  .-p-sm-s13 {
    padding: -.8125rem;
  }

  .pt-sm-s13 {
    padding-top: .8125rem;
  }

  .-pt-sm-s13 {
    padding-top: -.8125rem;
  }

  .pb-sm-s13 {
    padding-bottom: .8125rem;
  }

  .-pb-sm-s13 {
    padding-bottom: -.8125rem;
  }

  .pr-sm-s13 {
    padding-right: .8125rem;
  }

  .-pr-sm-s13 {
    padding-right: -.8125rem;
  }

  .pl-sm-s13 {
    padding-left: .8125rem;
  }

  .-pl-sm-s13 {
    padding-left: -.8125rem;
  }

  .mx-sm-s13 {
    margin-left: .8125rem;
    margin-right: .8125rem;
  }

  .my-sm-s13 {
    margin-top: .8125rem;
    margin-bottom: .8125rem;
  }

  .px-sm-s13 {
    padding-left: .8125rem;
    padding-right: .8125rem;
  }

  .py-sm-s13 {
    padding-top: .8125rem;
    padding-bottom: .8125rem;
  }

  .gap-sm-s13 {
    gap: .8125rem;
  }

  .pm-sm-s13, .gx-sm-s13 > .gx-child {
    padding-left: .40625rem;
    padding-right: .40625rem;
  }

  .gx-sm-s13 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .8125rem;
  }

  .gx-sm-s13 > .gx-child:last-of-type {
    padding-left: .8125rem;
    padding-right: 0;
  }

  .m-sm-s14 {
    margin: .875rem;
  }

  .-m-sm-s14 {
    margin: -.875rem;
  }

  .mt-sm-s14 {
    margin-top: .875rem;
  }

  .-mt-sm-s14 {
    margin-top: -.875rem;
  }

  .mb-sm-s14 {
    margin-bottom: .875rem;
  }

  .-mb-sm-s14 {
    margin-bottom: -.875rem;
  }

  .mr-sm-s14 {
    margin-right: .875rem;
  }

  .-mr-sm-s14 {
    margin-right: -.875rem;
  }

  .ml-sm-s14 {
    margin-left: .875rem;
  }

  .-ml-sm-s14 {
    margin-left: -.875rem;
  }

  .p-sm-s14 {
    padding: .875rem;
  }

  .-p-sm-s14 {
    padding: -.875rem;
  }

  .pt-sm-s14 {
    padding-top: .875rem;
  }

  .-pt-sm-s14 {
    padding-top: -.875rem;
  }

  .pb-sm-s14 {
    padding-bottom: .875rem;
  }

  .-pb-sm-s14 {
    padding-bottom: -.875rem;
  }

  .pr-sm-s14 {
    padding-right: .875rem;
  }

  .-pr-sm-s14 {
    padding-right: -.875rem;
  }

  .pl-sm-s14 {
    padding-left: .875rem;
  }

  .-pl-sm-s14 {
    padding-left: -.875rem;
  }

  .mx-sm-s14 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .my-sm-s14 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .px-sm-s14 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .py-sm-s14 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .gap-sm-s14 {
    gap: .875rem;
  }

  .pm-sm-s14, .gx-sm-s14 > .gx-child {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .gx-sm-s14 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .875rem;
  }

  .gx-sm-s14 > .gx-child:last-of-type {
    padding-left: .875rem;
    padding-right: 0;
  }

  .m-sm-s15 {
    margin: .9375rem;
  }

  .-m-sm-s15 {
    margin: -.9375rem;
  }

  .mt-sm-s15 {
    margin-top: .9375rem;
  }

  .-mt-sm-s15 {
    margin-top: -.9375rem;
  }

  .mb-sm-s15 {
    margin-bottom: .9375rem;
  }

  .-mb-sm-s15 {
    margin-bottom: -.9375rem;
  }

  .mr-sm-s15 {
    margin-right: .9375rem;
  }

  .-mr-sm-s15 {
    margin-right: -.9375rem;
  }

  .ml-sm-s15 {
    margin-left: .9375rem;
  }

  .-ml-sm-s15 {
    margin-left: -.9375rem;
  }

  .p-sm-s15 {
    padding: .9375rem;
  }

  .-p-sm-s15 {
    padding: -.9375rem;
  }

  .pt-sm-s15 {
    padding-top: .9375rem;
  }

  .-pt-sm-s15 {
    padding-top: -.9375rem;
  }

  .pb-sm-s15 {
    padding-bottom: .9375rem;
  }

  .-pb-sm-s15 {
    padding-bottom: -.9375rem;
  }

  .pr-sm-s15 {
    padding-right: .9375rem;
  }

  .-pr-sm-s15 {
    padding-right: -.9375rem;
  }

  .pl-sm-s15 {
    padding-left: .9375rem;
  }

  .-pl-sm-s15 {
    padding-left: -.9375rem;
  }

  .mx-sm-s15 {
    margin-left: .9375rem;
    margin-right: .9375rem;
  }

  .my-sm-s15 {
    margin-top: .9375rem;
    margin-bottom: .9375rem;
  }

  .px-sm-s15 {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .py-sm-s15 {
    padding-top: .9375rem;
    padding-bottom: .9375rem;
  }

  .gap-sm-s15 {
    gap: .9375rem;
  }

  .pm-sm-s15, .gx-sm-s15 > .gx-child {
    padding-left: .46875rem;
    padding-right: .46875rem;
  }

  .gx-sm-s15 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .9375rem;
  }

  .gx-sm-s15 > .gx-child:last-of-type {
    padding-left: .9375rem;
    padding-right: 0;
  }

  .m-sm-s16 {
    margin: 1rem;
  }

  .-m-sm-s16 {
    margin: -1rem;
  }

  .mt-sm-s16 {
    margin-top: 1rem;
  }

  .-mt-sm-s16 {
    margin-top: -1rem;
  }

  .mb-sm-s16 {
    margin-bottom: 1rem;
  }

  .-mb-sm-s16 {
    margin-bottom: -1rem;
  }

  .mr-sm-s16 {
    margin-right: 1rem;
  }

  .-mr-sm-s16 {
    margin-right: -1rem;
  }

  .ml-sm-s16 {
    margin-left: 1rem;
  }

  .-ml-sm-s16 {
    margin-left: -1rem;
  }

  .p-sm-s16 {
    padding: 1rem;
  }

  .-p-sm-s16 {
    padding: -1rem;
  }

  .pt-sm-s16 {
    padding-top: 1rem;
  }

  .-pt-sm-s16 {
    padding-top: -1rem;
  }

  .pb-sm-s16 {
    padding-bottom: 1rem;
  }

  .-pb-sm-s16 {
    padding-bottom: -1rem;
  }

  .pr-sm-s16 {
    padding-right: 1rem;
  }

  .-pr-sm-s16 {
    padding-right: -1rem;
  }

  .pl-sm-s16 {
    padding-left: 1rem;
  }

  .-pl-sm-s16 {
    padding-left: -1rem;
  }

  .mx-sm-s16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .my-sm-s16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .px-sm-s16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .py-sm-s16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .gap-sm-s16 {
    gap: 1rem;
  }

  .pm-sm-s16, .gx-sm-s16 > .gx-child {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .gx-sm-s16 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1rem;
  }

  .gx-sm-s16 > .gx-child:last-of-type {
    padding-left: 1rem;
    padding-right: 0;
  }

  .m-sm-s18 {
    margin: 1.125rem;
  }

  .-m-sm-s18 {
    margin: -1.125rem;
  }

  .mt-sm-s18 {
    margin-top: 1.125rem;
  }

  .-mt-sm-s18 {
    margin-top: -1.125rem;
  }

  .mb-sm-s18 {
    margin-bottom: 1.125rem;
  }

  .-mb-sm-s18 {
    margin-bottom: -1.125rem;
  }

  .mr-sm-s18 {
    margin-right: 1.125rem;
  }

  .-mr-sm-s18 {
    margin-right: -1.125rem;
  }

  .ml-sm-s18 {
    margin-left: 1.125rem;
  }

  .-ml-sm-s18 {
    margin-left: -1.125rem;
  }

  .p-sm-s18 {
    padding: 1.125rem;
  }

  .-p-sm-s18 {
    padding: -1.125rem;
  }

  .pt-sm-s18 {
    padding-top: 1.125rem;
  }

  .-pt-sm-s18 {
    padding-top: -1.125rem;
  }

  .pb-sm-s18 {
    padding-bottom: 1.125rem;
  }

  .-pb-sm-s18 {
    padding-bottom: -1.125rem;
  }

  .pr-sm-s18 {
    padding-right: 1.125rem;
  }

  .-pr-sm-s18 {
    padding-right: -1.125rem;
  }

  .pl-sm-s18 {
    padding-left: 1.125rem;
  }

  .-pl-sm-s18 {
    padding-left: -1.125rem;
  }

  .mx-sm-s18 {
    margin-left: 1.125rem;
    margin-right: 1.125rem;
  }

  .my-sm-s18 {
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
  }

  .px-sm-s18 {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .py-sm-s18 {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }

  .gap-sm-s18 {
    gap: 1.125rem;
  }

  .pm-sm-s18, .gx-sm-s18 > .gx-child {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .gx-sm-s18 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.125rem;
  }

  .gx-sm-s18 > .gx-child:last-of-type {
    padding-left: 1.125rem;
    padding-right: 0;
  }

  .m-sm-s20 {
    margin: 1.25rem;
  }

  .-m-sm-s20 {
    margin: -1.25rem;
  }

  .mt-sm-s20 {
    margin-top: 1.25rem;
  }

  .-mt-sm-s20 {
    margin-top: -1.25rem;
  }

  .mb-sm-s20 {
    margin-bottom: 1.25rem;
  }

  .-mb-sm-s20 {
    margin-bottom: -1.25rem;
  }

  .mr-sm-s20 {
    margin-right: 1.25rem;
  }

  .-mr-sm-s20 {
    margin-right: -1.25rem;
  }

  .ml-sm-s20 {
    margin-left: 1.25rem;
  }

  .-ml-sm-s20 {
    margin-left: -1.25rem;
  }

  .p-sm-s20 {
    padding: 1.25rem;
  }

  .-p-sm-s20 {
    padding: -1.25rem;
  }

  .pt-sm-s20 {
    padding-top: 1.25rem;
  }

  .-pt-sm-s20 {
    padding-top: -1.25rem;
  }

  .pb-sm-s20 {
    padding-bottom: 1.25rem;
  }

  .-pb-sm-s20 {
    padding-bottom: -1.25rem;
  }

  .pr-sm-s20 {
    padding-right: 1.25rem;
  }

  .-pr-sm-s20 {
    padding-right: -1.25rem;
  }

  .pl-sm-s20 {
    padding-left: 1.25rem;
  }

  .-pl-sm-s20 {
    padding-left: -1.25rem;
  }

  .mx-sm-s20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .my-sm-s20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .px-sm-s20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .py-sm-s20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .gap-sm-s20 {
    gap: 1.25rem;
  }

  .pm-sm-s20, .gx-sm-s20 > .gx-child {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .gx-sm-s20 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.25rem;
  }

  .gx-sm-s20 > .gx-child:last-of-type {
    padding-left: 1.25rem;
    padding-right: 0;
  }

  .m-sm-s22 {
    margin: 1.375rem;
  }

  .-m-sm-s22 {
    margin: -1.375rem;
  }

  .mt-sm-s22 {
    margin-top: 1.375rem;
  }

  .-mt-sm-s22 {
    margin-top: -1.375rem;
  }

  .mb-sm-s22 {
    margin-bottom: 1.375rem;
  }

  .-mb-sm-s22 {
    margin-bottom: -1.375rem;
  }

  .mr-sm-s22 {
    margin-right: 1.375rem;
  }

  .-mr-sm-s22 {
    margin-right: -1.375rem;
  }

  .ml-sm-s22 {
    margin-left: 1.375rem;
  }

  .-ml-sm-s22 {
    margin-left: -1.375rem;
  }

  .p-sm-s22 {
    padding: 1.375rem;
  }

  .-p-sm-s22 {
    padding: -1.375rem;
  }

  .pt-sm-s22 {
    padding-top: 1.375rem;
  }

  .-pt-sm-s22 {
    padding-top: -1.375rem;
  }

  .pb-sm-s22 {
    padding-bottom: 1.375rem;
  }

  .-pb-sm-s22 {
    padding-bottom: -1.375rem;
  }

  .pr-sm-s22 {
    padding-right: 1.375rem;
  }

  .-pr-sm-s22 {
    padding-right: -1.375rem;
  }

  .pl-sm-s22 {
    padding-left: 1.375rem;
  }

  .-pl-sm-s22 {
    padding-left: -1.375rem;
  }

  .mx-sm-s22 {
    margin-left: 1.375rem;
    margin-right: 1.375rem;
  }

  .my-sm-s22 {
    margin-top: 1.375rem;
    margin-bottom: 1.375rem;
  }

  .px-sm-s22 {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }

  .py-sm-s22 {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
  }

  .gap-sm-s22 {
    gap: 1.375rem;
  }

  .pm-sm-s22, .gx-sm-s22 > .gx-child {
    padding-left: .6875rem;
    padding-right: .6875rem;
  }

  .gx-sm-s22 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.375rem;
  }

  .gx-sm-s22 > .gx-child:last-of-type {
    padding-left: 1.375rem;
    padding-right: 0;
  }

  .m-sm-s24 {
    margin: 1.5rem;
  }

  .-m-sm-s24 {
    margin: -1.5rem;
  }

  .mt-sm-s24 {
    margin-top: 1.5rem;
  }

  .-mt-sm-s24 {
    margin-top: -1.5rem;
  }

  .mb-sm-s24 {
    margin-bottom: 1.5rem;
  }

  .-mb-sm-s24 {
    margin-bottom: -1.5rem;
  }

  .mr-sm-s24 {
    margin-right: 1.5rem;
  }

  .-mr-sm-s24 {
    margin-right: -1.5rem;
  }

  .ml-sm-s24 {
    margin-left: 1.5rem;
  }

  .-ml-sm-s24 {
    margin-left: -1.5rem;
  }

  .p-sm-s24 {
    padding: 1.5rem;
  }

  .-p-sm-s24 {
    padding: -1.5rem;
  }

  .pt-sm-s24 {
    padding-top: 1.5rem;
  }

  .-pt-sm-s24 {
    padding-top: -1.5rem;
  }

  .pb-sm-s24 {
    padding-bottom: 1.5rem;
  }

  .-pb-sm-s24 {
    padding-bottom: -1.5rem;
  }

  .pr-sm-s24 {
    padding-right: 1.5rem;
  }

  .-pr-sm-s24 {
    padding-right: -1.5rem;
  }

  .pl-sm-s24 {
    padding-left: 1.5rem;
  }

  .-pl-sm-s24 {
    padding-left: -1.5rem;
  }

  .mx-sm-s24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .my-sm-s24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .px-sm-s24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .py-sm-s24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .gap-sm-s24 {
    gap: 1.5rem;
  }

  .pm-sm-s24, .gx-sm-s24 > .gx-child {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .gx-sm-s24 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5rem;
  }

  .gx-sm-s24 > .gx-child:last-of-type {
    padding-left: 1.5rem;
    padding-right: 0;
  }

  .m-sm-s25 {
    margin: 1.5625rem;
  }

  .-m-sm-s25 {
    margin: -1.5625rem;
  }

  .mt-sm-s25 {
    margin-top: 1.5625rem;
  }

  .-mt-sm-s25 {
    margin-top: -1.5625rem;
  }

  .mb-sm-s25 {
    margin-bottom: 1.5625rem;
  }

  .-mb-sm-s25 {
    margin-bottom: -1.5625rem;
  }

  .mr-sm-s25 {
    margin-right: 1.5625rem;
  }

  .-mr-sm-s25 {
    margin-right: -1.5625rem;
  }

  .ml-sm-s25 {
    margin-left: 1.5625rem;
  }

  .-ml-sm-s25 {
    margin-left: -1.5625rem;
  }

  .p-sm-s25 {
    padding: 1.5625rem;
  }

  .-p-sm-s25 {
    padding: -1.5625rem;
  }

  .pt-sm-s25 {
    padding-top: 1.5625rem;
  }

  .-pt-sm-s25 {
    padding-top: -1.5625rem;
  }

  .pb-sm-s25 {
    padding-bottom: 1.5625rem;
  }

  .-pb-sm-s25 {
    padding-bottom: -1.5625rem;
  }

  .pr-sm-s25 {
    padding-right: 1.5625rem;
  }

  .-pr-sm-s25 {
    padding-right: -1.5625rem;
  }

  .pl-sm-s25 {
    padding-left: 1.5625rem;
  }

  .-pl-sm-s25 {
    padding-left: -1.5625rem;
  }

  .mx-sm-s25 {
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
  }

  .my-sm-s25 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }

  .px-sm-s25 {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .py-sm-s25 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }

  .gap-sm-s25 {
    gap: 1.5625rem;
  }

  .pm-sm-s25, .gx-sm-s25 > .gx-child {
    padding-left: .78125rem;
    padding-right: .78125rem;
  }

  .gx-sm-s25 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5625rem;
  }

  .gx-sm-s25 > .gx-child:last-of-type {
    padding-left: 1.5625rem;
    padding-right: 0;
  }

  .m-sm-s27 {
    margin: 1.6875rem;
  }

  .-m-sm-s27 {
    margin: -1.6875rem;
  }

  .mt-sm-s27 {
    margin-top: 1.6875rem;
  }

  .-mt-sm-s27 {
    margin-top: -1.6875rem;
  }

  .mb-sm-s27 {
    margin-bottom: 1.6875rem;
  }

  .-mb-sm-s27 {
    margin-bottom: -1.6875rem;
  }

  .mr-sm-s27 {
    margin-right: 1.6875rem;
  }

  .-mr-sm-s27 {
    margin-right: -1.6875rem;
  }

  .ml-sm-s27 {
    margin-left: 1.6875rem;
  }

  .-ml-sm-s27 {
    margin-left: -1.6875rem;
  }

  .p-sm-s27 {
    padding: 1.6875rem;
  }

  .-p-sm-s27 {
    padding: -1.6875rem;
  }

  .pt-sm-s27 {
    padding-top: 1.6875rem;
  }

  .-pt-sm-s27 {
    padding-top: -1.6875rem;
  }

  .pb-sm-s27 {
    padding-bottom: 1.6875rem;
  }

  .-pb-sm-s27 {
    padding-bottom: -1.6875rem;
  }

  .pr-sm-s27 {
    padding-right: 1.6875rem;
  }

  .-pr-sm-s27 {
    padding-right: -1.6875rem;
  }

  .pl-sm-s27 {
    padding-left: 1.6875rem;
  }

  .-pl-sm-s27 {
    padding-left: -1.6875rem;
  }

  .mx-sm-s27 {
    margin-left: 1.6875rem;
    margin-right: 1.6875rem;
  }

  .my-sm-s27 {
    margin-top: 1.6875rem;
    margin-bottom: 1.6875rem;
  }

  .px-sm-s27 {
    padding-left: 1.6875rem;
    padding-right: 1.6875rem;
  }

  .py-sm-s27 {
    padding-top: 1.6875rem;
    padding-bottom: 1.6875rem;
  }

  .gap-sm-s27 {
    gap: 1.6875rem;
  }

  .pm-sm-s27, .gx-sm-s27 > .gx-child {
    padding-left: .84375rem;
    padding-right: .84375rem;
  }

  .gx-sm-s27 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.6875rem;
  }

  .gx-sm-s27 > .gx-child:last-of-type {
    padding-left: 1.6875rem;
    padding-right: 0;
  }

  .m-sm-s29 {
    margin: 1.8125rem;
  }

  .-m-sm-s29 {
    margin: -1.8125rem;
  }

  .mt-sm-s29 {
    margin-top: 1.8125rem;
  }

  .-mt-sm-s29 {
    margin-top: -1.8125rem;
  }

  .mb-sm-s29 {
    margin-bottom: 1.8125rem;
  }

  .-mb-sm-s29 {
    margin-bottom: -1.8125rem;
  }

  .mr-sm-s29 {
    margin-right: 1.8125rem;
  }

  .-mr-sm-s29 {
    margin-right: -1.8125rem;
  }

  .ml-sm-s29 {
    margin-left: 1.8125rem;
  }

  .-ml-sm-s29 {
    margin-left: -1.8125rem;
  }

  .p-sm-s29 {
    padding: 1.8125rem;
  }

  .-p-sm-s29 {
    padding: -1.8125rem;
  }

  .pt-sm-s29 {
    padding-top: 1.8125rem;
  }

  .-pt-sm-s29 {
    padding-top: -1.8125rem;
  }

  .pb-sm-s29 {
    padding-bottom: 1.8125rem;
  }

  .-pb-sm-s29 {
    padding-bottom: -1.8125rem;
  }

  .pr-sm-s29 {
    padding-right: 1.8125rem;
  }

  .-pr-sm-s29 {
    padding-right: -1.8125rem;
  }

  .pl-sm-s29 {
    padding-left: 1.8125rem;
  }

  .-pl-sm-s29 {
    padding-left: -1.8125rem;
  }

  .mx-sm-s29 {
    margin-left: 1.8125rem;
    margin-right: 1.8125rem;
  }

  .my-sm-s29 {
    margin-top: 1.8125rem;
    margin-bottom: 1.8125rem;
  }

  .px-sm-s29 {
    padding-left: 1.8125rem;
    padding-right: 1.8125rem;
  }

  .py-sm-s29 {
    padding-top: 1.8125rem;
    padding-bottom: 1.8125rem;
  }

  .gap-sm-s29 {
    gap: 1.8125rem;
  }

  .pm-sm-s29, .gx-sm-s29 > .gx-child {
    padding-left: .90625rem;
    padding-right: .90625rem;
  }

  .gx-sm-s29 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.8125rem;
  }

  .gx-sm-s29 > .gx-child:last-of-type {
    padding-left: 1.8125rem;
    padding-right: 0;
  }

  .m-sm-s30 {
    margin: 1.875rem;
  }

  .-m-sm-s30 {
    margin: -1.875rem;
  }

  .mt-sm-s30 {
    margin-top: 1.875rem;
  }

  .-mt-sm-s30 {
    margin-top: -1.875rem;
  }

  .mb-sm-s30 {
    margin-bottom: 1.875rem;
  }

  .-mb-sm-s30 {
    margin-bottom: -1.875rem;
  }

  .mr-sm-s30 {
    margin-right: 1.875rem;
  }

  .-mr-sm-s30 {
    margin-right: -1.875rem;
  }

  .ml-sm-s30 {
    margin-left: 1.875rem;
  }

  .-ml-sm-s30 {
    margin-left: -1.875rem;
  }

  .p-sm-s30 {
    padding: 1.875rem;
  }

  .-p-sm-s30 {
    padding: -1.875rem;
  }

  .pt-sm-s30 {
    padding-top: 1.875rem;
  }

  .-pt-sm-s30 {
    padding-top: -1.875rem;
  }

  .pb-sm-s30 {
    padding-bottom: 1.875rem;
  }

  .-pb-sm-s30 {
    padding-bottom: -1.875rem;
  }

  .pr-sm-s30 {
    padding-right: 1.875rem;
  }

  .-pr-sm-s30 {
    padding-right: -1.875rem;
  }

  .pl-sm-s30 {
    padding-left: 1.875rem;
  }

  .-pl-sm-s30 {
    padding-left: -1.875rem;
  }

  .mx-sm-s30 {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }

  .my-sm-s30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .px-sm-s30 {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .py-sm-s30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .gap-sm-s30 {
    gap: 1.875rem;
  }

  .pm-sm-s30, .gx-sm-s30 > .gx-child {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .gx-sm-s30 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.875rem;
  }

  .gx-sm-s30 > .gx-child:last-of-type {
    padding-left: 1.875rem;
    padding-right: 0;
  }

  .m-sm-s32 {
    margin: 2rem;
  }

  .-m-sm-s32 {
    margin: -2rem;
  }

  .mt-sm-s32 {
    margin-top: 2rem;
  }

  .-mt-sm-s32 {
    margin-top: -2rem;
  }

  .mb-sm-s32 {
    margin-bottom: 2rem;
  }

  .-mb-sm-s32 {
    margin-bottom: -2rem;
  }

  .mr-sm-s32 {
    margin-right: 2rem;
  }

  .-mr-sm-s32 {
    margin-right: -2rem;
  }

  .ml-sm-s32 {
    margin-left: 2rem;
  }

  .-ml-sm-s32 {
    margin-left: -2rem;
  }

  .p-sm-s32 {
    padding: 2rem;
  }

  .-p-sm-s32 {
    padding: -2rem;
  }

  .pt-sm-s32 {
    padding-top: 2rem;
  }

  .-pt-sm-s32 {
    padding-top: -2rem;
  }

  .pb-sm-s32 {
    padding-bottom: 2rem;
  }

  .-pb-sm-s32 {
    padding-bottom: -2rem;
  }

  .pr-sm-s32 {
    padding-right: 2rem;
  }

  .-pr-sm-s32 {
    padding-right: -2rem;
  }

  .pl-sm-s32 {
    padding-left: 2rem;
  }

  .-pl-sm-s32 {
    padding-left: -2rem;
  }

  .mx-sm-s32 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .my-sm-s32 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .px-sm-s32 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .py-sm-s32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .gap-sm-s32 {
    gap: 2rem;
  }

  .pm-sm-s32, .gx-sm-s32 > .gx-child {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .gx-sm-s32 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2rem;
  }

  .gx-sm-s32 > .gx-child:last-of-type {
    padding-left: 2rem;
    padding-right: 0;
  }

  .m-sm-s35 {
    margin: 2.1875rem;
  }

  .-m-sm-s35 {
    margin: -2.1875rem;
  }

  .mt-sm-s35 {
    margin-top: 2.1875rem;
  }

  .-mt-sm-s35 {
    margin-top: -2.1875rem;
  }

  .mb-sm-s35 {
    margin-bottom: 2.1875rem;
  }

  .-mb-sm-s35 {
    margin-bottom: -2.1875rem;
  }

  .mr-sm-s35 {
    margin-right: 2.1875rem;
  }

  .-mr-sm-s35 {
    margin-right: -2.1875rem;
  }

  .ml-sm-s35 {
    margin-left: 2.1875rem;
  }

  .-ml-sm-s35 {
    margin-left: -2.1875rem;
  }

  .p-sm-s35 {
    padding: 2.1875rem;
  }

  .-p-sm-s35 {
    padding: -2.1875rem;
  }

  .pt-sm-s35 {
    padding-top: 2.1875rem;
  }

  .-pt-sm-s35 {
    padding-top: -2.1875rem;
  }

  .pb-sm-s35 {
    padding-bottom: 2.1875rem;
  }

  .-pb-sm-s35 {
    padding-bottom: -2.1875rem;
  }

  .pr-sm-s35 {
    padding-right: 2.1875rem;
  }

  .-pr-sm-s35 {
    padding-right: -2.1875rem;
  }

  .pl-sm-s35 {
    padding-left: 2.1875rem;
  }

  .-pl-sm-s35 {
    padding-left: -2.1875rem;
  }

  .mx-sm-s35 {
    margin-left: 2.1875rem;
    margin-right: 2.1875rem;
  }

  .my-sm-s35 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }

  .px-sm-s35 {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .py-sm-s35 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }

  .gap-sm-s35 {
    gap: 2.1875rem;
  }

  .pm-sm-s35, .gx-sm-s35 > .gx-child {
    padding-left: 1.09375rem;
    padding-right: 1.09375rem;
  }

  .gx-sm-s35 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.1875rem;
  }

  .gx-sm-s35 > .gx-child:last-of-type {
    padding-left: 2.1875rem;
    padding-right: 0;
  }

  .m-sm-s40 {
    margin: 2.5rem;
  }

  .-m-sm-s40 {
    margin: -2.5rem;
  }

  .mt-sm-s40 {
    margin-top: 2.5rem;
  }

  .-mt-sm-s40 {
    margin-top: -2.5rem;
  }

  .mb-sm-s40 {
    margin-bottom: 2.5rem;
  }

  .-mb-sm-s40 {
    margin-bottom: -2.5rem;
  }

  .mr-sm-s40 {
    margin-right: 2.5rem;
  }

  .-mr-sm-s40 {
    margin-right: -2.5rem;
  }

  .ml-sm-s40 {
    margin-left: 2.5rem;
  }

  .-ml-sm-s40 {
    margin-left: -2.5rem;
  }

  .p-sm-s40 {
    padding: 2.5rem;
  }

  .-p-sm-s40 {
    padding: -2.5rem;
  }

  .pt-sm-s40 {
    padding-top: 2.5rem;
  }

  .-pt-sm-s40 {
    padding-top: -2.5rem;
  }

  .pb-sm-s40 {
    padding-bottom: 2.5rem;
  }

  .-pb-sm-s40 {
    padding-bottom: -2.5rem;
  }

  .pr-sm-s40 {
    padding-right: 2.5rem;
  }

  .-pr-sm-s40 {
    padding-right: -2.5rem;
  }

  .pl-sm-s40 {
    padding-left: 2.5rem;
  }

  .-pl-sm-s40 {
    padding-left: -2.5rem;
  }

  .mx-sm-s40 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .my-sm-s40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .px-sm-s40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .py-sm-s40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .gap-sm-s40 {
    gap: 2.5rem;
  }

  .pm-sm-s40, .gx-sm-s40 > .gx-child {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .gx-sm-s40 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.5rem;
  }

  .gx-sm-s40 > .gx-child:last-of-type {
    padding-left: 2.5rem;
    padding-right: 0;
  }

  .m-sm-s45 {
    margin: 2.8125rem;
  }

  .-m-sm-s45 {
    margin: -2.8125rem;
  }

  .mt-sm-s45 {
    margin-top: 2.8125rem;
  }

  .-mt-sm-s45 {
    margin-top: -2.8125rem;
  }

  .mb-sm-s45 {
    margin-bottom: 2.8125rem;
  }

  .-mb-sm-s45 {
    margin-bottom: -2.8125rem;
  }

  .mr-sm-s45 {
    margin-right: 2.8125rem;
  }

  .-mr-sm-s45 {
    margin-right: -2.8125rem;
  }

  .ml-sm-s45 {
    margin-left: 2.8125rem;
  }

  .-ml-sm-s45 {
    margin-left: -2.8125rem;
  }

  .p-sm-s45 {
    padding: 2.8125rem;
  }

  .-p-sm-s45 {
    padding: -2.8125rem;
  }

  .pt-sm-s45 {
    padding-top: 2.8125rem;
  }

  .-pt-sm-s45 {
    padding-top: -2.8125rem;
  }

  .pb-sm-s45 {
    padding-bottom: 2.8125rem;
  }

  .-pb-sm-s45 {
    padding-bottom: -2.8125rem;
  }

  .pr-sm-s45 {
    padding-right: 2.8125rem;
  }

  .-pr-sm-s45 {
    padding-right: -2.8125rem;
  }

  .pl-sm-s45 {
    padding-left: 2.8125rem;
  }

  .-pl-sm-s45 {
    padding-left: -2.8125rem;
  }

  .mx-sm-s45 {
    margin-left: 2.8125rem;
    margin-right: 2.8125rem;
  }

  .my-sm-s45 {
    margin-top: 2.8125rem;
    margin-bottom: 2.8125rem;
  }

  .px-sm-s45 {
    padding-left: 2.8125rem;
    padding-right: 2.8125rem;
  }

  .py-sm-s45 {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }

  .gap-sm-s45 {
    gap: 2.8125rem;
  }

  .pm-sm-s45, .gx-sm-s45 > .gx-child {
    padding-left: 1.40625rem;
    padding-right: 1.40625rem;
  }

  .gx-sm-s45 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.8125rem;
  }

  .gx-sm-s45 > .gx-child:last-of-type {
    padding-left: 2.8125rem;
    padding-right: 0;
  }

  .m-sm-s48 {
    margin: 3rem;
  }

  .-m-sm-s48 {
    margin: -3rem;
  }

  .mt-sm-s48 {
    margin-top: 3rem;
  }

  .-mt-sm-s48 {
    margin-top: -3rem;
  }

  .mb-sm-s48 {
    margin-bottom: 3rem;
  }

  .-mb-sm-s48 {
    margin-bottom: -3rem;
  }

  .mr-sm-s48 {
    margin-right: 3rem;
  }

  .-mr-sm-s48 {
    margin-right: -3rem;
  }

  .ml-sm-s48 {
    margin-left: 3rem;
  }

  .-ml-sm-s48 {
    margin-left: -3rem;
  }

  .p-sm-s48 {
    padding: 3rem;
  }

  .-p-sm-s48 {
    padding: -3rem;
  }

  .pt-sm-s48 {
    padding-top: 3rem;
  }

  .-pt-sm-s48 {
    padding-top: -3rem;
  }

  .pb-sm-s48 {
    padding-bottom: 3rem;
  }

  .-pb-sm-s48 {
    padding-bottom: -3rem;
  }

  .pr-sm-s48 {
    padding-right: 3rem;
  }

  .-pr-sm-s48 {
    padding-right: -3rem;
  }

  .pl-sm-s48 {
    padding-left: 3rem;
  }

  .-pl-sm-s48 {
    padding-left: -3rem;
  }

  .mx-sm-s48 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .my-sm-s48 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .px-sm-s48 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .py-sm-s48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .gap-sm-s48 {
    gap: 3rem;
  }

  .pm-sm-s48, .gx-sm-s48 > .gx-child {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .gx-sm-s48 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3rem;
  }

  .gx-sm-s48 > .gx-child:last-of-type {
    padding-left: 3rem;
    padding-right: 0;
  }

  .m-sm-s50 {
    margin: 3.125rem;
  }

  .-m-sm-s50 {
    margin: -3.125rem;
  }

  .mt-sm-s50 {
    margin-top: 3.125rem;
  }

  .-mt-sm-s50 {
    margin-top: -3.125rem;
  }

  .mb-sm-s50 {
    margin-bottom: 3.125rem;
  }

  .-mb-sm-s50 {
    margin-bottom: -3.125rem;
  }

  .mr-sm-s50 {
    margin-right: 3.125rem;
  }

  .-mr-sm-s50 {
    margin-right: -3.125rem;
  }

  .ml-sm-s50 {
    margin-left: 3.125rem;
  }

  .-ml-sm-s50 {
    margin-left: -3.125rem;
  }

  .p-sm-s50 {
    padding: 3.125rem;
  }

  .-p-sm-s50 {
    padding: -3.125rem;
  }

  .pt-sm-s50 {
    padding-top: 3.125rem;
  }

  .-pt-sm-s50 {
    padding-top: -3.125rem;
  }

  .pb-sm-s50 {
    padding-bottom: 3.125rem;
  }

  .-pb-sm-s50 {
    padding-bottom: -3.125rem;
  }

  .pr-sm-s50 {
    padding-right: 3.125rem;
  }

  .-pr-sm-s50 {
    padding-right: -3.125rem;
  }

  .pl-sm-s50 {
    padding-left: 3.125rem;
  }

  .-pl-sm-s50 {
    padding-left: -3.125rem;
  }

  .mx-sm-s50 {
    margin-left: 3.125rem;
    margin-right: 3.125rem;
  }

  .my-sm-s50 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }

  .px-sm-s50 {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .py-sm-s50 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }

  .gap-sm-s50 {
    gap: 3.125rem;
  }

  .pm-sm-s50, .gx-sm-s50 > .gx-child {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .gx-sm-s50 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.125rem;
  }

  .gx-sm-s50 > .gx-child:last-of-type {
    padding-left: 3.125rem;
    padding-right: 0;
  }

  .m-sm-s60 {
    margin: 3.75rem;
  }

  .-m-sm-s60 {
    margin: -3.75rem;
  }

  .mt-sm-s60 {
    margin-top: 3.75rem;
  }

  .-mt-sm-s60 {
    margin-top: -3.75rem;
  }

  .mb-sm-s60 {
    margin-bottom: 3.75rem;
  }

  .-mb-sm-s60 {
    margin-bottom: -3.75rem;
  }

  .mr-sm-s60 {
    margin-right: 3.75rem;
  }

  .-mr-sm-s60 {
    margin-right: -3.75rem;
  }

  .ml-sm-s60 {
    margin-left: 3.75rem;
  }

  .-ml-sm-s60 {
    margin-left: -3.75rem;
  }

  .p-sm-s60 {
    padding: 3.75rem;
  }

  .-p-sm-s60 {
    padding: -3.75rem;
  }

  .pt-sm-s60 {
    padding-top: 3.75rem;
  }

  .-pt-sm-s60 {
    padding-top: -3.75rem;
  }

  .pb-sm-s60 {
    padding-bottom: 3.75rem;
  }

  .-pb-sm-s60 {
    padding-bottom: -3.75rem;
  }

  .pr-sm-s60 {
    padding-right: 3.75rem;
  }

  .-pr-sm-s60 {
    padding-right: -3.75rem;
  }

  .pl-sm-s60 {
    padding-left: 3.75rem;
  }

  .-pl-sm-s60 {
    padding-left: -3.75rem;
  }

  .mx-sm-s60 {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
  }

  .my-sm-s60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .px-sm-s60 {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .py-sm-s60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  .gap-sm-s60 {
    gap: 3.75rem;
  }

  .pm-sm-s60, .gx-sm-s60 > .gx-child {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .gx-sm-s60 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.75rem;
  }

  .gx-sm-s60 > .gx-child:last-of-type {
    padding-left: 3.75rem;
    padding-right: 0;
  }

  .m-sm-s70 {
    margin: 4.375rem;
  }

  .-m-sm-s70 {
    margin: -4.375rem;
  }

  .mt-sm-s70 {
    margin-top: 4.375rem;
  }

  .-mt-sm-s70 {
    margin-top: -4.375rem;
  }

  .mb-sm-s70 {
    margin-bottom: 4.375rem;
  }

  .-mb-sm-s70 {
    margin-bottom: -4.375rem;
  }

  .mr-sm-s70 {
    margin-right: 4.375rem;
  }

  .-mr-sm-s70 {
    margin-right: -4.375rem;
  }

  .ml-sm-s70 {
    margin-left: 4.375rem;
  }

  .-ml-sm-s70 {
    margin-left: -4.375rem;
  }

  .p-sm-s70 {
    padding: 4.375rem;
  }

  .-p-sm-s70 {
    padding: -4.375rem;
  }

  .pt-sm-s70 {
    padding-top: 4.375rem;
  }

  .-pt-sm-s70 {
    padding-top: -4.375rem;
  }

  .pb-sm-s70 {
    padding-bottom: 4.375rem;
  }

  .-pb-sm-s70 {
    padding-bottom: -4.375rem;
  }

  .pr-sm-s70 {
    padding-right: 4.375rem;
  }

  .-pr-sm-s70 {
    padding-right: -4.375rem;
  }

  .pl-sm-s70 {
    padding-left: 4.375rem;
  }

  .-pl-sm-s70 {
    padding-left: -4.375rem;
  }

  .mx-sm-s70 {
    margin-left: 4.375rem;
    margin-right: 4.375rem;
  }

  .my-sm-s70 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }

  .px-sm-s70 {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }

  .py-sm-s70 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }

  .gap-sm-s70 {
    gap: 4.375rem;
  }

  .pm-sm-s70, .gx-sm-s70 > .gx-child {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .gx-sm-s70 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 4.375rem;
  }

  .gx-sm-s70 > .gx-child:last-of-type {
    padding-left: 4.375rem;
    padding-right: 0;
  }

  .m-sm-s80 {
    margin: 5rem;
  }

  .-m-sm-s80 {
    margin: -5rem;
  }

  .mt-sm-s80 {
    margin-top: 5rem;
  }

  .-mt-sm-s80 {
    margin-top: -5rem;
  }

  .mb-sm-s80 {
    margin-bottom: 5rem;
  }

  .-mb-sm-s80 {
    margin-bottom: -5rem;
  }

  .mr-sm-s80 {
    margin-right: 5rem;
  }

  .-mr-sm-s80 {
    margin-right: -5rem;
  }

  .ml-sm-s80 {
    margin-left: 5rem;
  }

  .-ml-sm-s80 {
    margin-left: -5rem;
  }

  .p-sm-s80 {
    padding: 5rem;
  }

  .-p-sm-s80 {
    padding: -5rem;
  }

  .pt-sm-s80 {
    padding-top: 5rem;
  }

  .-pt-sm-s80 {
    padding-top: -5rem;
  }

  .pb-sm-s80 {
    padding-bottom: 5rem;
  }

  .-pb-sm-s80 {
    padding-bottom: -5rem;
  }

  .pr-sm-s80 {
    padding-right: 5rem;
  }

  .-pr-sm-s80 {
    padding-right: -5rem;
  }

  .pl-sm-s80 {
    padding-left: 5rem;
  }

  .-pl-sm-s80 {
    padding-left: -5rem;
  }

  .mx-sm-s80 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .my-sm-s80 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .px-sm-s80 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .py-sm-s80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .gap-sm-s80 {
    gap: 5rem;
  }

  .pm-sm-s80, .gx-sm-s80 > .gx-child {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .gx-sm-s80 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 5rem;
  }

  .gx-sm-s80 > .gx-child:last-of-type {
    padding-left: 5rem;
    padding-right: 0;
  }

  .m-sm-s100 {
    margin: 6.25rem;
  }

  .-m-sm-s100 {
    margin: -6.25rem;
  }

  .mt-sm-s100 {
    margin-top: 6.25rem;
  }

  .-mt-sm-s100 {
    margin-top: -6.25rem;
  }

  .mb-sm-s100 {
    margin-bottom: 6.25rem;
  }

  .-mb-sm-s100 {
    margin-bottom: -6.25rem;
  }

  .mr-sm-s100 {
    margin-right: 6.25rem;
  }

  .-mr-sm-s100 {
    margin-right: -6.25rem;
  }

  .ml-sm-s100 {
    margin-left: 6.25rem;
  }

  .-ml-sm-s100 {
    margin-left: -6.25rem;
  }

  .p-sm-s100 {
    padding: 6.25rem;
  }

  .-p-sm-s100 {
    padding: -6.25rem;
  }

  .pt-sm-s100 {
    padding-top: 6.25rem;
  }

  .-pt-sm-s100 {
    padding-top: -6.25rem;
  }

  .pb-sm-s100 {
    padding-bottom: 6.25rem;
  }

  .-pb-sm-s100 {
    padding-bottom: -6.25rem;
  }

  .pr-sm-s100 {
    padding-right: 6.25rem;
  }

  .-pr-sm-s100 {
    padding-right: -6.25rem;
  }

  .pl-sm-s100 {
    padding-left: 6.25rem;
  }

  .-pl-sm-s100 {
    padding-left: -6.25rem;
  }

  .mx-sm-s100 {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
  }

  .my-sm-s100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }

  .px-sm-s100 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }

  .py-sm-s100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  .gap-sm-s100 {
    gap: 6.25rem;
  }

  .pm-sm-s100, .gx-sm-s100 > .gx-child {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .gx-sm-s100 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 6.25rem;
  }

  .gx-sm-s100 > .gx-child:last-of-type {
    padding-left: 6.25rem;
    padding-right: 0;
  }

  .m-sm-s120 {
    margin: 7.5rem;
  }

  .-m-sm-s120 {
    margin: -7.5rem;
  }

  .mt-sm-s120 {
    margin-top: 7.5rem;
  }

  .-mt-sm-s120 {
    margin-top: -7.5rem;
  }

  .mb-sm-s120 {
    margin-bottom: 7.5rem;
  }

  .-mb-sm-s120 {
    margin-bottom: -7.5rem;
  }

  .mr-sm-s120 {
    margin-right: 7.5rem;
  }

  .-mr-sm-s120 {
    margin-right: -7.5rem;
  }

  .ml-sm-s120 {
    margin-left: 7.5rem;
  }

  .-ml-sm-s120 {
    margin-left: -7.5rem;
  }

  .p-sm-s120 {
    padding: 7.5rem;
  }

  .-p-sm-s120 {
    padding: -7.5rem;
  }

  .pt-sm-s120 {
    padding-top: 7.5rem;
  }

  .-pt-sm-s120 {
    padding-top: -7.5rem;
  }

  .pb-sm-s120 {
    padding-bottom: 7.5rem;
  }

  .-pb-sm-s120 {
    padding-bottom: -7.5rem;
  }

  .pr-sm-s120 {
    padding-right: 7.5rem;
  }

  .-pr-sm-s120 {
    padding-right: -7.5rem;
  }

  .pl-sm-s120 {
    padding-left: 7.5rem;
  }

  .-pl-sm-s120 {
    padding-left: -7.5rem;
  }

  .mx-sm-s120 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }

  .my-sm-s120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }

  .px-sm-s120 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }

  .py-sm-s120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }

  .gap-sm-s120 {
    gap: 7.5rem;
  }

  .pm-sm-s120, .gx-sm-s120 > .gx-child {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .gx-sm-s120 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 7.5rem;
  }

  .gx-sm-s120 > .gx-child:last-of-type {
    padding-left: 7.5rem;
    padding-right: 0;
  }

  .m-sm-s150 {
    margin: 9.375rem;
  }

  .-m-sm-s150 {
    margin: -9.375rem;
  }

  .mt-sm-s150 {
    margin-top: 9.375rem;
  }

  .-mt-sm-s150 {
    margin-top: -9.375rem;
  }

  .mb-sm-s150 {
    margin-bottom: 9.375rem;
  }

  .-mb-sm-s150 {
    margin-bottom: -9.375rem;
  }

  .mr-sm-s150 {
    margin-right: 9.375rem;
  }

  .-mr-sm-s150 {
    margin-right: -9.375rem;
  }

  .ml-sm-s150 {
    margin-left: 9.375rem;
  }

  .-ml-sm-s150 {
    margin-left: -9.375rem;
  }

  .p-sm-s150 {
    padding: 9.375rem;
  }

  .-p-sm-s150 {
    padding: -9.375rem;
  }

  .pt-sm-s150 {
    padding-top: 9.375rem;
  }

  .-pt-sm-s150 {
    padding-top: -9.375rem;
  }

  .pb-sm-s150 {
    padding-bottom: 9.375rem;
  }

  .-pb-sm-s150 {
    padding-bottom: -9.375rem;
  }

  .pr-sm-s150 {
    padding-right: 9.375rem;
  }

  .-pr-sm-s150 {
    padding-right: -9.375rem;
  }

  .pl-sm-s150 {
    padding-left: 9.375rem;
  }

  .-pl-sm-s150 {
    padding-left: -9.375rem;
  }

  .mx-sm-s150 {
    margin-left: 9.375rem;
    margin-right: 9.375rem;
  }

  .my-sm-s150 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }

  .px-sm-s150 {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
  }

  .py-sm-s150 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }

  .gap-sm-s150 {
    gap: 9.375rem;
  }

  .pm-sm-s150, .gx-sm-s150 > .gx-child {
    padding-left: 4.6875rem;
    padding-right: 4.6875rem;
  }

  .gx-sm-s150 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 9.375rem;
  }

  .gx-sm-s150 > .gx-child:last-of-type {
    padding-left: 9.375rem;
    padding-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .m-md-s0, .-m-md-s0 {
    margin: 0;
  }

  .mt-md-s0, .-mt-md-s0 {
    margin-top: 0;
  }

  .mb-md-s0, .-mb-md-s0 {
    margin-bottom: 0;
  }

  .mr-md-s0, .-mr-md-s0 {
    margin-right: 0;
  }

  .ml-md-s0, .-ml-md-s0 {
    margin-left: 0;
  }

  .p-md-s0, .-p-md-s0 {
    padding: 0;
  }

  .pt-md-s0, .-pt-md-s0 {
    padding-top: 0;
  }

  .pb-md-s0, .-pb-md-s0 {
    padding-bottom: 0;
  }

  .pr-md-s0, .-pr-md-s0 {
    padding-right: 0;
  }

  .pl-md-s0, .-pl-md-s0 {
    padding-left: 0;
  }

  .mx-md-s0 {
    margin-left: 0;
    margin-right: 0;
  }

  .my-md-s0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .px-md-s0 {
    padding-left: 0;
    padding-right: 0;
  }

  .py-md-s0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .gap-md-s0 {
    gap: 0;
  }

  .pm-md-s0, .gx-md-s0 > .gx-child, .gx-md-s0 > .gx-child:first-of-type, .gx-md-s0 > .gx-child:last-of-type {
    padding-left: 0;
    padding-right: 0;
  }

  .m-md-s2 {
    margin: .125rem;
  }

  .-m-md-s2 {
    margin: -.125rem;
  }

  .mt-md-s2 {
    margin-top: .125rem;
  }

  .-mt-md-s2 {
    margin-top: -.125rem;
  }

  .mb-md-s2 {
    margin-bottom: .125rem;
  }

  .-mb-md-s2 {
    margin-bottom: -.125rem;
  }

  .mr-md-s2 {
    margin-right: .125rem;
  }

  .-mr-md-s2 {
    margin-right: -.125rem;
  }

  .ml-md-s2 {
    margin-left: .125rem;
  }

  .-ml-md-s2 {
    margin-left: -.125rem;
  }

  .p-md-s2 {
    padding: .125rem;
  }

  .-p-md-s2 {
    padding: -.125rem;
  }

  .pt-md-s2 {
    padding-top: .125rem;
  }

  .-pt-md-s2 {
    padding-top: -.125rem;
  }

  .pb-md-s2 {
    padding-bottom: .125rem;
  }

  .-pb-md-s2 {
    padding-bottom: -.125rem;
  }

  .pr-md-s2 {
    padding-right: .125rem;
  }

  .-pr-md-s2 {
    padding-right: -.125rem;
  }

  .pl-md-s2 {
    padding-left: .125rem;
  }

  .-pl-md-s2 {
    padding-left: -.125rem;
  }

  .mx-md-s2 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .my-md-s2 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .px-md-s2 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .py-md-s2 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .gap-md-s2 {
    gap: .125rem;
  }

  .pm-md-s2, .gx-md-s2 > .gx-child {
    padding-left: .0625rem;
    padding-right: .0625rem;
  }

  .gx-md-s2 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .125rem;
  }

  .gx-md-s2 > .gx-child:last-of-type {
    padding-left: .125rem;
    padding-right: 0;
  }

  .m-md-s3 {
    margin: .1875rem;
  }

  .-m-md-s3 {
    margin: -.1875rem;
  }

  .mt-md-s3 {
    margin-top: .1875rem;
  }

  .-mt-md-s3 {
    margin-top: -.1875rem;
  }

  .mb-md-s3 {
    margin-bottom: .1875rem;
  }

  .-mb-md-s3 {
    margin-bottom: -.1875rem;
  }

  .mr-md-s3 {
    margin-right: .1875rem;
  }

  .-mr-md-s3 {
    margin-right: -.1875rem;
  }

  .ml-md-s3 {
    margin-left: .1875rem;
  }

  .-ml-md-s3 {
    margin-left: -.1875rem;
  }

  .p-md-s3 {
    padding: .1875rem;
  }

  .-p-md-s3 {
    padding: -.1875rem;
  }

  .pt-md-s3 {
    padding-top: .1875rem;
  }

  .-pt-md-s3 {
    padding-top: -.1875rem;
  }

  .pb-md-s3 {
    padding-bottom: .1875rem;
  }

  .-pb-md-s3 {
    padding-bottom: -.1875rem;
  }

  .pr-md-s3 {
    padding-right: .1875rem;
  }

  .-pr-md-s3 {
    padding-right: -.1875rem;
  }

  .pl-md-s3 {
    padding-left: .1875rem;
  }

  .-pl-md-s3 {
    padding-left: -.1875rem;
  }

  .mx-md-s3 {
    margin-left: .1875rem;
    margin-right: .1875rem;
  }

  .my-md-s3 {
    margin-top: .1875rem;
    margin-bottom: .1875rem;
  }

  .px-md-s3 {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .py-md-s3 {
    padding-top: .1875rem;
    padding-bottom: .1875rem;
  }

  .gap-md-s3 {
    gap: .1875rem;
  }

  .pm-md-s3, .gx-md-s3 > .gx-child {
    padding-left: .09375rem;
    padding-right: .09375rem;
  }

  .gx-md-s3 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .1875rem;
  }

  .gx-md-s3 > .gx-child:last-of-type {
    padding-left: .1875rem;
    padding-right: 0;
  }

  .m-md-s4 {
    margin: .25rem;
  }

  .-m-md-s4 {
    margin: -.25rem;
  }

  .mt-md-s4 {
    margin-top: .25rem;
  }

  .-mt-md-s4 {
    margin-top: -.25rem;
  }

  .mb-md-s4 {
    margin-bottom: .25rem;
  }

  .-mb-md-s4 {
    margin-bottom: -.25rem;
  }

  .mr-md-s4 {
    margin-right: .25rem;
  }

  .-mr-md-s4 {
    margin-right: -.25rem;
  }

  .ml-md-s4 {
    margin-left: .25rem;
  }

  .-ml-md-s4 {
    margin-left: -.25rem;
  }

  .p-md-s4 {
    padding: .25rem;
  }

  .-p-md-s4 {
    padding: -.25rem;
  }

  .pt-md-s4 {
    padding-top: .25rem;
  }

  .-pt-md-s4 {
    padding-top: -.25rem;
  }

  .pb-md-s4 {
    padding-bottom: .25rem;
  }

  .-pb-md-s4 {
    padding-bottom: -.25rem;
  }

  .pr-md-s4 {
    padding-right: .25rem;
  }

  .-pr-md-s4 {
    padding-right: -.25rem;
  }

  .pl-md-s4 {
    padding-left: .25rem;
  }

  .-pl-md-s4 {
    padding-left: -.25rem;
  }

  .mx-md-s4 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .my-md-s4 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .px-md-s4 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .py-md-s4 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .gap-md-s4 {
    gap: .25rem;
  }

  .pm-md-s4, .gx-md-s4 > .gx-child {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .gx-md-s4 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .25rem;
  }

  .gx-md-s4 > .gx-child:last-of-type {
    padding-left: .25rem;
    padding-right: 0;
  }

  .m-md-s5 {
    margin: .3125rem;
  }

  .-m-md-s5 {
    margin: -.3125rem;
  }

  .mt-md-s5 {
    margin-top: .3125rem;
  }

  .-mt-md-s5 {
    margin-top: -.3125rem;
  }

  .mb-md-s5 {
    margin-bottom: .3125rem;
  }

  .-mb-md-s5 {
    margin-bottom: -.3125rem;
  }

  .mr-md-s5 {
    margin-right: .3125rem;
  }

  .-mr-md-s5 {
    margin-right: -.3125rem;
  }

  .ml-md-s5 {
    margin-left: .3125rem;
  }

  .-ml-md-s5 {
    margin-left: -.3125rem;
  }

  .p-md-s5 {
    padding: .3125rem;
  }

  .-p-md-s5 {
    padding: -.3125rem;
  }

  .pt-md-s5 {
    padding-top: .3125rem;
  }

  .-pt-md-s5 {
    padding-top: -.3125rem;
  }

  .pb-md-s5 {
    padding-bottom: .3125rem;
  }

  .-pb-md-s5 {
    padding-bottom: -.3125rem;
  }

  .pr-md-s5 {
    padding-right: .3125rem;
  }

  .-pr-md-s5 {
    padding-right: -.3125rem;
  }

  .pl-md-s5 {
    padding-left: .3125rem;
  }

  .-pl-md-s5 {
    padding-left: -.3125rem;
  }

  .mx-md-s5 {
    margin-left: .3125rem;
    margin-right: .3125rem;
  }

  .my-md-s5 {
    margin-top: .3125rem;
    margin-bottom: .3125rem;
  }

  .px-md-s5 {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .py-md-s5 {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
  }

  .gap-md-s5 {
    gap: .3125rem;
  }

  .pm-md-s5, .gx-md-s5 > .gx-child {
    padding-left: .15625rem;
    padding-right: .15625rem;
  }

  .gx-md-s5 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .3125rem;
  }

  .gx-md-s5 > .gx-child:last-of-type {
    padding-left: .3125rem;
    padding-right: 0;
  }

  .m-md-s6 {
    margin: .375rem;
  }

  .-m-md-s6 {
    margin: -.375rem;
  }

  .mt-md-s6 {
    margin-top: .375rem;
  }

  .-mt-md-s6 {
    margin-top: -.375rem;
  }

  .mb-md-s6 {
    margin-bottom: .375rem;
  }

  .-mb-md-s6 {
    margin-bottom: -.375rem;
  }

  .mr-md-s6 {
    margin-right: .375rem;
  }

  .-mr-md-s6 {
    margin-right: -.375rem;
  }

  .ml-md-s6 {
    margin-left: .375rem;
  }

  .-ml-md-s6 {
    margin-left: -.375rem;
  }

  .p-md-s6 {
    padding: .375rem;
  }

  .-p-md-s6 {
    padding: -.375rem;
  }

  .pt-md-s6 {
    padding-top: .375rem;
  }

  .-pt-md-s6 {
    padding-top: -.375rem;
  }

  .pb-md-s6 {
    padding-bottom: .375rem;
  }

  .-pb-md-s6 {
    padding-bottom: -.375rem;
  }

  .pr-md-s6 {
    padding-right: .375rem;
  }

  .-pr-md-s6 {
    padding-right: -.375rem;
  }

  .pl-md-s6 {
    padding-left: .375rem;
  }

  .-pl-md-s6 {
    padding-left: -.375rem;
  }

  .mx-md-s6 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .my-md-s6 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .px-md-s6 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .py-md-s6 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .gap-md-s6 {
    gap: .375rem;
  }

  .pm-md-s6, .gx-md-s6 > .gx-child {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .gx-md-s6 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .375rem;
  }

  .gx-md-s6 > .gx-child:last-of-type {
    padding-left: .375rem;
    padding-right: 0;
  }

  .m-md-s7 {
    margin: .4375rem;
  }

  .-m-md-s7 {
    margin: -.4375rem;
  }

  .mt-md-s7 {
    margin-top: .4375rem;
  }

  .-mt-md-s7 {
    margin-top: -.4375rem;
  }

  .mb-md-s7 {
    margin-bottom: .4375rem;
  }

  .-mb-md-s7 {
    margin-bottom: -.4375rem;
  }

  .mr-md-s7 {
    margin-right: .4375rem;
  }

  .-mr-md-s7 {
    margin-right: -.4375rem;
  }

  .ml-md-s7 {
    margin-left: .4375rem;
  }

  .-ml-md-s7 {
    margin-left: -.4375rem;
  }

  .p-md-s7 {
    padding: .4375rem;
  }

  .-p-md-s7 {
    padding: -.4375rem;
  }

  .pt-md-s7 {
    padding-top: .4375rem;
  }

  .-pt-md-s7 {
    padding-top: -.4375rem;
  }

  .pb-md-s7 {
    padding-bottom: .4375rem;
  }

  .-pb-md-s7 {
    padding-bottom: -.4375rem;
  }

  .pr-md-s7 {
    padding-right: .4375rem;
  }

  .-pr-md-s7 {
    padding-right: -.4375rem;
  }

  .pl-md-s7 {
    padding-left: .4375rem;
  }

  .-pl-md-s7 {
    padding-left: -.4375rem;
  }

  .mx-md-s7 {
    margin-left: .4375rem;
    margin-right: .4375rem;
  }

  .my-md-s7 {
    margin-top: .4375rem;
    margin-bottom: .4375rem;
  }

  .px-md-s7 {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .py-md-s7 {
    padding-top: .4375rem;
    padding-bottom: .4375rem;
  }

  .gap-md-s7 {
    gap: .4375rem;
  }

  .pm-md-s7, .gx-md-s7 > .gx-child {
    padding-left: .21875rem;
    padding-right: .21875rem;
  }

  .gx-md-s7 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .4375rem;
  }

  .gx-md-s7 > .gx-child:last-of-type {
    padding-left: .4375rem;
    padding-right: 0;
  }

  .m-md-s8 {
    margin: .5rem;
  }

  .-m-md-s8 {
    margin: -.5rem;
  }

  .mt-md-s8 {
    margin-top: .5rem;
  }

  .-mt-md-s8 {
    margin-top: -.5rem;
  }

  .mb-md-s8 {
    margin-bottom: .5rem;
  }

  .-mb-md-s8 {
    margin-bottom: -.5rem;
  }

  .mr-md-s8 {
    margin-right: .5rem;
  }

  .-mr-md-s8 {
    margin-right: -.5rem;
  }

  .ml-md-s8 {
    margin-left: .5rem;
  }

  .-ml-md-s8 {
    margin-left: -.5rem;
  }

  .p-md-s8 {
    padding: .5rem;
  }

  .-p-md-s8 {
    padding: -.5rem;
  }

  .pt-md-s8 {
    padding-top: .5rem;
  }

  .-pt-md-s8 {
    padding-top: -.5rem;
  }

  .pb-md-s8 {
    padding-bottom: .5rem;
  }

  .-pb-md-s8 {
    padding-bottom: -.5rem;
  }

  .pr-md-s8 {
    padding-right: .5rem;
  }

  .-pr-md-s8 {
    padding-right: -.5rem;
  }

  .pl-md-s8 {
    padding-left: .5rem;
  }

  .-pl-md-s8 {
    padding-left: -.5rem;
  }

  .mx-md-s8 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .my-md-s8 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .px-md-s8 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .py-md-s8 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .gap-md-s8 {
    gap: .5rem;
  }

  .pm-md-s8, .gx-md-s8 > .gx-child {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .gx-md-s8 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5rem;
  }

  .gx-md-s8 > .gx-child:last-of-type {
    padding-left: .5rem;
    padding-right: 0;
  }

  .m-md-s9 {
    margin: .5625rem;
  }

  .-m-md-s9 {
    margin: -.5625rem;
  }

  .mt-md-s9 {
    margin-top: .5625rem;
  }

  .-mt-md-s9 {
    margin-top: -.5625rem;
  }

  .mb-md-s9 {
    margin-bottom: .5625rem;
  }

  .-mb-md-s9 {
    margin-bottom: -.5625rem;
  }

  .mr-md-s9 {
    margin-right: .5625rem;
  }

  .-mr-md-s9 {
    margin-right: -.5625rem;
  }

  .ml-md-s9 {
    margin-left: .5625rem;
  }

  .-ml-md-s9 {
    margin-left: -.5625rem;
  }

  .p-md-s9 {
    padding: .5625rem;
  }

  .-p-md-s9 {
    padding: -.5625rem;
  }

  .pt-md-s9 {
    padding-top: .5625rem;
  }

  .-pt-md-s9 {
    padding-top: -.5625rem;
  }

  .pb-md-s9 {
    padding-bottom: .5625rem;
  }

  .-pb-md-s9 {
    padding-bottom: -.5625rem;
  }

  .pr-md-s9 {
    padding-right: .5625rem;
  }

  .-pr-md-s9 {
    padding-right: -.5625rem;
  }

  .pl-md-s9 {
    padding-left: .5625rem;
  }

  .-pl-md-s9 {
    padding-left: -.5625rem;
  }

  .mx-md-s9 {
    margin-left: .5625rem;
    margin-right: .5625rem;
  }

  .my-md-s9 {
    margin-top: .5625rem;
    margin-bottom: .5625rem;
  }

  .px-md-s9 {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .py-md-s9 {
    padding-top: .5625rem;
    padding-bottom: .5625rem;
  }

  .gap-md-s9 {
    gap: .5625rem;
  }

  .pm-md-s9, .gx-md-s9 > .gx-child {
    padding-left: .28125rem;
    padding-right: .28125rem;
  }

  .gx-md-s9 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5625rem;
  }

  .gx-md-s9 > .gx-child:last-of-type {
    padding-left: .5625rem;
    padding-right: 0;
  }

  .m-md-s10 {
    margin: .625rem;
  }

  .-m-md-s10 {
    margin: -.625rem;
  }

  .mt-md-s10 {
    margin-top: .625rem;
  }

  .-mt-md-s10 {
    margin-top: -.625rem;
  }

  .mb-md-s10 {
    margin-bottom: .625rem;
  }

  .-mb-md-s10 {
    margin-bottom: -.625rem;
  }

  .mr-md-s10 {
    margin-right: .625rem;
  }

  .-mr-md-s10 {
    margin-right: -.625rem;
  }

  .ml-md-s10 {
    margin-left: .625rem;
  }

  .-ml-md-s10 {
    margin-left: -.625rem;
  }

  .p-md-s10 {
    padding: .625rem;
  }

  .-p-md-s10 {
    padding: -.625rem;
  }

  .pt-md-s10 {
    padding-top: .625rem;
  }

  .-pt-md-s10 {
    padding-top: -.625rem;
  }

  .pb-md-s10 {
    padding-bottom: .625rem;
  }

  .-pb-md-s10 {
    padding-bottom: -.625rem;
  }

  .pr-md-s10 {
    padding-right: .625rem;
  }

  .-pr-md-s10 {
    padding-right: -.625rem;
  }

  .pl-md-s10 {
    padding-left: .625rem;
  }

  .-pl-md-s10 {
    padding-left: -.625rem;
  }

  .mx-md-s10 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .my-md-s10 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .px-md-s10 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .py-md-s10 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .gap-md-s10 {
    gap: .625rem;
  }

  .pm-md-s10, .gx-md-s10 > .gx-child {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .gx-md-s10 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .625rem;
  }

  .gx-md-s10 > .gx-child:last-of-type {
    padding-left: .625rem;
    padding-right: 0;
  }

  .m-md-s12 {
    margin: .75rem;
  }

  .-m-md-s12 {
    margin: -.75rem;
  }

  .mt-md-s12 {
    margin-top: .75rem;
  }

  .-mt-md-s12 {
    margin-top: -.75rem;
  }

  .mb-md-s12 {
    margin-bottom: .75rem;
  }

  .-mb-md-s12 {
    margin-bottom: -.75rem;
  }

  .mr-md-s12 {
    margin-right: .75rem;
  }

  .-mr-md-s12 {
    margin-right: -.75rem;
  }

  .ml-md-s12 {
    margin-left: .75rem;
  }

  .-ml-md-s12 {
    margin-left: -.75rem;
  }

  .p-md-s12 {
    padding: .75rem;
  }

  .-p-md-s12 {
    padding: -.75rem;
  }

  .pt-md-s12 {
    padding-top: .75rem;
  }

  .-pt-md-s12 {
    padding-top: -.75rem;
  }

  .pb-md-s12 {
    padding-bottom: .75rem;
  }

  .-pb-md-s12 {
    padding-bottom: -.75rem;
  }

  .pr-md-s12 {
    padding-right: .75rem;
  }

  .-pr-md-s12 {
    padding-right: -.75rem;
  }

  .pl-md-s12 {
    padding-left: .75rem;
  }

  .-pl-md-s12 {
    padding-left: -.75rem;
  }

  .mx-md-s12 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .my-md-s12 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .px-md-s12 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .py-md-s12 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .gap-md-s12 {
    gap: .75rem;
  }

  .pm-md-s12, .gx-md-s12 > .gx-child {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .gx-md-s12 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .75rem;
  }

  .gx-md-s12 > .gx-child:last-of-type {
    padding-left: .75rem;
    padding-right: 0;
  }

  .m-md-s13 {
    margin: .8125rem;
  }

  .-m-md-s13 {
    margin: -.8125rem;
  }

  .mt-md-s13 {
    margin-top: .8125rem;
  }

  .-mt-md-s13 {
    margin-top: -.8125rem;
  }

  .mb-md-s13 {
    margin-bottom: .8125rem;
  }

  .-mb-md-s13 {
    margin-bottom: -.8125rem;
  }

  .mr-md-s13 {
    margin-right: .8125rem;
  }

  .-mr-md-s13 {
    margin-right: -.8125rem;
  }

  .ml-md-s13 {
    margin-left: .8125rem;
  }

  .-ml-md-s13 {
    margin-left: -.8125rem;
  }

  .p-md-s13 {
    padding: .8125rem;
  }

  .-p-md-s13 {
    padding: -.8125rem;
  }

  .pt-md-s13 {
    padding-top: .8125rem;
  }

  .-pt-md-s13 {
    padding-top: -.8125rem;
  }

  .pb-md-s13 {
    padding-bottom: .8125rem;
  }

  .-pb-md-s13 {
    padding-bottom: -.8125rem;
  }

  .pr-md-s13 {
    padding-right: .8125rem;
  }

  .-pr-md-s13 {
    padding-right: -.8125rem;
  }

  .pl-md-s13 {
    padding-left: .8125rem;
  }

  .-pl-md-s13 {
    padding-left: -.8125rem;
  }

  .mx-md-s13 {
    margin-left: .8125rem;
    margin-right: .8125rem;
  }

  .my-md-s13 {
    margin-top: .8125rem;
    margin-bottom: .8125rem;
  }

  .px-md-s13 {
    padding-left: .8125rem;
    padding-right: .8125rem;
  }

  .py-md-s13 {
    padding-top: .8125rem;
    padding-bottom: .8125rem;
  }

  .gap-md-s13 {
    gap: .8125rem;
  }

  .pm-md-s13, .gx-md-s13 > .gx-child {
    padding-left: .40625rem;
    padding-right: .40625rem;
  }

  .gx-md-s13 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .8125rem;
  }

  .gx-md-s13 > .gx-child:last-of-type {
    padding-left: .8125rem;
    padding-right: 0;
  }

  .m-md-s14 {
    margin: .875rem;
  }

  .-m-md-s14 {
    margin: -.875rem;
  }

  .mt-md-s14 {
    margin-top: .875rem;
  }

  .-mt-md-s14 {
    margin-top: -.875rem;
  }

  .mb-md-s14 {
    margin-bottom: .875rem;
  }

  .-mb-md-s14 {
    margin-bottom: -.875rem;
  }

  .mr-md-s14 {
    margin-right: .875rem;
  }

  .-mr-md-s14 {
    margin-right: -.875rem;
  }

  .ml-md-s14 {
    margin-left: .875rem;
  }

  .-ml-md-s14 {
    margin-left: -.875rem;
  }

  .p-md-s14 {
    padding: .875rem;
  }

  .-p-md-s14 {
    padding: -.875rem;
  }

  .pt-md-s14 {
    padding-top: .875rem;
  }

  .-pt-md-s14 {
    padding-top: -.875rem;
  }

  .pb-md-s14 {
    padding-bottom: .875rem;
  }

  .-pb-md-s14 {
    padding-bottom: -.875rem;
  }

  .pr-md-s14 {
    padding-right: .875rem;
  }

  .-pr-md-s14 {
    padding-right: -.875rem;
  }

  .pl-md-s14 {
    padding-left: .875rem;
  }

  .-pl-md-s14 {
    padding-left: -.875rem;
  }

  .mx-md-s14 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .my-md-s14 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .px-md-s14 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .py-md-s14 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .gap-md-s14 {
    gap: .875rem;
  }

  .pm-md-s14, .gx-md-s14 > .gx-child {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .gx-md-s14 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .875rem;
  }

  .gx-md-s14 > .gx-child:last-of-type {
    padding-left: .875rem;
    padding-right: 0;
  }

  .m-md-s15 {
    margin: .9375rem;
  }

  .-m-md-s15 {
    margin: -.9375rem;
  }

  .mt-md-s15 {
    margin-top: .9375rem;
  }

  .-mt-md-s15 {
    margin-top: -.9375rem;
  }

  .mb-md-s15 {
    margin-bottom: .9375rem;
  }

  .-mb-md-s15 {
    margin-bottom: -.9375rem;
  }

  .mr-md-s15 {
    margin-right: .9375rem;
  }

  .-mr-md-s15 {
    margin-right: -.9375rem;
  }

  .ml-md-s15 {
    margin-left: .9375rem;
  }

  .-ml-md-s15 {
    margin-left: -.9375rem;
  }

  .p-md-s15 {
    padding: .9375rem;
  }

  .-p-md-s15 {
    padding: -.9375rem;
  }

  .pt-md-s15 {
    padding-top: .9375rem;
  }

  .-pt-md-s15 {
    padding-top: -.9375rem;
  }

  .pb-md-s15 {
    padding-bottom: .9375rem;
  }

  .-pb-md-s15 {
    padding-bottom: -.9375rem;
  }

  .pr-md-s15 {
    padding-right: .9375rem;
  }

  .-pr-md-s15 {
    padding-right: -.9375rem;
  }

  .pl-md-s15 {
    padding-left: .9375rem;
  }

  .-pl-md-s15 {
    padding-left: -.9375rem;
  }

  .mx-md-s15 {
    margin-left: .9375rem;
    margin-right: .9375rem;
  }

  .my-md-s15 {
    margin-top: .9375rem;
    margin-bottom: .9375rem;
  }

  .px-md-s15 {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .py-md-s15 {
    padding-top: .9375rem;
    padding-bottom: .9375rem;
  }

  .gap-md-s15 {
    gap: .9375rem;
  }

  .pm-md-s15, .gx-md-s15 > .gx-child {
    padding-left: .46875rem;
    padding-right: .46875rem;
  }

  .gx-md-s15 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .9375rem;
  }

  .gx-md-s15 > .gx-child:last-of-type {
    padding-left: .9375rem;
    padding-right: 0;
  }

  .m-md-s16 {
    margin: 1rem;
  }

  .-m-md-s16 {
    margin: -1rem;
  }

  .mt-md-s16 {
    margin-top: 1rem;
  }

  .-mt-md-s16 {
    margin-top: -1rem;
  }

  .mb-md-s16 {
    margin-bottom: 1rem;
  }

  .-mb-md-s16 {
    margin-bottom: -1rem;
  }

  .mr-md-s16 {
    margin-right: 1rem;
  }

  .-mr-md-s16 {
    margin-right: -1rem;
  }

  .ml-md-s16 {
    margin-left: 1rem;
  }

  .-ml-md-s16 {
    margin-left: -1rem;
  }

  .p-md-s16 {
    padding: 1rem;
  }

  .-p-md-s16 {
    padding: -1rem;
  }

  .pt-md-s16 {
    padding-top: 1rem;
  }

  .-pt-md-s16 {
    padding-top: -1rem;
  }

  .pb-md-s16 {
    padding-bottom: 1rem;
  }

  .-pb-md-s16 {
    padding-bottom: -1rem;
  }

  .pr-md-s16 {
    padding-right: 1rem;
  }

  .-pr-md-s16 {
    padding-right: -1rem;
  }

  .pl-md-s16 {
    padding-left: 1rem;
  }

  .-pl-md-s16 {
    padding-left: -1rem;
  }

  .mx-md-s16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .my-md-s16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .px-md-s16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .py-md-s16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .gap-md-s16 {
    gap: 1rem;
  }

  .pm-md-s16, .gx-md-s16 > .gx-child {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .gx-md-s16 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1rem;
  }

  .gx-md-s16 > .gx-child:last-of-type {
    padding-left: 1rem;
    padding-right: 0;
  }

  .m-md-s18 {
    margin: 1.125rem;
  }

  .-m-md-s18 {
    margin: -1.125rem;
  }

  .mt-md-s18 {
    margin-top: 1.125rem;
  }

  .-mt-md-s18 {
    margin-top: -1.125rem;
  }

  .mb-md-s18 {
    margin-bottom: 1.125rem;
  }

  .-mb-md-s18 {
    margin-bottom: -1.125rem;
  }

  .mr-md-s18 {
    margin-right: 1.125rem;
  }

  .-mr-md-s18 {
    margin-right: -1.125rem;
  }

  .ml-md-s18 {
    margin-left: 1.125rem;
  }

  .-ml-md-s18 {
    margin-left: -1.125rem;
  }

  .p-md-s18 {
    padding: 1.125rem;
  }

  .-p-md-s18 {
    padding: -1.125rem;
  }

  .pt-md-s18 {
    padding-top: 1.125rem;
  }

  .-pt-md-s18 {
    padding-top: -1.125rem;
  }

  .pb-md-s18 {
    padding-bottom: 1.125rem;
  }

  .-pb-md-s18 {
    padding-bottom: -1.125rem;
  }

  .pr-md-s18 {
    padding-right: 1.125rem;
  }

  .-pr-md-s18 {
    padding-right: -1.125rem;
  }

  .pl-md-s18 {
    padding-left: 1.125rem;
  }

  .-pl-md-s18 {
    padding-left: -1.125rem;
  }

  .mx-md-s18 {
    margin-left: 1.125rem;
    margin-right: 1.125rem;
  }

  .my-md-s18 {
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
  }

  .px-md-s18 {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .py-md-s18 {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }

  .gap-md-s18 {
    gap: 1.125rem;
  }

  .pm-md-s18, .gx-md-s18 > .gx-child {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .gx-md-s18 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.125rem;
  }

  .gx-md-s18 > .gx-child:last-of-type {
    padding-left: 1.125rem;
    padding-right: 0;
  }

  .m-md-s20 {
    margin: 1.25rem;
  }

  .-m-md-s20 {
    margin: -1.25rem;
  }

  .mt-md-s20 {
    margin-top: 1.25rem;
  }

  .-mt-md-s20 {
    margin-top: -1.25rem;
  }

  .mb-md-s20 {
    margin-bottom: 1.25rem;
  }

  .-mb-md-s20 {
    margin-bottom: -1.25rem;
  }

  .mr-md-s20 {
    margin-right: 1.25rem;
  }

  .-mr-md-s20 {
    margin-right: -1.25rem;
  }

  .ml-md-s20 {
    margin-left: 1.25rem;
  }

  .-ml-md-s20 {
    margin-left: -1.25rem;
  }

  .p-md-s20 {
    padding: 1.25rem;
  }

  .-p-md-s20 {
    padding: -1.25rem;
  }

  .pt-md-s20 {
    padding-top: 1.25rem;
  }

  .-pt-md-s20 {
    padding-top: -1.25rem;
  }

  .pb-md-s20 {
    padding-bottom: 1.25rem;
  }

  .-pb-md-s20 {
    padding-bottom: -1.25rem;
  }

  .pr-md-s20 {
    padding-right: 1.25rem;
  }

  .-pr-md-s20 {
    padding-right: -1.25rem;
  }

  .pl-md-s20 {
    padding-left: 1.25rem;
  }

  .-pl-md-s20 {
    padding-left: -1.25rem;
  }

  .mx-md-s20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .my-md-s20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .px-md-s20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .py-md-s20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .gap-md-s20 {
    gap: 1.25rem;
  }

  .pm-md-s20, .gx-md-s20 > .gx-child {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .gx-md-s20 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.25rem;
  }

  .gx-md-s20 > .gx-child:last-of-type {
    padding-left: 1.25rem;
    padding-right: 0;
  }

  .m-md-s22 {
    margin: 1.375rem;
  }

  .-m-md-s22 {
    margin: -1.375rem;
  }

  .mt-md-s22 {
    margin-top: 1.375rem;
  }

  .-mt-md-s22 {
    margin-top: -1.375rem;
  }

  .mb-md-s22 {
    margin-bottom: 1.375rem;
  }

  .-mb-md-s22 {
    margin-bottom: -1.375rem;
  }

  .mr-md-s22 {
    margin-right: 1.375rem;
  }

  .-mr-md-s22 {
    margin-right: -1.375rem;
  }

  .ml-md-s22 {
    margin-left: 1.375rem;
  }

  .-ml-md-s22 {
    margin-left: -1.375rem;
  }

  .p-md-s22 {
    padding: 1.375rem;
  }

  .-p-md-s22 {
    padding: -1.375rem;
  }

  .pt-md-s22 {
    padding-top: 1.375rem;
  }

  .-pt-md-s22 {
    padding-top: -1.375rem;
  }

  .pb-md-s22 {
    padding-bottom: 1.375rem;
  }

  .-pb-md-s22 {
    padding-bottom: -1.375rem;
  }

  .pr-md-s22 {
    padding-right: 1.375rem;
  }

  .-pr-md-s22 {
    padding-right: -1.375rem;
  }

  .pl-md-s22 {
    padding-left: 1.375rem;
  }

  .-pl-md-s22 {
    padding-left: -1.375rem;
  }

  .mx-md-s22 {
    margin-left: 1.375rem;
    margin-right: 1.375rem;
  }

  .my-md-s22 {
    margin-top: 1.375rem;
    margin-bottom: 1.375rem;
  }

  .px-md-s22 {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }

  .py-md-s22 {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
  }

  .gap-md-s22 {
    gap: 1.375rem;
  }

  .pm-md-s22, .gx-md-s22 > .gx-child {
    padding-left: .6875rem;
    padding-right: .6875rem;
  }

  .gx-md-s22 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.375rem;
  }

  .gx-md-s22 > .gx-child:last-of-type {
    padding-left: 1.375rem;
    padding-right: 0;
  }

  .m-md-s24 {
    margin: 1.5rem;
  }

  .-m-md-s24 {
    margin: -1.5rem;
  }

  .mt-md-s24 {
    margin-top: 1.5rem;
  }

  .-mt-md-s24 {
    margin-top: -1.5rem;
  }

  .mb-md-s24 {
    margin-bottom: 1.5rem;
  }

  .-mb-md-s24 {
    margin-bottom: -1.5rem;
  }

  .mr-md-s24 {
    margin-right: 1.5rem;
  }

  .-mr-md-s24 {
    margin-right: -1.5rem;
  }

  .ml-md-s24 {
    margin-left: 1.5rem;
  }

  .-ml-md-s24 {
    margin-left: -1.5rem;
  }

  .p-md-s24 {
    padding: 1.5rem;
  }

  .-p-md-s24 {
    padding: -1.5rem;
  }

  .pt-md-s24 {
    padding-top: 1.5rem;
  }

  .-pt-md-s24 {
    padding-top: -1.5rem;
  }

  .pb-md-s24 {
    padding-bottom: 1.5rem;
  }

  .-pb-md-s24 {
    padding-bottom: -1.5rem;
  }

  .pr-md-s24 {
    padding-right: 1.5rem;
  }

  .-pr-md-s24 {
    padding-right: -1.5rem;
  }

  .pl-md-s24 {
    padding-left: 1.5rem;
  }

  .-pl-md-s24 {
    padding-left: -1.5rem;
  }

  .mx-md-s24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .my-md-s24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .px-md-s24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .py-md-s24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .gap-md-s24 {
    gap: 1.5rem;
  }

  .pm-md-s24, .gx-md-s24 > .gx-child {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .gx-md-s24 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5rem;
  }

  .gx-md-s24 > .gx-child:last-of-type {
    padding-left: 1.5rem;
    padding-right: 0;
  }

  .m-md-s25 {
    margin: 1.5625rem;
  }

  .-m-md-s25 {
    margin: -1.5625rem;
  }

  .mt-md-s25 {
    margin-top: 1.5625rem;
  }

  .-mt-md-s25 {
    margin-top: -1.5625rem;
  }

  .mb-md-s25 {
    margin-bottom: 1.5625rem;
  }

  .-mb-md-s25 {
    margin-bottom: -1.5625rem;
  }

  .mr-md-s25 {
    margin-right: 1.5625rem;
  }

  .-mr-md-s25 {
    margin-right: -1.5625rem;
  }

  .ml-md-s25 {
    margin-left: 1.5625rem;
  }

  .-ml-md-s25 {
    margin-left: -1.5625rem;
  }

  .p-md-s25 {
    padding: 1.5625rem;
  }

  .-p-md-s25 {
    padding: -1.5625rem;
  }

  .pt-md-s25 {
    padding-top: 1.5625rem;
  }

  .-pt-md-s25 {
    padding-top: -1.5625rem;
  }

  .pb-md-s25 {
    padding-bottom: 1.5625rem;
  }

  .-pb-md-s25 {
    padding-bottom: -1.5625rem;
  }

  .pr-md-s25 {
    padding-right: 1.5625rem;
  }

  .-pr-md-s25 {
    padding-right: -1.5625rem;
  }

  .pl-md-s25 {
    padding-left: 1.5625rem;
  }

  .-pl-md-s25 {
    padding-left: -1.5625rem;
  }

  .mx-md-s25 {
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
  }

  .my-md-s25 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }

  .px-md-s25 {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .py-md-s25 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }

  .gap-md-s25 {
    gap: 1.5625rem;
  }

  .pm-md-s25, .gx-md-s25 > .gx-child {
    padding-left: .78125rem;
    padding-right: .78125rem;
  }

  .gx-md-s25 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5625rem;
  }

  .gx-md-s25 > .gx-child:last-of-type {
    padding-left: 1.5625rem;
    padding-right: 0;
  }

  .m-md-s27 {
    margin: 1.6875rem;
  }

  .-m-md-s27 {
    margin: -1.6875rem;
  }

  .mt-md-s27 {
    margin-top: 1.6875rem;
  }

  .-mt-md-s27 {
    margin-top: -1.6875rem;
  }

  .mb-md-s27 {
    margin-bottom: 1.6875rem;
  }

  .-mb-md-s27 {
    margin-bottom: -1.6875rem;
  }

  .mr-md-s27 {
    margin-right: 1.6875rem;
  }

  .-mr-md-s27 {
    margin-right: -1.6875rem;
  }

  .ml-md-s27 {
    margin-left: 1.6875rem;
  }

  .-ml-md-s27 {
    margin-left: -1.6875rem;
  }

  .p-md-s27 {
    padding: 1.6875rem;
  }

  .-p-md-s27 {
    padding: -1.6875rem;
  }

  .pt-md-s27 {
    padding-top: 1.6875rem;
  }

  .-pt-md-s27 {
    padding-top: -1.6875rem;
  }

  .pb-md-s27 {
    padding-bottom: 1.6875rem;
  }

  .-pb-md-s27 {
    padding-bottom: -1.6875rem;
  }

  .pr-md-s27 {
    padding-right: 1.6875rem;
  }

  .-pr-md-s27 {
    padding-right: -1.6875rem;
  }

  .pl-md-s27 {
    padding-left: 1.6875rem;
  }

  .-pl-md-s27 {
    padding-left: -1.6875rem;
  }

  .mx-md-s27 {
    margin-left: 1.6875rem;
    margin-right: 1.6875rem;
  }

  .my-md-s27 {
    margin-top: 1.6875rem;
    margin-bottom: 1.6875rem;
  }

  .px-md-s27 {
    padding-left: 1.6875rem;
    padding-right: 1.6875rem;
  }

  .py-md-s27 {
    padding-top: 1.6875rem;
    padding-bottom: 1.6875rem;
  }

  .gap-md-s27 {
    gap: 1.6875rem;
  }

  .pm-md-s27, .gx-md-s27 > .gx-child {
    padding-left: .84375rem;
    padding-right: .84375rem;
  }

  .gx-md-s27 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.6875rem;
  }

  .gx-md-s27 > .gx-child:last-of-type {
    padding-left: 1.6875rem;
    padding-right: 0;
  }

  .m-md-s29 {
    margin: 1.8125rem;
  }

  .-m-md-s29 {
    margin: -1.8125rem;
  }

  .mt-md-s29 {
    margin-top: 1.8125rem;
  }

  .-mt-md-s29 {
    margin-top: -1.8125rem;
  }

  .mb-md-s29 {
    margin-bottom: 1.8125rem;
  }

  .-mb-md-s29 {
    margin-bottom: -1.8125rem;
  }

  .mr-md-s29 {
    margin-right: 1.8125rem;
  }

  .-mr-md-s29 {
    margin-right: -1.8125rem;
  }

  .ml-md-s29 {
    margin-left: 1.8125rem;
  }

  .-ml-md-s29 {
    margin-left: -1.8125rem;
  }

  .p-md-s29 {
    padding: 1.8125rem;
  }

  .-p-md-s29 {
    padding: -1.8125rem;
  }

  .pt-md-s29 {
    padding-top: 1.8125rem;
  }

  .-pt-md-s29 {
    padding-top: -1.8125rem;
  }

  .pb-md-s29 {
    padding-bottom: 1.8125rem;
  }

  .-pb-md-s29 {
    padding-bottom: -1.8125rem;
  }

  .pr-md-s29 {
    padding-right: 1.8125rem;
  }

  .-pr-md-s29 {
    padding-right: -1.8125rem;
  }

  .pl-md-s29 {
    padding-left: 1.8125rem;
  }

  .-pl-md-s29 {
    padding-left: -1.8125rem;
  }

  .mx-md-s29 {
    margin-left: 1.8125rem;
    margin-right: 1.8125rem;
  }

  .my-md-s29 {
    margin-top: 1.8125rem;
    margin-bottom: 1.8125rem;
  }

  .px-md-s29 {
    padding-left: 1.8125rem;
    padding-right: 1.8125rem;
  }

  .py-md-s29 {
    padding-top: 1.8125rem;
    padding-bottom: 1.8125rem;
  }

  .gap-md-s29 {
    gap: 1.8125rem;
  }

  .pm-md-s29, .gx-md-s29 > .gx-child {
    padding-left: .90625rem;
    padding-right: .90625rem;
  }

  .gx-md-s29 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.8125rem;
  }

  .gx-md-s29 > .gx-child:last-of-type {
    padding-left: 1.8125rem;
    padding-right: 0;
  }

  .m-md-s30 {
    margin: 1.875rem;
  }

  .-m-md-s30 {
    margin: -1.875rem;
  }

  .mt-md-s30 {
    margin-top: 1.875rem;
  }

  .-mt-md-s30 {
    margin-top: -1.875rem;
  }

  .mb-md-s30 {
    margin-bottom: 1.875rem;
  }

  .-mb-md-s30 {
    margin-bottom: -1.875rem;
  }

  .mr-md-s30 {
    margin-right: 1.875rem;
  }

  .-mr-md-s30 {
    margin-right: -1.875rem;
  }

  .ml-md-s30 {
    margin-left: 1.875rem;
  }

  .-ml-md-s30 {
    margin-left: -1.875rem;
  }

  .p-md-s30 {
    padding: 1.875rem;
  }

  .-p-md-s30 {
    padding: -1.875rem;
  }

  .pt-md-s30 {
    padding-top: 1.875rem;
  }

  .-pt-md-s30 {
    padding-top: -1.875rem;
  }

  .pb-md-s30 {
    padding-bottom: 1.875rem;
  }

  .-pb-md-s30 {
    padding-bottom: -1.875rem;
  }

  .pr-md-s30 {
    padding-right: 1.875rem;
  }

  .-pr-md-s30 {
    padding-right: -1.875rem;
  }

  .pl-md-s30 {
    padding-left: 1.875rem;
  }

  .-pl-md-s30 {
    padding-left: -1.875rem;
  }

  .mx-md-s30 {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }

  .my-md-s30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .px-md-s30 {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .py-md-s30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .gap-md-s30 {
    gap: 1.875rem;
  }

  .pm-md-s30, .gx-md-s30 > .gx-child {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .gx-md-s30 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.875rem;
  }

  .gx-md-s30 > .gx-child:last-of-type {
    padding-left: 1.875rem;
    padding-right: 0;
  }

  .m-md-s32 {
    margin: 2rem;
  }

  .-m-md-s32 {
    margin: -2rem;
  }

  .mt-md-s32 {
    margin-top: 2rem;
  }

  .-mt-md-s32 {
    margin-top: -2rem;
  }

  .mb-md-s32 {
    margin-bottom: 2rem;
  }

  .-mb-md-s32 {
    margin-bottom: -2rem;
  }

  .mr-md-s32 {
    margin-right: 2rem;
  }

  .-mr-md-s32 {
    margin-right: -2rem;
  }

  .ml-md-s32 {
    margin-left: 2rem;
  }

  .-ml-md-s32 {
    margin-left: -2rem;
  }

  .p-md-s32 {
    padding: 2rem;
  }

  .-p-md-s32 {
    padding: -2rem;
  }

  .pt-md-s32 {
    padding-top: 2rem;
  }

  .-pt-md-s32 {
    padding-top: -2rem;
  }

  .pb-md-s32 {
    padding-bottom: 2rem;
  }

  .-pb-md-s32 {
    padding-bottom: -2rem;
  }

  .pr-md-s32 {
    padding-right: 2rem;
  }

  .-pr-md-s32 {
    padding-right: -2rem;
  }

  .pl-md-s32 {
    padding-left: 2rem;
  }

  .-pl-md-s32 {
    padding-left: -2rem;
  }

  .mx-md-s32 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .my-md-s32 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .px-md-s32 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .py-md-s32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .gap-md-s32 {
    gap: 2rem;
  }

  .pm-md-s32, .gx-md-s32 > .gx-child {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .gx-md-s32 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2rem;
  }

  .gx-md-s32 > .gx-child:last-of-type {
    padding-left: 2rem;
    padding-right: 0;
  }

  .m-md-s35 {
    margin: 2.1875rem;
  }

  .-m-md-s35 {
    margin: -2.1875rem;
  }

  .mt-md-s35 {
    margin-top: 2.1875rem;
  }

  .-mt-md-s35 {
    margin-top: -2.1875rem;
  }

  .mb-md-s35 {
    margin-bottom: 2.1875rem;
  }

  .-mb-md-s35 {
    margin-bottom: -2.1875rem;
  }

  .mr-md-s35 {
    margin-right: 2.1875rem;
  }

  .-mr-md-s35 {
    margin-right: -2.1875rem;
  }

  .ml-md-s35 {
    margin-left: 2.1875rem;
  }

  .-ml-md-s35 {
    margin-left: -2.1875rem;
  }

  .p-md-s35 {
    padding: 2.1875rem;
  }

  .-p-md-s35 {
    padding: -2.1875rem;
  }

  .pt-md-s35 {
    padding-top: 2.1875rem;
  }

  .-pt-md-s35 {
    padding-top: -2.1875rem;
  }

  .pb-md-s35 {
    padding-bottom: 2.1875rem;
  }

  .-pb-md-s35 {
    padding-bottom: -2.1875rem;
  }

  .pr-md-s35 {
    padding-right: 2.1875rem;
  }

  .-pr-md-s35 {
    padding-right: -2.1875rem;
  }

  .pl-md-s35 {
    padding-left: 2.1875rem;
  }

  .-pl-md-s35 {
    padding-left: -2.1875rem;
  }

  .mx-md-s35 {
    margin-left: 2.1875rem;
    margin-right: 2.1875rem;
  }

  .my-md-s35 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }

  .px-md-s35 {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .py-md-s35 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }

  .gap-md-s35 {
    gap: 2.1875rem;
  }

  .pm-md-s35, .gx-md-s35 > .gx-child {
    padding-left: 1.09375rem;
    padding-right: 1.09375rem;
  }

  .gx-md-s35 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.1875rem;
  }

  .gx-md-s35 > .gx-child:last-of-type {
    padding-left: 2.1875rem;
    padding-right: 0;
  }

  .m-md-s40 {
    margin: 2.5rem;
  }

  .-m-md-s40 {
    margin: -2.5rem;
  }

  .mt-md-s40 {
    margin-top: 2.5rem;
  }

  .-mt-md-s40 {
    margin-top: -2.5rem;
  }

  .mb-md-s40 {
    margin-bottom: 2.5rem;
  }

  .-mb-md-s40 {
    margin-bottom: -2.5rem;
  }

  .mr-md-s40 {
    margin-right: 2.5rem;
  }

  .-mr-md-s40 {
    margin-right: -2.5rem;
  }

  .ml-md-s40 {
    margin-left: 2.5rem;
  }

  .-ml-md-s40 {
    margin-left: -2.5rem;
  }

  .p-md-s40 {
    padding: 2.5rem;
  }

  .-p-md-s40 {
    padding: -2.5rem;
  }

  .pt-md-s40 {
    padding-top: 2.5rem;
  }

  .-pt-md-s40 {
    padding-top: -2.5rem;
  }

  .pb-md-s40 {
    padding-bottom: 2.5rem;
  }

  .-pb-md-s40 {
    padding-bottom: -2.5rem;
  }

  .pr-md-s40 {
    padding-right: 2.5rem;
  }

  .-pr-md-s40 {
    padding-right: -2.5rem;
  }

  .pl-md-s40 {
    padding-left: 2.5rem;
  }

  .-pl-md-s40 {
    padding-left: -2.5rem;
  }

  .mx-md-s40 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .my-md-s40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .px-md-s40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .py-md-s40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .gap-md-s40 {
    gap: 2.5rem;
  }

  .pm-md-s40, .gx-md-s40 > .gx-child {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .gx-md-s40 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.5rem;
  }

  .gx-md-s40 > .gx-child:last-of-type {
    padding-left: 2.5rem;
    padding-right: 0;
  }

  .m-md-s45 {
    margin: 2.8125rem;
  }

  .-m-md-s45 {
    margin: -2.8125rem;
  }

  .mt-md-s45 {
    margin-top: 2.8125rem;
  }

  .-mt-md-s45 {
    margin-top: -2.8125rem;
  }

  .mb-md-s45 {
    margin-bottom: 2.8125rem;
  }

  .-mb-md-s45 {
    margin-bottom: -2.8125rem;
  }

  .mr-md-s45 {
    margin-right: 2.8125rem;
  }

  .-mr-md-s45 {
    margin-right: -2.8125rem;
  }

  .ml-md-s45 {
    margin-left: 2.8125rem;
  }

  .-ml-md-s45 {
    margin-left: -2.8125rem;
  }

  .p-md-s45 {
    padding: 2.8125rem;
  }

  .-p-md-s45 {
    padding: -2.8125rem;
  }

  .pt-md-s45 {
    padding-top: 2.8125rem;
  }

  .-pt-md-s45 {
    padding-top: -2.8125rem;
  }

  .pb-md-s45 {
    padding-bottom: 2.8125rem;
  }

  .-pb-md-s45 {
    padding-bottom: -2.8125rem;
  }

  .pr-md-s45 {
    padding-right: 2.8125rem;
  }

  .-pr-md-s45 {
    padding-right: -2.8125rem;
  }

  .pl-md-s45 {
    padding-left: 2.8125rem;
  }

  .-pl-md-s45 {
    padding-left: -2.8125rem;
  }

  .mx-md-s45 {
    margin-left: 2.8125rem;
    margin-right: 2.8125rem;
  }

  .my-md-s45 {
    margin-top: 2.8125rem;
    margin-bottom: 2.8125rem;
  }

  .px-md-s45 {
    padding-left: 2.8125rem;
    padding-right: 2.8125rem;
  }

  .py-md-s45 {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }

  .gap-md-s45 {
    gap: 2.8125rem;
  }

  .pm-md-s45, .gx-md-s45 > .gx-child {
    padding-left: 1.40625rem;
    padding-right: 1.40625rem;
  }

  .gx-md-s45 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.8125rem;
  }

  .gx-md-s45 > .gx-child:last-of-type {
    padding-left: 2.8125rem;
    padding-right: 0;
  }

  .m-md-s48 {
    margin: 3rem;
  }

  .-m-md-s48 {
    margin: -3rem;
  }

  .mt-md-s48 {
    margin-top: 3rem;
  }

  .-mt-md-s48 {
    margin-top: -3rem;
  }

  .mb-md-s48 {
    margin-bottom: 3rem;
  }

  .-mb-md-s48 {
    margin-bottom: -3rem;
  }

  .mr-md-s48 {
    margin-right: 3rem;
  }

  .-mr-md-s48 {
    margin-right: -3rem;
  }

  .ml-md-s48 {
    margin-left: 3rem;
  }

  .-ml-md-s48 {
    margin-left: -3rem;
  }

  .p-md-s48 {
    padding: 3rem;
  }

  .-p-md-s48 {
    padding: -3rem;
  }

  .pt-md-s48 {
    padding-top: 3rem;
  }

  .-pt-md-s48 {
    padding-top: -3rem;
  }

  .pb-md-s48 {
    padding-bottom: 3rem;
  }

  .-pb-md-s48 {
    padding-bottom: -3rem;
  }

  .pr-md-s48 {
    padding-right: 3rem;
  }

  .-pr-md-s48 {
    padding-right: -3rem;
  }

  .pl-md-s48 {
    padding-left: 3rem;
  }

  .-pl-md-s48 {
    padding-left: -3rem;
  }

  .mx-md-s48 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .my-md-s48 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .px-md-s48 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .py-md-s48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .gap-md-s48 {
    gap: 3rem;
  }

  .pm-md-s48, .gx-md-s48 > .gx-child {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .gx-md-s48 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3rem;
  }

  .gx-md-s48 > .gx-child:last-of-type {
    padding-left: 3rem;
    padding-right: 0;
  }

  .m-md-s50 {
    margin: 3.125rem;
  }

  .-m-md-s50 {
    margin: -3.125rem;
  }

  .mt-md-s50 {
    margin-top: 3.125rem;
  }

  .-mt-md-s50 {
    margin-top: -3.125rem;
  }

  .mb-md-s50 {
    margin-bottom: 3.125rem;
  }

  .-mb-md-s50 {
    margin-bottom: -3.125rem;
  }

  .mr-md-s50 {
    margin-right: 3.125rem;
  }

  .-mr-md-s50 {
    margin-right: -3.125rem;
  }

  .ml-md-s50 {
    margin-left: 3.125rem;
  }

  .-ml-md-s50 {
    margin-left: -3.125rem;
  }

  .p-md-s50 {
    padding: 3.125rem;
  }

  .-p-md-s50 {
    padding: -3.125rem;
  }

  .pt-md-s50 {
    padding-top: 3.125rem;
  }

  .-pt-md-s50 {
    padding-top: -3.125rem;
  }

  .pb-md-s50 {
    padding-bottom: 3.125rem;
  }

  .-pb-md-s50 {
    padding-bottom: -3.125rem;
  }

  .pr-md-s50 {
    padding-right: 3.125rem;
  }

  .-pr-md-s50 {
    padding-right: -3.125rem;
  }

  .pl-md-s50 {
    padding-left: 3.125rem;
  }

  .-pl-md-s50 {
    padding-left: -3.125rem;
  }

  .mx-md-s50 {
    margin-left: 3.125rem;
    margin-right: 3.125rem;
  }

  .my-md-s50 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }

  .px-md-s50 {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .py-md-s50 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }

  .gap-md-s50 {
    gap: 3.125rem;
  }

  .pm-md-s50, .gx-md-s50 > .gx-child {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .gx-md-s50 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.125rem;
  }

  .gx-md-s50 > .gx-child:last-of-type {
    padding-left: 3.125rem;
    padding-right: 0;
  }

  .m-md-s60 {
    margin: 3.75rem;
  }

  .-m-md-s60 {
    margin: -3.75rem;
  }

  .mt-md-s60 {
    margin-top: 3.75rem;
  }

  .-mt-md-s60 {
    margin-top: -3.75rem;
  }

  .mb-md-s60 {
    margin-bottom: 3.75rem;
  }

  .-mb-md-s60 {
    margin-bottom: -3.75rem;
  }

  .mr-md-s60 {
    margin-right: 3.75rem;
  }

  .-mr-md-s60 {
    margin-right: -3.75rem;
  }

  .ml-md-s60 {
    margin-left: 3.75rem;
  }

  .-ml-md-s60 {
    margin-left: -3.75rem;
  }

  .p-md-s60 {
    padding: 3.75rem;
  }

  .-p-md-s60 {
    padding: -3.75rem;
  }

  .pt-md-s60 {
    padding-top: 3.75rem;
  }

  .-pt-md-s60 {
    padding-top: -3.75rem;
  }

  .pb-md-s60 {
    padding-bottom: 3.75rem;
  }

  .-pb-md-s60 {
    padding-bottom: -3.75rem;
  }

  .pr-md-s60 {
    padding-right: 3.75rem;
  }

  .-pr-md-s60 {
    padding-right: -3.75rem;
  }

  .pl-md-s60 {
    padding-left: 3.75rem;
  }

  .-pl-md-s60 {
    padding-left: -3.75rem;
  }

  .mx-md-s60 {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
  }

  .my-md-s60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .px-md-s60 {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .py-md-s60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  .gap-md-s60 {
    gap: 3.75rem;
  }

  .pm-md-s60, .gx-md-s60 > .gx-child {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .gx-md-s60 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.75rem;
  }

  .gx-md-s60 > .gx-child:last-of-type {
    padding-left: 3.75rem;
    padding-right: 0;
  }

  .m-md-s70 {
    margin: 4.375rem;
  }

  .-m-md-s70 {
    margin: -4.375rem;
  }

  .mt-md-s70 {
    margin-top: 4.375rem;
  }

  .-mt-md-s70 {
    margin-top: -4.375rem;
  }

  .mb-md-s70 {
    margin-bottom: 4.375rem;
  }

  .-mb-md-s70 {
    margin-bottom: -4.375rem;
  }

  .mr-md-s70 {
    margin-right: 4.375rem;
  }

  .-mr-md-s70 {
    margin-right: -4.375rem;
  }

  .ml-md-s70 {
    margin-left: 4.375rem;
  }

  .-ml-md-s70 {
    margin-left: -4.375rem;
  }

  .p-md-s70 {
    padding: 4.375rem;
  }

  .-p-md-s70 {
    padding: -4.375rem;
  }

  .pt-md-s70 {
    padding-top: 4.375rem;
  }

  .-pt-md-s70 {
    padding-top: -4.375rem;
  }

  .pb-md-s70 {
    padding-bottom: 4.375rem;
  }

  .-pb-md-s70 {
    padding-bottom: -4.375rem;
  }

  .pr-md-s70 {
    padding-right: 4.375rem;
  }

  .-pr-md-s70 {
    padding-right: -4.375rem;
  }

  .pl-md-s70 {
    padding-left: 4.375rem;
  }

  .-pl-md-s70 {
    padding-left: -4.375rem;
  }

  .mx-md-s70 {
    margin-left: 4.375rem;
    margin-right: 4.375rem;
  }

  .my-md-s70 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }

  .px-md-s70 {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }

  .py-md-s70 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }

  .gap-md-s70 {
    gap: 4.375rem;
  }

  .pm-md-s70, .gx-md-s70 > .gx-child {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .gx-md-s70 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 4.375rem;
  }

  .gx-md-s70 > .gx-child:last-of-type {
    padding-left: 4.375rem;
    padding-right: 0;
  }

  .m-md-s80 {
    margin: 5rem;
  }

  .-m-md-s80 {
    margin: -5rem;
  }

  .mt-md-s80 {
    margin-top: 5rem;
  }

  .-mt-md-s80 {
    margin-top: -5rem;
  }

  .mb-md-s80 {
    margin-bottom: 5rem;
  }

  .-mb-md-s80 {
    margin-bottom: -5rem;
  }

  .mr-md-s80 {
    margin-right: 5rem;
  }

  .-mr-md-s80 {
    margin-right: -5rem;
  }

  .ml-md-s80 {
    margin-left: 5rem;
  }

  .-ml-md-s80 {
    margin-left: -5rem;
  }

  .p-md-s80 {
    padding: 5rem;
  }

  .-p-md-s80 {
    padding: -5rem;
  }

  .pt-md-s80 {
    padding-top: 5rem;
  }

  .-pt-md-s80 {
    padding-top: -5rem;
  }

  .pb-md-s80 {
    padding-bottom: 5rem;
  }

  .-pb-md-s80 {
    padding-bottom: -5rem;
  }

  .pr-md-s80 {
    padding-right: 5rem;
  }

  .-pr-md-s80 {
    padding-right: -5rem;
  }

  .pl-md-s80 {
    padding-left: 5rem;
  }

  .-pl-md-s80 {
    padding-left: -5rem;
  }

  .mx-md-s80 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .my-md-s80 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .px-md-s80 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .py-md-s80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .gap-md-s80 {
    gap: 5rem;
  }

  .pm-md-s80, .gx-md-s80 > .gx-child {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .gx-md-s80 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 5rem;
  }

  .gx-md-s80 > .gx-child:last-of-type {
    padding-left: 5rem;
    padding-right: 0;
  }

  .m-md-s100 {
    margin: 6.25rem;
  }

  .-m-md-s100 {
    margin: -6.25rem;
  }

  .mt-md-s100 {
    margin-top: 6.25rem;
  }

  .-mt-md-s100 {
    margin-top: -6.25rem;
  }

  .mb-md-s100 {
    margin-bottom: 6.25rem;
  }

  .-mb-md-s100 {
    margin-bottom: -6.25rem;
  }

  .mr-md-s100 {
    margin-right: 6.25rem;
  }

  .-mr-md-s100 {
    margin-right: -6.25rem;
  }

  .ml-md-s100 {
    margin-left: 6.25rem;
  }

  .-ml-md-s100 {
    margin-left: -6.25rem;
  }

  .p-md-s100 {
    padding: 6.25rem;
  }

  .-p-md-s100 {
    padding: -6.25rem;
  }

  .pt-md-s100 {
    padding-top: 6.25rem;
  }

  .-pt-md-s100 {
    padding-top: -6.25rem;
  }

  .pb-md-s100 {
    padding-bottom: 6.25rem;
  }

  .-pb-md-s100 {
    padding-bottom: -6.25rem;
  }

  .pr-md-s100 {
    padding-right: 6.25rem;
  }

  .-pr-md-s100 {
    padding-right: -6.25rem;
  }

  .pl-md-s100 {
    padding-left: 6.25rem;
  }

  .-pl-md-s100 {
    padding-left: -6.25rem;
  }

  .mx-md-s100 {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
  }

  .my-md-s100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }

  .px-md-s100 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }

  .py-md-s100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  .gap-md-s100 {
    gap: 6.25rem;
  }

  .pm-md-s100, .gx-md-s100 > .gx-child {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .gx-md-s100 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 6.25rem;
  }

  .gx-md-s100 > .gx-child:last-of-type {
    padding-left: 6.25rem;
    padding-right: 0;
  }

  .m-md-s120 {
    margin: 7.5rem;
  }

  .-m-md-s120 {
    margin: -7.5rem;
  }

  .mt-md-s120 {
    margin-top: 7.5rem;
  }

  .-mt-md-s120 {
    margin-top: -7.5rem;
  }

  .mb-md-s120 {
    margin-bottom: 7.5rem;
  }

  .-mb-md-s120 {
    margin-bottom: -7.5rem;
  }

  .mr-md-s120 {
    margin-right: 7.5rem;
  }

  .-mr-md-s120 {
    margin-right: -7.5rem;
  }

  .ml-md-s120 {
    margin-left: 7.5rem;
  }

  .-ml-md-s120 {
    margin-left: -7.5rem;
  }

  .p-md-s120 {
    padding: 7.5rem;
  }

  .-p-md-s120 {
    padding: -7.5rem;
  }

  .pt-md-s120 {
    padding-top: 7.5rem;
  }

  .-pt-md-s120 {
    padding-top: -7.5rem;
  }

  .pb-md-s120 {
    padding-bottom: 7.5rem;
  }

  .-pb-md-s120 {
    padding-bottom: -7.5rem;
  }

  .pr-md-s120 {
    padding-right: 7.5rem;
  }

  .-pr-md-s120 {
    padding-right: -7.5rem;
  }

  .pl-md-s120 {
    padding-left: 7.5rem;
  }

  .-pl-md-s120 {
    padding-left: -7.5rem;
  }

  .mx-md-s120 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }

  .my-md-s120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }

  .px-md-s120 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }

  .py-md-s120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }

  .gap-md-s120 {
    gap: 7.5rem;
  }

  .pm-md-s120, .gx-md-s120 > .gx-child {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .gx-md-s120 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 7.5rem;
  }

  .gx-md-s120 > .gx-child:last-of-type {
    padding-left: 7.5rem;
    padding-right: 0;
  }

  .m-md-s150 {
    margin: 9.375rem;
  }

  .-m-md-s150 {
    margin: -9.375rem;
  }

  .mt-md-s150 {
    margin-top: 9.375rem;
  }

  .-mt-md-s150 {
    margin-top: -9.375rem;
  }

  .mb-md-s150 {
    margin-bottom: 9.375rem;
  }

  .-mb-md-s150 {
    margin-bottom: -9.375rem;
  }

  .mr-md-s150 {
    margin-right: 9.375rem;
  }

  .-mr-md-s150 {
    margin-right: -9.375rem;
  }

  .ml-md-s150 {
    margin-left: 9.375rem;
  }

  .-ml-md-s150 {
    margin-left: -9.375rem;
  }

  .p-md-s150 {
    padding: 9.375rem;
  }

  .-p-md-s150 {
    padding: -9.375rem;
  }

  .pt-md-s150 {
    padding-top: 9.375rem;
  }

  .-pt-md-s150 {
    padding-top: -9.375rem;
  }

  .pb-md-s150 {
    padding-bottom: 9.375rem;
  }

  .-pb-md-s150 {
    padding-bottom: -9.375rem;
  }

  .pr-md-s150 {
    padding-right: 9.375rem;
  }

  .-pr-md-s150 {
    padding-right: -9.375rem;
  }

  .pl-md-s150 {
    padding-left: 9.375rem;
  }

  .-pl-md-s150 {
    padding-left: -9.375rem;
  }

  .mx-md-s150 {
    margin-left: 9.375rem;
    margin-right: 9.375rem;
  }

  .my-md-s150 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }

  .px-md-s150 {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
  }

  .py-md-s150 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }

  .gap-md-s150 {
    gap: 9.375rem;
  }

  .pm-md-s150, .gx-md-s150 > .gx-child {
    padding-left: 4.6875rem;
    padding-right: 4.6875rem;
  }

  .gx-md-s150 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 9.375rem;
  }

  .gx-md-s150 > .gx-child:last-of-type {
    padding-left: 9.375rem;
    padding-right: 0;
  }
}

@media screen and (min-width: 1025px) {
  .m-lg-s0, .-m-lg-s0 {
    margin: 0;
  }

  .mt-lg-s0, .-mt-lg-s0 {
    margin-top: 0;
  }

  .mb-lg-s0, .-mb-lg-s0 {
    margin-bottom: 0;
  }

  .mr-lg-s0, .-mr-lg-s0 {
    margin-right: 0;
  }

  .ml-lg-s0, .-ml-lg-s0 {
    margin-left: 0;
  }

  .p-lg-s0, .-p-lg-s0 {
    padding: 0;
  }

  .pt-lg-s0, .-pt-lg-s0 {
    padding-top: 0;
  }

  .pb-lg-s0, .-pb-lg-s0 {
    padding-bottom: 0;
  }

  .pr-lg-s0, .-pr-lg-s0 {
    padding-right: 0;
  }

  .pl-lg-s0, .-pl-lg-s0 {
    padding-left: 0;
  }

  .mx-lg-s0 {
    margin-left: 0;
    margin-right: 0;
  }

  .my-lg-s0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .px-lg-s0 {
    padding-left: 0;
    padding-right: 0;
  }

  .py-lg-s0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .gap-lg-s0 {
    gap: 0;
  }

  .pm-lg-s0, .gx-lg-s0 > .gx-child, .gx-lg-s0 > .gx-child:first-of-type, .gx-lg-s0 > .gx-child:last-of-type {
    padding-left: 0;
    padding-right: 0;
  }

  .m-lg-s2 {
    margin: .125rem;
  }

  .-m-lg-s2 {
    margin: -.125rem;
  }

  .mt-lg-s2 {
    margin-top: .125rem;
  }

  .-mt-lg-s2 {
    margin-top: -.125rem;
  }

  .mb-lg-s2 {
    margin-bottom: .125rem;
  }

  .-mb-lg-s2 {
    margin-bottom: -.125rem;
  }

  .mr-lg-s2 {
    margin-right: .125rem;
  }

  .-mr-lg-s2 {
    margin-right: -.125rem;
  }

  .ml-lg-s2 {
    margin-left: .125rem;
  }

  .-ml-lg-s2 {
    margin-left: -.125rem;
  }

  .p-lg-s2 {
    padding: .125rem;
  }

  .-p-lg-s2 {
    padding: -.125rem;
  }

  .pt-lg-s2 {
    padding-top: .125rem;
  }

  .-pt-lg-s2 {
    padding-top: -.125rem;
  }

  .pb-lg-s2 {
    padding-bottom: .125rem;
  }

  .-pb-lg-s2 {
    padding-bottom: -.125rem;
  }

  .pr-lg-s2 {
    padding-right: .125rem;
  }

  .-pr-lg-s2 {
    padding-right: -.125rem;
  }

  .pl-lg-s2 {
    padding-left: .125rem;
  }

  .-pl-lg-s2 {
    padding-left: -.125rem;
  }

  .mx-lg-s2 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .my-lg-s2 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .px-lg-s2 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .py-lg-s2 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .gap-lg-s2 {
    gap: .125rem;
  }

  .pm-lg-s2, .gx-lg-s2 > .gx-child {
    padding-left: .0625rem;
    padding-right: .0625rem;
  }

  .gx-lg-s2 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .125rem;
  }

  .gx-lg-s2 > .gx-child:last-of-type {
    padding-left: .125rem;
    padding-right: 0;
  }

  .m-lg-s3 {
    margin: .1875rem;
  }

  .-m-lg-s3 {
    margin: -.1875rem;
  }

  .mt-lg-s3 {
    margin-top: .1875rem;
  }

  .-mt-lg-s3 {
    margin-top: -.1875rem;
  }

  .mb-lg-s3 {
    margin-bottom: .1875rem;
  }

  .-mb-lg-s3 {
    margin-bottom: -.1875rem;
  }

  .mr-lg-s3 {
    margin-right: .1875rem;
  }

  .-mr-lg-s3 {
    margin-right: -.1875rem;
  }

  .ml-lg-s3 {
    margin-left: .1875rem;
  }

  .-ml-lg-s3 {
    margin-left: -.1875rem;
  }

  .p-lg-s3 {
    padding: .1875rem;
  }

  .-p-lg-s3 {
    padding: -.1875rem;
  }

  .pt-lg-s3 {
    padding-top: .1875rem;
  }

  .-pt-lg-s3 {
    padding-top: -.1875rem;
  }

  .pb-lg-s3 {
    padding-bottom: .1875rem;
  }

  .-pb-lg-s3 {
    padding-bottom: -.1875rem;
  }

  .pr-lg-s3 {
    padding-right: .1875rem;
  }

  .-pr-lg-s3 {
    padding-right: -.1875rem;
  }

  .pl-lg-s3 {
    padding-left: .1875rem;
  }

  .-pl-lg-s3 {
    padding-left: -.1875rem;
  }

  .mx-lg-s3 {
    margin-left: .1875rem;
    margin-right: .1875rem;
  }

  .my-lg-s3 {
    margin-top: .1875rem;
    margin-bottom: .1875rem;
  }

  .px-lg-s3 {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .py-lg-s3 {
    padding-top: .1875rem;
    padding-bottom: .1875rem;
  }

  .gap-lg-s3 {
    gap: .1875rem;
  }

  .pm-lg-s3, .gx-lg-s3 > .gx-child {
    padding-left: .09375rem;
    padding-right: .09375rem;
  }

  .gx-lg-s3 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .1875rem;
  }

  .gx-lg-s3 > .gx-child:last-of-type {
    padding-left: .1875rem;
    padding-right: 0;
  }

  .m-lg-s4 {
    margin: .25rem;
  }

  .-m-lg-s4 {
    margin: -.25rem;
  }

  .mt-lg-s4 {
    margin-top: .25rem;
  }

  .-mt-lg-s4 {
    margin-top: -.25rem;
  }

  .mb-lg-s4 {
    margin-bottom: .25rem;
  }

  .-mb-lg-s4 {
    margin-bottom: -.25rem;
  }

  .mr-lg-s4 {
    margin-right: .25rem;
  }

  .-mr-lg-s4 {
    margin-right: -.25rem;
  }

  .ml-lg-s4 {
    margin-left: .25rem;
  }

  .-ml-lg-s4 {
    margin-left: -.25rem;
  }

  .p-lg-s4 {
    padding: .25rem;
  }

  .-p-lg-s4 {
    padding: -.25rem;
  }

  .pt-lg-s4 {
    padding-top: .25rem;
  }

  .-pt-lg-s4 {
    padding-top: -.25rem;
  }

  .pb-lg-s4 {
    padding-bottom: .25rem;
  }

  .-pb-lg-s4 {
    padding-bottom: -.25rem;
  }

  .pr-lg-s4 {
    padding-right: .25rem;
  }

  .-pr-lg-s4 {
    padding-right: -.25rem;
  }

  .pl-lg-s4 {
    padding-left: .25rem;
  }

  .-pl-lg-s4 {
    padding-left: -.25rem;
  }

  .mx-lg-s4 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .my-lg-s4 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .px-lg-s4 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .py-lg-s4 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .gap-lg-s4 {
    gap: .25rem;
  }

  .pm-lg-s4, .gx-lg-s4 > .gx-child {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .gx-lg-s4 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .25rem;
  }

  .gx-lg-s4 > .gx-child:last-of-type {
    padding-left: .25rem;
    padding-right: 0;
  }

  .m-lg-s5 {
    margin: .3125rem;
  }

  .-m-lg-s5 {
    margin: -.3125rem;
  }

  .mt-lg-s5 {
    margin-top: .3125rem;
  }

  .-mt-lg-s5 {
    margin-top: -.3125rem;
  }

  .mb-lg-s5 {
    margin-bottom: .3125rem;
  }

  .-mb-lg-s5 {
    margin-bottom: -.3125rem;
  }

  .mr-lg-s5 {
    margin-right: .3125rem;
  }

  .-mr-lg-s5 {
    margin-right: -.3125rem;
  }

  .ml-lg-s5 {
    margin-left: .3125rem;
  }

  .-ml-lg-s5 {
    margin-left: -.3125rem;
  }

  .p-lg-s5 {
    padding: .3125rem;
  }

  .-p-lg-s5 {
    padding: -.3125rem;
  }

  .pt-lg-s5 {
    padding-top: .3125rem;
  }

  .-pt-lg-s5 {
    padding-top: -.3125rem;
  }

  .pb-lg-s5 {
    padding-bottom: .3125rem;
  }

  .-pb-lg-s5 {
    padding-bottom: -.3125rem;
  }

  .pr-lg-s5 {
    padding-right: .3125rem;
  }

  .-pr-lg-s5 {
    padding-right: -.3125rem;
  }

  .pl-lg-s5 {
    padding-left: .3125rem;
  }

  .-pl-lg-s5 {
    padding-left: -.3125rem;
  }

  .mx-lg-s5 {
    margin-left: .3125rem;
    margin-right: .3125rem;
  }

  .my-lg-s5 {
    margin-top: .3125rem;
    margin-bottom: .3125rem;
  }

  .px-lg-s5 {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .py-lg-s5 {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
  }

  .gap-lg-s5 {
    gap: .3125rem;
  }

  .pm-lg-s5, .gx-lg-s5 > .gx-child {
    padding-left: .15625rem;
    padding-right: .15625rem;
  }

  .gx-lg-s5 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .3125rem;
  }

  .gx-lg-s5 > .gx-child:last-of-type {
    padding-left: .3125rem;
    padding-right: 0;
  }

  .m-lg-s6 {
    margin: .375rem;
  }

  .-m-lg-s6 {
    margin: -.375rem;
  }

  .mt-lg-s6 {
    margin-top: .375rem;
  }

  .-mt-lg-s6 {
    margin-top: -.375rem;
  }

  .mb-lg-s6 {
    margin-bottom: .375rem;
  }

  .-mb-lg-s6 {
    margin-bottom: -.375rem;
  }

  .mr-lg-s6 {
    margin-right: .375rem;
  }

  .-mr-lg-s6 {
    margin-right: -.375rem;
  }

  .ml-lg-s6 {
    margin-left: .375rem;
  }

  .-ml-lg-s6 {
    margin-left: -.375rem;
  }

  .p-lg-s6 {
    padding: .375rem;
  }

  .-p-lg-s6 {
    padding: -.375rem;
  }

  .pt-lg-s6 {
    padding-top: .375rem;
  }

  .-pt-lg-s6 {
    padding-top: -.375rem;
  }

  .pb-lg-s6 {
    padding-bottom: .375rem;
  }

  .-pb-lg-s6 {
    padding-bottom: -.375rem;
  }

  .pr-lg-s6 {
    padding-right: .375rem;
  }

  .-pr-lg-s6 {
    padding-right: -.375rem;
  }

  .pl-lg-s6 {
    padding-left: .375rem;
  }

  .-pl-lg-s6 {
    padding-left: -.375rem;
  }

  .mx-lg-s6 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .my-lg-s6 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .px-lg-s6 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .py-lg-s6 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .gap-lg-s6 {
    gap: .375rem;
  }

  .pm-lg-s6, .gx-lg-s6 > .gx-child {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .gx-lg-s6 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .375rem;
  }

  .gx-lg-s6 > .gx-child:last-of-type {
    padding-left: .375rem;
    padding-right: 0;
  }

  .m-lg-s7 {
    margin: .4375rem;
  }

  .-m-lg-s7 {
    margin: -.4375rem;
  }

  .mt-lg-s7 {
    margin-top: .4375rem;
  }

  .-mt-lg-s7 {
    margin-top: -.4375rem;
  }

  .mb-lg-s7 {
    margin-bottom: .4375rem;
  }

  .-mb-lg-s7 {
    margin-bottom: -.4375rem;
  }

  .mr-lg-s7 {
    margin-right: .4375rem;
  }

  .-mr-lg-s7 {
    margin-right: -.4375rem;
  }

  .ml-lg-s7 {
    margin-left: .4375rem;
  }

  .-ml-lg-s7 {
    margin-left: -.4375rem;
  }

  .p-lg-s7 {
    padding: .4375rem;
  }

  .-p-lg-s7 {
    padding: -.4375rem;
  }

  .pt-lg-s7 {
    padding-top: .4375rem;
  }

  .-pt-lg-s7 {
    padding-top: -.4375rem;
  }

  .pb-lg-s7 {
    padding-bottom: .4375rem;
  }

  .-pb-lg-s7 {
    padding-bottom: -.4375rem;
  }

  .pr-lg-s7 {
    padding-right: .4375rem;
  }

  .-pr-lg-s7 {
    padding-right: -.4375rem;
  }

  .pl-lg-s7 {
    padding-left: .4375rem;
  }

  .-pl-lg-s7 {
    padding-left: -.4375rem;
  }

  .mx-lg-s7 {
    margin-left: .4375rem;
    margin-right: .4375rem;
  }

  .my-lg-s7 {
    margin-top: .4375rem;
    margin-bottom: .4375rem;
  }

  .px-lg-s7 {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .py-lg-s7 {
    padding-top: .4375rem;
    padding-bottom: .4375rem;
  }

  .gap-lg-s7 {
    gap: .4375rem;
  }

  .pm-lg-s7, .gx-lg-s7 > .gx-child {
    padding-left: .21875rem;
    padding-right: .21875rem;
  }

  .gx-lg-s7 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .4375rem;
  }

  .gx-lg-s7 > .gx-child:last-of-type {
    padding-left: .4375rem;
    padding-right: 0;
  }

  .m-lg-s8 {
    margin: .5rem;
  }

  .-m-lg-s8 {
    margin: -.5rem;
  }

  .mt-lg-s8 {
    margin-top: .5rem;
  }

  .-mt-lg-s8 {
    margin-top: -.5rem;
  }

  .mb-lg-s8 {
    margin-bottom: .5rem;
  }

  .-mb-lg-s8 {
    margin-bottom: -.5rem;
  }

  .mr-lg-s8 {
    margin-right: .5rem;
  }

  .-mr-lg-s8 {
    margin-right: -.5rem;
  }

  .ml-lg-s8 {
    margin-left: .5rem;
  }

  .-ml-lg-s8 {
    margin-left: -.5rem;
  }

  .p-lg-s8 {
    padding: .5rem;
  }

  .-p-lg-s8 {
    padding: -.5rem;
  }

  .pt-lg-s8 {
    padding-top: .5rem;
  }

  .-pt-lg-s8 {
    padding-top: -.5rem;
  }

  .pb-lg-s8 {
    padding-bottom: .5rem;
  }

  .-pb-lg-s8 {
    padding-bottom: -.5rem;
  }

  .pr-lg-s8 {
    padding-right: .5rem;
  }

  .-pr-lg-s8 {
    padding-right: -.5rem;
  }

  .pl-lg-s8 {
    padding-left: .5rem;
  }

  .-pl-lg-s8 {
    padding-left: -.5rem;
  }

  .mx-lg-s8 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .my-lg-s8 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .px-lg-s8 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .py-lg-s8 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .gap-lg-s8 {
    gap: .5rem;
  }

  .pm-lg-s8, .gx-lg-s8 > .gx-child {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .gx-lg-s8 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5rem;
  }

  .gx-lg-s8 > .gx-child:last-of-type {
    padding-left: .5rem;
    padding-right: 0;
  }

  .m-lg-s9 {
    margin: .5625rem;
  }

  .-m-lg-s9 {
    margin: -.5625rem;
  }

  .mt-lg-s9 {
    margin-top: .5625rem;
  }

  .-mt-lg-s9 {
    margin-top: -.5625rem;
  }

  .mb-lg-s9 {
    margin-bottom: .5625rem;
  }

  .-mb-lg-s9 {
    margin-bottom: -.5625rem;
  }

  .mr-lg-s9 {
    margin-right: .5625rem;
  }

  .-mr-lg-s9 {
    margin-right: -.5625rem;
  }

  .ml-lg-s9 {
    margin-left: .5625rem;
  }

  .-ml-lg-s9 {
    margin-left: -.5625rem;
  }

  .p-lg-s9 {
    padding: .5625rem;
  }

  .-p-lg-s9 {
    padding: -.5625rem;
  }

  .pt-lg-s9 {
    padding-top: .5625rem;
  }

  .-pt-lg-s9 {
    padding-top: -.5625rem;
  }

  .pb-lg-s9 {
    padding-bottom: .5625rem;
  }

  .-pb-lg-s9 {
    padding-bottom: -.5625rem;
  }

  .pr-lg-s9 {
    padding-right: .5625rem;
  }

  .-pr-lg-s9 {
    padding-right: -.5625rem;
  }

  .pl-lg-s9 {
    padding-left: .5625rem;
  }

  .-pl-lg-s9 {
    padding-left: -.5625rem;
  }

  .mx-lg-s9 {
    margin-left: .5625rem;
    margin-right: .5625rem;
  }

  .my-lg-s9 {
    margin-top: .5625rem;
    margin-bottom: .5625rem;
  }

  .px-lg-s9 {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .py-lg-s9 {
    padding-top: .5625rem;
    padding-bottom: .5625rem;
  }

  .gap-lg-s9 {
    gap: .5625rem;
  }

  .pm-lg-s9, .gx-lg-s9 > .gx-child {
    padding-left: .28125rem;
    padding-right: .28125rem;
  }

  .gx-lg-s9 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5625rem;
  }

  .gx-lg-s9 > .gx-child:last-of-type {
    padding-left: .5625rem;
    padding-right: 0;
  }

  .m-lg-s10 {
    margin: .625rem;
  }

  .-m-lg-s10 {
    margin: -.625rem;
  }

  .mt-lg-s10 {
    margin-top: .625rem;
  }

  .-mt-lg-s10 {
    margin-top: -.625rem;
  }

  .mb-lg-s10 {
    margin-bottom: .625rem;
  }

  .-mb-lg-s10 {
    margin-bottom: -.625rem;
  }

  .mr-lg-s10 {
    margin-right: .625rem;
  }

  .-mr-lg-s10 {
    margin-right: -.625rem;
  }

  .ml-lg-s10 {
    margin-left: .625rem;
  }

  .-ml-lg-s10 {
    margin-left: -.625rem;
  }

  .p-lg-s10 {
    padding: .625rem;
  }

  .-p-lg-s10 {
    padding: -.625rem;
  }

  .pt-lg-s10 {
    padding-top: .625rem;
  }

  .-pt-lg-s10 {
    padding-top: -.625rem;
  }

  .pb-lg-s10 {
    padding-bottom: .625rem;
  }

  .-pb-lg-s10 {
    padding-bottom: -.625rem;
  }

  .pr-lg-s10 {
    padding-right: .625rem;
  }

  .-pr-lg-s10 {
    padding-right: -.625rem;
  }

  .pl-lg-s10 {
    padding-left: .625rem;
  }

  .-pl-lg-s10 {
    padding-left: -.625rem;
  }

  .mx-lg-s10 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .my-lg-s10 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .px-lg-s10 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .py-lg-s10 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .gap-lg-s10 {
    gap: .625rem;
  }

  .pm-lg-s10, .gx-lg-s10 > .gx-child {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .gx-lg-s10 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .625rem;
  }

  .gx-lg-s10 > .gx-child:last-of-type {
    padding-left: .625rem;
    padding-right: 0;
  }

  .m-lg-s12 {
    margin: .75rem;
  }

  .-m-lg-s12 {
    margin: -.75rem;
  }

  .mt-lg-s12 {
    margin-top: .75rem;
  }

  .-mt-lg-s12 {
    margin-top: -.75rem;
  }

  .mb-lg-s12 {
    margin-bottom: .75rem;
  }

  .-mb-lg-s12 {
    margin-bottom: -.75rem;
  }

  .mr-lg-s12 {
    margin-right: .75rem;
  }

  .-mr-lg-s12 {
    margin-right: -.75rem;
  }

  .ml-lg-s12 {
    margin-left: .75rem;
  }

  .-ml-lg-s12 {
    margin-left: -.75rem;
  }

  .p-lg-s12 {
    padding: .75rem;
  }

  .-p-lg-s12 {
    padding: -.75rem;
  }

  .pt-lg-s12 {
    padding-top: .75rem;
  }

  .-pt-lg-s12 {
    padding-top: -.75rem;
  }

  .pb-lg-s12 {
    padding-bottom: .75rem;
  }

  .-pb-lg-s12 {
    padding-bottom: -.75rem;
  }

  .pr-lg-s12 {
    padding-right: .75rem;
  }

  .-pr-lg-s12 {
    padding-right: -.75rem;
  }

  .pl-lg-s12 {
    padding-left: .75rem;
  }

  .-pl-lg-s12 {
    padding-left: -.75rem;
  }

  .mx-lg-s12 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .my-lg-s12 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .px-lg-s12 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .py-lg-s12 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .gap-lg-s12 {
    gap: .75rem;
  }

  .pm-lg-s12, .gx-lg-s12 > .gx-child {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .gx-lg-s12 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .75rem;
  }

  .gx-lg-s12 > .gx-child:last-of-type {
    padding-left: .75rem;
    padding-right: 0;
  }

  .m-lg-s13 {
    margin: .8125rem;
  }

  .-m-lg-s13 {
    margin: -.8125rem;
  }

  .mt-lg-s13 {
    margin-top: .8125rem;
  }

  .-mt-lg-s13 {
    margin-top: -.8125rem;
  }

  .mb-lg-s13 {
    margin-bottom: .8125rem;
  }

  .-mb-lg-s13 {
    margin-bottom: -.8125rem;
  }

  .mr-lg-s13 {
    margin-right: .8125rem;
  }

  .-mr-lg-s13 {
    margin-right: -.8125rem;
  }

  .ml-lg-s13 {
    margin-left: .8125rem;
  }

  .-ml-lg-s13 {
    margin-left: -.8125rem;
  }

  .p-lg-s13 {
    padding: .8125rem;
  }

  .-p-lg-s13 {
    padding: -.8125rem;
  }

  .pt-lg-s13 {
    padding-top: .8125rem;
  }

  .-pt-lg-s13 {
    padding-top: -.8125rem;
  }

  .pb-lg-s13 {
    padding-bottom: .8125rem;
  }

  .-pb-lg-s13 {
    padding-bottom: -.8125rem;
  }

  .pr-lg-s13 {
    padding-right: .8125rem;
  }

  .-pr-lg-s13 {
    padding-right: -.8125rem;
  }

  .pl-lg-s13 {
    padding-left: .8125rem;
  }

  .-pl-lg-s13 {
    padding-left: -.8125rem;
  }

  .mx-lg-s13 {
    margin-left: .8125rem;
    margin-right: .8125rem;
  }

  .my-lg-s13 {
    margin-top: .8125rem;
    margin-bottom: .8125rem;
  }

  .px-lg-s13 {
    padding-left: .8125rem;
    padding-right: .8125rem;
  }

  .py-lg-s13 {
    padding-top: .8125rem;
    padding-bottom: .8125rem;
  }

  .gap-lg-s13 {
    gap: .8125rem;
  }

  .pm-lg-s13, .gx-lg-s13 > .gx-child {
    padding-left: .40625rem;
    padding-right: .40625rem;
  }

  .gx-lg-s13 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .8125rem;
  }

  .gx-lg-s13 > .gx-child:last-of-type {
    padding-left: .8125rem;
    padding-right: 0;
  }

  .m-lg-s14 {
    margin: .875rem;
  }

  .-m-lg-s14 {
    margin: -.875rem;
  }

  .mt-lg-s14 {
    margin-top: .875rem;
  }

  .-mt-lg-s14 {
    margin-top: -.875rem;
  }

  .mb-lg-s14 {
    margin-bottom: .875rem;
  }

  .-mb-lg-s14 {
    margin-bottom: -.875rem;
  }

  .mr-lg-s14 {
    margin-right: .875rem;
  }

  .-mr-lg-s14 {
    margin-right: -.875rem;
  }

  .ml-lg-s14 {
    margin-left: .875rem;
  }

  .-ml-lg-s14 {
    margin-left: -.875rem;
  }

  .p-lg-s14 {
    padding: .875rem;
  }

  .-p-lg-s14 {
    padding: -.875rem;
  }

  .pt-lg-s14 {
    padding-top: .875rem;
  }

  .-pt-lg-s14 {
    padding-top: -.875rem;
  }

  .pb-lg-s14 {
    padding-bottom: .875rem;
  }

  .-pb-lg-s14 {
    padding-bottom: -.875rem;
  }

  .pr-lg-s14 {
    padding-right: .875rem;
  }

  .-pr-lg-s14 {
    padding-right: -.875rem;
  }

  .pl-lg-s14 {
    padding-left: .875rem;
  }

  .-pl-lg-s14 {
    padding-left: -.875rem;
  }

  .mx-lg-s14 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .my-lg-s14 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .px-lg-s14 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .py-lg-s14 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .gap-lg-s14 {
    gap: .875rem;
  }

  .pm-lg-s14, .gx-lg-s14 > .gx-child {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .gx-lg-s14 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .875rem;
  }

  .gx-lg-s14 > .gx-child:last-of-type {
    padding-left: .875rem;
    padding-right: 0;
  }

  .m-lg-s15 {
    margin: .9375rem;
  }

  .-m-lg-s15 {
    margin: -.9375rem;
  }

  .mt-lg-s15 {
    margin-top: .9375rem;
  }

  .-mt-lg-s15 {
    margin-top: -.9375rem;
  }

  .mb-lg-s15 {
    margin-bottom: .9375rem;
  }

  .-mb-lg-s15 {
    margin-bottom: -.9375rem;
  }

  .mr-lg-s15 {
    margin-right: .9375rem;
  }

  .-mr-lg-s15 {
    margin-right: -.9375rem;
  }

  .ml-lg-s15 {
    margin-left: .9375rem;
  }

  .-ml-lg-s15 {
    margin-left: -.9375rem;
  }

  .p-lg-s15 {
    padding: .9375rem;
  }

  .-p-lg-s15 {
    padding: -.9375rem;
  }

  .pt-lg-s15 {
    padding-top: .9375rem;
  }

  .-pt-lg-s15 {
    padding-top: -.9375rem;
  }

  .pb-lg-s15 {
    padding-bottom: .9375rem;
  }

  .-pb-lg-s15 {
    padding-bottom: -.9375rem;
  }

  .pr-lg-s15 {
    padding-right: .9375rem;
  }

  .-pr-lg-s15 {
    padding-right: -.9375rem;
  }

  .pl-lg-s15 {
    padding-left: .9375rem;
  }

  .-pl-lg-s15 {
    padding-left: -.9375rem;
  }

  .mx-lg-s15 {
    margin-left: .9375rem;
    margin-right: .9375rem;
  }

  .my-lg-s15 {
    margin-top: .9375rem;
    margin-bottom: .9375rem;
  }

  .px-lg-s15 {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .py-lg-s15 {
    padding-top: .9375rem;
    padding-bottom: .9375rem;
  }

  .gap-lg-s15 {
    gap: .9375rem;
  }

  .pm-lg-s15, .gx-lg-s15 > .gx-child {
    padding-left: .46875rem;
    padding-right: .46875rem;
  }

  .gx-lg-s15 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .9375rem;
  }

  .gx-lg-s15 > .gx-child:last-of-type {
    padding-left: .9375rem;
    padding-right: 0;
  }

  .m-lg-s16 {
    margin: 1rem;
  }

  .-m-lg-s16 {
    margin: -1rem;
  }

  .mt-lg-s16 {
    margin-top: 1rem;
  }

  .-mt-lg-s16 {
    margin-top: -1rem;
  }

  .mb-lg-s16 {
    margin-bottom: 1rem;
  }

  .-mb-lg-s16 {
    margin-bottom: -1rem;
  }

  .mr-lg-s16 {
    margin-right: 1rem;
  }

  .-mr-lg-s16 {
    margin-right: -1rem;
  }

  .ml-lg-s16 {
    margin-left: 1rem;
  }

  .-ml-lg-s16 {
    margin-left: -1rem;
  }

  .p-lg-s16 {
    padding: 1rem;
  }

  .-p-lg-s16 {
    padding: -1rem;
  }

  .pt-lg-s16 {
    padding-top: 1rem;
  }

  .-pt-lg-s16 {
    padding-top: -1rem;
  }

  .pb-lg-s16 {
    padding-bottom: 1rem;
  }

  .-pb-lg-s16 {
    padding-bottom: -1rem;
  }

  .pr-lg-s16 {
    padding-right: 1rem;
  }

  .-pr-lg-s16 {
    padding-right: -1rem;
  }

  .pl-lg-s16 {
    padding-left: 1rem;
  }

  .-pl-lg-s16 {
    padding-left: -1rem;
  }

  .mx-lg-s16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .my-lg-s16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .px-lg-s16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .py-lg-s16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .gap-lg-s16 {
    gap: 1rem;
  }

  .pm-lg-s16, .gx-lg-s16 > .gx-child {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .gx-lg-s16 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1rem;
  }

  .gx-lg-s16 > .gx-child:last-of-type {
    padding-left: 1rem;
    padding-right: 0;
  }

  .m-lg-s18 {
    margin: 1.125rem;
  }

  .-m-lg-s18 {
    margin: -1.125rem;
  }

  .mt-lg-s18 {
    margin-top: 1.125rem;
  }

  .-mt-lg-s18 {
    margin-top: -1.125rem;
  }

  .mb-lg-s18 {
    margin-bottom: 1.125rem;
  }

  .-mb-lg-s18 {
    margin-bottom: -1.125rem;
  }

  .mr-lg-s18 {
    margin-right: 1.125rem;
  }

  .-mr-lg-s18 {
    margin-right: -1.125rem;
  }

  .ml-lg-s18 {
    margin-left: 1.125rem;
  }

  .-ml-lg-s18 {
    margin-left: -1.125rem;
  }

  .p-lg-s18 {
    padding: 1.125rem;
  }

  .-p-lg-s18 {
    padding: -1.125rem;
  }

  .pt-lg-s18 {
    padding-top: 1.125rem;
  }

  .-pt-lg-s18 {
    padding-top: -1.125rem;
  }

  .pb-lg-s18 {
    padding-bottom: 1.125rem;
  }

  .-pb-lg-s18 {
    padding-bottom: -1.125rem;
  }

  .pr-lg-s18 {
    padding-right: 1.125rem;
  }

  .-pr-lg-s18 {
    padding-right: -1.125rem;
  }

  .pl-lg-s18 {
    padding-left: 1.125rem;
  }

  .-pl-lg-s18 {
    padding-left: -1.125rem;
  }

  .mx-lg-s18 {
    margin-left: 1.125rem;
    margin-right: 1.125rem;
  }

  .my-lg-s18 {
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
  }

  .px-lg-s18 {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .py-lg-s18 {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }

  .gap-lg-s18 {
    gap: 1.125rem;
  }

  .pm-lg-s18, .gx-lg-s18 > .gx-child {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .gx-lg-s18 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.125rem;
  }

  .gx-lg-s18 > .gx-child:last-of-type {
    padding-left: 1.125rem;
    padding-right: 0;
  }

  .m-lg-s20 {
    margin: 1.25rem;
  }

  .-m-lg-s20 {
    margin: -1.25rem;
  }

  .mt-lg-s20 {
    margin-top: 1.25rem;
  }

  .-mt-lg-s20 {
    margin-top: -1.25rem;
  }

  .mb-lg-s20 {
    margin-bottom: 1.25rem;
  }

  .-mb-lg-s20 {
    margin-bottom: -1.25rem;
  }

  .mr-lg-s20 {
    margin-right: 1.25rem;
  }

  .-mr-lg-s20 {
    margin-right: -1.25rem;
  }

  .ml-lg-s20 {
    margin-left: 1.25rem;
  }

  .-ml-lg-s20 {
    margin-left: -1.25rem;
  }

  .p-lg-s20 {
    padding: 1.25rem;
  }

  .-p-lg-s20 {
    padding: -1.25rem;
  }

  .pt-lg-s20 {
    padding-top: 1.25rem;
  }

  .-pt-lg-s20 {
    padding-top: -1.25rem;
  }

  .pb-lg-s20 {
    padding-bottom: 1.25rem;
  }

  .-pb-lg-s20 {
    padding-bottom: -1.25rem;
  }

  .pr-lg-s20 {
    padding-right: 1.25rem;
  }

  .-pr-lg-s20 {
    padding-right: -1.25rem;
  }

  .pl-lg-s20 {
    padding-left: 1.25rem;
  }

  .-pl-lg-s20 {
    padding-left: -1.25rem;
  }

  .mx-lg-s20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .my-lg-s20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .px-lg-s20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .py-lg-s20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .gap-lg-s20 {
    gap: 1.25rem;
  }

  .pm-lg-s20, .gx-lg-s20 > .gx-child {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .gx-lg-s20 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.25rem;
  }

  .gx-lg-s20 > .gx-child:last-of-type {
    padding-left: 1.25rem;
    padding-right: 0;
  }

  .m-lg-s22 {
    margin: 1.375rem;
  }

  .-m-lg-s22 {
    margin: -1.375rem;
  }

  .mt-lg-s22 {
    margin-top: 1.375rem;
  }

  .-mt-lg-s22 {
    margin-top: -1.375rem;
  }

  .mb-lg-s22 {
    margin-bottom: 1.375rem;
  }

  .-mb-lg-s22 {
    margin-bottom: -1.375rem;
  }

  .mr-lg-s22 {
    margin-right: 1.375rem;
  }

  .-mr-lg-s22 {
    margin-right: -1.375rem;
  }

  .ml-lg-s22 {
    margin-left: 1.375rem;
  }

  .-ml-lg-s22 {
    margin-left: -1.375rem;
  }

  .p-lg-s22 {
    padding: 1.375rem;
  }

  .-p-lg-s22 {
    padding: -1.375rem;
  }

  .pt-lg-s22 {
    padding-top: 1.375rem;
  }

  .-pt-lg-s22 {
    padding-top: -1.375rem;
  }

  .pb-lg-s22 {
    padding-bottom: 1.375rem;
  }

  .-pb-lg-s22 {
    padding-bottom: -1.375rem;
  }

  .pr-lg-s22 {
    padding-right: 1.375rem;
  }

  .-pr-lg-s22 {
    padding-right: -1.375rem;
  }

  .pl-lg-s22 {
    padding-left: 1.375rem;
  }

  .-pl-lg-s22 {
    padding-left: -1.375rem;
  }

  .mx-lg-s22 {
    margin-left: 1.375rem;
    margin-right: 1.375rem;
  }

  .my-lg-s22 {
    margin-top: 1.375rem;
    margin-bottom: 1.375rem;
  }

  .px-lg-s22 {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }

  .py-lg-s22 {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
  }

  .gap-lg-s22 {
    gap: 1.375rem;
  }

  .pm-lg-s22, .gx-lg-s22 > .gx-child {
    padding-left: .6875rem;
    padding-right: .6875rem;
  }

  .gx-lg-s22 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.375rem;
  }

  .gx-lg-s22 > .gx-child:last-of-type {
    padding-left: 1.375rem;
    padding-right: 0;
  }

  .m-lg-s24 {
    margin: 1.5rem;
  }

  .-m-lg-s24 {
    margin: -1.5rem;
  }

  .mt-lg-s24 {
    margin-top: 1.5rem;
  }

  .-mt-lg-s24 {
    margin-top: -1.5rem;
  }

  .mb-lg-s24 {
    margin-bottom: 1.5rem;
  }

  .-mb-lg-s24 {
    margin-bottom: -1.5rem;
  }

  .mr-lg-s24 {
    margin-right: 1.5rem;
  }

  .-mr-lg-s24 {
    margin-right: -1.5rem;
  }

  .ml-lg-s24 {
    margin-left: 1.5rem;
  }

  .-ml-lg-s24 {
    margin-left: -1.5rem;
  }

  .p-lg-s24 {
    padding: 1.5rem;
  }

  .-p-lg-s24 {
    padding: -1.5rem;
  }

  .pt-lg-s24 {
    padding-top: 1.5rem;
  }

  .-pt-lg-s24 {
    padding-top: -1.5rem;
  }

  .pb-lg-s24 {
    padding-bottom: 1.5rem;
  }

  .-pb-lg-s24 {
    padding-bottom: -1.5rem;
  }

  .pr-lg-s24 {
    padding-right: 1.5rem;
  }

  .-pr-lg-s24 {
    padding-right: -1.5rem;
  }

  .pl-lg-s24 {
    padding-left: 1.5rem;
  }

  .-pl-lg-s24 {
    padding-left: -1.5rem;
  }

  .mx-lg-s24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .my-lg-s24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .px-lg-s24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .py-lg-s24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .gap-lg-s24 {
    gap: 1.5rem;
  }

  .pm-lg-s24, .gx-lg-s24 > .gx-child {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .gx-lg-s24 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5rem;
  }

  .gx-lg-s24 > .gx-child:last-of-type {
    padding-left: 1.5rem;
    padding-right: 0;
  }

  .m-lg-s25 {
    margin: 1.5625rem;
  }

  .-m-lg-s25 {
    margin: -1.5625rem;
  }

  .mt-lg-s25 {
    margin-top: 1.5625rem;
  }

  .-mt-lg-s25 {
    margin-top: -1.5625rem;
  }

  .mb-lg-s25 {
    margin-bottom: 1.5625rem;
  }

  .-mb-lg-s25 {
    margin-bottom: -1.5625rem;
  }

  .mr-lg-s25 {
    margin-right: 1.5625rem;
  }

  .-mr-lg-s25 {
    margin-right: -1.5625rem;
  }

  .ml-lg-s25 {
    margin-left: 1.5625rem;
  }

  .-ml-lg-s25 {
    margin-left: -1.5625rem;
  }

  .p-lg-s25 {
    padding: 1.5625rem;
  }

  .-p-lg-s25 {
    padding: -1.5625rem;
  }

  .pt-lg-s25 {
    padding-top: 1.5625rem;
  }

  .-pt-lg-s25 {
    padding-top: -1.5625rem;
  }

  .pb-lg-s25 {
    padding-bottom: 1.5625rem;
  }

  .-pb-lg-s25 {
    padding-bottom: -1.5625rem;
  }

  .pr-lg-s25 {
    padding-right: 1.5625rem;
  }

  .-pr-lg-s25 {
    padding-right: -1.5625rem;
  }

  .pl-lg-s25 {
    padding-left: 1.5625rem;
  }

  .-pl-lg-s25 {
    padding-left: -1.5625rem;
  }

  .mx-lg-s25 {
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
  }

  .my-lg-s25 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }

  .px-lg-s25 {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .py-lg-s25 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }

  .gap-lg-s25 {
    gap: 1.5625rem;
  }

  .pm-lg-s25, .gx-lg-s25 > .gx-child {
    padding-left: .78125rem;
    padding-right: .78125rem;
  }

  .gx-lg-s25 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5625rem;
  }

  .gx-lg-s25 > .gx-child:last-of-type {
    padding-left: 1.5625rem;
    padding-right: 0;
  }

  .m-lg-s27 {
    margin: 1.6875rem;
  }

  .-m-lg-s27 {
    margin: -1.6875rem;
  }

  .mt-lg-s27 {
    margin-top: 1.6875rem;
  }

  .-mt-lg-s27 {
    margin-top: -1.6875rem;
  }

  .mb-lg-s27 {
    margin-bottom: 1.6875rem;
  }

  .-mb-lg-s27 {
    margin-bottom: -1.6875rem;
  }

  .mr-lg-s27 {
    margin-right: 1.6875rem;
  }

  .-mr-lg-s27 {
    margin-right: -1.6875rem;
  }

  .ml-lg-s27 {
    margin-left: 1.6875rem;
  }

  .-ml-lg-s27 {
    margin-left: -1.6875rem;
  }

  .p-lg-s27 {
    padding: 1.6875rem;
  }

  .-p-lg-s27 {
    padding: -1.6875rem;
  }

  .pt-lg-s27 {
    padding-top: 1.6875rem;
  }

  .-pt-lg-s27 {
    padding-top: -1.6875rem;
  }

  .pb-lg-s27 {
    padding-bottom: 1.6875rem;
  }

  .-pb-lg-s27 {
    padding-bottom: -1.6875rem;
  }

  .pr-lg-s27 {
    padding-right: 1.6875rem;
  }

  .-pr-lg-s27 {
    padding-right: -1.6875rem;
  }

  .pl-lg-s27 {
    padding-left: 1.6875rem;
  }

  .-pl-lg-s27 {
    padding-left: -1.6875rem;
  }

  .mx-lg-s27 {
    margin-left: 1.6875rem;
    margin-right: 1.6875rem;
  }

  .my-lg-s27 {
    margin-top: 1.6875rem;
    margin-bottom: 1.6875rem;
  }

  .px-lg-s27 {
    padding-left: 1.6875rem;
    padding-right: 1.6875rem;
  }

  .py-lg-s27 {
    padding-top: 1.6875rem;
    padding-bottom: 1.6875rem;
  }

  .gap-lg-s27 {
    gap: 1.6875rem;
  }

  .pm-lg-s27, .gx-lg-s27 > .gx-child {
    padding-left: .84375rem;
    padding-right: .84375rem;
  }

  .gx-lg-s27 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.6875rem;
  }

  .gx-lg-s27 > .gx-child:last-of-type {
    padding-left: 1.6875rem;
    padding-right: 0;
  }

  .m-lg-s29 {
    margin: 1.8125rem;
  }

  .-m-lg-s29 {
    margin: -1.8125rem;
  }

  .mt-lg-s29 {
    margin-top: 1.8125rem;
  }

  .-mt-lg-s29 {
    margin-top: -1.8125rem;
  }

  .mb-lg-s29 {
    margin-bottom: 1.8125rem;
  }

  .-mb-lg-s29 {
    margin-bottom: -1.8125rem;
  }

  .mr-lg-s29 {
    margin-right: 1.8125rem;
  }

  .-mr-lg-s29 {
    margin-right: -1.8125rem;
  }

  .ml-lg-s29 {
    margin-left: 1.8125rem;
  }

  .-ml-lg-s29 {
    margin-left: -1.8125rem;
  }

  .p-lg-s29 {
    padding: 1.8125rem;
  }

  .-p-lg-s29 {
    padding: -1.8125rem;
  }

  .pt-lg-s29 {
    padding-top: 1.8125rem;
  }

  .-pt-lg-s29 {
    padding-top: -1.8125rem;
  }

  .pb-lg-s29 {
    padding-bottom: 1.8125rem;
  }

  .-pb-lg-s29 {
    padding-bottom: -1.8125rem;
  }

  .pr-lg-s29 {
    padding-right: 1.8125rem;
  }

  .-pr-lg-s29 {
    padding-right: -1.8125rem;
  }

  .pl-lg-s29 {
    padding-left: 1.8125rem;
  }

  .-pl-lg-s29 {
    padding-left: -1.8125rem;
  }

  .mx-lg-s29 {
    margin-left: 1.8125rem;
    margin-right: 1.8125rem;
  }

  .my-lg-s29 {
    margin-top: 1.8125rem;
    margin-bottom: 1.8125rem;
  }

  .px-lg-s29 {
    padding-left: 1.8125rem;
    padding-right: 1.8125rem;
  }

  .py-lg-s29 {
    padding-top: 1.8125rem;
    padding-bottom: 1.8125rem;
  }

  .gap-lg-s29 {
    gap: 1.8125rem;
  }

  .pm-lg-s29, .gx-lg-s29 > .gx-child {
    padding-left: .90625rem;
    padding-right: .90625rem;
  }

  .gx-lg-s29 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.8125rem;
  }

  .gx-lg-s29 > .gx-child:last-of-type {
    padding-left: 1.8125rem;
    padding-right: 0;
  }

  .m-lg-s30 {
    margin: 1.875rem;
  }

  .-m-lg-s30 {
    margin: -1.875rem;
  }

  .mt-lg-s30 {
    margin-top: 1.875rem;
  }

  .-mt-lg-s30 {
    margin-top: -1.875rem;
  }

  .mb-lg-s30 {
    margin-bottom: 1.875rem;
  }

  .-mb-lg-s30 {
    margin-bottom: -1.875rem;
  }

  .mr-lg-s30 {
    margin-right: 1.875rem;
  }

  .-mr-lg-s30 {
    margin-right: -1.875rem;
  }

  .ml-lg-s30 {
    margin-left: 1.875rem;
  }

  .-ml-lg-s30 {
    margin-left: -1.875rem;
  }

  .p-lg-s30 {
    padding: 1.875rem;
  }

  .-p-lg-s30 {
    padding: -1.875rem;
  }

  .pt-lg-s30 {
    padding-top: 1.875rem;
  }

  .-pt-lg-s30 {
    padding-top: -1.875rem;
  }

  .pb-lg-s30 {
    padding-bottom: 1.875rem;
  }

  .-pb-lg-s30 {
    padding-bottom: -1.875rem;
  }

  .pr-lg-s30 {
    padding-right: 1.875rem;
  }

  .-pr-lg-s30 {
    padding-right: -1.875rem;
  }

  .pl-lg-s30 {
    padding-left: 1.875rem;
  }

  .-pl-lg-s30 {
    padding-left: -1.875rem;
  }

  .mx-lg-s30 {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }

  .my-lg-s30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .px-lg-s30 {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .py-lg-s30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .gap-lg-s30 {
    gap: 1.875rem;
  }

  .pm-lg-s30, .gx-lg-s30 > .gx-child {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .gx-lg-s30 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.875rem;
  }

  .gx-lg-s30 > .gx-child:last-of-type {
    padding-left: 1.875rem;
    padding-right: 0;
  }

  .m-lg-s32 {
    margin: 2rem;
  }

  .-m-lg-s32 {
    margin: -2rem;
  }

  .mt-lg-s32 {
    margin-top: 2rem;
  }

  .-mt-lg-s32 {
    margin-top: -2rem;
  }

  .mb-lg-s32 {
    margin-bottom: 2rem;
  }

  .-mb-lg-s32 {
    margin-bottom: -2rem;
  }

  .mr-lg-s32 {
    margin-right: 2rem;
  }

  .-mr-lg-s32 {
    margin-right: -2rem;
  }

  .ml-lg-s32 {
    margin-left: 2rem;
  }

  .-ml-lg-s32 {
    margin-left: -2rem;
  }

  .p-lg-s32 {
    padding: 2rem;
  }

  .-p-lg-s32 {
    padding: -2rem;
  }

  .pt-lg-s32 {
    padding-top: 2rem;
  }

  .-pt-lg-s32 {
    padding-top: -2rem;
  }

  .pb-lg-s32 {
    padding-bottom: 2rem;
  }

  .-pb-lg-s32 {
    padding-bottom: -2rem;
  }

  .pr-lg-s32 {
    padding-right: 2rem;
  }

  .-pr-lg-s32 {
    padding-right: -2rem;
  }

  .pl-lg-s32 {
    padding-left: 2rem;
  }

  .-pl-lg-s32 {
    padding-left: -2rem;
  }

  .mx-lg-s32 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .my-lg-s32 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .px-lg-s32 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .py-lg-s32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .gap-lg-s32 {
    gap: 2rem;
  }

  .pm-lg-s32, .gx-lg-s32 > .gx-child {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .gx-lg-s32 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2rem;
  }

  .gx-lg-s32 > .gx-child:last-of-type {
    padding-left: 2rem;
    padding-right: 0;
  }

  .m-lg-s35 {
    margin: 2.1875rem;
  }

  .-m-lg-s35 {
    margin: -2.1875rem;
  }

  .mt-lg-s35 {
    margin-top: 2.1875rem;
  }

  .-mt-lg-s35 {
    margin-top: -2.1875rem;
  }

  .mb-lg-s35 {
    margin-bottom: 2.1875rem;
  }

  .-mb-lg-s35 {
    margin-bottom: -2.1875rem;
  }

  .mr-lg-s35 {
    margin-right: 2.1875rem;
  }

  .-mr-lg-s35 {
    margin-right: -2.1875rem;
  }

  .ml-lg-s35 {
    margin-left: 2.1875rem;
  }

  .-ml-lg-s35 {
    margin-left: -2.1875rem;
  }

  .p-lg-s35 {
    padding: 2.1875rem;
  }

  .-p-lg-s35 {
    padding: -2.1875rem;
  }

  .pt-lg-s35 {
    padding-top: 2.1875rem;
  }

  .-pt-lg-s35 {
    padding-top: -2.1875rem;
  }

  .pb-lg-s35 {
    padding-bottom: 2.1875rem;
  }

  .-pb-lg-s35 {
    padding-bottom: -2.1875rem;
  }

  .pr-lg-s35 {
    padding-right: 2.1875rem;
  }

  .-pr-lg-s35 {
    padding-right: -2.1875rem;
  }

  .pl-lg-s35 {
    padding-left: 2.1875rem;
  }

  .-pl-lg-s35 {
    padding-left: -2.1875rem;
  }

  .mx-lg-s35 {
    margin-left: 2.1875rem;
    margin-right: 2.1875rem;
  }

  .my-lg-s35 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }

  .px-lg-s35 {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .py-lg-s35 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }

  .gap-lg-s35 {
    gap: 2.1875rem;
  }

  .pm-lg-s35, .gx-lg-s35 > .gx-child {
    padding-left: 1.09375rem;
    padding-right: 1.09375rem;
  }

  .gx-lg-s35 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.1875rem;
  }

  .gx-lg-s35 > .gx-child:last-of-type {
    padding-left: 2.1875rem;
    padding-right: 0;
  }

  .m-lg-s40 {
    margin: 2.5rem;
  }

  .-m-lg-s40 {
    margin: -2.5rem;
  }

  .mt-lg-s40 {
    margin-top: 2.5rem;
  }

  .-mt-lg-s40 {
    margin-top: -2.5rem;
  }

  .mb-lg-s40 {
    margin-bottom: 2.5rem;
  }

  .-mb-lg-s40 {
    margin-bottom: -2.5rem;
  }

  .mr-lg-s40 {
    margin-right: 2.5rem;
  }

  .-mr-lg-s40 {
    margin-right: -2.5rem;
  }

  .ml-lg-s40 {
    margin-left: 2.5rem;
  }

  .-ml-lg-s40 {
    margin-left: -2.5rem;
  }

  .p-lg-s40 {
    padding: 2.5rem;
  }

  .-p-lg-s40 {
    padding: -2.5rem;
  }

  .pt-lg-s40 {
    padding-top: 2.5rem;
  }

  .-pt-lg-s40 {
    padding-top: -2.5rem;
  }

  .pb-lg-s40 {
    padding-bottom: 2.5rem;
  }

  .-pb-lg-s40 {
    padding-bottom: -2.5rem;
  }

  .pr-lg-s40 {
    padding-right: 2.5rem;
  }

  .-pr-lg-s40 {
    padding-right: -2.5rem;
  }

  .pl-lg-s40 {
    padding-left: 2.5rem;
  }

  .-pl-lg-s40 {
    padding-left: -2.5rem;
  }

  .mx-lg-s40 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .my-lg-s40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .px-lg-s40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .py-lg-s40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .gap-lg-s40 {
    gap: 2.5rem;
  }

  .pm-lg-s40, .gx-lg-s40 > .gx-child {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .gx-lg-s40 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.5rem;
  }

  .gx-lg-s40 > .gx-child:last-of-type {
    padding-left: 2.5rem;
    padding-right: 0;
  }

  .m-lg-s45 {
    margin: 2.8125rem;
  }

  .-m-lg-s45 {
    margin: -2.8125rem;
  }

  .mt-lg-s45 {
    margin-top: 2.8125rem;
  }

  .-mt-lg-s45 {
    margin-top: -2.8125rem;
  }

  .mb-lg-s45 {
    margin-bottom: 2.8125rem;
  }

  .-mb-lg-s45 {
    margin-bottom: -2.8125rem;
  }

  .mr-lg-s45 {
    margin-right: 2.8125rem;
  }

  .-mr-lg-s45 {
    margin-right: -2.8125rem;
  }

  .ml-lg-s45 {
    margin-left: 2.8125rem;
  }

  .-ml-lg-s45 {
    margin-left: -2.8125rem;
  }

  .p-lg-s45 {
    padding: 2.8125rem;
  }

  .-p-lg-s45 {
    padding: -2.8125rem;
  }

  .pt-lg-s45 {
    padding-top: 2.8125rem;
  }

  .-pt-lg-s45 {
    padding-top: -2.8125rem;
  }

  .pb-lg-s45 {
    padding-bottom: 2.8125rem;
  }

  .-pb-lg-s45 {
    padding-bottom: -2.8125rem;
  }

  .pr-lg-s45 {
    padding-right: 2.8125rem;
  }

  .-pr-lg-s45 {
    padding-right: -2.8125rem;
  }

  .pl-lg-s45 {
    padding-left: 2.8125rem;
  }

  .-pl-lg-s45 {
    padding-left: -2.8125rem;
  }

  .mx-lg-s45 {
    margin-left: 2.8125rem;
    margin-right: 2.8125rem;
  }

  .my-lg-s45 {
    margin-top: 2.8125rem;
    margin-bottom: 2.8125rem;
  }

  .px-lg-s45 {
    padding-left: 2.8125rem;
    padding-right: 2.8125rem;
  }

  .py-lg-s45 {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }

  .gap-lg-s45 {
    gap: 2.8125rem;
  }

  .pm-lg-s45, .gx-lg-s45 > .gx-child {
    padding-left: 1.40625rem;
    padding-right: 1.40625rem;
  }

  .gx-lg-s45 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.8125rem;
  }

  .gx-lg-s45 > .gx-child:last-of-type {
    padding-left: 2.8125rem;
    padding-right: 0;
  }

  .m-lg-s48 {
    margin: 3rem;
  }

  .-m-lg-s48 {
    margin: -3rem;
  }

  .mt-lg-s48 {
    margin-top: 3rem;
  }

  .-mt-lg-s48 {
    margin-top: -3rem;
  }

  .mb-lg-s48 {
    margin-bottom: 3rem;
  }

  .-mb-lg-s48 {
    margin-bottom: -3rem;
  }

  .mr-lg-s48 {
    margin-right: 3rem;
  }

  .-mr-lg-s48 {
    margin-right: -3rem;
  }

  .ml-lg-s48 {
    margin-left: 3rem;
  }

  .-ml-lg-s48 {
    margin-left: -3rem;
  }

  .p-lg-s48 {
    padding: 3rem;
  }

  .-p-lg-s48 {
    padding: -3rem;
  }

  .pt-lg-s48 {
    padding-top: 3rem;
  }

  .-pt-lg-s48 {
    padding-top: -3rem;
  }

  .pb-lg-s48 {
    padding-bottom: 3rem;
  }

  .-pb-lg-s48 {
    padding-bottom: -3rem;
  }

  .pr-lg-s48 {
    padding-right: 3rem;
  }

  .-pr-lg-s48 {
    padding-right: -3rem;
  }

  .pl-lg-s48 {
    padding-left: 3rem;
  }

  .-pl-lg-s48 {
    padding-left: -3rem;
  }

  .mx-lg-s48 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .my-lg-s48 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .px-lg-s48 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .py-lg-s48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .gap-lg-s48 {
    gap: 3rem;
  }

  .pm-lg-s48, .gx-lg-s48 > .gx-child {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .gx-lg-s48 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3rem;
  }

  .gx-lg-s48 > .gx-child:last-of-type {
    padding-left: 3rem;
    padding-right: 0;
  }

  .m-lg-s50 {
    margin: 3.125rem;
  }

  .-m-lg-s50 {
    margin: -3.125rem;
  }

  .mt-lg-s50 {
    margin-top: 3.125rem;
  }

  .-mt-lg-s50 {
    margin-top: -3.125rem;
  }

  .mb-lg-s50 {
    margin-bottom: 3.125rem;
  }

  .-mb-lg-s50 {
    margin-bottom: -3.125rem;
  }

  .mr-lg-s50 {
    margin-right: 3.125rem;
  }

  .-mr-lg-s50 {
    margin-right: -3.125rem;
  }

  .ml-lg-s50 {
    margin-left: 3.125rem;
  }

  .-ml-lg-s50 {
    margin-left: -3.125rem;
  }

  .p-lg-s50 {
    padding: 3.125rem;
  }

  .-p-lg-s50 {
    padding: -3.125rem;
  }

  .pt-lg-s50 {
    padding-top: 3.125rem;
  }

  .-pt-lg-s50 {
    padding-top: -3.125rem;
  }

  .pb-lg-s50 {
    padding-bottom: 3.125rem;
  }

  .-pb-lg-s50 {
    padding-bottom: -3.125rem;
  }

  .pr-lg-s50 {
    padding-right: 3.125rem;
  }

  .-pr-lg-s50 {
    padding-right: -3.125rem;
  }

  .pl-lg-s50 {
    padding-left: 3.125rem;
  }

  .-pl-lg-s50 {
    padding-left: -3.125rem;
  }

  .mx-lg-s50 {
    margin-left: 3.125rem;
    margin-right: 3.125rem;
  }

  .my-lg-s50 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }

  .px-lg-s50 {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .py-lg-s50 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }

  .gap-lg-s50 {
    gap: 3.125rem;
  }

  .pm-lg-s50, .gx-lg-s50 > .gx-child {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .gx-lg-s50 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.125rem;
  }

  .gx-lg-s50 > .gx-child:last-of-type {
    padding-left: 3.125rem;
    padding-right: 0;
  }

  .m-lg-s60 {
    margin: 3.75rem;
  }

  .-m-lg-s60 {
    margin: -3.75rem;
  }

  .mt-lg-s60 {
    margin-top: 3.75rem;
  }

  .-mt-lg-s60 {
    margin-top: -3.75rem;
  }

  .mb-lg-s60 {
    margin-bottom: 3.75rem;
  }

  .-mb-lg-s60 {
    margin-bottom: -3.75rem;
  }

  .mr-lg-s60 {
    margin-right: 3.75rem;
  }

  .-mr-lg-s60 {
    margin-right: -3.75rem;
  }

  .ml-lg-s60 {
    margin-left: 3.75rem;
  }

  .-ml-lg-s60 {
    margin-left: -3.75rem;
  }

  .p-lg-s60 {
    padding: 3.75rem;
  }

  .-p-lg-s60 {
    padding: -3.75rem;
  }

  .pt-lg-s60 {
    padding-top: 3.75rem;
  }

  .-pt-lg-s60 {
    padding-top: -3.75rem;
  }

  .pb-lg-s60 {
    padding-bottom: 3.75rem;
  }

  .-pb-lg-s60 {
    padding-bottom: -3.75rem;
  }

  .pr-lg-s60 {
    padding-right: 3.75rem;
  }

  .-pr-lg-s60 {
    padding-right: -3.75rem;
  }

  .pl-lg-s60 {
    padding-left: 3.75rem;
  }

  .-pl-lg-s60 {
    padding-left: -3.75rem;
  }

  .mx-lg-s60 {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
  }

  .my-lg-s60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .px-lg-s60 {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .py-lg-s60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  .gap-lg-s60 {
    gap: 3.75rem;
  }

  .pm-lg-s60, .gx-lg-s60 > .gx-child {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .gx-lg-s60 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.75rem;
  }

  .gx-lg-s60 > .gx-child:last-of-type {
    padding-left: 3.75rem;
    padding-right: 0;
  }

  .m-lg-s70 {
    margin: 4.375rem;
  }

  .-m-lg-s70 {
    margin: -4.375rem;
  }

  .mt-lg-s70 {
    margin-top: 4.375rem;
  }

  .-mt-lg-s70 {
    margin-top: -4.375rem;
  }

  .mb-lg-s70 {
    margin-bottom: 4.375rem;
  }

  .-mb-lg-s70 {
    margin-bottom: -4.375rem;
  }

  .mr-lg-s70 {
    margin-right: 4.375rem;
  }

  .-mr-lg-s70 {
    margin-right: -4.375rem;
  }

  .ml-lg-s70 {
    margin-left: 4.375rem;
  }

  .-ml-lg-s70 {
    margin-left: -4.375rem;
  }

  .p-lg-s70 {
    padding: 4.375rem;
  }

  .-p-lg-s70 {
    padding: -4.375rem;
  }

  .pt-lg-s70 {
    padding-top: 4.375rem;
  }

  .-pt-lg-s70 {
    padding-top: -4.375rem;
  }

  .pb-lg-s70 {
    padding-bottom: 4.375rem;
  }

  .-pb-lg-s70 {
    padding-bottom: -4.375rem;
  }

  .pr-lg-s70 {
    padding-right: 4.375rem;
  }

  .-pr-lg-s70 {
    padding-right: -4.375rem;
  }

  .pl-lg-s70 {
    padding-left: 4.375rem;
  }

  .-pl-lg-s70 {
    padding-left: -4.375rem;
  }

  .mx-lg-s70 {
    margin-left: 4.375rem;
    margin-right: 4.375rem;
  }

  .my-lg-s70 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }

  .px-lg-s70 {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }

  .py-lg-s70 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }

  .gap-lg-s70 {
    gap: 4.375rem;
  }

  .pm-lg-s70, .gx-lg-s70 > .gx-child {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .gx-lg-s70 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 4.375rem;
  }

  .gx-lg-s70 > .gx-child:last-of-type {
    padding-left: 4.375rem;
    padding-right: 0;
  }

  .m-lg-s80 {
    margin: 5rem;
  }

  .-m-lg-s80 {
    margin: -5rem;
  }

  .mt-lg-s80 {
    margin-top: 5rem;
  }

  .-mt-lg-s80 {
    margin-top: -5rem;
  }

  .mb-lg-s80 {
    margin-bottom: 5rem;
  }

  .-mb-lg-s80 {
    margin-bottom: -5rem;
  }

  .mr-lg-s80 {
    margin-right: 5rem;
  }

  .-mr-lg-s80 {
    margin-right: -5rem;
  }

  .ml-lg-s80 {
    margin-left: 5rem;
  }

  .-ml-lg-s80 {
    margin-left: -5rem;
  }

  .p-lg-s80 {
    padding: 5rem;
  }

  .-p-lg-s80 {
    padding: -5rem;
  }

  .pt-lg-s80 {
    padding-top: 5rem;
  }

  .-pt-lg-s80 {
    padding-top: -5rem;
  }

  .pb-lg-s80 {
    padding-bottom: 5rem;
  }

  .-pb-lg-s80 {
    padding-bottom: -5rem;
  }

  .pr-lg-s80 {
    padding-right: 5rem;
  }

  .-pr-lg-s80 {
    padding-right: -5rem;
  }

  .pl-lg-s80 {
    padding-left: 5rem;
  }

  .-pl-lg-s80 {
    padding-left: -5rem;
  }

  .mx-lg-s80 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .my-lg-s80 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .px-lg-s80 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .py-lg-s80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .gap-lg-s80 {
    gap: 5rem;
  }

  .pm-lg-s80, .gx-lg-s80 > .gx-child {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .gx-lg-s80 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 5rem;
  }

  .gx-lg-s80 > .gx-child:last-of-type {
    padding-left: 5rem;
    padding-right: 0;
  }

  .m-lg-s100 {
    margin: 6.25rem;
  }

  .-m-lg-s100 {
    margin: -6.25rem;
  }

  .mt-lg-s100 {
    margin-top: 6.25rem;
  }

  .-mt-lg-s100 {
    margin-top: -6.25rem;
  }

  .mb-lg-s100 {
    margin-bottom: 6.25rem;
  }

  .-mb-lg-s100 {
    margin-bottom: -6.25rem;
  }

  .mr-lg-s100 {
    margin-right: 6.25rem;
  }

  .-mr-lg-s100 {
    margin-right: -6.25rem;
  }

  .ml-lg-s100 {
    margin-left: 6.25rem;
  }

  .-ml-lg-s100 {
    margin-left: -6.25rem;
  }

  .p-lg-s100 {
    padding: 6.25rem;
  }

  .-p-lg-s100 {
    padding: -6.25rem;
  }

  .pt-lg-s100 {
    padding-top: 6.25rem;
  }

  .-pt-lg-s100 {
    padding-top: -6.25rem;
  }

  .pb-lg-s100 {
    padding-bottom: 6.25rem;
  }

  .-pb-lg-s100 {
    padding-bottom: -6.25rem;
  }

  .pr-lg-s100 {
    padding-right: 6.25rem;
  }

  .-pr-lg-s100 {
    padding-right: -6.25rem;
  }

  .pl-lg-s100 {
    padding-left: 6.25rem;
  }

  .-pl-lg-s100 {
    padding-left: -6.25rem;
  }

  .mx-lg-s100 {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
  }

  .my-lg-s100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }

  .px-lg-s100 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }

  .py-lg-s100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  .gap-lg-s100 {
    gap: 6.25rem;
  }

  .pm-lg-s100, .gx-lg-s100 > .gx-child {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .gx-lg-s100 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 6.25rem;
  }

  .gx-lg-s100 > .gx-child:last-of-type {
    padding-left: 6.25rem;
    padding-right: 0;
  }

  .m-lg-s120 {
    margin: 7.5rem;
  }

  .-m-lg-s120 {
    margin: -7.5rem;
  }

  .mt-lg-s120 {
    margin-top: 7.5rem;
  }

  .-mt-lg-s120 {
    margin-top: -7.5rem;
  }

  .mb-lg-s120 {
    margin-bottom: 7.5rem;
  }

  .-mb-lg-s120 {
    margin-bottom: -7.5rem;
  }

  .mr-lg-s120 {
    margin-right: 7.5rem;
  }

  .-mr-lg-s120 {
    margin-right: -7.5rem;
  }

  .ml-lg-s120 {
    margin-left: 7.5rem;
  }

  .-ml-lg-s120 {
    margin-left: -7.5rem;
  }

  .p-lg-s120 {
    padding: 7.5rem;
  }

  .-p-lg-s120 {
    padding: -7.5rem;
  }

  .pt-lg-s120 {
    padding-top: 7.5rem;
  }

  .-pt-lg-s120 {
    padding-top: -7.5rem;
  }

  .pb-lg-s120 {
    padding-bottom: 7.5rem;
  }

  .-pb-lg-s120 {
    padding-bottom: -7.5rem;
  }

  .pr-lg-s120 {
    padding-right: 7.5rem;
  }

  .-pr-lg-s120 {
    padding-right: -7.5rem;
  }

  .pl-lg-s120 {
    padding-left: 7.5rem;
  }

  .-pl-lg-s120 {
    padding-left: -7.5rem;
  }

  .mx-lg-s120 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }

  .my-lg-s120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }

  .px-lg-s120 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }

  .py-lg-s120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }

  .gap-lg-s120 {
    gap: 7.5rem;
  }

  .pm-lg-s120, .gx-lg-s120 > .gx-child {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .gx-lg-s120 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 7.5rem;
  }

  .gx-lg-s120 > .gx-child:last-of-type {
    padding-left: 7.5rem;
    padding-right: 0;
  }

  .m-lg-s150 {
    margin: 9.375rem;
  }

  .-m-lg-s150 {
    margin: -9.375rem;
  }

  .mt-lg-s150 {
    margin-top: 9.375rem;
  }

  .-mt-lg-s150 {
    margin-top: -9.375rem;
  }

  .mb-lg-s150 {
    margin-bottom: 9.375rem;
  }

  .-mb-lg-s150 {
    margin-bottom: -9.375rem;
  }

  .mr-lg-s150 {
    margin-right: 9.375rem;
  }

  .-mr-lg-s150 {
    margin-right: -9.375rem;
  }

  .ml-lg-s150 {
    margin-left: 9.375rem;
  }

  .-ml-lg-s150 {
    margin-left: -9.375rem;
  }

  .p-lg-s150 {
    padding: 9.375rem;
  }

  .-p-lg-s150 {
    padding: -9.375rem;
  }

  .pt-lg-s150 {
    padding-top: 9.375rem;
  }

  .-pt-lg-s150 {
    padding-top: -9.375rem;
  }

  .pb-lg-s150 {
    padding-bottom: 9.375rem;
  }

  .-pb-lg-s150 {
    padding-bottom: -9.375rem;
  }

  .pr-lg-s150 {
    padding-right: 9.375rem;
  }

  .-pr-lg-s150 {
    padding-right: -9.375rem;
  }

  .pl-lg-s150 {
    padding-left: 9.375rem;
  }

  .-pl-lg-s150 {
    padding-left: -9.375rem;
  }

  .mx-lg-s150 {
    margin-left: 9.375rem;
    margin-right: 9.375rem;
  }

  .my-lg-s150 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }

  .px-lg-s150 {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
  }

  .py-lg-s150 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }

  .gap-lg-s150 {
    gap: 9.375rem;
  }

  .pm-lg-s150, .gx-lg-s150 > .gx-child {
    padding-left: 4.6875rem;
    padding-right: 4.6875rem;
  }

  .gx-lg-s150 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 9.375rem;
  }

  .gx-lg-s150 > .gx-child:last-of-type {
    padding-left: 9.375rem;
    padding-right: 0;
  }
}

@media screen and (min-width: 1280px) {
  .m-xl-s0, .-m-xl-s0 {
    margin: 0;
  }

  .mt-xl-s0, .-mt-xl-s0 {
    margin-top: 0;
  }

  .mb-xl-s0, .-mb-xl-s0 {
    margin-bottom: 0;
  }

  .mr-xl-s0, .-mr-xl-s0 {
    margin-right: 0;
  }

  .ml-xl-s0, .-ml-xl-s0 {
    margin-left: 0;
  }

  .p-xl-s0, .-p-xl-s0 {
    padding: 0;
  }

  .pt-xl-s0, .-pt-xl-s0 {
    padding-top: 0;
  }

  .pb-xl-s0, .-pb-xl-s0 {
    padding-bottom: 0;
  }

  .pr-xl-s0, .-pr-xl-s0 {
    padding-right: 0;
  }

  .pl-xl-s0, .-pl-xl-s0 {
    padding-left: 0;
  }

  .mx-xl-s0 {
    margin-left: 0;
    margin-right: 0;
  }

  .my-xl-s0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .px-xl-s0 {
    padding-left: 0;
    padding-right: 0;
  }

  .py-xl-s0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .gap-xl-s0 {
    gap: 0;
  }

  .pm-xl-s0, .gx-xl-s0 > .gx-child, .gx-xl-s0 > .gx-child:first-of-type, .gx-xl-s0 > .gx-child:last-of-type {
    padding-left: 0;
    padding-right: 0;
  }

  .m-xl-s2 {
    margin: .125rem;
  }

  .-m-xl-s2 {
    margin: -.125rem;
  }

  .mt-xl-s2 {
    margin-top: .125rem;
  }

  .-mt-xl-s2 {
    margin-top: -.125rem;
  }

  .mb-xl-s2 {
    margin-bottom: .125rem;
  }

  .-mb-xl-s2 {
    margin-bottom: -.125rem;
  }

  .mr-xl-s2 {
    margin-right: .125rem;
  }

  .-mr-xl-s2 {
    margin-right: -.125rem;
  }

  .ml-xl-s2 {
    margin-left: .125rem;
  }

  .-ml-xl-s2 {
    margin-left: -.125rem;
  }

  .p-xl-s2 {
    padding: .125rem;
  }

  .-p-xl-s2 {
    padding: -.125rem;
  }

  .pt-xl-s2 {
    padding-top: .125rem;
  }

  .-pt-xl-s2 {
    padding-top: -.125rem;
  }

  .pb-xl-s2 {
    padding-bottom: .125rem;
  }

  .-pb-xl-s2 {
    padding-bottom: -.125rem;
  }

  .pr-xl-s2 {
    padding-right: .125rem;
  }

  .-pr-xl-s2 {
    padding-right: -.125rem;
  }

  .pl-xl-s2 {
    padding-left: .125rem;
  }

  .-pl-xl-s2 {
    padding-left: -.125rem;
  }

  .mx-xl-s2 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .my-xl-s2 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .px-xl-s2 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .py-xl-s2 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .gap-xl-s2 {
    gap: .125rem;
  }

  .pm-xl-s2, .gx-xl-s2 > .gx-child {
    padding-left: .0625rem;
    padding-right: .0625rem;
  }

  .gx-xl-s2 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .125rem;
  }

  .gx-xl-s2 > .gx-child:last-of-type {
    padding-left: .125rem;
    padding-right: 0;
  }

  .m-xl-s3 {
    margin: .1875rem;
  }

  .-m-xl-s3 {
    margin: -.1875rem;
  }

  .mt-xl-s3 {
    margin-top: .1875rem;
  }

  .-mt-xl-s3 {
    margin-top: -.1875rem;
  }

  .mb-xl-s3 {
    margin-bottom: .1875rem;
  }

  .-mb-xl-s3 {
    margin-bottom: -.1875rem;
  }

  .mr-xl-s3 {
    margin-right: .1875rem;
  }

  .-mr-xl-s3 {
    margin-right: -.1875rem;
  }

  .ml-xl-s3 {
    margin-left: .1875rem;
  }

  .-ml-xl-s3 {
    margin-left: -.1875rem;
  }

  .p-xl-s3 {
    padding: .1875rem;
  }

  .-p-xl-s3 {
    padding: -.1875rem;
  }

  .pt-xl-s3 {
    padding-top: .1875rem;
  }

  .-pt-xl-s3 {
    padding-top: -.1875rem;
  }

  .pb-xl-s3 {
    padding-bottom: .1875rem;
  }

  .-pb-xl-s3 {
    padding-bottom: -.1875rem;
  }

  .pr-xl-s3 {
    padding-right: .1875rem;
  }

  .-pr-xl-s3 {
    padding-right: -.1875rem;
  }

  .pl-xl-s3 {
    padding-left: .1875rem;
  }

  .-pl-xl-s3 {
    padding-left: -.1875rem;
  }

  .mx-xl-s3 {
    margin-left: .1875rem;
    margin-right: .1875rem;
  }

  .my-xl-s3 {
    margin-top: .1875rem;
    margin-bottom: .1875rem;
  }

  .px-xl-s3 {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .py-xl-s3 {
    padding-top: .1875rem;
    padding-bottom: .1875rem;
  }

  .gap-xl-s3 {
    gap: .1875rem;
  }

  .pm-xl-s3, .gx-xl-s3 > .gx-child {
    padding-left: .09375rem;
    padding-right: .09375rem;
  }

  .gx-xl-s3 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .1875rem;
  }

  .gx-xl-s3 > .gx-child:last-of-type {
    padding-left: .1875rem;
    padding-right: 0;
  }

  .m-xl-s4 {
    margin: .25rem;
  }

  .-m-xl-s4 {
    margin: -.25rem;
  }

  .mt-xl-s4 {
    margin-top: .25rem;
  }

  .-mt-xl-s4 {
    margin-top: -.25rem;
  }

  .mb-xl-s4 {
    margin-bottom: .25rem;
  }

  .-mb-xl-s4 {
    margin-bottom: -.25rem;
  }

  .mr-xl-s4 {
    margin-right: .25rem;
  }

  .-mr-xl-s4 {
    margin-right: -.25rem;
  }

  .ml-xl-s4 {
    margin-left: .25rem;
  }

  .-ml-xl-s4 {
    margin-left: -.25rem;
  }

  .p-xl-s4 {
    padding: .25rem;
  }

  .-p-xl-s4 {
    padding: -.25rem;
  }

  .pt-xl-s4 {
    padding-top: .25rem;
  }

  .-pt-xl-s4 {
    padding-top: -.25rem;
  }

  .pb-xl-s4 {
    padding-bottom: .25rem;
  }

  .-pb-xl-s4 {
    padding-bottom: -.25rem;
  }

  .pr-xl-s4 {
    padding-right: .25rem;
  }

  .-pr-xl-s4 {
    padding-right: -.25rem;
  }

  .pl-xl-s4 {
    padding-left: .25rem;
  }

  .-pl-xl-s4 {
    padding-left: -.25rem;
  }

  .mx-xl-s4 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .my-xl-s4 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .px-xl-s4 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .py-xl-s4 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .gap-xl-s4 {
    gap: .25rem;
  }

  .pm-xl-s4, .gx-xl-s4 > .gx-child {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .gx-xl-s4 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .25rem;
  }

  .gx-xl-s4 > .gx-child:last-of-type {
    padding-left: .25rem;
    padding-right: 0;
  }

  .m-xl-s5 {
    margin: .3125rem;
  }

  .-m-xl-s5 {
    margin: -.3125rem;
  }

  .mt-xl-s5 {
    margin-top: .3125rem;
  }

  .-mt-xl-s5 {
    margin-top: -.3125rem;
  }

  .mb-xl-s5 {
    margin-bottom: .3125rem;
  }

  .-mb-xl-s5 {
    margin-bottom: -.3125rem;
  }

  .mr-xl-s5 {
    margin-right: .3125rem;
  }

  .-mr-xl-s5 {
    margin-right: -.3125rem;
  }

  .ml-xl-s5 {
    margin-left: .3125rem;
  }

  .-ml-xl-s5 {
    margin-left: -.3125rem;
  }

  .p-xl-s5 {
    padding: .3125rem;
  }

  .-p-xl-s5 {
    padding: -.3125rem;
  }

  .pt-xl-s5 {
    padding-top: .3125rem;
  }

  .-pt-xl-s5 {
    padding-top: -.3125rem;
  }

  .pb-xl-s5 {
    padding-bottom: .3125rem;
  }

  .-pb-xl-s5 {
    padding-bottom: -.3125rem;
  }

  .pr-xl-s5 {
    padding-right: .3125rem;
  }

  .-pr-xl-s5 {
    padding-right: -.3125rem;
  }

  .pl-xl-s5 {
    padding-left: .3125rem;
  }

  .-pl-xl-s5 {
    padding-left: -.3125rem;
  }

  .mx-xl-s5 {
    margin-left: .3125rem;
    margin-right: .3125rem;
  }

  .my-xl-s5 {
    margin-top: .3125rem;
    margin-bottom: .3125rem;
  }

  .px-xl-s5 {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .py-xl-s5 {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
  }

  .gap-xl-s5 {
    gap: .3125rem;
  }

  .pm-xl-s5, .gx-xl-s5 > .gx-child {
    padding-left: .15625rem;
    padding-right: .15625rem;
  }

  .gx-xl-s5 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .3125rem;
  }

  .gx-xl-s5 > .gx-child:last-of-type {
    padding-left: .3125rem;
    padding-right: 0;
  }

  .m-xl-s6 {
    margin: .375rem;
  }

  .-m-xl-s6 {
    margin: -.375rem;
  }

  .mt-xl-s6 {
    margin-top: .375rem;
  }

  .-mt-xl-s6 {
    margin-top: -.375rem;
  }

  .mb-xl-s6 {
    margin-bottom: .375rem;
  }

  .-mb-xl-s6 {
    margin-bottom: -.375rem;
  }

  .mr-xl-s6 {
    margin-right: .375rem;
  }

  .-mr-xl-s6 {
    margin-right: -.375rem;
  }

  .ml-xl-s6 {
    margin-left: .375rem;
  }

  .-ml-xl-s6 {
    margin-left: -.375rem;
  }

  .p-xl-s6 {
    padding: .375rem;
  }

  .-p-xl-s6 {
    padding: -.375rem;
  }

  .pt-xl-s6 {
    padding-top: .375rem;
  }

  .-pt-xl-s6 {
    padding-top: -.375rem;
  }

  .pb-xl-s6 {
    padding-bottom: .375rem;
  }

  .-pb-xl-s6 {
    padding-bottom: -.375rem;
  }

  .pr-xl-s6 {
    padding-right: .375rem;
  }

  .-pr-xl-s6 {
    padding-right: -.375rem;
  }

  .pl-xl-s6 {
    padding-left: .375rem;
  }

  .-pl-xl-s6 {
    padding-left: -.375rem;
  }

  .mx-xl-s6 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .my-xl-s6 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .px-xl-s6 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .py-xl-s6 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .gap-xl-s6 {
    gap: .375rem;
  }

  .pm-xl-s6, .gx-xl-s6 > .gx-child {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .gx-xl-s6 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .375rem;
  }

  .gx-xl-s6 > .gx-child:last-of-type {
    padding-left: .375rem;
    padding-right: 0;
  }

  .m-xl-s7 {
    margin: .4375rem;
  }

  .-m-xl-s7 {
    margin: -.4375rem;
  }

  .mt-xl-s7 {
    margin-top: .4375rem;
  }

  .-mt-xl-s7 {
    margin-top: -.4375rem;
  }

  .mb-xl-s7 {
    margin-bottom: .4375rem;
  }

  .-mb-xl-s7 {
    margin-bottom: -.4375rem;
  }

  .mr-xl-s7 {
    margin-right: .4375rem;
  }

  .-mr-xl-s7 {
    margin-right: -.4375rem;
  }

  .ml-xl-s7 {
    margin-left: .4375rem;
  }

  .-ml-xl-s7 {
    margin-left: -.4375rem;
  }

  .p-xl-s7 {
    padding: .4375rem;
  }

  .-p-xl-s7 {
    padding: -.4375rem;
  }

  .pt-xl-s7 {
    padding-top: .4375rem;
  }

  .-pt-xl-s7 {
    padding-top: -.4375rem;
  }

  .pb-xl-s7 {
    padding-bottom: .4375rem;
  }

  .-pb-xl-s7 {
    padding-bottom: -.4375rem;
  }

  .pr-xl-s7 {
    padding-right: .4375rem;
  }

  .-pr-xl-s7 {
    padding-right: -.4375rem;
  }

  .pl-xl-s7 {
    padding-left: .4375rem;
  }

  .-pl-xl-s7 {
    padding-left: -.4375rem;
  }

  .mx-xl-s7 {
    margin-left: .4375rem;
    margin-right: .4375rem;
  }

  .my-xl-s7 {
    margin-top: .4375rem;
    margin-bottom: .4375rem;
  }

  .px-xl-s7 {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .py-xl-s7 {
    padding-top: .4375rem;
    padding-bottom: .4375rem;
  }

  .gap-xl-s7 {
    gap: .4375rem;
  }

  .pm-xl-s7, .gx-xl-s7 > .gx-child {
    padding-left: .21875rem;
    padding-right: .21875rem;
  }

  .gx-xl-s7 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .4375rem;
  }

  .gx-xl-s7 > .gx-child:last-of-type {
    padding-left: .4375rem;
    padding-right: 0;
  }

  .m-xl-s8 {
    margin: .5rem;
  }

  .-m-xl-s8 {
    margin: -.5rem;
  }

  .mt-xl-s8 {
    margin-top: .5rem;
  }

  .-mt-xl-s8 {
    margin-top: -.5rem;
  }

  .mb-xl-s8 {
    margin-bottom: .5rem;
  }

  .-mb-xl-s8 {
    margin-bottom: -.5rem;
  }

  .mr-xl-s8 {
    margin-right: .5rem;
  }

  .-mr-xl-s8 {
    margin-right: -.5rem;
  }

  .ml-xl-s8 {
    margin-left: .5rem;
  }

  .-ml-xl-s8 {
    margin-left: -.5rem;
  }

  .p-xl-s8 {
    padding: .5rem;
  }

  .-p-xl-s8 {
    padding: -.5rem;
  }

  .pt-xl-s8 {
    padding-top: .5rem;
  }

  .-pt-xl-s8 {
    padding-top: -.5rem;
  }

  .pb-xl-s8 {
    padding-bottom: .5rem;
  }

  .-pb-xl-s8 {
    padding-bottom: -.5rem;
  }

  .pr-xl-s8 {
    padding-right: .5rem;
  }

  .-pr-xl-s8 {
    padding-right: -.5rem;
  }

  .pl-xl-s8 {
    padding-left: .5rem;
  }

  .-pl-xl-s8 {
    padding-left: -.5rem;
  }

  .mx-xl-s8 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .my-xl-s8 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .px-xl-s8 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .py-xl-s8 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .gap-xl-s8 {
    gap: .5rem;
  }

  .pm-xl-s8, .gx-xl-s8 > .gx-child {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .gx-xl-s8 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5rem;
  }

  .gx-xl-s8 > .gx-child:last-of-type {
    padding-left: .5rem;
    padding-right: 0;
  }

  .m-xl-s9 {
    margin: .5625rem;
  }

  .-m-xl-s9 {
    margin: -.5625rem;
  }

  .mt-xl-s9 {
    margin-top: .5625rem;
  }

  .-mt-xl-s9 {
    margin-top: -.5625rem;
  }

  .mb-xl-s9 {
    margin-bottom: .5625rem;
  }

  .-mb-xl-s9 {
    margin-bottom: -.5625rem;
  }

  .mr-xl-s9 {
    margin-right: .5625rem;
  }

  .-mr-xl-s9 {
    margin-right: -.5625rem;
  }

  .ml-xl-s9 {
    margin-left: .5625rem;
  }

  .-ml-xl-s9 {
    margin-left: -.5625rem;
  }

  .p-xl-s9 {
    padding: .5625rem;
  }

  .-p-xl-s9 {
    padding: -.5625rem;
  }

  .pt-xl-s9 {
    padding-top: .5625rem;
  }

  .-pt-xl-s9 {
    padding-top: -.5625rem;
  }

  .pb-xl-s9 {
    padding-bottom: .5625rem;
  }

  .-pb-xl-s9 {
    padding-bottom: -.5625rem;
  }

  .pr-xl-s9 {
    padding-right: .5625rem;
  }

  .-pr-xl-s9 {
    padding-right: -.5625rem;
  }

  .pl-xl-s9 {
    padding-left: .5625rem;
  }

  .-pl-xl-s9 {
    padding-left: -.5625rem;
  }

  .mx-xl-s9 {
    margin-left: .5625rem;
    margin-right: .5625rem;
  }

  .my-xl-s9 {
    margin-top: .5625rem;
    margin-bottom: .5625rem;
  }

  .px-xl-s9 {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .py-xl-s9 {
    padding-top: .5625rem;
    padding-bottom: .5625rem;
  }

  .gap-xl-s9 {
    gap: .5625rem;
  }

  .pm-xl-s9, .gx-xl-s9 > .gx-child {
    padding-left: .28125rem;
    padding-right: .28125rem;
  }

  .gx-xl-s9 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5625rem;
  }

  .gx-xl-s9 > .gx-child:last-of-type {
    padding-left: .5625rem;
    padding-right: 0;
  }

  .m-xl-s10 {
    margin: .625rem;
  }

  .-m-xl-s10 {
    margin: -.625rem;
  }

  .mt-xl-s10 {
    margin-top: .625rem;
  }

  .-mt-xl-s10 {
    margin-top: -.625rem;
  }

  .mb-xl-s10 {
    margin-bottom: .625rem;
  }

  .-mb-xl-s10 {
    margin-bottom: -.625rem;
  }

  .mr-xl-s10 {
    margin-right: .625rem;
  }

  .-mr-xl-s10 {
    margin-right: -.625rem;
  }

  .ml-xl-s10 {
    margin-left: .625rem;
  }

  .-ml-xl-s10 {
    margin-left: -.625rem;
  }

  .p-xl-s10 {
    padding: .625rem;
  }

  .-p-xl-s10 {
    padding: -.625rem;
  }

  .pt-xl-s10 {
    padding-top: .625rem;
  }

  .-pt-xl-s10 {
    padding-top: -.625rem;
  }

  .pb-xl-s10 {
    padding-bottom: .625rem;
  }

  .-pb-xl-s10 {
    padding-bottom: -.625rem;
  }

  .pr-xl-s10 {
    padding-right: .625rem;
  }

  .-pr-xl-s10 {
    padding-right: -.625rem;
  }

  .pl-xl-s10 {
    padding-left: .625rem;
  }

  .-pl-xl-s10 {
    padding-left: -.625rem;
  }

  .mx-xl-s10 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .my-xl-s10 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .px-xl-s10 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .py-xl-s10 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .gap-xl-s10 {
    gap: .625rem;
  }

  .pm-xl-s10, .gx-xl-s10 > .gx-child {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .gx-xl-s10 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .625rem;
  }

  .gx-xl-s10 > .gx-child:last-of-type {
    padding-left: .625rem;
    padding-right: 0;
  }

  .m-xl-s12 {
    margin: .75rem;
  }

  .-m-xl-s12 {
    margin: -.75rem;
  }

  .mt-xl-s12 {
    margin-top: .75rem;
  }

  .-mt-xl-s12 {
    margin-top: -.75rem;
  }

  .mb-xl-s12 {
    margin-bottom: .75rem;
  }

  .-mb-xl-s12 {
    margin-bottom: -.75rem;
  }

  .mr-xl-s12 {
    margin-right: .75rem;
  }

  .-mr-xl-s12 {
    margin-right: -.75rem;
  }

  .ml-xl-s12 {
    margin-left: .75rem;
  }

  .-ml-xl-s12 {
    margin-left: -.75rem;
  }

  .p-xl-s12 {
    padding: .75rem;
  }

  .-p-xl-s12 {
    padding: -.75rem;
  }

  .pt-xl-s12 {
    padding-top: .75rem;
  }

  .-pt-xl-s12 {
    padding-top: -.75rem;
  }

  .pb-xl-s12 {
    padding-bottom: .75rem;
  }

  .-pb-xl-s12 {
    padding-bottom: -.75rem;
  }

  .pr-xl-s12 {
    padding-right: .75rem;
  }

  .-pr-xl-s12 {
    padding-right: -.75rem;
  }

  .pl-xl-s12 {
    padding-left: .75rem;
  }

  .-pl-xl-s12 {
    padding-left: -.75rem;
  }

  .mx-xl-s12 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .my-xl-s12 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .px-xl-s12 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .py-xl-s12 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .gap-xl-s12 {
    gap: .75rem;
  }

  .pm-xl-s12, .gx-xl-s12 > .gx-child {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .gx-xl-s12 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .75rem;
  }

  .gx-xl-s12 > .gx-child:last-of-type {
    padding-left: .75rem;
    padding-right: 0;
  }

  .m-xl-s13 {
    margin: .8125rem;
  }

  .-m-xl-s13 {
    margin: -.8125rem;
  }

  .mt-xl-s13 {
    margin-top: .8125rem;
  }

  .-mt-xl-s13 {
    margin-top: -.8125rem;
  }

  .mb-xl-s13 {
    margin-bottom: .8125rem;
  }

  .-mb-xl-s13 {
    margin-bottom: -.8125rem;
  }

  .mr-xl-s13 {
    margin-right: .8125rem;
  }

  .-mr-xl-s13 {
    margin-right: -.8125rem;
  }

  .ml-xl-s13 {
    margin-left: .8125rem;
  }

  .-ml-xl-s13 {
    margin-left: -.8125rem;
  }

  .p-xl-s13 {
    padding: .8125rem;
  }

  .-p-xl-s13 {
    padding: -.8125rem;
  }

  .pt-xl-s13 {
    padding-top: .8125rem;
  }

  .-pt-xl-s13 {
    padding-top: -.8125rem;
  }

  .pb-xl-s13 {
    padding-bottom: .8125rem;
  }

  .-pb-xl-s13 {
    padding-bottom: -.8125rem;
  }

  .pr-xl-s13 {
    padding-right: .8125rem;
  }

  .-pr-xl-s13 {
    padding-right: -.8125rem;
  }

  .pl-xl-s13 {
    padding-left: .8125rem;
  }

  .-pl-xl-s13 {
    padding-left: -.8125rem;
  }

  .mx-xl-s13 {
    margin-left: .8125rem;
    margin-right: .8125rem;
  }

  .my-xl-s13 {
    margin-top: .8125rem;
    margin-bottom: .8125rem;
  }

  .px-xl-s13 {
    padding-left: .8125rem;
    padding-right: .8125rem;
  }

  .py-xl-s13 {
    padding-top: .8125rem;
    padding-bottom: .8125rem;
  }

  .gap-xl-s13 {
    gap: .8125rem;
  }

  .pm-xl-s13, .gx-xl-s13 > .gx-child {
    padding-left: .40625rem;
    padding-right: .40625rem;
  }

  .gx-xl-s13 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .8125rem;
  }

  .gx-xl-s13 > .gx-child:last-of-type {
    padding-left: .8125rem;
    padding-right: 0;
  }

  .m-xl-s14 {
    margin: .875rem;
  }

  .-m-xl-s14 {
    margin: -.875rem;
  }

  .mt-xl-s14 {
    margin-top: .875rem;
  }

  .-mt-xl-s14 {
    margin-top: -.875rem;
  }

  .mb-xl-s14 {
    margin-bottom: .875rem;
  }

  .-mb-xl-s14 {
    margin-bottom: -.875rem;
  }

  .mr-xl-s14 {
    margin-right: .875rem;
  }

  .-mr-xl-s14 {
    margin-right: -.875rem;
  }

  .ml-xl-s14 {
    margin-left: .875rem;
  }

  .-ml-xl-s14 {
    margin-left: -.875rem;
  }

  .p-xl-s14 {
    padding: .875rem;
  }

  .-p-xl-s14 {
    padding: -.875rem;
  }

  .pt-xl-s14 {
    padding-top: .875rem;
  }

  .-pt-xl-s14 {
    padding-top: -.875rem;
  }

  .pb-xl-s14 {
    padding-bottom: .875rem;
  }

  .-pb-xl-s14 {
    padding-bottom: -.875rem;
  }

  .pr-xl-s14 {
    padding-right: .875rem;
  }

  .-pr-xl-s14 {
    padding-right: -.875rem;
  }

  .pl-xl-s14 {
    padding-left: .875rem;
  }

  .-pl-xl-s14 {
    padding-left: -.875rem;
  }

  .mx-xl-s14 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .my-xl-s14 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .px-xl-s14 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .py-xl-s14 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .gap-xl-s14 {
    gap: .875rem;
  }

  .pm-xl-s14, .gx-xl-s14 > .gx-child {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .gx-xl-s14 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .875rem;
  }

  .gx-xl-s14 > .gx-child:last-of-type {
    padding-left: .875rem;
    padding-right: 0;
  }

  .m-xl-s15 {
    margin: .9375rem;
  }

  .-m-xl-s15 {
    margin: -.9375rem;
  }

  .mt-xl-s15 {
    margin-top: .9375rem;
  }

  .-mt-xl-s15 {
    margin-top: -.9375rem;
  }

  .mb-xl-s15 {
    margin-bottom: .9375rem;
  }

  .-mb-xl-s15 {
    margin-bottom: -.9375rem;
  }

  .mr-xl-s15 {
    margin-right: .9375rem;
  }

  .-mr-xl-s15 {
    margin-right: -.9375rem;
  }

  .ml-xl-s15 {
    margin-left: .9375rem;
  }

  .-ml-xl-s15 {
    margin-left: -.9375rem;
  }

  .p-xl-s15 {
    padding: .9375rem;
  }

  .-p-xl-s15 {
    padding: -.9375rem;
  }

  .pt-xl-s15 {
    padding-top: .9375rem;
  }

  .-pt-xl-s15 {
    padding-top: -.9375rem;
  }

  .pb-xl-s15 {
    padding-bottom: .9375rem;
  }

  .-pb-xl-s15 {
    padding-bottom: -.9375rem;
  }

  .pr-xl-s15 {
    padding-right: .9375rem;
  }

  .-pr-xl-s15 {
    padding-right: -.9375rem;
  }

  .pl-xl-s15 {
    padding-left: .9375rem;
  }

  .-pl-xl-s15 {
    padding-left: -.9375rem;
  }

  .mx-xl-s15 {
    margin-left: .9375rem;
    margin-right: .9375rem;
  }

  .my-xl-s15 {
    margin-top: .9375rem;
    margin-bottom: .9375rem;
  }

  .px-xl-s15 {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .py-xl-s15 {
    padding-top: .9375rem;
    padding-bottom: .9375rem;
  }

  .gap-xl-s15 {
    gap: .9375rem;
  }

  .pm-xl-s15, .gx-xl-s15 > .gx-child {
    padding-left: .46875rem;
    padding-right: .46875rem;
  }

  .gx-xl-s15 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .9375rem;
  }

  .gx-xl-s15 > .gx-child:last-of-type {
    padding-left: .9375rem;
    padding-right: 0;
  }

  .m-xl-s16 {
    margin: 1rem;
  }

  .-m-xl-s16 {
    margin: -1rem;
  }

  .mt-xl-s16 {
    margin-top: 1rem;
  }

  .-mt-xl-s16 {
    margin-top: -1rem;
  }

  .mb-xl-s16 {
    margin-bottom: 1rem;
  }

  .-mb-xl-s16 {
    margin-bottom: -1rem;
  }

  .mr-xl-s16 {
    margin-right: 1rem;
  }

  .-mr-xl-s16 {
    margin-right: -1rem;
  }

  .ml-xl-s16 {
    margin-left: 1rem;
  }

  .-ml-xl-s16 {
    margin-left: -1rem;
  }

  .p-xl-s16 {
    padding: 1rem;
  }

  .-p-xl-s16 {
    padding: -1rem;
  }

  .pt-xl-s16 {
    padding-top: 1rem;
  }

  .-pt-xl-s16 {
    padding-top: -1rem;
  }

  .pb-xl-s16 {
    padding-bottom: 1rem;
  }

  .-pb-xl-s16 {
    padding-bottom: -1rem;
  }

  .pr-xl-s16 {
    padding-right: 1rem;
  }

  .-pr-xl-s16 {
    padding-right: -1rem;
  }

  .pl-xl-s16 {
    padding-left: 1rem;
  }

  .-pl-xl-s16 {
    padding-left: -1rem;
  }

  .mx-xl-s16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .my-xl-s16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .px-xl-s16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .py-xl-s16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .gap-xl-s16 {
    gap: 1rem;
  }

  .pm-xl-s16, .gx-xl-s16 > .gx-child {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .gx-xl-s16 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1rem;
  }

  .gx-xl-s16 > .gx-child:last-of-type {
    padding-left: 1rem;
    padding-right: 0;
  }

  .m-xl-s18 {
    margin: 1.125rem;
  }

  .-m-xl-s18 {
    margin: -1.125rem;
  }

  .mt-xl-s18 {
    margin-top: 1.125rem;
  }

  .-mt-xl-s18 {
    margin-top: -1.125rem;
  }

  .mb-xl-s18 {
    margin-bottom: 1.125rem;
  }

  .-mb-xl-s18 {
    margin-bottom: -1.125rem;
  }

  .mr-xl-s18 {
    margin-right: 1.125rem;
  }

  .-mr-xl-s18 {
    margin-right: -1.125rem;
  }

  .ml-xl-s18 {
    margin-left: 1.125rem;
  }

  .-ml-xl-s18 {
    margin-left: -1.125rem;
  }

  .p-xl-s18 {
    padding: 1.125rem;
  }

  .-p-xl-s18 {
    padding: -1.125rem;
  }

  .pt-xl-s18 {
    padding-top: 1.125rem;
  }

  .-pt-xl-s18 {
    padding-top: -1.125rem;
  }

  .pb-xl-s18 {
    padding-bottom: 1.125rem;
  }

  .-pb-xl-s18 {
    padding-bottom: -1.125rem;
  }

  .pr-xl-s18 {
    padding-right: 1.125rem;
  }

  .-pr-xl-s18 {
    padding-right: -1.125rem;
  }

  .pl-xl-s18 {
    padding-left: 1.125rem;
  }

  .-pl-xl-s18 {
    padding-left: -1.125rem;
  }

  .mx-xl-s18 {
    margin-left: 1.125rem;
    margin-right: 1.125rem;
  }

  .my-xl-s18 {
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
  }

  .px-xl-s18 {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .py-xl-s18 {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }

  .gap-xl-s18 {
    gap: 1.125rem;
  }

  .pm-xl-s18, .gx-xl-s18 > .gx-child {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .gx-xl-s18 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.125rem;
  }

  .gx-xl-s18 > .gx-child:last-of-type {
    padding-left: 1.125rem;
    padding-right: 0;
  }

  .m-xl-s20 {
    margin: 1.25rem;
  }

  .-m-xl-s20 {
    margin: -1.25rem;
  }

  .mt-xl-s20 {
    margin-top: 1.25rem;
  }

  .-mt-xl-s20 {
    margin-top: -1.25rem;
  }

  .mb-xl-s20 {
    margin-bottom: 1.25rem;
  }

  .-mb-xl-s20 {
    margin-bottom: -1.25rem;
  }

  .mr-xl-s20 {
    margin-right: 1.25rem;
  }

  .-mr-xl-s20 {
    margin-right: -1.25rem;
  }

  .ml-xl-s20 {
    margin-left: 1.25rem;
  }

  .-ml-xl-s20 {
    margin-left: -1.25rem;
  }

  .p-xl-s20 {
    padding: 1.25rem;
  }

  .-p-xl-s20 {
    padding: -1.25rem;
  }

  .pt-xl-s20 {
    padding-top: 1.25rem;
  }

  .-pt-xl-s20 {
    padding-top: -1.25rem;
  }

  .pb-xl-s20 {
    padding-bottom: 1.25rem;
  }

  .-pb-xl-s20 {
    padding-bottom: -1.25rem;
  }

  .pr-xl-s20 {
    padding-right: 1.25rem;
  }

  .-pr-xl-s20 {
    padding-right: -1.25rem;
  }

  .pl-xl-s20 {
    padding-left: 1.25rem;
  }

  .-pl-xl-s20 {
    padding-left: -1.25rem;
  }

  .mx-xl-s20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .my-xl-s20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .px-xl-s20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .py-xl-s20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .gap-xl-s20 {
    gap: 1.25rem;
  }

  .pm-xl-s20, .gx-xl-s20 > .gx-child {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .gx-xl-s20 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.25rem;
  }

  .gx-xl-s20 > .gx-child:last-of-type {
    padding-left: 1.25rem;
    padding-right: 0;
  }

  .m-xl-s22 {
    margin: 1.375rem;
  }

  .-m-xl-s22 {
    margin: -1.375rem;
  }

  .mt-xl-s22 {
    margin-top: 1.375rem;
  }

  .-mt-xl-s22 {
    margin-top: -1.375rem;
  }

  .mb-xl-s22 {
    margin-bottom: 1.375rem;
  }

  .-mb-xl-s22 {
    margin-bottom: -1.375rem;
  }

  .mr-xl-s22 {
    margin-right: 1.375rem;
  }

  .-mr-xl-s22 {
    margin-right: -1.375rem;
  }

  .ml-xl-s22 {
    margin-left: 1.375rem;
  }

  .-ml-xl-s22 {
    margin-left: -1.375rem;
  }

  .p-xl-s22 {
    padding: 1.375rem;
  }

  .-p-xl-s22 {
    padding: -1.375rem;
  }

  .pt-xl-s22 {
    padding-top: 1.375rem;
  }

  .-pt-xl-s22 {
    padding-top: -1.375rem;
  }

  .pb-xl-s22 {
    padding-bottom: 1.375rem;
  }

  .-pb-xl-s22 {
    padding-bottom: -1.375rem;
  }

  .pr-xl-s22 {
    padding-right: 1.375rem;
  }

  .-pr-xl-s22 {
    padding-right: -1.375rem;
  }

  .pl-xl-s22 {
    padding-left: 1.375rem;
  }

  .-pl-xl-s22 {
    padding-left: -1.375rem;
  }

  .mx-xl-s22 {
    margin-left: 1.375rem;
    margin-right: 1.375rem;
  }

  .my-xl-s22 {
    margin-top: 1.375rem;
    margin-bottom: 1.375rem;
  }

  .px-xl-s22 {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }

  .py-xl-s22 {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
  }

  .gap-xl-s22 {
    gap: 1.375rem;
  }

  .pm-xl-s22, .gx-xl-s22 > .gx-child {
    padding-left: .6875rem;
    padding-right: .6875rem;
  }

  .gx-xl-s22 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.375rem;
  }

  .gx-xl-s22 > .gx-child:last-of-type {
    padding-left: 1.375rem;
    padding-right: 0;
  }

  .m-xl-s24 {
    margin: 1.5rem;
  }

  .-m-xl-s24 {
    margin: -1.5rem;
  }

  .mt-xl-s24 {
    margin-top: 1.5rem;
  }

  .-mt-xl-s24 {
    margin-top: -1.5rem;
  }

  .mb-xl-s24 {
    margin-bottom: 1.5rem;
  }

  .-mb-xl-s24 {
    margin-bottom: -1.5rem;
  }

  .mr-xl-s24 {
    margin-right: 1.5rem;
  }

  .-mr-xl-s24 {
    margin-right: -1.5rem;
  }

  .ml-xl-s24 {
    margin-left: 1.5rem;
  }

  .-ml-xl-s24 {
    margin-left: -1.5rem;
  }

  .p-xl-s24 {
    padding: 1.5rem;
  }

  .-p-xl-s24 {
    padding: -1.5rem;
  }

  .pt-xl-s24 {
    padding-top: 1.5rem;
  }

  .-pt-xl-s24 {
    padding-top: -1.5rem;
  }

  .pb-xl-s24 {
    padding-bottom: 1.5rem;
  }

  .-pb-xl-s24 {
    padding-bottom: -1.5rem;
  }

  .pr-xl-s24 {
    padding-right: 1.5rem;
  }

  .-pr-xl-s24 {
    padding-right: -1.5rem;
  }

  .pl-xl-s24 {
    padding-left: 1.5rem;
  }

  .-pl-xl-s24 {
    padding-left: -1.5rem;
  }

  .mx-xl-s24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .my-xl-s24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .px-xl-s24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .py-xl-s24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .gap-xl-s24 {
    gap: 1.5rem;
  }

  .pm-xl-s24, .gx-xl-s24 > .gx-child {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .gx-xl-s24 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5rem;
  }

  .gx-xl-s24 > .gx-child:last-of-type {
    padding-left: 1.5rem;
    padding-right: 0;
  }

  .m-xl-s25 {
    margin: 1.5625rem;
  }

  .-m-xl-s25 {
    margin: -1.5625rem;
  }

  .mt-xl-s25 {
    margin-top: 1.5625rem;
  }

  .-mt-xl-s25 {
    margin-top: -1.5625rem;
  }

  .mb-xl-s25 {
    margin-bottom: 1.5625rem;
  }

  .-mb-xl-s25 {
    margin-bottom: -1.5625rem;
  }

  .mr-xl-s25 {
    margin-right: 1.5625rem;
  }

  .-mr-xl-s25 {
    margin-right: -1.5625rem;
  }

  .ml-xl-s25 {
    margin-left: 1.5625rem;
  }

  .-ml-xl-s25 {
    margin-left: -1.5625rem;
  }

  .p-xl-s25 {
    padding: 1.5625rem;
  }

  .-p-xl-s25 {
    padding: -1.5625rem;
  }

  .pt-xl-s25 {
    padding-top: 1.5625rem;
  }

  .-pt-xl-s25 {
    padding-top: -1.5625rem;
  }

  .pb-xl-s25 {
    padding-bottom: 1.5625rem;
  }

  .-pb-xl-s25 {
    padding-bottom: -1.5625rem;
  }

  .pr-xl-s25 {
    padding-right: 1.5625rem;
  }

  .-pr-xl-s25 {
    padding-right: -1.5625rem;
  }

  .pl-xl-s25 {
    padding-left: 1.5625rem;
  }

  .-pl-xl-s25 {
    padding-left: -1.5625rem;
  }

  .mx-xl-s25 {
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
  }

  .my-xl-s25 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }

  .px-xl-s25 {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .py-xl-s25 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }

  .gap-xl-s25 {
    gap: 1.5625rem;
  }

  .pm-xl-s25, .gx-xl-s25 > .gx-child {
    padding-left: .78125rem;
    padding-right: .78125rem;
  }

  .gx-xl-s25 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5625rem;
  }

  .gx-xl-s25 > .gx-child:last-of-type {
    padding-left: 1.5625rem;
    padding-right: 0;
  }

  .m-xl-s27 {
    margin: 1.6875rem;
  }

  .-m-xl-s27 {
    margin: -1.6875rem;
  }

  .mt-xl-s27 {
    margin-top: 1.6875rem;
  }

  .-mt-xl-s27 {
    margin-top: -1.6875rem;
  }

  .mb-xl-s27 {
    margin-bottom: 1.6875rem;
  }

  .-mb-xl-s27 {
    margin-bottom: -1.6875rem;
  }

  .mr-xl-s27 {
    margin-right: 1.6875rem;
  }

  .-mr-xl-s27 {
    margin-right: -1.6875rem;
  }

  .ml-xl-s27 {
    margin-left: 1.6875rem;
  }

  .-ml-xl-s27 {
    margin-left: -1.6875rem;
  }

  .p-xl-s27 {
    padding: 1.6875rem;
  }

  .-p-xl-s27 {
    padding: -1.6875rem;
  }

  .pt-xl-s27 {
    padding-top: 1.6875rem;
  }

  .-pt-xl-s27 {
    padding-top: -1.6875rem;
  }

  .pb-xl-s27 {
    padding-bottom: 1.6875rem;
  }

  .-pb-xl-s27 {
    padding-bottom: -1.6875rem;
  }

  .pr-xl-s27 {
    padding-right: 1.6875rem;
  }

  .-pr-xl-s27 {
    padding-right: -1.6875rem;
  }

  .pl-xl-s27 {
    padding-left: 1.6875rem;
  }

  .-pl-xl-s27 {
    padding-left: -1.6875rem;
  }

  .mx-xl-s27 {
    margin-left: 1.6875rem;
    margin-right: 1.6875rem;
  }

  .my-xl-s27 {
    margin-top: 1.6875rem;
    margin-bottom: 1.6875rem;
  }

  .px-xl-s27 {
    padding-left: 1.6875rem;
    padding-right: 1.6875rem;
  }

  .py-xl-s27 {
    padding-top: 1.6875rem;
    padding-bottom: 1.6875rem;
  }

  .gap-xl-s27 {
    gap: 1.6875rem;
  }

  .pm-xl-s27, .gx-xl-s27 > .gx-child {
    padding-left: .84375rem;
    padding-right: .84375rem;
  }

  .gx-xl-s27 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.6875rem;
  }

  .gx-xl-s27 > .gx-child:last-of-type {
    padding-left: 1.6875rem;
    padding-right: 0;
  }

  .m-xl-s29 {
    margin: 1.8125rem;
  }

  .-m-xl-s29 {
    margin: -1.8125rem;
  }

  .mt-xl-s29 {
    margin-top: 1.8125rem;
  }

  .-mt-xl-s29 {
    margin-top: -1.8125rem;
  }

  .mb-xl-s29 {
    margin-bottom: 1.8125rem;
  }

  .-mb-xl-s29 {
    margin-bottom: -1.8125rem;
  }

  .mr-xl-s29 {
    margin-right: 1.8125rem;
  }

  .-mr-xl-s29 {
    margin-right: -1.8125rem;
  }

  .ml-xl-s29 {
    margin-left: 1.8125rem;
  }

  .-ml-xl-s29 {
    margin-left: -1.8125rem;
  }

  .p-xl-s29 {
    padding: 1.8125rem;
  }

  .-p-xl-s29 {
    padding: -1.8125rem;
  }

  .pt-xl-s29 {
    padding-top: 1.8125rem;
  }

  .-pt-xl-s29 {
    padding-top: -1.8125rem;
  }

  .pb-xl-s29 {
    padding-bottom: 1.8125rem;
  }

  .-pb-xl-s29 {
    padding-bottom: -1.8125rem;
  }

  .pr-xl-s29 {
    padding-right: 1.8125rem;
  }

  .-pr-xl-s29 {
    padding-right: -1.8125rem;
  }

  .pl-xl-s29 {
    padding-left: 1.8125rem;
  }

  .-pl-xl-s29 {
    padding-left: -1.8125rem;
  }

  .mx-xl-s29 {
    margin-left: 1.8125rem;
    margin-right: 1.8125rem;
  }

  .my-xl-s29 {
    margin-top: 1.8125rem;
    margin-bottom: 1.8125rem;
  }

  .px-xl-s29 {
    padding-left: 1.8125rem;
    padding-right: 1.8125rem;
  }

  .py-xl-s29 {
    padding-top: 1.8125rem;
    padding-bottom: 1.8125rem;
  }

  .gap-xl-s29 {
    gap: 1.8125rem;
  }

  .pm-xl-s29, .gx-xl-s29 > .gx-child {
    padding-left: .90625rem;
    padding-right: .90625rem;
  }

  .gx-xl-s29 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.8125rem;
  }

  .gx-xl-s29 > .gx-child:last-of-type {
    padding-left: 1.8125rem;
    padding-right: 0;
  }

  .m-xl-s30 {
    margin: 1.875rem;
  }

  .-m-xl-s30 {
    margin: -1.875rem;
  }

  .mt-xl-s30 {
    margin-top: 1.875rem;
  }

  .-mt-xl-s30 {
    margin-top: -1.875rem;
  }

  .mb-xl-s30 {
    margin-bottom: 1.875rem;
  }

  .-mb-xl-s30 {
    margin-bottom: -1.875rem;
  }

  .mr-xl-s30 {
    margin-right: 1.875rem;
  }

  .-mr-xl-s30 {
    margin-right: -1.875rem;
  }

  .ml-xl-s30 {
    margin-left: 1.875rem;
  }

  .-ml-xl-s30 {
    margin-left: -1.875rem;
  }

  .p-xl-s30 {
    padding: 1.875rem;
  }

  .-p-xl-s30 {
    padding: -1.875rem;
  }

  .pt-xl-s30 {
    padding-top: 1.875rem;
  }

  .-pt-xl-s30 {
    padding-top: -1.875rem;
  }

  .pb-xl-s30 {
    padding-bottom: 1.875rem;
  }

  .-pb-xl-s30 {
    padding-bottom: -1.875rem;
  }

  .pr-xl-s30 {
    padding-right: 1.875rem;
  }

  .-pr-xl-s30 {
    padding-right: -1.875rem;
  }

  .pl-xl-s30 {
    padding-left: 1.875rem;
  }

  .-pl-xl-s30 {
    padding-left: -1.875rem;
  }

  .mx-xl-s30 {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }

  .my-xl-s30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .px-xl-s30 {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .py-xl-s30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .gap-xl-s30 {
    gap: 1.875rem;
  }

  .pm-xl-s30, .gx-xl-s30 > .gx-child {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .gx-xl-s30 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.875rem;
  }

  .gx-xl-s30 > .gx-child:last-of-type {
    padding-left: 1.875rem;
    padding-right: 0;
  }

  .m-xl-s32 {
    margin: 2rem;
  }

  .-m-xl-s32 {
    margin: -2rem;
  }

  .mt-xl-s32 {
    margin-top: 2rem;
  }

  .-mt-xl-s32 {
    margin-top: -2rem;
  }

  .mb-xl-s32 {
    margin-bottom: 2rem;
  }

  .-mb-xl-s32 {
    margin-bottom: -2rem;
  }

  .mr-xl-s32 {
    margin-right: 2rem;
  }

  .-mr-xl-s32 {
    margin-right: -2rem;
  }

  .ml-xl-s32 {
    margin-left: 2rem;
  }

  .-ml-xl-s32 {
    margin-left: -2rem;
  }

  .p-xl-s32 {
    padding: 2rem;
  }

  .-p-xl-s32 {
    padding: -2rem;
  }

  .pt-xl-s32 {
    padding-top: 2rem;
  }

  .-pt-xl-s32 {
    padding-top: -2rem;
  }

  .pb-xl-s32 {
    padding-bottom: 2rem;
  }

  .-pb-xl-s32 {
    padding-bottom: -2rem;
  }

  .pr-xl-s32 {
    padding-right: 2rem;
  }

  .-pr-xl-s32 {
    padding-right: -2rem;
  }

  .pl-xl-s32 {
    padding-left: 2rem;
  }

  .-pl-xl-s32 {
    padding-left: -2rem;
  }

  .mx-xl-s32 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .my-xl-s32 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .px-xl-s32 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .py-xl-s32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .gap-xl-s32 {
    gap: 2rem;
  }

  .pm-xl-s32, .gx-xl-s32 > .gx-child {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .gx-xl-s32 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2rem;
  }

  .gx-xl-s32 > .gx-child:last-of-type {
    padding-left: 2rem;
    padding-right: 0;
  }

  .m-xl-s35 {
    margin: 2.1875rem;
  }

  .-m-xl-s35 {
    margin: -2.1875rem;
  }

  .mt-xl-s35 {
    margin-top: 2.1875rem;
  }

  .-mt-xl-s35 {
    margin-top: -2.1875rem;
  }

  .mb-xl-s35 {
    margin-bottom: 2.1875rem;
  }

  .-mb-xl-s35 {
    margin-bottom: -2.1875rem;
  }

  .mr-xl-s35 {
    margin-right: 2.1875rem;
  }

  .-mr-xl-s35 {
    margin-right: -2.1875rem;
  }

  .ml-xl-s35 {
    margin-left: 2.1875rem;
  }

  .-ml-xl-s35 {
    margin-left: -2.1875rem;
  }

  .p-xl-s35 {
    padding: 2.1875rem;
  }

  .-p-xl-s35 {
    padding: -2.1875rem;
  }

  .pt-xl-s35 {
    padding-top: 2.1875rem;
  }

  .-pt-xl-s35 {
    padding-top: -2.1875rem;
  }

  .pb-xl-s35 {
    padding-bottom: 2.1875rem;
  }

  .-pb-xl-s35 {
    padding-bottom: -2.1875rem;
  }

  .pr-xl-s35 {
    padding-right: 2.1875rem;
  }

  .-pr-xl-s35 {
    padding-right: -2.1875rem;
  }

  .pl-xl-s35 {
    padding-left: 2.1875rem;
  }

  .-pl-xl-s35 {
    padding-left: -2.1875rem;
  }

  .mx-xl-s35 {
    margin-left: 2.1875rem;
    margin-right: 2.1875rem;
  }

  .my-xl-s35 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }

  .px-xl-s35 {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .py-xl-s35 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }

  .gap-xl-s35 {
    gap: 2.1875rem;
  }

  .pm-xl-s35, .gx-xl-s35 > .gx-child {
    padding-left: 1.09375rem;
    padding-right: 1.09375rem;
  }

  .gx-xl-s35 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.1875rem;
  }

  .gx-xl-s35 > .gx-child:last-of-type {
    padding-left: 2.1875rem;
    padding-right: 0;
  }

  .m-xl-s40 {
    margin: 2.5rem;
  }

  .-m-xl-s40 {
    margin: -2.5rem;
  }

  .mt-xl-s40 {
    margin-top: 2.5rem;
  }

  .-mt-xl-s40 {
    margin-top: -2.5rem;
  }

  .mb-xl-s40 {
    margin-bottom: 2.5rem;
  }

  .-mb-xl-s40 {
    margin-bottom: -2.5rem;
  }

  .mr-xl-s40 {
    margin-right: 2.5rem;
  }

  .-mr-xl-s40 {
    margin-right: -2.5rem;
  }

  .ml-xl-s40 {
    margin-left: 2.5rem;
  }

  .-ml-xl-s40 {
    margin-left: -2.5rem;
  }

  .p-xl-s40 {
    padding: 2.5rem;
  }

  .-p-xl-s40 {
    padding: -2.5rem;
  }

  .pt-xl-s40 {
    padding-top: 2.5rem;
  }

  .-pt-xl-s40 {
    padding-top: -2.5rem;
  }

  .pb-xl-s40 {
    padding-bottom: 2.5rem;
  }

  .-pb-xl-s40 {
    padding-bottom: -2.5rem;
  }

  .pr-xl-s40 {
    padding-right: 2.5rem;
  }

  .-pr-xl-s40 {
    padding-right: -2.5rem;
  }

  .pl-xl-s40 {
    padding-left: 2.5rem;
  }

  .-pl-xl-s40 {
    padding-left: -2.5rem;
  }

  .mx-xl-s40 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .my-xl-s40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .px-xl-s40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .py-xl-s40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .gap-xl-s40 {
    gap: 2.5rem;
  }

  .pm-xl-s40, .gx-xl-s40 > .gx-child {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .gx-xl-s40 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.5rem;
  }

  .gx-xl-s40 > .gx-child:last-of-type {
    padding-left: 2.5rem;
    padding-right: 0;
  }

  .m-xl-s45 {
    margin: 2.8125rem;
  }

  .-m-xl-s45 {
    margin: -2.8125rem;
  }

  .mt-xl-s45 {
    margin-top: 2.8125rem;
  }

  .-mt-xl-s45 {
    margin-top: -2.8125rem;
  }

  .mb-xl-s45 {
    margin-bottom: 2.8125rem;
  }

  .-mb-xl-s45 {
    margin-bottom: -2.8125rem;
  }

  .mr-xl-s45 {
    margin-right: 2.8125rem;
  }

  .-mr-xl-s45 {
    margin-right: -2.8125rem;
  }

  .ml-xl-s45 {
    margin-left: 2.8125rem;
  }

  .-ml-xl-s45 {
    margin-left: -2.8125rem;
  }

  .p-xl-s45 {
    padding: 2.8125rem;
  }

  .-p-xl-s45 {
    padding: -2.8125rem;
  }

  .pt-xl-s45 {
    padding-top: 2.8125rem;
  }

  .-pt-xl-s45 {
    padding-top: -2.8125rem;
  }

  .pb-xl-s45 {
    padding-bottom: 2.8125rem;
  }

  .-pb-xl-s45 {
    padding-bottom: -2.8125rem;
  }

  .pr-xl-s45 {
    padding-right: 2.8125rem;
  }

  .-pr-xl-s45 {
    padding-right: -2.8125rem;
  }

  .pl-xl-s45 {
    padding-left: 2.8125rem;
  }

  .-pl-xl-s45 {
    padding-left: -2.8125rem;
  }

  .mx-xl-s45 {
    margin-left: 2.8125rem;
    margin-right: 2.8125rem;
  }

  .my-xl-s45 {
    margin-top: 2.8125rem;
    margin-bottom: 2.8125rem;
  }

  .px-xl-s45 {
    padding-left: 2.8125rem;
    padding-right: 2.8125rem;
  }

  .py-xl-s45 {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }

  .gap-xl-s45 {
    gap: 2.8125rem;
  }

  .pm-xl-s45, .gx-xl-s45 > .gx-child {
    padding-left: 1.40625rem;
    padding-right: 1.40625rem;
  }

  .gx-xl-s45 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.8125rem;
  }

  .gx-xl-s45 > .gx-child:last-of-type {
    padding-left: 2.8125rem;
    padding-right: 0;
  }

  .m-xl-s48 {
    margin: 3rem;
  }

  .-m-xl-s48 {
    margin: -3rem;
  }

  .mt-xl-s48 {
    margin-top: 3rem;
  }

  .-mt-xl-s48 {
    margin-top: -3rem;
  }

  .mb-xl-s48 {
    margin-bottom: 3rem;
  }

  .-mb-xl-s48 {
    margin-bottom: -3rem;
  }

  .mr-xl-s48 {
    margin-right: 3rem;
  }

  .-mr-xl-s48 {
    margin-right: -3rem;
  }

  .ml-xl-s48 {
    margin-left: 3rem;
  }

  .-ml-xl-s48 {
    margin-left: -3rem;
  }

  .p-xl-s48 {
    padding: 3rem;
  }

  .-p-xl-s48 {
    padding: -3rem;
  }

  .pt-xl-s48 {
    padding-top: 3rem;
  }

  .-pt-xl-s48 {
    padding-top: -3rem;
  }

  .pb-xl-s48 {
    padding-bottom: 3rem;
  }

  .-pb-xl-s48 {
    padding-bottom: -3rem;
  }

  .pr-xl-s48 {
    padding-right: 3rem;
  }

  .-pr-xl-s48 {
    padding-right: -3rem;
  }

  .pl-xl-s48 {
    padding-left: 3rem;
  }

  .-pl-xl-s48 {
    padding-left: -3rem;
  }

  .mx-xl-s48 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .my-xl-s48 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .px-xl-s48 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .py-xl-s48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .gap-xl-s48 {
    gap: 3rem;
  }

  .pm-xl-s48, .gx-xl-s48 > .gx-child {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .gx-xl-s48 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3rem;
  }

  .gx-xl-s48 > .gx-child:last-of-type {
    padding-left: 3rem;
    padding-right: 0;
  }

  .m-xl-s50 {
    margin: 3.125rem;
  }

  .-m-xl-s50 {
    margin: -3.125rem;
  }

  .mt-xl-s50 {
    margin-top: 3.125rem;
  }

  .-mt-xl-s50 {
    margin-top: -3.125rem;
  }

  .mb-xl-s50 {
    margin-bottom: 3.125rem;
  }

  .-mb-xl-s50 {
    margin-bottom: -3.125rem;
  }

  .mr-xl-s50 {
    margin-right: 3.125rem;
  }

  .-mr-xl-s50 {
    margin-right: -3.125rem;
  }

  .ml-xl-s50 {
    margin-left: 3.125rem;
  }

  .-ml-xl-s50 {
    margin-left: -3.125rem;
  }

  .p-xl-s50 {
    padding: 3.125rem;
  }

  .-p-xl-s50 {
    padding: -3.125rem;
  }

  .pt-xl-s50 {
    padding-top: 3.125rem;
  }

  .-pt-xl-s50 {
    padding-top: -3.125rem;
  }

  .pb-xl-s50 {
    padding-bottom: 3.125rem;
  }

  .-pb-xl-s50 {
    padding-bottom: -3.125rem;
  }

  .pr-xl-s50 {
    padding-right: 3.125rem;
  }

  .-pr-xl-s50 {
    padding-right: -3.125rem;
  }

  .pl-xl-s50 {
    padding-left: 3.125rem;
  }

  .-pl-xl-s50 {
    padding-left: -3.125rem;
  }

  .mx-xl-s50 {
    margin-left: 3.125rem;
    margin-right: 3.125rem;
  }

  .my-xl-s50 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }

  .px-xl-s50 {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .py-xl-s50 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }

  .gap-xl-s50 {
    gap: 3.125rem;
  }

  .pm-xl-s50, .gx-xl-s50 > .gx-child {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .gx-xl-s50 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.125rem;
  }

  .gx-xl-s50 > .gx-child:last-of-type {
    padding-left: 3.125rem;
    padding-right: 0;
  }

  .m-xl-s60 {
    margin: 3.75rem;
  }

  .-m-xl-s60 {
    margin: -3.75rem;
  }

  .mt-xl-s60 {
    margin-top: 3.75rem;
  }

  .-mt-xl-s60 {
    margin-top: -3.75rem;
  }

  .mb-xl-s60 {
    margin-bottom: 3.75rem;
  }

  .-mb-xl-s60 {
    margin-bottom: -3.75rem;
  }

  .mr-xl-s60 {
    margin-right: 3.75rem;
  }

  .-mr-xl-s60 {
    margin-right: -3.75rem;
  }

  .ml-xl-s60 {
    margin-left: 3.75rem;
  }

  .-ml-xl-s60 {
    margin-left: -3.75rem;
  }

  .p-xl-s60 {
    padding: 3.75rem;
  }

  .-p-xl-s60 {
    padding: -3.75rem;
  }

  .pt-xl-s60 {
    padding-top: 3.75rem;
  }

  .-pt-xl-s60 {
    padding-top: -3.75rem;
  }

  .pb-xl-s60 {
    padding-bottom: 3.75rem;
  }

  .-pb-xl-s60 {
    padding-bottom: -3.75rem;
  }

  .pr-xl-s60 {
    padding-right: 3.75rem;
  }

  .-pr-xl-s60 {
    padding-right: -3.75rem;
  }

  .pl-xl-s60 {
    padding-left: 3.75rem;
  }

  .-pl-xl-s60 {
    padding-left: -3.75rem;
  }

  .mx-xl-s60 {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
  }

  .my-xl-s60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .px-xl-s60 {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .py-xl-s60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  .gap-xl-s60 {
    gap: 3.75rem;
  }

  .pm-xl-s60, .gx-xl-s60 > .gx-child {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .gx-xl-s60 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.75rem;
  }

  .gx-xl-s60 > .gx-child:last-of-type {
    padding-left: 3.75rem;
    padding-right: 0;
  }

  .m-xl-s70 {
    margin: 4.375rem;
  }

  .-m-xl-s70 {
    margin: -4.375rem;
  }

  .mt-xl-s70 {
    margin-top: 4.375rem;
  }

  .-mt-xl-s70 {
    margin-top: -4.375rem;
  }

  .mb-xl-s70 {
    margin-bottom: 4.375rem;
  }

  .-mb-xl-s70 {
    margin-bottom: -4.375rem;
  }

  .mr-xl-s70 {
    margin-right: 4.375rem;
  }

  .-mr-xl-s70 {
    margin-right: -4.375rem;
  }

  .ml-xl-s70 {
    margin-left: 4.375rem;
  }

  .-ml-xl-s70 {
    margin-left: -4.375rem;
  }

  .p-xl-s70 {
    padding: 4.375rem;
  }

  .-p-xl-s70 {
    padding: -4.375rem;
  }

  .pt-xl-s70 {
    padding-top: 4.375rem;
  }

  .-pt-xl-s70 {
    padding-top: -4.375rem;
  }

  .pb-xl-s70 {
    padding-bottom: 4.375rem;
  }

  .-pb-xl-s70 {
    padding-bottom: -4.375rem;
  }

  .pr-xl-s70 {
    padding-right: 4.375rem;
  }

  .-pr-xl-s70 {
    padding-right: -4.375rem;
  }

  .pl-xl-s70 {
    padding-left: 4.375rem;
  }

  .-pl-xl-s70 {
    padding-left: -4.375rem;
  }

  .mx-xl-s70 {
    margin-left: 4.375rem;
    margin-right: 4.375rem;
  }

  .my-xl-s70 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }

  .px-xl-s70 {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }

  .py-xl-s70 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }

  .gap-xl-s70 {
    gap: 4.375rem;
  }

  .pm-xl-s70, .gx-xl-s70 > .gx-child {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .gx-xl-s70 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 4.375rem;
  }

  .gx-xl-s70 > .gx-child:last-of-type {
    padding-left: 4.375rem;
    padding-right: 0;
  }

  .m-xl-s80 {
    margin: 5rem;
  }

  .-m-xl-s80 {
    margin: -5rem;
  }

  .mt-xl-s80 {
    margin-top: 5rem;
  }

  .-mt-xl-s80 {
    margin-top: -5rem;
  }

  .mb-xl-s80 {
    margin-bottom: 5rem;
  }

  .-mb-xl-s80 {
    margin-bottom: -5rem;
  }

  .mr-xl-s80 {
    margin-right: 5rem;
  }

  .-mr-xl-s80 {
    margin-right: -5rem;
  }

  .ml-xl-s80 {
    margin-left: 5rem;
  }

  .-ml-xl-s80 {
    margin-left: -5rem;
  }

  .p-xl-s80 {
    padding: 5rem;
  }

  .-p-xl-s80 {
    padding: -5rem;
  }

  .pt-xl-s80 {
    padding-top: 5rem;
  }

  .-pt-xl-s80 {
    padding-top: -5rem;
  }

  .pb-xl-s80 {
    padding-bottom: 5rem;
  }

  .-pb-xl-s80 {
    padding-bottom: -5rem;
  }

  .pr-xl-s80 {
    padding-right: 5rem;
  }

  .-pr-xl-s80 {
    padding-right: -5rem;
  }

  .pl-xl-s80 {
    padding-left: 5rem;
  }

  .-pl-xl-s80 {
    padding-left: -5rem;
  }

  .mx-xl-s80 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .my-xl-s80 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .px-xl-s80 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .py-xl-s80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .gap-xl-s80 {
    gap: 5rem;
  }

  .pm-xl-s80, .gx-xl-s80 > .gx-child {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .gx-xl-s80 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 5rem;
  }

  .gx-xl-s80 > .gx-child:last-of-type {
    padding-left: 5rem;
    padding-right: 0;
  }

  .m-xl-s100 {
    margin: 6.25rem;
  }

  .-m-xl-s100 {
    margin: -6.25rem;
  }

  .mt-xl-s100 {
    margin-top: 6.25rem;
  }

  .-mt-xl-s100 {
    margin-top: -6.25rem;
  }

  .mb-xl-s100 {
    margin-bottom: 6.25rem;
  }

  .-mb-xl-s100 {
    margin-bottom: -6.25rem;
  }

  .mr-xl-s100 {
    margin-right: 6.25rem;
  }

  .-mr-xl-s100 {
    margin-right: -6.25rem;
  }

  .ml-xl-s100 {
    margin-left: 6.25rem;
  }

  .-ml-xl-s100 {
    margin-left: -6.25rem;
  }

  .p-xl-s100 {
    padding: 6.25rem;
  }

  .-p-xl-s100 {
    padding: -6.25rem;
  }

  .pt-xl-s100 {
    padding-top: 6.25rem;
  }

  .-pt-xl-s100 {
    padding-top: -6.25rem;
  }

  .pb-xl-s100 {
    padding-bottom: 6.25rem;
  }

  .-pb-xl-s100 {
    padding-bottom: -6.25rem;
  }

  .pr-xl-s100 {
    padding-right: 6.25rem;
  }

  .-pr-xl-s100 {
    padding-right: -6.25rem;
  }

  .pl-xl-s100 {
    padding-left: 6.25rem;
  }

  .-pl-xl-s100 {
    padding-left: -6.25rem;
  }

  .mx-xl-s100 {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
  }

  .my-xl-s100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }

  .px-xl-s100 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }

  .py-xl-s100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  .gap-xl-s100 {
    gap: 6.25rem;
  }

  .pm-xl-s100, .gx-xl-s100 > .gx-child {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .gx-xl-s100 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 6.25rem;
  }

  .gx-xl-s100 > .gx-child:last-of-type {
    padding-left: 6.25rem;
    padding-right: 0;
  }

  .m-xl-s120 {
    margin: 7.5rem;
  }

  .-m-xl-s120 {
    margin: -7.5rem;
  }

  .mt-xl-s120 {
    margin-top: 7.5rem;
  }

  .-mt-xl-s120 {
    margin-top: -7.5rem;
  }

  .mb-xl-s120 {
    margin-bottom: 7.5rem;
  }

  .-mb-xl-s120 {
    margin-bottom: -7.5rem;
  }

  .mr-xl-s120 {
    margin-right: 7.5rem;
  }

  .-mr-xl-s120 {
    margin-right: -7.5rem;
  }

  .ml-xl-s120 {
    margin-left: 7.5rem;
  }

  .-ml-xl-s120 {
    margin-left: -7.5rem;
  }

  .p-xl-s120 {
    padding: 7.5rem;
  }

  .-p-xl-s120 {
    padding: -7.5rem;
  }

  .pt-xl-s120 {
    padding-top: 7.5rem;
  }

  .-pt-xl-s120 {
    padding-top: -7.5rem;
  }

  .pb-xl-s120 {
    padding-bottom: 7.5rem;
  }

  .-pb-xl-s120 {
    padding-bottom: -7.5rem;
  }

  .pr-xl-s120 {
    padding-right: 7.5rem;
  }

  .-pr-xl-s120 {
    padding-right: -7.5rem;
  }

  .pl-xl-s120 {
    padding-left: 7.5rem;
  }

  .-pl-xl-s120 {
    padding-left: -7.5rem;
  }

  .mx-xl-s120 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }

  .my-xl-s120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }

  .px-xl-s120 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }

  .py-xl-s120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }

  .gap-xl-s120 {
    gap: 7.5rem;
  }

  .pm-xl-s120, .gx-xl-s120 > .gx-child {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .gx-xl-s120 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 7.5rem;
  }

  .gx-xl-s120 > .gx-child:last-of-type {
    padding-left: 7.5rem;
    padding-right: 0;
  }

  .m-xl-s150 {
    margin: 9.375rem;
  }

  .-m-xl-s150 {
    margin: -9.375rem;
  }

  .mt-xl-s150 {
    margin-top: 9.375rem;
  }

  .-mt-xl-s150 {
    margin-top: -9.375rem;
  }

  .mb-xl-s150 {
    margin-bottom: 9.375rem;
  }

  .-mb-xl-s150 {
    margin-bottom: -9.375rem;
  }

  .mr-xl-s150 {
    margin-right: 9.375rem;
  }

  .-mr-xl-s150 {
    margin-right: -9.375rem;
  }

  .ml-xl-s150 {
    margin-left: 9.375rem;
  }

  .-ml-xl-s150 {
    margin-left: -9.375rem;
  }

  .p-xl-s150 {
    padding: 9.375rem;
  }

  .-p-xl-s150 {
    padding: -9.375rem;
  }

  .pt-xl-s150 {
    padding-top: 9.375rem;
  }

  .-pt-xl-s150 {
    padding-top: -9.375rem;
  }

  .pb-xl-s150 {
    padding-bottom: 9.375rem;
  }

  .-pb-xl-s150 {
    padding-bottom: -9.375rem;
  }

  .pr-xl-s150 {
    padding-right: 9.375rem;
  }

  .-pr-xl-s150 {
    padding-right: -9.375rem;
  }

  .pl-xl-s150 {
    padding-left: 9.375rem;
  }

  .-pl-xl-s150 {
    padding-left: -9.375rem;
  }

  .mx-xl-s150 {
    margin-left: 9.375rem;
    margin-right: 9.375rem;
  }

  .my-xl-s150 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }

  .px-xl-s150 {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
  }

  .py-xl-s150 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }

  .gap-xl-s150 {
    gap: 9.375rem;
  }

  .pm-xl-s150, .gx-xl-s150 > .gx-child {
    padding-left: 4.6875rem;
    padding-right: 4.6875rem;
  }

  .gx-xl-s150 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 9.375rem;
  }

  .gx-xl-s150 > .gx-child:last-of-type {
    padding-left: 9.375rem;
    padding-right: 0;
  }
}

@media screen and (min-width: 1400px) {
  .m-lxl-s0, .-m-lxl-s0 {
    margin: 0;
  }

  .mt-lxl-s0, .-mt-lxl-s0 {
    margin-top: 0;
  }

  .mb-lxl-s0, .-mb-lxl-s0 {
    margin-bottom: 0;
  }

  .mr-lxl-s0, .-mr-lxl-s0 {
    margin-right: 0;
  }

  .ml-lxl-s0, .-ml-lxl-s0 {
    margin-left: 0;
  }

  .p-lxl-s0, .-p-lxl-s0 {
    padding: 0;
  }

  .pt-lxl-s0, .-pt-lxl-s0 {
    padding-top: 0;
  }

  .pb-lxl-s0, .-pb-lxl-s0 {
    padding-bottom: 0;
  }

  .pr-lxl-s0, .-pr-lxl-s0 {
    padding-right: 0;
  }

  .pl-lxl-s0, .-pl-lxl-s0 {
    padding-left: 0;
  }

  .mx-lxl-s0 {
    margin-left: 0;
    margin-right: 0;
  }

  .my-lxl-s0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .px-lxl-s0 {
    padding-left: 0;
    padding-right: 0;
  }

  .py-lxl-s0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .gap-lxl-s0 {
    gap: 0;
  }

  .pm-lxl-s0, .gx-lxl-s0 > .gx-child, .gx-lxl-s0 > .gx-child:first-of-type, .gx-lxl-s0 > .gx-child:last-of-type {
    padding-left: 0;
    padding-right: 0;
  }

  .m-lxl-s2 {
    margin: .125rem;
  }

  .-m-lxl-s2 {
    margin: -.125rem;
  }

  .mt-lxl-s2 {
    margin-top: .125rem;
  }

  .-mt-lxl-s2 {
    margin-top: -.125rem;
  }

  .mb-lxl-s2 {
    margin-bottom: .125rem;
  }

  .-mb-lxl-s2 {
    margin-bottom: -.125rem;
  }

  .mr-lxl-s2 {
    margin-right: .125rem;
  }

  .-mr-lxl-s2 {
    margin-right: -.125rem;
  }

  .ml-lxl-s2 {
    margin-left: .125rem;
  }

  .-ml-lxl-s2 {
    margin-left: -.125rem;
  }

  .p-lxl-s2 {
    padding: .125rem;
  }

  .-p-lxl-s2 {
    padding: -.125rem;
  }

  .pt-lxl-s2 {
    padding-top: .125rem;
  }

  .-pt-lxl-s2 {
    padding-top: -.125rem;
  }

  .pb-lxl-s2 {
    padding-bottom: .125rem;
  }

  .-pb-lxl-s2 {
    padding-bottom: -.125rem;
  }

  .pr-lxl-s2 {
    padding-right: .125rem;
  }

  .-pr-lxl-s2 {
    padding-right: -.125rem;
  }

  .pl-lxl-s2 {
    padding-left: .125rem;
  }

  .-pl-lxl-s2 {
    padding-left: -.125rem;
  }

  .mx-lxl-s2 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .my-lxl-s2 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .px-lxl-s2 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .py-lxl-s2 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .gap-lxl-s2 {
    gap: .125rem;
  }

  .pm-lxl-s2, .gx-lxl-s2 > .gx-child {
    padding-left: .0625rem;
    padding-right: .0625rem;
  }

  .gx-lxl-s2 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .125rem;
  }

  .gx-lxl-s2 > .gx-child:last-of-type {
    padding-left: .125rem;
    padding-right: 0;
  }

  .m-lxl-s3 {
    margin: .1875rem;
  }

  .-m-lxl-s3 {
    margin: -.1875rem;
  }

  .mt-lxl-s3 {
    margin-top: .1875rem;
  }

  .-mt-lxl-s3 {
    margin-top: -.1875rem;
  }

  .mb-lxl-s3 {
    margin-bottom: .1875rem;
  }

  .-mb-lxl-s3 {
    margin-bottom: -.1875rem;
  }

  .mr-lxl-s3 {
    margin-right: .1875rem;
  }

  .-mr-lxl-s3 {
    margin-right: -.1875rem;
  }

  .ml-lxl-s3 {
    margin-left: .1875rem;
  }

  .-ml-lxl-s3 {
    margin-left: -.1875rem;
  }

  .p-lxl-s3 {
    padding: .1875rem;
  }

  .-p-lxl-s3 {
    padding: -.1875rem;
  }

  .pt-lxl-s3 {
    padding-top: .1875rem;
  }

  .-pt-lxl-s3 {
    padding-top: -.1875rem;
  }

  .pb-lxl-s3 {
    padding-bottom: .1875rem;
  }

  .-pb-lxl-s3 {
    padding-bottom: -.1875rem;
  }

  .pr-lxl-s3 {
    padding-right: .1875rem;
  }

  .-pr-lxl-s3 {
    padding-right: -.1875rem;
  }

  .pl-lxl-s3 {
    padding-left: .1875rem;
  }

  .-pl-lxl-s3 {
    padding-left: -.1875rem;
  }

  .mx-lxl-s3 {
    margin-left: .1875rem;
    margin-right: .1875rem;
  }

  .my-lxl-s3 {
    margin-top: .1875rem;
    margin-bottom: .1875rem;
  }

  .px-lxl-s3 {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .py-lxl-s3 {
    padding-top: .1875rem;
    padding-bottom: .1875rem;
  }

  .gap-lxl-s3 {
    gap: .1875rem;
  }

  .pm-lxl-s3, .gx-lxl-s3 > .gx-child {
    padding-left: .09375rem;
    padding-right: .09375rem;
  }

  .gx-lxl-s3 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .1875rem;
  }

  .gx-lxl-s3 > .gx-child:last-of-type {
    padding-left: .1875rem;
    padding-right: 0;
  }

  .m-lxl-s4 {
    margin: .25rem;
  }

  .-m-lxl-s4 {
    margin: -.25rem;
  }

  .mt-lxl-s4 {
    margin-top: .25rem;
  }

  .-mt-lxl-s4 {
    margin-top: -.25rem;
  }

  .mb-lxl-s4 {
    margin-bottom: .25rem;
  }

  .-mb-lxl-s4 {
    margin-bottom: -.25rem;
  }

  .mr-lxl-s4 {
    margin-right: .25rem;
  }

  .-mr-lxl-s4 {
    margin-right: -.25rem;
  }

  .ml-lxl-s4 {
    margin-left: .25rem;
  }

  .-ml-lxl-s4 {
    margin-left: -.25rem;
  }

  .p-lxl-s4 {
    padding: .25rem;
  }

  .-p-lxl-s4 {
    padding: -.25rem;
  }

  .pt-lxl-s4 {
    padding-top: .25rem;
  }

  .-pt-lxl-s4 {
    padding-top: -.25rem;
  }

  .pb-lxl-s4 {
    padding-bottom: .25rem;
  }

  .-pb-lxl-s4 {
    padding-bottom: -.25rem;
  }

  .pr-lxl-s4 {
    padding-right: .25rem;
  }

  .-pr-lxl-s4 {
    padding-right: -.25rem;
  }

  .pl-lxl-s4 {
    padding-left: .25rem;
  }

  .-pl-lxl-s4 {
    padding-left: -.25rem;
  }

  .mx-lxl-s4 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .my-lxl-s4 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .px-lxl-s4 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .py-lxl-s4 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .gap-lxl-s4 {
    gap: .25rem;
  }

  .pm-lxl-s4, .gx-lxl-s4 > .gx-child {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .gx-lxl-s4 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .25rem;
  }

  .gx-lxl-s4 > .gx-child:last-of-type {
    padding-left: .25rem;
    padding-right: 0;
  }

  .m-lxl-s5 {
    margin: .3125rem;
  }

  .-m-lxl-s5 {
    margin: -.3125rem;
  }

  .mt-lxl-s5 {
    margin-top: .3125rem;
  }

  .-mt-lxl-s5 {
    margin-top: -.3125rem;
  }

  .mb-lxl-s5 {
    margin-bottom: .3125rem;
  }

  .-mb-lxl-s5 {
    margin-bottom: -.3125rem;
  }

  .mr-lxl-s5 {
    margin-right: .3125rem;
  }

  .-mr-lxl-s5 {
    margin-right: -.3125rem;
  }

  .ml-lxl-s5 {
    margin-left: .3125rem;
  }

  .-ml-lxl-s5 {
    margin-left: -.3125rem;
  }

  .p-lxl-s5 {
    padding: .3125rem;
  }

  .-p-lxl-s5 {
    padding: -.3125rem;
  }

  .pt-lxl-s5 {
    padding-top: .3125rem;
  }

  .-pt-lxl-s5 {
    padding-top: -.3125rem;
  }

  .pb-lxl-s5 {
    padding-bottom: .3125rem;
  }

  .-pb-lxl-s5 {
    padding-bottom: -.3125rem;
  }

  .pr-lxl-s5 {
    padding-right: .3125rem;
  }

  .-pr-lxl-s5 {
    padding-right: -.3125rem;
  }

  .pl-lxl-s5 {
    padding-left: .3125rem;
  }

  .-pl-lxl-s5 {
    padding-left: -.3125rem;
  }

  .mx-lxl-s5 {
    margin-left: .3125rem;
    margin-right: .3125rem;
  }

  .my-lxl-s5 {
    margin-top: .3125rem;
    margin-bottom: .3125rem;
  }

  .px-lxl-s5 {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .py-lxl-s5 {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
  }

  .gap-lxl-s5 {
    gap: .3125rem;
  }

  .pm-lxl-s5, .gx-lxl-s5 > .gx-child {
    padding-left: .15625rem;
    padding-right: .15625rem;
  }

  .gx-lxl-s5 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .3125rem;
  }

  .gx-lxl-s5 > .gx-child:last-of-type {
    padding-left: .3125rem;
    padding-right: 0;
  }

  .m-lxl-s6 {
    margin: .375rem;
  }

  .-m-lxl-s6 {
    margin: -.375rem;
  }

  .mt-lxl-s6 {
    margin-top: .375rem;
  }

  .-mt-lxl-s6 {
    margin-top: -.375rem;
  }

  .mb-lxl-s6 {
    margin-bottom: .375rem;
  }

  .-mb-lxl-s6 {
    margin-bottom: -.375rem;
  }

  .mr-lxl-s6 {
    margin-right: .375rem;
  }

  .-mr-lxl-s6 {
    margin-right: -.375rem;
  }

  .ml-lxl-s6 {
    margin-left: .375rem;
  }

  .-ml-lxl-s6 {
    margin-left: -.375rem;
  }

  .p-lxl-s6 {
    padding: .375rem;
  }

  .-p-lxl-s6 {
    padding: -.375rem;
  }

  .pt-lxl-s6 {
    padding-top: .375rem;
  }

  .-pt-lxl-s6 {
    padding-top: -.375rem;
  }

  .pb-lxl-s6 {
    padding-bottom: .375rem;
  }

  .-pb-lxl-s6 {
    padding-bottom: -.375rem;
  }

  .pr-lxl-s6 {
    padding-right: .375rem;
  }

  .-pr-lxl-s6 {
    padding-right: -.375rem;
  }

  .pl-lxl-s6 {
    padding-left: .375rem;
  }

  .-pl-lxl-s6 {
    padding-left: -.375rem;
  }

  .mx-lxl-s6 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .my-lxl-s6 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .px-lxl-s6 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .py-lxl-s6 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .gap-lxl-s6 {
    gap: .375rem;
  }

  .pm-lxl-s6, .gx-lxl-s6 > .gx-child {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .gx-lxl-s6 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .375rem;
  }

  .gx-lxl-s6 > .gx-child:last-of-type {
    padding-left: .375rem;
    padding-right: 0;
  }

  .m-lxl-s7 {
    margin: .4375rem;
  }

  .-m-lxl-s7 {
    margin: -.4375rem;
  }

  .mt-lxl-s7 {
    margin-top: .4375rem;
  }

  .-mt-lxl-s7 {
    margin-top: -.4375rem;
  }

  .mb-lxl-s7 {
    margin-bottom: .4375rem;
  }

  .-mb-lxl-s7 {
    margin-bottom: -.4375rem;
  }

  .mr-lxl-s7 {
    margin-right: .4375rem;
  }

  .-mr-lxl-s7 {
    margin-right: -.4375rem;
  }

  .ml-lxl-s7 {
    margin-left: .4375rem;
  }

  .-ml-lxl-s7 {
    margin-left: -.4375rem;
  }

  .p-lxl-s7 {
    padding: .4375rem;
  }

  .-p-lxl-s7 {
    padding: -.4375rem;
  }

  .pt-lxl-s7 {
    padding-top: .4375rem;
  }

  .-pt-lxl-s7 {
    padding-top: -.4375rem;
  }

  .pb-lxl-s7 {
    padding-bottom: .4375rem;
  }

  .-pb-lxl-s7 {
    padding-bottom: -.4375rem;
  }

  .pr-lxl-s7 {
    padding-right: .4375rem;
  }

  .-pr-lxl-s7 {
    padding-right: -.4375rem;
  }

  .pl-lxl-s7 {
    padding-left: .4375rem;
  }

  .-pl-lxl-s7 {
    padding-left: -.4375rem;
  }

  .mx-lxl-s7 {
    margin-left: .4375rem;
    margin-right: .4375rem;
  }

  .my-lxl-s7 {
    margin-top: .4375rem;
    margin-bottom: .4375rem;
  }

  .px-lxl-s7 {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .py-lxl-s7 {
    padding-top: .4375rem;
    padding-bottom: .4375rem;
  }

  .gap-lxl-s7 {
    gap: .4375rem;
  }

  .pm-lxl-s7, .gx-lxl-s7 > .gx-child {
    padding-left: .21875rem;
    padding-right: .21875rem;
  }

  .gx-lxl-s7 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .4375rem;
  }

  .gx-lxl-s7 > .gx-child:last-of-type {
    padding-left: .4375rem;
    padding-right: 0;
  }

  .m-lxl-s8 {
    margin: .5rem;
  }

  .-m-lxl-s8 {
    margin: -.5rem;
  }

  .mt-lxl-s8 {
    margin-top: .5rem;
  }

  .-mt-lxl-s8 {
    margin-top: -.5rem;
  }

  .mb-lxl-s8 {
    margin-bottom: .5rem;
  }

  .-mb-lxl-s8 {
    margin-bottom: -.5rem;
  }

  .mr-lxl-s8 {
    margin-right: .5rem;
  }

  .-mr-lxl-s8 {
    margin-right: -.5rem;
  }

  .ml-lxl-s8 {
    margin-left: .5rem;
  }

  .-ml-lxl-s8 {
    margin-left: -.5rem;
  }

  .p-lxl-s8 {
    padding: .5rem;
  }

  .-p-lxl-s8 {
    padding: -.5rem;
  }

  .pt-lxl-s8 {
    padding-top: .5rem;
  }

  .-pt-lxl-s8 {
    padding-top: -.5rem;
  }

  .pb-lxl-s8 {
    padding-bottom: .5rem;
  }

  .-pb-lxl-s8 {
    padding-bottom: -.5rem;
  }

  .pr-lxl-s8 {
    padding-right: .5rem;
  }

  .-pr-lxl-s8 {
    padding-right: -.5rem;
  }

  .pl-lxl-s8 {
    padding-left: .5rem;
  }

  .-pl-lxl-s8 {
    padding-left: -.5rem;
  }

  .mx-lxl-s8 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .my-lxl-s8 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .px-lxl-s8 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .py-lxl-s8 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .gap-lxl-s8 {
    gap: .5rem;
  }

  .pm-lxl-s8, .gx-lxl-s8 > .gx-child {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .gx-lxl-s8 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5rem;
  }

  .gx-lxl-s8 > .gx-child:last-of-type {
    padding-left: .5rem;
    padding-right: 0;
  }

  .m-lxl-s9 {
    margin: .5625rem;
  }

  .-m-lxl-s9 {
    margin: -.5625rem;
  }

  .mt-lxl-s9 {
    margin-top: .5625rem;
  }

  .-mt-lxl-s9 {
    margin-top: -.5625rem;
  }

  .mb-lxl-s9 {
    margin-bottom: .5625rem;
  }

  .-mb-lxl-s9 {
    margin-bottom: -.5625rem;
  }

  .mr-lxl-s9 {
    margin-right: .5625rem;
  }

  .-mr-lxl-s9 {
    margin-right: -.5625rem;
  }

  .ml-lxl-s9 {
    margin-left: .5625rem;
  }

  .-ml-lxl-s9 {
    margin-left: -.5625rem;
  }

  .p-lxl-s9 {
    padding: .5625rem;
  }

  .-p-lxl-s9 {
    padding: -.5625rem;
  }

  .pt-lxl-s9 {
    padding-top: .5625rem;
  }

  .-pt-lxl-s9 {
    padding-top: -.5625rem;
  }

  .pb-lxl-s9 {
    padding-bottom: .5625rem;
  }

  .-pb-lxl-s9 {
    padding-bottom: -.5625rem;
  }

  .pr-lxl-s9 {
    padding-right: .5625rem;
  }

  .-pr-lxl-s9 {
    padding-right: -.5625rem;
  }

  .pl-lxl-s9 {
    padding-left: .5625rem;
  }

  .-pl-lxl-s9 {
    padding-left: -.5625rem;
  }

  .mx-lxl-s9 {
    margin-left: .5625rem;
    margin-right: .5625rem;
  }

  .my-lxl-s9 {
    margin-top: .5625rem;
    margin-bottom: .5625rem;
  }

  .px-lxl-s9 {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .py-lxl-s9 {
    padding-top: .5625rem;
    padding-bottom: .5625rem;
  }

  .gap-lxl-s9 {
    gap: .5625rem;
  }

  .pm-lxl-s9, .gx-lxl-s9 > .gx-child {
    padding-left: .28125rem;
    padding-right: .28125rem;
  }

  .gx-lxl-s9 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5625rem;
  }

  .gx-lxl-s9 > .gx-child:last-of-type {
    padding-left: .5625rem;
    padding-right: 0;
  }

  .m-lxl-s10 {
    margin: .625rem;
  }

  .-m-lxl-s10 {
    margin: -.625rem;
  }

  .mt-lxl-s10 {
    margin-top: .625rem;
  }

  .-mt-lxl-s10 {
    margin-top: -.625rem;
  }

  .mb-lxl-s10 {
    margin-bottom: .625rem;
  }

  .-mb-lxl-s10 {
    margin-bottom: -.625rem;
  }

  .mr-lxl-s10 {
    margin-right: .625rem;
  }

  .-mr-lxl-s10 {
    margin-right: -.625rem;
  }

  .ml-lxl-s10 {
    margin-left: .625rem;
  }

  .-ml-lxl-s10 {
    margin-left: -.625rem;
  }

  .p-lxl-s10 {
    padding: .625rem;
  }

  .-p-lxl-s10 {
    padding: -.625rem;
  }

  .pt-lxl-s10 {
    padding-top: .625rem;
  }

  .-pt-lxl-s10 {
    padding-top: -.625rem;
  }

  .pb-lxl-s10 {
    padding-bottom: .625rem;
  }

  .-pb-lxl-s10 {
    padding-bottom: -.625rem;
  }

  .pr-lxl-s10 {
    padding-right: .625rem;
  }

  .-pr-lxl-s10 {
    padding-right: -.625rem;
  }

  .pl-lxl-s10 {
    padding-left: .625rem;
  }

  .-pl-lxl-s10 {
    padding-left: -.625rem;
  }

  .mx-lxl-s10 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .my-lxl-s10 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .px-lxl-s10 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .py-lxl-s10 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .gap-lxl-s10 {
    gap: .625rem;
  }

  .pm-lxl-s10, .gx-lxl-s10 > .gx-child {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .gx-lxl-s10 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .625rem;
  }

  .gx-lxl-s10 > .gx-child:last-of-type {
    padding-left: .625rem;
    padding-right: 0;
  }

  .m-lxl-s12 {
    margin: .75rem;
  }

  .-m-lxl-s12 {
    margin: -.75rem;
  }

  .mt-lxl-s12 {
    margin-top: .75rem;
  }

  .-mt-lxl-s12 {
    margin-top: -.75rem;
  }

  .mb-lxl-s12 {
    margin-bottom: .75rem;
  }

  .-mb-lxl-s12 {
    margin-bottom: -.75rem;
  }

  .mr-lxl-s12 {
    margin-right: .75rem;
  }

  .-mr-lxl-s12 {
    margin-right: -.75rem;
  }

  .ml-lxl-s12 {
    margin-left: .75rem;
  }

  .-ml-lxl-s12 {
    margin-left: -.75rem;
  }

  .p-lxl-s12 {
    padding: .75rem;
  }

  .-p-lxl-s12 {
    padding: -.75rem;
  }

  .pt-lxl-s12 {
    padding-top: .75rem;
  }

  .-pt-lxl-s12 {
    padding-top: -.75rem;
  }

  .pb-lxl-s12 {
    padding-bottom: .75rem;
  }

  .-pb-lxl-s12 {
    padding-bottom: -.75rem;
  }

  .pr-lxl-s12 {
    padding-right: .75rem;
  }

  .-pr-lxl-s12 {
    padding-right: -.75rem;
  }

  .pl-lxl-s12 {
    padding-left: .75rem;
  }

  .-pl-lxl-s12 {
    padding-left: -.75rem;
  }

  .mx-lxl-s12 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .my-lxl-s12 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .px-lxl-s12 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .py-lxl-s12 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .gap-lxl-s12 {
    gap: .75rem;
  }

  .pm-lxl-s12, .gx-lxl-s12 > .gx-child {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .gx-lxl-s12 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .75rem;
  }

  .gx-lxl-s12 > .gx-child:last-of-type {
    padding-left: .75rem;
    padding-right: 0;
  }

  .m-lxl-s13 {
    margin: .8125rem;
  }

  .-m-lxl-s13 {
    margin: -.8125rem;
  }

  .mt-lxl-s13 {
    margin-top: .8125rem;
  }

  .-mt-lxl-s13 {
    margin-top: -.8125rem;
  }

  .mb-lxl-s13 {
    margin-bottom: .8125rem;
  }

  .-mb-lxl-s13 {
    margin-bottom: -.8125rem;
  }

  .mr-lxl-s13 {
    margin-right: .8125rem;
  }

  .-mr-lxl-s13 {
    margin-right: -.8125rem;
  }

  .ml-lxl-s13 {
    margin-left: .8125rem;
  }

  .-ml-lxl-s13 {
    margin-left: -.8125rem;
  }

  .p-lxl-s13 {
    padding: .8125rem;
  }

  .-p-lxl-s13 {
    padding: -.8125rem;
  }

  .pt-lxl-s13 {
    padding-top: .8125rem;
  }

  .-pt-lxl-s13 {
    padding-top: -.8125rem;
  }

  .pb-lxl-s13 {
    padding-bottom: .8125rem;
  }

  .-pb-lxl-s13 {
    padding-bottom: -.8125rem;
  }

  .pr-lxl-s13 {
    padding-right: .8125rem;
  }

  .-pr-lxl-s13 {
    padding-right: -.8125rem;
  }

  .pl-lxl-s13 {
    padding-left: .8125rem;
  }

  .-pl-lxl-s13 {
    padding-left: -.8125rem;
  }

  .mx-lxl-s13 {
    margin-left: .8125rem;
    margin-right: .8125rem;
  }

  .my-lxl-s13 {
    margin-top: .8125rem;
    margin-bottom: .8125rem;
  }

  .px-lxl-s13 {
    padding-left: .8125rem;
    padding-right: .8125rem;
  }

  .py-lxl-s13 {
    padding-top: .8125rem;
    padding-bottom: .8125rem;
  }

  .gap-lxl-s13 {
    gap: .8125rem;
  }

  .pm-lxl-s13, .gx-lxl-s13 > .gx-child {
    padding-left: .40625rem;
    padding-right: .40625rem;
  }

  .gx-lxl-s13 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .8125rem;
  }

  .gx-lxl-s13 > .gx-child:last-of-type {
    padding-left: .8125rem;
    padding-right: 0;
  }

  .m-lxl-s14 {
    margin: .875rem;
  }

  .-m-lxl-s14 {
    margin: -.875rem;
  }

  .mt-lxl-s14 {
    margin-top: .875rem;
  }

  .-mt-lxl-s14 {
    margin-top: -.875rem;
  }

  .mb-lxl-s14 {
    margin-bottom: .875rem;
  }

  .-mb-lxl-s14 {
    margin-bottom: -.875rem;
  }

  .mr-lxl-s14 {
    margin-right: .875rem;
  }

  .-mr-lxl-s14 {
    margin-right: -.875rem;
  }

  .ml-lxl-s14 {
    margin-left: .875rem;
  }

  .-ml-lxl-s14 {
    margin-left: -.875rem;
  }

  .p-lxl-s14 {
    padding: .875rem;
  }

  .-p-lxl-s14 {
    padding: -.875rem;
  }

  .pt-lxl-s14 {
    padding-top: .875rem;
  }

  .-pt-lxl-s14 {
    padding-top: -.875rem;
  }

  .pb-lxl-s14 {
    padding-bottom: .875rem;
  }

  .-pb-lxl-s14 {
    padding-bottom: -.875rem;
  }

  .pr-lxl-s14 {
    padding-right: .875rem;
  }

  .-pr-lxl-s14 {
    padding-right: -.875rem;
  }

  .pl-lxl-s14 {
    padding-left: .875rem;
  }

  .-pl-lxl-s14 {
    padding-left: -.875rem;
  }

  .mx-lxl-s14 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .my-lxl-s14 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .px-lxl-s14 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .py-lxl-s14 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .gap-lxl-s14 {
    gap: .875rem;
  }

  .pm-lxl-s14, .gx-lxl-s14 > .gx-child {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .gx-lxl-s14 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .875rem;
  }

  .gx-lxl-s14 > .gx-child:last-of-type {
    padding-left: .875rem;
    padding-right: 0;
  }

  .m-lxl-s15 {
    margin: .9375rem;
  }

  .-m-lxl-s15 {
    margin: -.9375rem;
  }

  .mt-lxl-s15 {
    margin-top: .9375rem;
  }

  .-mt-lxl-s15 {
    margin-top: -.9375rem;
  }

  .mb-lxl-s15 {
    margin-bottom: .9375rem;
  }

  .-mb-lxl-s15 {
    margin-bottom: -.9375rem;
  }

  .mr-lxl-s15 {
    margin-right: .9375rem;
  }

  .-mr-lxl-s15 {
    margin-right: -.9375rem;
  }

  .ml-lxl-s15 {
    margin-left: .9375rem;
  }

  .-ml-lxl-s15 {
    margin-left: -.9375rem;
  }

  .p-lxl-s15 {
    padding: .9375rem;
  }

  .-p-lxl-s15 {
    padding: -.9375rem;
  }

  .pt-lxl-s15 {
    padding-top: .9375rem;
  }

  .-pt-lxl-s15 {
    padding-top: -.9375rem;
  }

  .pb-lxl-s15 {
    padding-bottom: .9375rem;
  }

  .-pb-lxl-s15 {
    padding-bottom: -.9375rem;
  }

  .pr-lxl-s15 {
    padding-right: .9375rem;
  }

  .-pr-lxl-s15 {
    padding-right: -.9375rem;
  }

  .pl-lxl-s15 {
    padding-left: .9375rem;
  }

  .-pl-lxl-s15 {
    padding-left: -.9375rem;
  }

  .mx-lxl-s15 {
    margin-left: .9375rem;
    margin-right: .9375rem;
  }

  .my-lxl-s15 {
    margin-top: .9375rem;
    margin-bottom: .9375rem;
  }

  .px-lxl-s15 {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .py-lxl-s15 {
    padding-top: .9375rem;
    padding-bottom: .9375rem;
  }

  .gap-lxl-s15 {
    gap: .9375rem;
  }

  .pm-lxl-s15, .gx-lxl-s15 > .gx-child {
    padding-left: .46875rem;
    padding-right: .46875rem;
  }

  .gx-lxl-s15 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .9375rem;
  }

  .gx-lxl-s15 > .gx-child:last-of-type {
    padding-left: .9375rem;
    padding-right: 0;
  }

  .m-lxl-s16 {
    margin: 1rem;
  }

  .-m-lxl-s16 {
    margin: -1rem;
  }

  .mt-lxl-s16 {
    margin-top: 1rem;
  }

  .-mt-lxl-s16 {
    margin-top: -1rem;
  }

  .mb-lxl-s16 {
    margin-bottom: 1rem;
  }

  .-mb-lxl-s16 {
    margin-bottom: -1rem;
  }

  .mr-lxl-s16 {
    margin-right: 1rem;
  }

  .-mr-lxl-s16 {
    margin-right: -1rem;
  }

  .ml-lxl-s16 {
    margin-left: 1rem;
  }

  .-ml-lxl-s16 {
    margin-left: -1rem;
  }

  .p-lxl-s16 {
    padding: 1rem;
  }

  .-p-lxl-s16 {
    padding: -1rem;
  }

  .pt-lxl-s16 {
    padding-top: 1rem;
  }

  .-pt-lxl-s16 {
    padding-top: -1rem;
  }

  .pb-lxl-s16 {
    padding-bottom: 1rem;
  }

  .-pb-lxl-s16 {
    padding-bottom: -1rem;
  }

  .pr-lxl-s16 {
    padding-right: 1rem;
  }

  .-pr-lxl-s16 {
    padding-right: -1rem;
  }

  .pl-lxl-s16 {
    padding-left: 1rem;
  }

  .-pl-lxl-s16 {
    padding-left: -1rem;
  }

  .mx-lxl-s16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .my-lxl-s16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .px-lxl-s16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .py-lxl-s16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .gap-lxl-s16 {
    gap: 1rem;
  }

  .pm-lxl-s16, .gx-lxl-s16 > .gx-child {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .gx-lxl-s16 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1rem;
  }

  .gx-lxl-s16 > .gx-child:last-of-type {
    padding-left: 1rem;
    padding-right: 0;
  }

  .m-lxl-s18 {
    margin: 1.125rem;
  }

  .-m-lxl-s18 {
    margin: -1.125rem;
  }

  .mt-lxl-s18 {
    margin-top: 1.125rem;
  }

  .-mt-lxl-s18 {
    margin-top: -1.125rem;
  }

  .mb-lxl-s18 {
    margin-bottom: 1.125rem;
  }

  .-mb-lxl-s18 {
    margin-bottom: -1.125rem;
  }

  .mr-lxl-s18 {
    margin-right: 1.125rem;
  }

  .-mr-lxl-s18 {
    margin-right: -1.125rem;
  }

  .ml-lxl-s18 {
    margin-left: 1.125rem;
  }

  .-ml-lxl-s18 {
    margin-left: -1.125rem;
  }

  .p-lxl-s18 {
    padding: 1.125rem;
  }

  .-p-lxl-s18 {
    padding: -1.125rem;
  }

  .pt-lxl-s18 {
    padding-top: 1.125rem;
  }

  .-pt-lxl-s18 {
    padding-top: -1.125rem;
  }

  .pb-lxl-s18 {
    padding-bottom: 1.125rem;
  }

  .-pb-lxl-s18 {
    padding-bottom: -1.125rem;
  }

  .pr-lxl-s18 {
    padding-right: 1.125rem;
  }

  .-pr-lxl-s18 {
    padding-right: -1.125rem;
  }

  .pl-lxl-s18 {
    padding-left: 1.125rem;
  }

  .-pl-lxl-s18 {
    padding-left: -1.125rem;
  }

  .mx-lxl-s18 {
    margin-left: 1.125rem;
    margin-right: 1.125rem;
  }

  .my-lxl-s18 {
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
  }

  .px-lxl-s18 {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .py-lxl-s18 {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }

  .gap-lxl-s18 {
    gap: 1.125rem;
  }

  .pm-lxl-s18, .gx-lxl-s18 > .gx-child {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .gx-lxl-s18 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.125rem;
  }

  .gx-lxl-s18 > .gx-child:last-of-type {
    padding-left: 1.125rem;
    padding-right: 0;
  }

  .m-lxl-s20 {
    margin: 1.25rem;
  }

  .-m-lxl-s20 {
    margin: -1.25rem;
  }

  .mt-lxl-s20 {
    margin-top: 1.25rem;
  }

  .-mt-lxl-s20 {
    margin-top: -1.25rem;
  }

  .mb-lxl-s20 {
    margin-bottom: 1.25rem;
  }

  .-mb-lxl-s20 {
    margin-bottom: -1.25rem;
  }

  .mr-lxl-s20 {
    margin-right: 1.25rem;
  }

  .-mr-lxl-s20 {
    margin-right: -1.25rem;
  }

  .ml-lxl-s20 {
    margin-left: 1.25rem;
  }

  .-ml-lxl-s20 {
    margin-left: -1.25rem;
  }

  .p-lxl-s20 {
    padding: 1.25rem;
  }

  .-p-lxl-s20 {
    padding: -1.25rem;
  }

  .pt-lxl-s20 {
    padding-top: 1.25rem;
  }

  .-pt-lxl-s20 {
    padding-top: -1.25rem;
  }

  .pb-lxl-s20 {
    padding-bottom: 1.25rem;
  }

  .-pb-lxl-s20 {
    padding-bottom: -1.25rem;
  }

  .pr-lxl-s20 {
    padding-right: 1.25rem;
  }

  .-pr-lxl-s20 {
    padding-right: -1.25rem;
  }

  .pl-lxl-s20 {
    padding-left: 1.25rem;
  }

  .-pl-lxl-s20 {
    padding-left: -1.25rem;
  }

  .mx-lxl-s20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .my-lxl-s20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .px-lxl-s20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .py-lxl-s20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .gap-lxl-s20 {
    gap: 1.25rem;
  }

  .pm-lxl-s20, .gx-lxl-s20 > .gx-child {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .gx-lxl-s20 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.25rem;
  }

  .gx-lxl-s20 > .gx-child:last-of-type {
    padding-left: 1.25rem;
    padding-right: 0;
  }

  .m-lxl-s22 {
    margin: 1.375rem;
  }

  .-m-lxl-s22 {
    margin: -1.375rem;
  }

  .mt-lxl-s22 {
    margin-top: 1.375rem;
  }

  .-mt-lxl-s22 {
    margin-top: -1.375rem;
  }

  .mb-lxl-s22 {
    margin-bottom: 1.375rem;
  }

  .-mb-lxl-s22 {
    margin-bottom: -1.375rem;
  }

  .mr-lxl-s22 {
    margin-right: 1.375rem;
  }

  .-mr-lxl-s22 {
    margin-right: -1.375rem;
  }

  .ml-lxl-s22 {
    margin-left: 1.375rem;
  }

  .-ml-lxl-s22 {
    margin-left: -1.375rem;
  }

  .p-lxl-s22 {
    padding: 1.375rem;
  }

  .-p-lxl-s22 {
    padding: -1.375rem;
  }

  .pt-lxl-s22 {
    padding-top: 1.375rem;
  }

  .-pt-lxl-s22 {
    padding-top: -1.375rem;
  }

  .pb-lxl-s22 {
    padding-bottom: 1.375rem;
  }

  .-pb-lxl-s22 {
    padding-bottom: -1.375rem;
  }

  .pr-lxl-s22 {
    padding-right: 1.375rem;
  }

  .-pr-lxl-s22 {
    padding-right: -1.375rem;
  }

  .pl-lxl-s22 {
    padding-left: 1.375rem;
  }

  .-pl-lxl-s22 {
    padding-left: -1.375rem;
  }

  .mx-lxl-s22 {
    margin-left: 1.375rem;
    margin-right: 1.375rem;
  }

  .my-lxl-s22 {
    margin-top: 1.375rem;
    margin-bottom: 1.375rem;
  }

  .px-lxl-s22 {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }

  .py-lxl-s22 {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
  }

  .gap-lxl-s22 {
    gap: 1.375rem;
  }

  .pm-lxl-s22, .gx-lxl-s22 > .gx-child {
    padding-left: .6875rem;
    padding-right: .6875rem;
  }

  .gx-lxl-s22 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.375rem;
  }

  .gx-lxl-s22 > .gx-child:last-of-type {
    padding-left: 1.375rem;
    padding-right: 0;
  }

  .m-lxl-s24 {
    margin: 1.5rem;
  }

  .-m-lxl-s24 {
    margin: -1.5rem;
  }

  .mt-lxl-s24 {
    margin-top: 1.5rem;
  }

  .-mt-lxl-s24 {
    margin-top: -1.5rem;
  }

  .mb-lxl-s24 {
    margin-bottom: 1.5rem;
  }

  .-mb-lxl-s24 {
    margin-bottom: -1.5rem;
  }

  .mr-lxl-s24 {
    margin-right: 1.5rem;
  }

  .-mr-lxl-s24 {
    margin-right: -1.5rem;
  }

  .ml-lxl-s24 {
    margin-left: 1.5rem;
  }

  .-ml-lxl-s24 {
    margin-left: -1.5rem;
  }

  .p-lxl-s24 {
    padding: 1.5rem;
  }

  .-p-lxl-s24 {
    padding: -1.5rem;
  }

  .pt-lxl-s24 {
    padding-top: 1.5rem;
  }

  .-pt-lxl-s24 {
    padding-top: -1.5rem;
  }

  .pb-lxl-s24 {
    padding-bottom: 1.5rem;
  }

  .-pb-lxl-s24 {
    padding-bottom: -1.5rem;
  }

  .pr-lxl-s24 {
    padding-right: 1.5rem;
  }

  .-pr-lxl-s24 {
    padding-right: -1.5rem;
  }

  .pl-lxl-s24 {
    padding-left: 1.5rem;
  }

  .-pl-lxl-s24 {
    padding-left: -1.5rem;
  }

  .mx-lxl-s24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .my-lxl-s24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .px-lxl-s24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .py-lxl-s24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .gap-lxl-s24 {
    gap: 1.5rem;
  }

  .pm-lxl-s24, .gx-lxl-s24 > .gx-child {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .gx-lxl-s24 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5rem;
  }

  .gx-lxl-s24 > .gx-child:last-of-type {
    padding-left: 1.5rem;
    padding-right: 0;
  }

  .m-lxl-s25 {
    margin: 1.5625rem;
  }

  .-m-lxl-s25 {
    margin: -1.5625rem;
  }

  .mt-lxl-s25 {
    margin-top: 1.5625rem;
  }

  .-mt-lxl-s25 {
    margin-top: -1.5625rem;
  }

  .mb-lxl-s25 {
    margin-bottom: 1.5625rem;
  }

  .-mb-lxl-s25 {
    margin-bottom: -1.5625rem;
  }

  .mr-lxl-s25 {
    margin-right: 1.5625rem;
  }

  .-mr-lxl-s25 {
    margin-right: -1.5625rem;
  }

  .ml-lxl-s25 {
    margin-left: 1.5625rem;
  }

  .-ml-lxl-s25 {
    margin-left: -1.5625rem;
  }

  .p-lxl-s25 {
    padding: 1.5625rem;
  }

  .-p-lxl-s25 {
    padding: -1.5625rem;
  }

  .pt-lxl-s25 {
    padding-top: 1.5625rem;
  }

  .-pt-lxl-s25 {
    padding-top: -1.5625rem;
  }

  .pb-lxl-s25 {
    padding-bottom: 1.5625rem;
  }

  .-pb-lxl-s25 {
    padding-bottom: -1.5625rem;
  }

  .pr-lxl-s25 {
    padding-right: 1.5625rem;
  }

  .-pr-lxl-s25 {
    padding-right: -1.5625rem;
  }

  .pl-lxl-s25 {
    padding-left: 1.5625rem;
  }

  .-pl-lxl-s25 {
    padding-left: -1.5625rem;
  }

  .mx-lxl-s25 {
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
  }

  .my-lxl-s25 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }

  .px-lxl-s25 {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .py-lxl-s25 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }

  .gap-lxl-s25 {
    gap: 1.5625rem;
  }

  .pm-lxl-s25, .gx-lxl-s25 > .gx-child {
    padding-left: .78125rem;
    padding-right: .78125rem;
  }

  .gx-lxl-s25 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5625rem;
  }

  .gx-lxl-s25 > .gx-child:last-of-type {
    padding-left: 1.5625rem;
    padding-right: 0;
  }

  .m-lxl-s27 {
    margin: 1.6875rem;
  }

  .-m-lxl-s27 {
    margin: -1.6875rem;
  }

  .mt-lxl-s27 {
    margin-top: 1.6875rem;
  }

  .-mt-lxl-s27 {
    margin-top: -1.6875rem;
  }

  .mb-lxl-s27 {
    margin-bottom: 1.6875rem;
  }

  .-mb-lxl-s27 {
    margin-bottom: -1.6875rem;
  }

  .mr-lxl-s27 {
    margin-right: 1.6875rem;
  }

  .-mr-lxl-s27 {
    margin-right: -1.6875rem;
  }

  .ml-lxl-s27 {
    margin-left: 1.6875rem;
  }

  .-ml-lxl-s27 {
    margin-left: -1.6875rem;
  }

  .p-lxl-s27 {
    padding: 1.6875rem;
  }

  .-p-lxl-s27 {
    padding: -1.6875rem;
  }

  .pt-lxl-s27 {
    padding-top: 1.6875rem;
  }

  .-pt-lxl-s27 {
    padding-top: -1.6875rem;
  }

  .pb-lxl-s27 {
    padding-bottom: 1.6875rem;
  }

  .-pb-lxl-s27 {
    padding-bottom: -1.6875rem;
  }

  .pr-lxl-s27 {
    padding-right: 1.6875rem;
  }

  .-pr-lxl-s27 {
    padding-right: -1.6875rem;
  }

  .pl-lxl-s27 {
    padding-left: 1.6875rem;
  }

  .-pl-lxl-s27 {
    padding-left: -1.6875rem;
  }

  .mx-lxl-s27 {
    margin-left: 1.6875rem;
    margin-right: 1.6875rem;
  }

  .my-lxl-s27 {
    margin-top: 1.6875rem;
    margin-bottom: 1.6875rem;
  }

  .px-lxl-s27 {
    padding-left: 1.6875rem;
    padding-right: 1.6875rem;
  }

  .py-lxl-s27 {
    padding-top: 1.6875rem;
    padding-bottom: 1.6875rem;
  }

  .gap-lxl-s27 {
    gap: 1.6875rem;
  }

  .pm-lxl-s27, .gx-lxl-s27 > .gx-child {
    padding-left: .84375rem;
    padding-right: .84375rem;
  }

  .gx-lxl-s27 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.6875rem;
  }

  .gx-lxl-s27 > .gx-child:last-of-type {
    padding-left: 1.6875rem;
    padding-right: 0;
  }

  .m-lxl-s29 {
    margin: 1.8125rem;
  }

  .-m-lxl-s29 {
    margin: -1.8125rem;
  }

  .mt-lxl-s29 {
    margin-top: 1.8125rem;
  }

  .-mt-lxl-s29 {
    margin-top: -1.8125rem;
  }

  .mb-lxl-s29 {
    margin-bottom: 1.8125rem;
  }

  .-mb-lxl-s29 {
    margin-bottom: -1.8125rem;
  }

  .mr-lxl-s29 {
    margin-right: 1.8125rem;
  }

  .-mr-lxl-s29 {
    margin-right: -1.8125rem;
  }

  .ml-lxl-s29 {
    margin-left: 1.8125rem;
  }

  .-ml-lxl-s29 {
    margin-left: -1.8125rem;
  }

  .p-lxl-s29 {
    padding: 1.8125rem;
  }

  .-p-lxl-s29 {
    padding: -1.8125rem;
  }

  .pt-lxl-s29 {
    padding-top: 1.8125rem;
  }

  .-pt-lxl-s29 {
    padding-top: -1.8125rem;
  }

  .pb-lxl-s29 {
    padding-bottom: 1.8125rem;
  }

  .-pb-lxl-s29 {
    padding-bottom: -1.8125rem;
  }

  .pr-lxl-s29 {
    padding-right: 1.8125rem;
  }

  .-pr-lxl-s29 {
    padding-right: -1.8125rem;
  }

  .pl-lxl-s29 {
    padding-left: 1.8125rem;
  }

  .-pl-lxl-s29 {
    padding-left: -1.8125rem;
  }

  .mx-lxl-s29 {
    margin-left: 1.8125rem;
    margin-right: 1.8125rem;
  }

  .my-lxl-s29 {
    margin-top: 1.8125rem;
    margin-bottom: 1.8125rem;
  }

  .px-lxl-s29 {
    padding-left: 1.8125rem;
    padding-right: 1.8125rem;
  }

  .py-lxl-s29 {
    padding-top: 1.8125rem;
    padding-bottom: 1.8125rem;
  }

  .gap-lxl-s29 {
    gap: 1.8125rem;
  }

  .pm-lxl-s29, .gx-lxl-s29 > .gx-child {
    padding-left: .90625rem;
    padding-right: .90625rem;
  }

  .gx-lxl-s29 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.8125rem;
  }

  .gx-lxl-s29 > .gx-child:last-of-type {
    padding-left: 1.8125rem;
    padding-right: 0;
  }

  .m-lxl-s30 {
    margin: 1.875rem;
  }

  .-m-lxl-s30 {
    margin: -1.875rem;
  }

  .mt-lxl-s30 {
    margin-top: 1.875rem;
  }

  .-mt-lxl-s30 {
    margin-top: -1.875rem;
  }

  .mb-lxl-s30 {
    margin-bottom: 1.875rem;
  }

  .-mb-lxl-s30 {
    margin-bottom: -1.875rem;
  }

  .mr-lxl-s30 {
    margin-right: 1.875rem;
  }

  .-mr-lxl-s30 {
    margin-right: -1.875rem;
  }

  .ml-lxl-s30 {
    margin-left: 1.875rem;
  }

  .-ml-lxl-s30 {
    margin-left: -1.875rem;
  }

  .p-lxl-s30 {
    padding: 1.875rem;
  }

  .-p-lxl-s30 {
    padding: -1.875rem;
  }

  .pt-lxl-s30 {
    padding-top: 1.875rem;
  }

  .-pt-lxl-s30 {
    padding-top: -1.875rem;
  }

  .pb-lxl-s30 {
    padding-bottom: 1.875rem;
  }

  .-pb-lxl-s30 {
    padding-bottom: -1.875rem;
  }

  .pr-lxl-s30 {
    padding-right: 1.875rem;
  }

  .-pr-lxl-s30 {
    padding-right: -1.875rem;
  }

  .pl-lxl-s30 {
    padding-left: 1.875rem;
  }

  .-pl-lxl-s30 {
    padding-left: -1.875rem;
  }

  .mx-lxl-s30 {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }

  .my-lxl-s30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .px-lxl-s30 {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .py-lxl-s30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .gap-lxl-s30 {
    gap: 1.875rem;
  }

  .pm-lxl-s30, .gx-lxl-s30 > .gx-child {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .gx-lxl-s30 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.875rem;
  }

  .gx-lxl-s30 > .gx-child:last-of-type {
    padding-left: 1.875rem;
    padding-right: 0;
  }

  .m-lxl-s32 {
    margin: 2rem;
  }

  .-m-lxl-s32 {
    margin: -2rem;
  }

  .mt-lxl-s32 {
    margin-top: 2rem;
  }

  .-mt-lxl-s32 {
    margin-top: -2rem;
  }

  .mb-lxl-s32 {
    margin-bottom: 2rem;
  }

  .-mb-lxl-s32 {
    margin-bottom: -2rem;
  }

  .mr-lxl-s32 {
    margin-right: 2rem;
  }

  .-mr-lxl-s32 {
    margin-right: -2rem;
  }

  .ml-lxl-s32 {
    margin-left: 2rem;
  }

  .-ml-lxl-s32 {
    margin-left: -2rem;
  }

  .p-lxl-s32 {
    padding: 2rem;
  }

  .-p-lxl-s32 {
    padding: -2rem;
  }

  .pt-lxl-s32 {
    padding-top: 2rem;
  }

  .-pt-lxl-s32 {
    padding-top: -2rem;
  }

  .pb-lxl-s32 {
    padding-bottom: 2rem;
  }

  .-pb-lxl-s32 {
    padding-bottom: -2rem;
  }

  .pr-lxl-s32 {
    padding-right: 2rem;
  }

  .-pr-lxl-s32 {
    padding-right: -2rem;
  }

  .pl-lxl-s32 {
    padding-left: 2rem;
  }

  .-pl-lxl-s32 {
    padding-left: -2rem;
  }

  .mx-lxl-s32 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .my-lxl-s32 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .px-lxl-s32 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .py-lxl-s32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .gap-lxl-s32 {
    gap: 2rem;
  }

  .pm-lxl-s32, .gx-lxl-s32 > .gx-child {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .gx-lxl-s32 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2rem;
  }

  .gx-lxl-s32 > .gx-child:last-of-type {
    padding-left: 2rem;
    padding-right: 0;
  }

  .m-lxl-s35 {
    margin: 2.1875rem;
  }

  .-m-lxl-s35 {
    margin: -2.1875rem;
  }

  .mt-lxl-s35 {
    margin-top: 2.1875rem;
  }

  .-mt-lxl-s35 {
    margin-top: -2.1875rem;
  }

  .mb-lxl-s35 {
    margin-bottom: 2.1875rem;
  }

  .-mb-lxl-s35 {
    margin-bottom: -2.1875rem;
  }

  .mr-lxl-s35 {
    margin-right: 2.1875rem;
  }

  .-mr-lxl-s35 {
    margin-right: -2.1875rem;
  }

  .ml-lxl-s35 {
    margin-left: 2.1875rem;
  }

  .-ml-lxl-s35 {
    margin-left: -2.1875rem;
  }

  .p-lxl-s35 {
    padding: 2.1875rem;
  }

  .-p-lxl-s35 {
    padding: -2.1875rem;
  }

  .pt-lxl-s35 {
    padding-top: 2.1875rem;
  }

  .-pt-lxl-s35 {
    padding-top: -2.1875rem;
  }

  .pb-lxl-s35 {
    padding-bottom: 2.1875rem;
  }

  .-pb-lxl-s35 {
    padding-bottom: -2.1875rem;
  }

  .pr-lxl-s35 {
    padding-right: 2.1875rem;
  }

  .-pr-lxl-s35 {
    padding-right: -2.1875rem;
  }

  .pl-lxl-s35 {
    padding-left: 2.1875rem;
  }

  .-pl-lxl-s35 {
    padding-left: -2.1875rem;
  }

  .mx-lxl-s35 {
    margin-left: 2.1875rem;
    margin-right: 2.1875rem;
  }

  .my-lxl-s35 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }

  .px-lxl-s35 {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .py-lxl-s35 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }

  .gap-lxl-s35 {
    gap: 2.1875rem;
  }

  .pm-lxl-s35, .gx-lxl-s35 > .gx-child {
    padding-left: 1.09375rem;
    padding-right: 1.09375rem;
  }

  .gx-lxl-s35 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.1875rem;
  }

  .gx-lxl-s35 > .gx-child:last-of-type {
    padding-left: 2.1875rem;
    padding-right: 0;
  }

  .m-lxl-s40 {
    margin: 2.5rem;
  }

  .-m-lxl-s40 {
    margin: -2.5rem;
  }

  .mt-lxl-s40 {
    margin-top: 2.5rem;
  }

  .-mt-lxl-s40 {
    margin-top: -2.5rem;
  }

  .mb-lxl-s40 {
    margin-bottom: 2.5rem;
  }

  .-mb-lxl-s40 {
    margin-bottom: -2.5rem;
  }

  .mr-lxl-s40 {
    margin-right: 2.5rem;
  }

  .-mr-lxl-s40 {
    margin-right: -2.5rem;
  }

  .ml-lxl-s40 {
    margin-left: 2.5rem;
  }

  .-ml-lxl-s40 {
    margin-left: -2.5rem;
  }

  .p-lxl-s40 {
    padding: 2.5rem;
  }

  .-p-lxl-s40 {
    padding: -2.5rem;
  }

  .pt-lxl-s40 {
    padding-top: 2.5rem;
  }

  .-pt-lxl-s40 {
    padding-top: -2.5rem;
  }

  .pb-lxl-s40 {
    padding-bottom: 2.5rem;
  }

  .-pb-lxl-s40 {
    padding-bottom: -2.5rem;
  }

  .pr-lxl-s40 {
    padding-right: 2.5rem;
  }

  .-pr-lxl-s40 {
    padding-right: -2.5rem;
  }

  .pl-lxl-s40 {
    padding-left: 2.5rem;
  }

  .-pl-lxl-s40 {
    padding-left: -2.5rem;
  }

  .mx-lxl-s40 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .my-lxl-s40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .px-lxl-s40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .py-lxl-s40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .gap-lxl-s40 {
    gap: 2.5rem;
  }

  .pm-lxl-s40, .gx-lxl-s40 > .gx-child {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .gx-lxl-s40 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.5rem;
  }

  .gx-lxl-s40 > .gx-child:last-of-type {
    padding-left: 2.5rem;
    padding-right: 0;
  }

  .m-lxl-s45 {
    margin: 2.8125rem;
  }

  .-m-lxl-s45 {
    margin: -2.8125rem;
  }

  .mt-lxl-s45 {
    margin-top: 2.8125rem;
  }

  .-mt-lxl-s45 {
    margin-top: -2.8125rem;
  }

  .mb-lxl-s45 {
    margin-bottom: 2.8125rem;
  }

  .-mb-lxl-s45 {
    margin-bottom: -2.8125rem;
  }

  .mr-lxl-s45 {
    margin-right: 2.8125rem;
  }

  .-mr-lxl-s45 {
    margin-right: -2.8125rem;
  }

  .ml-lxl-s45 {
    margin-left: 2.8125rem;
  }

  .-ml-lxl-s45 {
    margin-left: -2.8125rem;
  }

  .p-lxl-s45 {
    padding: 2.8125rem;
  }

  .-p-lxl-s45 {
    padding: -2.8125rem;
  }

  .pt-lxl-s45 {
    padding-top: 2.8125rem;
  }

  .-pt-lxl-s45 {
    padding-top: -2.8125rem;
  }

  .pb-lxl-s45 {
    padding-bottom: 2.8125rem;
  }

  .-pb-lxl-s45 {
    padding-bottom: -2.8125rem;
  }

  .pr-lxl-s45 {
    padding-right: 2.8125rem;
  }

  .-pr-lxl-s45 {
    padding-right: -2.8125rem;
  }

  .pl-lxl-s45 {
    padding-left: 2.8125rem;
  }

  .-pl-lxl-s45 {
    padding-left: -2.8125rem;
  }

  .mx-lxl-s45 {
    margin-left: 2.8125rem;
    margin-right: 2.8125rem;
  }

  .my-lxl-s45 {
    margin-top: 2.8125rem;
    margin-bottom: 2.8125rem;
  }

  .px-lxl-s45 {
    padding-left: 2.8125rem;
    padding-right: 2.8125rem;
  }

  .py-lxl-s45 {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }

  .gap-lxl-s45 {
    gap: 2.8125rem;
  }

  .pm-lxl-s45, .gx-lxl-s45 > .gx-child {
    padding-left: 1.40625rem;
    padding-right: 1.40625rem;
  }

  .gx-lxl-s45 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.8125rem;
  }

  .gx-lxl-s45 > .gx-child:last-of-type {
    padding-left: 2.8125rem;
    padding-right: 0;
  }

  .m-lxl-s48 {
    margin: 3rem;
  }

  .-m-lxl-s48 {
    margin: -3rem;
  }

  .mt-lxl-s48 {
    margin-top: 3rem;
  }

  .-mt-lxl-s48 {
    margin-top: -3rem;
  }

  .mb-lxl-s48 {
    margin-bottom: 3rem;
  }

  .-mb-lxl-s48 {
    margin-bottom: -3rem;
  }

  .mr-lxl-s48 {
    margin-right: 3rem;
  }

  .-mr-lxl-s48 {
    margin-right: -3rem;
  }

  .ml-lxl-s48 {
    margin-left: 3rem;
  }

  .-ml-lxl-s48 {
    margin-left: -3rem;
  }

  .p-lxl-s48 {
    padding: 3rem;
  }

  .-p-lxl-s48 {
    padding: -3rem;
  }

  .pt-lxl-s48 {
    padding-top: 3rem;
  }

  .-pt-lxl-s48 {
    padding-top: -3rem;
  }

  .pb-lxl-s48 {
    padding-bottom: 3rem;
  }

  .-pb-lxl-s48 {
    padding-bottom: -3rem;
  }

  .pr-lxl-s48 {
    padding-right: 3rem;
  }

  .-pr-lxl-s48 {
    padding-right: -3rem;
  }

  .pl-lxl-s48 {
    padding-left: 3rem;
  }

  .-pl-lxl-s48 {
    padding-left: -3rem;
  }

  .mx-lxl-s48 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .my-lxl-s48 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .px-lxl-s48 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .py-lxl-s48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .gap-lxl-s48 {
    gap: 3rem;
  }

  .pm-lxl-s48, .gx-lxl-s48 > .gx-child {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .gx-lxl-s48 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3rem;
  }

  .gx-lxl-s48 > .gx-child:last-of-type {
    padding-left: 3rem;
    padding-right: 0;
  }

  .m-lxl-s50 {
    margin: 3.125rem;
  }

  .-m-lxl-s50 {
    margin: -3.125rem;
  }

  .mt-lxl-s50 {
    margin-top: 3.125rem;
  }

  .-mt-lxl-s50 {
    margin-top: -3.125rem;
  }

  .mb-lxl-s50 {
    margin-bottom: 3.125rem;
  }

  .-mb-lxl-s50 {
    margin-bottom: -3.125rem;
  }

  .mr-lxl-s50 {
    margin-right: 3.125rem;
  }

  .-mr-lxl-s50 {
    margin-right: -3.125rem;
  }

  .ml-lxl-s50 {
    margin-left: 3.125rem;
  }

  .-ml-lxl-s50 {
    margin-left: -3.125rem;
  }

  .p-lxl-s50 {
    padding: 3.125rem;
  }

  .-p-lxl-s50 {
    padding: -3.125rem;
  }

  .pt-lxl-s50 {
    padding-top: 3.125rem;
  }

  .-pt-lxl-s50 {
    padding-top: -3.125rem;
  }

  .pb-lxl-s50 {
    padding-bottom: 3.125rem;
  }

  .-pb-lxl-s50 {
    padding-bottom: -3.125rem;
  }

  .pr-lxl-s50 {
    padding-right: 3.125rem;
  }

  .-pr-lxl-s50 {
    padding-right: -3.125rem;
  }

  .pl-lxl-s50 {
    padding-left: 3.125rem;
  }

  .-pl-lxl-s50 {
    padding-left: -3.125rem;
  }

  .mx-lxl-s50 {
    margin-left: 3.125rem;
    margin-right: 3.125rem;
  }

  .my-lxl-s50 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }

  .px-lxl-s50 {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .py-lxl-s50 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }

  .gap-lxl-s50 {
    gap: 3.125rem;
  }

  .pm-lxl-s50, .gx-lxl-s50 > .gx-child {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .gx-lxl-s50 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.125rem;
  }

  .gx-lxl-s50 > .gx-child:last-of-type {
    padding-left: 3.125rem;
    padding-right: 0;
  }

  .m-lxl-s60 {
    margin: 3.75rem;
  }

  .-m-lxl-s60 {
    margin: -3.75rem;
  }

  .mt-lxl-s60 {
    margin-top: 3.75rem;
  }

  .-mt-lxl-s60 {
    margin-top: -3.75rem;
  }

  .mb-lxl-s60 {
    margin-bottom: 3.75rem;
  }

  .-mb-lxl-s60 {
    margin-bottom: -3.75rem;
  }

  .mr-lxl-s60 {
    margin-right: 3.75rem;
  }

  .-mr-lxl-s60 {
    margin-right: -3.75rem;
  }

  .ml-lxl-s60 {
    margin-left: 3.75rem;
  }

  .-ml-lxl-s60 {
    margin-left: -3.75rem;
  }

  .p-lxl-s60 {
    padding: 3.75rem;
  }

  .-p-lxl-s60 {
    padding: -3.75rem;
  }

  .pt-lxl-s60 {
    padding-top: 3.75rem;
  }

  .-pt-lxl-s60 {
    padding-top: -3.75rem;
  }

  .pb-lxl-s60 {
    padding-bottom: 3.75rem;
  }

  .-pb-lxl-s60 {
    padding-bottom: -3.75rem;
  }

  .pr-lxl-s60 {
    padding-right: 3.75rem;
  }

  .-pr-lxl-s60 {
    padding-right: -3.75rem;
  }

  .pl-lxl-s60 {
    padding-left: 3.75rem;
  }

  .-pl-lxl-s60 {
    padding-left: -3.75rem;
  }

  .mx-lxl-s60 {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
  }

  .my-lxl-s60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .px-lxl-s60 {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .py-lxl-s60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  .gap-lxl-s60 {
    gap: 3.75rem;
  }

  .pm-lxl-s60, .gx-lxl-s60 > .gx-child {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .gx-lxl-s60 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.75rem;
  }

  .gx-lxl-s60 > .gx-child:last-of-type {
    padding-left: 3.75rem;
    padding-right: 0;
  }

  .m-lxl-s70 {
    margin: 4.375rem;
  }

  .-m-lxl-s70 {
    margin: -4.375rem;
  }

  .mt-lxl-s70 {
    margin-top: 4.375rem;
  }

  .-mt-lxl-s70 {
    margin-top: -4.375rem;
  }

  .mb-lxl-s70 {
    margin-bottom: 4.375rem;
  }

  .-mb-lxl-s70 {
    margin-bottom: -4.375rem;
  }

  .mr-lxl-s70 {
    margin-right: 4.375rem;
  }

  .-mr-lxl-s70 {
    margin-right: -4.375rem;
  }

  .ml-lxl-s70 {
    margin-left: 4.375rem;
  }

  .-ml-lxl-s70 {
    margin-left: -4.375rem;
  }

  .p-lxl-s70 {
    padding: 4.375rem;
  }

  .-p-lxl-s70 {
    padding: -4.375rem;
  }

  .pt-lxl-s70 {
    padding-top: 4.375rem;
  }

  .-pt-lxl-s70 {
    padding-top: -4.375rem;
  }

  .pb-lxl-s70 {
    padding-bottom: 4.375rem;
  }

  .-pb-lxl-s70 {
    padding-bottom: -4.375rem;
  }

  .pr-lxl-s70 {
    padding-right: 4.375rem;
  }

  .-pr-lxl-s70 {
    padding-right: -4.375rem;
  }

  .pl-lxl-s70 {
    padding-left: 4.375rem;
  }

  .-pl-lxl-s70 {
    padding-left: -4.375rem;
  }

  .mx-lxl-s70 {
    margin-left: 4.375rem;
    margin-right: 4.375rem;
  }

  .my-lxl-s70 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }

  .px-lxl-s70 {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }

  .py-lxl-s70 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }

  .gap-lxl-s70 {
    gap: 4.375rem;
  }

  .pm-lxl-s70, .gx-lxl-s70 > .gx-child {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .gx-lxl-s70 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 4.375rem;
  }

  .gx-lxl-s70 > .gx-child:last-of-type {
    padding-left: 4.375rem;
    padding-right: 0;
  }

  .m-lxl-s80 {
    margin: 5rem;
  }

  .-m-lxl-s80 {
    margin: -5rem;
  }

  .mt-lxl-s80 {
    margin-top: 5rem;
  }

  .-mt-lxl-s80 {
    margin-top: -5rem;
  }

  .mb-lxl-s80 {
    margin-bottom: 5rem;
  }

  .-mb-lxl-s80 {
    margin-bottom: -5rem;
  }

  .mr-lxl-s80 {
    margin-right: 5rem;
  }

  .-mr-lxl-s80 {
    margin-right: -5rem;
  }

  .ml-lxl-s80 {
    margin-left: 5rem;
  }

  .-ml-lxl-s80 {
    margin-left: -5rem;
  }

  .p-lxl-s80 {
    padding: 5rem;
  }

  .-p-lxl-s80 {
    padding: -5rem;
  }

  .pt-lxl-s80 {
    padding-top: 5rem;
  }

  .-pt-lxl-s80 {
    padding-top: -5rem;
  }

  .pb-lxl-s80 {
    padding-bottom: 5rem;
  }

  .-pb-lxl-s80 {
    padding-bottom: -5rem;
  }

  .pr-lxl-s80 {
    padding-right: 5rem;
  }

  .-pr-lxl-s80 {
    padding-right: -5rem;
  }

  .pl-lxl-s80 {
    padding-left: 5rem;
  }

  .-pl-lxl-s80 {
    padding-left: -5rem;
  }

  .mx-lxl-s80 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .my-lxl-s80 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .px-lxl-s80 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .py-lxl-s80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .gap-lxl-s80 {
    gap: 5rem;
  }

  .pm-lxl-s80, .gx-lxl-s80 > .gx-child {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .gx-lxl-s80 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 5rem;
  }

  .gx-lxl-s80 > .gx-child:last-of-type {
    padding-left: 5rem;
    padding-right: 0;
  }

  .m-lxl-s100 {
    margin: 6.25rem;
  }

  .-m-lxl-s100 {
    margin: -6.25rem;
  }

  .mt-lxl-s100 {
    margin-top: 6.25rem;
  }

  .-mt-lxl-s100 {
    margin-top: -6.25rem;
  }

  .mb-lxl-s100 {
    margin-bottom: 6.25rem;
  }

  .-mb-lxl-s100 {
    margin-bottom: -6.25rem;
  }

  .mr-lxl-s100 {
    margin-right: 6.25rem;
  }

  .-mr-lxl-s100 {
    margin-right: -6.25rem;
  }

  .ml-lxl-s100 {
    margin-left: 6.25rem;
  }

  .-ml-lxl-s100 {
    margin-left: -6.25rem;
  }

  .p-lxl-s100 {
    padding: 6.25rem;
  }

  .-p-lxl-s100 {
    padding: -6.25rem;
  }

  .pt-lxl-s100 {
    padding-top: 6.25rem;
  }

  .-pt-lxl-s100 {
    padding-top: -6.25rem;
  }

  .pb-lxl-s100 {
    padding-bottom: 6.25rem;
  }

  .-pb-lxl-s100 {
    padding-bottom: -6.25rem;
  }

  .pr-lxl-s100 {
    padding-right: 6.25rem;
  }

  .-pr-lxl-s100 {
    padding-right: -6.25rem;
  }

  .pl-lxl-s100 {
    padding-left: 6.25rem;
  }

  .-pl-lxl-s100 {
    padding-left: -6.25rem;
  }

  .mx-lxl-s100 {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
  }

  .my-lxl-s100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }

  .px-lxl-s100 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }

  .py-lxl-s100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  .gap-lxl-s100 {
    gap: 6.25rem;
  }

  .pm-lxl-s100, .gx-lxl-s100 > .gx-child {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .gx-lxl-s100 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 6.25rem;
  }

  .gx-lxl-s100 > .gx-child:last-of-type {
    padding-left: 6.25rem;
    padding-right: 0;
  }

  .m-lxl-s120 {
    margin: 7.5rem;
  }

  .-m-lxl-s120 {
    margin: -7.5rem;
  }

  .mt-lxl-s120 {
    margin-top: 7.5rem;
  }

  .-mt-lxl-s120 {
    margin-top: -7.5rem;
  }

  .mb-lxl-s120 {
    margin-bottom: 7.5rem;
  }

  .-mb-lxl-s120 {
    margin-bottom: -7.5rem;
  }

  .mr-lxl-s120 {
    margin-right: 7.5rem;
  }

  .-mr-lxl-s120 {
    margin-right: -7.5rem;
  }

  .ml-lxl-s120 {
    margin-left: 7.5rem;
  }

  .-ml-lxl-s120 {
    margin-left: -7.5rem;
  }

  .p-lxl-s120 {
    padding: 7.5rem;
  }

  .-p-lxl-s120 {
    padding: -7.5rem;
  }

  .pt-lxl-s120 {
    padding-top: 7.5rem;
  }

  .-pt-lxl-s120 {
    padding-top: -7.5rem;
  }

  .pb-lxl-s120 {
    padding-bottom: 7.5rem;
  }

  .-pb-lxl-s120 {
    padding-bottom: -7.5rem;
  }

  .pr-lxl-s120 {
    padding-right: 7.5rem;
  }

  .-pr-lxl-s120 {
    padding-right: -7.5rem;
  }

  .pl-lxl-s120 {
    padding-left: 7.5rem;
  }

  .-pl-lxl-s120 {
    padding-left: -7.5rem;
  }

  .mx-lxl-s120 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }

  .my-lxl-s120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }

  .px-lxl-s120 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }

  .py-lxl-s120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }

  .gap-lxl-s120 {
    gap: 7.5rem;
  }

  .pm-lxl-s120, .gx-lxl-s120 > .gx-child {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .gx-lxl-s120 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 7.5rem;
  }

  .gx-lxl-s120 > .gx-child:last-of-type {
    padding-left: 7.5rem;
    padding-right: 0;
  }

  .m-lxl-s150 {
    margin: 9.375rem;
  }

  .-m-lxl-s150 {
    margin: -9.375rem;
  }

  .mt-lxl-s150 {
    margin-top: 9.375rem;
  }

  .-mt-lxl-s150 {
    margin-top: -9.375rem;
  }

  .mb-lxl-s150 {
    margin-bottom: 9.375rem;
  }

  .-mb-lxl-s150 {
    margin-bottom: -9.375rem;
  }

  .mr-lxl-s150 {
    margin-right: 9.375rem;
  }

  .-mr-lxl-s150 {
    margin-right: -9.375rem;
  }

  .ml-lxl-s150 {
    margin-left: 9.375rem;
  }

  .-ml-lxl-s150 {
    margin-left: -9.375rem;
  }

  .p-lxl-s150 {
    padding: 9.375rem;
  }

  .-p-lxl-s150 {
    padding: -9.375rem;
  }

  .pt-lxl-s150 {
    padding-top: 9.375rem;
  }

  .-pt-lxl-s150 {
    padding-top: -9.375rem;
  }

  .pb-lxl-s150 {
    padding-bottom: 9.375rem;
  }

  .-pb-lxl-s150 {
    padding-bottom: -9.375rem;
  }

  .pr-lxl-s150 {
    padding-right: 9.375rem;
  }

  .-pr-lxl-s150 {
    padding-right: -9.375rem;
  }

  .pl-lxl-s150 {
    padding-left: 9.375rem;
  }

  .-pl-lxl-s150 {
    padding-left: -9.375rem;
  }

  .mx-lxl-s150 {
    margin-left: 9.375rem;
    margin-right: 9.375rem;
  }

  .my-lxl-s150 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }

  .px-lxl-s150 {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
  }

  .py-lxl-s150 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }

  .gap-lxl-s150 {
    gap: 9.375rem;
  }

  .pm-lxl-s150, .gx-lxl-s150 > .gx-child {
    padding-left: 4.6875rem;
    padding-right: 4.6875rem;
  }

  .gx-lxl-s150 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 9.375rem;
  }

  .gx-lxl-s150 > .gx-child:last-of-type {
    padding-left: 9.375rem;
    padding-right: 0;
  }
}

@media screen and (min-width: 1512px) {
  .m-xlx-s0, .-m-xlx-s0 {
    margin: 0;
  }

  .mt-xlx-s0, .-mt-xlx-s0 {
    margin-top: 0;
  }

  .mb-xlx-s0, .-mb-xlx-s0 {
    margin-bottom: 0;
  }

  .mr-xlx-s0, .-mr-xlx-s0 {
    margin-right: 0;
  }

  .ml-xlx-s0, .-ml-xlx-s0 {
    margin-left: 0;
  }

  .p-xlx-s0, .-p-xlx-s0 {
    padding: 0;
  }

  .pt-xlx-s0, .-pt-xlx-s0 {
    padding-top: 0;
  }

  .pb-xlx-s0, .-pb-xlx-s0 {
    padding-bottom: 0;
  }

  .pr-xlx-s0, .-pr-xlx-s0 {
    padding-right: 0;
  }

  .pl-xlx-s0, .-pl-xlx-s0 {
    padding-left: 0;
  }

  .mx-xlx-s0 {
    margin-left: 0;
    margin-right: 0;
  }

  .my-xlx-s0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .px-xlx-s0 {
    padding-left: 0;
    padding-right: 0;
  }

  .py-xlx-s0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .gap-xlx-s0 {
    gap: 0;
  }

  .pm-xlx-s0, .gx-xlx-s0 > .gx-child, .gx-xlx-s0 > .gx-child:first-of-type, .gx-xlx-s0 > .gx-child:last-of-type {
    padding-left: 0;
    padding-right: 0;
  }

  .m-xlx-s2 {
    margin: .125rem;
  }

  .-m-xlx-s2 {
    margin: -.125rem;
  }

  .mt-xlx-s2 {
    margin-top: .125rem;
  }

  .-mt-xlx-s2 {
    margin-top: -.125rem;
  }

  .mb-xlx-s2 {
    margin-bottom: .125rem;
  }

  .-mb-xlx-s2 {
    margin-bottom: -.125rem;
  }

  .mr-xlx-s2 {
    margin-right: .125rem;
  }

  .-mr-xlx-s2 {
    margin-right: -.125rem;
  }

  .ml-xlx-s2 {
    margin-left: .125rem;
  }

  .-ml-xlx-s2 {
    margin-left: -.125rem;
  }

  .p-xlx-s2 {
    padding: .125rem;
  }

  .-p-xlx-s2 {
    padding: -.125rem;
  }

  .pt-xlx-s2 {
    padding-top: .125rem;
  }

  .-pt-xlx-s2 {
    padding-top: -.125rem;
  }

  .pb-xlx-s2 {
    padding-bottom: .125rem;
  }

  .-pb-xlx-s2 {
    padding-bottom: -.125rem;
  }

  .pr-xlx-s2 {
    padding-right: .125rem;
  }

  .-pr-xlx-s2 {
    padding-right: -.125rem;
  }

  .pl-xlx-s2 {
    padding-left: .125rem;
  }

  .-pl-xlx-s2 {
    padding-left: -.125rem;
  }

  .mx-xlx-s2 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .my-xlx-s2 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .px-xlx-s2 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .py-xlx-s2 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .gap-xlx-s2 {
    gap: .125rem;
  }

  .pm-xlx-s2, .gx-xlx-s2 > .gx-child {
    padding-left: .0625rem;
    padding-right: .0625rem;
  }

  .gx-xlx-s2 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .125rem;
  }

  .gx-xlx-s2 > .gx-child:last-of-type {
    padding-left: .125rem;
    padding-right: 0;
  }

  .m-xlx-s3 {
    margin: .1875rem;
  }

  .-m-xlx-s3 {
    margin: -.1875rem;
  }

  .mt-xlx-s3 {
    margin-top: .1875rem;
  }

  .-mt-xlx-s3 {
    margin-top: -.1875rem;
  }

  .mb-xlx-s3 {
    margin-bottom: .1875rem;
  }

  .-mb-xlx-s3 {
    margin-bottom: -.1875rem;
  }

  .mr-xlx-s3 {
    margin-right: .1875rem;
  }

  .-mr-xlx-s3 {
    margin-right: -.1875rem;
  }

  .ml-xlx-s3 {
    margin-left: .1875rem;
  }

  .-ml-xlx-s3 {
    margin-left: -.1875rem;
  }

  .p-xlx-s3 {
    padding: .1875rem;
  }

  .-p-xlx-s3 {
    padding: -.1875rem;
  }

  .pt-xlx-s3 {
    padding-top: .1875rem;
  }

  .-pt-xlx-s3 {
    padding-top: -.1875rem;
  }

  .pb-xlx-s3 {
    padding-bottom: .1875rem;
  }

  .-pb-xlx-s3 {
    padding-bottom: -.1875rem;
  }

  .pr-xlx-s3 {
    padding-right: .1875rem;
  }

  .-pr-xlx-s3 {
    padding-right: -.1875rem;
  }

  .pl-xlx-s3 {
    padding-left: .1875rem;
  }

  .-pl-xlx-s3 {
    padding-left: -.1875rem;
  }

  .mx-xlx-s3 {
    margin-left: .1875rem;
    margin-right: .1875rem;
  }

  .my-xlx-s3 {
    margin-top: .1875rem;
    margin-bottom: .1875rem;
  }

  .px-xlx-s3 {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .py-xlx-s3 {
    padding-top: .1875rem;
    padding-bottom: .1875rem;
  }

  .gap-xlx-s3 {
    gap: .1875rem;
  }

  .pm-xlx-s3, .gx-xlx-s3 > .gx-child {
    padding-left: .09375rem;
    padding-right: .09375rem;
  }

  .gx-xlx-s3 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .1875rem;
  }

  .gx-xlx-s3 > .gx-child:last-of-type {
    padding-left: .1875rem;
    padding-right: 0;
  }

  .m-xlx-s4 {
    margin: .25rem;
  }

  .-m-xlx-s4 {
    margin: -.25rem;
  }

  .mt-xlx-s4 {
    margin-top: .25rem;
  }

  .-mt-xlx-s4 {
    margin-top: -.25rem;
  }

  .mb-xlx-s4 {
    margin-bottom: .25rem;
  }

  .-mb-xlx-s4 {
    margin-bottom: -.25rem;
  }

  .mr-xlx-s4 {
    margin-right: .25rem;
  }

  .-mr-xlx-s4 {
    margin-right: -.25rem;
  }

  .ml-xlx-s4 {
    margin-left: .25rem;
  }

  .-ml-xlx-s4 {
    margin-left: -.25rem;
  }

  .p-xlx-s4 {
    padding: .25rem;
  }

  .-p-xlx-s4 {
    padding: -.25rem;
  }

  .pt-xlx-s4 {
    padding-top: .25rem;
  }

  .-pt-xlx-s4 {
    padding-top: -.25rem;
  }

  .pb-xlx-s4 {
    padding-bottom: .25rem;
  }

  .-pb-xlx-s4 {
    padding-bottom: -.25rem;
  }

  .pr-xlx-s4 {
    padding-right: .25rem;
  }

  .-pr-xlx-s4 {
    padding-right: -.25rem;
  }

  .pl-xlx-s4 {
    padding-left: .25rem;
  }

  .-pl-xlx-s4 {
    padding-left: -.25rem;
  }

  .mx-xlx-s4 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .my-xlx-s4 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .px-xlx-s4 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .py-xlx-s4 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .gap-xlx-s4 {
    gap: .25rem;
  }

  .pm-xlx-s4, .gx-xlx-s4 > .gx-child {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .gx-xlx-s4 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .25rem;
  }

  .gx-xlx-s4 > .gx-child:last-of-type {
    padding-left: .25rem;
    padding-right: 0;
  }

  .m-xlx-s5 {
    margin: .3125rem;
  }

  .-m-xlx-s5 {
    margin: -.3125rem;
  }

  .mt-xlx-s5 {
    margin-top: .3125rem;
  }

  .-mt-xlx-s5 {
    margin-top: -.3125rem;
  }

  .mb-xlx-s5 {
    margin-bottom: .3125rem;
  }

  .-mb-xlx-s5 {
    margin-bottom: -.3125rem;
  }

  .mr-xlx-s5 {
    margin-right: .3125rem;
  }

  .-mr-xlx-s5 {
    margin-right: -.3125rem;
  }

  .ml-xlx-s5 {
    margin-left: .3125rem;
  }

  .-ml-xlx-s5 {
    margin-left: -.3125rem;
  }

  .p-xlx-s5 {
    padding: .3125rem;
  }

  .-p-xlx-s5 {
    padding: -.3125rem;
  }

  .pt-xlx-s5 {
    padding-top: .3125rem;
  }

  .-pt-xlx-s5 {
    padding-top: -.3125rem;
  }

  .pb-xlx-s5 {
    padding-bottom: .3125rem;
  }

  .-pb-xlx-s5 {
    padding-bottom: -.3125rem;
  }

  .pr-xlx-s5 {
    padding-right: .3125rem;
  }

  .-pr-xlx-s5 {
    padding-right: -.3125rem;
  }

  .pl-xlx-s5 {
    padding-left: .3125rem;
  }

  .-pl-xlx-s5 {
    padding-left: -.3125rem;
  }

  .mx-xlx-s5 {
    margin-left: .3125rem;
    margin-right: .3125rem;
  }

  .my-xlx-s5 {
    margin-top: .3125rem;
    margin-bottom: .3125rem;
  }

  .px-xlx-s5 {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .py-xlx-s5 {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
  }

  .gap-xlx-s5 {
    gap: .3125rem;
  }

  .pm-xlx-s5, .gx-xlx-s5 > .gx-child {
    padding-left: .15625rem;
    padding-right: .15625rem;
  }

  .gx-xlx-s5 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .3125rem;
  }

  .gx-xlx-s5 > .gx-child:last-of-type {
    padding-left: .3125rem;
    padding-right: 0;
  }

  .m-xlx-s6 {
    margin: .375rem;
  }

  .-m-xlx-s6 {
    margin: -.375rem;
  }

  .mt-xlx-s6 {
    margin-top: .375rem;
  }

  .-mt-xlx-s6 {
    margin-top: -.375rem;
  }

  .mb-xlx-s6 {
    margin-bottom: .375rem;
  }

  .-mb-xlx-s6 {
    margin-bottom: -.375rem;
  }

  .mr-xlx-s6 {
    margin-right: .375rem;
  }

  .-mr-xlx-s6 {
    margin-right: -.375rem;
  }

  .ml-xlx-s6 {
    margin-left: .375rem;
  }

  .-ml-xlx-s6 {
    margin-left: -.375rem;
  }

  .p-xlx-s6 {
    padding: .375rem;
  }

  .-p-xlx-s6 {
    padding: -.375rem;
  }

  .pt-xlx-s6 {
    padding-top: .375rem;
  }

  .-pt-xlx-s6 {
    padding-top: -.375rem;
  }

  .pb-xlx-s6 {
    padding-bottom: .375rem;
  }

  .-pb-xlx-s6 {
    padding-bottom: -.375rem;
  }

  .pr-xlx-s6 {
    padding-right: .375rem;
  }

  .-pr-xlx-s6 {
    padding-right: -.375rem;
  }

  .pl-xlx-s6 {
    padding-left: .375rem;
  }

  .-pl-xlx-s6 {
    padding-left: -.375rem;
  }

  .mx-xlx-s6 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .my-xlx-s6 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .px-xlx-s6 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .py-xlx-s6 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .gap-xlx-s6 {
    gap: .375rem;
  }

  .pm-xlx-s6, .gx-xlx-s6 > .gx-child {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .gx-xlx-s6 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .375rem;
  }

  .gx-xlx-s6 > .gx-child:last-of-type {
    padding-left: .375rem;
    padding-right: 0;
  }

  .m-xlx-s7 {
    margin: .4375rem;
  }

  .-m-xlx-s7 {
    margin: -.4375rem;
  }

  .mt-xlx-s7 {
    margin-top: .4375rem;
  }

  .-mt-xlx-s7 {
    margin-top: -.4375rem;
  }

  .mb-xlx-s7 {
    margin-bottom: .4375rem;
  }

  .-mb-xlx-s7 {
    margin-bottom: -.4375rem;
  }

  .mr-xlx-s7 {
    margin-right: .4375rem;
  }

  .-mr-xlx-s7 {
    margin-right: -.4375rem;
  }

  .ml-xlx-s7 {
    margin-left: .4375rem;
  }

  .-ml-xlx-s7 {
    margin-left: -.4375rem;
  }

  .p-xlx-s7 {
    padding: .4375rem;
  }

  .-p-xlx-s7 {
    padding: -.4375rem;
  }

  .pt-xlx-s7 {
    padding-top: .4375rem;
  }

  .-pt-xlx-s7 {
    padding-top: -.4375rem;
  }

  .pb-xlx-s7 {
    padding-bottom: .4375rem;
  }

  .-pb-xlx-s7 {
    padding-bottom: -.4375rem;
  }

  .pr-xlx-s7 {
    padding-right: .4375rem;
  }

  .-pr-xlx-s7 {
    padding-right: -.4375rem;
  }

  .pl-xlx-s7 {
    padding-left: .4375rem;
  }

  .-pl-xlx-s7 {
    padding-left: -.4375rem;
  }

  .mx-xlx-s7 {
    margin-left: .4375rem;
    margin-right: .4375rem;
  }

  .my-xlx-s7 {
    margin-top: .4375rem;
    margin-bottom: .4375rem;
  }

  .px-xlx-s7 {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .py-xlx-s7 {
    padding-top: .4375rem;
    padding-bottom: .4375rem;
  }

  .gap-xlx-s7 {
    gap: .4375rem;
  }

  .pm-xlx-s7, .gx-xlx-s7 > .gx-child {
    padding-left: .21875rem;
    padding-right: .21875rem;
  }

  .gx-xlx-s7 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .4375rem;
  }

  .gx-xlx-s7 > .gx-child:last-of-type {
    padding-left: .4375rem;
    padding-right: 0;
  }

  .m-xlx-s8 {
    margin: .5rem;
  }

  .-m-xlx-s8 {
    margin: -.5rem;
  }

  .mt-xlx-s8 {
    margin-top: .5rem;
  }

  .-mt-xlx-s8 {
    margin-top: -.5rem;
  }

  .mb-xlx-s8 {
    margin-bottom: .5rem;
  }

  .-mb-xlx-s8 {
    margin-bottom: -.5rem;
  }

  .mr-xlx-s8 {
    margin-right: .5rem;
  }

  .-mr-xlx-s8 {
    margin-right: -.5rem;
  }

  .ml-xlx-s8 {
    margin-left: .5rem;
  }

  .-ml-xlx-s8 {
    margin-left: -.5rem;
  }

  .p-xlx-s8 {
    padding: .5rem;
  }

  .-p-xlx-s8 {
    padding: -.5rem;
  }

  .pt-xlx-s8 {
    padding-top: .5rem;
  }

  .-pt-xlx-s8 {
    padding-top: -.5rem;
  }

  .pb-xlx-s8 {
    padding-bottom: .5rem;
  }

  .-pb-xlx-s8 {
    padding-bottom: -.5rem;
  }

  .pr-xlx-s8 {
    padding-right: .5rem;
  }

  .-pr-xlx-s8 {
    padding-right: -.5rem;
  }

  .pl-xlx-s8 {
    padding-left: .5rem;
  }

  .-pl-xlx-s8 {
    padding-left: -.5rem;
  }

  .mx-xlx-s8 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .my-xlx-s8 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .px-xlx-s8 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .py-xlx-s8 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .gap-xlx-s8 {
    gap: .5rem;
  }

  .pm-xlx-s8, .gx-xlx-s8 > .gx-child {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .gx-xlx-s8 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5rem;
  }

  .gx-xlx-s8 > .gx-child:last-of-type {
    padding-left: .5rem;
    padding-right: 0;
  }

  .m-xlx-s9 {
    margin: .5625rem;
  }

  .-m-xlx-s9 {
    margin: -.5625rem;
  }

  .mt-xlx-s9 {
    margin-top: .5625rem;
  }

  .-mt-xlx-s9 {
    margin-top: -.5625rem;
  }

  .mb-xlx-s9 {
    margin-bottom: .5625rem;
  }

  .-mb-xlx-s9 {
    margin-bottom: -.5625rem;
  }

  .mr-xlx-s9 {
    margin-right: .5625rem;
  }

  .-mr-xlx-s9 {
    margin-right: -.5625rem;
  }

  .ml-xlx-s9 {
    margin-left: .5625rem;
  }

  .-ml-xlx-s9 {
    margin-left: -.5625rem;
  }

  .p-xlx-s9 {
    padding: .5625rem;
  }

  .-p-xlx-s9 {
    padding: -.5625rem;
  }

  .pt-xlx-s9 {
    padding-top: .5625rem;
  }

  .-pt-xlx-s9 {
    padding-top: -.5625rem;
  }

  .pb-xlx-s9 {
    padding-bottom: .5625rem;
  }

  .-pb-xlx-s9 {
    padding-bottom: -.5625rem;
  }

  .pr-xlx-s9 {
    padding-right: .5625rem;
  }

  .-pr-xlx-s9 {
    padding-right: -.5625rem;
  }

  .pl-xlx-s9 {
    padding-left: .5625rem;
  }

  .-pl-xlx-s9 {
    padding-left: -.5625rem;
  }

  .mx-xlx-s9 {
    margin-left: .5625rem;
    margin-right: .5625rem;
  }

  .my-xlx-s9 {
    margin-top: .5625rem;
    margin-bottom: .5625rem;
  }

  .px-xlx-s9 {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .py-xlx-s9 {
    padding-top: .5625rem;
    padding-bottom: .5625rem;
  }

  .gap-xlx-s9 {
    gap: .5625rem;
  }

  .pm-xlx-s9, .gx-xlx-s9 > .gx-child {
    padding-left: .28125rem;
    padding-right: .28125rem;
  }

  .gx-xlx-s9 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5625rem;
  }

  .gx-xlx-s9 > .gx-child:last-of-type {
    padding-left: .5625rem;
    padding-right: 0;
  }

  .m-xlx-s10 {
    margin: .625rem;
  }

  .-m-xlx-s10 {
    margin: -.625rem;
  }

  .mt-xlx-s10 {
    margin-top: .625rem;
  }

  .-mt-xlx-s10 {
    margin-top: -.625rem;
  }

  .mb-xlx-s10 {
    margin-bottom: .625rem;
  }

  .-mb-xlx-s10 {
    margin-bottom: -.625rem;
  }

  .mr-xlx-s10 {
    margin-right: .625rem;
  }

  .-mr-xlx-s10 {
    margin-right: -.625rem;
  }

  .ml-xlx-s10 {
    margin-left: .625rem;
  }

  .-ml-xlx-s10 {
    margin-left: -.625rem;
  }

  .p-xlx-s10 {
    padding: .625rem;
  }

  .-p-xlx-s10 {
    padding: -.625rem;
  }

  .pt-xlx-s10 {
    padding-top: .625rem;
  }

  .-pt-xlx-s10 {
    padding-top: -.625rem;
  }

  .pb-xlx-s10 {
    padding-bottom: .625rem;
  }

  .-pb-xlx-s10 {
    padding-bottom: -.625rem;
  }

  .pr-xlx-s10 {
    padding-right: .625rem;
  }

  .-pr-xlx-s10 {
    padding-right: -.625rem;
  }

  .pl-xlx-s10 {
    padding-left: .625rem;
  }

  .-pl-xlx-s10 {
    padding-left: -.625rem;
  }

  .mx-xlx-s10 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .my-xlx-s10 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .px-xlx-s10 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .py-xlx-s10 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .gap-xlx-s10 {
    gap: .625rem;
  }

  .pm-xlx-s10, .gx-xlx-s10 > .gx-child {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .gx-xlx-s10 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .625rem;
  }

  .gx-xlx-s10 > .gx-child:last-of-type {
    padding-left: .625rem;
    padding-right: 0;
  }

  .m-xlx-s12 {
    margin: .75rem;
  }

  .-m-xlx-s12 {
    margin: -.75rem;
  }

  .mt-xlx-s12 {
    margin-top: .75rem;
  }

  .-mt-xlx-s12 {
    margin-top: -.75rem;
  }

  .mb-xlx-s12 {
    margin-bottom: .75rem;
  }

  .-mb-xlx-s12 {
    margin-bottom: -.75rem;
  }

  .mr-xlx-s12 {
    margin-right: .75rem;
  }

  .-mr-xlx-s12 {
    margin-right: -.75rem;
  }

  .ml-xlx-s12 {
    margin-left: .75rem;
  }

  .-ml-xlx-s12 {
    margin-left: -.75rem;
  }

  .p-xlx-s12 {
    padding: .75rem;
  }

  .-p-xlx-s12 {
    padding: -.75rem;
  }

  .pt-xlx-s12 {
    padding-top: .75rem;
  }

  .-pt-xlx-s12 {
    padding-top: -.75rem;
  }

  .pb-xlx-s12 {
    padding-bottom: .75rem;
  }

  .-pb-xlx-s12 {
    padding-bottom: -.75rem;
  }

  .pr-xlx-s12 {
    padding-right: .75rem;
  }

  .-pr-xlx-s12 {
    padding-right: -.75rem;
  }

  .pl-xlx-s12 {
    padding-left: .75rem;
  }

  .-pl-xlx-s12 {
    padding-left: -.75rem;
  }

  .mx-xlx-s12 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .my-xlx-s12 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .px-xlx-s12 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .py-xlx-s12 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .gap-xlx-s12 {
    gap: .75rem;
  }

  .pm-xlx-s12, .gx-xlx-s12 > .gx-child {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .gx-xlx-s12 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .75rem;
  }

  .gx-xlx-s12 > .gx-child:last-of-type {
    padding-left: .75rem;
    padding-right: 0;
  }

  .m-xlx-s13 {
    margin: .8125rem;
  }

  .-m-xlx-s13 {
    margin: -.8125rem;
  }

  .mt-xlx-s13 {
    margin-top: .8125rem;
  }

  .-mt-xlx-s13 {
    margin-top: -.8125rem;
  }

  .mb-xlx-s13 {
    margin-bottom: .8125rem;
  }

  .-mb-xlx-s13 {
    margin-bottom: -.8125rem;
  }

  .mr-xlx-s13 {
    margin-right: .8125rem;
  }

  .-mr-xlx-s13 {
    margin-right: -.8125rem;
  }

  .ml-xlx-s13 {
    margin-left: .8125rem;
  }

  .-ml-xlx-s13 {
    margin-left: -.8125rem;
  }

  .p-xlx-s13 {
    padding: .8125rem;
  }

  .-p-xlx-s13 {
    padding: -.8125rem;
  }

  .pt-xlx-s13 {
    padding-top: .8125rem;
  }

  .-pt-xlx-s13 {
    padding-top: -.8125rem;
  }

  .pb-xlx-s13 {
    padding-bottom: .8125rem;
  }

  .-pb-xlx-s13 {
    padding-bottom: -.8125rem;
  }

  .pr-xlx-s13 {
    padding-right: .8125rem;
  }

  .-pr-xlx-s13 {
    padding-right: -.8125rem;
  }

  .pl-xlx-s13 {
    padding-left: .8125rem;
  }

  .-pl-xlx-s13 {
    padding-left: -.8125rem;
  }

  .mx-xlx-s13 {
    margin-left: .8125rem;
    margin-right: .8125rem;
  }

  .my-xlx-s13 {
    margin-top: .8125rem;
    margin-bottom: .8125rem;
  }

  .px-xlx-s13 {
    padding-left: .8125rem;
    padding-right: .8125rem;
  }

  .py-xlx-s13 {
    padding-top: .8125rem;
    padding-bottom: .8125rem;
  }

  .gap-xlx-s13 {
    gap: .8125rem;
  }

  .pm-xlx-s13, .gx-xlx-s13 > .gx-child {
    padding-left: .40625rem;
    padding-right: .40625rem;
  }

  .gx-xlx-s13 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .8125rem;
  }

  .gx-xlx-s13 > .gx-child:last-of-type {
    padding-left: .8125rem;
    padding-right: 0;
  }

  .m-xlx-s14 {
    margin: .875rem;
  }

  .-m-xlx-s14 {
    margin: -.875rem;
  }

  .mt-xlx-s14 {
    margin-top: .875rem;
  }

  .-mt-xlx-s14 {
    margin-top: -.875rem;
  }

  .mb-xlx-s14 {
    margin-bottom: .875rem;
  }

  .-mb-xlx-s14 {
    margin-bottom: -.875rem;
  }

  .mr-xlx-s14 {
    margin-right: .875rem;
  }

  .-mr-xlx-s14 {
    margin-right: -.875rem;
  }

  .ml-xlx-s14 {
    margin-left: .875rem;
  }

  .-ml-xlx-s14 {
    margin-left: -.875rem;
  }

  .p-xlx-s14 {
    padding: .875rem;
  }

  .-p-xlx-s14 {
    padding: -.875rem;
  }

  .pt-xlx-s14 {
    padding-top: .875rem;
  }

  .-pt-xlx-s14 {
    padding-top: -.875rem;
  }

  .pb-xlx-s14 {
    padding-bottom: .875rem;
  }

  .-pb-xlx-s14 {
    padding-bottom: -.875rem;
  }

  .pr-xlx-s14 {
    padding-right: .875rem;
  }

  .-pr-xlx-s14 {
    padding-right: -.875rem;
  }

  .pl-xlx-s14 {
    padding-left: .875rem;
  }

  .-pl-xlx-s14 {
    padding-left: -.875rem;
  }

  .mx-xlx-s14 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .my-xlx-s14 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .px-xlx-s14 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .py-xlx-s14 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .gap-xlx-s14 {
    gap: .875rem;
  }

  .pm-xlx-s14, .gx-xlx-s14 > .gx-child {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .gx-xlx-s14 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .875rem;
  }

  .gx-xlx-s14 > .gx-child:last-of-type {
    padding-left: .875rem;
    padding-right: 0;
  }

  .m-xlx-s15 {
    margin: .9375rem;
  }

  .-m-xlx-s15 {
    margin: -.9375rem;
  }

  .mt-xlx-s15 {
    margin-top: .9375rem;
  }

  .-mt-xlx-s15 {
    margin-top: -.9375rem;
  }

  .mb-xlx-s15 {
    margin-bottom: .9375rem;
  }

  .-mb-xlx-s15 {
    margin-bottom: -.9375rem;
  }

  .mr-xlx-s15 {
    margin-right: .9375rem;
  }

  .-mr-xlx-s15 {
    margin-right: -.9375rem;
  }

  .ml-xlx-s15 {
    margin-left: .9375rem;
  }

  .-ml-xlx-s15 {
    margin-left: -.9375rem;
  }

  .p-xlx-s15 {
    padding: .9375rem;
  }

  .-p-xlx-s15 {
    padding: -.9375rem;
  }

  .pt-xlx-s15 {
    padding-top: .9375rem;
  }

  .-pt-xlx-s15 {
    padding-top: -.9375rem;
  }

  .pb-xlx-s15 {
    padding-bottom: .9375rem;
  }

  .-pb-xlx-s15 {
    padding-bottom: -.9375rem;
  }

  .pr-xlx-s15 {
    padding-right: .9375rem;
  }

  .-pr-xlx-s15 {
    padding-right: -.9375rem;
  }

  .pl-xlx-s15 {
    padding-left: .9375rem;
  }

  .-pl-xlx-s15 {
    padding-left: -.9375rem;
  }

  .mx-xlx-s15 {
    margin-left: .9375rem;
    margin-right: .9375rem;
  }

  .my-xlx-s15 {
    margin-top: .9375rem;
    margin-bottom: .9375rem;
  }

  .px-xlx-s15 {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .py-xlx-s15 {
    padding-top: .9375rem;
    padding-bottom: .9375rem;
  }

  .gap-xlx-s15 {
    gap: .9375rem;
  }

  .pm-xlx-s15, .gx-xlx-s15 > .gx-child {
    padding-left: .46875rem;
    padding-right: .46875rem;
  }

  .gx-xlx-s15 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .9375rem;
  }

  .gx-xlx-s15 > .gx-child:last-of-type {
    padding-left: .9375rem;
    padding-right: 0;
  }

  .m-xlx-s16 {
    margin: 1rem;
  }

  .-m-xlx-s16 {
    margin: -1rem;
  }

  .mt-xlx-s16 {
    margin-top: 1rem;
  }

  .-mt-xlx-s16 {
    margin-top: -1rem;
  }

  .mb-xlx-s16 {
    margin-bottom: 1rem;
  }

  .-mb-xlx-s16 {
    margin-bottom: -1rem;
  }

  .mr-xlx-s16 {
    margin-right: 1rem;
  }

  .-mr-xlx-s16 {
    margin-right: -1rem;
  }

  .ml-xlx-s16 {
    margin-left: 1rem;
  }

  .-ml-xlx-s16 {
    margin-left: -1rem;
  }

  .p-xlx-s16 {
    padding: 1rem;
  }

  .-p-xlx-s16 {
    padding: -1rem;
  }

  .pt-xlx-s16 {
    padding-top: 1rem;
  }

  .-pt-xlx-s16 {
    padding-top: -1rem;
  }

  .pb-xlx-s16 {
    padding-bottom: 1rem;
  }

  .-pb-xlx-s16 {
    padding-bottom: -1rem;
  }

  .pr-xlx-s16 {
    padding-right: 1rem;
  }

  .-pr-xlx-s16 {
    padding-right: -1rem;
  }

  .pl-xlx-s16 {
    padding-left: 1rem;
  }

  .-pl-xlx-s16 {
    padding-left: -1rem;
  }

  .mx-xlx-s16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .my-xlx-s16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .px-xlx-s16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .py-xlx-s16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .gap-xlx-s16 {
    gap: 1rem;
  }

  .pm-xlx-s16, .gx-xlx-s16 > .gx-child {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .gx-xlx-s16 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1rem;
  }

  .gx-xlx-s16 > .gx-child:last-of-type {
    padding-left: 1rem;
    padding-right: 0;
  }

  .m-xlx-s18 {
    margin: 1.125rem;
  }

  .-m-xlx-s18 {
    margin: -1.125rem;
  }

  .mt-xlx-s18 {
    margin-top: 1.125rem;
  }

  .-mt-xlx-s18 {
    margin-top: -1.125rem;
  }

  .mb-xlx-s18 {
    margin-bottom: 1.125rem;
  }

  .-mb-xlx-s18 {
    margin-bottom: -1.125rem;
  }

  .mr-xlx-s18 {
    margin-right: 1.125rem;
  }

  .-mr-xlx-s18 {
    margin-right: -1.125rem;
  }

  .ml-xlx-s18 {
    margin-left: 1.125rem;
  }

  .-ml-xlx-s18 {
    margin-left: -1.125rem;
  }

  .p-xlx-s18 {
    padding: 1.125rem;
  }

  .-p-xlx-s18 {
    padding: -1.125rem;
  }

  .pt-xlx-s18 {
    padding-top: 1.125rem;
  }

  .-pt-xlx-s18 {
    padding-top: -1.125rem;
  }

  .pb-xlx-s18 {
    padding-bottom: 1.125rem;
  }

  .-pb-xlx-s18 {
    padding-bottom: -1.125rem;
  }

  .pr-xlx-s18 {
    padding-right: 1.125rem;
  }

  .-pr-xlx-s18 {
    padding-right: -1.125rem;
  }

  .pl-xlx-s18 {
    padding-left: 1.125rem;
  }

  .-pl-xlx-s18 {
    padding-left: -1.125rem;
  }

  .mx-xlx-s18 {
    margin-left: 1.125rem;
    margin-right: 1.125rem;
  }

  .my-xlx-s18 {
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
  }

  .px-xlx-s18 {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .py-xlx-s18 {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }

  .gap-xlx-s18 {
    gap: 1.125rem;
  }

  .pm-xlx-s18, .gx-xlx-s18 > .gx-child {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .gx-xlx-s18 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.125rem;
  }

  .gx-xlx-s18 > .gx-child:last-of-type {
    padding-left: 1.125rem;
    padding-right: 0;
  }

  .m-xlx-s20 {
    margin: 1.25rem;
  }

  .-m-xlx-s20 {
    margin: -1.25rem;
  }

  .mt-xlx-s20 {
    margin-top: 1.25rem;
  }

  .-mt-xlx-s20 {
    margin-top: -1.25rem;
  }

  .mb-xlx-s20 {
    margin-bottom: 1.25rem;
  }

  .-mb-xlx-s20 {
    margin-bottom: -1.25rem;
  }

  .mr-xlx-s20 {
    margin-right: 1.25rem;
  }

  .-mr-xlx-s20 {
    margin-right: -1.25rem;
  }

  .ml-xlx-s20 {
    margin-left: 1.25rem;
  }

  .-ml-xlx-s20 {
    margin-left: -1.25rem;
  }

  .p-xlx-s20 {
    padding: 1.25rem;
  }

  .-p-xlx-s20 {
    padding: -1.25rem;
  }

  .pt-xlx-s20 {
    padding-top: 1.25rem;
  }

  .-pt-xlx-s20 {
    padding-top: -1.25rem;
  }

  .pb-xlx-s20 {
    padding-bottom: 1.25rem;
  }

  .-pb-xlx-s20 {
    padding-bottom: -1.25rem;
  }

  .pr-xlx-s20 {
    padding-right: 1.25rem;
  }

  .-pr-xlx-s20 {
    padding-right: -1.25rem;
  }

  .pl-xlx-s20 {
    padding-left: 1.25rem;
  }

  .-pl-xlx-s20 {
    padding-left: -1.25rem;
  }

  .mx-xlx-s20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .my-xlx-s20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .px-xlx-s20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .py-xlx-s20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .gap-xlx-s20 {
    gap: 1.25rem;
  }

  .pm-xlx-s20, .gx-xlx-s20 > .gx-child {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .gx-xlx-s20 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.25rem;
  }

  .gx-xlx-s20 > .gx-child:last-of-type {
    padding-left: 1.25rem;
    padding-right: 0;
  }

  .m-xlx-s22 {
    margin: 1.375rem;
  }

  .-m-xlx-s22 {
    margin: -1.375rem;
  }

  .mt-xlx-s22 {
    margin-top: 1.375rem;
  }

  .-mt-xlx-s22 {
    margin-top: -1.375rem;
  }

  .mb-xlx-s22 {
    margin-bottom: 1.375rem;
  }

  .-mb-xlx-s22 {
    margin-bottom: -1.375rem;
  }

  .mr-xlx-s22 {
    margin-right: 1.375rem;
  }

  .-mr-xlx-s22 {
    margin-right: -1.375rem;
  }

  .ml-xlx-s22 {
    margin-left: 1.375rem;
  }

  .-ml-xlx-s22 {
    margin-left: -1.375rem;
  }

  .p-xlx-s22 {
    padding: 1.375rem;
  }

  .-p-xlx-s22 {
    padding: -1.375rem;
  }

  .pt-xlx-s22 {
    padding-top: 1.375rem;
  }

  .-pt-xlx-s22 {
    padding-top: -1.375rem;
  }

  .pb-xlx-s22 {
    padding-bottom: 1.375rem;
  }

  .-pb-xlx-s22 {
    padding-bottom: -1.375rem;
  }

  .pr-xlx-s22 {
    padding-right: 1.375rem;
  }

  .-pr-xlx-s22 {
    padding-right: -1.375rem;
  }

  .pl-xlx-s22 {
    padding-left: 1.375rem;
  }

  .-pl-xlx-s22 {
    padding-left: -1.375rem;
  }

  .mx-xlx-s22 {
    margin-left: 1.375rem;
    margin-right: 1.375rem;
  }

  .my-xlx-s22 {
    margin-top: 1.375rem;
    margin-bottom: 1.375rem;
  }

  .px-xlx-s22 {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }

  .py-xlx-s22 {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
  }

  .gap-xlx-s22 {
    gap: 1.375rem;
  }

  .pm-xlx-s22, .gx-xlx-s22 > .gx-child {
    padding-left: .6875rem;
    padding-right: .6875rem;
  }

  .gx-xlx-s22 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.375rem;
  }

  .gx-xlx-s22 > .gx-child:last-of-type {
    padding-left: 1.375rem;
    padding-right: 0;
  }

  .m-xlx-s24 {
    margin: 1.5rem;
  }

  .-m-xlx-s24 {
    margin: -1.5rem;
  }

  .mt-xlx-s24 {
    margin-top: 1.5rem;
  }

  .-mt-xlx-s24 {
    margin-top: -1.5rem;
  }

  .mb-xlx-s24 {
    margin-bottom: 1.5rem;
  }

  .-mb-xlx-s24 {
    margin-bottom: -1.5rem;
  }

  .mr-xlx-s24 {
    margin-right: 1.5rem;
  }

  .-mr-xlx-s24 {
    margin-right: -1.5rem;
  }

  .ml-xlx-s24 {
    margin-left: 1.5rem;
  }

  .-ml-xlx-s24 {
    margin-left: -1.5rem;
  }

  .p-xlx-s24 {
    padding: 1.5rem;
  }

  .-p-xlx-s24 {
    padding: -1.5rem;
  }

  .pt-xlx-s24 {
    padding-top: 1.5rem;
  }

  .-pt-xlx-s24 {
    padding-top: -1.5rem;
  }

  .pb-xlx-s24 {
    padding-bottom: 1.5rem;
  }

  .-pb-xlx-s24 {
    padding-bottom: -1.5rem;
  }

  .pr-xlx-s24 {
    padding-right: 1.5rem;
  }

  .-pr-xlx-s24 {
    padding-right: -1.5rem;
  }

  .pl-xlx-s24 {
    padding-left: 1.5rem;
  }

  .-pl-xlx-s24 {
    padding-left: -1.5rem;
  }

  .mx-xlx-s24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .my-xlx-s24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .px-xlx-s24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .py-xlx-s24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .gap-xlx-s24 {
    gap: 1.5rem;
  }

  .pm-xlx-s24, .gx-xlx-s24 > .gx-child {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .gx-xlx-s24 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5rem;
  }

  .gx-xlx-s24 > .gx-child:last-of-type {
    padding-left: 1.5rem;
    padding-right: 0;
  }

  .m-xlx-s25 {
    margin: 1.5625rem;
  }

  .-m-xlx-s25 {
    margin: -1.5625rem;
  }

  .mt-xlx-s25 {
    margin-top: 1.5625rem;
  }

  .-mt-xlx-s25 {
    margin-top: -1.5625rem;
  }

  .mb-xlx-s25 {
    margin-bottom: 1.5625rem;
  }

  .-mb-xlx-s25 {
    margin-bottom: -1.5625rem;
  }

  .mr-xlx-s25 {
    margin-right: 1.5625rem;
  }

  .-mr-xlx-s25 {
    margin-right: -1.5625rem;
  }

  .ml-xlx-s25 {
    margin-left: 1.5625rem;
  }

  .-ml-xlx-s25 {
    margin-left: -1.5625rem;
  }

  .p-xlx-s25 {
    padding: 1.5625rem;
  }

  .-p-xlx-s25 {
    padding: -1.5625rem;
  }

  .pt-xlx-s25 {
    padding-top: 1.5625rem;
  }

  .-pt-xlx-s25 {
    padding-top: -1.5625rem;
  }

  .pb-xlx-s25 {
    padding-bottom: 1.5625rem;
  }

  .-pb-xlx-s25 {
    padding-bottom: -1.5625rem;
  }

  .pr-xlx-s25 {
    padding-right: 1.5625rem;
  }

  .-pr-xlx-s25 {
    padding-right: -1.5625rem;
  }

  .pl-xlx-s25 {
    padding-left: 1.5625rem;
  }

  .-pl-xlx-s25 {
    padding-left: -1.5625rem;
  }

  .mx-xlx-s25 {
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
  }

  .my-xlx-s25 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }

  .px-xlx-s25 {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .py-xlx-s25 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }

  .gap-xlx-s25 {
    gap: 1.5625rem;
  }

  .pm-xlx-s25, .gx-xlx-s25 > .gx-child {
    padding-left: .78125rem;
    padding-right: .78125rem;
  }

  .gx-xlx-s25 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5625rem;
  }

  .gx-xlx-s25 > .gx-child:last-of-type {
    padding-left: 1.5625rem;
    padding-right: 0;
  }

  .m-xlx-s27 {
    margin: 1.6875rem;
  }

  .-m-xlx-s27 {
    margin: -1.6875rem;
  }

  .mt-xlx-s27 {
    margin-top: 1.6875rem;
  }

  .-mt-xlx-s27 {
    margin-top: -1.6875rem;
  }

  .mb-xlx-s27 {
    margin-bottom: 1.6875rem;
  }

  .-mb-xlx-s27 {
    margin-bottom: -1.6875rem;
  }

  .mr-xlx-s27 {
    margin-right: 1.6875rem;
  }

  .-mr-xlx-s27 {
    margin-right: -1.6875rem;
  }

  .ml-xlx-s27 {
    margin-left: 1.6875rem;
  }

  .-ml-xlx-s27 {
    margin-left: -1.6875rem;
  }

  .p-xlx-s27 {
    padding: 1.6875rem;
  }

  .-p-xlx-s27 {
    padding: -1.6875rem;
  }

  .pt-xlx-s27 {
    padding-top: 1.6875rem;
  }

  .-pt-xlx-s27 {
    padding-top: -1.6875rem;
  }

  .pb-xlx-s27 {
    padding-bottom: 1.6875rem;
  }

  .-pb-xlx-s27 {
    padding-bottom: -1.6875rem;
  }

  .pr-xlx-s27 {
    padding-right: 1.6875rem;
  }

  .-pr-xlx-s27 {
    padding-right: -1.6875rem;
  }

  .pl-xlx-s27 {
    padding-left: 1.6875rem;
  }

  .-pl-xlx-s27 {
    padding-left: -1.6875rem;
  }

  .mx-xlx-s27 {
    margin-left: 1.6875rem;
    margin-right: 1.6875rem;
  }

  .my-xlx-s27 {
    margin-top: 1.6875rem;
    margin-bottom: 1.6875rem;
  }

  .px-xlx-s27 {
    padding-left: 1.6875rem;
    padding-right: 1.6875rem;
  }

  .py-xlx-s27 {
    padding-top: 1.6875rem;
    padding-bottom: 1.6875rem;
  }

  .gap-xlx-s27 {
    gap: 1.6875rem;
  }

  .pm-xlx-s27, .gx-xlx-s27 > .gx-child {
    padding-left: .84375rem;
    padding-right: .84375rem;
  }

  .gx-xlx-s27 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.6875rem;
  }

  .gx-xlx-s27 > .gx-child:last-of-type {
    padding-left: 1.6875rem;
    padding-right: 0;
  }

  .m-xlx-s29 {
    margin: 1.8125rem;
  }

  .-m-xlx-s29 {
    margin: -1.8125rem;
  }

  .mt-xlx-s29 {
    margin-top: 1.8125rem;
  }

  .-mt-xlx-s29 {
    margin-top: -1.8125rem;
  }

  .mb-xlx-s29 {
    margin-bottom: 1.8125rem;
  }

  .-mb-xlx-s29 {
    margin-bottom: -1.8125rem;
  }

  .mr-xlx-s29 {
    margin-right: 1.8125rem;
  }

  .-mr-xlx-s29 {
    margin-right: -1.8125rem;
  }

  .ml-xlx-s29 {
    margin-left: 1.8125rem;
  }

  .-ml-xlx-s29 {
    margin-left: -1.8125rem;
  }

  .p-xlx-s29 {
    padding: 1.8125rem;
  }

  .-p-xlx-s29 {
    padding: -1.8125rem;
  }

  .pt-xlx-s29 {
    padding-top: 1.8125rem;
  }

  .-pt-xlx-s29 {
    padding-top: -1.8125rem;
  }

  .pb-xlx-s29 {
    padding-bottom: 1.8125rem;
  }

  .-pb-xlx-s29 {
    padding-bottom: -1.8125rem;
  }

  .pr-xlx-s29 {
    padding-right: 1.8125rem;
  }

  .-pr-xlx-s29 {
    padding-right: -1.8125rem;
  }

  .pl-xlx-s29 {
    padding-left: 1.8125rem;
  }

  .-pl-xlx-s29 {
    padding-left: -1.8125rem;
  }

  .mx-xlx-s29 {
    margin-left: 1.8125rem;
    margin-right: 1.8125rem;
  }

  .my-xlx-s29 {
    margin-top: 1.8125rem;
    margin-bottom: 1.8125rem;
  }

  .px-xlx-s29 {
    padding-left: 1.8125rem;
    padding-right: 1.8125rem;
  }

  .py-xlx-s29 {
    padding-top: 1.8125rem;
    padding-bottom: 1.8125rem;
  }

  .gap-xlx-s29 {
    gap: 1.8125rem;
  }

  .pm-xlx-s29, .gx-xlx-s29 > .gx-child {
    padding-left: .90625rem;
    padding-right: .90625rem;
  }

  .gx-xlx-s29 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.8125rem;
  }

  .gx-xlx-s29 > .gx-child:last-of-type {
    padding-left: 1.8125rem;
    padding-right: 0;
  }

  .m-xlx-s30 {
    margin: 1.875rem;
  }

  .-m-xlx-s30 {
    margin: -1.875rem;
  }

  .mt-xlx-s30 {
    margin-top: 1.875rem;
  }

  .-mt-xlx-s30 {
    margin-top: -1.875rem;
  }

  .mb-xlx-s30 {
    margin-bottom: 1.875rem;
  }

  .-mb-xlx-s30 {
    margin-bottom: -1.875rem;
  }

  .mr-xlx-s30 {
    margin-right: 1.875rem;
  }

  .-mr-xlx-s30 {
    margin-right: -1.875rem;
  }

  .ml-xlx-s30 {
    margin-left: 1.875rem;
  }

  .-ml-xlx-s30 {
    margin-left: -1.875rem;
  }

  .p-xlx-s30 {
    padding: 1.875rem;
  }

  .-p-xlx-s30 {
    padding: -1.875rem;
  }

  .pt-xlx-s30 {
    padding-top: 1.875rem;
  }

  .-pt-xlx-s30 {
    padding-top: -1.875rem;
  }

  .pb-xlx-s30 {
    padding-bottom: 1.875rem;
  }

  .-pb-xlx-s30 {
    padding-bottom: -1.875rem;
  }

  .pr-xlx-s30 {
    padding-right: 1.875rem;
  }

  .-pr-xlx-s30 {
    padding-right: -1.875rem;
  }

  .pl-xlx-s30 {
    padding-left: 1.875rem;
  }

  .-pl-xlx-s30 {
    padding-left: -1.875rem;
  }

  .mx-xlx-s30 {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }

  .my-xlx-s30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .px-xlx-s30 {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .py-xlx-s30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .gap-xlx-s30 {
    gap: 1.875rem;
  }

  .pm-xlx-s30, .gx-xlx-s30 > .gx-child {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .gx-xlx-s30 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.875rem;
  }

  .gx-xlx-s30 > .gx-child:last-of-type {
    padding-left: 1.875rem;
    padding-right: 0;
  }

  .m-xlx-s32 {
    margin: 2rem;
  }

  .-m-xlx-s32 {
    margin: -2rem;
  }

  .mt-xlx-s32 {
    margin-top: 2rem;
  }

  .-mt-xlx-s32 {
    margin-top: -2rem;
  }

  .mb-xlx-s32 {
    margin-bottom: 2rem;
  }

  .-mb-xlx-s32 {
    margin-bottom: -2rem;
  }

  .mr-xlx-s32 {
    margin-right: 2rem;
  }

  .-mr-xlx-s32 {
    margin-right: -2rem;
  }

  .ml-xlx-s32 {
    margin-left: 2rem;
  }

  .-ml-xlx-s32 {
    margin-left: -2rem;
  }

  .p-xlx-s32 {
    padding: 2rem;
  }

  .-p-xlx-s32 {
    padding: -2rem;
  }

  .pt-xlx-s32 {
    padding-top: 2rem;
  }

  .-pt-xlx-s32 {
    padding-top: -2rem;
  }

  .pb-xlx-s32 {
    padding-bottom: 2rem;
  }

  .-pb-xlx-s32 {
    padding-bottom: -2rem;
  }

  .pr-xlx-s32 {
    padding-right: 2rem;
  }

  .-pr-xlx-s32 {
    padding-right: -2rem;
  }

  .pl-xlx-s32 {
    padding-left: 2rem;
  }

  .-pl-xlx-s32 {
    padding-left: -2rem;
  }

  .mx-xlx-s32 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .my-xlx-s32 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .px-xlx-s32 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .py-xlx-s32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .gap-xlx-s32 {
    gap: 2rem;
  }

  .pm-xlx-s32, .gx-xlx-s32 > .gx-child {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .gx-xlx-s32 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2rem;
  }

  .gx-xlx-s32 > .gx-child:last-of-type {
    padding-left: 2rem;
    padding-right: 0;
  }

  .m-xlx-s35 {
    margin: 2.1875rem;
  }

  .-m-xlx-s35 {
    margin: -2.1875rem;
  }

  .mt-xlx-s35 {
    margin-top: 2.1875rem;
  }

  .-mt-xlx-s35 {
    margin-top: -2.1875rem;
  }

  .mb-xlx-s35 {
    margin-bottom: 2.1875rem;
  }

  .-mb-xlx-s35 {
    margin-bottom: -2.1875rem;
  }

  .mr-xlx-s35 {
    margin-right: 2.1875rem;
  }

  .-mr-xlx-s35 {
    margin-right: -2.1875rem;
  }

  .ml-xlx-s35 {
    margin-left: 2.1875rem;
  }

  .-ml-xlx-s35 {
    margin-left: -2.1875rem;
  }

  .p-xlx-s35 {
    padding: 2.1875rem;
  }

  .-p-xlx-s35 {
    padding: -2.1875rem;
  }

  .pt-xlx-s35 {
    padding-top: 2.1875rem;
  }

  .-pt-xlx-s35 {
    padding-top: -2.1875rem;
  }

  .pb-xlx-s35 {
    padding-bottom: 2.1875rem;
  }

  .-pb-xlx-s35 {
    padding-bottom: -2.1875rem;
  }

  .pr-xlx-s35 {
    padding-right: 2.1875rem;
  }

  .-pr-xlx-s35 {
    padding-right: -2.1875rem;
  }

  .pl-xlx-s35 {
    padding-left: 2.1875rem;
  }

  .-pl-xlx-s35 {
    padding-left: -2.1875rem;
  }

  .mx-xlx-s35 {
    margin-left: 2.1875rem;
    margin-right: 2.1875rem;
  }

  .my-xlx-s35 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }

  .px-xlx-s35 {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .py-xlx-s35 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }

  .gap-xlx-s35 {
    gap: 2.1875rem;
  }

  .pm-xlx-s35, .gx-xlx-s35 > .gx-child {
    padding-left: 1.09375rem;
    padding-right: 1.09375rem;
  }

  .gx-xlx-s35 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.1875rem;
  }

  .gx-xlx-s35 > .gx-child:last-of-type {
    padding-left: 2.1875rem;
    padding-right: 0;
  }

  .m-xlx-s40 {
    margin: 2.5rem;
  }

  .-m-xlx-s40 {
    margin: -2.5rem;
  }

  .mt-xlx-s40 {
    margin-top: 2.5rem;
  }

  .-mt-xlx-s40 {
    margin-top: -2.5rem;
  }

  .mb-xlx-s40 {
    margin-bottom: 2.5rem;
  }

  .-mb-xlx-s40 {
    margin-bottom: -2.5rem;
  }

  .mr-xlx-s40 {
    margin-right: 2.5rem;
  }

  .-mr-xlx-s40 {
    margin-right: -2.5rem;
  }

  .ml-xlx-s40 {
    margin-left: 2.5rem;
  }

  .-ml-xlx-s40 {
    margin-left: -2.5rem;
  }

  .p-xlx-s40 {
    padding: 2.5rem;
  }

  .-p-xlx-s40 {
    padding: -2.5rem;
  }

  .pt-xlx-s40 {
    padding-top: 2.5rem;
  }

  .-pt-xlx-s40 {
    padding-top: -2.5rem;
  }

  .pb-xlx-s40 {
    padding-bottom: 2.5rem;
  }

  .-pb-xlx-s40 {
    padding-bottom: -2.5rem;
  }

  .pr-xlx-s40 {
    padding-right: 2.5rem;
  }

  .-pr-xlx-s40 {
    padding-right: -2.5rem;
  }

  .pl-xlx-s40 {
    padding-left: 2.5rem;
  }

  .-pl-xlx-s40 {
    padding-left: -2.5rem;
  }

  .mx-xlx-s40 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .my-xlx-s40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .px-xlx-s40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .py-xlx-s40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .gap-xlx-s40 {
    gap: 2.5rem;
  }

  .pm-xlx-s40, .gx-xlx-s40 > .gx-child {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .gx-xlx-s40 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.5rem;
  }

  .gx-xlx-s40 > .gx-child:last-of-type {
    padding-left: 2.5rem;
    padding-right: 0;
  }

  .m-xlx-s45 {
    margin: 2.8125rem;
  }

  .-m-xlx-s45 {
    margin: -2.8125rem;
  }

  .mt-xlx-s45 {
    margin-top: 2.8125rem;
  }

  .-mt-xlx-s45 {
    margin-top: -2.8125rem;
  }

  .mb-xlx-s45 {
    margin-bottom: 2.8125rem;
  }

  .-mb-xlx-s45 {
    margin-bottom: -2.8125rem;
  }

  .mr-xlx-s45 {
    margin-right: 2.8125rem;
  }

  .-mr-xlx-s45 {
    margin-right: -2.8125rem;
  }

  .ml-xlx-s45 {
    margin-left: 2.8125rem;
  }

  .-ml-xlx-s45 {
    margin-left: -2.8125rem;
  }

  .p-xlx-s45 {
    padding: 2.8125rem;
  }

  .-p-xlx-s45 {
    padding: -2.8125rem;
  }

  .pt-xlx-s45 {
    padding-top: 2.8125rem;
  }

  .-pt-xlx-s45 {
    padding-top: -2.8125rem;
  }

  .pb-xlx-s45 {
    padding-bottom: 2.8125rem;
  }

  .-pb-xlx-s45 {
    padding-bottom: -2.8125rem;
  }

  .pr-xlx-s45 {
    padding-right: 2.8125rem;
  }

  .-pr-xlx-s45 {
    padding-right: -2.8125rem;
  }

  .pl-xlx-s45 {
    padding-left: 2.8125rem;
  }

  .-pl-xlx-s45 {
    padding-left: -2.8125rem;
  }

  .mx-xlx-s45 {
    margin-left: 2.8125rem;
    margin-right: 2.8125rem;
  }

  .my-xlx-s45 {
    margin-top: 2.8125rem;
    margin-bottom: 2.8125rem;
  }

  .px-xlx-s45 {
    padding-left: 2.8125rem;
    padding-right: 2.8125rem;
  }

  .py-xlx-s45 {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }

  .gap-xlx-s45 {
    gap: 2.8125rem;
  }

  .pm-xlx-s45, .gx-xlx-s45 > .gx-child {
    padding-left: 1.40625rem;
    padding-right: 1.40625rem;
  }

  .gx-xlx-s45 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.8125rem;
  }

  .gx-xlx-s45 > .gx-child:last-of-type {
    padding-left: 2.8125rem;
    padding-right: 0;
  }

  .m-xlx-s48 {
    margin: 3rem;
  }

  .-m-xlx-s48 {
    margin: -3rem;
  }

  .mt-xlx-s48 {
    margin-top: 3rem;
  }

  .-mt-xlx-s48 {
    margin-top: -3rem;
  }

  .mb-xlx-s48 {
    margin-bottom: 3rem;
  }

  .-mb-xlx-s48 {
    margin-bottom: -3rem;
  }

  .mr-xlx-s48 {
    margin-right: 3rem;
  }

  .-mr-xlx-s48 {
    margin-right: -3rem;
  }

  .ml-xlx-s48 {
    margin-left: 3rem;
  }

  .-ml-xlx-s48 {
    margin-left: -3rem;
  }

  .p-xlx-s48 {
    padding: 3rem;
  }

  .-p-xlx-s48 {
    padding: -3rem;
  }

  .pt-xlx-s48 {
    padding-top: 3rem;
  }

  .-pt-xlx-s48 {
    padding-top: -3rem;
  }

  .pb-xlx-s48 {
    padding-bottom: 3rem;
  }

  .-pb-xlx-s48 {
    padding-bottom: -3rem;
  }

  .pr-xlx-s48 {
    padding-right: 3rem;
  }

  .-pr-xlx-s48 {
    padding-right: -3rem;
  }

  .pl-xlx-s48 {
    padding-left: 3rem;
  }

  .-pl-xlx-s48 {
    padding-left: -3rem;
  }

  .mx-xlx-s48 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .my-xlx-s48 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .px-xlx-s48 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .py-xlx-s48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .gap-xlx-s48 {
    gap: 3rem;
  }

  .pm-xlx-s48, .gx-xlx-s48 > .gx-child {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .gx-xlx-s48 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3rem;
  }

  .gx-xlx-s48 > .gx-child:last-of-type {
    padding-left: 3rem;
    padding-right: 0;
  }

  .m-xlx-s50 {
    margin: 3.125rem;
  }

  .-m-xlx-s50 {
    margin: -3.125rem;
  }

  .mt-xlx-s50 {
    margin-top: 3.125rem;
  }

  .-mt-xlx-s50 {
    margin-top: -3.125rem;
  }

  .mb-xlx-s50 {
    margin-bottom: 3.125rem;
  }

  .-mb-xlx-s50 {
    margin-bottom: -3.125rem;
  }

  .mr-xlx-s50 {
    margin-right: 3.125rem;
  }

  .-mr-xlx-s50 {
    margin-right: -3.125rem;
  }

  .ml-xlx-s50 {
    margin-left: 3.125rem;
  }

  .-ml-xlx-s50 {
    margin-left: -3.125rem;
  }

  .p-xlx-s50 {
    padding: 3.125rem;
  }

  .-p-xlx-s50 {
    padding: -3.125rem;
  }

  .pt-xlx-s50 {
    padding-top: 3.125rem;
  }

  .-pt-xlx-s50 {
    padding-top: -3.125rem;
  }

  .pb-xlx-s50 {
    padding-bottom: 3.125rem;
  }

  .-pb-xlx-s50 {
    padding-bottom: -3.125rem;
  }

  .pr-xlx-s50 {
    padding-right: 3.125rem;
  }

  .-pr-xlx-s50 {
    padding-right: -3.125rem;
  }

  .pl-xlx-s50 {
    padding-left: 3.125rem;
  }

  .-pl-xlx-s50 {
    padding-left: -3.125rem;
  }

  .mx-xlx-s50 {
    margin-left: 3.125rem;
    margin-right: 3.125rem;
  }

  .my-xlx-s50 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }

  .px-xlx-s50 {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .py-xlx-s50 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }

  .gap-xlx-s50 {
    gap: 3.125rem;
  }

  .pm-xlx-s50, .gx-xlx-s50 > .gx-child {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .gx-xlx-s50 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.125rem;
  }

  .gx-xlx-s50 > .gx-child:last-of-type {
    padding-left: 3.125rem;
    padding-right: 0;
  }

  .m-xlx-s60 {
    margin: 3.75rem;
  }

  .-m-xlx-s60 {
    margin: -3.75rem;
  }

  .mt-xlx-s60 {
    margin-top: 3.75rem;
  }

  .-mt-xlx-s60 {
    margin-top: -3.75rem;
  }

  .mb-xlx-s60 {
    margin-bottom: 3.75rem;
  }

  .-mb-xlx-s60 {
    margin-bottom: -3.75rem;
  }

  .mr-xlx-s60 {
    margin-right: 3.75rem;
  }

  .-mr-xlx-s60 {
    margin-right: -3.75rem;
  }

  .ml-xlx-s60 {
    margin-left: 3.75rem;
  }

  .-ml-xlx-s60 {
    margin-left: -3.75rem;
  }

  .p-xlx-s60 {
    padding: 3.75rem;
  }

  .-p-xlx-s60 {
    padding: -3.75rem;
  }

  .pt-xlx-s60 {
    padding-top: 3.75rem;
  }

  .-pt-xlx-s60 {
    padding-top: -3.75rem;
  }

  .pb-xlx-s60 {
    padding-bottom: 3.75rem;
  }

  .-pb-xlx-s60 {
    padding-bottom: -3.75rem;
  }

  .pr-xlx-s60 {
    padding-right: 3.75rem;
  }

  .-pr-xlx-s60 {
    padding-right: -3.75rem;
  }

  .pl-xlx-s60 {
    padding-left: 3.75rem;
  }

  .-pl-xlx-s60 {
    padding-left: -3.75rem;
  }

  .mx-xlx-s60 {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
  }

  .my-xlx-s60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .px-xlx-s60 {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .py-xlx-s60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  .gap-xlx-s60 {
    gap: 3.75rem;
  }

  .pm-xlx-s60, .gx-xlx-s60 > .gx-child {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .gx-xlx-s60 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.75rem;
  }

  .gx-xlx-s60 > .gx-child:last-of-type {
    padding-left: 3.75rem;
    padding-right: 0;
  }

  .m-xlx-s70 {
    margin: 4.375rem;
  }

  .-m-xlx-s70 {
    margin: -4.375rem;
  }

  .mt-xlx-s70 {
    margin-top: 4.375rem;
  }

  .-mt-xlx-s70 {
    margin-top: -4.375rem;
  }

  .mb-xlx-s70 {
    margin-bottom: 4.375rem;
  }

  .-mb-xlx-s70 {
    margin-bottom: -4.375rem;
  }

  .mr-xlx-s70 {
    margin-right: 4.375rem;
  }

  .-mr-xlx-s70 {
    margin-right: -4.375rem;
  }

  .ml-xlx-s70 {
    margin-left: 4.375rem;
  }

  .-ml-xlx-s70 {
    margin-left: -4.375rem;
  }

  .p-xlx-s70 {
    padding: 4.375rem;
  }

  .-p-xlx-s70 {
    padding: -4.375rem;
  }

  .pt-xlx-s70 {
    padding-top: 4.375rem;
  }

  .-pt-xlx-s70 {
    padding-top: -4.375rem;
  }

  .pb-xlx-s70 {
    padding-bottom: 4.375rem;
  }

  .-pb-xlx-s70 {
    padding-bottom: -4.375rem;
  }

  .pr-xlx-s70 {
    padding-right: 4.375rem;
  }

  .-pr-xlx-s70 {
    padding-right: -4.375rem;
  }

  .pl-xlx-s70 {
    padding-left: 4.375rem;
  }

  .-pl-xlx-s70 {
    padding-left: -4.375rem;
  }

  .mx-xlx-s70 {
    margin-left: 4.375rem;
    margin-right: 4.375rem;
  }

  .my-xlx-s70 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }

  .px-xlx-s70 {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }

  .py-xlx-s70 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }

  .gap-xlx-s70 {
    gap: 4.375rem;
  }

  .pm-xlx-s70, .gx-xlx-s70 > .gx-child {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .gx-xlx-s70 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 4.375rem;
  }

  .gx-xlx-s70 > .gx-child:last-of-type {
    padding-left: 4.375rem;
    padding-right: 0;
  }

  .m-xlx-s80 {
    margin: 5rem;
  }

  .-m-xlx-s80 {
    margin: -5rem;
  }

  .mt-xlx-s80 {
    margin-top: 5rem;
  }

  .-mt-xlx-s80 {
    margin-top: -5rem;
  }

  .mb-xlx-s80 {
    margin-bottom: 5rem;
  }

  .-mb-xlx-s80 {
    margin-bottom: -5rem;
  }

  .mr-xlx-s80 {
    margin-right: 5rem;
  }

  .-mr-xlx-s80 {
    margin-right: -5rem;
  }

  .ml-xlx-s80 {
    margin-left: 5rem;
  }

  .-ml-xlx-s80 {
    margin-left: -5rem;
  }

  .p-xlx-s80 {
    padding: 5rem;
  }

  .-p-xlx-s80 {
    padding: -5rem;
  }

  .pt-xlx-s80 {
    padding-top: 5rem;
  }

  .-pt-xlx-s80 {
    padding-top: -5rem;
  }

  .pb-xlx-s80 {
    padding-bottom: 5rem;
  }

  .-pb-xlx-s80 {
    padding-bottom: -5rem;
  }

  .pr-xlx-s80 {
    padding-right: 5rem;
  }

  .-pr-xlx-s80 {
    padding-right: -5rem;
  }

  .pl-xlx-s80 {
    padding-left: 5rem;
  }

  .-pl-xlx-s80 {
    padding-left: -5rem;
  }

  .mx-xlx-s80 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .my-xlx-s80 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .px-xlx-s80 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .py-xlx-s80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .gap-xlx-s80 {
    gap: 5rem;
  }

  .pm-xlx-s80, .gx-xlx-s80 > .gx-child {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .gx-xlx-s80 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 5rem;
  }

  .gx-xlx-s80 > .gx-child:last-of-type {
    padding-left: 5rem;
    padding-right: 0;
  }

  .m-xlx-s100 {
    margin: 6.25rem;
  }

  .-m-xlx-s100 {
    margin: -6.25rem;
  }

  .mt-xlx-s100 {
    margin-top: 6.25rem;
  }

  .-mt-xlx-s100 {
    margin-top: -6.25rem;
  }

  .mb-xlx-s100 {
    margin-bottom: 6.25rem;
  }

  .-mb-xlx-s100 {
    margin-bottom: -6.25rem;
  }

  .mr-xlx-s100 {
    margin-right: 6.25rem;
  }

  .-mr-xlx-s100 {
    margin-right: -6.25rem;
  }

  .ml-xlx-s100 {
    margin-left: 6.25rem;
  }

  .-ml-xlx-s100 {
    margin-left: -6.25rem;
  }

  .p-xlx-s100 {
    padding: 6.25rem;
  }

  .-p-xlx-s100 {
    padding: -6.25rem;
  }

  .pt-xlx-s100 {
    padding-top: 6.25rem;
  }

  .-pt-xlx-s100 {
    padding-top: -6.25rem;
  }

  .pb-xlx-s100 {
    padding-bottom: 6.25rem;
  }

  .-pb-xlx-s100 {
    padding-bottom: -6.25rem;
  }

  .pr-xlx-s100 {
    padding-right: 6.25rem;
  }

  .-pr-xlx-s100 {
    padding-right: -6.25rem;
  }

  .pl-xlx-s100 {
    padding-left: 6.25rem;
  }

  .-pl-xlx-s100 {
    padding-left: -6.25rem;
  }

  .mx-xlx-s100 {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
  }

  .my-xlx-s100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }

  .px-xlx-s100 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }

  .py-xlx-s100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  .gap-xlx-s100 {
    gap: 6.25rem;
  }

  .pm-xlx-s100, .gx-xlx-s100 > .gx-child {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .gx-xlx-s100 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 6.25rem;
  }

  .gx-xlx-s100 > .gx-child:last-of-type {
    padding-left: 6.25rem;
    padding-right: 0;
  }

  .m-xlx-s120 {
    margin: 7.5rem;
  }

  .-m-xlx-s120 {
    margin: -7.5rem;
  }

  .mt-xlx-s120 {
    margin-top: 7.5rem;
  }

  .-mt-xlx-s120 {
    margin-top: -7.5rem;
  }

  .mb-xlx-s120 {
    margin-bottom: 7.5rem;
  }

  .-mb-xlx-s120 {
    margin-bottom: -7.5rem;
  }

  .mr-xlx-s120 {
    margin-right: 7.5rem;
  }

  .-mr-xlx-s120 {
    margin-right: -7.5rem;
  }

  .ml-xlx-s120 {
    margin-left: 7.5rem;
  }

  .-ml-xlx-s120 {
    margin-left: -7.5rem;
  }

  .p-xlx-s120 {
    padding: 7.5rem;
  }

  .-p-xlx-s120 {
    padding: -7.5rem;
  }

  .pt-xlx-s120 {
    padding-top: 7.5rem;
  }

  .-pt-xlx-s120 {
    padding-top: -7.5rem;
  }

  .pb-xlx-s120 {
    padding-bottom: 7.5rem;
  }

  .-pb-xlx-s120 {
    padding-bottom: -7.5rem;
  }

  .pr-xlx-s120 {
    padding-right: 7.5rem;
  }

  .-pr-xlx-s120 {
    padding-right: -7.5rem;
  }

  .pl-xlx-s120 {
    padding-left: 7.5rem;
  }

  .-pl-xlx-s120 {
    padding-left: -7.5rem;
  }

  .mx-xlx-s120 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }

  .my-xlx-s120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }

  .px-xlx-s120 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }

  .py-xlx-s120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }

  .gap-xlx-s120 {
    gap: 7.5rem;
  }

  .pm-xlx-s120, .gx-xlx-s120 > .gx-child {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .gx-xlx-s120 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 7.5rem;
  }

  .gx-xlx-s120 > .gx-child:last-of-type {
    padding-left: 7.5rem;
    padding-right: 0;
  }

  .m-xlx-s150 {
    margin: 9.375rem;
  }

  .-m-xlx-s150 {
    margin: -9.375rem;
  }

  .mt-xlx-s150 {
    margin-top: 9.375rem;
  }

  .-mt-xlx-s150 {
    margin-top: -9.375rem;
  }

  .mb-xlx-s150 {
    margin-bottom: 9.375rem;
  }

  .-mb-xlx-s150 {
    margin-bottom: -9.375rem;
  }

  .mr-xlx-s150 {
    margin-right: 9.375rem;
  }

  .-mr-xlx-s150 {
    margin-right: -9.375rem;
  }

  .ml-xlx-s150 {
    margin-left: 9.375rem;
  }

  .-ml-xlx-s150 {
    margin-left: -9.375rem;
  }

  .p-xlx-s150 {
    padding: 9.375rem;
  }

  .-p-xlx-s150 {
    padding: -9.375rem;
  }

  .pt-xlx-s150 {
    padding-top: 9.375rem;
  }

  .-pt-xlx-s150 {
    padding-top: -9.375rem;
  }

  .pb-xlx-s150 {
    padding-bottom: 9.375rem;
  }

  .-pb-xlx-s150 {
    padding-bottom: -9.375rem;
  }

  .pr-xlx-s150 {
    padding-right: 9.375rem;
  }

  .-pr-xlx-s150 {
    padding-right: -9.375rem;
  }

  .pl-xlx-s150 {
    padding-left: 9.375rem;
  }

  .-pl-xlx-s150 {
    padding-left: -9.375rem;
  }

  .mx-xlx-s150 {
    margin-left: 9.375rem;
    margin-right: 9.375rem;
  }

  .my-xlx-s150 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }

  .px-xlx-s150 {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
  }

  .py-xlx-s150 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }

  .gap-xlx-s150 {
    gap: 9.375rem;
  }

  .pm-xlx-s150, .gx-xlx-s150 > .gx-child {
    padding-left: 4.6875rem;
    padding-right: 4.6875rem;
  }

  .gx-xlx-s150 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 9.375rem;
  }

  .gx-xlx-s150 > .gx-child:last-of-type {
    padding-left: 9.375rem;
    padding-right: 0;
  }
}

@media screen and (min-width: 1920px) {
  .m-xxl-s0, .-m-xxl-s0 {
    margin: 0;
  }

  .mt-xxl-s0, .-mt-xxl-s0 {
    margin-top: 0;
  }

  .mb-xxl-s0, .-mb-xxl-s0 {
    margin-bottom: 0;
  }

  .mr-xxl-s0, .-mr-xxl-s0 {
    margin-right: 0;
  }

  .ml-xxl-s0, .-ml-xxl-s0 {
    margin-left: 0;
  }

  .p-xxl-s0, .-p-xxl-s0 {
    padding: 0;
  }

  .pt-xxl-s0, .-pt-xxl-s0 {
    padding-top: 0;
  }

  .pb-xxl-s0, .-pb-xxl-s0 {
    padding-bottom: 0;
  }

  .pr-xxl-s0, .-pr-xxl-s0 {
    padding-right: 0;
  }

  .pl-xxl-s0, .-pl-xxl-s0 {
    padding-left: 0;
  }

  .mx-xxl-s0 {
    margin-left: 0;
    margin-right: 0;
  }

  .my-xxl-s0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .px-xxl-s0 {
    padding-left: 0;
    padding-right: 0;
  }

  .py-xxl-s0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .gap-xxl-s0 {
    gap: 0;
  }

  .pm-xxl-s0, .gx-xxl-s0 > .gx-child, .gx-xxl-s0 > .gx-child:first-of-type, .gx-xxl-s0 > .gx-child:last-of-type {
    padding-left: 0;
    padding-right: 0;
  }

  .m-xxl-s2 {
    margin: .125rem;
  }

  .-m-xxl-s2 {
    margin: -.125rem;
  }

  .mt-xxl-s2 {
    margin-top: .125rem;
  }

  .-mt-xxl-s2 {
    margin-top: -.125rem;
  }

  .mb-xxl-s2 {
    margin-bottom: .125rem;
  }

  .-mb-xxl-s2 {
    margin-bottom: -.125rem;
  }

  .mr-xxl-s2 {
    margin-right: .125rem;
  }

  .-mr-xxl-s2 {
    margin-right: -.125rem;
  }

  .ml-xxl-s2 {
    margin-left: .125rem;
  }

  .-ml-xxl-s2 {
    margin-left: -.125rem;
  }

  .p-xxl-s2 {
    padding: .125rem;
  }

  .-p-xxl-s2 {
    padding: -.125rem;
  }

  .pt-xxl-s2 {
    padding-top: .125rem;
  }

  .-pt-xxl-s2 {
    padding-top: -.125rem;
  }

  .pb-xxl-s2 {
    padding-bottom: .125rem;
  }

  .-pb-xxl-s2 {
    padding-bottom: -.125rem;
  }

  .pr-xxl-s2 {
    padding-right: .125rem;
  }

  .-pr-xxl-s2 {
    padding-right: -.125rem;
  }

  .pl-xxl-s2 {
    padding-left: .125rem;
  }

  .-pl-xxl-s2 {
    padding-left: -.125rem;
  }

  .mx-xxl-s2 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .my-xxl-s2 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .px-xxl-s2 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .py-xxl-s2 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .gap-xxl-s2 {
    gap: .125rem;
  }

  .pm-xxl-s2, .gx-xxl-s2 > .gx-child {
    padding-left: .0625rem;
    padding-right: .0625rem;
  }

  .gx-xxl-s2 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .125rem;
  }

  .gx-xxl-s2 > .gx-child:last-of-type {
    padding-left: .125rem;
    padding-right: 0;
  }

  .m-xxl-s3 {
    margin: .1875rem;
  }

  .-m-xxl-s3 {
    margin: -.1875rem;
  }

  .mt-xxl-s3 {
    margin-top: .1875rem;
  }

  .-mt-xxl-s3 {
    margin-top: -.1875rem;
  }

  .mb-xxl-s3 {
    margin-bottom: .1875rem;
  }

  .-mb-xxl-s3 {
    margin-bottom: -.1875rem;
  }

  .mr-xxl-s3 {
    margin-right: .1875rem;
  }

  .-mr-xxl-s3 {
    margin-right: -.1875rem;
  }

  .ml-xxl-s3 {
    margin-left: .1875rem;
  }

  .-ml-xxl-s3 {
    margin-left: -.1875rem;
  }

  .p-xxl-s3 {
    padding: .1875rem;
  }

  .-p-xxl-s3 {
    padding: -.1875rem;
  }

  .pt-xxl-s3 {
    padding-top: .1875rem;
  }

  .-pt-xxl-s3 {
    padding-top: -.1875rem;
  }

  .pb-xxl-s3 {
    padding-bottom: .1875rem;
  }

  .-pb-xxl-s3 {
    padding-bottom: -.1875rem;
  }

  .pr-xxl-s3 {
    padding-right: .1875rem;
  }

  .-pr-xxl-s3 {
    padding-right: -.1875rem;
  }

  .pl-xxl-s3 {
    padding-left: .1875rem;
  }

  .-pl-xxl-s3 {
    padding-left: -.1875rem;
  }

  .mx-xxl-s3 {
    margin-left: .1875rem;
    margin-right: .1875rem;
  }

  .my-xxl-s3 {
    margin-top: .1875rem;
    margin-bottom: .1875rem;
  }

  .px-xxl-s3 {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .py-xxl-s3 {
    padding-top: .1875rem;
    padding-bottom: .1875rem;
  }

  .gap-xxl-s3 {
    gap: .1875rem;
  }

  .pm-xxl-s3, .gx-xxl-s3 > .gx-child {
    padding-left: .09375rem;
    padding-right: .09375rem;
  }

  .gx-xxl-s3 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .1875rem;
  }

  .gx-xxl-s3 > .gx-child:last-of-type {
    padding-left: .1875rem;
    padding-right: 0;
  }

  .m-xxl-s4 {
    margin: .25rem;
  }

  .-m-xxl-s4 {
    margin: -.25rem;
  }

  .mt-xxl-s4 {
    margin-top: .25rem;
  }

  .-mt-xxl-s4 {
    margin-top: -.25rem;
  }

  .mb-xxl-s4 {
    margin-bottom: .25rem;
  }

  .-mb-xxl-s4 {
    margin-bottom: -.25rem;
  }

  .mr-xxl-s4 {
    margin-right: .25rem;
  }

  .-mr-xxl-s4 {
    margin-right: -.25rem;
  }

  .ml-xxl-s4 {
    margin-left: .25rem;
  }

  .-ml-xxl-s4 {
    margin-left: -.25rem;
  }

  .p-xxl-s4 {
    padding: .25rem;
  }

  .-p-xxl-s4 {
    padding: -.25rem;
  }

  .pt-xxl-s4 {
    padding-top: .25rem;
  }

  .-pt-xxl-s4 {
    padding-top: -.25rem;
  }

  .pb-xxl-s4 {
    padding-bottom: .25rem;
  }

  .-pb-xxl-s4 {
    padding-bottom: -.25rem;
  }

  .pr-xxl-s4 {
    padding-right: .25rem;
  }

  .-pr-xxl-s4 {
    padding-right: -.25rem;
  }

  .pl-xxl-s4 {
    padding-left: .25rem;
  }

  .-pl-xxl-s4 {
    padding-left: -.25rem;
  }

  .mx-xxl-s4 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .my-xxl-s4 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .px-xxl-s4 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .py-xxl-s4 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .gap-xxl-s4 {
    gap: .25rem;
  }

  .pm-xxl-s4, .gx-xxl-s4 > .gx-child {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .gx-xxl-s4 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .25rem;
  }

  .gx-xxl-s4 > .gx-child:last-of-type {
    padding-left: .25rem;
    padding-right: 0;
  }

  .m-xxl-s5 {
    margin: .3125rem;
  }

  .-m-xxl-s5 {
    margin: -.3125rem;
  }

  .mt-xxl-s5 {
    margin-top: .3125rem;
  }

  .-mt-xxl-s5 {
    margin-top: -.3125rem;
  }

  .mb-xxl-s5 {
    margin-bottom: .3125rem;
  }

  .-mb-xxl-s5 {
    margin-bottom: -.3125rem;
  }

  .mr-xxl-s5 {
    margin-right: .3125rem;
  }

  .-mr-xxl-s5 {
    margin-right: -.3125rem;
  }

  .ml-xxl-s5 {
    margin-left: .3125rem;
  }

  .-ml-xxl-s5 {
    margin-left: -.3125rem;
  }

  .p-xxl-s5 {
    padding: .3125rem;
  }

  .-p-xxl-s5 {
    padding: -.3125rem;
  }

  .pt-xxl-s5 {
    padding-top: .3125rem;
  }

  .-pt-xxl-s5 {
    padding-top: -.3125rem;
  }

  .pb-xxl-s5 {
    padding-bottom: .3125rem;
  }

  .-pb-xxl-s5 {
    padding-bottom: -.3125rem;
  }

  .pr-xxl-s5 {
    padding-right: .3125rem;
  }

  .-pr-xxl-s5 {
    padding-right: -.3125rem;
  }

  .pl-xxl-s5 {
    padding-left: .3125rem;
  }

  .-pl-xxl-s5 {
    padding-left: -.3125rem;
  }

  .mx-xxl-s5 {
    margin-left: .3125rem;
    margin-right: .3125rem;
  }

  .my-xxl-s5 {
    margin-top: .3125rem;
    margin-bottom: .3125rem;
  }

  .px-xxl-s5 {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .py-xxl-s5 {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
  }

  .gap-xxl-s5 {
    gap: .3125rem;
  }

  .pm-xxl-s5, .gx-xxl-s5 > .gx-child {
    padding-left: .15625rem;
    padding-right: .15625rem;
  }

  .gx-xxl-s5 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .3125rem;
  }

  .gx-xxl-s5 > .gx-child:last-of-type {
    padding-left: .3125rem;
    padding-right: 0;
  }

  .m-xxl-s6 {
    margin: .375rem;
  }

  .-m-xxl-s6 {
    margin: -.375rem;
  }

  .mt-xxl-s6 {
    margin-top: .375rem;
  }

  .-mt-xxl-s6 {
    margin-top: -.375rem;
  }

  .mb-xxl-s6 {
    margin-bottom: .375rem;
  }

  .-mb-xxl-s6 {
    margin-bottom: -.375rem;
  }

  .mr-xxl-s6 {
    margin-right: .375rem;
  }

  .-mr-xxl-s6 {
    margin-right: -.375rem;
  }

  .ml-xxl-s6 {
    margin-left: .375rem;
  }

  .-ml-xxl-s6 {
    margin-left: -.375rem;
  }

  .p-xxl-s6 {
    padding: .375rem;
  }

  .-p-xxl-s6 {
    padding: -.375rem;
  }

  .pt-xxl-s6 {
    padding-top: .375rem;
  }

  .-pt-xxl-s6 {
    padding-top: -.375rem;
  }

  .pb-xxl-s6 {
    padding-bottom: .375rem;
  }

  .-pb-xxl-s6 {
    padding-bottom: -.375rem;
  }

  .pr-xxl-s6 {
    padding-right: .375rem;
  }

  .-pr-xxl-s6 {
    padding-right: -.375rem;
  }

  .pl-xxl-s6 {
    padding-left: .375rem;
  }

  .-pl-xxl-s6 {
    padding-left: -.375rem;
  }

  .mx-xxl-s6 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .my-xxl-s6 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .px-xxl-s6 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .py-xxl-s6 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .gap-xxl-s6 {
    gap: .375rem;
  }

  .pm-xxl-s6, .gx-xxl-s6 > .gx-child {
    padding-left: .1875rem;
    padding-right: .1875rem;
  }

  .gx-xxl-s6 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .375rem;
  }

  .gx-xxl-s6 > .gx-child:last-of-type {
    padding-left: .375rem;
    padding-right: 0;
  }

  .m-xxl-s7 {
    margin: .4375rem;
  }

  .-m-xxl-s7 {
    margin: -.4375rem;
  }

  .mt-xxl-s7 {
    margin-top: .4375rem;
  }

  .-mt-xxl-s7 {
    margin-top: -.4375rem;
  }

  .mb-xxl-s7 {
    margin-bottom: .4375rem;
  }

  .-mb-xxl-s7 {
    margin-bottom: -.4375rem;
  }

  .mr-xxl-s7 {
    margin-right: .4375rem;
  }

  .-mr-xxl-s7 {
    margin-right: -.4375rem;
  }

  .ml-xxl-s7 {
    margin-left: .4375rem;
  }

  .-ml-xxl-s7 {
    margin-left: -.4375rem;
  }

  .p-xxl-s7 {
    padding: .4375rem;
  }

  .-p-xxl-s7 {
    padding: -.4375rem;
  }

  .pt-xxl-s7 {
    padding-top: .4375rem;
  }

  .-pt-xxl-s7 {
    padding-top: -.4375rem;
  }

  .pb-xxl-s7 {
    padding-bottom: .4375rem;
  }

  .-pb-xxl-s7 {
    padding-bottom: -.4375rem;
  }

  .pr-xxl-s7 {
    padding-right: .4375rem;
  }

  .-pr-xxl-s7 {
    padding-right: -.4375rem;
  }

  .pl-xxl-s7 {
    padding-left: .4375rem;
  }

  .-pl-xxl-s7 {
    padding-left: -.4375rem;
  }

  .mx-xxl-s7 {
    margin-left: .4375rem;
    margin-right: .4375rem;
  }

  .my-xxl-s7 {
    margin-top: .4375rem;
    margin-bottom: .4375rem;
  }

  .px-xxl-s7 {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .py-xxl-s7 {
    padding-top: .4375rem;
    padding-bottom: .4375rem;
  }

  .gap-xxl-s7 {
    gap: .4375rem;
  }

  .pm-xxl-s7, .gx-xxl-s7 > .gx-child {
    padding-left: .21875rem;
    padding-right: .21875rem;
  }

  .gx-xxl-s7 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .4375rem;
  }

  .gx-xxl-s7 > .gx-child:last-of-type {
    padding-left: .4375rem;
    padding-right: 0;
  }

  .m-xxl-s8 {
    margin: .5rem;
  }

  .-m-xxl-s8 {
    margin: -.5rem;
  }

  .mt-xxl-s8 {
    margin-top: .5rem;
  }

  .-mt-xxl-s8 {
    margin-top: -.5rem;
  }

  .mb-xxl-s8 {
    margin-bottom: .5rem;
  }

  .-mb-xxl-s8 {
    margin-bottom: -.5rem;
  }

  .mr-xxl-s8 {
    margin-right: .5rem;
  }

  .-mr-xxl-s8 {
    margin-right: -.5rem;
  }

  .ml-xxl-s8 {
    margin-left: .5rem;
  }

  .-ml-xxl-s8 {
    margin-left: -.5rem;
  }

  .p-xxl-s8 {
    padding: .5rem;
  }

  .-p-xxl-s8 {
    padding: -.5rem;
  }

  .pt-xxl-s8 {
    padding-top: .5rem;
  }

  .-pt-xxl-s8 {
    padding-top: -.5rem;
  }

  .pb-xxl-s8 {
    padding-bottom: .5rem;
  }

  .-pb-xxl-s8 {
    padding-bottom: -.5rem;
  }

  .pr-xxl-s8 {
    padding-right: .5rem;
  }

  .-pr-xxl-s8 {
    padding-right: -.5rem;
  }

  .pl-xxl-s8 {
    padding-left: .5rem;
  }

  .-pl-xxl-s8 {
    padding-left: -.5rem;
  }

  .mx-xxl-s8 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .my-xxl-s8 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .px-xxl-s8 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .py-xxl-s8 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .gap-xxl-s8 {
    gap: .5rem;
  }

  .pm-xxl-s8, .gx-xxl-s8 > .gx-child {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .gx-xxl-s8 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5rem;
  }

  .gx-xxl-s8 > .gx-child:last-of-type {
    padding-left: .5rem;
    padding-right: 0;
  }

  .m-xxl-s9 {
    margin: .5625rem;
  }

  .-m-xxl-s9 {
    margin: -.5625rem;
  }

  .mt-xxl-s9 {
    margin-top: .5625rem;
  }

  .-mt-xxl-s9 {
    margin-top: -.5625rem;
  }

  .mb-xxl-s9 {
    margin-bottom: .5625rem;
  }

  .-mb-xxl-s9 {
    margin-bottom: -.5625rem;
  }

  .mr-xxl-s9 {
    margin-right: .5625rem;
  }

  .-mr-xxl-s9 {
    margin-right: -.5625rem;
  }

  .ml-xxl-s9 {
    margin-left: .5625rem;
  }

  .-ml-xxl-s9 {
    margin-left: -.5625rem;
  }

  .p-xxl-s9 {
    padding: .5625rem;
  }

  .-p-xxl-s9 {
    padding: -.5625rem;
  }

  .pt-xxl-s9 {
    padding-top: .5625rem;
  }

  .-pt-xxl-s9 {
    padding-top: -.5625rem;
  }

  .pb-xxl-s9 {
    padding-bottom: .5625rem;
  }

  .-pb-xxl-s9 {
    padding-bottom: -.5625rem;
  }

  .pr-xxl-s9 {
    padding-right: .5625rem;
  }

  .-pr-xxl-s9 {
    padding-right: -.5625rem;
  }

  .pl-xxl-s9 {
    padding-left: .5625rem;
  }

  .-pl-xxl-s9 {
    padding-left: -.5625rem;
  }

  .mx-xxl-s9 {
    margin-left: .5625rem;
    margin-right: .5625rem;
  }

  .my-xxl-s9 {
    margin-top: .5625rem;
    margin-bottom: .5625rem;
  }

  .px-xxl-s9 {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .py-xxl-s9 {
    padding-top: .5625rem;
    padding-bottom: .5625rem;
  }

  .gap-xxl-s9 {
    gap: .5625rem;
  }

  .pm-xxl-s9, .gx-xxl-s9 > .gx-child {
    padding-left: .28125rem;
    padding-right: .28125rem;
  }

  .gx-xxl-s9 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .5625rem;
  }

  .gx-xxl-s9 > .gx-child:last-of-type {
    padding-left: .5625rem;
    padding-right: 0;
  }

  .m-xxl-s10 {
    margin: .625rem;
  }

  .-m-xxl-s10 {
    margin: -.625rem;
  }

  .mt-xxl-s10 {
    margin-top: .625rem;
  }

  .-mt-xxl-s10 {
    margin-top: -.625rem;
  }

  .mb-xxl-s10 {
    margin-bottom: .625rem;
  }

  .-mb-xxl-s10 {
    margin-bottom: -.625rem;
  }

  .mr-xxl-s10 {
    margin-right: .625rem;
  }

  .-mr-xxl-s10 {
    margin-right: -.625rem;
  }

  .ml-xxl-s10 {
    margin-left: .625rem;
  }

  .-ml-xxl-s10 {
    margin-left: -.625rem;
  }

  .p-xxl-s10 {
    padding: .625rem;
  }

  .-p-xxl-s10 {
    padding: -.625rem;
  }

  .pt-xxl-s10 {
    padding-top: .625rem;
  }

  .-pt-xxl-s10 {
    padding-top: -.625rem;
  }

  .pb-xxl-s10 {
    padding-bottom: .625rem;
  }

  .-pb-xxl-s10 {
    padding-bottom: -.625rem;
  }

  .pr-xxl-s10 {
    padding-right: .625rem;
  }

  .-pr-xxl-s10 {
    padding-right: -.625rem;
  }

  .pl-xxl-s10 {
    padding-left: .625rem;
  }

  .-pl-xxl-s10 {
    padding-left: -.625rem;
  }

  .mx-xxl-s10 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .my-xxl-s10 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .px-xxl-s10 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .py-xxl-s10 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .gap-xxl-s10 {
    gap: .625rem;
  }

  .pm-xxl-s10, .gx-xxl-s10 > .gx-child {
    padding-left: .3125rem;
    padding-right: .3125rem;
  }

  .gx-xxl-s10 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .625rem;
  }

  .gx-xxl-s10 > .gx-child:last-of-type {
    padding-left: .625rem;
    padding-right: 0;
  }

  .m-xxl-s12 {
    margin: .75rem;
  }

  .-m-xxl-s12 {
    margin: -.75rem;
  }

  .mt-xxl-s12 {
    margin-top: .75rem;
  }

  .-mt-xxl-s12 {
    margin-top: -.75rem;
  }

  .mb-xxl-s12 {
    margin-bottom: .75rem;
  }

  .-mb-xxl-s12 {
    margin-bottom: -.75rem;
  }

  .mr-xxl-s12 {
    margin-right: .75rem;
  }

  .-mr-xxl-s12 {
    margin-right: -.75rem;
  }

  .ml-xxl-s12 {
    margin-left: .75rem;
  }

  .-ml-xxl-s12 {
    margin-left: -.75rem;
  }

  .p-xxl-s12 {
    padding: .75rem;
  }

  .-p-xxl-s12 {
    padding: -.75rem;
  }

  .pt-xxl-s12 {
    padding-top: .75rem;
  }

  .-pt-xxl-s12 {
    padding-top: -.75rem;
  }

  .pb-xxl-s12 {
    padding-bottom: .75rem;
  }

  .-pb-xxl-s12 {
    padding-bottom: -.75rem;
  }

  .pr-xxl-s12 {
    padding-right: .75rem;
  }

  .-pr-xxl-s12 {
    padding-right: -.75rem;
  }

  .pl-xxl-s12 {
    padding-left: .75rem;
  }

  .-pl-xxl-s12 {
    padding-left: -.75rem;
  }

  .mx-xxl-s12 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .my-xxl-s12 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .px-xxl-s12 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .py-xxl-s12 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .gap-xxl-s12 {
    gap: .75rem;
  }

  .pm-xxl-s12, .gx-xxl-s12 > .gx-child {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .gx-xxl-s12 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .75rem;
  }

  .gx-xxl-s12 > .gx-child:last-of-type {
    padding-left: .75rem;
    padding-right: 0;
  }

  .m-xxl-s13 {
    margin: .8125rem;
  }

  .-m-xxl-s13 {
    margin: -.8125rem;
  }

  .mt-xxl-s13 {
    margin-top: .8125rem;
  }

  .-mt-xxl-s13 {
    margin-top: -.8125rem;
  }

  .mb-xxl-s13 {
    margin-bottom: .8125rem;
  }

  .-mb-xxl-s13 {
    margin-bottom: -.8125rem;
  }

  .mr-xxl-s13 {
    margin-right: .8125rem;
  }

  .-mr-xxl-s13 {
    margin-right: -.8125rem;
  }

  .ml-xxl-s13 {
    margin-left: .8125rem;
  }

  .-ml-xxl-s13 {
    margin-left: -.8125rem;
  }

  .p-xxl-s13 {
    padding: .8125rem;
  }

  .-p-xxl-s13 {
    padding: -.8125rem;
  }

  .pt-xxl-s13 {
    padding-top: .8125rem;
  }

  .-pt-xxl-s13 {
    padding-top: -.8125rem;
  }

  .pb-xxl-s13 {
    padding-bottom: .8125rem;
  }

  .-pb-xxl-s13 {
    padding-bottom: -.8125rem;
  }

  .pr-xxl-s13 {
    padding-right: .8125rem;
  }

  .-pr-xxl-s13 {
    padding-right: -.8125rem;
  }

  .pl-xxl-s13 {
    padding-left: .8125rem;
  }

  .-pl-xxl-s13 {
    padding-left: -.8125rem;
  }

  .mx-xxl-s13 {
    margin-left: .8125rem;
    margin-right: .8125rem;
  }

  .my-xxl-s13 {
    margin-top: .8125rem;
    margin-bottom: .8125rem;
  }

  .px-xxl-s13 {
    padding-left: .8125rem;
    padding-right: .8125rem;
  }

  .py-xxl-s13 {
    padding-top: .8125rem;
    padding-bottom: .8125rem;
  }

  .gap-xxl-s13 {
    gap: .8125rem;
  }

  .pm-xxl-s13, .gx-xxl-s13 > .gx-child {
    padding-left: .40625rem;
    padding-right: .40625rem;
  }

  .gx-xxl-s13 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .8125rem;
  }

  .gx-xxl-s13 > .gx-child:last-of-type {
    padding-left: .8125rem;
    padding-right: 0;
  }

  .m-xxl-s14 {
    margin: .875rem;
  }

  .-m-xxl-s14 {
    margin: -.875rem;
  }

  .mt-xxl-s14 {
    margin-top: .875rem;
  }

  .-mt-xxl-s14 {
    margin-top: -.875rem;
  }

  .mb-xxl-s14 {
    margin-bottom: .875rem;
  }

  .-mb-xxl-s14 {
    margin-bottom: -.875rem;
  }

  .mr-xxl-s14 {
    margin-right: .875rem;
  }

  .-mr-xxl-s14 {
    margin-right: -.875rem;
  }

  .ml-xxl-s14 {
    margin-left: .875rem;
  }

  .-ml-xxl-s14 {
    margin-left: -.875rem;
  }

  .p-xxl-s14 {
    padding: .875rem;
  }

  .-p-xxl-s14 {
    padding: -.875rem;
  }

  .pt-xxl-s14 {
    padding-top: .875rem;
  }

  .-pt-xxl-s14 {
    padding-top: -.875rem;
  }

  .pb-xxl-s14 {
    padding-bottom: .875rem;
  }

  .-pb-xxl-s14 {
    padding-bottom: -.875rem;
  }

  .pr-xxl-s14 {
    padding-right: .875rem;
  }

  .-pr-xxl-s14 {
    padding-right: -.875rem;
  }

  .pl-xxl-s14 {
    padding-left: .875rem;
  }

  .-pl-xxl-s14 {
    padding-left: -.875rem;
  }

  .mx-xxl-s14 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .my-xxl-s14 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .px-xxl-s14 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .py-xxl-s14 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .gap-xxl-s14 {
    gap: .875rem;
  }

  .pm-xxl-s14, .gx-xxl-s14 > .gx-child {
    padding-left: .4375rem;
    padding-right: .4375rem;
  }

  .gx-xxl-s14 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .875rem;
  }

  .gx-xxl-s14 > .gx-child:last-of-type {
    padding-left: .875rem;
    padding-right: 0;
  }

  .m-xxl-s15 {
    margin: .9375rem;
  }

  .-m-xxl-s15 {
    margin: -.9375rem;
  }

  .mt-xxl-s15 {
    margin-top: .9375rem;
  }

  .-mt-xxl-s15 {
    margin-top: -.9375rem;
  }

  .mb-xxl-s15 {
    margin-bottom: .9375rem;
  }

  .-mb-xxl-s15 {
    margin-bottom: -.9375rem;
  }

  .mr-xxl-s15 {
    margin-right: .9375rem;
  }

  .-mr-xxl-s15 {
    margin-right: -.9375rem;
  }

  .ml-xxl-s15 {
    margin-left: .9375rem;
  }

  .-ml-xxl-s15 {
    margin-left: -.9375rem;
  }

  .p-xxl-s15 {
    padding: .9375rem;
  }

  .-p-xxl-s15 {
    padding: -.9375rem;
  }

  .pt-xxl-s15 {
    padding-top: .9375rem;
  }

  .-pt-xxl-s15 {
    padding-top: -.9375rem;
  }

  .pb-xxl-s15 {
    padding-bottom: .9375rem;
  }

  .-pb-xxl-s15 {
    padding-bottom: -.9375rem;
  }

  .pr-xxl-s15 {
    padding-right: .9375rem;
  }

  .-pr-xxl-s15 {
    padding-right: -.9375rem;
  }

  .pl-xxl-s15 {
    padding-left: .9375rem;
  }

  .-pl-xxl-s15 {
    padding-left: -.9375rem;
  }

  .mx-xxl-s15 {
    margin-left: .9375rem;
    margin-right: .9375rem;
  }

  .my-xxl-s15 {
    margin-top: .9375rem;
    margin-bottom: .9375rem;
  }

  .px-xxl-s15 {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .py-xxl-s15 {
    padding-top: .9375rem;
    padding-bottom: .9375rem;
  }

  .gap-xxl-s15 {
    gap: .9375rem;
  }

  .pm-xxl-s15, .gx-xxl-s15 > .gx-child {
    padding-left: .46875rem;
    padding-right: .46875rem;
  }

  .gx-xxl-s15 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: .9375rem;
  }

  .gx-xxl-s15 > .gx-child:last-of-type {
    padding-left: .9375rem;
    padding-right: 0;
  }

  .m-xxl-s16 {
    margin: 1rem;
  }

  .-m-xxl-s16 {
    margin: -1rem;
  }

  .mt-xxl-s16 {
    margin-top: 1rem;
  }

  .-mt-xxl-s16 {
    margin-top: -1rem;
  }

  .mb-xxl-s16 {
    margin-bottom: 1rem;
  }

  .-mb-xxl-s16 {
    margin-bottom: -1rem;
  }

  .mr-xxl-s16 {
    margin-right: 1rem;
  }

  .-mr-xxl-s16 {
    margin-right: -1rem;
  }

  .ml-xxl-s16 {
    margin-left: 1rem;
  }

  .-ml-xxl-s16 {
    margin-left: -1rem;
  }

  .p-xxl-s16 {
    padding: 1rem;
  }

  .-p-xxl-s16 {
    padding: -1rem;
  }

  .pt-xxl-s16 {
    padding-top: 1rem;
  }

  .-pt-xxl-s16 {
    padding-top: -1rem;
  }

  .pb-xxl-s16 {
    padding-bottom: 1rem;
  }

  .-pb-xxl-s16 {
    padding-bottom: -1rem;
  }

  .pr-xxl-s16 {
    padding-right: 1rem;
  }

  .-pr-xxl-s16 {
    padding-right: -1rem;
  }

  .pl-xxl-s16 {
    padding-left: 1rem;
  }

  .-pl-xxl-s16 {
    padding-left: -1rem;
  }

  .mx-xxl-s16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .my-xxl-s16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .px-xxl-s16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .py-xxl-s16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .gap-xxl-s16 {
    gap: 1rem;
  }

  .pm-xxl-s16, .gx-xxl-s16 > .gx-child {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .gx-xxl-s16 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1rem;
  }

  .gx-xxl-s16 > .gx-child:last-of-type {
    padding-left: 1rem;
    padding-right: 0;
  }

  .m-xxl-s18 {
    margin: 1.125rem;
  }

  .-m-xxl-s18 {
    margin: -1.125rem;
  }

  .mt-xxl-s18 {
    margin-top: 1.125rem;
  }

  .-mt-xxl-s18 {
    margin-top: -1.125rem;
  }

  .mb-xxl-s18 {
    margin-bottom: 1.125rem;
  }

  .-mb-xxl-s18 {
    margin-bottom: -1.125rem;
  }

  .mr-xxl-s18 {
    margin-right: 1.125rem;
  }

  .-mr-xxl-s18 {
    margin-right: -1.125rem;
  }

  .ml-xxl-s18 {
    margin-left: 1.125rem;
  }

  .-ml-xxl-s18 {
    margin-left: -1.125rem;
  }

  .p-xxl-s18 {
    padding: 1.125rem;
  }

  .-p-xxl-s18 {
    padding: -1.125rem;
  }

  .pt-xxl-s18 {
    padding-top: 1.125rem;
  }

  .-pt-xxl-s18 {
    padding-top: -1.125rem;
  }

  .pb-xxl-s18 {
    padding-bottom: 1.125rem;
  }

  .-pb-xxl-s18 {
    padding-bottom: -1.125rem;
  }

  .pr-xxl-s18 {
    padding-right: 1.125rem;
  }

  .-pr-xxl-s18 {
    padding-right: -1.125rem;
  }

  .pl-xxl-s18 {
    padding-left: 1.125rem;
  }

  .-pl-xxl-s18 {
    padding-left: -1.125rem;
  }

  .mx-xxl-s18 {
    margin-left: 1.125rem;
    margin-right: 1.125rem;
  }

  .my-xxl-s18 {
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
  }

  .px-xxl-s18 {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }

  .py-xxl-s18 {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }

  .gap-xxl-s18 {
    gap: 1.125rem;
  }

  .pm-xxl-s18, .gx-xxl-s18 > .gx-child {
    padding-left: .5625rem;
    padding-right: .5625rem;
  }

  .gx-xxl-s18 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.125rem;
  }

  .gx-xxl-s18 > .gx-child:last-of-type {
    padding-left: 1.125rem;
    padding-right: 0;
  }

  .m-xxl-s20 {
    margin: 1.25rem;
  }

  .-m-xxl-s20 {
    margin: -1.25rem;
  }

  .mt-xxl-s20 {
    margin-top: 1.25rem;
  }

  .-mt-xxl-s20 {
    margin-top: -1.25rem;
  }

  .mb-xxl-s20 {
    margin-bottom: 1.25rem;
  }

  .-mb-xxl-s20 {
    margin-bottom: -1.25rem;
  }

  .mr-xxl-s20 {
    margin-right: 1.25rem;
  }

  .-mr-xxl-s20 {
    margin-right: -1.25rem;
  }

  .ml-xxl-s20 {
    margin-left: 1.25rem;
  }

  .-ml-xxl-s20 {
    margin-left: -1.25rem;
  }

  .p-xxl-s20 {
    padding: 1.25rem;
  }

  .-p-xxl-s20 {
    padding: -1.25rem;
  }

  .pt-xxl-s20 {
    padding-top: 1.25rem;
  }

  .-pt-xxl-s20 {
    padding-top: -1.25rem;
  }

  .pb-xxl-s20 {
    padding-bottom: 1.25rem;
  }

  .-pb-xxl-s20 {
    padding-bottom: -1.25rem;
  }

  .pr-xxl-s20 {
    padding-right: 1.25rem;
  }

  .-pr-xxl-s20 {
    padding-right: -1.25rem;
  }

  .pl-xxl-s20 {
    padding-left: 1.25rem;
  }

  .-pl-xxl-s20 {
    padding-left: -1.25rem;
  }

  .mx-xxl-s20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .my-xxl-s20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .px-xxl-s20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .py-xxl-s20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .gap-xxl-s20 {
    gap: 1.25rem;
  }

  .pm-xxl-s20, .gx-xxl-s20 > .gx-child {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .gx-xxl-s20 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.25rem;
  }

  .gx-xxl-s20 > .gx-child:last-of-type {
    padding-left: 1.25rem;
    padding-right: 0;
  }

  .m-xxl-s22 {
    margin: 1.375rem;
  }

  .-m-xxl-s22 {
    margin: -1.375rem;
  }

  .mt-xxl-s22 {
    margin-top: 1.375rem;
  }

  .-mt-xxl-s22 {
    margin-top: -1.375rem;
  }

  .mb-xxl-s22 {
    margin-bottom: 1.375rem;
  }

  .-mb-xxl-s22 {
    margin-bottom: -1.375rem;
  }

  .mr-xxl-s22 {
    margin-right: 1.375rem;
  }

  .-mr-xxl-s22 {
    margin-right: -1.375rem;
  }

  .ml-xxl-s22 {
    margin-left: 1.375rem;
  }

  .-ml-xxl-s22 {
    margin-left: -1.375rem;
  }

  .p-xxl-s22 {
    padding: 1.375rem;
  }

  .-p-xxl-s22 {
    padding: -1.375rem;
  }

  .pt-xxl-s22 {
    padding-top: 1.375rem;
  }

  .-pt-xxl-s22 {
    padding-top: -1.375rem;
  }

  .pb-xxl-s22 {
    padding-bottom: 1.375rem;
  }

  .-pb-xxl-s22 {
    padding-bottom: -1.375rem;
  }

  .pr-xxl-s22 {
    padding-right: 1.375rem;
  }

  .-pr-xxl-s22 {
    padding-right: -1.375rem;
  }

  .pl-xxl-s22 {
    padding-left: 1.375rem;
  }

  .-pl-xxl-s22 {
    padding-left: -1.375rem;
  }

  .mx-xxl-s22 {
    margin-left: 1.375rem;
    margin-right: 1.375rem;
  }

  .my-xxl-s22 {
    margin-top: 1.375rem;
    margin-bottom: 1.375rem;
  }

  .px-xxl-s22 {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }

  .py-xxl-s22 {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
  }

  .gap-xxl-s22 {
    gap: 1.375rem;
  }

  .pm-xxl-s22, .gx-xxl-s22 > .gx-child {
    padding-left: .6875rem;
    padding-right: .6875rem;
  }

  .gx-xxl-s22 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.375rem;
  }

  .gx-xxl-s22 > .gx-child:last-of-type {
    padding-left: 1.375rem;
    padding-right: 0;
  }

  .m-xxl-s24 {
    margin: 1.5rem;
  }

  .-m-xxl-s24 {
    margin: -1.5rem;
  }

  .mt-xxl-s24 {
    margin-top: 1.5rem;
  }

  .-mt-xxl-s24 {
    margin-top: -1.5rem;
  }

  .mb-xxl-s24 {
    margin-bottom: 1.5rem;
  }

  .-mb-xxl-s24 {
    margin-bottom: -1.5rem;
  }

  .mr-xxl-s24 {
    margin-right: 1.5rem;
  }

  .-mr-xxl-s24 {
    margin-right: -1.5rem;
  }

  .ml-xxl-s24 {
    margin-left: 1.5rem;
  }

  .-ml-xxl-s24 {
    margin-left: -1.5rem;
  }

  .p-xxl-s24 {
    padding: 1.5rem;
  }

  .-p-xxl-s24 {
    padding: -1.5rem;
  }

  .pt-xxl-s24 {
    padding-top: 1.5rem;
  }

  .-pt-xxl-s24 {
    padding-top: -1.5rem;
  }

  .pb-xxl-s24 {
    padding-bottom: 1.5rem;
  }

  .-pb-xxl-s24 {
    padding-bottom: -1.5rem;
  }

  .pr-xxl-s24 {
    padding-right: 1.5rem;
  }

  .-pr-xxl-s24 {
    padding-right: -1.5rem;
  }

  .pl-xxl-s24 {
    padding-left: 1.5rem;
  }

  .-pl-xxl-s24 {
    padding-left: -1.5rem;
  }

  .mx-xxl-s24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .my-xxl-s24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .px-xxl-s24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .py-xxl-s24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .gap-xxl-s24 {
    gap: 1.5rem;
  }

  .pm-xxl-s24, .gx-xxl-s24 > .gx-child {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .gx-xxl-s24 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5rem;
  }

  .gx-xxl-s24 > .gx-child:last-of-type {
    padding-left: 1.5rem;
    padding-right: 0;
  }

  .m-xxl-s25 {
    margin: 1.5625rem;
  }

  .-m-xxl-s25 {
    margin: -1.5625rem;
  }

  .mt-xxl-s25 {
    margin-top: 1.5625rem;
  }

  .-mt-xxl-s25 {
    margin-top: -1.5625rem;
  }

  .mb-xxl-s25 {
    margin-bottom: 1.5625rem;
  }

  .-mb-xxl-s25 {
    margin-bottom: -1.5625rem;
  }

  .mr-xxl-s25 {
    margin-right: 1.5625rem;
  }

  .-mr-xxl-s25 {
    margin-right: -1.5625rem;
  }

  .ml-xxl-s25 {
    margin-left: 1.5625rem;
  }

  .-ml-xxl-s25 {
    margin-left: -1.5625rem;
  }

  .p-xxl-s25 {
    padding: 1.5625rem;
  }

  .-p-xxl-s25 {
    padding: -1.5625rem;
  }

  .pt-xxl-s25 {
    padding-top: 1.5625rem;
  }

  .-pt-xxl-s25 {
    padding-top: -1.5625rem;
  }

  .pb-xxl-s25 {
    padding-bottom: 1.5625rem;
  }

  .-pb-xxl-s25 {
    padding-bottom: -1.5625rem;
  }

  .pr-xxl-s25 {
    padding-right: 1.5625rem;
  }

  .-pr-xxl-s25 {
    padding-right: -1.5625rem;
  }

  .pl-xxl-s25 {
    padding-left: 1.5625rem;
  }

  .-pl-xxl-s25 {
    padding-left: -1.5625rem;
  }

  .mx-xxl-s25 {
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
  }

  .my-xxl-s25 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem;
  }

  .px-xxl-s25 {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .py-xxl-s25 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }

  .gap-xxl-s25 {
    gap: 1.5625rem;
  }

  .pm-xxl-s25, .gx-xxl-s25 > .gx-child {
    padding-left: .78125rem;
    padding-right: .78125rem;
  }

  .gx-xxl-s25 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.5625rem;
  }

  .gx-xxl-s25 > .gx-child:last-of-type {
    padding-left: 1.5625rem;
    padding-right: 0;
  }

  .m-xxl-s27 {
    margin: 1.6875rem;
  }

  .-m-xxl-s27 {
    margin: -1.6875rem;
  }

  .mt-xxl-s27 {
    margin-top: 1.6875rem;
  }

  .-mt-xxl-s27 {
    margin-top: -1.6875rem;
  }

  .mb-xxl-s27 {
    margin-bottom: 1.6875rem;
  }

  .-mb-xxl-s27 {
    margin-bottom: -1.6875rem;
  }

  .mr-xxl-s27 {
    margin-right: 1.6875rem;
  }

  .-mr-xxl-s27 {
    margin-right: -1.6875rem;
  }

  .ml-xxl-s27 {
    margin-left: 1.6875rem;
  }

  .-ml-xxl-s27 {
    margin-left: -1.6875rem;
  }

  .p-xxl-s27 {
    padding: 1.6875rem;
  }

  .-p-xxl-s27 {
    padding: -1.6875rem;
  }

  .pt-xxl-s27 {
    padding-top: 1.6875rem;
  }

  .-pt-xxl-s27 {
    padding-top: -1.6875rem;
  }

  .pb-xxl-s27 {
    padding-bottom: 1.6875rem;
  }

  .-pb-xxl-s27 {
    padding-bottom: -1.6875rem;
  }

  .pr-xxl-s27 {
    padding-right: 1.6875rem;
  }

  .-pr-xxl-s27 {
    padding-right: -1.6875rem;
  }

  .pl-xxl-s27 {
    padding-left: 1.6875rem;
  }

  .-pl-xxl-s27 {
    padding-left: -1.6875rem;
  }

  .mx-xxl-s27 {
    margin-left: 1.6875rem;
    margin-right: 1.6875rem;
  }

  .my-xxl-s27 {
    margin-top: 1.6875rem;
    margin-bottom: 1.6875rem;
  }

  .px-xxl-s27 {
    padding-left: 1.6875rem;
    padding-right: 1.6875rem;
  }

  .py-xxl-s27 {
    padding-top: 1.6875rem;
    padding-bottom: 1.6875rem;
  }

  .gap-xxl-s27 {
    gap: 1.6875rem;
  }

  .pm-xxl-s27, .gx-xxl-s27 > .gx-child {
    padding-left: .84375rem;
    padding-right: .84375rem;
  }

  .gx-xxl-s27 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.6875rem;
  }

  .gx-xxl-s27 > .gx-child:last-of-type {
    padding-left: 1.6875rem;
    padding-right: 0;
  }

  .m-xxl-s29 {
    margin: 1.8125rem;
  }

  .-m-xxl-s29 {
    margin: -1.8125rem;
  }

  .mt-xxl-s29 {
    margin-top: 1.8125rem;
  }

  .-mt-xxl-s29 {
    margin-top: -1.8125rem;
  }

  .mb-xxl-s29 {
    margin-bottom: 1.8125rem;
  }

  .-mb-xxl-s29 {
    margin-bottom: -1.8125rem;
  }

  .mr-xxl-s29 {
    margin-right: 1.8125rem;
  }

  .-mr-xxl-s29 {
    margin-right: -1.8125rem;
  }

  .ml-xxl-s29 {
    margin-left: 1.8125rem;
  }

  .-ml-xxl-s29 {
    margin-left: -1.8125rem;
  }

  .p-xxl-s29 {
    padding: 1.8125rem;
  }

  .-p-xxl-s29 {
    padding: -1.8125rem;
  }

  .pt-xxl-s29 {
    padding-top: 1.8125rem;
  }

  .-pt-xxl-s29 {
    padding-top: -1.8125rem;
  }

  .pb-xxl-s29 {
    padding-bottom: 1.8125rem;
  }

  .-pb-xxl-s29 {
    padding-bottom: -1.8125rem;
  }

  .pr-xxl-s29 {
    padding-right: 1.8125rem;
  }

  .-pr-xxl-s29 {
    padding-right: -1.8125rem;
  }

  .pl-xxl-s29 {
    padding-left: 1.8125rem;
  }

  .-pl-xxl-s29 {
    padding-left: -1.8125rem;
  }

  .mx-xxl-s29 {
    margin-left: 1.8125rem;
    margin-right: 1.8125rem;
  }

  .my-xxl-s29 {
    margin-top: 1.8125rem;
    margin-bottom: 1.8125rem;
  }

  .px-xxl-s29 {
    padding-left: 1.8125rem;
    padding-right: 1.8125rem;
  }

  .py-xxl-s29 {
    padding-top: 1.8125rem;
    padding-bottom: 1.8125rem;
  }

  .gap-xxl-s29 {
    gap: 1.8125rem;
  }

  .pm-xxl-s29, .gx-xxl-s29 > .gx-child {
    padding-left: .90625rem;
    padding-right: .90625rem;
  }

  .gx-xxl-s29 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.8125rem;
  }

  .gx-xxl-s29 > .gx-child:last-of-type {
    padding-left: 1.8125rem;
    padding-right: 0;
  }

  .m-xxl-s30 {
    margin: 1.875rem;
  }

  .-m-xxl-s30 {
    margin: -1.875rem;
  }

  .mt-xxl-s30 {
    margin-top: 1.875rem;
  }

  .-mt-xxl-s30 {
    margin-top: -1.875rem;
  }

  .mb-xxl-s30 {
    margin-bottom: 1.875rem;
  }

  .-mb-xxl-s30 {
    margin-bottom: -1.875rem;
  }

  .mr-xxl-s30 {
    margin-right: 1.875rem;
  }

  .-mr-xxl-s30 {
    margin-right: -1.875rem;
  }

  .ml-xxl-s30 {
    margin-left: 1.875rem;
  }

  .-ml-xxl-s30 {
    margin-left: -1.875rem;
  }

  .p-xxl-s30 {
    padding: 1.875rem;
  }

  .-p-xxl-s30 {
    padding: -1.875rem;
  }

  .pt-xxl-s30 {
    padding-top: 1.875rem;
  }

  .-pt-xxl-s30 {
    padding-top: -1.875rem;
  }

  .pb-xxl-s30 {
    padding-bottom: 1.875rem;
  }

  .-pb-xxl-s30 {
    padding-bottom: -1.875rem;
  }

  .pr-xxl-s30 {
    padding-right: 1.875rem;
  }

  .-pr-xxl-s30 {
    padding-right: -1.875rem;
  }

  .pl-xxl-s30 {
    padding-left: 1.875rem;
  }

  .-pl-xxl-s30 {
    padding-left: -1.875rem;
  }

  .mx-xxl-s30 {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }

  .my-xxl-s30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .px-xxl-s30 {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .py-xxl-s30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .gap-xxl-s30 {
    gap: 1.875rem;
  }

  .pm-xxl-s30, .gx-xxl-s30 > .gx-child {
    padding-left: .9375rem;
    padding-right: .9375rem;
  }

  .gx-xxl-s30 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 1.875rem;
  }

  .gx-xxl-s30 > .gx-child:last-of-type {
    padding-left: 1.875rem;
    padding-right: 0;
  }

  .m-xxl-s32 {
    margin: 2rem;
  }

  .-m-xxl-s32 {
    margin: -2rem;
  }

  .mt-xxl-s32 {
    margin-top: 2rem;
  }

  .-mt-xxl-s32 {
    margin-top: -2rem;
  }

  .mb-xxl-s32 {
    margin-bottom: 2rem;
  }

  .-mb-xxl-s32 {
    margin-bottom: -2rem;
  }

  .mr-xxl-s32 {
    margin-right: 2rem;
  }

  .-mr-xxl-s32 {
    margin-right: -2rem;
  }

  .ml-xxl-s32 {
    margin-left: 2rem;
  }

  .-ml-xxl-s32 {
    margin-left: -2rem;
  }

  .p-xxl-s32 {
    padding: 2rem;
  }

  .-p-xxl-s32 {
    padding: -2rem;
  }

  .pt-xxl-s32 {
    padding-top: 2rem;
  }

  .-pt-xxl-s32 {
    padding-top: -2rem;
  }

  .pb-xxl-s32 {
    padding-bottom: 2rem;
  }

  .-pb-xxl-s32 {
    padding-bottom: -2rem;
  }

  .pr-xxl-s32 {
    padding-right: 2rem;
  }

  .-pr-xxl-s32 {
    padding-right: -2rem;
  }

  .pl-xxl-s32 {
    padding-left: 2rem;
  }

  .-pl-xxl-s32 {
    padding-left: -2rem;
  }

  .mx-xxl-s32 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .my-xxl-s32 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .px-xxl-s32 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .py-xxl-s32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .gap-xxl-s32 {
    gap: 2rem;
  }

  .pm-xxl-s32, .gx-xxl-s32 > .gx-child {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .gx-xxl-s32 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2rem;
  }

  .gx-xxl-s32 > .gx-child:last-of-type {
    padding-left: 2rem;
    padding-right: 0;
  }

  .m-xxl-s35 {
    margin: 2.1875rem;
  }

  .-m-xxl-s35 {
    margin: -2.1875rem;
  }

  .mt-xxl-s35 {
    margin-top: 2.1875rem;
  }

  .-mt-xxl-s35 {
    margin-top: -2.1875rem;
  }

  .mb-xxl-s35 {
    margin-bottom: 2.1875rem;
  }

  .-mb-xxl-s35 {
    margin-bottom: -2.1875rem;
  }

  .mr-xxl-s35 {
    margin-right: 2.1875rem;
  }

  .-mr-xxl-s35 {
    margin-right: -2.1875rem;
  }

  .ml-xxl-s35 {
    margin-left: 2.1875rem;
  }

  .-ml-xxl-s35 {
    margin-left: -2.1875rem;
  }

  .p-xxl-s35 {
    padding: 2.1875rem;
  }

  .-p-xxl-s35 {
    padding: -2.1875rem;
  }

  .pt-xxl-s35 {
    padding-top: 2.1875rem;
  }

  .-pt-xxl-s35 {
    padding-top: -2.1875rem;
  }

  .pb-xxl-s35 {
    padding-bottom: 2.1875rem;
  }

  .-pb-xxl-s35 {
    padding-bottom: -2.1875rem;
  }

  .pr-xxl-s35 {
    padding-right: 2.1875rem;
  }

  .-pr-xxl-s35 {
    padding-right: -2.1875rem;
  }

  .pl-xxl-s35 {
    padding-left: 2.1875rem;
  }

  .-pl-xxl-s35 {
    padding-left: -2.1875rem;
  }

  .mx-xxl-s35 {
    margin-left: 2.1875rem;
    margin-right: 2.1875rem;
  }

  .my-xxl-s35 {
    margin-top: 2.1875rem;
    margin-bottom: 2.1875rem;
  }

  .px-xxl-s35 {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .py-xxl-s35 {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }

  .gap-xxl-s35 {
    gap: 2.1875rem;
  }

  .pm-xxl-s35, .gx-xxl-s35 > .gx-child {
    padding-left: 1.09375rem;
    padding-right: 1.09375rem;
  }

  .gx-xxl-s35 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.1875rem;
  }

  .gx-xxl-s35 > .gx-child:last-of-type {
    padding-left: 2.1875rem;
    padding-right: 0;
  }

  .m-xxl-s40 {
    margin: 2.5rem;
  }

  .-m-xxl-s40 {
    margin: -2.5rem;
  }

  .mt-xxl-s40 {
    margin-top: 2.5rem;
  }

  .-mt-xxl-s40 {
    margin-top: -2.5rem;
  }

  .mb-xxl-s40 {
    margin-bottom: 2.5rem;
  }

  .-mb-xxl-s40 {
    margin-bottom: -2.5rem;
  }

  .mr-xxl-s40 {
    margin-right: 2.5rem;
  }

  .-mr-xxl-s40 {
    margin-right: -2.5rem;
  }

  .ml-xxl-s40 {
    margin-left: 2.5rem;
  }

  .-ml-xxl-s40 {
    margin-left: -2.5rem;
  }

  .p-xxl-s40 {
    padding: 2.5rem;
  }

  .-p-xxl-s40 {
    padding: -2.5rem;
  }

  .pt-xxl-s40 {
    padding-top: 2.5rem;
  }

  .-pt-xxl-s40 {
    padding-top: -2.5rem;
  }

  .pb-xxl-s40 {
    padding-bottom: 2.5rem;
  }

  .-pb-xxl-s40 {
    padding-bottom: -2.5rem;
  }

  .pr-xxl-s40 {
    padding-right: 2.5rem;
  }

  .-pr-xxl-s40 {
    padding-right: -2.5rem;
  }

  .pl-xxl-s40 {
    padding-left: 2.5rem;
  }

  .-pl-xxl-s40 {
    padding-left: -2.5rem;
  }

  .mx-xxl-s40 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .my-xxl-s40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .px-xxl-s40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .py-xxl-s40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .gap-xxl-s40 {
    gap: 2.5rem;
  }

  .pm-xxl-s40, .gx-xxl-s40 > .gx-child {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .gx-xxl-s40 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.5rem;
  }

  .gx-xxl-s40 > .gx-child:last-of-type {
    padding-left: 2.5rem;
    padding-right: 0;
  }

  .m-xxl-s45 {
    margin: 2.8125rem;
  }

  .-m-xxl-s45 {
    margin: -2.8125rem;
  }

  .mt-xxl-s45 {
    margin-top: 2.8125rem;
  }

  .-mt-xxl-s45 {
    margin-top: -2.8125rem;
  }

  .mb-xxl-s45 {
    margin-bottom: 2.8125rem;
  }

  .-mb-xxl-s45 {
    margin-bottom: -2.8125rem;
  }

  .mr-xxl-s45 {
    margin-right: 2.8125rem;
  }

  .-mr-xxl-s45 {
    margin-right: -2.8125rem;
  }

  .ml-xxl-s45 {
    margin-left: 2.8125rem;
  }

  .-ml-xxl-s45 {
    margin-left: -2.8125rem;
  }

  .p-xxl-s45 {
    padding: 2.8125rem;
  }

  .-p-xxl-s45 {
    padding: -2.8125rem;
  }

  .pt-xxl-s45 {
    padding-top: 2.8125rem;
  }

  .-pt-xxl-s45 {
    padding-top: -2.8125rem;
  }

  .pb-xxl-s45 {
    padding-bottom: 2.8125rem;
  }

  .-pb-xxl-s45 {
    padding-bottom: -2.8125rem;
  }

  .pr-xxl-s45 {
    padding-right: 2.8125rem;
  }

  .-pr-xxl-s45 {
    padding-right: -2.8125rem;
  }

  .pl-xxl-s45 {
    padding-left: 2.8125rem;
  }

  .-pl-xxl-s45 {
    padding-left: -2.8125rem;
  }

  .mx-xxl-s45 {
    margin-left: 2.8125rem;
    margin-right: 2.8125rem;
  }

  .my-xxl-s45 {
    margin-top: 2.8125rem;
    margin-bottom: 2.8125rem;
  }

  .px-xxl-s45 {
    padding-left: 2.8125rem;
    padding-right: 2.8125rem;
  }

  .py-xxl-s45 {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }

  .gap-xxl-s45 {
    gap: 2.8125rem;
  }

  .pm-xxl-s45, .gx-xxl-s45 > .gx-child {
    padding-left: 1.40625rem;
    padding-right: 1.40625rem;
  }

  .gx-xxl-s45 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 2.8125rem;
  }

  .gx-xxl-s45 > .gx-child:last-of-type {
    padding-left: 2.8125rem;
    padding-right: 0;
  }

  .m-xxl-s48 {
    margin: 3rem;
  }

  .-m-xxl-s48 {
    margin: -3rem;
  }

  .mt-xxl-s48 {
    margin-top: 3rem;
  }

  .-mt-xxl-s48 {
    margin-top: -3rem;
  }

  .mb-xxl-s48 {
    margin-bottom: 3rem;
  }

  .-mb-xxl-s48 {
    margin-bottom: -3rem;
  }

  .mr-xxl-s48 {
    margin-right: 3rem;
  }

  .-mr-xxl-s48 {
    margin-right: -3rem;
  }

  .ml-xxl-s48 {
    margin-left: 3rem;
  }

  .-ml-xxl-s48 {
    margin-left: -3rem;
  }

  .p-xxl-s48 {
    padding: 3rem;
  }

  .-p-xxl-s48 {
    padding: -3rem;
  }

  .pt-xxl-s48 {
    padding-top: 3rem;
  }

  .-pt-xxl-s48 {
    padding-top: -3rem;
  }

  .pb-xxl-s48 {
    padding-bottom: 3rem;
  }

  .-pb-xxl-s48 {
    padding-bottom: -3rem;
  }

  .pr-xxl-s48 {
    padding-right: 3rem;
  }

  .-pr-xxl-s48 {
    padding-right: -3rem;
  }

  .pl-xxl-s48 {
    padding-left: 3rem;
  }

  .-pl-xxl-s48 {
    padding-left: -3rem;
  }

  .mx-xxl-s48 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .my-xxl-s48 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .px-xxl-s48 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .py-xxl-s48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .gap-xxl-s48 {
    gap: 3rem;
  }

  .pm-xxl-s48, .gx-xxl-s48 > .gx-child {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .gx-xxl-s48 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3rem;
  }

  .gx-xxl-s48 > .gx-child:last-of-type {
    padding-left: 3rem;
    padding-right: 0;
  }

  .m-xxl-s50 {
    margin: 3.125rem;
  }

  .-m-xxl-s50 {
    margin: -3.125rem;
  }

  .mt-xxl-s50 {
    margin-top: 3.125rem;
  }

  .-mt-xxl-s50 {
    margin-top: -3.125rem;
  }

  .mb-xxl-s50 {
    margin-bottom: 3.125rem;
  }

  .-mb-xxl-s50 {
    margin-bottom: -3.125rem;
  }

  .mr-xxl-s50 {
    margin-right: 3.125rem;
  }

  .-mr-xxl-s50 {
    margin-right: -3.125rem;
  }

  .ml-xxl-s50 {
    margin-left: 3.125rem;
  }

  .-ml-xxl-s50 {
    margin-left: -3.125rem;
  }

  .p-xxl-s50 {
    padding: 3.125rem;
  }

  .-p-xxl-s50 {
    padding: -3.125rem;
  }

  .pt-xxl-s50 {
    padding-top: 3.125rem;
  }

  .-pt-xxl-s50 {
    padding-top: -3.125rem;
  }

  .pb-xxl-s50 {
    padding-bottom: 3.125rem;
  }

  .-pb-xxl-s50 {
    padding-bottom: -3.125rem;
  }

  .pr-xxl-s50 {
    padding-right: 3.125rem;
  }

  .-pr-xxl-s50 {
    padding-right: -3.125rem;
  }

  .pl-xxl-s50 {
    padding-left: 3.125rem;
  }

  .-pl-xxl-s50 {
    padding-left: -3.125rem;
  }

  .mx-xxl-s50 {
    margin-left: 3.125rem;
    margin-right: 3.125rem;
  }

  .my-xxl-s50 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }

  .px-xxl-s50 {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .py-xxl-s50 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }

  .gap-xxl-s50 {
    gap: 3.125rem;
  }

  .pm-xxl-s50, .gx-xxl-s50 > .gx-child {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }

  .gx-xxl-s50 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.125rem;
  }

  .gx-xxl-s50 > .gx-child:last-of-type {
    padding-left: 3.125rem;
    padding-right: 0;
  }

  .m-xxl-s60 {
    margin: 3.75rem;
  }

  .-m-xxl-s60 {
    margin: -3.75rem;
  }

  .mt-xxl-s60 {
    margin-top: 3.75rem;
  }

  .-mt-xxl-s60 {
    margin-top: -3.75rem;
  }

  .mb-xxl-s60 {
    margin-bottom: 3.75rem;
  }

  .-mb-xxl-s60 {
    margin-bottom: -3.75rem;
  }

  .mr-xxl-s60 {
    margin-right: 3.75rem;
  }

  .-mr-xxl-s60 {
    margin-right: -3.75rem;
  }

  .ml-xxl-s60 {
    margin-left: 3.75rem;
  }

  .-ml-xxl-s60 {
    margin-left: -3.75rem;
  }

  .p-xxl-s60 {
    padding: 3.75rem;
  }

  .-p-xxl-s60 {
    padding: -3.75rem;
  }

  .pt-xxl-s60 {
    padding-top: 3.75rem;
  }

  .-pt-xxl-s60 {
    padding-top: -3.75rem;
  }

  .pb-xxl-s60 {
    padding-bottom: 3.75rem;
  }

  .-pb-xxl-s60 {
    padding-bottom: -3.75rem;
  }

  .pr-xxl-s60 {
    padding-right: 3.75rem;
  }

  .-pr-xxl-s60 {
    padding-right: -3.75rem;
  }

  .pl-xxl-s60 {
    padding-left: 3.75rem;
  }

  .-pl-xxl-s60 {
    padding-left: -3.75rem;
  }

  .mx-xxl-s60 {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
  }

  .my-xxl-s60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .px-xxl-s60 {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .py-xxl-s60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  .gap-xxl-s60 {
    gap: 3.75rem;
  }

  .pm-xxl-s60, .gx-xxl-s60 > .gx-child {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  .gx-xxl-s60 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 3.75rem;
  }

  .gx-xxl-s60 > .gx-child:last-of-type {
    padding-left: 3.75rem;
    padding-right: 0;
  }

  .m-xxl-s70 {
    margin: 4.375rem;
  }

  .-m-xxl-s70 {
    margin: -4.375rem;
  }

  .mt-xxl-s70 {
    margin-top: 4.375rem;
  }

  .-mt-xxl-s70 {
    margin-top: -4.375rem;
  }

  .mb-xxl-s70 {
    margin-bottom: 4.375rem;
  }

  .-mb-xxl-s70 {
    margin-bottom: -4.375rem;
  }

  .mr-xxl-s70 {
    margin-right: 4.375rem;
  }

  .-mr-xxl-s70 {
    margin-right: -4.375rem;
  }

  .ml-xxl-s70 {
    margin-left: 4.375rem;
  }

  .-ml-xxl-s70 {
    margin-left: -4.375rem;
  }

  .p-xxl-s70 {
    padding: 4.375rem;
  }

  .-p-xxl-s70 {
    padding: -4.375rem;
  }

  .pt-xxl-s70 {
    padding-top: 4.375rem;
  }

  .-pt-xxl-s70 {
    padding-top: -4.375rem;
  }

  .pb-xxl-s70 {
    padding-bottom: 4.375rem;
  }

  .-pb-xxl-s70 {
    padding-bottom: -4.375rem;
  }

  .pr-xxl-s70 {
    padding-right: 4.375rem;
  }

  .-pr-xxl-s70 {
    padding-right: -4.375rem;
  }

  .pl-xxl-s70 {
    padding-left: 4.375rem;
  }

  .-pl-xxl-s70 {
    padding-left: -4.375rem;
  }

  .mx-xxl-s70 {
    margin-left: 4.375rem;
    margin-right: 4.375rem;
  }

  .my-xxl-s70 {
    margin-top: 4.375rem;
    margin-bottom: 4.375rem;
  }

  .px-xxl-s70 {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }

  .py-xxl-s70 {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }

  .gap-xxl-s70 {
    gap: 4.375rem;
  }

  .pm-xxl-s70, .gx-xxl-s70 > .gx-child {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }

  .gx-xxl-s70 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 4.375rem;
  }

  .gx-xxl-s70 > .gx-child:last-of-type {
    padding-left: 4.375rem;
    padding-right: 0;
  }

  .m-xxl-s80 {
    margin: 5rem;
  }

  .-m-xxl-s80 {
    margin: -5rem;
  }

  .mt-xxl-s80 {
    margin-top: 5rem;
  }

  .-mt-xxl-s80 {
    margin-top: -5rem;
  }

  .mb-xxl-s80 {
    margin-bottom: 5rem;
  }

  .-mb-xxl-s80 {
    margin-bottom: -5rem;
  }

  .mr-xxl-s80 {
    margin-right: 5rem;
  }

  .-mr-xxl-s80 {
    margin-right: -5rem;
  }

  .ml-xxl-s80 {
    margin-left: 5rem;
  }

  .-ml-xxl-s80 {
    margin-left: -5rem;
  }

  .p-xxl-s80 {
    padding: 5rem;
  }

  .-p-xxl-s80 {
    padding: -5rem;
  }

  .pt-xxl-s80 {
    padding-top: 5rem;
  }

  .-pt-xxl-s80 {
    padding-top: -5rem;
  }

  .pb-xxl-s80 {
    padding-bottom: 5rem;
  }

  .-pb-xxl-s80 {
    padding-bottom: -5rem;
  }

  .pr-xxl-s80 {
    padding-right: 5rem;
  }

  .-pr-xxl-s80 {
    padding-right: -5rem;
  }

  .pl-xxl-s80 {
    padding-left: 5rem;
  }

  .-pl-xxl-s80 {
    padding-left: -5rem;
  }

  .mx-xxl-s80 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .my-xxl-s80 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .px-xxl-s80 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .py-xxl-s80 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .gap-xxl-s80 {
    gap: 5rem;
  }

  .pm-xxl-s80, .gx-xxl-s80 > .gx-child {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .gx-xxl-s80 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 5rem;
  }

  .gx-xxl-s80 > .gx-child:last-of-type {
    padding-left: 5rem;
    padding-right: 0;
  }

  .m-xxl-s100 {
    margin: 6.25rem;
  }

  .-m-xxl-s100 {
    margin: -6.25rem;
  }

  .mt-xxl-s100 {
    margin-top: 6.25rem;
  }

  .-mt-xxl-s100 {
    margin-top: -6.25rem;
  }

  .mb-xxl-s100 {
    margin-bottom: 6.25rem;
  }

  .-mb-xxl-s100 {
    margin-bottom: -6.25rem;
  }

  .mr-xxl-s100 {
    margin-right: 6.25rem;
  }

  .-mr-xxl-s100 {
    margin-right: -6.25rem;
  }

  .ml-xxl-s100 {
    margin-left: 6.25rem;
  }

  .-ml-xxl-s100 {
    margin-left: -6.25rem;
  }

  .p-xxl-s100 {
    padding: 6.25rem;
  }

  .-p-xxl-s100 {
    padding: -6.25rem;
  }

  .pt-xxl-s100 {
    padding-top: 6.25rem;
  }

  .-pt-xxl-s100 {
    padding-top: -6.25rem;
  }

  .pb-xxl-s100 {
    padding-bottom: 6.25rem;
  }

  .-pb-xxl-s100 {
    padding-bottom: -6.25rem;
  }

  .pr-xxl-s100 {
    padding-right: 6.25rem;
  }

  .-pr-xxl-s100 {
    padding-right: -6.25rem;
  }

  .pl-xxl-s100 {
    padding-left: 6.25rem;
  }

  .-pl-xxl-s100 {
    padding-left: -6.25rem;
  }

  .mx-xxl-s100 {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
  }

  .my-xxl-s100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }

  .px-xxl-s100 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }

  .py-xxl-s100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  .gap-xxl-s100 {
    gap: 6.25rem;
  }

  .pm-xxl-s100, .gx-xxl-s100 > .gx-child {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }

  .gx-xxl-s100 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 6.25rem;
  }

  .gx-xxl-s100 > .gx-child:last-of-type {
    padding-left: 6.25rem;
    padding-right: 0;
  }

  .m-xxl-s120 {
    margin: 7.5rem;
  }

  .-m-xxl-s120 {
    margin: -7.5rem;
  }

  .mt-xxl-s120 {
    margin-top: 7.5rem;
  }

  .-mt-xxl-s120 {
    margin-top: -7.5rem;
  }

  .mb-xxl-s120 {
    margin-bottom: 7.5rem;
  }

  .-mb-xxl-s120 {
    margin-bottom: -7.5rem;
  }

  .mr-xxl-s120 {
    margin-right: 7.5rem;
  }

  .-mr-xxl-s120 {
    margin-right: -7.5rem;
  }

  .ml-xxl-s120 {
    margin-left: 7.5rem;
  }

  .-ml-xxl-s120 {
    margin-left: -7.5rem;
  }

  .p-xxl-s120 {
    padding: 7.5rem;
  }

  .-p-xxl-s120 {
    padding: -7.5rem;
  }

  .pt-xxl-s120 {
    padding-top: 7.5rem;
  }

  .-pt-xxl-s120 {
    padding-top: -7.5rem;
  }

  .pb-xxl-s120 {
    padding-bottom: 7.5rem;
  }

  .-pb-xxl-s120 {
    padding-bottom: -7.5rem;
  }

  .pr-xxl-s120 {
    padding-right: 7.5rem;
  }

  .-pr-xxl-s120 {
    padding-right: -7.5rem;
  }

  .pl-xxl-s120 {
    padding-left: 7.5rem;
  }

  .-pl-xxl-s120 {
    padding-left: -7.5rem;
  }

  .mx-xxl-s120 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }

  .my-xxl-s120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }

  .px-xxl-s120 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }

  .py-xxl-s120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }

  .gap-xxl-s120 {
    gap: 7.5rem;
  }

  .pm-xxl-s120, .gx-xxl-s120 > .gx-child {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .gx-xxl-s120 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 7.5rem;
  }

  .gx-xxl-s120 > .gx-child:last-of-type {
    padding-left: 7.5rem;
    padding-right: 0;
  }

  .m-xxl-s150 {
    margin: 9.375rem;
  }

  .-m-xxl-s150 {
    margin: -9.375rem;
  }

  .mt-xxl-s150 {
    margin-top: 9.375rem;
  }

  .-mt-xxl-s150 {
    margin-top: -9.375rem;
  }

  .mb-xxl-s150 {
    margin-bottom: 9.375rem;
  }

  .-mb-xxl-s150 {
    margin-bottom: -9.375rem;
  }

  .mr-xxl-s150 {
    margin-right: 9.375rem;
  }

  .-mr-xxl-s150 {
    margin-right: -9.375rem;
  }

  .ml-xxl-s150 {
    margin-left: 9.375rem;
  }

  .-ml-xxl-s150 {
    margin-left: -9.375rem;
  }

  .p-xxl-s150 {
    padding: 9.375rem;
  }

  .-p-xxl-s150 {
    padding: -9.375rem;
  }

  .pt-xxl-s150 {
    padding-top: 9.375rem;
  }

  .-pt-xxl-s150 {
    padding-top: -9.375rem;
  }

  .pb-xxl-s150 {
    padding-bottom: 9.375rem;
  }

  .-pb-xxl-s150 {
    padding-bottom: -9.375rem;
  }

  .pr-xxl-s150 {
    padding-right: 9.375rem;
  }

  .-pr-xxl-s150 {
    padding-right: -9.375rem;
  }

  .pl-xxl-s150 {
    padding-left: 9.375rem;
  }

  .-pl-xxl-s150 {
    padding-left: -9.375rem;
  }

  .mx-xxl-s150 {
    margin-left: 9.375rem;
    margin-right: 9.375rem;
  }

  .my-xxl-s150 {
    margin-top: 9.375rem;
    margin-bottom: 9.375rem;
  }

  .px-xxl-s150 {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
  }

  .py-xxl-s150 {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }

  .gap-xxl-s150 {
    gap: 9.375rem;
  }

  .pm-xxl-s150, .gx-xxl-s150 > .gx-child {
    padding-left: 4.6875rem;
    padding-right: 4.6875rem;
  }

  .gx-xxl-s150 > .gx-child:first-of-type {
    padding-left: 0;
    padding-right: 9.375rem;
  }

  .gx-xxl-s150 > .gx-child:last-of-type {
    padding-left: 9.375rem;
    padding-right: 0;
  }
}

.error-red {
  color: red;
}

.error-red.fluid.invisible {
  height: 0;
}

.ui-kit.ui-field {
  border-bottom: 1px solid #949494;
  align-items: baseline;
  gap: .8rem;
  display: flex;
}

.dark .ui-kit.ui-field {
  border-bottom: 1px solid #c7c7c7;
}

.dark .ui-kit.ui-field.contained {
  border: 1px solid #d4d7e5;
}

.ui-kit.ui-field.contained {
  border: 1px solid #d4d7e5;
  border-radius: 8px;
  padding: 8px 16px;
}

.ui-kit.ui-field input {
  width: 100%;
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  font-size: 1rem;
  font-weight: 300;
}

.dark .ui-kit.ui-field input {
  color: #fff;
}

.ui-kit.ui-field input::-webkit-input-placeholder {
  color: #949494;
}

.ui-kit.ui-field input::placeholder {
  color: #949494;
}

.dark .ui-kit.ui-field input::-webkit-input-placeholder {
  color: #c7c7c7;
}

.dark .ui-kit.ui-field input::placeholder {
  color: #c7c7c7;
}

.ui-kit.ui-field img {
  width: auto;
  height: 18px;
}

.ui-kit.ui-field.error {
  color: red;
  border-color: red;
}

.ui-kit.ui-field.error input, .ui-kit.ui-field.error select {
  border-color: red;
}

.ui-kit.ui-field.error input::-webkit-input-placeholder, .ui-kit.ui-field.error select::-webkit-input-placeholder {
  color: red;
}

.ui-kit.ui-field.error input::placeholder, .ui-kit.ui-field.error select::placeholder {
  color: red;
}

.ui-kit.ui-field.error select {
  color: red;
}

.ui-kit.ui-checkbox {
  color: #000;
  align-items: baseline;
  gap: .8rem;
  font-size: .875rem;
  font-weight: 300;
  display: flex;
}

.ui-kit.ui-checkbox input {
  accent-color: #00725c;
}

.ui-kit.ui-checkbox a {
  color: #009e80;
}

.dark .ui-kit.ui-checkbox a {
  color: #01a998;
}

.dark .ui-kit.ui-checkbox {
  color: #fff;
}

.ui-kit.ui-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 5;
  border-radius: 2.25rem;
  outline: none;
  padding: 1rem 5rem;
  font-size: .875rem;
  font-weight: 300;
  display: block;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1512px) {
  .ui-kit.ui-button {
    font-size: 1rem;
  }
}

@media screen and (min-width: 768px) {
  .ui-kit.ui-button {
    padding: 1.25rem 7.5rem;
  }
}

@media screen and (min-width: 1512px) {
  .ui-kit.ui-button {
    padding: 1.25rem 7.5rem;
  }
}

.ui-kit.ui-button.small {
  padding: 1rem;
  font-size: .875rem;
}

@media screen and (min-width: 768px) {
  .ui-kit.ui-button.small {
    font-size: 1.125rem;
  }
}

.ui-kit.ui-button.medium {
  padding: 1rem;
  font-size: .875rem;
}

@media screen and (min-width: 768px) {
  .ui-kit.ui-button.medium {
    padding: 1.25rem .25rem;
    font-size: 1.125rem;
  }
}

.ui-kit.ui-button.wide {
  width: 100%;
  padding: 1.25rem;
}

@media screen and (min-width: 640px) {
  .ui-kit.ui-button.wide {
    width: 100%;
    max-width: 416px;
  }
}

.ui-kit.ui-button.fs-14 {
  font-size: .875rem;
}

@media screen and (min-width: 1025px) {
  .ui-kit.ui-button.lg-large {
    font-size: 1.25rem;
  }
}

.ui-kit.ui-button.p-responsive {
  padding: 1rem;
}

@media screen and (min-width: 1280px) {
  .ui-kit.ui-button.p-responsive {
    padding: 1.2rem 2.5rem;
  }
}

.ui-kit.ui-button:hover .button-cover {
  width: 100%;
}

.ui-kit.ui-button .button-cover {
  z-index: -1;
  width: 0;
  height: 100%;
  border-radius: inherit;
  transition: width .2s, background-color .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.ui-kit.ui-button-solid {
  color: #fff;
  background-color: #00725c;
  border: none;
  font-weight: 600;
}

.ui-kit.ui-button-solid:hover .button-cover {
  width: 100%;
  background-color: #005252;
}

.dark .ui-kit.ui-button-solid:hover .button-cover {
  background-color: #01a998;
}

.ui-kit.ui-button-outline {
  color: #00725c;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid #00725c;
  transition: background-color .4s;
}

.dark .ui-kit.ui-button-outline {
  color: #01a998;
}

.ui-kit.ui-button-outline:hover {
  color: #fff;
  background-color: #00725c;
}

.ui-kit.ui-button-outline:hover .button-cover {
  width: 100%;
  background-color: #00725c;
}

.ui-kit.ui-button.solid-primary {
  color: #fff;
  background-color: #00725c;
  border: none;
}

.ui-kit.ui-button.solid-primary:disabled {
  color: #fff;
  pointer-events: none;
  background-color: #bababa;
  border-color: #fff;
}

.dark .ui-kit.ui-button.solid-primary {
  color: #fff;
  background-color: #00725c;
}

.ui-kit.ui-button.solid-primary.not-cover {
  transition: background-color .4s;
}

.ui-kit.ui-button.solid-primary.not-cover:hover {
  color: #fff;
  background-color: #005252;
}

.dark .ui-kit.ui-button.solid-primary.not-cover:hover {
  color: #fff;
  background-color: #01a998;
}

.ui-kit.ui-button.solid-primary:hover .button-cover {
  width: 100%;
  color: #fff;
  background-color: #005252;
}

.dark .ui-kit.ui-button.solid-primary:hover .button-cover {
  color: #fff;
  background-color: #01a998;
}

.ui-kit.ui-button.solid-blue-250 {
  color: #fff;
  background-color: #3b3beb;
  border: none;
}

.ui-kit.ui-button.solid-blue-250:disabled {
  color: #fff;
  pointer-events: none;
  background-color: #bababa;
  border-color: #fff;
}

.dark .ui-kit.ui-button.solid-blue-250 {
  color: #fff;
  background-color: #2ca8b1;
}

.ui-kit.ui-button.solid-blue-250.not-cover {
  transition: background-color .4s;
}

.ui-kit.ui-button.solid-blue-250.not-cover:hover {
  color: #fff;
  background-color: #000081;
}

.dark .ui-kit.ui-button.solid-blue-250.not-cover:hover {
  color: #fff;
  background-color: #035577;
}

.ui-kit.ui-button.solid-blue-250:hover .button-cover {
  width: 100%;
  color: #fff;
  background-color: #000081;
}

.dark .ui-kit.ui-button.solid-blue-250:hover .button-cover {
  color: #fff;
  background-color: #035577;
}

.ui-kit.ui-button.solid-blue-500 {
  color: #fff;
  background-color: #0303d1;
  border: none;
}

.ui-kit.ui-button.solid-blue-500:disabled {
  color: #fff;
  pointer-events: none;
  background-color: #bababa;
  border-color: #fff;
}

.dark .ui-kit.ui-button.solid-blue-500 {
  color: #fff;
  background-color: #0303d1;
}

.ui-kit.ui-button.solid-blue-500.not-cover {
  transition: background-color .4s;
}

.ui-kit.ui-button.solid-blue-500.not-cover:hover, .dark .ui-kit.ui-button.solid-blue-500.not-cover:hover {
  color: #fff;
  background-color: #3a3aea;
}

.ui-kit.ui-button.solid-blue-500:hover .button-cover {
  width: 100%;
  color: #fff;
  background-color: #3a3aea;
}

.dark .ui-kit.ui-button.solid-blue-500:hover .button-cover {
  color: #fff;
  background-color: #3a3aea;
}

.ui-kit.ui-button.solid-blue-400 {
  color: #fff;
  background-color: #3a3aea;
  border: none;
}

.ui-kit.ui-button.solid-blue-400:disabled {
  color: #fff;
  pointer-events: none;
  background-color: #bababa;
  border-color: #fff;
}

.dark .ui-kit.ui-button.solid-blue-400 {
  color: #fff;
  background-color: #3a3aea;
}

.ui-kit.ui-button.solid-blue-400.not-cover {
  transition: background-color .4s;
}

.ui-kit.ui-button.solid-blue-400.not-cover:hover, .dark .ui-kit.ui-button.solid-blue-400.not-cover:hover {
  color: #fff;
  background-color: #0303d1;
}

.ui-kit.ui-button.solid-blue-400:hover .button-cover {
  width: 100%;
  color: #fff;
  background-color: #0303d1;
}

.dark .ui-kit.ui-button.solid-blue-400:hover .button-cover {
  color: #fff;
  background-color: #0303d1;
}

.ui-kit.ui-button.solid-pink-500 {
  color: #fff;
  background-color: #cc2e78;
  border: none;
}

.ui-kit.ui-button.solid-pink-500:disabled {
  color: #fff;
  pointer-events: none;
  background-color: #bababa;
  border-color: #fff;
}

.dark .ui-kit.ui-button.solid-pink-500 {
  color: #fff;
  background-color: #cc2e78;
}

.ui-kit.ui-button.solid-pink-500.not-cover {
  transition: background-color .4s;
}

.ui-kit.ui-button.solid-pink-500.not-cover:hover, .dark .ui-kit.ui-button.solid-pink-500.not-cover:hover {
  color: #fff;
  background-color: #ee4d99;
}

.ui-kit.ui-button.solid-pink-500:hover .button-cover {
  width: 100%;
  color: #fff;
  background-color: #ee4d99;
}

.dark .ui-kit.ui-button.solid-pink-500:hover .button-cover {
  color: #fff;
  background-color: #ee4d99;
}

.ui-kit.ui-button.solid-navy-300 {
  color: #fff;
  background-color: #3b3beb;
  border: none;
}

.ui-kit.ui-button.solid-navy-300:disabled {
  color: #fff;
  pointer-events: none;
  background-color: #bababa;
  border-color: #fff;
}

.dark .ui-kit.ui-button.solid-navy-300 {
  color: #fff;
  background-color: #2b77bd;
}

.ui-kit.ui-button.solid-navy-300.not-cover {
  transition: background-color .4s;
}

.ui-kit.ui-button.solid-navy-300.not-cover:hover {
  color: #fff;
  background-color: #000081;
}

.dark .ui-kit.ui-button.solid-navy-300.not-cover:hover {
  color: #fff;
  background-color: #005298;
}

.ui-kit.ui-button.solid-navy-300:hover .button-cover {
  width: 100%;
  color: #fff;
  background-color: #000081;
}

.dark .ui-kit.ui-button.solid-navy-300:hover .button-cover {
  color: #fff;
  background-color: #005298;
}

.ui-kit.ui-button.solid-purple-400 {
  color: #fff;
  background-color: #7120ce;
  border: none;
}

.ui-kit.ui-button.solid-purple-400:disabled {
  color: #fff;
  pointer-events: none;
  background-color: #bababa;
  border-color: #fff;
}

.dark .ui-kit.ui-button.solid-purple-400 {
  color: #fff;
  background-color: #7120ce;
}

.ui-kit.ui-button.solid-purple-400.not-cover {
  transition: background-color .4s;
}

.ui-kit.ui-button.solid-purple-400.not-cover:hover, .dark .ui-kit.ui-button.solid-purple-400.not-cover:hover {
  color: #fff;
  background-color: #6e37bd;
}

.ui-kit.ui-button.solid-purple-400:hover .button-cover {
  width: 100%;
  color: #fff;
  background-color: #6e37bd;
}

.dark .ui-kit.ui-button.solid-purple-400:hover .button-cover {
  color: #fff;
  background-color: #6e37bd;
}

.ui-kit.ui-button.solid-persian-green {
  color: #fff;
  background-color: #009e80;
  border: none;
}

.ui-kit.ui-button.solid-persian-green:disabled {
  color: #fff;
  pointer-events: none;
  background-color: #bababa;
  border-color: #fff;
}

.dark .ui-kit.ui-button.solid-persian-green {
  color: #fff;
  background-color: #009e80;
}

.ui-kit.ui-button.solid-persian-green.not-cover {
  transition: background-color .4s;
}

.ui-kit.ui-button.solid-persian-green.not-cover:hover, .dark .ui-kit.ui-button.solid-persian-green.not-cover:hover {
  color: #fff;
  background-color: #00725c;
}

.ui-kit.ui-button.solid-persian-green:hover .button-cover {
  width: 100%;
  color: #fff;
  background-color: #00725c;
}

.dark .ui-kit.ui-button.solid-persian-green:hover .button-cover {
  color: #fff;
  background-color: #00725c;
}

.ui-kit.ui-button.outline-primary {
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  color: #00725c;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #00725c;
  transition: background-color .4s;
}

@media screen and (min-width: 390px) {
  .ui-kit.ui-button.outline-primary {
    border: 2px solid #00725c;
  }
}

.dark .ui-kit.ui-button.outline-primary {
  color: #01a998;
  border: 2px solid #00725c;
}

.ui-kit.ui-button.outline-primary:disabled {
  color: #bababa;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: #bababa;
}

.ui-kit.ui-button.outline-primary:hover, .dark .ui-kit.ui-button.outline-primary:hover {
  color: #fff;
  background-color: #00725c;
}

.ui-kit.ui-button.outline-primary:hover .button-cover {
  width: 100%;
  background-color: #00725c;
}

.dark .ui-kit.ui-button.outline-primary:hover .button-cover {
  color: #fff;
  background-color: #00725c;
}

.ui-kit.ui-button.outline-primary.shadowy {
  box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
}

@media screen and (min-width: 768px) {
  .ui-kit.ui-button.outline-primary.narrow-padding {
    padding: 2.5px 16px;
  }
}

.ui-kit.ui-button.outline-blue-250 {
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  color: #3b3beb;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #3b3beb;
  transition: background-color .4s;
}

@media screen and (min-width: 390px) {
  .ui-kit.ui-button.outline-blue-250 {
    border: 2px solid #3b3beb;
  }
}

.dark .ui-kit.ui-button.outline-blue-250 {
  color: #fff;
  border: 2px solid #2ca8b1;
}

.ui-kit.ui-button.outline-blue-250:disabled {
  color: #bababa;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: #bababa;
}

.ui-kit.ui-button.outline-blue-250:hover {
  color: #fff;
  background-color: #3b3beb;
}

.dark .ui-kit.ui-button.outline-blue-250:hover {
  color: #fff;
  background-color: #2ca8b1;
}

.ui-kit.ui-button.outline-blue-250:hover .button-cover {
  width: 100%;
  background-color: #3b3beb;
}

.dark .ui-kit.ui-button.outline-blue-250:hover .button-cover {
  color: #fff;
  background-color: #2ca8b1;
}

.ui-kit.ui-button.outline-blue-250.shadowy {
  box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
}

@media screen and (min-width: 768px) {
  .ui-kit.ui-button.outline-blue-250.narrow-padding {
    padding: 2.5px 16px;
  }
}

.ui-kit.ui-button.outline-blue-500 {
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #0303d1;
  transition: background-color .4s;
}

@media screen and (min-width: 390px) {
  .ui-kit.ui-button.outline-blue-500 {
    border: 2px solid #0303d1;
  }
}

.dark .ui-kit.ui-button.outline-blue-500 {
  color: #fff;
  border: 2px solid #2ca8b1;
}

.ui-kit.ui-button.outline-blue-500:disabled {
  color: #bababa;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: #bababa;
}

.ui-kit.ui-button.outline-blue-500:hover {
  color: #fff;
  background-color: #0303d1;
}

.dark .ui-kit.ui-button.outline-blue-500:hover {
  color: #fff;
  background-color: #2ca8b1;
}

.ui-kit.ui-button.outline-blue-500:hover .button-cover {
  width: 100%;
  background-color: #0303d1;
}

.dark .ui-kit.ui-button.outline-blue-500:hover .button-cover {
  color: #fff;
  background-color: #2ca8b1;
}

.ui-kit.ui-button.outline-blue-500.shadowy {
  box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
}

@media screen and (min-width: 768px) {
  .ui-kit.ui-button.outline-blue-500.narrow-padding {
    padding: 2.5px 16px;
  }
}

.ui-kit.ui-button.outline-pink-400 {
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  color: #ee4d99;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #ee4d99;
  transition: background-color .4s;
}

@media screen and (min-width: 390px) {
  .ui-kit.ui-button.outline-pink-400 {
    border: 2px solid #ee4d99;
  }
}

.dark .ui-kit.ui-button.outline-pink-400 {
  color: #ee4d99;
  border: 2px solid #ee4d99;
}

.ui-kit.ui-button.outline-pink-400:disabled {
  color: #bababa;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: #bababa;
}

.ui-kit.ui-button.outline-pink-400:hover, .dark .ui-kit.ui-button.outline-pink-400:hover {
  color: #fff;
  background-color: #ee4d99;
}

.ui-kit.ui-button.outline-pink-400:hover .button-cover {
  width: 100%;
  background-color: #ee4d99;
}

.dark .ui-kit.ui-button.outline-pink-400:hover .button-cover {
  color: #fff;
  background-color: #ee4d99;
}

.ui-kit.ui-button.outline-pink-400.shadowy {
  box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
}

@media screen and (min-width: 768px) {
  .ui-kit.ui-button.outline-pink-400.narrow-padding {
    padding: 2.5px 16px;
  }
}

.ui-kit.ui-button.outline-purple-500 {
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  color: #000;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #6e37bd;
  transition: background-color .4s;
}

@media screen and (min-width: 390px) {
  .ui-kit.ui-button.outline-purple-500 {
    border: 2px solid #6e37bd;
  }
}

.dark .ui-kit.ui-button.outline-purple-500 {
  color: #fff;
  border: 2px solid #6e37bd;
}

.ui-kit.ui-button.outline-purple-500:disabled {
  color: #bababa;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  border-color: #bababa;
}

.ui-kit.ui-button.outline-purple-500:hover, .dark .ui-kit.ui-button.outline-purple-500:hover {
  color: #fff;
  background-color: #6e37bd;
}

.ui-kit.ui-button.outline-purple-500:hover .button-cover {
  width: 100%;
  background-color: #6e37bd;
}

.dark .ui-kit.ui-button.outline-purple-500:hover .button-cover {
  color: #fff;
  background-color: #6e37bd;
}

.ui-kit.ui-button.outline-purple-500.shadowy {
  box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
}

@media screen and (min-width: 768px) {
  .ui-kit.ui-button.outline-purple-500.narrow-padding {
    padding: 2.5px 16px;
  }
}

.ui-kit.ui-button.mixed-blue-white {
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  color: #3b3beb;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #3b3beb;
  transition: background-color .4s;
}

@media screen and (min-width: 390px) {
  .ui-kit.ui-button.mixed-blue-white {
    border: 2px solid #3b3beb;
  }
}

.ui-kit.ui-button.mixed-blue-white:hover {
  color: #e6e6e8;
  background-color: #3b3beb;
}

.ui-kit.ui-button.mixed-blue-white:hover .button-cover {
  width: 100%;
  background-color: #3b3beb;
}

.ui-kit.ui-button.mixed-blue-white.shadowy {
  box-shadow: 0 4px 4px rgba(0, 0, 0, .1);
}

@media screen and (min-width: 768px) {
  .ui-kit.ui-button.mixed-blue-white.narrow-padding {
    padding: 2.5px 16px;
  }
}

.dark .ui-kit.ui-button.mixed-blue-white {
  color: #011923;
  background-color: #e6e6e8;
  border: 2px solid #e6e6e8;
}

.dark .ui-kit.ui-button.mixed-blue-white:disabled {
  color: #fff;
  pointer-events: none;
  background-color: #bababa;
  border-color: #fff;
}

.dark .ui-kit.ui-button.mixed-blue-white.not-cover {
  transition: background-color .4s;
}

.dark .ui-kit.ui-button.mixed-blue-white.not-cover:hover {
  color: #011923;
  background-color: #e6e6e8;
}

.dark .ui-kit.ui-button.mixed-blue-white:hover {
  font-weight: 500;
}

.dark .ui-kit.ui-button.mixed-blue-white:hover .button-cover {
  width: 100%;
  color: #011923;
  background-color: #e6e6e8;
}

.ui-kit.ui-select {
  color: #949494;
  background-color: #fff;
  border: none;
  outline: none;
  font-size: .875rem;
  font-weight: 200;
}

.ui-kit.ui-select img {
  height: 18px;
  width: auto;
}

@media screen and (min-width: 1025px) {
  .ui-kit.ui-select {
    font-size: 1.125rem;
  }
}

.dark .ui-kit.ui-select {
  color: #c7c7c7;
  background-color: #001923;
}

.ui-kit.field-help {
  font-size: 1rem;
  font-weight: 300;
}

.dark .ui-kit.field-help {
  color: #c7c7c7;
}

.ui-kit.icon-link {
  text-align: center;
  position: relative;
}

.ui-kit.icon-link a, .ui-kit.icon-link button {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.ui-kit.icon-link a, .ui-kit.icon-link p {
  color: #fff;
  margin: 0;
}

.ui-kit.icon-link button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
}

.field-container {
  flex-direction: column;
  display: flex;
}

@media screen and (min-width: 1025px) {
  .field-container {
    flex-direction: row;
    gap: 6.25rem;
    margin-bottom: 3.125rem;
  }
}

.field-container > * {
  flex: 1;
  margin-bottom: 3.125rem;
}

@media screen and (min-width: 1025px) {
  .field-container > * {
    margin-bottom: 0;
  }
}

.icon-link-tooltip {
  visibility: hidden;
  color: #fff;
  z-index: 10;
  background-color: #000;
  border-radius: 5px;
  padding: .4rem .75rem;
  font-size: .68rem;
  font-weight: 200;
  position: absolute;
  left: 55px;
}

.icon-link-tooltip:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 7px solid rgba(0, 0, 0, 0);
  border-bottom: 7px solid rgba(0, 0, 0, 0);
  border-right: 7px solid #000;
  display: block;
  position: absolute;
  top: 50%;
  left: -.4rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-link-tooltip.tooltip-right {
  left: unset;
  width: -webkit-max-content;
  width: max-content;
  max-width: 200px;
  background-color: #222;
  top: 50%;
  right: 75px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dark .icon-link-tooltip.tooltip-right {
  background-color: #1d3b5e;
}

.icon-link-tooltip.tooltip-right:before {
  left: unset;
  border-left: 7px solid #222;
  border-right: 7px solid rgba(0, 0, 0, 0);
  right: -.8rem;
}

.dark .icon-link-tooltip.tooltip-right:before {
  border-left: 7px solid #1d3b5e;
}

.wtform-field.error {
  color: red;
}

.wtform-field.error input, .wtform-field.error select {
  border-color: red;
}

.wtform-field .field-content {
  gap: 1rem;
  display: flex;
}

.wtform-field.card-range {
  min-width: 40%;
  flex: 1;
}

.wtform-field.card-range input[type="range"] {
  margin: 1.5rem 0 1rem;
}

@media screen and (min-width: 1025px) {
  .wtform-field.card-range {
    min-width: unset;
  }
}

.wtform-field.card-range input::-webkit-outer-spin-button, .wtform-field.card-range input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.wtform-field.card-range input[type="number"] {
  -webkit-appearance: textfield;
  appearance: textfield;
}

.wtform-field .top-input {
  text-align: center;
  max-width: 70px;
  padding: .2813rem .5rem;
}

.wtform-field .input-increment .top-input {
  padding: .2813rem 0;
}

.wtform-field .row-value {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.wtform-field .row-value input {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #bababa;
  border-radius: 50px;
}

.wtform-field .row-value input[type="range"] {
  border: none;
}

.wtform-field input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  margin: 2rem 0 3rem;
}

.wtform-field input[type="range"]:focus {
  outline: none;
}

.wtform-field input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background-color: #00725c;
  border: 0;
  border-radius: 6px;
}

.wtform-field input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #00725c;
  border: none;
  border-radius: 50%;
  outline: none;
  margin-top: -6px;
}

.wtform-field input[type="range"]::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background-color: #00725c;
  border: 0;
  border-radius: 6px;
}

.wtform-field input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background-color: #00725c;
  border: none;
  border-radius: 50%;
  outline: none;
  margin-top: -6px;
}

.wtform-field input[type="range"]::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background-color: #00725c;
  border: 0;
  border-radius: 6px;
}

.wtform-field input[type="range"]::-ms-thumb {
  width: 15px;
  height: 15px;
  cursor: pointer;
  background: #00725c;
  border: none;
  border-radius: 50%;
  outline: none;
  margin-top: 0;
}

.wtform-field .diopter-label {
  width: 15px;
  height: 15px;
  background-color: #00725c;
  border-radius: 50%;
  right: calc(50% - 30px);
}

@media screen and (min-width: 768px) {
  .wtform-field .diopter-label {
    right: calc(50% - 41.5px);
  }
}

.wtform-field .diopter-label.transition {
  transition: right .5s linear;
}

.field-error-message {
  color: red;
  font-size: 14px;
}

.field-error-message.under-input-error {
  margin-left: 118px;
}

.row-value .input-increment {
  border: 1px solid #bababa;
  border-radius: 100px;
  overflow: hidden;
}

.dark .row-value .input-increment {
  border: 1px solid #fff;
}

.row-value .input-increment button {
  padding: 0 14px;
  background-color: #443ae1 !important;
}

@media screen and (min-width: 1025px) {
  .row-value .input-increment button {
    padding: 0 10px;
  }
}

@media screen and (min-width: 1280px) {
  .row-value .input-increment button {
    padding: 0 14px;
  }
}

.row-value .input-increment input {
  min-width: 60px;
  border: none;
  border-radius: 0;
}

@media screen and (min-width: 1025px) {
  .row-value .input-increment input {
    min-width: 50px;
  }
}

@media screen and (min-width: 1280px) {
  .row-value .input-increment input {
    min-width: 60px;
  }
}

.row-value .input-increment .increment-minus {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.row-value .input-increment .increment-plus {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.login-field {
  border: 1px solid #d4d7e5;
  border-radius: 8px;
}

.row-input.input-error, select.input-error {
  color: red;
  border-color: red;
}

.row-input.input-error input, .row-input.input-error select, select.input-error input, select.input-error select {
  border-color: red;
}

.row-input.input-error input::-webkit-input-placeholder, .row-input.input-error select::-webkit-input-placeholder, select.input-error input::-webkit-input-placeholder, select.input-error select::-webkit-input-placeholder {
  color: red;
}

.row-input.input-error input::placeholder, .row-input.input-error select::placeholder, select.input-error input::placeholder, select.input-error select::placeholder {
  color: red;
}

.row-input.input-error select, select.input-error select {
  color: red;
}

.date-container:not(.d-none).input-error + select {
  color: red;
  border-color: red;
}

.date-container:not(.d-none).input-error + select input, .date-container:not(.d-none).input-error + select select {
  border-color: red;
}

.date-container:not(.d-none).input-error + select input::-webkit-input-placeholder, .date-container:not(.d-none).input-error + select select::-webkit-input-placeholder {
  color: red;
}

.date-container:not(.d-none).input-error + select input::placeholder, .date-container:not(.d-none).input-error + select select::placeholder {
  color: red;
}

.date-container:not(.d-none).input-error + select select {
  color: red;
}

.ui-kit.ui-coopervision-logo {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.ui-kit.ui-coopervision-logo > img {
  object-fit: contain;
  max-width: 100%;
}

.ui-kit.ui-coopervision-logo > p {
  color: #5a5a5a;
  margin-bottom: .5rem;
  font-size: .875rem;
}

.dark .ui-kit.ui-coopervision-logo > p {
  color: #fff;
}

.ui-kit.ui-ocumetra-logo img {
  max-width: 318px;
}

.ui-kit.ui-ocumetra-logo span {
  margin-bottom: 4px;
  font-size: 12px;
}

.meye-logo-image {
  max-width: 150px;
}

@media screen and (min-width: 1025px) {
  .meye-logo-image {
    max-width: 250px;
  }
}

@media screen and (min-width: 1280px) {
  .meye-logo-image {
    max-width: 360px;
  }
}

.coopervision-logo-img {
  max-width: 136px;
}

@media screen and (min-width: 768px) {
  .coopervision-logo-img {
    max-width: 180px;
  }
}

.footer-logo {
  max-width: 180px;
  height: auto;
}

@media (max-width: 768px) {
  .footer-logo {
    max-width: 120px;
  }
}

@media (max-width: 640px) {
  .footer-logo {
    max-width: 100px;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ui-kit.ui-theme-toggle {
  position: relative;
}

.ui-kit.ui-theme-toggle button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: 0;
}

.ui-kit.ui-theme-toggle.loading {
  pointer-events: none;
}

.ui-kit.ui-theme-toggle.loading .ui-kit-toggle-label .slider .circle {
  background-color: rgba(0, 0, 0, 0);
  border: 3px solid #fff;
  border-right-color: rgba(0, 0, 0, 0);
  -webkit-animation: 1.5s linear infinite spin;
  animation: 1.5s linear infinite spin;
}

.ui-kit.ui-theme-toggle .ui-kit-toggle-label {
  cursor: pointer;
  display: inline-block;
}

.ui-kit.ui-theme-toggle .ui-kit-toggle-label .slider {
  background-color: #e5e5e5;
  border-radius: 1rem;
  align-items: center;
  gap: .8rem;
  padding: 2px 10px;
  transition: rotate, all .4s;
  display: inline-flex;
}

.ui-kit.ui-theme-toggle .ui-kit-toggle-label .slider .circle {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
}

.dark .ui-kit.ui-theme-toggle .ui-kit-toggle-label .slider {
  background-color: #043f3f;
}

.ui-kit.ui-theme-toggle .ui-kit-toggle-label input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ui-kit.ui-theme-toggle .ui-kit-toggle-label input[value="light"] + .slider, .ui-kit.ui-theme-toggle .ui-kit-toggle-label input[value="light"] + .slider img {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ui-kit.ui-theme-toggle .ui-kit-toggle-label .toggle-tooltip {
  text-align: center;
  min-width: 150px;
  color: #949494;
  background-color: #fff;
  border-radius: 20px;
  font-size: .6rem;
  font-weight: 300;
  display: none;
  position: absolute;
  top: 40px;
  left: 50%;
  -webkit-transform: translateX(-65%);
  transform: translateX(-65%);
}

.dark .ui-kit.ui-theme-toggle .ui-kit-toggle-label .toggle-tooltip {
  color: #f5f5f5;
  background-color: #001923;
}

.ui-kit.ui-theme-toggle:hover .toggle-tooltip {
  display: inline;
}

.ui-kit.ui-radio-toggle {
  width: 100%;
}

@media screen and (min-width: 1025px) {
  .ui-kit.ui-radio-toggle {
    width: auto;
  }
}

@media screen and (min-width: 1400px) {
  .ui-kit.ui-radio-toggle label p {
    font-size: 1.125rem;
  }
}

.ui-kit.ui-radio-toggle.small .animated-buttons-container label {
  padding: .4rem 0;
}

.ui-kit.ui-radio-toggle.font-light .animated-buttons-container label p {
  font-weight: 200;
}

.ui-kit.ui-radio-toggle.white-label .animated-buttons-container {
  background-color: #fff;
}

.ui-kit.ui-radio-toggle.toggle-blue .animated-buttons-container .button-decoration {
  background-color: #3a3aea;
}

.ui-kit.ui-radio-toggle.toggle-blue-350 .animated-buttons-container .button-decoration {
  background-color: #3a3af2;
}

.dark .ui-kit.ui-radio-toggle.toggle-blue-350 .animated-buttons-container .button-decoration {
  background-color: #e6e6e8;
  border: 1.5px solid #2a537e;
}

.ui-kit.ui-radio-toggle.toggle-purple .animated-buttons-container .button-decoration {
  background-color: #6e37bd;
}

.ui-kit.ui-radio-toggle.toggle-dark-blue .animated-buttons-container .button-decoration {
  background-color: #0303d1;
}

.dark .ui-kit.ui-radio-toggle.toggle-dark-blue .animated-buttons-container .button-decoration {
  background-color: #035577;
  border: 1.5px solid #2ca8b1;
}

.ui-kit.ui-radio-toggle.toggle-pink .animated-buttons-container .button-decoration {
  background-color: #cc2e78;
}

.dark .ui-kit.ui-radio-toggle.toggle-dark-blue-300 .animated-buttons-container {
  background-color: #012c3c;
}

.ui-kit.ui-radio-toggle .animated-buttons-container {
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 27px;
  display: flex;
  position: relative;
}

.dark .ui-kit.ui-radio-toggle .animated-buttons-container {
  background-color: #010314;
}

.ui-kit.ui-radio-toggle .animated-buttons-container label {
  z-index: 20;
  width: 100%;
  height: inherit;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: .4rem 0;
  display: flex;
  position: relative;
}

.ui-kit.ui-radio-toggle .animated-buttons-container label p {
  text-align: center;
  z-index: 30;
  flex: 1;
  transition: color .4s;
  position: relative;
}

.ui-kit.ui-radio-toggle .animated-buttons-container input {
  opacity: 0;
  width: 0;
  height: 0;
  border: 0;
}

.ui-kit.ui-radio-toggle .animated-buttons-container input:checked + p, .ui-kit.ui-radio-toggle .animated-buttons-container .toggle-active {
  color: #fff;
  font-weight: 600;
}

.dark .ui-kit.ui-radio-toggle .animated-buttons-container.color-dark input:checked + p, .dark .ui-kit.ui-radio-toggle .animated-buttons-container.color-dark .toggle-active {
  color: #121212;
}

.ui-kit.ui-radio-toggle .animated-buttons-container .button-decoration {
  height: 100%;
  width: 50%;
  z-index: 0;
  background-color: #00725c;
  border-radius: 27px;
  transition: left .4s;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.ui-kit.ui-radio-toggle .animated-buttons-container .button-decoration.first-selected {
  display: block;
  left: 0;
  box-shadow: 3px 0 6px rgba(0, 0, 0, .25);
}

.ui-kit.ui-radio-toggle .animated-buttons-container .button-decoration.second-selected {
  display: block;
  left: 50%;
  box-shadow: -3px 0 6px rgba(0, 0, 0, .25);
}

.radio-toggle-container {
  min-width: 150px;
}

@media screen and (min-width: 1025px) {
  .radio-toggle-container {
    min-width: 100px;
  }
}

@media screen and (min-width: 1280px) {
  .radio-toggle-container {
    min-width: 150px;
  }
}

.ui-kit.product-card {
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  padding: 2rem 1rem;
  font-size: .875rem;
  font-weight: 300;
  display: flex;
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .16);
}

.ui-kit.product-card.disabled {
  pointer-events: none;
  -webkit-filter: grayscale();
  filter: grayscale();
}

@media screen and (min-width: 768px) {
  .ui-kit.product-card {
    padding-left: 280px;
    padding-right: 1.5rem;
  }
}

@media screen and (min-width: 1025px) {
  .ui-kit.product-card {
    min-height: 476px;
    padding-top: 170px;
    padding-bottom: 170px;
  }

  .ui-kit.product-card:hover {
    padding-top: 40px;
    padding-bottom: 2rem;
  }

  .ui-kit.product-card:hover .card-caller {
    height: auto;
    visibility: visible;
    opacity: 1;
  }

  .ui-kit.product-card:hover h4 {
    text-align: left;
    font-size: 1.5rem;
  }
}

.dark .ui-kit.product-card {
  box-shadow: unset;
  border: none;
}

.ui-kit.product-card ul {
  margin-top: 1rem;
  padding: 0;
}

.ui-kit.product-card ul li {
  border-bottom: 1px solid #c7c7c7;
  margin-bottom: .5rem;
  padding-bottom: .5rem;
}

.ui-kit.product-card a {
  color: #00725c;
}

.dark .ui-kit.product-card a {
  color: #01a998;
}

.ui-kit.product-card h4 {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

@media screen and (min-width: 1025px) {
  .ui-kit.product-card h4 {
    font-size: 3.125rem;
  }
}

.ui-kit.product-card .card-content {
  flex: 1;
}

@media screen and (min-width: 1025px) {
  .ui-kit.product-card .card-content {
    align-self: center;
  }
}

.ui-kit.product-card .card-caller {
  z-index: 10;
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

@media screen and (min-width: 1025px) {
  .ui-kit.product-card .card-caller {
    height: 0;
    visibility: hidden;
    opacity: 0;
  }
}

.ui-kit.product-card img {
  height: auto;
  display: none;
  position: absolute;
}

@media screen and (min-width: 768px) {
  .ui-kit.product-card img {
    display: block;
  }
}

.ui-kit.product-card .button-container {
  align-self: center;
}

.ui-kit.subscription-card {
  width: 100%;
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  flex-direction: column;
  padding: 2.68rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .16);
}

.ui-kit.subscription-card.show-icon {
  padding-top: 0;
}

@media screen and (min-width: 1025px) {
  .ui-kit.subscription-card {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

.dark .ui-kit.subscription-card {
  background-color: #000;
}

.ui-kit.subscription-card img {
  margin-top: -22px;
  margin-bottom: 1.875rem;
}

.ui-kit.subscription-card p {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-weight: 300;
}

.ui-kit.subscription-card div {
  justify-content: center;
  display: flex;
}

.ui-kit.subscription-card .card-title {
  color: #00725c;
  margin-bottom: .5rem;
  font-size: 2.125rem;
  font-weight: 300;
}

.dark .ui-kit.subscription-card .card-title {
  color: #01a998;
}

.ui-kit.subscription-card .card-price {
  font-size: 2.125rem;
  font-weight: bold;
}

.ui-kit.subscription-card .card-badge {
  color: #fff;
  background-color: #00725c;
  border-radius: 100px;
  margin-top: -22px;
  padding: 8px 40px;
  font-size: 20px;
  font-weight: bold;
}

.ui-kit.benefits-card {
  background-color: #ebebeb;
  border-radius: 20px;
  padding: 2rem;
  font-weight: 300;
}

.dark .ui-kit.benefits-card {
  background-color: #000;
}

.ui-kit.benefits-card h4 {
  margin-bottom: 1.75rem;
  font-size: 1.5rem;
  font-weight: 300;
}

@media screen and (min-width: 1280px) {
  .ui-kit.benefits-card h4 {
    font-size: 2rem;
  }
}

.ui-kit.benefits-card .benefit-description {
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: 300;
}

@media screen and (min-width: 1280px) {
  .ui-kit.benefits-card .benefit-description {
    font-size: 1.5rem;
  }
}

.ui-kit.benefits-card .benefits-list {
  margin: 0;
  padding: 0;
}

.ui-kit.benefits-card .benefit-item {
  align-items: baseline;
  gap: .8rem;
  display: flex;
}

.ui-kit.benefits-card .benefit-item p {
  width: 100%;
  border-bottom: 1px solid #d6d6d6;
  padding: .5rem 0;
  font-weight: 300;
}

.ui-kit.benefits-card .benefit-item.last-item p {
  border-bottom: none;
}

.ui-kit.benefits-card .benefit-item img {
  position: relative;
  top: 4px;
}

.ui-kit.benefits-card .benefit-item img, .ui-kit.benefits-card .benefit-item p {
  margin: 0;
  display: block;
}

.ui-kit.ui-titled-card {
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .16);
}

.dark .ui-kit.ui-titled-card {
  background-color: #001923;
  box-shadow: 0 1px 8px rgba(11, 183, 166, .44);
}

.dark .ui-kit.ui-titled-card input {
  color: #fff;
}

.ui-kit.ui-report-data-card .card-header {
  background-color: #e6e6ff;
  border: 2px solid rgba(0, 0, 0, 0);
  border-bottom: 0;
  border-radius: 15px 15px 0 0;
}

.dark .ui-kit.ui-report-data-card .card-header {
  color: #000;
  border: 2px solid #fff;
  border-bottom: 0;
}

.ui-kit.ui-report-data-card .card-header.header-blue {
  background-color: #3a3af2;
}

.dark .ui-kit.ui-report-data-card .card-header.header-blue {
  background-color: #3838f8;
}

.ui-kit.ui-report-data-card .card-body {
  border: 2px solid #e6e6ff;
  border-top: none;
  border-radius: 0 0 15px 15px;
}

.dark .ui-kit.ui-report-data-card .card-body.body-white {
  border: 2px solid #fff;
  border-top: none;
}

.card-bubble {
  background-color: #fff;
  position: relative;
}

.card-bubble.bubble-arrow:before, .card-bubble.up-arrow:before {
  content: "";
  width: 0;
  height: 0;
  -webkit-filter: drop-shadow(0 -2px #e6e6ff);
  filter: drop-shadow(0 -2px #e6e6ff);
  border-bottom: 20px solid #fff;
  border-left: 20px solid rgba(0, 0, 0, 0);
  border-right: 20px solid rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
  top: -20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.dark .card-bubble.bubble-arrow:before, .dark .card-bubble.up-arrow:before {
  -webkit-filter: drop-shadow(0 -2px #dde8e9);
  filter: drop-shadow(0 -2px #dde8e9);
  border-bottom: 20px solid #001923;
}

.card-bubble.down-arrow:after {
  content: "";
  width: 0;
  height: 0;
  -webkit-filter: drop-shadow(0 2px #e6e6ff);
  filter: drop-shadow(0 2px #e6e6ff);
  border-top: 15px solid #fff;
  border-left: 15px solid rgba(0, 0, 0, 0);
  border-right: 15px solid rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.dark .card-bubble.down-arrow:after {
  -webkit-filter: drop-shadow(0 2px #2b77bd);
  filter: drop-shadow(0 2px #2b77bd);
}

.card-divider {
  border-bottom: 2px solid #bababa;
}

.card-divider.divider-purple {
  border-color: #e6e6ff;
}

@media screen and (min-width: 768px) {
  .card-divider.divider-md-right {
    border-bottom: none;
    border-right: 2px solid #bababa;
  }
}

.dark .body-white .card-divider {
  border-color: #fff;
}

.border-purple-white {
  border: 2px solid #e6e6ff;
}

.dark .border-purple-white {
  border: 2px solid #dde8e9;
}

.shadow-blue {
  box-shadow: 0 0 10px rgba(3, 3, 209, .32);
}

.dark .shadow-blue {
  box-shadow: 0 0 10px #2ca8b1;
}

.full-icon-card {
  border: 3px solid #f5f5f5;
}

.dark .full-icon-card {
  border: 3px solid #012c3c;
}

.full-icon-card .full-icon-card-banner {
  background-color: #e6e6ff;
  outline: 3px solid #e6e6ff;
}

.dark .full-icon-card .full-icon-card-banner {
  outline: 3px solid #012c3c;
}

@media screen and (min-width: 390px) {
  .border-xs-blue {
    border: 2px solid #e6e6ff;
  }

  .dark .border-xs-blue {
    border: 2px solid #2ca8b1;
  }

  .shadow-xs-blue {
    box-shadow: 0 0 10px rgba(3, 3, 209, .32);
  }

  .dark .shadow-xs-blue {
    box-shadow: 0 0 10px rgba(100, 146, 163, .32);
  }
}

.figure-card {
  border: 2px solid #e6e6ff;
}

@media screen and (min-width: 1280px) {
  .figure-card-divider {
    border-right: 1px solid gray;
  }
}

.ui-kit.trial-days {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 640px) {
  .ui-kit.trial-days {
    align-items: flex-end;
  }
}

.ui-kit.trial-days > div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  border-radius: 20px;
  margin-bottom: .5rem;
  padding: .5rem 1.5rem;
  font-weight: bold;
}

.ui-kit.trial-days > p, .ui-kit.trial-days .trial-warning {
  text-align: right;
  font-size: .875rem;
  font-weight: 300;
}

.ui-kit.trial-days.trial-days-left > div {
  color: #fff;
  background-color: #00725c;
}

.ui-kit.trial-days.trial-days-over > div {
  color: red;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid red;
}

.ui-kit.trial-days .trial-warning {
  color: red;
  margin-bottom: .5rem;
}

.ui-kit.plus-minus-icon {
  width: 15px;
  flex-shrink: 0;
  display: inline-block;
}

.ui-kit.plus-minus-icon.plus {
  height: 15px;
  background: conic-gradient(from 90deg at 2px 2px, transparent 90deg, #949494 0) calc(100% + 1px) calc(100% + 1px) / calc(50% + 2px) calc(50% + 2px);
}

.ui-kit.plus-minus-icon.minus {
  height: 2px;
  background-color: #949494;
}

.accordion-container {
  flex-direction: column;
  gap: 4rem;
  display: flex;
}

@media screen and (min-width: 1025px) {
  .accordion-container {
    flex-direction: row;
  }
}

.accordion-container > div:nth-of-type(2) {
  flex: 1;
}

.content-accordion {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  -webkit-transform: translateX(5rem);
  transform: translateX(5rem);
}

.content-accordion h4 {
  font-size: 1.25rem;
  font-weight: 200;
}

.content-accordion p {
  font-size: 1rem;
  font-weight: 200;
}

.content-accordion.show-accordion {
  opacity: 1;
  max-height: 100%;
  transition: all .4s ease-in-out;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.content-selectors {
  width: 100%;
  align-self: flex-start;
  top: 10px;
}

@media screen and (min-width: 1025px) {
  .content-selectors {
    width: auto;
    position: -webkit-sticky;
    position: sticky;
  }
}

.content-selectors h4 {
  font-size: 1.875rem;
  font-weight: 200;
}

.content-selectors > div {
  border-radius: 28px;
  padding: 1rem 2rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .16);
}

.dark .content-selectors > div {
  background-color: #000;
}

.content-selectors > div:nth-of-type(1) {
  margin-bottom: 1rem;
}

.content-selector ul {
  margin: 0;
  padding: 0;
}

.content-selector-header {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.content-selector-topics {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.content-selector-topics.show-topics {
  max-height: 100%;
  opacity: 1;
  transition: all .4s ease-in-out;
}

.content-selector-button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-left: 2px solid #c7c7c7;
  outline: none;
  margin: 0;
  padding: 1rem;
  transition: border-left .4s;
}

.dark .content-selector-button {
  color: #fff;
}

.content-selector-button.show-accordion {
  color: #00725c;
  border-left: 2px solid #00725c;
}

.dark .content-selector-button.show-accordion {
  color: #01a998;
  border-left-color: #01a998;
}

a.accordion-item:hover {
  color: unset;
  text-decoration: none;
}

a.accordion-item:active {
  color: unset;
}

.content-accordion-item {
  border-bottom: 1px solid #949494;
}

.dark .content-accordion-item {
  border-color: #fff;
}

.content-accordion-item .collapsable-body {
  max-height: 0;
  opacity: 0;
  border-top: 1px solid #ebebeb;
  padding: 0 1rem;
  transition: all .4s ease-out;
  overflow: hidden;
}

.dark .content-accordion-item .collapsable-body {
  border-color: rgba(255, 255, 255, .2);
}

.content-accordion-item .collapsable-body.active {
  max-height: 1000vh;
  opacity: 1;
  padding: 1rem 1.25rem 1rem 1rem;
}

.content-accordion-item .collapsable-header {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
  padding: 1.25rem;
  display: flex;
}

.ui-kit-tooltip-mobile {
  z-index: 2000;
  width: 100vw;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  max-height: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, .85);
  border-radius: 15px 15px 0 0;
  transition: max-height .5s;
  position: fixed;
  bottom: 0;
}

.ui-kit-tooltip-mobile.show {
  visibility: visible;
  max-height: 500px;
}

.ui-kit-tooltip-mobile.show + .tooltip-mobile-backdrop {
  background-color: rgba(0, 0, 0, .2);
  display: block;
}

@media screen and (min-width: 768px) {
  .ui-kit-tooltip-mobile.show {
    display: none;
  }
}

.ui-kit-tooltip-mobile + .tooltip-mobile-backdrop {
  z-index: 1500;
  display: none;
}

.tooltip-mobile-button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-top: 1px solid #fff;
  text-decoration: underline;
}

.ui-kit-tooltip {
  width: 100%;
  color: #d9d9d9;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  z-index: 50;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, .75);
  border-radius: 25px;
  padding: 20px 20px 20px 30px;
  transition: all .4s;
  left: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, .4);
}

@media screen and (min-width: 1025px) {
  .ui-kit-tooltip {
    padding: 35px 35px 25px;
  }
}

.ui-kit-tooltip .never-transform, .ui-kit-tooltip .never-transform:hover {
  -webkit-transform: rotate(0) !important;
  transform: rotate(0) !important;
}

.ui-kit-tooltip + span svg {
  width: 34px;
  height: 34px;
  background-color: #443ae1;
  border-radius: 50%;
  transition: -webkit-transform .4s, transform .4s;
  position: relative;
}

.ui-kit-tooltip + span svg.icon-svg {
  background-color: rgba(0, 0, 0, 0);
}

.ui-kit-tooltip + span svg.icon-svg:hover {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
}

.ui-kit-tooltip + span svg.icon-svg:hover .fill-white {
  fill: #fff !important;
}

.dark .ui-kit-tooltip + span svg {
  background-color: #2b77bd;
}

.ui-kit-tooltip + span svg:hover {
  background-color: #0000ad;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
}

.ui-kit-tooltip + span svg:hover .info-path {
  fill: #0000ad;
}

.ui-kit-tooltip + span svg:hover .fill-blue-50 {
  fill: #c6c5ff;
}

.ui-kit-tooltip + span svg path {
  transition: fill .4s;
}

.ui-kit-tooltip + span svg.purple {
  background-color: #e6e6ff;
}

.ui-kit-tooltip + span svg.purple:hover {
  box-shadow: none;
  background-color: #a7a7f9;
}

.ui-kit-tooltip + span svg.purple:hover path {
  fill: #fff;
}

.ui-kit-tooltip.show {
  visibility: visible;
  opacity: 1;
}

.ui-kit-tooltip.show + span svg.purple {
  box-shadow: none;
  background-color: #a7a7f9;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.ui-kit-tooltip.show + span svg.purple path {
  fill: #fff;
}

.ui-kit-tooltip.show + span:before {
  visibility: visible;
  opacity: 1;
}

.ui-kit-tooltip.show + span .x-close.x-close-mirror {
  -webkit-transform: translate(-50%, -50%)rotate(-45deg);
  transform: translate(-50%, -50%)rotate(-45deg);
}

.ui-kit-tooltip.show + span .x-close {
  opacity: 1;
  z-index: 5;
  -webkit-transform: translate(-50%, -50%)rotate(45deg);
  transform: translate(-50%, -50%)rotate(45deg);
}

.ui-kit-tooltip.show + span .icon-effect {
  box-shadow: none;
}

.ui-kit-tooltip.show + span svg {
  box-shadow: none;
  background-color: #bababa;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ui-kit-tooltip.show + span svg.never-transform {
  -webkit-transform: none !important;
  transform: none !important;
}

.ui-kit-tooltip.show + span svg.never-transform path.fill-white, .ui-kit-tooltip.show + span svg.never-transform:hover .info-path.fill-white {
  fill: #fff;
}

.ui-kit-tooltip.show + span svg path, .ui-kit-tooltip.show + span svg:hover .info-path {
  fill: #bababa;
}

.x-close-mirror {
  -webkit-transform: translate(-50%, -50%)rotate(-45deg);
  transform: translate(-50%, -50%)rotate(-45deg);
}

.x-close {
  width: 3px;
  height: 70%;
  opacity: 0;
  background-color: #fff;
  border-radius: 100px;
  transition: -webkit-transform .4s, transform .4s, opacity .4s;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) {
  .tooltip-w-overflow-sm {
    width: calc(100% + 20px);
  }
}

.tooltip-reverse {
  flex-direction: row-reverse;
  display: flex;
}

.tooltip-arrow:before {
  content: "";
  z-index: 25;
  visibility: hidden;
  opacity: 0;
  width: 14px;
  height: 14px;
  background-image: linear-gradient(135deg, rgba(0, 0, 0, .75) 0, rgba(0, 0, 0, .75) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
  transition: opacity .4s;
  display: none;
  position: absolute;
  bottom: -18px;
  left: 50%;
  -webkit-transform: translateX(-50%)rotate(45deg);
  transform: translateX(-50%)rotate(45deg);
  box-shadow: -2px -2px 2px rgba(0, 0, 0, .1);
}

@media screen and (min-width: 768px) {
  .tooltip-arrow:before {
    display: block;
  }
}

.dark .tooltip-arrow:before {
  background-color: #012c3c;
}

.tooltip-arrow.no-arrow:before {
  display: none;
}

.mobile-tooltip-content {
  max-height: 250px;
  overflow-y: auto;
}

.mobile-tooltip-content::-webkit-scrollbar {
  width: 4px;
}

.mobile-tooltip-content::-webkit-scrollbar-track {
  background: rgba(239, 239, 239, .5);
  border-radius: 5px;
}

.mobile-tooltip-content::-webkit-scrollbar-thumb {
  width: 10px;
  background: #c5c5ff;
  border-radius: 5px;
  outline: 1px solid #c5c5ff;
}

.autocomplete-list {
  padding: 0;
  overflow: hidden;
}

.autocomplete-list > li {
  cursor: pointer;
  border: 1px solid #bababa;
  border-top: 0;
  padding: 10px 20px;
  font-size: 12px;
  overflow: hidden;
}

@media screen and (min-width: 1280px) {
  .autocomplete-list > li {
    font-size: 14px;
  }
}

@media screen and (min-width: 1512px) {
  .autocomplete-list > li {
    font-size: 18px;
  }
}

.autocomplete-list > li:last-of-type {
  border-radius: 0 0 10px 10px;
}

.autocomplete-list li, .autocomplete-list:hover li, .autocomplete-list li:hover ~ .focused {
  background-color: #fff;
}

.dark .autocomplete-list li, .dark .autocomplete-list:hover li, .dark .autocomplete-list li:hover ~ .focused {
  background-color: #000;
}

.autocomplete-list .focused, .autocomplete-list li:hover {
  background-color: #f2e9ff;
}

.dark .autocomplete-list .focused, .dark .autocomplete-list li:hover {
  color: #000;
  background-color: #f2e9ff;
}

.autocomplete-input {
  border: 1px solid #bababa;
}

.autocomplete-input.input-error {
  border: 1px solid red;
}

.autocomplete-input.input-error::-webkit-input-placeholder {
  color: red;
}

.autocomplete-input.input-error::placeholder {
  color: red;
}

.autocomplete-input::-webkit-input-placeholder {
  color: #000;
}

.autocomplete-input::placeholder {
  color: #000;
}

.dark .autocomplete-input::-webkit-input-placeholder {
  color: #fff;
}

.dark .autocomplete-input::placeholder {
  color: #fff;
}

.ui-kit.ui-header {
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: .5rem;
  padding: 1.5rem 1rem;
  display: flex;
}

@media screen and (min-width: 640px) {
  .ui-kit.ui-header {
    align-items: center;
    padding: 3.125rem 3.125rem 2rem;
  }
}

.ui-kit.ui-header.register-header {
  width: 100%;
  max-width: 1296px;
  margin: 0 auto;
  padding: 1.875rem 0 0;
}

@media screen and (min-width: 1512px) {
  .ui-kit.ui-header.register-header {
    max-width: unset;
  }
}

.ui-kit.ui-header .footer-logo {
  max-width: 180px;
  height: auto;
}

@media (max-width: 768px) {
  .ui-kit.ui-header .footer-logo {
    max-width: 120px;
  }
}

@media (max-width: 640px) {
  .ui-kit.ui-header .footer-logo {
    max-width: 100px;
  }
}

.ui-kit.ui-header a > img {
  max-width: 210px;
}

@media screen and (min-width: 640px) {
  .ui-kit.ui-header a > img {
    max-width: 100%;
  }
}

.dark .ui-kit.ui-header a > img {
  -webkit-filter: brightness(0) invert();
  filter: brightness(0) invert();
}

.ui-kit.ui-header .left-header {
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  display: flex;
}

@media screen and (min-width: 640px) {
  .ui-kit.ui-header .left-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.ui-kit.ui-header .header-actions {
  z-index: 1000;
  align-items: center;
  gap: 1rem;
  display: flex;
  position: absolute;
  top: 60px;
}

.ui-kit.ui-header .header-actions.top-unset {
  top: unset;
}

@media screen and (min-width: 1025px) {
  .ui-kit.ui-header .header-actions {
    right: 50px;
  }
}

.ui-kit.ui-header .auth-cta {
  align-items: baseline;
  gap: 1rem;
  display: flex;
}

@media screen and (min-width: 1025px) {
  .ui-kit.ui-header .auth-cta {
    gap: 1.875rem;
  }
}

.ui-kit.ui-header .auth-cta a {
  color: #000;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #009e80;
  border-radius: 100px;
  padding: 16px 44px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1rem;
}

.dark .ui-kit.ui-header .auth-cta a {
  color: #fff;
}

.ui-kit.ui-header .header-sponsor-logo {
  max-width: 210px;
}

@media screen and (min-width: 1025px) {
  .ui-kit.ui-header .header-sponsor-logo {
    max-width: 305px;
  }
}

.ui-kit.ui-header.with-banner {
  padding-top: 50px !important;
}

.sidebar-layout .ui-kit.ui-header {
  padding: .625rem 1rem 1.5rem;
}

@media screen and (min-width: 1025px) {
  .sidebar-layout .ui-kit.ui-header {
    padding: 3.125rem 3.125rem 0 calc(3.125rem + 60px);
  }
}

@media screen and (min-width: 1280px) {
  .sidebar-layout .ui-kit.ui-header {
    padding: 3.125rem 3.125rem 0 calc(3.125rem + 60px);
  }
}

.ui-kit.ui-footer {
  color: #000;
  background-color: #fff;
  padding: 0 1rem 1rem;
  font-size: 1rem;
  font-weight: 300;
}

.ui-kit.ui-footer.footer-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.dark .ui-kit.ui-footer {
  color: #fff;
  background-color: #001923;
}

.dark .ui-kit.ui-footer.footer-transparent {
  background-color: rgba(0, 0, 0, 0);
}

@media screen and (min-width: 640px) {
  .ui-kit.ui-footer {
    padding: 0 3.125rem 1rem;
  }
}

.ui-kit.ui-footer .footer-row {
  border-top: solid #e3e3e3;
}

.dark .ui-kit.ui-footer .footer-row {
  border-top: solid #003d3d;
}

.dark .ui-kit.ui-footer .footer-row.row-white {
  border-top: solid #fff;
}

.ui-kit.ui-footer .footer-links li > a {
  color: #00725c;
}

.ui-kit.ui-footer .footer-links li > a:hover {
  text-decoration: underline;
}

.dark .ui-kit.ui-footer .footer-links li > a {
  color: #2ca8b1;
}

.ui-kit.ui-footer .footer-links h6, .ui-kit.ui-footer .footer-links li {
  margin-bottom: .5rem;
  font-weight: 300;
}

.sidebar-layout + .ui-kit.ui-footer {
  padding: .625rem 1rem 8rem;
}

@media screen and (min-width: 1025px) {
  .sidebar-layout + .ui-kit.ui-footer {
    padding: 3.125rem 3.125rem 0 calc(3.125rem + 60px);
  }
}

@media screen and (min-width: 1280px) {
  .sidebar-layout + .ui-kit.ui-footer {
    padding: 3.125rem 3.125rem 0 calc(3.125rem + 60px);
  }
}

.ui-kit.column-layout {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

.ui-kit.single-layout {
  min-height: calc(100vh - 400px);
  padding: 1.5rem 1rem;
}

@media screen and (min-width: 640px) {
  .ui-kit.single-layout {
    padding: 1.5rem 3.125rem;
  }
}

.ui-kit.double-layout {
  flex: 1;
  gap: 3rem;
  padding: 1.5rem 1rem;
  display: flex;
}

@media screen and (min-width: 640px) {
  .ui-kit.double-layout {
    padding: 1.5rem 3.125rem;
  }
}

@media screen and (min-width: 1280px) {
  .ui-kit.double-layout {
    gap: 6.25rem;
  }
}

.ui-kit.double-layout > section {
  flex: 1;
}

.ui-kit.double-layout > section:nth-of-type(2) {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: none;
}

@media screen and (min-width: 768px) {
  .ui-kit.double-layout > section:nth-of-type(2) {
    display: flex;
  }
}

.layout-padding {
  padding: 1.5rem 1rem;
}

@media screen and (min-width: 640px) {
  .layout-padding {
    padding: 1.5rem 3.125rem;
  }
}

.ui-kit.sidebar-layout {
  color: #000;
  min-height: calc(100vh - 350px);
  width: 100%;
  display: flex;
}

.ui-kit.sidebar-layout section {
  flex: 1;
}

.dark .ui-kit.sidebar-layout {
  color: #fff;
}

@media screen and (min-width: 1025px) {
  .ui-kit.sidebar-layout .aside-wrapper {
    z-index: 5000;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
  }
}

.ui-kit.sidebar-layout .aside-navigator {
  scrollbar-color: rgba(0, 0, 0, 0);
  scrollbar-width: none;
  z-index: 200;
  width: 100%;
  background-color: #222;
  border-top: 1px solid #615e5e;
  transition: width .4s;
  position: fixed;
  bottom: 0;
  left: 0;
}

.ui-kit.sidebar-layout .aside-navigator::-webkit-scrollbar {
  width: 0;
}

.dark .ui-kit.sidebar-layout .aside-navigator {
  background-color: #010314;
}

@media screen and (min-width: 1025px) {
  .ui-kit.sidebar-layout .aside-navigator {
    height: 100%;
    width: 100px;
    border-top: none;
    padding: 3.125rem .75rem;
    position: static;
    top: 0;
    overflow-y: scroll;
  }

  .ui-kit.sidebar-layout .aside-navigator .icon-label {
    display: inline !important;
  }
}

@media screen and (min-width: 1025px) and (min-width: 1025px) {
  .ui-kit.sidebar-layout .aside-navigator .sidebar-icons-container {
    gap: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (min-width: 1400px) {
  .ui-kit.sidebar-layout .aside-navigator .sidebar-icons-container {
    gap: 2.8rem;
  }
}

.ui-kit.sidebar-layout .main-content {
  margin-bottom: 1rem;
  padding: .625rem 1rem 1.5rem;
}

@media screen and (min-width: 1025px) {
  .ui-kit.sidebar-layout .main-content {
    padding: 3.125rem 3.125rem 0 calc(3.125rem + 60px);
  }
}

@media screen and (min-width: 1280px) {
  .ui-kit.sidebar-layout .main-content {
    padding: 3.125rem 3.125rem 0 calc(3.125rem + 60px);
  }
}

@media screen and (min-width: 1025px) {
  .ui-kit.sidebar-layout .main-content {
    margin-bottom: 1rem;
  }
}

.ui-kit.sidebar-layout .sidebar-icons-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 1025px) {
  .ui-kit.sidebar-layout .sidebar-icons-container {
    width: 100%;
    flex-direction: column;
    gap: 2.8rem;
  }
}

@media screen and (min-width: 1400px) {
  .ui-kit.sidebar-layout .sidebar-icons-container {
    gap: 2.8rem;
  }
}

.ui-kit.sidebar-layout .language-flag {
  opacity: 0;
  pointer-events: none;
  padding-right: 13px;
  transition: padding .4s, opacity .2s ease-out, -webkit-transform .2s ease-out, transform .2s ease-out;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}

.ui-kit.sidebar-layout .language-selector {
  display: flex;
}

.ui-kit.sidebar-layout .language-selector .language-link {
  cursor: pointer;
}

.ui-kit.sidebar-layout .language-selector .language-item {
  position: relative;
}

.ui-kit.sidebar-layout .language-selector .language-item:hover span {
  color: #fff;
}

.ui-kit.sidebar-layout .language-selector .language-item > .language-link:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 100px;
  transition: -webkit-transform .4s ease-out, transform .4s ease-out, background-color .4s ease-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: scaleX(.7);
  transform: scaleX(.7);
}

.ui-kit.sidebar-layout .language-selector .language-item:hover > .language-link {
  transition: background-color .4s;
}

.ui-kit.sidebar-layout .language-selector .language-item:hover > .language-link:before {
  background-color: rgba(255, 255, 255, .2);
  transition: background-color .4s;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.ui-kit.sidebar-layout .language-selector .language-item:hover .language-flag, .ui-kit.sidebar-layout .language-selector .language-item.active .language-flag, .ui-kit.sidebar-layout .language-selector .language-item:active .language-flag {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  padding: 0 13px;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@media screen and (min-width: 1025px) {
  .ui-kit.sidebar-layout .aside-linked, .ui-kit.sidebar-layout + .aside-linked {
    padding-left: calc(3.125rem + 100px) !important;
  }
}

.p-sidebar {
  padding: 3.125rem 1rem;
}

@media screen and (min-width: 768px) {
  .p-sidebar {
    padding: 3.125rem 2.125rem;
  }
}

.py-sidebar {
  padding: 3.125rem .75rem;
}

.w-sidebar {
  width: 100%;
}

@media screen and (min-width: 1025px) {
  .w-sidebar {
    width: -webkit-max-content;
    width: max-content;
    max-width: 500px;
  }
}

.sidebar-section {
  color: #fff;
  left: 0;
  top: unset;
  width: 100%;
  max-height: 100%;
  z-index: -1;
  background-color: #222;
  border-radius: 20px 20px 0 0;
  position: fixed;
  bottom: 0;
  overflow-y: auto;
}

.dark .sidebar-section {
  background-color: #010314;
}

@media screen and (min-width: 1025px) {
  .sidebar-section {
    width: auto;
    height: 100%;
    border-bottom: none;
    border-left: 1px solid #615e5e;
    border-radius: 0 20px 20px 0;
    top: 0;
    left: 100px;
  }
}

.sidebar-button {
  border-radius: 10px;
  padding: .625rem;
  transition: background-color .4s;
}

.sidebar-button:hover {
  background-color: rgba(255, 255, 255, .2);
}

.sidebar-button[aria-expanded="true"] {
  background-color: rgba(255, 255, 255, .1);
}

.sidebar-section.collapsing {
  -webkit-animation: .6s fade;
  animation: .6s fade;
}

.sidebar-item {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 100px;
  padding: .625rem .75rem;
  transition: background-color .4s;
}

.sidebar-item:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, .2);
}

@-webkit-keyframes fade {
  from {
    opacity: .5;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: .5;
  }

  to {
    opacity: 1;
  }
}

#index-page {
  color: #000;
}

.dark #index-page {
  color: #fff;
}

#index-page .products-container {
  flex-direction: column;
  gap: 6rem;
  margin-top: 2rem;
  display: flex;
}

@media screen and (min-width: 1280px) {
  #index-page .products-container {
    flex-direction: row;
  }
}

#index-page .meye-guide-card, #index-page .meye-gauge-card {
  background-color: #fff;
  border-radius: 10px;
  flex: 1;
  transition: -webkit-transform .6s, transform .6s;
  position: relative;
}

@media screen and (min-width: 1025px) {
  #index-page .meye-guide-card:hover, #index-page .meye-gauge-card:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  #index-page .meye-guide-card:hover > .product-card:not(.disabled) img:nth-of-type(1), #index-page .meye-gauge-card:hover > .product-card:not(.disabled) img:nth-of-type(1) {
    -webkit-transform: scale(1.1)rotate(-5deg);
    transform: scale(1.1)rotate(-5deg);
  }

  #index-page .meye-guide-card:hover > .product-card:not(.disabled) img:nth-of-type(2), #index-page .meye-gauge-card:hover > .product-card:not(.disabled) img:nth-of-type(2) {
    -webkit-transform: scale(1.1)translateX(-10px)rotate(5deg);
    transform: scale(1.1)translateX(-10px)rotate(5deg);
  }
}

#index-page .meye-guide-card > div, #index-page .meye-gauge-card > div {
  background-position: -3rem;
  background-repeat: no-repeat;
  background-size: 40rem;
}

.dark #index-page .meye-guide-card, .dark #index-page .meye-gauge-card {
  background-color: #001923;
  box-shadow: 0 0 15px rgba(255, 255, 255, .3);
}

@media screen and (min-width: 1025px) {
  #index-page .meye-guide-card img, #index-page .meye-gauge-card img {
    transition: -webkit-transform .6s ease-in-out, transform .6s ease-in-out;
  }
}

@media screen and (min-width: 768px) {
  #index-page .meye-guide-card > div {
    background-image: url("https://resources.ocumetra.com/images/backgrounds/purple_background_still.webp");
  }
}

#index-page .meye-guide-card img:nth-of-type(1) {
  width: 180px;
  top: -30px;
  left: 20px;
}

#index-page .meye-guide-card img:nth-of-type(2) {
  width: 250px;
  bottom: -80px;
  left: 100px;
  -webkit-transform: rotate(15deg);
  transform: rotate(15deg);
}

@media screen and (min-width: 768px) {
  #index-page .meye-gauge-card > div {
    background-image: url("https://resources.ocumetra.com/images/backgrounds/green_background_still.webp");
  }
}

#index-page .meye-gauge-card img:nth-of-type(1) {
  width: 100px;
  top: -30px;
  left: 20px;
}

#index-page .meye-gauge-card img:nth-of-type(2) {
  width: 130px;
  bottom: -30px;
  left: 120px;
  -webkit-transform: rotate(10deg);
  transform: rotate(10deg);
}

#index-page .subscription-cta {
  margin-bottom: 5rem;
}

#index-page .background-video {
  width: 50vw;
  height: 100%;
  z-index: -1;
  opacity: .7;
  -webkit-filter: brightness(1.5);
  filter: brightness(1.5);
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

.dark #index-page .background-video {
  opacity: 1;
  -webkit-filter: none;
  filter: none;
}

@media screen and (min-width: 768px) {
  #index-page .background-video {
    display: block;
  }
}

#index-page .gradient-fallback {
  width: 50vw;
  height: 100%;
  z-index: -1;
  display: none;
  position: absolute;
  right: 0;
}

.products-card {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, .05);
  border-radius: 12px;
  padding: 16px 8px 16px 16px;
  box-shadow: 0 6px 12px rgba(104, 104, 104, .08), 0 2px 6px rgba(199, 199, 199, .4);
}

@media screen and (min-width: 390px) {
  .products-card {
    padding: 24px 14px 22px 16px;
  }
}

@media screen and (min-width: 1025px) {
  .products-card {
    border-radius: 10px;
    padding: 16px 20px 11px 16px;
  }
}

.dark .products-card {
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  background-color: rgba(0, 42, 60, .337);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .08), 0 2px 6px rgba(9, 9, 9, .4);
}

.hover-card-container .hover-footer {
  display: none;
}

@media screen and (min-width: 1025px) {
  .hover-card-container .on-idle {
    visibility: visible;
    opacity: 1;
    order: 1;
  }

  .hover-card-container .on-hover {
    visibility: hidden;
    opacity: 0;
    order: 2;
  }

  .hover-card-container .hover-wrapper {
    max-width: 318px;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    display: grid;
  }

  .hover-card-container .hover-content {
    grid-area: 1 / 1 / 3 / 3;
    transition: opacity 1s;
  }

  .hover-card-container .hover-image {
    max-width: 300px;
    -webkit-transform: translate3d(-50%);
    transform: translate3d(-50%);
    transition: max-width .4s, -webkit-transform .4s, transform .4s, margin-bottom .4s;
    position: relative;
  }

  .hover-card-container .hover-offset {
    transition: -webkit-transform .4s, transform .4s;
    -webkit-transform: translateY(25%);
    transform: translateY(25%);
  }

  .hover-card-container .hover-footer {
    opacity: 0;
    transition: opacity .4s;
    display: block;
  }
}

@media screen and (min-width: 1025px) and (min-width: 1025px) {
  .hover-card-container .hover-footer {
    visibility: hidden;
  }
}

@media screen and (min-width: 1025px) {
  .hover-card-container:hover .on-idle {
    visibility: hidden;
    opacity: 0;
    order: 2;
  }

  .hover-card-container:hover .on-hover {
    visibility: visible;
    opacity: 1;
    order: 1;
  }

  .hover-card-container:hover .hover-image {
    max-width: 200px;
    -webkit-transform: translate3d(0%);
    transform: translate3d(0%);
    margin-bottom: 22px;
  }

  .hover-card-container:hover .hover-offset {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  .hover-card-container:hover .img-grid-overlap:nth-child(1) {
    z-index: 5;
    -webkit-transform: rotate(-20deg)translateY(40px);
    transform: rotate(-20deg)translateY(40px);
  }

  .hover-card-container:hover .img-grid-overlap:nth-child(2) {
    -webkit-transform: rotate(20deg)translate(40px, -40px);
    transform: rotate(20deg)translate(40px, -40px);
  }

  .hover-card-container:hover .hover-footer {
    visibility: visible;
    opacity: 1;
  }
}

.grid-overlap {
  grid-template-columns: 1fr;
  display: grid;
  position: relative;
  top: -13px;
}

.grid-overlap .img-grid-overlap {
  grid-area: 1 / 1 / 1 / 1;
  transition: -webkit-transform .4s, transform .4s;
}

.grid-overlap .img-grid-overlap:nth-child(1) {
  z-index: 5;
}

.grid-overlap .img-grid-overlap:nth-child(2) {
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
}

#register-page, #profile-page {
  color: #000;
}

.dark #register-page, .dark #profile-page {
  color: #fff;
}

#register-page .main-content select, #profile-page .main-content select {
  text-align: left;
  border-bottom: 1px solid #949494;
  font-size: .875rem;
}

.dark #register-page .main-content select, .dark #profile-page .main-content select {
  border-color: #c7c7c7;
}

#register-page .custom-field, #profile-page .custom-field {
  border-bottom: 1px solid #949494;
  align-items: baseline;
  gap: .5rem;
  display: flex;
}

#register-page .custom-field.contained, #profile-page .custom-field.contained {
  border: 1px solid #d4d7e5;
  border-radius: 8px;
  padding: 8px 16px;
}

#register-page .custom-field img, #profile-page .custom-field img {
  height: 18px;
  width: auto;
}

#register-page .custom-field select, #register-page .custom-field input, #profile-page .custom-field select, #profile-page .custom-field input {
  width: 100%;
  border: none;
  padding: 2.75px 0;
  font-size: 1rem;
}

#register-page .custom-field.no-margin, #profile-page .custom-field.no-margin {
  margin-bottom: 0;
}

#register-page .custom-field.error, #profile-page .custom-field.error {
  color: red;
  border-color: red;
}

#register-page .custom-field.error input, #register-page .custom-field.error select, #profile-page .custom-field.error input, #profile-page .custom-field.error select {
  border-color: red;
}

#register-page .custom-field.error input::-webkit-input-placeholder, #register-page .custom-field.error select::-webkit-input-placeholder, #profile-page .custom-field.error input::-webkit-input-placeholder, #profile-page .custom-field.error select::-webkit-input-placeholder {
  color: red;
}

#register-page .custom-field.error input::placeholder, #register-page .custom-field.error select::placeholder, #profile-page .custom-field.error input::placeholder, #profile-page .custom-field.error select::placeholder {
  color: red;
}

#register-page .custom-field.error select, #profile-page .custom-field.error select {
  color: red;
}

#register-page .current-email, #profile-page .current-email {
  align-items: center;
  gap: 1.3rem;
  margin-bottom: 3.25rem;
  display: inline-flex;
}

#register-page .current-email p, #profile-page .current-email p {
  margin: 0;
}

@media screen and (min-width: 1280px) {
  #register-page .help-container, #profile-page .help-container {
    min-height: 150px;
  }
}

.ui-kit.ui-header .header-actions.register-actions {
  padding-left: 20px;
  position: static;
}

.top-banner {
  z-index: 2000;
}

.top-banner .environment-container {
  height: 40px;
  background-color: #3a3af2;
  border-radius: 0 0 0 30px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.top-banner .environment-container p {
  color: #fff;
  font-weight: 700;
}

.auth-container {
  width: 100%;
  max-width: 1296px;
  margin: 0 auto;
}

#server-error-page, #reset-password-page {
  color: #000;
}

#server-error-page section, #reset-password-page section {
  align-items: center;
  display: flex;
}

#server-error-page h2, #reset-password-page h2 {
  font-size: 2.5rem;
  font-weight: 300;
}

.dark #server-error-page, .dark #reset-password-page {
  color: #fff;
}

#server-error-page {
  padding-top: 3rem;
}

#server-error-page .buttons-container {
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 3rem;
  display: flex;
}

@media screen and (min-width: 1025px) {
  #server-error-page .buttons-container {
    justify-content: flex-start;
    gap: 5rem;
  }
}

#reset-password-page .email-sent-message {
  font-size: .875rem;
}

#reset-password-page .email-sent-message > span {
  color: #00725c;
}

.dark #reset-password-page .email-sent-message > span {
  color: #01a998;
}

.dark .dark-gradient-body {
  background-color: #152b4b;
  background-image: linear-gradient(#152b4b, #010314);
  background-size: cover;
  background-attachment: fixed;
}

#meye-guide-page, #meye-gauge-page, #meye-score-page {
  position: relative;
}

#meye-guide-page .flex-field, #meye-gauge-page .flex-field, #meye-score-page .flex-field {
  flex-direction: column;
  flex: 1;
}

@media screen and (min-width: 390px) {
  #meye-guide-page .flex-field, #meye-gauge-page .flex-field, #meye-score-page .flex-field {
    flex-direction: row;
  }
}

#meye-guide-page .flex-field label, #meye-gauge-page .flex-field label, #meye-score-page .flex-field label {
  min-width: 110px;
  font-weight: 200;
}

#meye-guide-page .field-border, #meye-gauge-page .field-border, #meye-score-page .field-border {
  border-bottom: 1px solid #949494;
}

#meye-guide-page .field-border-error, #meye-gauge-page .field-border-error, #meye-score-page .field-border-error {
  border-color: red;
}

#meye-guide-page .field-border-error input::-webkit-input-placeholder, #meye-gauge-page .field-border-error input::-webkit-input-placeholder, #meye-score-page .field-border-error input::-webkit-input-placeholder {
  color: red;
}

#meye-guide-page .field-border-error input::placeholder, #meye-gauge-page .field-border-error input::placeholder, #meye-score-page .field-border-error input::placeholder {
  color: red;
}

#meye-guide-page .kr-container > .card-range, #meye-gauge-page .kr-container > .card-range, #meye-score-page .kr-container > .card-range {
  display: none;
}

#meye-guide-page .kr-container > .card-range.show, #meye-gauge-page .kr-container > .card-range.show, #meye-score-page .kr-container > .card-range.show {
  display: block;
}

#meye-guide-page #current-age, #meye-gauge-page #current-age, #meye-score-page #current-age {
  white-space: nowrap;
  color: #949494;
  overflow: auto;
}

#meye-guide-page #current-age, #meye-guide-page #current-age-months, #meye-gauge-page #current-age, #meye-gauge-page #current-age-months, #meye-score-page #current-age, #meye-score-page #current-age-months {
  display: none;
}

#meye-guide-page .axial-length-button, #meye-gauge-page .axial-length-button, #meye-score-page .axial-length-button {
  text-align: left;
  background-color: inherit;
  border: none;
  align-items: center;
  display: flex;
}

#meye-guide-page .axial-length-button .accordion-chevron-icon, #meye-gauge-page .axial-length-button .accordion-chevron-icon, #meye-score-page .axial-length-button .accordion-chevron-icon {
  margin-left: 15px;
}

#meye-guide-page .axial-length-button .accordion-chevron-icon svg, #meye-gauge-page .axial-length-button .accordion-chevron-icon svg, #meye-score-page .axial-length-button .accordion-chevron-icon svg {
  width: 20px;
}

#meye-guide-page .axial-length-container, #meye-gauge-page .axial-length-container, #meye-score-page .axial-length-container {
  border-radius: 0 0 20px 20px;
}

#meye-guide-page .axial-length-container button, #meye-gauge-page .axial-length-container button, #meye-score-page .axial-length-container button {
  background-color: inherit;
  border: none;
}

@media screen and (min-width: 1025px) {
  #meye-guide-page .axial-length-container, #meye-gauge-page .axial-length-container, #meye-score-page .axial-length-container {
    border-radius: 0 20px 20px 0;
  }
}

#meye-guide-page .axial-length-container .badge, #meye-gauge-page .axial-length-container .badge, #meye-score-page .axial-length-container .badge {
  color: #fff;
  background-color: #443ae1;
}

@media screen and (min-width: 640px) {
  #meye-guide-page .axial-range-field, #meye-gauge-page .axial-range-field, #meye-score-page .axial-range-field {
    flex: 1;
  }
}

#meye-guide-page .axial-length-item, #meye-gauge-page .axial-length-item, #meye-score-page .axial-length-item {
  flex: .7;
}

#meye-guide-page .card-wrapper, #meye-gauge-page .card-wrapper, #meye-score-page .card-wrapper {
  border-radius: 20px;
}

#meye-guide-page .patient-input-row, #meye-gauge-page .patient-input-row, #meye-score-page .patient-input-row {
  gap: 2rem;
}

@media screen and (min-width: 1280px) {
  #meye-guide-page .patient-input-row, #meye-gauge-page .patient-input-row, #meye-score-page .patient-input-row {
    gap: 3rem;
  }
}

#meye-guide-page svg .al-shape path, #meye-guide-page svg .ruler-shape text, #meye-gauge-page svg .al-shape path, #meye-gauge-page svg .ruler-shape text, #meye-score-page svg .al-shape path, #meye-score-page svg .ruler-shape text {
  fill: #000;
}

#meye-guide-page svg .al-shape text, #meye-gauge-page svg .al-shape text, #meye-score-page svg .al-shape text {
  font-weight: bold;
}

#meye-guide-page svg line, #meye-gauge-page svg line, #meye-score-page svg line {
  fill: none;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 1px;
}

#meye-guide-page svg.balance g.circle-untreated > text, #meye-guide-page svg.balance g.circle-treated > text, #meye-gauge-page svg.balance g.circle-untreated > text, #meye-gauge-page svg.balance g.circle-treated > text, #meye-score-page svg.balance g.circle-untreated > text, #meye-score-page svg.balance g.circle-treated > text {
  font-size: 3.4rem;
}

#meye-guide-page svg.balance g.transform-untreated, #meye-gauge-page svg.balance g.transform-untreated, #meye-score-page svg.balance g.transform-untreated {
  -webkit-transform: scale(1.5)translateX(-8px);
  transform: scale(1.5)translateX(-8px);
}

@media screen and (min-width: 390px) {
  #meye-guide-page svg.balance g.transform-untreated, #meye-gauge-page svg.balance g.transform-untreated, #meye-score-page svg.balance g.transform-untreated {
    -webkit-transform: scale(1.25)translateX(-8px);
    transform: scale(1.25)translateX(-8px);
  }
}

@media screen and (min-width: 640px) {
  #meye-guide-page svg.balance g.transform-untreated, #meye-gauge-page svg.balance g.transform-untreated, #meye-score-page svg.balance g.transform-untreated {
    -webkit-transform: none;
    transform: none;
  }
}

#meye-guide-page svg.balance g.transform-treated, #meye-gauge-page svg.balance g.transform-treated, #meye-score-page svg.balance g.transform-treated {
  -webkit-transform: scale(1.5)translateX(-210px);
  transform: scale(1.5)translateX(-210px);
}

@media screen and (min-width: 390px) {
  #meye-guide-page svg.balance g.transform-treated, #meye-gauge-page svg.balance g.transform-treated, #meye-score-page svg.balance g.transform-treated {
    -webkit-transform: scale(1.25)translateX(-120px);
    transform: scale(1.25)translateX(-120px);
  }
}

@media screen and (min-width: 640px) {
  #meye-guide-page svg.balance g.transform-treated, #meye-gauge-page svg.balance g.transform-treated, #meye-score-page svg.balance g.transform-treated {
    -webkit-transform: none;
    transform: none;
  }
}

#meye-guide-page .simulator-tooltip:nth-child(2n+1), #meye-gauge-page .simulator-tooltip:nth-child(2n+1), #meye-score-page .simulator-tooltip:nth-child(2n+1) {
  color: #fff;
  background-color: #222;
}

#meye-guide-page .simulator-images-container .handle, #meye-gauge-page .simulator-images-container .handle, #meye-score-page .simulator-images-container .handle {
  width: 30px;
  margin-left: -15px;
}

@media screen and (min-width: 768px) {
  #meye-guide-page .simulator-images-container .handle, #meye-gauge-page .simulator-images-container .handle, #meye-score-page .simulator-images-container .handle {
    width: 60px;
    margin-left: -30px;
  }
}

#meye-guide-page .simulator-images-container .handle .controller, #meye-gauge-page .simulator-images-container .handle .controller, #meye-score-page .simulator-images-container .handle .controller {
  width: 30px;
  height: 30px;
}

#meye-guide-page .simulator-images-container .handle .controller .controller-icon, #meye-gauge-page .simulator-images-container .handle .controller .controller-icon, #meye-score-page .simulator-images-container .handle .controller .controller-icon {
  width: 12px;
  aspect-ratio: 4 / 5;
  background-color: #fff;
  -webkit-mask-image: url("https://resources.ocumetra.com/images/icons/double_chevron.svg");
  mask-image: url("https://resources.ocumetra.com/images/icons/double_chevron.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

@media screen and (min-width: 768px) {
  #meye-guide-page .simulator-images-container .handle .controller .controller-icon, #meye-gauge-page .simulator-images-container .handle .controller .controller-icon, #meye-score-page .simulator-images-container .handle .controller .controller-icon {
    width: 25px;
  }
}

#meye-guide-page .simulator-images-container .handle .controller .control-arrow, #meye-gauge-page .simulator-images-container .handle .controller .control-arrow, #meye-score-page .simulator-images-container .handle .controller .control-arrow {
  display: none;
}

@media screen and (min-width: 768px) {
  #meye-guide-page .simulator-images-container .handle .controller, #meye-gauge-page .simulator-images-container .handle .controller, #meye-score-page .simulator-images-container .handle .controller {
    width: 60px;
    height: 60px;
  }
}

#meye-guide-page .simulator-images-container .handle .controller:before, #meye-gauge-page .simulator-images-container .handle .controller:before, #meye-score-page .simulator-images-container .handle .controller:before {
  margin-bottom: 12.5px;
  bottom: calc(50% + 2px);
}

@media screen and (min-width: 768px) {
  #meye-guide-page .simulator-images-container .handle .controller:before, #meye-gauge-page .simulator-images-container .handle .controller:before, #meye-score-page .simulator-images-container .handle .controller:before {
    margin-bottom: 25px;
    bottom: calc(50% + 3px);
  }
}

#meye-guide-page .simulator-images-container .handle .controller:after, #meye-gauge-page .simulator-images-container .handle .controller:after, #meye-score-page .simulator-images-container .handle .controller:after {
  margin-top: 12.5px;
  top: calc(50% + 2px);
}

@media screen and (min-width: 768px) {
  #meye-guide-page .simulator-images-container .handle .controller:after, #meye-gauge-page .simulator-images-container .handle .controller:after, #meye-score-page .simulator-images-container .handle .controller:after {
    margin-top: 25px;
    top: calc(50% + 3px);
  }
}

#meye-guide-page .sharing-options-item svg line, #meye-gauge-page .sharing-options-item svg line, #meye-score-page .sharing-options-item svg line {
  stroke: #fff;
}

@media screen and (min-width: 640px) {
  #meye-guide-page .header-actions, #meye-gauge-page .header-actions, #meye-score-page .header-actions {
    right: 16px;
  }
}

@media screen and (min-width: 1025px) {
  #meye-guide-page .header-actions, #meye-gauge-page .header-actions, #meye-score-page .header-actions {
    right: 50px;
  }
}

#meye-guide-page .meye-form.arc-range-input, #meye-gauge-page .meye-form.arc-range-input, #meye-score-page .meye-form.arc-range-input {
  min-width: 150px;
  max-width: 262px;
  height: auto;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  position: relative;
}

#meye-guide-page .meye-form.arc-range-input.arc-reverse, #meye-gauge-page .meye-form.arc-range-input.arc-reverse, #meye-score-page .meye-form.arc-range-input.arc-reverse {
  -webkit-transform: scale(-1);
  transform: scale(-1);
}

#meye-guide-page .meye-form.arc-range-input input[type="range"], #meye-gauge-page .meye-form.arc-range-input input[type="range"], #meye-score-page .meye-form.arc-range-input input[type="range"] {
  display: none;
}

@media screen and (min-width: 1025px) {
  #meye-guide-page .meye-form.arc-range-input, #meye-gauge-page .meye-form.arc-range-input, #meye-score-page .meye-form.arc-range-input {
    min-width: 180px;
  }
}

#meye-guide-page .meye-form.arc-range-input .arc-shape, #meye-gauge-page .meye-form.arc-range-input .arc-shape, #meye-score-page .meye-form.arc-range-input .arc-shape {
  width: 100%;
  aspect-ratio: 2 / 1;
  border: 3px solid #00725c;
  border-top: 0;
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
  position: relative;
  -webkit-transform: scaleX(1.05);
  transform: scaleX(1.05);
}

#meye-guide-page .meye-form.arc-range-input .arc-shape:before, #meye-gauge-page .meye-form.arc-range-input .arc-shape:before, #meye-score-page .meye-form.arc-range-input .arc-shape:before {
  content: "";
  z-index: -1;
  width: 13px;
  height: 3px;
  top: -1.5px;
  right: unset;
  bottom: unset;
  background-color: #00725c;
  border-radius: 13px;
  display: block;
  position: absolute;
  left: -8px;
}

#meye-guide-page .meye-form.arc-range-input .arc-shape:after, #meye-gauge-page .meye-form.arc-range-input .arc-shape:after, #meye-score-page .meye-form.arc-range-input .arc-shape:after {
  content: "";
  z-index: -1;
  width: 13px;
  height: 3px;
  top: -1.5px;
  right: -8px;
  bottom: unset;
  left: unset;
  background-color: #00725c;
  border-radius: 13px;
  display: block;
  position: absolute;
}

#meye-guide-page .meye-form.arc-range-input .arc-shape .arc-pointer, #meye-gauge-page .meye-form.arc-range-input .arc-shape .arc-pointer, #meye-score-page .meye-form.arc-range-input .arc-shape .arc-pointer {
  width: 100%;
  height: 200%;
  cursor: pointer;
  -webkit-clip-path: inset(50% 0 -20px);
  clip-path: inset(50% 0 -20px);
  border-radius: 50%;
  transition: -webkit-transform 30ms linear, transform 30ms linear;
  position: absolute;
  bottom: 0;
}

#meye-guide-page .meye-form.arc-range-input .arc-shape .arc-pointer:after, #meye-gauge-page .meye-form.arc-range-input .arc-shape .arc-pointer:after, #meye-score-page .meye-form.arc-range-input .arc-shape .arc-pointer:after {
  content: "";
  width: 13px;
  height: 13px;
  cursor: pointer;
  background-color: #00725c;
  border-radius: 50%;
  display: block;
  position: absolute;
  bottom: -8px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

#meye-guide-page .meye-form-arc-range-max-width, #meye-gauge-page .meye-form-arc-range-max-width, #meye-score-page .meye-form-arc-range-max-width {
  max-width: 262px;
  margin-left: 6px;
}

#meye-guide-page input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  margin: .625rem 0 10px;
}

#meye-guide-page input[type="range"]:focus {
  outline: none;
}

#meye-guide-page input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background-color: #d3d3d3;
  border: 0;
  border-radius: 6px;
}

#meye-guide-page input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #3a3aea;
  border: none;
  border-radius: 50%;
  outline: none;
  margin-top: -7px;
}

#meye-guide-page input[type="range"]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background-color: #d3d3d3;
  border: 0;
  border-radius: 6px;
}

#meye-guide-page input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #3a3aea;
  border: none;
  border-radius: 50%;
  outline: none;
  margin-top: -7px;
}

#meye-guide-page input[type="range"]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background-color: #d3d3d3;
  border: 0;
  border-radius: 6px;
}

#meye-guide-page input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: #3a3aea;
  border: none;
  border-radius: 50%;
  outline: none;
  margin-top: 0;
}

#meye-guide-page .diopter-label {
  width: 20px;
  height: 20px;
  background-color: #d3d3d3;
  border-radius: 50%;
  right: calc(50% - 30px);
}

@media screen and (min-width: 768px) {
  #meye-guide-page .diopter-label {
    right: calc(50% - 41.5px);
  }
}

#meye-guide-page .diopter-label.transition {
  transition: right .5s linear;
}

#meye-guide-page .meye-form.arc-range-input {
  min-width: 150px;
  max-width: 220px;
  height: auto;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  position: relative;
}

#meye-guide-page .meye-form.arc-range-input.arc-reverse {
  -webkit-transform: scale(-1);
  transform: scale(-1);
}

#meye-guide-page .meye-form.arc-range-input input[type="range"] {
  display: none;
}

@media screen and (min-width: 1025px) {
  #meye-guide-page .meye-form.arc-range-input {
    min-width: 180px;
  }
}

#meye-guide-page .meye-form.arc-range-input .arc-shape {
  width: 100%;
  aspect-ratio: 2 / 1;
  border: 3px solid #d3d3d3;
  border-top: 0;
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
  position: relative;
  -webkit-transform: scaleX(1.05);
  transform: scaleX(1.05);
}

#meye-guide-page .meye-form.arc-range-input .arc-shape:before {
  content: "";
  z-index: -1;
  width: 13px;
  height: 3px;
  top: -1.5px;
  right: unset;
  bottom: unset;
  background-color: #d3d3d3;
  border-radius: 13px;
  display: block;
  position: absolute;
  left: -8px;
}

#meye-guide-page .meye-form.arc-range-input .arc-shape:after {
  content: "";
  z-index: -1;
  width: 13px;
  height: 3px;
  top: -1.5px;
  right: -8px;
  bottom: unset;
  left: unset;
  background-color: #d3d3d3;
  border-radius: 13px;
  display: block;
  position: absolute;
}

#meye-guide-page .meye-form.arc-range-input .arc-shape .arc-pointer {
  width: 100%;
  height: 200%;
  cursor: pointer;
  -webkit-clip-path: inset(50% 0 -20px);
  clip-path: inset(50% 0 -20px);
  border-radius: 50%;
  transition: -webkit-transform 30ms linear, transform 30ms linear;
  position: absolute;
  bottom: 0;
}

#meye-guide-page .meye-form.arc-range-input .arc-shape .arc-pointer:after {
  content: "";
  width: 13px;
  height: 13px;
  cursor: pointer;
  background-color: #3a3aea;
  border-radius: 50%;
  display: block;
  position: absolute;
  bottom: -8px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

#meye-guide-page .meye-form-arc-range-max-width {
  max-width: 220px;
  margin-left: 6px;
}

.icon-line-blue line {
  fill: none;
  stroke: #e6e6ff;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.dark .icon-line-blue line {
  stroke: #1d3b5e;
}

.report-link, .report-link:hover {
  color: #0303d1;
}

.dark .report-link, .dark .report-link:hover {
  color: #c5eef1;
}

.report-logo {
  max-width: 206px;
}

@media screen and (min-width: 768px) {
  .report-logo {
    max-width: 363px;
  }
}

.report-data-container {
  border: 1px solid #e1e1e1;
}

.dark .report-data-container {
  border: 1px solid #dde8e9;
}

.accordion-chevron-icon {
  transition: -webkit-transform .4s, transform .4s;
}

.accordion-button {
  border: 3px solid #e6e6ff;
  transition: all .4s;
}

.dark .accordion-button {
  border: 3px solid rgba(0, 0, 0, 0);
  box-shadow: 0 0 10px #458ee1;
}

.accordion-button:hover {
  background-color: #fff;
  border: 3px solid rgba(0, 0, 0, 0);
  box-shadow: 0 0 10px 10px rgba(230, 230, 255, .5);
}

.dark .accordion-button:hover {
  background-color: #dde8e9;
  border: 3px solid #2b517c;
  box-shadow: 0 0 10px #458ee1;
}

.dark .accordion-button:hover .accordion-button-hover {
  color: #010314;
}

.dark .accordion-button:hover .dark-stroke-white:not(.white-hover) {
  stroke: #010314;
}

.accordion-button:hover svg .active-stroke {
  stroke: #0303d1;
  transition: stroke .4s;
}

.dark .accordion-button:hover svg .active-stroke {
  stroke: #152b4b;
}

.accordion-button:hover svg .active-fill {
  fill: #0303d1;
  transition: fill .4s;
}

.dark .accordion-button:hover svg .active-fill {
  fill: #152b4b;
}

.accordion-button[aria-expanded="true"] {
  background-color: #fff;
  border: 3px solid rgba(0, 0, 0, 0);
}

.dark .accordion-button[aria-expanded="true"] {
  background-color: #001923;
}

.accordion-button[aria-expanded="true"] .accordion-chevron-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordion-button[aria-expanded="true"] svg .active-stroke {
  stroke: #0303d1;
  transition: stroke .4s;
}

.dark .accordion-button[aria-expanded="true"] svg .active-stroke {
  stroke: #80b5f5;
}

.accordion-button[aria-expanded="true"] svg .active-fill {
  fill: #0303d1;
  transition: fill .4s;
}

.dark .accordion-button[aria-expanded="true"] svg .active-fill {
  fill: #80b5f5;
}

.show-wrapper {
  border: 2px solid #e6e6ff;
  box-shadow: 0 0 10px 10px rgba(230, 230, 255, .5);
}

.dark .show-wrapper {
  box-shadow: 0 0 10px rgba(230, 230, 255, .5);
}

.show-wrapper .accordion-button {
  background-color: #fff;
  border: 3px solid rgba(0, 0, 0, 0);
}

.dark .show-wrapper .accordion-button {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border: 3px solid rgba(0, 0, 0, 0);
}

.dark .show-wrapper .accordion-button:hover {
  background-color: #dde8e9;
}

.show-wrapper .accordion-button[aria-expanded="true"] .accordion-chevron-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.show-wrapper .accordion-button svg .active-stroke {
  stroke: #0303d1;
  transition: stroke .4s;
}

.dark .show-wrapper .accordion-button svg .active-stroke {
  stroke: #80b5f5;
}

.show-wrapper .accordion-button svg .active-fill {
  fill: #0303d1;
  transition: fill .4s;
}

.dark .show-wrapper .accordion-button svg .active-fill {
  fill: #80b5f5;
}

.show-wrapper .accordion-button:hover svg .active-stroke {
  stroke: #0303d1;
  transition: stroke .4s;
}

.dark .show-wrapper .accordion-button:hover svg .active-stroke {
  stroke: #152b4b;
}

.show-wrapper .accordion-button:hover svg .active-fill {
  fill: #0303d1;
  transition: fill .4s;
}

.dark .show-wrapper .accordion-button:hover svg .active-fill {
  fill: #152b4b;
}

.dark .show-wrapper {
  border: 2px solid #dde8e9;
}

.eye-chart-button.active {
  display: none;
}

.report-footer {
  border-top: 2px solid #dedede;
}

.report-wrapper {
  background-color: #fff;
  border-radius: 25px;
}

.dark .report-wrapper {
  background-color: rgba(0, 0, 0, 0);
}

.report-section {
  background-color: rgba(0, 0, 0, 0);
  padding: 10px;
}

@media screen and (min-width: 1025px) {
  .report-section {
    max-width: 1070px;
    width: min(1070px, 85%);
    margin-left: auto;
    margin-right: auto;
    padding: 30px 0;
  }
}

.report-text-section {
  background-color: rgba(0, 0, 0, 0);
  padding: 5px 24px 24px;
}

@media screen and (min-width: 1025px) {
  .report-text-section {
    margin-left: auto;
    margin-right: auto;
    padding: 5px 80px;
  }
}

.bg-purple-navy {
  background-color: #e6e6ff;
}

.dark .bg-purple-navy {
  background-color: #1d3b5e;
}

.bg-dark-gray {
  background-color: #efefef;
}

.grey-border {
  box-shadow: 0 10px 10px rgba(0, 0, 0, .1);
}

.dark .grey-border {
  box-shadow: 0 0 5px #2ca8b1;
}

.gray-border-bottom {
  border-bottom: 2px solid #f5f5f5;
}

.dark .gray-border-bottom {
  border-bottom: 2px solid #c5eef1;
}

.u-border {
  border: 2px solid #e6e6ff;
  border-top: none;
  border-radius: 0 0 15px 15px;
}

.dark .u-border {
  border: 2px solid #dde8e9;
}

@media screen and (min-width: 768px) {
  .u-border {
    border: 2px solid #e6e6ff;
    border-left: none;
    border-radius: 0 15px 15px 0;
  }

  .dark .u-border {
    border: 2px solid #dde8e9;
    border-left: none;
  }
}

.border-left {
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 15px;
}

@media screen and (min-width: 1025px) {
  .border-left {
    border-right: 0;
  }
}

.dark .border-left {
  border: 2px solid #dde8e9;
}

@media screen and (min-width: 1025px) {
  .dark .border-left {
    border-right: 0;
  }
}

.report-toggle-container {
  max-width: 381px;
  border-radius: 100px;
}

.dark .report-toggle-container {
  box-shadow: 0 0 10px 1px #035577;
}

@media screen and (min-width: 768px) {
  .report-toggle-container {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1025px) {
  .report-image-container {
    max-width: 50%;
  }
}

#meye-gauge-page input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  margin: 2rem 0 15px;
}

#meye-gauge-page input[type="range"]:focus {
  outline: none;
}

#meye-gauge-page input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background-color: #d3d3d3;
  border: 0;
  border-radius: 6px;
}

#meye-gauge-page input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #cc2e78;
  border: none;
  border-radius: 50%;
  outline: none;
  margin-top: -7px;
}

#meye-gauge-page input[type="range"]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background-color: #d3d3d3;
  border: 0;
  border-radius: 6px;
}

#meye-gauge-page input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #cc2e78;
  border: none;
  border-radius: 50%;
  outline: none;
  margin-top: -7px;
}

#meye-gauge-page input[type="range"]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background-color: #d3d3d3;
  border: 0;
  border-radius: 6px;
}

#meye-gauge-page input[type="range"]::-ms-thumb {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: #cc2e78;
  border: none;
  border-radius: 50%;
  outline: none;
  margin-top: 0;
}

#meye-gauge-page .diopter-label {
  width: 20px;
  height: 20px;
  background-color: #d3d3d3;
  border-radius: 50%;
  right: calc(50% - 30px);
}

@media screen and (min-width: 768px) {
  #meye-gauge-page .diopter-label {
    right: calc(50% - 41.5px);
  }
}

#meye-gauge-page .diopter-label.transition {
  transition: right .5s linear;
}

#meye-gauge-page .meye-form.arc-range-input {
  min-width: 150px;
  max-width: 262px;
  height: auto;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  position: relative;
}

#meye-gauge-page .meye-form.arc-range-input.arc-reverse {
  -webkit-transform: scale(-1);
  transform: scale(-1);
}

#meye-gauge-page .meye-form.arc-range-input input[type="range"] {
  display: none;
}

@media screen and (min-width: 1025px) {
  #meye-gauge-page .meye-form.arc-range-input {
    min-width: 180px;
  }
}

#meye-gauge-page .meye-form.arc-range-input .arc-shape {
  width: 100%;
  aspect-ratio: 2 / 1;
  border: 6px solid #d3d3d3;
  border-top: 0;
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
  position: relative;
  -webkit-transform: scaleX(1.05);
  transform: scaleX(1.05);
}

#meye-gauge-page .meye-form.arc-range-input .arc-shape:before {
  content: "";
  z-index: -1;
  width: 13px;
  height: 3px;
  top: -3px;
  right: unset;
  bottom: unset;
  background-color: #d3d3d3;
  border-radius: 13px;
  display: block;
  position: absolute;
  left: -9.5px;
}

#meye-gauge-page .meye-form.arc-range-input .arc-shape:after {
  content: "";
  z-index: -1;
  width: 13px;
  height: 3px;
  top: -3px;
  right: -9.5px;
  bottom: unset;
  left: unset;
  background-color: #d3d3d3;
  border-radius: 13px;
  display: block;
  position: absolute;
}

#meye-gauge-page .meye-form.arc-range-input .arc-shape .arc-pointer {
  width: 100%;
  height: 200%;
  cursor: pointer;
  -webkit-clip-path: inset(50% 0 -20px);
  clip-path: inset(50% 0 -20px);
  border-radius: 50%;
  transition: -webkit-transform 30ms linear, transform 30ms linear;
  position: absolute;
  bottom: 0;
}

#meye-gauge-page .meye-form.arc-range-input .arc-shape .arc-pointer:after {
  content: "";
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: #cc2e78;
  border-radius: 50%;
  display: block;
  position: absolute;
  bottom: -13px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

#meye-gauge-page .meye-form-arc-range-max-width {
  max-width: 262px;
  margin-left: 12px;
}

.flex-divider {
  height: 50px;
  background-color: #bababa;
  flex: 0 0 2px;
}

.drop-shadow-blue {
  -webkit-backdrop-filter: blur();
  backdrop-filter: blur();
  -webkit-filter: drop-shadow(0 0 35px rgba(119, 143, 239, .757));
  filter: drop-shadow(0 0 35px rgba(119, 143, 239, .757));
}

.qrcode-container {
  width: 100%;
  max-width: 288px;
  border-radius: inherit;
}

.qrcode-image {
  width: 100%;
  max-width: 24em;
  border-radius: 15px;
}

.gray-popover {
  max-width: calc(50% - 20px);
  background-color: #f5f5f5;
  font-family: Montserrat, Arial, sans-serif;
}

.popover .popover-arrow:after {
  border-color: #f5f5f5 rgba(0, 0, 0, 0) !important;
}

.popover-left.popover .popover-arrow:after {
  border-color: rgba(0, 0, 0, 0) #f5f5f5 !important;
}

.pill-badge {
  background-color: #e6e6ff;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.pill-badge-height {
  height: calc(100% - 30px);
}

.chart-wrapper {
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
}

.report-chart-container svg {
  width: 100%;
  height: auto;
  border-radius: inherit;
}

#collapsible-content {
  width: 100%;
  margin-bottom: 20px;
}

.card-collapsible {
  background-color: var(--color-bg-card);
  border-radius: 19px;
}

.box-container {
  color: var(--color-title);
  width: 100%;
  justify-content: space-between;
  margin-top: 15px;
  padding: 15px;
  display: flex;
}

.card-collapse {
  padding-bottom: 25px;
  display: none;
}

.header-box {
  align-items: center;
}

.img-header {
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin: 0 3%;
  display: flex;
}

.img-header img, .txt-header {
  width: 100%;
}

.txt-header h2 {
  text-transform: uppercase;
  margin: 0;
  font-size: 25px;
  font-weight: bold;
}

.txt-header p {
  max-height: 100px;
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 34px;
  font-weight: 200;
  overflow: hidden;
}

.disclaimer-options {
  color: var(--color-title);
  margin: 0 60px 30px;
}

.disclaimer-options h2 {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 300;
}

.disclaimer-options p {
  font-size: 25px;
  font-weight: 300;
}

.decimal-list-item {
  list-style-type: decimal;
}

.disclaimer-options .decimal-list-item-text {
  font-size: 1.1rem;
}

:-webkit-any(ul, ol).list-pl-0 {
  padding-left: 0;
}

:is(ul, ol).list-pl-0 {
  padding-left: 0;
}

:-webkit-any(ul, ol).list-pl-1 {
  padding-left: 1rem;
}

:is(ul, ol).list-pl-1 {
  padding-left: 1rem;
}

.card-option-collapsible {
  width: 100%;
  cursor: pointer;
  background-color: #e0e0e0;
  background-position: top;
}

.ad-card-control .ad-list {
  height: auto;
}

.margin-container {
  height: 200px;
}

.info-container {
  background-color: #f5f5f5;
  border-radius: 20px 20px 0 0;
}

.info-container .mco-title {
  color: #0303d1;
  padding: 10px 20px;
  font-size: 20px;
}

@media screen and (min-width: 768px) {
  .info-container .mco-title {
    font-size: 25px;
  }
}

.read-more-container {
  color: var(--color-txt);
  border-top: 1px solid rgba(59, 80, 101, .416);
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
}

@media screen and (min-width: 768px) {
  .read-more-container {
    font-size: 20px;
  }
}

.read-more-container .arrow {
  border-color: var(--color-txt);
  margin: 10px;
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

.dark .read-more-container .arrow.arrow-dark {
  border-color: #fff;
}

.available-treatments-container .show .read-more-container .arrow {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.available-treatments-container .read-less {
  display: none;
}

.available-treatments-container .show .read-less {
  display: block;
}

.available-treatments-container .show .read-more {
  display: none;
}

.body-read-more {
  max-height: 0;
  transition: all .5s;
  overflow: hidden;
}

.available-treatments-container .show.body-read-more {
  max-height: 5000px;
}

.text-container {
  color: var(--color-txt);
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  padding: 20px;
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  .text-container {
    font-size: 16px;
  }
}

.pros-cons-container {
  display: flex;
}

.pros-cons-container .pros-cons:first-child {
  margin-right: 100px;
}

.text-container p {
  margin-bottom: 20px;
}

.pros-cons ul {
  margin-bottom: 20px;
  padding: 0;
}

.pros-cons li {
  list-style-type: none;
}

.pros-cons span {
  margin-bottom: 15px;
  display: block;
}

.mco-option-img {
  background-color: #f5f5f5;
  background-position: top;
  background-repeat: no-repeat;
}

@media screen and (min-width: 1920px) {
  .mco-option-img {
    background-size: 100%, 200px, cover;
  }
}

#mco1-option1-img {
  background-image: url("https://resources.ocumetra.com/images/pictures/spectacles.webp");
}

#mco1-option2-img {
  background-image: url("https://resources.ocumetra.com/images/pictures/daytime_contact_lenses.webp");
}

#mco1-option3-img {
  background-image: url("https://resources.ocumetra.com/images/pictures/overnight_contact_lenses.webp");
}

#mco1-option4-img {
  background-image: url("https://resources.ocumetra.com/images/pictures/atropine_drops.webp");
}

#mco2-option1-img {
  background-image: url("https://resources.ocumetra.com/images/pictures/outdoor_time.webp");
}

#mco2-option2-img {
  background-image: url("https://resources.ocumetra.com/images/pictures/optimise_near_work.webp");
}

#mco2-option3-img {
  background-image: url("https://resources.ocumetra.com/images/pictures/limit_screen_time.webp");
}

#mco2-option4-img {
  background-image: url("https://resources.ocumetra.com/images/pictures/regular_eye_checks.webp");
}

.guidelines-screen-time-container h2 {
  color: #3a5067;
  margin-bottom: 20px;
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .guidelines-screen-time-container h2 {
    font-size: 23px;
  }
}

.guidelines-screen-time-container a {
  color: #549a85;
}

.children-age-container {
  justify-content: space-between;
  margin-top: 20px;
  display: flex;
}

.children-age-container span {
  text-transform: uppercase;
  color: #3a5067;
  font-size: 14px;
  font-weight: bold;
  display: block;
}

@media screen and (min-width: 768px) {
  .children-age-container span {
    font-size: 15px;
  }
}

.children-age-container h2 {
  color: var(--color-title-card);
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .children-age-container h2 {
    font-size: min(15px + .8vw, 25px);
  }
}

.children-age-container div:first-child {
  width: 25%;
}

.children-age-container div:nth-child(2) {
  width: 70%;
}

.pill-purple-subtle {
  background-color: #e6e6ff;
}

.pill-purple {
  background-color: #a7a7ff;
}

.morph-card-label {
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}

@media screen and (min-width: 768px) {
  .morph-card-label {
    width: 100%;
    max-width: 54%;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    border-radius: 100px;
    margin-bottom: -50px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media screen and (min-width: 1025px) {
  .morph-card-label {
    max-width: 64%;
  }
}

.morph-border {
  border-bottom: 2px solid #bababa;
}

@media screen and (min-width: 768px) {
  .morph-border {
    border-bottom: 0;
    border-right: 2px solid #bababa;
  }
}

.estimate-container {
  position: relative;
}

.floating-tip {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .25);
}

.axial-tooltip {
  color: #fff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, .7);
}

.subtle-label {
  color: #000;
  background-color: #e6e6ff;
}

.sharing-options {
  z-index: 999;
  cursor: pointer;
  background-color: #222;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: fixed;
  bottom: 20px;
  right: 0;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .sharing-options {
    border-radius: 15px;
  }

  .sharing-options input[name="sharing-options-input"] {
    visibility: hidden;
    height: 0;
  }

  .sharing-options input[name="sharing-options-input"]:checked + .sharing-options-list {
    max-height: 100vh;
    padding-top: 30px;
  }

  .sharing-options input[name="sharing-options-input"]:checked + .sharing-options-list > .sharing-options-trigger {
    max-height: 0;
    visibility: hidden;
    opacity: 0;
    padding: 0;
  }

  .sharing-options input[name="sharing-options-input"]:checked + .sharing-options-list > .sharing-options-item {
    height: 33px;
    opacity: 1;
    z-index: auto;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) {
  .sharing-options {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    -webkit-transform: translate(50px);
    transform: translate(50px);
  }
}

.sidebar-layout .sharing-options {
  bottom: 85px;
  right: 10px;
}

.sharing-options-trigger {
  max-height: 100vh;
  visibility: visible;
  opacity: 1;
  padding: 15px;
  transition: opacity .2s;
}

.icon-wrapper.icon-visible:hover .icon-link-tooltip {
  visibility: visible;
}

.sharing-options-item {
  height: 0;
  opacity: 0;
  z-index: -1;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  transition: opacity .4s, height .4s, margin-bottom .4s;
  display: flex;
  position: relative;
}

@media screen and (min-width: 1025px) {
  .sharing-options-item {
    height: auto;
    z-index: auto;
    opacity: 1;
    transition: background-color .4s;
  }

  .sharing-options-item:hover {
    background-color: #4b4b4b;
  }

  .dark .sharing-options-item:hover {
    background-color: #2a537e;
  }
}

.sharing-options-item.last-option-item {
  transition: opacity .2s;
}

.sharing-options-list {
  max-height: 200px;
  transition: max-height .4s;
}

@media screen and (min-width: 1025px) {
  .sharing-options-list {
    max-height: unset;
  }
}

.report-help-container {
  max-width: 450px;
}

@media screen and (min-width: 1025px) {
  .report-help-container {
    max-width: 1070px;
  }
}

.disabled-measured {
  -webkit-filter: grayscale();
  filter: grayscale();
}

.disabled-estimated {
  -webkit-filter: grayscale();
  filter: grayscale();
  pointer-events: none;
}

.meye-tool-logo {
  width: 220px;
}

@media screen and (min-width: 768px) {
  .meye-tool-logo {
    width: auto;
  }
}

.container-dob {
  visibility: hidden;
  z-index: 5;
  min-height: 40px;
}

.dark .neon-shadow {
  box-shadow: 0 0 5px #2ca8b1;
}

.dark .centile-chart-dark svg use {
  fill: #fff;
}

.dark .centile-chart-dark svg g#patch_1 > path, .dark .centile-chart-dark svg g#patch_2 > path {
  fill: rgba(0, 0, 0, 0) !important;
}

.dark .centile-chart-dark svg g#patch_3 > path, .dark .centile-chart-dark svg g#patch_4 > path {
  stroke: #fff !important;
}

.qr-modal-dialog {
  max-width: 500px;
}

.eye-toggle-basis {
  flex-basis: 385px;
}

.chart-border {
  border: 2px solid #e6e6ff;
}

.dark .chart-border {
  border: 2px solid #dde8e9;
}

.report-section-container {
  max-width: 1070px;
}

.centile-chart-container > svg {
  width: 100%;
  height: auto;
  border-radius: inherit;
}

.accordion-item-body.collapsing {
  transition: none;
  display: none;
}

.invalid-dob {
  margin-top: -13.5px;
}

.report-button-container {
  max-width: 300px;
}

.fs-eye-data {
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  .fs-eye-data {
    font-size: 20px;
  }
}

.report-disclaimer-text {
  font-size: 12px;
}

@media screen and (min-width: 768px) {
  .report-disclaimer-text {
    font-size: 16px;
  }
}

.fs-eye-title {
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  .fs-eye-title {
    font-size: 22px;
  }
}

@media screen and (min-width: 1025px) {
  .fs-eye-data {
    font-size: 16px;
  }

  .fs-eye-title {
    font-size: 18px;
  }

  .report-disclaimer-text {
    font-size: 12px;
  }
}

@media screen and (min-width: 1280px) {
  .fs-eye-data {
    font-size: 20px;
  }

  .fs-eye-title {
    font-size: 22px;
  }

  .report-disclaimer-text {
    font-size: 16px;
  }
}

.report-button-height {
  height: 100%;
  max-height: 70px;
}

@media screen and (max-width: 768px) and (min-width: 0) {
  .score-table-border {
    border-bottom: 1px solid #e2e5f1;
  }
}

@media screen and (min-width: 768px) {
  .score-table-border-md {
    border-bottom: 1px solid #e2e5f1;
  }

  .score-cell-sm {
    max-width: 16%;
  }

  .score-cell-md {
    max-width: 24%;
  }

  .score-cell-lg {
    max-width: 28%;
  }
}

.score-chart-logo {
  max-width: 84px;
}

@media screen and (min-width: 1280px) {
  .score-chart-logo {
    max-width: 129px;
  }
}

.dark .dark-gradient-body {
  background-color: #152b4b;
  background-image: linear-gradient(#152b4b, #010314);
  background-size: cover;
  background-attachment: fixed;
}

.login-header {
  padding-top: 35%;
}

@media screen and (min-width: 390px) {
  .login-header {
    padding-top: 20px;
  }
}

@media screen and (min-width: 1025px) {
  .login-header {
    padding-top: 0;
  }
}

.login-container {
  max-width: 1294px;
  padding: 0 20px;
}

@media screen and (min-width: 1025px) {
  .login-container {
    min-height: calc(100vh - 200px);
  }
}

.graphic-eye {
  position: absolute;
  top: 0;
}

@media screen and (min-width: 1025px) {
  .graphic-eye {
    position: fixed;
    top: 50%;
  }
}

.spin-loop-animation {
  width: 100%;
  -webkit-animation: 8s linear infinite spin-loop-mobile;
  animation: 8s linear infinite spin-loop-mobile;
}

@media screen and (min-width: 1025px) {
  .spin-loop-animation {
    width: auto;
    -webkit-animation: 8s linear infinite spin-loop;
    animation: 8s linear infinite spin-loop;
  }
}

.mobile-img-screen {
  -webkit-animation: 3s ease-in-out infinite floating-screen;
  animation: 3s ease-in-out infinite floating-screen;
}

@media screen and (min-width: 1294px) {
  .login-container {
    padding: 0;
  }
}

@-webkit-keyframes floating-screen {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes floating-screen {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes spin-loop-mobile {
  0% {
    -webkit-transform: translate(-50%)rotate(0);
    transform: translate(-50%)rotate(0);
  }

  100% {
    -webkit-transform: translate(-50%)rotate(360deg);
    transform: translate(-50%)rotate(360deg);
  }
}

@keyframes spin-loop-mobile {
  0% {
    -webkit-transform: translate(-50%)rotate(0);
    transform: translate(-50%)rotate(0);
  }

  100% {
    -webkit-transform: translate(-50%)rotate(360deg);
    transform: translate(-50%)rotate(360deg);
  }
}

@-webkit-keyframes spin-loop {
  0% {
    -webkit-transform: translate(-50%, -50%)rotate(0);
    transform: translate(-50%, -50%)rotate(0);
  }

  100% {
    -webkit-transform: translate(-50%, -50%)rotate(360deg);
    transform: translate(-50%, -50%)rotate(360deg);
  }
}

@keyframes spin-loop {
  0% {
    -webkit-transform: translate(-50%, -50%)rotate(0);
    transform: translate(-50%, -50%)rotate(0);
  }

  100% {
    -webkit-transform: translate(-50%, -50%)rotate(360deg);
    transform: translate(-50%, -50%)rotate(360deg);
  }
}

.flDebugPanelContentParent {
  overflow: auto !important;
}

.preload-transitions * {
  transition: none !important;
}

.linked-field label {
  min-width: 100px;
}

.linked-field input {
  border: none;
  border-bottom: 1px solid gray;
  outline: none;
}

body {
  min-height: 100vh;
  color: #000;
  background-color: #fff;
}

.dark body {
  color: #fff;
  background-color: #001923;
}

.no-style-dropdown select {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}

.no-style-dropdown {
  position: relative;
}

.no-style-dropdown:after {
  content: "⌄";
  color: #949494;
  display: inline-block;
  position: absolute;
  top: 40%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.top-input-container {
  flex-basis: 70px;
  position: relative;
}

.plus-sign {
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 12px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ui-kit.heading-1 {
  margin-bottom: 3.6rem;
  font-size: 1.5rem;
  font-weight: 300;
}

@media screen and (min-width: 640px) {
  .ui-kit.heading-1 {
    font-size: 2.5rem;
  }
}

.ui-kit.heading-2 {
  margin-bottom: 3.6rem;
  font-size: 1rem;
  font-weight: 300;
}

.ui-kit.heading-2.heading-border {
  border-bottom: 2px solid #f5f5f5;
  padding-bottom: 1.875rem;
}

.dark .ui-kit.heading-2.heading-border {
  border-color: #949494;
}

@media screen and (min-width: 640px) {
  .ui-kit.heading-2 {
    font-size: 1.5rem;
  }
}

.ui-kit.heading-3 {
  color: #949494;
  margin-bottom: 3.52rem;
  font-size: 1.25rem;
  font-weight: 300;
}

.dark .ui-kit.heading-3 {
  color: #c7c7c7;
}

.featured-text {
  color: #01a998;
  margin-bottom: 3.6rem;
  font-weight: 300;
}

@media screen and (min-width: 1920px) {
  .featured-text {
    margin-left: 20rem;
  }
}

.ui-kit.margin-b-3 {
  margin-bottom: 3.6rem;
}

.ui-kit.flex-center {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.flex {
  display: flex;
}

.flex-0-8 {
  flex: .8;
}

.flex-1 {
  flex: 1;
}

.flex-1-5 {
  flex: 1.5;
}

.flex-2 {
  flex: 2;
}

@media screen and (min-width: 1025px) {
  .flex-lg-default {
    flex: 0 auto;
  }
}

@media screen and (min-width: 1400px) {
  .flex-lxl-unset {
    flex: unset;
  }
}

.font-light {
  font-weight: 200;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

@media screen and (min-width: 1025px) {
  .font-lg-medium {
    font-weight: 500;
  }
}

.underline {
  text-decoration: underline;
}

.text-sm {
  font-size: .875rem;
}

.text-right {
  text-align: right;
}

.text-gray {
  color: #5a5a5a;
}

.anchor-hover:hover {
  color: #00725c;
  text-decoration: underline;
}

.basis-515 {
  flex-basis: 515px;
}

.w-s100 {
  width: 100%;
}

@media screen and (min-width: 640px) {
  .w-sm-s50 {
    width: 50%;
  }

  .w-sm-auto {
    width: auto;
  }
}

@media screen and (min-width: 1025px) {
  .w-lg-100 {
    width: 100%;
  }
}

.w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media screen and (min-width: 1025px) {
  .w-lg-fit {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media screen and (min-width: 1280px) {
  .w-xl-30 {
    width: 33.3333%;
  }
}

@media screen and (min-width: 768px) {
  .w-md-90 {
    width: 90%;
  }
}

@media screen and (min-width: 1025px) {
  .max-w-lg-575 {
    max-width: 575px;
  }
}

.max-w-620 {
  max-width: 620px;
}

.max-h-150 {
  max-height: 150px;
}

.indication-flex {
  flex: 1 0 50px;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-15 {
  border-radius: 15px;
}

.rounded-b-15 {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.rounded-16 {
  border-radius: 16px;
}

.rounded-20 {
  border-radius: 20px;
}

.rounded-25 {
  border-radius: 25px;
}

.rounded-t-15 {
  border-radius: 15px 15px 0 0;
}

@media screen and (min-width: 1025px) {
  .rounded-lg-15 {
    border-radius: 15px;
  }
}

@media screen and (min-width: 768px) {
  .rounded-l-md-15 {
    border-radius: 15px 0 0 15px;
  }
}

.box-shadow-lg {
  box-shadow: 0 0 .5rem rgba(0, 0, 0, .075);
}

.flip-y {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.cursor-pointer {
  cursor: pointer;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.tools-spinner {
  z-index: 50;
  width: calc(100% - 30px);
  border-radius: 20px;
  gap: 2rem;
  padding: 5rem 2rem;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) {
  .tools-spinner {
    width: auto;
    max-width: 741px;
    padding: 4.375rem 6.25rem;
  }
}

.theme-spinner {
  width: 75px;
  height: 75px;
  border: 10px solid #000;
  border-right-color: rgba(0, 0, 0, 0);
}

.spinner-blue-500 {
  border-color: #0303d1 rgba(0, 0, 0, 0) #0303d1 #0303d1;
}

.dark .spinner-blue-500 {
  border-color: #3a3aea rgba(0, 0, 0, 0) #3a3aea #3a3aea;
}

.spinner-purple-500 {
  border-color: #6e37bd rgba(0, 0, 0, 0) #6e37bd #6e37bd;
}

.dark .spinner-purple-500 {
  border-color: #7120ce rgba(0, 0, 0, 0) #7120ce #7120ce;
}

.submit-container {
  justify-content: center;
  display: flex;
}

.submit-container button:disabled {
  color: #fff;
  pointer-events: none;
  background-color: #bababa;
  border-color: #fff;
}

.d-dark-none {
  display: block;
}

.dark .d-dark-none, .d-dark-block {
  display: none;
}

.dark .d-dark-block {
  display: block;
}

.dark .dark-stroke-white {
  stroke: #fff;
}

.dark .dark-stroke-navy {
  stroke: #1d3b5e;
}

.dark .dark-fill {
  fill: #1d3b5e;
}

.fill-blue-50 {
  fill: rgba(0, 0, 0, 0);
}

.dark .fill-blue-350 {
  fill: #3a3af2;
}

.dark .dark-fill-navy-blue-300 {
  fill: #2b77bd;
}

.dark .dark-fill-blue {
  fill: #005298;
}

.dark .dark-fill-light-blue {
  fill: #2ca8b1;
}

.dark .dark-fill-black, .light-text-fill {
  fill: #000;
}

.dark .dark-text-fill {
  fill: #c5eef1;
}

.dark .dark-text-fill-white {
  fill: #fff;
}

@media screen and (min-width: 640px) {
  .w-md-max-content {
    width: -webkit-max-content;
    width: max-content;
  }
}

.border-blue {
  border: 2px solid #e6e6ff;
}

.dark .border-blue {
  border: 2px solid #2b77bd;
}

@media screen and (min-width: 1280px) {
  .border-xl-blue {
    border: 2px solid #e6e6ff;
  }

  .dark .border-xl-blue {
    border: 2px solid #2b77bd;
  }
}

.border-b-subtle {
  border-bottom: 1px solid rgba(255, 255, 255, .3);
}

.border-purple {
  border: 2px solid #e6e6ff;
}

.dark .border-purple {
  border: 2px solid #2ca8b1;
}

@media screen and (min-width: 1025px) {
  .border-lg-purple {
    border: 2px solid #e6e6ff;
  }

  .dark .border-lg-purple {
    border: 2px solid #2ca8b1;
  }
}

.border-responsive {
  border-top: 2px solid #bababa;
}

@media screen and (min-width: 1025px) {
  .border-responsive {
    border-top: none;
    border-left: 2px solid #bababa;
  }
}

.border-top-mobile {
  border-top: 1px solid #bababa;
}

@media screen and (min-width: 1025px) {
  .border-top-mobile {
    border-top: none;
  }
}

.black-popover .popover-arrow:after {
  border-color: rgba(0, 0, 0, 0) !important;
}

.black-popover {
  min-width: 200px;
  --bs-popover-max-width: auto;
  --bs-popover-border-color: transparent;
  --bs-popover-body-padding-x: 0;
  --bs-popover-body-padding-y: 0;
  --bs-popover-body-color: #fff;
  --bs-popover-bg: #222;
}

.dark .black-popover {
  --bs-popover-bg: #012c3c;
}

.dot-simulator-container .collapsing {
  transition: none;
}

.dot-simulator-container .accordion-button[aria-expanded="true"], .dot-simulator-container .dot-lens-button.active {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #7120ce;
}

.list-disc {
  list-style-type: disc;
}

.icon-svg-container {
  max-width: 24px;
}

@media screen and (min-width: 768px) {
  .icon-svg-container {
    max-width: 35px;
  }
}

.icon-svg-container svg {
  width: 100%;
}

.icon-svg-container-sm {
  max-width: 15px;
}

@media screen and (min-width: 768px) {
  .icon-svg-container-sm {
    max-width: 24px;
  }
}

.icon-svg-container-sm svg {
  width: 100%;
}

.icon-container {
  max-height: 35px;
  max-width: 35px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: background-color .4s;
  display: flex;
}

.icon-container:hover {
  background-color: #dcdcdc;
}

.icon-container:hover .hover-path {
  fill: #fff;
}

.transition-fill {
  transition: fill .4s;
}

.icon-effect:hover {
  box-shadow: 0 2.5px 2.5px rgba(0, 0, 0, .25);
}

.icon-effect:hover .hover-info-icon {
  fill: #0000ad;
}

.copied-text {
  color: rgba(255, 255, 255, .5);
}

.hover-blue-350:hover {
  color: #3a3af2;
}

.hover-blue:hover {
  color: #0303d1;
  text-decoration: underline;
}

.dark .hover-blue:hover {
  color: #2ca8b1;
}

.mz-1 {
  z-index: -1;
}

.z-10 {
  z-index: 10;
}

.flex-centered {
  justify-content: center;
  align-items: center;
}

.text-placeholder::-webkit-input-placeholder {
  color: #c1c1c1;
}

.text-placeholder::placeholder {
  color: #c1c1c1;
}

.border-y {
  border-top: 1px solid #949494;
  border-bottom: 1px solid #949494;
}

.border-b {
  border-bottom: 1px solid #949494;
}

.border-dark-gray-450 {
  border-color: #615e5e;
}

.rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.expand-rotate .accordion-chevron-icon svg {
  transition: -webkit-transform .4s, transform .4s;
}

.expand-rotate .display-more {
  display: block;
}

.expand-rotate .display-less {
  display: none;
}

.expand-rotate .read-container {
  opacity: 1;
  transition: opacity .4s .2s;
  display: inline-flex;
}

.expand-rotate[aria-expanded="true"] .display-more {
  display: none;
}

.expand-rotate[aria-expanded="true"] .display-less {
  display: block;
}

.expand-rotate[aria-expanded="true"] .read-container {
  visibility: hidden;
  opacity: 0;
  display: none;
}

@media screen and (min-width: 1025px) {
  .expand-rotate[aria-expanded="true"] .read-container {
    visibility: visible;
    opacity: 1;
    display: inline-flex;
  }
}

@media screen and (min-width: 768px) {
  .tab-content .active-md {
    display: block;
  }
}

.dot-index-indicator {
  background-color: #d9d9d9;
  transition: background-color .4s;
}

.dot-index-indicator.active {
  background-color: rgba(6, 6, 6, .5);
}

@media screen and (max-width: 768px) and (min-width: 0) {
  .score-scroll-snap {
    scroll-snap-type: x mandatory;
    scrollbar-color: rgba(0, 0, 0, 0);
    scrollbar-width: none;
    gap: 17px;
  }

  .score-scroll-snap::-webkit-scrollbar {
    width: 0;
  }
}

@media screen and (min-width: 768px) {
  .score-scroll-snap {
    max-height: 150px;
    display: block;
  }
}

@media screen and (min-width: 1280px) {
  .score-scroll-snap {
    max-height: 210px;
  }
}

@media screen and (max-width: 768px) and (min-width: 0) {
  .score-snap-child {
    scroll-snap-stop: always;
    scroll-snap-align: center;
  }
}

.date-padding {
  padding: 5px;
}

@media screen and (min-width: 768px) {
  .date-padding {
    padding: 5px 5px 5px 10px;
  }
}

@media (min-width: 768px) {
  .score-snap-child:nth-of-type(1) .label-highlight {
    background-color: #f6f1fd;
  }

  .score-snap-child:nth-of-type(1) .report-link {
    visibility: hidden;
  }

  .radius-left {
    border-radius: 50px 0 0 50px;
  }

  .radius-right {
    border-radius: 0 50px 50px 0;
  }
}

.table-snap-container {
  border: 1px solid #e7e7e7;
}

@media screen and (max-width: 768px) and (min-width: 0) {
  .table-snap-container.responsive {
    max-width: calc(100vw - 32px);
    overflow: hidden;
  }
}

.input-readonly {
  pointer-events: none;
  color: #000;
  border: none;
  outline: none;
  padding: 0;
  font-size: 12px;
}

.input-readonly.bg-arrow {
  background-image: none;
  padding: 15px 0;
}

@media screen and (min-width: 1400px) {
  .input-readonly {
    font-size: 18px;
  }
}

.dark .input-readonly {
  color: #fff;
}

.meye-date-picker {
  color: rgba(0, 0, 0, .2);
  min-height: 35px;
  cursor: text;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  position: relative;
}

.dark .meye-date-picker::-webkit-input-placeholder {
  color: rgba(255, 255, 255, .3);
}

.dark .meye-date-picker::placeholder {
  color: rgba(255, 255, 255, .3);
}

.dark .meye-date-picker {
  color: rgba(255, 255, 255, .3);
}

.row-display .calendar-icon {
  display: none;
}

.row-display .meye-date-picker {
  width: 100%;
}

.row-display .row-input {
  border: none;
  padding: 0;
}

.row-display .date-container {
  margin-left: 0;
}

.row-input {
  width: 100%;
  color: rgba(0, 0, 0, 0);
  background-color: #fcfcfc;
  border: 1px solid #bababa;
  border-radius: 100px;
  padding: 5px 15px;
  display: block;
}

.row-input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, .2);
}

.row-input::placeholder {
  color: rgba(0, 0, 0, .2);
}

.dark .row-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, .3);
}

.dark .row-input::placeholder {
  color: rgba(255, 255, 255, .3);
}

.dark .row-input {
  background-color: rgba(255, 255, 255, .15);
}

.meye-date-picker.input-error {
  color: red !important;
}

.focused-inputs .row-input, .focused-inputs .meye-date-picker {
  color: #000;
  background-color: rgba(0, 0, 0, 0);
}

.focused-inputs .row-input.input-error, .focused-inputs .meye-date-picker.input-error {
  color: red;
}

.dark .focused-inputs .row-input, .dark .focused-inputs .meye-date-picker {
  color: #fff;
}

.disabled-gray:disabled {
  background-color: #bababa;
}

.entering {
  -webkit-animation: .4s entering;
  animation: .4s entering;
}

.exiting {
  -webkit-animation: .4s exiting;
  animation: .4s exiting;
}

@-webkit-keyframes entering {
  0% {
    opacity: 0;
    max-height: 0;
  }

  100% {
    opacity: 1;
    max-height: 1000px;
  }
}

@keyframes entering {
  0% {
    opacity: 0;
    max-height: 0;
  }

  100% {
    opacity: 1;
    max-height: 1000px;
  }
}

@-webkit-keyframes exiting {
  0% {
    opacity: 1;
    max-height: 1000px;
  }

  100% {
    opacity: 1;
    max-height: 0;
  }
}

@keyframes exiting {
  0% {
    opacity: 1;
    max-height: 1000px;
  }

  100% {
    opacity: 1;
    max-height: 0;
  }
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -webkit-appearance: textfield;
  appearance: textfield;
}

.circle-button {
  width: 25px;
  height: 25px;
}

.circle-button svg {
  -webkit-transform: scale(.7);
  transform: scale(.7);
}

@media screen and (min-width: 1512px) {
  .circle-button {
    width: 35px;
    height: 35px;
  }

  .circle-button svg {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.border-1 {
  border-width: 1px;
}

.border-solid {
  border: 1px solid;
}

.border-style-solid {
  border-style: solid;
}

.text-break-all {
  word-break: break-all;
}

.truncate-text {
  display: none;
}

@media screen and (min-width: 1280px) {
  .truncate-text {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.expand .truncate-text {
  margin-bottom: 24px;
  display: block;
}

@media screen and (min-width: 1280px) {
  .expand .truncate-text {
    -webkit-line-clamp: unset;
    margin-bottom: 0;
  }

  .read-figure {
    height: 80px;
  }

  .expand .figure-card-divider {
    flex-direction: column;
  }

  .expand .read-figure {
    height: auto;
    padding-right: 0;
  }
}

.expand .figure-card-title {
  margin-bottom: 50px;
}

@media screen and (min-width: 1280px) {
  .expand .figure-card-title {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0;
  }
}

.expand .text-more, .text-less {
  display: none;
}

.expand .text-less {
  display: block;
}

.expand .expand-rotate .accordion-chevron-icon svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.circle-background, .circle-progress {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 3.5em;
  font-weight: bold;
}

.circle-text-small {
  font-size: 3em;
  font-weight: bold;
}

svg.progress-circle-svg {
  max-width: 200px;
}

@media screen and (min-width: 1280px) {
  svg.progress-circle-svg {
    max-width: unset;
  }
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.score-date-picker {
  position: absolute;
  top: 50%;
  right: -5px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.treatments-toggle {
  visibility: visible;
  max-height: 1000vh;
  opacity: 1;
  transition: max-height .4s, visibility .4s, opacity .8s;
}

.treatments-toggle.toggle {
  visibility: hidden;
  max-height: 0;
  opacity: 0;
}

.bg-arrow {
  background-image: url("../../chevron.737e2454.svg");
  background-position: calc(100% - 20px);
  background-repeat: no-repeat;
}

.object-fit-cover {
  object-fit: cover;
}

.hover-none:hover {
  color: initial;
}

.invert-color-scheme {
  color-scheme: dark;
}

.dark .invert-color-scheme {
  color-scheme: light;
}

.tabular-nums {
  font-variant-numeric: tabular-nums;
}

.grid-layers {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  display: grid;
}

.grid-layers > .grid-layer {
  grid-area: 1 / 1 / 2 / 2;
}

.repeater-container {
  border: 1px solid #e7e7e7;
}

.repeater-container.error, .repeater-container.extra-error {
  border: 1px solid red;
}

.blink-border {
  -webkit-animation: 1s blink;
  animation: 1s blink;
}

@-webkit-keyframes blink {
  50% {
    border-color: rgba(137, 125, 152, .71);
  }
}

@keyframes blink {
  50% {
    border-color: rgba(137, 125, 152, .71);
  }
}

.rounded-left-s20 {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.loader-dots {
  -webkit-animation: 2s cubic-bezier(.6, 0, .4, 1) infinite spin;
  animation: 2s cubic-bezier(.6, 0, .4, 1) infinite spin;
}

@supports (font: -apple-system-body) and ((-webkit-appearance: none)) {
  input[type="date"] {
    padding-right: 2.5rem;
  }
}

/*# sourceMappingURL=base.css.map */
