@use "../theme/colors" as *;
@use "../theme/breakpoints" as *;
@use "../theme/mixins" as *;

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.ui-kit.ui-theme-toggle {
    position: relative;

    button {
        background-color: $transparent;
        border: none;
        outline: 0;
    }

    &.loading {
        pointer-events: none;

        .ui-kit-toggle-label .slider .circle {
            background-color: transparent;
            border: 3px solid white;
            border-right: 3px solid transparent;
            animation: spin 1.5s linear infinite;
        }
    }

    .ui-kit-toggle-label {
        display: inline-block;
        cursor: pointer;

        .slider {
            display: inline-flex;
            align-items: center;
            gap: 0.8rem;
            background-color: $light-gray-200;
            border-radius: 1rem;
            padding: 2px 10px;
            transition: rotate, 0.4s;

            .circle {
                background-color: white;
                width: 16px;
                height: 16px;
                border-radius: 50%;
            }
            @include dark {
                background-color: $teal-700;
            }
        }

        input {
            opacity: 0;
            width: 0;
            height: 0;

            &[value="light"] + .slider {
                transform: rotate(180deg);

                img {
                    transform: rotate(180deg);
                }
            }
        }

        .toggle-tooltip {
            font-size: 0.6rem;
            font-weight: 300;
            text-align: center;
            min-width: 150px;
            position: absolute;
            display: none;
            left: 50%;
            transform: translateX(-65%);
            color: $gray-400;
            top: 40px;
            background-color: $white;
            border-radius: 20px;

            @include dark {
                background-color: $dark-blue-400;
                color: $light-gray-100;
            }
        }
    }

    &:hover .toggle-tooltip {
        display: inline;
    }
}

.ui-kit.ui-radio-toggle {
    width: 100%;

    @include breakpoint($lg) {
        width: auto;
    }

    label p {
        @include breakpoint($lxl) {
            font-size: 1.125rem;
        }
    }

    &.small {
        .animated-buttons-container label {
            padding: 0.4rem 0;
        }
    }

    &.font-light {
        .animated-buttons-container label p {
            font-weight: 200;
        }
    }

    &.white-label {
        .animated-buttons-container {
            background-color: $white;
        }
    }

    &.toggle-blue {
        .animated-buttons-container .button-decoration {
            background-color: $blue-400;
        }
    }

    &.toggle-blue-350 {
        .animated-buttons-container .button-decoration {
            background-color: $blue-350;

            @include dark {
                border: 1.5px solid $navy-blue-600;
                background-color: $white-900;
            }
        }
    }

    &.toggle-purple {
        .animated-buttons-container .button-decoration {
            background-color: $purple-500;
        }
    }

    &.toggle-dark-blue {
        .animated-buttons-container .button-decoration {
            background-color: $blue-500;

            @include dark {
                border: 1.5px solid $light-blue-400;
                background-color: $teal-675;
            }
        }
    }

    &.toggle-pink {
        .animated-buttons-container .button-decoration {
            background-color: $pink-500;
        }
    }

    &.toggle-dark-blue-300 {
        .animated-buttons-container {
            @include dark {
                background-color: $dark-blue-300;
            }
        }
    }

    .animated-buttons-container {
        display: flex;
        background-color: $light-gray-100;
        border-radius: 27px;
        width: 100%;
        position: relative;
        border: 1px solid transparent;

        @include dark {
            background-color: $black-900;
        }

        label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            z-index: 20;
            width: 100%;
            padding: 0.4rem 0;
            height: inherit;
            cursor: pointer;

            p {
                flex: 1;
                text-align: center;
                position: relative;
                z-index: 30;
                transition: color 0.4s;
            }
        }

        input {
            opacity: 0;
            width: 0;
            height: 0;
            border: 0;
        }

        input:checked + p,
        .toggle-active {
            color: $white;
            font-weight: 600;
        }

        &.color-dark {
            input:checked + p,
            .toggle-active {
                @include dark {
                    color: $dark-gray-800;
                }
            }
        }

        .button-decoration {
            display: none;
            position: absolute;
            background-color: $primary;
            border-radius: 27px;
            top: 0;
            left: 0;
            height: 100%;
            width: 50%;
            z-index: 0;
            transition: left 0.4s;

            &.first-selected {
                display: block;
                left: 0;
                box-shadow: 3px 0 6px rgb(0 0 0 / 25%);
            }

            &.second-selected {
                display: block;
                left: 50%;
                box-shadow: -3px 0 6px rgb(0 0 0 / 25%);
            }
        }
    }
}

.radio-toggle-container {
    min-width: 150px;

    @include breakpoint($lg) {
        min-width: 100px;
    }

    @include breakpoint($xl) {
        min-width: 150px;
    }
}
