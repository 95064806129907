@use "../theme/colors" as *;
@use "../theme/breakpoints" as *;
@use "../theme/mixins" as *;

#index-page {
    color: $black;

    @include dark {
        color: $white;
    }

    .products-container {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 6rem;

        @include breakpoint($xl) {
            flex-direction: row;
        }
    }

    .meye-guide-card,
    .meye-gauge-card {
        background-color: $white;
        position: relative;
        border-radius: 10px;
        flex: 1;
        transition: transform 0.6s;

        &:hover {
            @include breakpoint($lg) {
                transform: scale(1.05);
            }
        }

        &:hover > .product-card:not(.disabled) {
            @include breakpoint($lg) {
                img:nth-of-type(1) {
                    transform: scale(1.1) rotate(-5deg);
                }

                img:nth-of-type(2) {
                    transform: scale(1.1) translateX(-10px) rotate(5deg);
                }
            }
        }

        & > div {
            background-size: 40rem;
            background-position: -3rem;
            background-repeat: no-repeat;
        }

        @include dark {
            background-color: $dark-blue-400;
            box-shadow: 0 0 15px $dark-gray-300;
        }

        img {
            @include breakpoint($lg) {
                transition: transform 0.6s ease-in-out;
            }
        }
    }

    .meye-guide-card {
        & > div {
            @include breakpoint($md) {
                background-image: url("https://resources.ocumetra.com/images/backgrounds/purple_background_still.webp");
            }
        }

        img:nth-of-type(1) {
            width: 180px;
            top: -30px;
            left: 20px;
        }

        img:nth-of-type(2) {
            width: 250px;
            left: 100px;
            bottom: -80px;
            transform: rotate(15deg);
        }
    }

    .meye-gauge-card {
        & > div {
            @include breakpoint($md) {
                background-image: url("https://resources.ocumetra.com/images/backgrounds/green_background_still.webp");
            }
        }

        img:nth-of-type(1) {
            width: 100px;
            top: -30px;
            left: 20px;
        }

        img:nth-of-type(2) {
            width: 130px;
            left: 120px;
            bottom: -30px;
            transform: rotate(10deg);
        }
    }

    .subscription-cta {
        margin-bottom: 5rem;
    }

    .background-video {
        width: 50vw;
        height: 100%;
        display: none;
        position: absolute;
        right: 0;
        z-index: -1;
        top: 0;
        opacity: 0.7;
        filter: brightness(1.5);

        @include dark {
            opacity: 1;
            filter: none;
        }

        @include breakpoint($md) {
            display: block;
        }
    }

    .gradient-fallback {
        display: none;
        position: absolute;
        width: 50vw;
        height: 100%;
        right: 0;
        z-index: -1;
    }
}

.products-card {
    padding: 16px 8px 16px 16px;
    border-radius: 12px;
    box-shadow: 0 6px 12px #68686814, 0 2px 6px #c7c7c766;
    background-color: rgba(255 255 255 / 5%);
    backdrop-filter: blur(4px);

    @include breakpoint($xs) {
        padding: 24px 14px 22px 16px;
    }

    @include breakpoint($lg) {
        padding: 16px 20px 11px 16px;
        border-radius: 10px;
    }

    @include dark {
        background-color: #002a3c56;
        box-shadow: 0 6px 12px #00000014, 0 2px 6px #09090966;
        backdrop-filter: blur(12px);
    }
}

.hover-card-container {
    .hover-footer {
        display: none;
    }

    @include breakpoint($lg) {
        .on-idle {
            visibility: visible;
            opacity: 1;
            order: 1;
        }

        .on-hover {
            visibility: hidden;
            opacity: 0;
            order: 2;
        }

        .hover-wrapper {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            max-width: 318px;
        }

        .hover-content {
            transition: opacity 1s;
            grid-row: 1 / 3;
            grid-column: 1 /3;
        }

        .hover-image {
            max-width: 300px;
            position: relative;
            transform: translate3d(-50%);
            transition: max-width 0.4s, transform 0.4s, margin-bottom 0.4s;
        }

        .hover-offset {
            transform: translateY(25%);
            transition: transform 0.4s;
        }

        .hover-footer {
            display: block;
            opacity: 0;
            transition: opacity 0.4s;

            @include breakpoint($lg) {
                visibility: hidden;
            }
        }

        &:hover {
            .on-idle {
                visibility: hidden;
                opacity: 0;
                order: 2;
            }

            .on-hover {
                visibility: visible;
                opacity: 1;
                order: 1;
            }

            .hover-image {
                max-width: 200px;
                transform: translate3d(0%);
                margin-bottom: 22px;
            }

            .hover-offset {
                transform: translateY(0);
            }

            .img-grid-overlap {
                &:nth-child(1) {
                    z-index: 5;
                    transform: rotate(-20deg) translateY(40px);
                }

                &:nth-child(2) {
                    transform: rotate(20deg) translate(40px, -40px);
                }
            }

            .hover-footer {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

.grid-overlap {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    top: -13px;

    .img-grid-overlap {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
        transition: transform 0.4s ease;

        &:nth-child(1) {
            z-index: 5;
        }

        &:nth-child(2) {
            transform: translateX(50px);
        }
    }
}
