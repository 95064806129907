@use "sass:map";
@use "../theme/colors" as *;
@use "../theme/mixins" as *;
@use "../theme/breakpoints.scss" as *;

@function button-theme(
    $light-bg-color,
    $light-color,
    $dark-bg-color,
    $dark-color
) {
    @return (
        light-bg-color: $light-bg-color,
        light-color: $light-color,
        dark-bg-color: $dark-bg-color,
        dark-color: $dark-color
    );
}

$solid-button-themes: (
    primary: (
        base: button-theme($primary, $white, $primary, $white),
        cover: button-theme($secondary, $white, $tertiary, $white),
    ),
    blue-250: (
        base: button-theme($blue-250, $white, $light-blue-400, $white),
        cover: button-theme($blue-800, $white, $teal-675, $white),
    ),
    blue-500: (
        base: button-theme($blue-500, $white, $blue-500, $white),
        cover: button-theme($blue-400, $white, $blue-400, $white),
    ),
    blue-400: (
        base: button-theme($blue-400, $white, $blue-400, $white),
        cover: button-theme($blue-500, $white, $blue-500, $white),
    ),
    pink-500: (
        base: button-theme($pink-500, $white, $pink-500, $white),
        cover: button-theme($pink-400, $white, $pink-400, $white),
    ),
    navy-300: (
        base: button-theme($blue-250, $white, $navy-blue-300, $white),
        cover: button-theme($blue-800, $white, $navy-blue-400, $white),
    ),
    purple-400: (
        base: button-theme($purple-400, $white, $purple-400, $white),
        cover: button-theme($purple-500, $white, $purple-500, $white),
    ),
    persian-green: (
        base: button-theme($persian-green, $white, $persian-green, $white),
        cover: button-theme($primary, $white, $primary, $white),
    ),
);
$outlined-button-themes: (
    primary: (
        base: button-theme($primary, $primary, $primary, $tertiary),
        cover: button-theme($primary, $white, $primary, $white),
    ),
    blue-250: (
        base: button-theme($blue-250, $blue-250, $light-blue-400, $white),
        cover: button-theme($blue-250, $white, $light-blue-400, $white),
    ),
    blue-500: (
        base: button-theme($blue-500, $black, $light-blue-400, $white),
        cover: button-theme($blue-500, $white, $light-blue-400, $white),
    ),
    pink-400: (
        base: button-theme($pink-400, $pink-400, $pink-400, $pink-400),
        cover: button-theme($pink-400, $white, $pink-400, $white),
    ),
    purple-500: (
        base: button-theme($purple-500, $black, $purple-500, $white),
        cover: button-theme($purple-500, $white, $purple-500, $white),
    ),
);
$mixed-button-themes: (
    blue-white: (
        base: button-theme($blue-250, $blue-250, $white-900, $dark-teal-500),
        cover: button-theme($blue-250, $white-900, $white-900, $dark-teal-500),
    ),
);

@mixin create-solid-button-theme($name, $button-base, $button-cover) {
    &.solid-#{$name} {
        background-color: map.get($button-base, "light-bg-color");
        color: map.get($button-base, "light-color");
        border: none;

        &:disabled {
            background-color: $gray-350;
            color: white;
            border-color: white;
            pointer-events: none;
        }

        @include dark {
            background-color: map.get($button-base, "dark-bg-color");
            color: map.get($button-base, "dark-color");
        }

        &.not-cover {
            transition: background-color 0.4s;

            &:hover {
                background-color: map.get($button-cover, "light-bg-color");
                color: map.get($button-cover, "light-color");

                @include dark {
                    background-color: map.get($button-cover, "dark-bg-color");
                    color: map.get($button-cover, "dark-color");
                }
            }
        }

        &:hover .button-cover {
            width: 100%;
            background-color: map.get($button-cover, "light-bg-color");
            color: map.get($button-cover, "light-color");

            @include dark {
                background-color: map.get($button-cover, "dark-bg-color");
                color: map.get($button-cover, "dark-color");
            }
        }
    }
}

@mixin create-outlined-button-theme($name, $button-base, $button-cover) {
    &.outline-#{$name} {
        background-color: transparent;
        backdrop-filter: blur(100px);
        border: 1px solid map.get($button-base, "light-bg-color");
        color: map.get($button-base, "light-color");
        transition: background-color 0.4s;

        @include breakpoint($xs) {
            border: 2px solid map.get($button-base, "light-bg-color");
        }

        @include dark {
            border: 2px solid map.get($button-base, "dark-bg-color");
            color: map.get($button-base, "dark-color");
        }

        &:disabled {
            background-color: transparent;
            color: $gray-350;
            border-color: $gray-350;
            pointer-events: none;
        }

        &:hover {
            color: map.get($button-cover, "light-color");
            background-color: map.get($button-cover, "light-bg-color");

            @include dark {
                background-color: map.get($button-cover, "dark-bg-color");
                color: map.get($button-cover, "dark-color");
            }

            .button-cover {
                background-color: map.get($button-cover, "light-bg-color");
                width: 100%;

                @include dark {
                    background-color: map.get($button-cover, "dark-bg-color");
                    color: map.get($button-cover, "dark-color");
                }
            }
        }

        &.shadowy {
            box-shadow: 0 4px 4px 0 rgba(0 0 0 / 10%);
        }

        &.narrow-padding {
            @include breakpoint($md) {
                padding: 2.5px 16px;
            }
        }
    }
}

@mixin create-mixed-button-theme($name, $button-base, $button-cover) {
    &.mixed-#{$name} {
        background-color: transparent;
        backdrop-filter: blur(100px);
        border: 1px solid map.get($button-base, "light-bg-color");
        color: map.get($button-base, "light-color");
        transition: background-color 0.4s;

        @include breakpoint($xs) {
            border: 2px solid map.get($button-base, "light-bg-color");
        }

        &:hover {
            color: map.get($button-cover, "light-color");
            background-color: map.get($button-cover, "light-bg-color");

            .button-cover {
                background-color: map.get($button-cover, "light-bg-color");
                width: 100%;
            }
        }

        &.shadowy {
            box-shadow: 0 4px 4px 0 rgba(0 0 0 / 10%);
        }

        &.narrow-padding {
            @include breakpoint($md) {
                padding: 2.5px 16px;
            }
        }

        @include dark {
            background-color: map.get($button-base, "dark-bg-color");
            color: map.get($button-base, "dark-color");
            border: 2px solid map.get($button-base, "dark-bg-color");

            &:disabled {
                background-color: $gray-350;
                color: white;
                border-color: white;
                pointer-events: none;
            }

            &.not-cover {
                transition: background-color 0.4s;

                &:hover {
                    background-color: map.get($button-cover, "dark-bg-color");
                    color: map.get($button-cover, "dark-color");
                }
            }

            &:hover {
                font-weight: 500;
            }

            &:hover .button-cover {
                width: 100%;
                background-color: map.get($button-cover, "dark-bg-color");
                color: map.get($button-cover, "dark-color");
            }
        }
    }
}
