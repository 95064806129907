@use "./breakpoints.scss" as breakpoints;
@use "./mixins.scss" as mixins;
@use "sass:map";
@use "./functions.scss" as functions;

@function generate-rem-font-sizes($sizes) {
    $map: ();
    @each $size in $sizes {
        $map: map.merge(
            $map,
            (
                $size: functions.px-to-rem($size),
            )
        );
    }
    @return $map;
}

@function fs-rem($key-in-px) {
    @return map.get($rem-font-sizes-map, $key-in-px);
}

$px-font-sizes: (
    5,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    24,
    25,
    26,
    28,
    29,
    30,
    32,
    34,
    35,
    36,
    38,
    40,
    45,
    50,
    56
);
$rem-font-sizes-map: generate-rem-font-sizes($px-font-sizes);

@each $bp-key, $bp-value in breakpoints.$breakpoints-map {
    @each $px-key, $rem in $rem-font-sizes-map {
        @if $bp-key == base {
            .fs-#{$px-key} {
                font-size: $rem;
            }

            .lh-s#{$px-key} {
                line-height: $rem;
            }
        } @else {
            @include mixins.toplevel-breakpoint($bp-value) {
                .fs-#{$bp-key}-#{$px-key} {
                    font-size: $rem;
                }

                .lh-#{$bp-key}-s#{$px-key} {
                    line-height: $rem;
                }
            }
        }
    }
}
