@use "../theme/colors" as *;
@use "../theme/breakpoints" as *;
@use "../theme/mixins" as *;

.ui-kit.ui-coopervision-logo {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    & > img {
        object-fit: contain;
        max-width: 100%;
    }

    & > p {
        font-size: 0.875rem;
        color: $dark-gray-400;
        margin-bottom: 0.5rem;

        @include dark {
            color: $white;
        }
    }
}

.ui-kit.ui-ocumetra-logo {
    img {
        max-width: 318px;
    }

    span {
        font-size: 12px;
        margin-bottom: 4px;
    }
}

.meye-logo-image {
    max-width: 150px;

    @include breakpoint($lg) {
        max-width: 250px;
    }

    @include breakpoint($xl) {
        max-width: 360px;
    }
}

.coopervision-logo-img {
    max-width: 136px;

    @include breakpoint($md) {
        max-width: 180px;
    }
}

.footer-logo {
    max-width: 180px;
    height: auto;

    @media (max-width: $md) {
        max-width: 120px;
    }

    @media (max-width: $sm) {
        max-width: 100px;
    }
}
