@use "../theme/colors" as *;
@use "../theme/breakpoints" as *;
@use "../theme/mixins" as *;

.ui-kit.column-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.ui-kit.single-layout {
    min-height: calc(100vh - 400px);
    padding: 1.5rem 1rem;

    @include breakpoint($sm) {
        padding: 1.5rem 3.125rem;
    }
}

.ui-kit.double-layout {
    flex: 1;
    display: flex;
    gap: 3rem;
    padding: 1.5rem 1rem;

    @include breakpoint($sm) {
        padding: 1.5rem 3.125rem;
    }

    @include breakpoint($xl) {
        gap: 6.25rem;
    }

    & > section {
        flex: 1;
    }

    & > section:nth-of-type(2) {
        flex-direction: column;
        text-align: center;
        align-items: center;
        display: none;

        @include breakpoint($md) {
            display: flex;
        }
    }
}

.layout-padding {
    padding: 1.5rem 1rem;

    @include breakpoint($sm) {
        padding: 1.5rem 3.125rem;
    }
}
